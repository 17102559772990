import React, { memo, useState } from 'react';

import { message, Row, Select, Typography } from 'antd';

import CustomModal from 'Common/CustomModal';
import { updateRole } from '../SuperAdminFun';

const AssignDepartmentModal = ({
  isDepartmentModalOpen,
  setIsDepartmentModalOpen,
  userDataID,
  selectdDepartment,
  setSelectedDepartment,
  listAllDepartments,
  getActiveUsersByOrganization,
}) => {
  const [DepartmentLoader, setDepartmentLoader] = useState(false);

  const handleDepartmentModalCancel = () => {
    setIsDepartmentModalOpen(false);
  };

  const AssignDepartment = async () => {
    setDepartmentLoader(true);
    try {
      const roleData = {
        id: userDataID?.id,
        departmentID: selectdDepartment,
      };
      await updateRole(roleData);
      setDepartmentLoader(false);
      message.success('Department Assigned Successfully');
      setIsDepartmentModalOpen(false);
      await getActiveUsersByOrganization();
    } catch (error) {
      setDepartmentLoader(false);
      throw error;
    }
  };
  return (
    <>
      <CustomModal
        visible={isDepartmentModalOpen}
        onCancel={handleDepartmentModalCancel}
        onSubmit={AssignDepartment}
        buttonLoader={DepartmentLoader}
        centered
      >
        <Row justify="center">
          <Typography.Title level={4}>Assign Department</Typography.Title>
        </Row>
        <Row justify="center">
          <Select
            style={{
              width: 200,
            }}
            value={selectdDepartment}
            onChange={(e) => setSelectedDepartment(e)}
          >
            {listAllDepartments?.map((item, index) => {
              return (
                <Select.Option key={index} value={item?.id}>
                  {item?.name}
                </Select.Option>
              );
            })}
          </Select>
        </Row>
      </CustomModal>
    </>
  );
};

export default memo(AssignDepartmentModal);

