import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  Col,
  Row,
  Input,
  Button,
  Grid,
  Typography,
  Table,
  Dropdown,
  Menu,
  message,
  Avatar,
  Modal,
} from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import moment from 'moment';

import { ECHOIMAGES } from '../../Assets/Images';
import { getUsersByOrganizationIDCustom } from './SuperAdminFun';
import { UserRole, userStatus } from '../../utils/Constants';
import {
  getCurrentOrganization,
  getCurrnetUserData,
} from '../../store/slices/loginSlice';
import { RESTGetManagement, RESTReminder } from '../../utils/RESTApi';
import AdminGlobalFilter from './AdminGlobalFilter';
import { searchUsersCustom } from 'utils/Actions';
import { fetchOrgDepartments } from 'utils/CommonFunctions';
import { debounceFn, searchTextInArrayOfObject } from 'utils/commonMethods';
import TableSkeleton from 'Common/TableSkeleton';
import FilterButton from 'Common/FilterButton';

export default function InvitedUsers({ userCount, setUserCount, activeTab }) {
  const { Search } = Input;
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const history = useHistory();
  const [open, setOpen] = useState(false);

  const [allInvitedUsers, setAllInvitedUsers] = useState([]);
  const [listAllDepartments, setListAllDepartments] = useState([]);
  const [backupAllUsers, setBackupAllUsers] = useState([]);
  const [isRemindModal, setIsRemindModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const { Text } = Typography;
  const { FilterIcon } = ECHOIMAGES.ideaIcons;
  const { remindLogo } = ECHOIMAGES.admin;
  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;
  const [sortTableOrder, setSortTableOrder] = useState('ASC');
  const [sortTableColumn, setSortTableColumn] = useState('createdAt');
  const [filterChange, setFilterChange] = useState(false);
  const [adminFilter, setAdminFilter] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [searchField, setSearchField] = useState();

  const showRemindModal = () => {
    setIsRemindModal(true);
  };
  setTimeout(() => {
    setIsRemindModal(false);
  }, 5000);
  //drawer stuff
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  //for admin
  const getActiveUsersByOrganization = async (
    sortOrder,
    sortColumn,
    page,
    filterChange,
    search
  ) => {
    try {
      setLoading(true);
      let tempPage = page || currentPage;
      let sort_direction = sortTableOrder;
      let sort_field = sortTableColumn;
      if (sortOrder && sortColumn) {
        sort_direction = sortOrder;
        sort_field = sortColumn;
      }
      let filter = {
        organizationID: { eq: orgId },
      };
      if (search === '') {
        delete adminFilter.Search;
      } else {
        adminFilter.Search = search;
      }
      let inputData = {
        organization_id: orgId,
        sort_field: sort_field,
        sort_direction: sort_direction,
        scope: 'ADMIN',
        page: tempPage,
        filters: adminFilter,
      };
      adminFilter.Status = 'INVITED';
      let res = await RESTGetManagement(inputData);
      const users = res?.body || [];
      let invitedUsers = filterChange
        ? users
        : tempPage > 1
        ? [...allInvitedUsers, ...users]
        : users;
      let totalUsersCount = await searchUsersCustom(filter);
      let activeUser =
        totalUsersCount?.data?.searchUsers?.aggregateItems?.[0]?.result?.buckets.find(
          (item) => item?.key === userStatus.active
        );
      let disabledUser =
        totalUsersCount?.data?.searchUsers?.aggregateItems?.[0]?.result?.buckets.find(
          (item) => item?.key === userStatus.disabled
        );
      let invitedUser =
        totalUsersCount?.data?.searchUsers?.aggregateItems?.[0]?.result?.buckets.find(
          (item) => item?.key === userStatus.invited
        );
      setUserCount({
        ...userCount,
        activeUserLength: activeUser?.doc_count || 0,
        disabledUserLength: disabledUser?.doc_count || 0,
        invitesUserLength: invitedUser?.doc_count || 0,
      });

      setAllInvitedUsers(invitedUsers);
      setBackupAllUsers(invitedUsers);

      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const sortTableData = async (sortData) => {
    let sortOrder = sortData.order === 'ascend' ? 'ASC' : 'DESC';
    let sortColumn = sortData.field;
    setSortTableOrder(sortOrder);
    setSortTableColumn(sortColumn);
    setCurrentPage(1);
    await getActiveUsersByOrganization(
      sortOrder,
      sortColumn,
      1,
      true,
      searchField
    );
  };

  const handelInfiniteScroll = async (e) => {
    const { target } = e;
    const isBottom =
      Math.round(target.scrollHeight) - Math.round(target.scrollTop) ===
      Math.round(target.clientHeight);
    if (isBottom && allInvitedUsers?.length > 0) {
      setCurrentPage((prevPage) => prevPage + 1);
      setLoadData(true);
      if (isAdminGroup || currnetOrg?.id) {
        await getActiveUsersByOrganization(
          sortTableOrder,
          sortTableColumn,
          currentPage + 1,
          true,
          searchField
        );
      }
    }
  };

  const listOrgDepartments = async () => {
    const departments = await fetchOrgDepartments(orgId);
    setListAllDepartments(departments);
  };

  const sendReminder = async (id) => {
    try {
      if (selectedRowKeys.length) {
        await Promise.all(
          selectedRowKeys.map(async (e) => {
            let inputData = {
              user_id: e,
            };
            showRemindModal();
            await RESTReminder(inputData);
          })
        );
      } else {
        let inputData = {
          user_id: id,
        };
        showRemindModal();
        await RESTReminder(inputData);
      }
      setSelectedRowKeys([]);
      message.success('Send Reminder Successfully');
    } catch (err) {
      console.log(err);
    }
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onSearch = async (searchText) => {
    if (searchText !== '') {
      setCurrentPage(1);
      setSearchField(searchText);
      await getActiveUsersByOrganization(
        sortTableOrder,
        sortTableColumn,
        1,
        true,
        searchText
      );
    } else {
      setCurrentPage(1);
      setSearchField('');
      await getActiveUsersByOrganization(
        sortTableOrder,
        sortTableColumn,
        1,
        true,
        ''
      );
    }
  };

  const debouncedHandleInput = debounceFn(onSearch, 500);

  const handleInputSearch = (searchText) => {
    debouncedHandleInput(searchText);
  };

  useEffect(() => {
    getActiveUsersByOrganization();
    listOrgDepartments();
    // eslint-disable-next-line
  }, []);

  //   columns
  const columns = [
    {
      title: 'Name',
      dataIndex: 'firstName',
      key: 'firstName',
      sortOrder:
        sortTableColumn === 'firstName'
          ? sortTableOrder === 'ASC'
            ? 'ascend'
            : 'descend'
          : null,
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a, b) => {
        return a?.firstName?.localeCompare(b?.firstName);
      },
      render: (text, row) => {
        return (
          <>
            <Avatar
              size={35}
              alt=""
              className="mr-10 avtar-style tag-font"
              style={{ width: '30px', height: '30px', lineHeight: '30px' }}
            >
              {row?.firstName?.[0]?.toUpperCase()}
            </Avatar>
            <span>
              {row?.firstName === null
                ? '-'
                : row?.firstName + ' ' + row?.lastName}
            </span>
          </>
        );
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'Email',
      sorter: (a, b) => {
        return a?.email.localeCompare(b?.email);
      },
      sortOrder:
        sortTableColumn === 'email'
          ? sortTableOrder === 'ASC'
            ? 'ascend'
            : 'descend'
          : null,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, row) => {
        return (
          <div>
            <Text
              style={
                true
                  ? {
                      width: 120,
                    }
                  : undefined
              }
              ellipsis={
                true
                  ? {
                      tooltip: <span>{row?.email}</span>,
                    }
                  : false
              }
              className="table-font"
            >
              {row?.email}
            </Text>
          </div>
        );
      },
    },

    {
      title: 'Role',
      dataIndex: 'role',
      key: 'Role',
      sorter: (a, b) => {
        return a?.role.localeCompare(b?.role);
      },
      sortOrder:
        sortTableColumn === 'role'
          ? sortTableOrder === 'ASC'
            ? 'ascend'
            : 'descend'
          : null,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, row) => {
        const roleName = text === UserRole.sme ? UserRole?.teamMember : text;
        return <span className="table-font">{roleName}</span>;
      },
    },
    {
      title: 'Department',
      dataIndex: 'Department',
      key: 'Department',
      sorter: (a, b) => {
        return a?.Department?.name?.localeCompare(b?.Department?.name);
      },
      sortOrder:
        sortTableColumn === 'department'
          ? sortTableOrder === 'ASC'
            ? 'ascend'
            : 'descend'
          : null,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, row) => (
        <span className="table-font">{text?.name ? text?.name : '-'}</span>
      ),
      align: 'center',
    },
    {
      title: 'Invited On',
      dataIndex: 'createdAt',
      key: 'InvitedOn',
      sortOrder:
        sortTableColumn === 'createdAt'
          ? sortTableOrder === 'ASC'
            ? 'ascend'
            : 'descend'
          : null,

      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      render: (text, row) => (
        <span className="table-font">{moment(row?.createdAt).format('l')}</span>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'Status',
      render: (text, row) => <span className="table-font">{text}</span>,
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      fixed: 'right',
      width: 80,
      render: (text, row) => (
        <Dropdown
          overlay={
            <Menu
              items={[
                {
                  label: <span>Remind</span>,
                  key: 0,
                  onClick: () => {
                    sendReminder(row?.id);
                  },
                },
                {
                  label: <span>Edit</span>,
                  key: 1,
                  onClick: () => {
                    history.push(`/edit-invited-user/${row?.id}`);
                  },
                },
              ]}
            />
          }
          trigger={['click']}
        >
          <MoreOutlined style={{ color: '#5AACC9' }} className="dot-style" />
        </Dropdown>
      ),
    },
  ];

  return (
    <div className="background-white p-20 idea">
      <Row justify="center">
        <Col span={24}>
          <Row justify="space-between" align="middle">
            <Search
              className="searchbar-style"
              placeholder="Search"
              onChange={(e) => handleInputSearch(e.target.value)}
            />
            {selectedRowKeys?.length ? (
              <Button type="primary" onClick={sendReminder} size="middle">
                Remind All
              </Button>
            ) : (
              <FilterButton onClick={showDrawer} />
            )}
          </Row>
        </Col>
      </Row>
      {loading ? (
        <>
          <TableSkeleton />
          <TableSkeleton />
        </>
      ) : (
        <div
          style={{ maxHeight: 'calc(100vh - 140px)', overflowY: 'auto' }}
          onScroll={handelInfiniteScroll}
        >
          <Table
            onChange={(pagination, filter, sorter) => {
              sortTableData(sorter);
            }}
            rowSelection={rowSelection}
            className="admin-table mt-20"
            columns={columns}
            dataSource={allInvitedUsers}
            pagination={false}
            scroll={{ x: 1300 }}
            size={screens.xxl ? 'middle' : 'small'}
            sticky={{
              offsetHeader: 0,
            }}
          />
        </div>
      )}

      {/* Filter Drawer  */}
      <AdminGlobalFilter
        tab="invited"
        onClose={onClose}
        open={open}
        setListAllDepartments={setListAllDepartments}
        listAllDepartments={listAllDepartments}
        backupAllUsers={backupAllUsers}
        setAllInvitedUsers={setAllInvitedUsers}
        setOpen={setOpen}
        activeTab={activeTab}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        sortTableColumn={sortTableColumn}
        sortTableOrder={sortTableOrder}
        setFilterChange={setFilterChange}
        filterChange={filterChange}
        setAdminFilter={setAdminFilter}
        searchField={searchField}
      />
      <Modal
        className="delete-user-modal"
        centered
        open={isRemindModal}
        footer={null}
        closable={false}
      >
        <Row justify="center">
          <img src={remindLogo} alt="" className="pt-10" />
        </Row>
        <Row justify="center">
          <Typography.Title level={4}>
            Email reminder is being sent.
          </Typography.Title>
        </Row>
      </Modal>
    </div>
  );
}

