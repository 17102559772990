import { Form, Input, Button, Checkbox, Typography } from 'antd';
import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import OtpInput from 'react-otp-input';
import { InfoCircleOutlined } from '@ant-design/icons';

export default function Step1(props) {
  const [form] = Form.useForm();
  const [tnc, setTnc] = useState(false);
  return (
    <>
      <Typography.Title level={3}>Sign Up</Typography.Title>
      <Typography.Title level={5}>
        Start your journey with StrAlign
      </Typography.Title>

      <Form
        layout="vertical"
        requiredMark={false}
        form={form}
        onFinish={(e) => {
          if (props.showVerified) {
            props.handleConfirmSignUp(e);
          } else {
            props.handleSignup(e);
          }
        }}
      >
        <Form.Item
          label="Email"
          name="Email"
          rules={[
            {
              required: true,
              message: 'Please input your email',
            },
          ]}
        >
          <Input className="input" disabled={props.showVerified} />
        </Form.Item>

        <Form.Item
          label="Password"
          tooltip={{
            title: 'Passowrd should be 8 characters long',
            icon: <InfoCircleOutlined />,
          }}
          name="Password"
          rules={[
            {
              required: true,
              message: 'Please input your password',
            },
            {
              min: 8,
              message: 'Please enter more than 8 characters',
            },
          ]}
        >
          <Input.Password
            className="input"
            disabled={props.showVerified}
            // iconRender={(visible) =>
            //     visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            // }
          />
        </Form.Item>
        <Form.Item
          label="Confirm Password"
          name="ConfirmPassword"
          rules={[
            {
              required: true,
              message: 'Please input your password',
            },
            {
              min: 8,
              message: 'Please enter more than 8 characters',
            },
          ]}
        >
          <Input.Password className="input" disabled={props.showVerified} />
        </Form.Item>
        <Form.Item name="remember" valuePropName="checked">
          <Checkbox onChange={(e) => setTnc(e.target.checked)} value={tnc}>
            <span className="tandc">
              I agree to <a href="/terms-of-service">Terms of service</a> and{' '}
              <a href="/privacy-policy">Privacy policy</a>
            </span>
          </Checkbox>
        </Form.Item>
        {props.showVerified && (
          <>
            <Typography.Title level={5}>
              Check your email for a verification code
            </Typography.Title>
            <Form.Item
              className="otp"
              name="code"
              rules={[
                {
                  required: true,
                  message: 'Please input your confirmation code',
                },
              ]}
            >
              <OtpInput
                name="code"
                shouldAutoFocus
                className="otp-input"
                id="code"
                key="code"
                value={props.otp}
                onChange={props.handleChange}
                numInputs={6}
                separator={<span style={{ color: 'white' }}> ---</span>}
              />
            </Form.Item>
          </>
        )}
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="blue-filled-btn w-100 mt-10 h-40"
            loading={props.loading}
            disabled={!tnc}
          >
            {props.showVerified ? 'Sign Up' : 'Continue'}
          </Button>
        </Form.Item>

        <Typography.Title level={5} className="signup-text">
          Already have an account? <Link to="/login">Login</Link>
        </Typography.Title>
      </Form>
    </>
  );
}
