import { message } from 'antd';
import {
  createPriorityBatchCustom,
  createPriorityBatchesAndUsersCustom,
  deletePriorityBatchCustom,
  deletePriorityBatchesAndUsersCustom,
} from '../../utils/Actions';

export const createPriorityBatch = async (data) => {
  try {
    const createPriority = await createPriorityBatchCustom(data);
    return createPriority;
  } catch (error) {
    message.error(error.message);
  }
};
export const createPriorityBatchesAndUser = async (data) => {
  try {
    const createPriorityBatch = await createPriorityBatchesAndUsersCustom(data);
    return createPriorityBatch;
  } catch (error) {
    message.error(error.message);
  }
};

export const deletePriorityBatchesAndUser = async (id) => {
  try {
    const deletePriorityBatchesAndUser =
      await deletePriorityBatchesAndUsersCustom(id);
    return deletePriorityBatchesAndUser;
  } catch (error) {
    message.error(error.message);
  }
};
export const deletePriorityBatches = async (id) => {
  try {
    const deletePriorityBatch = await deletePriorityBatchCustom(id);
    return deletePriorityBatch;
  } catch (error) {
    message.error(error.message);
  }
};
