import React, { useEffect, useState } from 'react';
import { WarningOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  message,
  Row,
  Select,
  Typography,
  Tooltip,
  Divider,
  Skeleton,
} from 'antd';
import { ECHOIMAGES } from '../../Assets/Images';
import './priorityBatches.less';
import { useSelector, useDispatch } from 'react-redux';
import {
  getCurrentOrganization,
  getCurrnetUserData,
  setComparisonIDs,
} from '../../store/slices/loginSlice';
import { listObjective } from '../Goal/GoalFunction';
import moment from 'moment';
import { arrToCommaSeperated } from 'utils/commonMethods';
import { getDefaultBatchID } from 'store/slices/compareSlice';
import { RESTGetAuthorization } from 'utils/RESTApi';
import { capitalizeFirstLetter, UserRole, userStatus } from 'utils/Constants';
import { fetchOrgDepartments } from 'utils/CommonFunctions';
import { FetchUser } from 'Pages/AllPriorities/IdeaFunction';
import { useHistory, useLocation } from 'react-router-dom';

export default function ComparePriorityBatch() {
  const { warningIcon, noScenarioIcon } = ECHOIMAGES.PriorityBatches;
  const { Option } = Select;

  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;
  const userId = currnetOrg?.adminID || loginUsrOrg?.id;
  const location = useLocation();
  const history = useHistory();
  const compareIds = location?.state;
  const dispatch = useDispatch();
  let priorityDefaultBatchID =
    currnetOrg?.defaultPrioirtybatch?.id ||
    loginUsrOrg?.Organization?.organizationDefaultPrioirtybatchId;

  const fetchDefaultBatchID = useSelector(getDefaultBatchID);

  const defaultPriorityBatchID = fetchDefaultBatchID
    ? fetchDefaultBatchID
    : priorityDefaultBatchID;

  const { questionCircle } = ECHOIMAGES.HomeIcons;

  // list Organization Goal for Leader
  const listGoals = async () => {
    try {
      const filter = {
        organizationObjectivesId: orgId,
      };
      const res = await listObjective(filter);
    } catch (error) {
      message.error(error.message);
    }
  };
  const [listPriorityBatch, setListPriorityBatch] = useState([]);
  const [comparePriority, setComparePriority] = useState({});
  const [compareLoader, setCompareLoader] = useState(false);

  const handleScenario = async (e) => {
    setCompareLoader(true);
    let api = `/scenarios/get?organizationID=${orgId}&userID=${userId}&id=${
      e ? e : defaultPriorityBatchID
    }`;
    let res = await RESTGetAuthorization(api);
    setComparePriority(res?.body);
    setCompareLoader(false);
  };

  const listAddedPriorityBatch = async () => {
    try {
      let api = `/scenarios?organizationID=${orgId}&userID=${userId}`;
      let res = await RESTGetAuthorization(api);
      setListPriorityBatch(res?.body?.items);
    } catch (error) {
      message.error(error.message);
    }
  };
  const [firstScenario, setFirstScenario] = useState({});
  const [firstScenarioLoader, setFirstScenarioLoader] = useState(false);
  const handleFirstScenario = async (e) => {
    try {
      setFirstScenarioLoader(true);
      let api = `/scenarios/get?organizationID=${orgId}&userID=${userId}&id=${
        e || compareIds[1]
      }`;
      let res = await RESTGetAuthorization(api);
      setFirstScenario(res?.body);
      setFirstScenarioLoader(false);
    } catch (err) {
      setFirstScenarioLoader(false);
      console.log(err);
    }
  };
  const [secondScenario, setSecondScenario] = useState({});
  const [secondScenarioLoader, setSecondScenarioLoader] = useState(false);
  const handleSecondScenario = async (e) => {
    try {
      setSecondScenarioLoader(true);
      let api = `/scenarios/get?organizationID=${orgId}&userID=${userId}&id=${
        e || compareIds[2]
      }`;
      let res = await RESTGetAuthorization(api);
      setSecondScenario(res?.body);
      setSecondScenarioLoader(false);
    } catch (err) {
      setSecondScenarioLoader(false);
      console.log(err);
    }
  };

  useEffect(() => {
    handleFirstScenario();
    handleSecondScenario();
    handleScenario();
    // eslint-disable-next-line
  }, []);

  let comparisonSelectedIDs = [
    defaultPriorityBatchID,
    firstScenario?.id,
    secondScenario?.id,
  ];
  const compareTooltip = (
    <>
      This page allows you to compare different scenarios side by side.
      <a
        href="https://stralign.com/help-compare-scenarios"
        target="_blank"
        rel="noopener noreferrer"
      >
        Click here to see video explanation.
      </a>
    </>
  );
  const [listAllDepartments, setListAllDepartments] = useState([]);
  const [ownerList, setOwnerList] = useState([]);
  const listOrgDepartments = async () => {
    const departments = await fetchOrgDepartments(orgId);
    setListAllDepartments(departments);
  };

  //get all users by org
  const getAllUsers = async () => {
    try {
      let data = {
        status: { eq: userStatus.active },
        or: [
          { role: { eq: UserRole.admin } },
          { role: { eq: UserRole.leader } },
          { role: { eq: UserRole.superAdmin } },
          { role: { eq: UserRole.sme } },
        ],
      };
      let id = { organizationID: orgId };
      let nextToken = null;
      let allUserData = [];
      while (true) {
        let res = await FetchUser(data, id, nextToken);
        allUserData.push(...res.items);
        if (!res?.nextToken) {
          break;
        }
        nextToken = res?.nextToken;
      }
      await processUserData(allUserData);
    } catch (err) {
      console.log(err);
    }
  };
  const processUserData = async (userData) => {
    setOwnerList(userData);
  };
  useEffect(() => {
    listGoals();
    listAddedPriorityBatch();
    listOrgDepartments();
    getAllUsers();
    // eslint-disable-next-line
  }, []);

  const handleFilterValue = (item) => {
    if (item?.field?.type === '#') {
      const symbol = item?.field?.subType === '$' ? '$' : '';
      // If type is '#'
      if (
        Object.entries(item?.filter)[0][0] === 'is between' ||
        Object.entries(item?.filter)[0][0] === 'is not between'
      ) {
        return (
          Object.entries(item?.filter)[0][0] +
          ' ' +
          symbol +
          Object.entries(item?.filter)[0][1][0] +
          '-' +
          symbol +
          Object.entries(item?.filter)[0][1][1]
        );
      } else {
        return (
          Object.entries(item?.filter)[0][0] +
          ' ' +
          symbol +
          Object.entries(item?.filter)[0][1]
        );
      }
    } else if (item?.field?.type === '+-') {
      // If type is '+-'
      if (
        Object.entries(item?.filter)[0][0] === 'is between' ||
        Object.entries(item?.filter)[0][0] === 'is not between'
      ) {
        return (
          Object.entries(item?.filter)[0][0] +
          ' ' +
          item?.field?.breakpoints.find(
            (b) =>
              parseInt(b.value) ===
              parseInt(Object.entries(item?.filter)[0][1][0])
          )?.label +
          '-' +
          item?.field?.breakpoints.find(
            (b) =>
              parseInt(b.value) ===
              parseInt(Object.entries(item?.filter)[0][1][1])
          )?.label
        );
      } else {
        return (
          Object.entries(item?.filter)[0][0] +
          ' ' +
          item?.field?.breakpoints?.find(
            (b) => b.value === Object.entries(item?.filter)[0][1]
          )?.label
        );
      }
    } else if (
      item?.field?.name === 'Sponsor' ||
      item?.field?.name === 'Contributor'
    ) {
      return (
        Object.entries(item?.filter)[0][0] +
        ' ' +
        Object.entries(item?.field)
          .filter(([key, value]) => typeof value !== 'string')
          .map((item) => item?.[1]?.firstName + ' ' + item?.[1]?.lastName)
          .join(', ')
      );
    } else {
      return (
        Object.entries(item?.filter)[0][0] +
        ' ' +
        Object.entries(item?.field)
          .filter(([key, value]) => typeof value !== 'string')
          .map((item) => item?.[1]?.name)
          .join(', ')
      );
    }
  };

  const handleClick = () => {
    dispatch(setComparisonIDs(comparisonSelectedIDs));
    history.push({
      pathname: '/scenario-planning',
      state: {
        data: comparisonSelectedIDs,
        previousPath: '/compare-scenarios',
      },
    });
  };

  return (
    <div className="add-idea  overflowX-hidden background-white p-20">
      <Row gutter={[16, 16]} className="compare-batches">
        <Col span={17} lg={17} md={24} xs={24} sm={24} className="mb-10">
          <Typography.Title level={3} className="text-black title mb-0">
            Compare scenario
            <Tooltip title={compareTooltip}>
              <img
                src={questionCircle}
                alt=""
                className="detail-question-icon"
              />
            </Tooltip>
          </Typography.Title>
        </Col>
        <Col
          span={7}
          lg={7}
          md={24}
          xs={24}
          sm={24}
          className="mt-auto plr-0 mb-10"
        >
          <Row>
            <Button
              size="middle"
              className="bordered-button"
              onClick={() => {
                setComparePriority({});
                setFirstScenario({});
                setSecondScenario({});
              }}
            >
              Reset All
            </Button>
            <Button
              type="primary"
              className="ml-30"
              onClick={handleClick}
              disabled={!firstScenario?.id && !secondScenario?.id}
            >
              Go to Scenario Planning
            </Button>
          </Row>
        </Col>

        {comparePriority?.id ? (
          <Col span={7} lg={7} md={12} sm={24} xs={24} className="pr-0">
            <div className="create-goal h-100">
              <Row justify="space-between" align="middle">
                <Typography.Title
                  level={4}
                  className="mb-0 fs-18 fw-600"
                  style={
                    true
                      ? {
                          width: 240,
                        }
                      : undefined
                  }
                  ellipsis={
                    true
                      ? {
                          tooltip: comparePriority?.title,
                        }
                      : false
                  }
                >
                  {comparePriority?.title}
                </Typography.Title>
              </Row>
              <Row>
                <Typography.Text level={3} className="mb-5">
                  {comparePriority?.description}
                </Typography.Text>
              </Row>
              <Row className="head-data mt-20" gutter={[0, 20]}>
                <Col span={24}>
                  <Typography.Title
                    level={5}
                    className="mb-0 text-black fs-14 fw-500"
                  >
                    Owner Name
                  </Typography.Title>
                  <Typography.Title
                    level={5}
                    className="mb-0 mt-0 data fw-400"
                    style={
                      true
                        ? {
                            width: 240,
                          }
                        : undefined
                    }
                    ellipsis={
                      true
                        ? {
                            tooltip:
                              comparePriority?.owners?.length > 0
                                ? arrToCommaSeperated(
                                    comparePriority?.owners?.map(
                                      (item) =>
                                        item?.firstName + ' ' + item?.lastName
                                    )
                                  )
                                : '-',
                          }
                        : false
                    }
                  >
                    {' '}
                    {comparePriority?.owners?.length > 0
                      ? arrToCommaSeperated(
                          comparePriority?.owners?.map(
                            (item) => item?.firstName + ' ' + item?.lastName
                          )
                        )
                      : '-'}
                  </Typography.Title>
                </Col>
              </Row>
              <Row className="head-data mt-10 mb-5" gutter={[0, 20]}>
                <Col span={12}>
                  <Typography.Title
                    level={5}
                    className="mb-0 mr-10 text-black fs-14 fw-500"
                  >
                    Created date
                  </Typography.Title>

                  <Typography.Title level={5} className="mb-0 mt-0 data fw-400">
                    {' '}
                    {moment(comparePriority?.createdAt).format('l')}
                  </Typography.Title>
                </Col>
                <Col span={12}>
                  <Typography.Title
                    level={5}
                    className="mb-0 mr-10 text-black fs-14 fw-500"
                  >
                    Last modified date
                  </Typography.Title>

                  <Typography.Title level={5} className="mb-0 mt-0 data fw-400">
                    {' '}
                    {moment(comparePriority?.updatedAt).format('l')}
                  </Typography.Title>
                </Col>
              </Row>

              <Row
                className="head-data mt-20 mb-10"
                justify="space-between"
                align="middle"
              >
                <Typography.Title level={5} className="mb-0 fw-500">
                  Goals
                </Typography.Title>
                <Typography.Text level={5} className="mb-0 mt-0 fw-400">
                  Weight
                </Typography.Text>
              </Row>
              {comparePriority?.priorities?.map((item, index) => {
                return (
                  <Row
                    justify="space-between"
                    align="middle"
                    className="added-goal"
                    gutter={[0, 10]}
                    key={index}
                  >
                    <Typography.Text
                      level={5}
                      className="mb-0 title fw-500"
                      style={
                        true
                          ? {
                              width: 190,
                            }
                          : undefined
                      }
                      ellipsis={
                        true
                          ? {
                              tooltip: item?.name,
                            }
                          : false
                      }
                    >
                      {item?.name}
                    </Typography.Text>
                    <Row align="middle">
                      <Typography.Text level={5} className="mb-0">
                        <span className="fw-bolder">{item?.weight}%</span>
                      </Typography.Text>
                    </Row>
                  </Row>
                );
              })}
              {(comparePriority?.voteFilters?.filters?.length > 0 ||
                comparePriority?.rankingFilters?.filters?.length > 0) && (
                <Typography.Title level={5} className="mb-0">
                  Scenario Filters
                </Typography.Title>
              )}
              {comparePriority?.rankingFilters?.filters?.length > 0 && (
                <Typography.Text>
                  <span
                    className={
                      comparePriority?.rankingFilters?.count === 0
                        ? 'fw-bold zero-items-count'
                        : comparePriority?.rankingFilters?.count <= 5
                        ? 'fw-bold yellow-items-count'
                        : 'fw-bold'
                    }
                  >
                    {' '}
                    {comparePriority?.rankingFilters?.count}
                  </span>{' '}
                  items match your scenario
                </Typography.Text>
              )}
              {comparePriority?.voteFilters?.filters?.length > 0 && (
                <Row className="mt-10">
                  <Row className="w-100 mb-10" justify="space-between">
                    <Col span={6}>
                      <Typography.Text className="who-text">
                        Who
                      </Typography.Text>
                    </Col>
                    <Col span={18}>
                      <Typography.Text level={5} className="fs-14 gray-text">
                        Use votes that match
                      </Typography.Text>
                      <Typography.Text className="f-14 ml-5 fw-bold">
                        {comparePriority?.voteFilters?.match === 'ALL'
                          ? 'All conditions'
                          : comparePriority?.voteFilters?.match === 'ONE'
                          ? 'Any conditions'
                          : null}
                      </Typography.Text>
                    </Col>
                  </Row>

                  {comparePriority?.voteFilters?.filters?.map((item, index) => {
                    let includeStringDepartment = 'to be excluded';
                    let includeStringUserRole = 'to be excluded';
                    let includedStringUsers = 'to be excluded';
                    let addedDepartment = [];
                    if (
                      item.departmentFilters &&
                      item.departmentFilters.included
                    ) {
                      includeStringDepartment = 'to be included';
                    }
                    if (item.userRoleFilters && item.userRoleFilters.included) {
                      includeStringUserRole = 'to be included';
                    }
                    if (item.userFilters && item.userFilters.included) {
                      includedStringUsers = 'to be included';
                    }
                    addedDepartment =
                      item?.departmentFilters?.departmentID?.map((id) => {
                        return listAllDepartments?.find((e) => e?.id === id)
                          ?.name;
                      });

                    return (
                      <>
                        <Row className="scenario-detail-drawer w-100 mb-10">
                          <Col span={24} className="added-filter">
                            <Row>
                              {item?.departmentFilters?.departmentID?.length >
                                0 && (
                                <>
                                  <Typography.Text>Department</Typography.Text>
                                  <Typography.Text className="ml-5">
                                    {includeStringDepartment} :{' '}
                                  </Typography.Text>
                                  <Typography.Text
                                    style={
                                      true
                                        ? {
                                            width: 100,
                                          }
                                        : undefined
                                    }
                                    ellipsis={
                                      true
                                        ? {
                                            tooltip:
                                              arrToCommaSeperated(
                                                addedDepartment
                                              ),
                                          }
                                        : false
                                    }
                                    className="ml-5"
                                  >
                                    <span className="fw-500">
                                      {arrToCommaSeperated(addedDepartment)}
                                    </span>
                                  </Typography.Text>
                                </>
                              )}
                            </Row>
                            <Row>
                              {item?.userRoleFilters?.userROLE?.length > 0 && (
                                <>
                                  <Typography.Text>User role</Typography.Text>{' '}
                                  <Typography.Text className="ml-5">
                                    {includeStringUserRole} :{' '}
                                  </Typography.Text>
                                  <Typography.Text
                                    style={
                                      true
                                        ? {
                                            width: 120,
                                          }
                                        : undefined
                                    }
                                    ellipsis={
                                      true
                                        ? {
                                            tooltip:
                                              item?.userRoleFilters?.userROLE?.map(
                                                (role, index) => (
                                                  <>
                                                    <span className="fw-500">
                                                      {role === UserRole.sme
                                                        ? capitalizeFirstLetter(
                                                            UserRole?.teamMember
                                                          )
                                                        : capitalizeFirstLetter(
                                                            role
                                                          )}
                                                    </span>{' '}
                                                    {item?.userRoleFilters
                                                      ?.weight?.length > 0 && (
                                                      <>
                                                        (W=
                                                        <span className="fw-500">
                                                          {
                                                            item
                                                              ?.userRoleFilters
                                                              ?.weight[index]
                                                          }
                                                        </span>
                                                        )
                                                      </>
                                                    )}
                                                    {index <
                                                      item?.userRoleFilters
                                                        ?.userROLE?.length -
                                                        1 && <span>, </span>}
                                                  </>
                                                )
                                              ),
                                          }
                                        : false
                                    }
                                    className="ml-5"
                                  >
                                    {item?.userRoleFilters?.userROLE?.map(
                                      (role, index) => (
                                        <>
                                          <span className="fw-500">
                                            {role === UserRole.sme
                                              ? capitalizeFirstLetter(
                                                  UserRole?.teamMember
                                                )
                                              : capitalizeFirstLetter(role)}
                                          </span>{' '}
                                          {item?.userRoleFilters?.weight
                                            ?.length > 0 && (
                                            <>
                                              (W=
                                              <span className="fw-500">
                                                {
                                                  item?.userRoleFilters?.weight[
                                                    index
                                                  ]
                                                }
                                              </span>
                                              )
                                            </>
                                          )}
                                          {index <
                                            item?.userRoleFilters?.userROLE
                                              ?.length -
                                              1 && <span>, </span>}
                                        </>
                                      )
                                    )}
                                  </Typography.Text>
                                </>
                              )}
                            </Row>
                            <Row>
                              {item?.userFilters?.userID?.length > 0 && (
                                <>
                                  <Typography.Text>Users</Typography.Text>
                                  <Typography.Text className="ml-5">
                                    {includedStringUsers} :{' '}
                                  </Typography.Text>
                                  <Typography.Text
                                    className="ml-5"
                                    style={
                                      true
                                        ? {
                                            width: 140,
                                          }
                                        : undefined
                                    }
                                    ellipsis={
                                      true
                                        ? {
                                            tooltip:
                                              item?.userFilters?.userID?.map(
                                                (name, index) => (
                                                  <>
                                                    <span className="fw-500">
                                                      {ownerList?.find(
                                                        (e) => e?.id === name
                                                      )?.firstName +
                                                        ' ' +
                                                        ownerList?.find(
                                                          (e) => e?.id === name
                                                        )?.lastName}{' '}
                                                    </span>
                                                    {item?.userFilters?.weight
                                                      ?.length > 0 && (
                                                      <>
                                                        (W=
                                                        <span className="fw-500">
                                                          {
                                                            item?.userFilters
                                                              ?.weight[index]
                                                          }
                                                        </span>
                                                        )
                                                      </>
                                                    )}
                                                    {index <
                                                      item?.userFilters?.userID
                                                        ?.length -
                                                        1 && <span>, </span>}
                                                  </>
                                                )
                                              ),
                                          }
                                        : false
                                    }
                                  >
                                    {item?.userFilters?.userID?.map(
                                      (name, index) => (
                                        <>
                                          <span className="fw-500">
                                            {ownerList?.find(
                                              (e) => e?.id === name
                                            )?.firstName +
                                              ' ' +
                                              ownerList?.find(
                                                (e) => e?.id === name
                                              )?.lastName}{' '}
                                          </span>
                                          {item?.userFilters?.weight?.length >
                                            0 && (
                                            <>
                                              (W=
                                              <span className="fw-500">
                                                {
                                                  item?.userFilters?.weight[
                                                    index
                                                  ]
                                                }
                                              </span>
                                              )
                                            </>
                                          )}
                                          {index <
                                            item?.userFilters?.userID?.length -
                                              1 && <span>, </span>}
                                        </>
                                      )
                                    )}
                                  </Typography.Text>
                                </>
                              )}
                            </Row>
                          </Col>
                        </Row>
                      </>
                    );
                  })}
                </Row>
              )}

              {comparePriority?.rankingFilters?.filters?.length > 0 && (
                <Row className="mt-10" justify="space-between">
                  <Col span={5}>
                    <Typography.Text className="idea-text">
                      Ideas
                    </Typography.Text>
                  </Col>
                  <Col span={19}>
                    <Typography.Text className="gray-text">
                      Show ideas that match
                    </Typography.Text>
                    <Typography.Text className="f-14 ml-5 fw-bold ">
                      {comparePriority?.rankingFilters?.match === 'ALL'
                        ? 'All conditions'
                        : comparePriority?.rankingFilters?.match === 'ONE'
                        ? 'Any conditions'
                        : null}
                    </Typography.Text>
                  </Col>
                </Row>
              )}
              {comparePriority?.rankingFilters?.filters?.map((item) => {
                return (
                  <Row className="scenario-filter-list scenario-detail-drawer mt-10">
                    <Col
                      span={24}
                      className={
                        item?.isUpdated
                          ? 'added-filter updated-bg mb-10'
                          : item?.isDeleted
                          ? ' added-filter deleted-bg mb-10'
                          : 'added-filter mb-10'
                      }
                    >
                      <Row>
                        <Col span={22}>
                          <Row>
                            <>
                              {item?.isUpdated && (
                                <img
                                  src={warningIcon}
                                  alt=""
                                  className="mr-5"
                                />
                              )}
                              {item?.isDeleted && <WarningOutlined />}
                              <Typography.Text
                                className={
                                  item?.isDeleted
                                    ? 'mr-5 deleted-mf-color'
                                    : 'mr-5'
                                }
                              >
                                {item?.field?.name}
                              </Typography.Text>
                              <Typography.Text
                                className={
                                  item?.isDeleted ? 'deleted-mf-color' : ''
                                }
                              >
                                {handleFilterValue(item)}
                              </Typography.Text>
                            </>
                          </Row>
                          <Row>
                            <>
                              {item?.threshold !== null &&
                                item?.threshold !== undefined && (
                                  <Typography.Text
                                    className={
                                      item?.isDeleted
                                        ? 'deleted-mf-color mr-5'
                                        : 'mr-5'
                                    }
                                  >
                                    Threshold :{' '}
                                    {item?.field?.type === '+-' ? (
                                      item?.field?.breakpoints?.find(
                                        (el) =>
                                          parseInt(el?.value) ===
                                          parseInt(item?.threshold)
                                      )?.label
                                    ) : (
                                      <>
                                        {item?.field?.subType === '$'
                                          ? '$'
                                          : ''}
                                        {item?.threshold}
                                      </>
                                    )}
                                  </Typography.Text>
                                )}
                              <Typography.Text
                                className={
                                  item?.isDeleted ? 'deleted-mf-color' : ''
                                }
                              >
                                Include blanks :{' '}
                                {item?.blanks ? 'true' : 'false'}
                              </Typography.Text>
                            </>
                          </Row>
                          {item?.isUpdated && (
                            <>
                              <Divider className="updated-divider" />
                              <Typography.Text>Update required</Typography.Text>
                            </>
                          )}
                          {item?.isDeleted && (
                            <>
                              <Divider className="updated-divider" />
                              <Typography.Text>
                                Field no longer available
                              </Typography.Text>
                            </>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                );
              })}
            </div>
          </Col>
        ) : (
          <Col span={7} lg={7} md={12} sm={24} xs={24}>
            <div className="empty-box h-100">
              <Select
                onChange={(e) => {
                  handleScenario(e);
                }}
                className="w-100 compare-dropdown"
                placeholder="Select scenario"
              >
                {listPriorityBatch.map((item, index) => {
                  return (
                    item?.title && (
                      <Option value={item?.id} key={index}>
                        {item?.title}
                      </Option>
                    )
                  );
                })}
              </Select>
              {compareLoader ? (
                <Skeleton active className="mt-10" />
              ) : (
                <Row>
                  <div className="empty-card">
                    <img src={noScenarioIcon} alt="" />
                    <Typography.Title level={5}>
                      Please select the scenario <br />
                      for comparison
                    </Typography.Title>
                  </div>
                </Row>
              )}
            </div>
          </Col>
        )}

        <Divider type="vertical" className="h-auto" />

        {firstScenario?.id ? (
          <Col span={8} lg={8} md={12} sm={24} xs={24}>
            <Select
              onChange={(e) => {
                handleFirstScenario(e);
              }}
              className="w-100 compare-dropdown"
              placeholder="Select scenario"
              value={firstScenario?.title}
            >
              {listPriorityBatch.map((item, index) => {
                return (
                  <Option value={item?.id} key={index}>
                    {item?.title}
                  </Option>
                );
              })}
            </Select>
            <Row>
              <Typography.Text level={3} className="mt-10">
                {firstScenario?.description}
              </Typography.Text>
            </Row>
            <Row className="head-data mt-20" gutter={[0, 20]}>
              <Col span={24}>
                <Typography.Title
                  level={5}
                  className="mb-0 text-black fs-14 fw-500"
                >
                  Owner Name
                </Typography.Title>
                <Typography.Title
                  level={5}
                  className="mb-0 mt-0 data fw-400"
                  style={
                    true
                      ? {
                          width: 240,
                        }
                      : undefined
                  }
                  ellipsis={
                    true
                      ? {
                          tooltip:
                            firstScenario?.owners?.length > 0
                              ? arrToCommaSeperated(
                                  firstScenario?.owners?.map(
                                    (item) =>
                                      item?.firstName + ' ' + item?.lastName
                                  )
                                )
                              : '-',
                        }
                      : false
                  }
                >
                  {' '}
                  {firstScenario?.owners?.length > 0
                    ? arrToCommaSeperated(
                        firstScenario?.owners?.map(
                          (item) => item?.firstName + ' ' + item?.lastName
                        )
                      )
                    : '-'}
                </Typography.Title>
              </Col>
            </Row>
            <Row className="head-data mt-10 mb-5" gutter={[0, 20]}>
              <Col span={12}>
                <Typography.Title
                  level={5}
                  className="mb-0 mr-10 text-black fs-14 fw-500"
                >
                  Created date
                </Typography.Title>
                <Typography.Title level={5} className="mb-0 mt-0 data fw-400">
                  {' '}
                  {moment(firstScenario?.createdAt).format('l')}
                </Typography.Title>
              </Col>
              <Col span={12}>
                <Typography.Title
                  level={5}
                  className="mb-0 mr-10 text-black fs-14 fw-500"
                >
                  Last modified date
                </Typography.Title>
                <Typography.Title level={5} className="mb-0 mt-0 data fw-400">
                  {' '}
                  {moment(firstScenario.updatedAt).format('l')}
                </Typography.Title>
              </Col>
            </Row>

            <Row
              className="head-data mt-20 mb-10"
              justify="space-between"
              align="middle"
            >
              <Typography.Title level={5} className="mb-0  fw-500">
                Goals
              </Typography.Title>
              <Typography.Text level={5} className="mb-0 mt-0  fw-400">
                Weight
              </Typography.Text>
            </Row>
            <Row className="create-goal">
              {firstScenario?.priorities?.map((item, index) => {
                return (
                  <Row
                    justify="space-between"
                    align="middle"
                    className="added-goal w-100"
                    gutter={[0, 10]}
                    key={index}
                  >
                    <Typography.Text
                      style={
                        true
                          ? {
                              width: 190,
                            }
                          : undefined
                      }
                      ellipsis={
                        true
                          ? {
                              tooltip: item?.name,
                            }
                          : false
                      }
                      level={5}
                      className="mb-0 title fw-500"
                    >
                      {item?.name}
                    </Typography.Text>
                    <Row align="middle">
                      <Typography.Text level={5} className="mb-0">
                        <span className="fw-bolder">{item?.weight}%</span>
                      </Typography.Text>
                    </Row>
                  </Row>
                );
              })}
            </Row>
            {(firstScenario?.voteFilters?.filters?.length > 0 ||
              firstScenario?.rankingFilters?.filters?.length > 0) && (
              <Typography.Title level={5} className="mb-0">
                Scenario Filters
              </Typography.Title>
            )}
            {firstScenario?.rankingFilters?.filters?.length > 0 && (
              <Typography.Text>
                <span
                  className={
                    firstScenario?.rankingFilters?.count === 0
                      ? 'fw-bold zero-items-count'
                      : firstScenario?.rankingFilters?.count <= 5
                      ? 'fw-bold yellow-items-count'
                      : 'fw-bold'
                  }
                >
                  {firstScenario?.rankingFilters?.count}
                </span>{' '}
                items match your scenario
              </Typography.Text>
            )}
            {firstScenario?.voteFilters?.filters?.length > 0 && (
              <Row className="mt-10">
                <Row className="w-100 mb-10" justify="space-between">
                  <Col span={8}>
                    <Typography.Text className="who-text">Who</Typography.Text>
                  </Col>
                  <Col span={16}>
                    <Typography.Text level={5} className="fs-14 gray-text">
                      Use votes that match
                    </Typography.Text>
                    <Typography.Text className="f-14 ml-5 fw-bold">
                      {firstScenario?.voteFilters?.match === 'ALL'
                        ? 'All conditions'
                        : firstScenario?.voteFilters?.match === 'ONE'
                        ? 'Any conditions'
                        : null}
                    </Typography.Text>
                  </Col>
                </Row>

                {firstScenario?.voteFilters?.filters?.map((item, index) => {
                  let includeStringDepartment = 'to be excluded';
                  let includeStringUserRole = 'to be excluded';
                  let includedStringUsers = 'to be excluded';
                  let addedDepartment = [];
                  if (
                    item.departmentFilters &&
                    item.departmentFilters.included
                  ) {
                    includeStringDepartment = 'to be included';
                  }
                  if (item.userRoleFilters && item.userRoleFilters.included) {
                    includeStringUserRole = 'to be included';
                  }
                  if (item.userFilters && item.userFilters.included) {
                    includedStringUsers = 'to be included';
                  }
                  addedDepartment = item?.departmentFilters?.departmentID?.map(
                    (id) => {
                      return listAllDepartments?.find((e) => e?.id === id)
                        ?.name;
                    }
                  );

                  return (
                    <>
                      <Row className="scenario-detail-drawer  w-100 mb-10">
                        <Col span={24} className="added-filter">
                          <Row>
                            {item?.departmentFilters?.departmentID?.length >
                              0 && (
                              <>
                                <Typography.Text>Department</Typography.Text>
                                <Typography.Text className="ml-5">
                                  {includeStringDepartment} :{' '}
                                </Typography.Text>
                                <Typography.Text
                                  style={
                                    true
                                      ? {
                                          width: 100,
                                        }
                                      : undefined
                                  }
                                  ellipsis={
                                    true
                                      ? {
                                          tooltip:
                                            arrToCommaSeperated(
                                              addedDepartment
                                            ),
                                        }
                                      : false
                                  }
                                  className="ml-5"
                                >
                                  <span className="fw-500">
                                    {arrToCommaSeperated(addedDepartment)}
                                  </span>
                                </Typography.Text>
                              </>
                            )}
                          </Row>
                          <Row>
                            {item?.userRoleFilters?.userROLE?.length > 0 && (
                              <>
                                <Typography.Text>User role</Typography.Text>{' '}
                                <Typography.Text className="ml-5">
                                  {includeStringUserRole} :{' '}
                                </Typography.Text>
                                <Typography.Text
                                  style={
                                    true
                                      ? {
                                          width: 120,
                                        }
                                      : undefined
                                  }
                                  ellipsis={
                                    true
                                      ? {
                                          tooltip:
                                            item?.userRoleFilters?.userROLE?.map(
                                              (role, index) => (
                                                <>
                                                  <span className="fw-500">
                                                    {role === UserRole.sme
                                                      ? capitalizeFirstLetter(
                                                          UserRole?.teamMember
                                                        )
                                                      : capitalizeFirstLetter(
                                                          role
                                                        )}
                                                  </span>{' '}
                                                  {item?.userRoleFilters
                                                    ?.weight && (
                                                    <>
                                                      (W=
                                                      <span className="fw-500">
                                                        {
                                                          item?.userRoleFilters
                                                            ?.weight[index]
                                                        }
                                                      </span>
                                                      )
                                                    </>
                                                  )}
                                                  {index <
                                                    item?.userRoleFilters
                                                      ?.userROLE?.length -
                                                      1 && <span>, </span>}
                                                </>
                                              )
                                            ),
                                        }
                                      : false
                                  }
                                  className="ml-5"
                                >
                                  {item?.userRoleFilters?.userROLE?.map(
                                    (role, index) => (
                                      <>
                                        <span className="fw-500">
                                          {role === UserRole.sme
                                            ? capitalizeFirstLetter(
                                                UserRole?.teamMember
                                              )
                                            : capitalizeFirstLetter(role)}
                                        </span>{' '}
                                        {item?.userRoleFilters?.weight && (
                                          <>
                                            (W=
                                            <span className="fw-500">
                                              {
                                                item?.userRoleFilters?.weight[
                                                  index
                                                ]
                                              }
                                            </span>
                                            )
                                          </>
                                        )}
                                        {index <
                                          item?.userRoleFilters?.userROLE
                                            ?.length -
                                            1 && <span>, </span>}
                                      </>
                                    )
                                  )}
                                </Typography.Text>
                              </>
                            )}
                          </Row>
                          <Row>
                            {item?.userFilters?.userID?.length > 0 && (
                              <>
                                <Typography.Text>Users</Typography.Text>
                                <Typography.Text className="ml-5">
                                  {includedStringUsers} :{' '}
                                </Typography.Text>
                                <Typography.Text
                                  className="ml-5"
                                  style={
                                    true
                                      ? {
                                          width: 140,
                                        }
                                      : undefined
                                  }
                                  ellipsis={
                                    true
                                      ? {
                                          tooltip:
                                            item?.userFilters?.userID?.map(
                                              (name, index) => (
                                                <>
                                                  <span className="fw-500">
                                                    {ownerList?.find(
                                                      (e) => e?.id === name
                                                    )?.firstName +
                                                      ' ' +
                                                      ownerList?.find(
                                                        (e) => e?.id === name
                                                      )?.lastName}{' '}
                                                  </span>
                                                  {item?.userFilters
                                                    ?.weight && (
                                                    <>
                                                      (W=
                                                      <span className="fw-500">
                                                        {
                                                          item?.userFilters
                                                            ?.weight[index]
                                                        }
                                                      </span>
                                                      )
                                                    </>
                                                  )}
                                                  {index <
                                                    item?.userFilters?.userID
                                                      ?.length -
                                                      1 && <span>, </span>}
                                                </>
                                              )
                                            ),
                                        }
                                      : false
                                  }
                                >
                                  {item?.userFilters?.userID?.map(
                                    (name, index) => (
                                      <>
                                        <span className="fw-500">
                                          {ownerList?.find(
                                            (e) => e?.id === name
                                          )?.firstName +
                                            ' ' +
                                            ownerList?.find(
                                              (e) => e?.id === name
                                            )?.lastName}{' '}
                                        </span>
                                        {item?.userFilters?.weight && (
                                          <>
                                            (W=
                                            <span className="fw-500">
                                              {item?.userFilters?.weight[index]}
                                            </span>
                                            )
                                          </>
                                        )}
                                        {index <
                                          item?.userFilters?.userID?.length -
                                            1 && <span>, </span>}
                                      </>
                                    )
                                  )}
                                </Typography.Text>
                              </>
                            )}
                          </Row>
                        </Col>
                      </Row>
                    </>
                  );
                })}
              </Row>
            )}

            {firstScenario?.rankingFilters?.filters?.length > 0 && (
              <Row className="mt-10" justify="space-between">
                <Col span={7}>
                  <Typography.Text className="idea-text">Ideas</Typography.Text>
                </Col>
                <Col span={17}>
                  <Typography.Text className="gray-text">
                    Show ideas that match
                  </Typography.Text>
                  <Typography.Text className="f-14 ml-5 fw-bold ">
                    {firstScenario?.rankingFilters?.match === 'ALL'
                      ? 'All conditions'
                      : firstScenario?.rankingFilters?.match === 'ONE'
                      ? 'Any conditions'
                      : null}
                  </Typography.Text>
                </Col>
              </Row>
            )}
            {firstScenario?.rankingFilters?.filters?.map((item) => {
              return (
                <Row className="scenario-filter-list scenario-detail-drawer mt-10">
                  <Col
                    span={24}
                    className={
                      item?.isUpdated
                        ? 'added-filter updated-bg mb-10'
                        : item?.isDeleted
                        ? ' added-filter deleted-bg mb-10'
                        : 'added-filter mb-10'
                    }
                  >
                    <Row>
                      <Col span={22}>
                        <Row>
                          <>
                            {item?.isUpdated && (
                              <img src={warningIcon} alt="" className="mr-5" />
                            )}
                            {item?.isDeleted && <WarningOutlined />}
                            <Typography.Text
                              className={
                                item?.isDeleted
                                  ? 'mr-5 deleted-mf-color'
                                  : 'mr-5'
                              }
                            >
                              {item?.field?.name}
                            </Typography.Text>
                            <Typography.Text
                              className={
                                item?.isDeleted ? 'deleted-mf-color' : ''
                              }
                            >
                              {handleFilterValue(item)}
                            </Typography.Text>
                          </>
                        </Row>
                        <Row>
                          <>
                            {item?.threshold !== null && (
                              <Typography.Text
                                className={
                                  item?.isDeleted
                                    ? 'deleted-mf-color mr-5'
                                    : 'mr-5'
                                }
                              >
                                Threshold :{' '}
                                {item?.field?.type === '+-' ? (
                                  item?.field?.breakpoints?.find(
                                    (el) =>
                                      parseInt(el?.value) ===
                                      parseInt(item?.threshold)
                                  )?.label
                                ) : (
                                  <>
                                    {item?.field?.subType === '$' ? '$' : ''}
                                    {item?.threshold}
                                  </>
                                )}
                              </Typography.Text>
                            )}
                            <Typography.Text
                              className={
                                item?.isDeleted ? 'deleted-mf-color' : ''
                              }
                            >
                              Include blanks : {item?.blanks ? 'true' : 'false'}
                            </Typography.Text>
                          </>
                        </Row>
                        {item?.isUpdated && (
                          <>
                            <Divider className="updated-divider" />
                            <Typography.Text>Update required</Typography.Text>
                          </>
                        )}
                        {item?.isDeleted && (
                          <>
                            <Divider className="updated-divider" />
                            <Typography.Text>
                              Field no longer available
                            </Typography.Text>
                          </>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              );
            })}
          </Col>
        ) : (
          <Col span={8} lg={8} md={12} sm={24} xs={24}>
            <div className="empty-box h-100">
              <Select
                onChange={(e) => {
                  handleFirstScenario(e);
                }}
                className="w-100 compare-dropdown"
                placeholder="Select scenario"
              >
                {listPriorityBatch.map((item, index) => {
                  return (
                    <Option value={item?.id} key={index}>
                      {item?.title}
                    </Option>
                  );
                })}
              </Select>
              {firstScenarioLoader ? (
                <Skeleton active className="mt-10" />
              ) : (
                <Row>
                  <div className="empty-card">
                    <img src={noScenarioIcon} alt="" />
                    <Typography.Title level={5}>
                      Please select the scenario <br />
                      for comparison
                    </Typography.Title>
                  </div>
                </Row>
              )}
            </div>
          </Col>
        )}
        <Divider type="vertical" className="h-auto" />

        {secondScenario?.id ? (
          <Col span={8} lg={8} md={12} sm={24} xs={24}>
            <Select
              onChange={(e) => {
                handleSecondScenario(e);
              }}
              value={secondScenario.title}
              className="w-100 compare-dropdown"
              placeholder="Select scenario"
            >
              {listPriorityBatch.map((item, index) => {
                return (
                  <Option value={item?.id} key={index}>
                    {item?.title}
                  </Option>
                );
              })}
            </Select>
            <Row>
              <Typography.Text level={3} className="mt-10">
                {secondScenario?.description}
              </Typography.Text>
            </Row>
            <Row className="head-data mt-20" gutter={[0, 20]}>
              <Col span={24}>
                <Typography.Title
                  level={5}
                  className="mb-0 text-black fs-14 fw-500"
                >
                  Owner Name
                </Typography.Title>
                <Typography.Title
                  level={5}
                  className="mb-0 mt-0 data fw-400"
                  style={
                    true
                      ? {
                          width: 240,
                        }
                      : undefined
                  }
                  ellipsis={
                    true
                      ? {
                          tooltip:
                            secondScenario?.owners?.length > 0
                              ? arrToCommaSeperated(
                                  secondScenario?.owners?.map(
                                    (item) =>
                                      item?.firstName + ' ' + item?.lastName
                                  )
                                )
                              : '-',
                        }
                      : false
                  }
                >
                  {' '}
                  {secondScenario?.owners?.length > 0
                    ? arrToCommaSeperated(
                        secondScenario?.owners?.map(
                          (item) => item?.firstName + ' ' + item?.lastName
                        )
                      )
                    : '-'}
                </Typography.Title>
              </Col>
            </Row>
            <Row className="head-data mt-10 mb-5" gutter={[0, 20]}>
              <Col span={12}>
                <Typography.Title
                  level={5}
                  className="mb-0 mr-10 text-black fs-14 fw-500"
                >
                  Created date
                </Typography.Title>
                <Typography.Title level={5} className="mb-0 mt-0 data fw-400">
                  {' '}
                  {moment(secondScenario?.createdAt).format('l')}
                </Typography.Title>
              </Col>
              <Col span={12}>
                <Typography.Title
                  level={5}
                  className="mb-0 mr-10 text-black fs-14 fw-500"
                >
                  Last modified date
                </Typography.Title>
                <Typography.Title level={5} className="mb-0 mt-0 data fw-400">
                  {' '}
                  {moment(secondScenario.updatedAt).format('l')}
                </Typography.Title>
              </Col>
            </Row>

            <Row
              className="head-data mt-20 mb-10"
              justify="space-between"
              align="middle"
            >
              <Typography.Title level={5} className="mb-0 fw-500">
                Goals
              </Typography.Title>
              <Typography.Text level={5} className="mb-0 mt-0 fw-400">
                Weight
              </Typography.Text>
            </Row>
            <Row className="create-goal">
              {secondScenario?.priorities?.map((item, index) => {
                return (
                  <Row
                    justify="space-between"
                    align="middle"
                    className="added-goal w-100"
                    gutter={[0, 10]}
                    key={index}
                  >
                    <Typography.Text
                      style={
                        true
                          ? {
                              width: 190,
                            }
                          : undefined
                      }
                      ellipsis={
                        true
                          ? {
                              tooltip: item?.name,
                            }
                          : false
                      }
                      level={5}
                      className="mb-0 title fw-500"
                    >
                      {item?.name}
                    </Typography.Text>
                    <Row align="middle">
                      <Typography.Text level={5} className="mb-0">
                        <span className="fw-bolder">{item?.weight}%</span>
                      </Typography.Text>
                    </Row>
                  </Row>
                );
              })}
            </Row>
            {(secondScenario?.voteFilters?.filters?.length > 0 ||
              secondScenario?.rankingFilters?.filters?.length > 0) && (
              <Typography.Title level={5} className="mb-0">
                Scenario Filters
              </Typography.Title>
            )}
            {secondScenario?.rankingFilters?.filters?.length > 0 && (
              <Typography.Text>
                <span
                  className={
                    secondScenario?.rankingFilters?.count === 0
                      ? 'fw-bold zero-items-count'
                      : secondScenario?.rankingFilters?.count <= 5
                      ? 'fw-bold yellow-items-count'
                      : 'fw-bold'
                  }
                >
                  {' '}
                  {secondScenario?.rankingFilters?.count}{' '}
                </span>{' '}
                items match your scenario
              </Typography.Text>
            )}
            {secondScenario?.voteFilters?.filters?.length > 0 && (
              <Row className="mt-10">
                <Row className="w-100 mb-10" justify="space-between">
                  <Col span={8}>
                    <Typography.Text className="who-text">Who</Typography.Text>
                  </Col>
                  <Col span={16}>
                    <Typography.Text level={5} className="fs-14 gray-text">
                      Use votes that match
                    </Typography.Text>
                    <Typography.Text className="f-14 ml-5 fw-bold">
                      {secondScenario?.voteFilters?.match === 'ALL'
                        ? 'All conditions'
                        : secondScenario?.voteFilters?.match === 'ONE'
                        ? 'Any conditions'
                        : null}
                    </Typography.Text>
                  </Col>
                </Row>

                {secondScenario?.voteFilters?.filters?.map((item, index) => {
                  let includeStringDepartment = 'to be excluded';
                  let includeStringUserRole = 'to be excluded';
                  let includedStringUsers = 'to be excluded';
                  let addedDepartment = [];
                  if (
                    item.departmentFilters &&
                    item.departmentFilters.included
                  ) {
                    includeStringDepartment = 'to be included';
                  }
                  if (item.userRoleFilters && item.userRoleFilters.included) {
                    includeStringUserRole = 'to be included';
                  }
                  if (item.userFilters && item.userFilters.included) {
                    includedStringUsers = 'to be included';
                  }
                  addedDepartment = item?.departmentFilters?.departmentID?.map(
                    (id) => {
                      return listAllDepartments?.find((e) => e?.id === id)
                        ?.name;
                    }
                  );

                  return (
                    <>
                      <Row className="scenario-detail-drawer w-100 mb-10">
                        <Col span={24} className="added-filter">
                          <Row>
                            {item?.departmentFilters?.departmentID?.length >
                              0 && (
                              <>
                                <Typography.Text>Department</Typography.Text>
                                <Typography.Text className="ml-5">
                                  {includeStringDepartment} :{' '}
                                </Typography.Text>
                                <Typography.Text
                                  style={
                                    true
                                      ? {
                                          width: 100,
                                        }
                                      : undefined
                                  }
                                  ellipsis={
                                    true
                                      ? {
                                          tooltip:
                                            arrToCommaSeperated(
                                              addedDepartment
                                            ),
                                        }
                                      : false
                                  }
                                  className="ml-5"
                                >
                                  <span className="fw-500">
                                    {arrToCommaSeperated(addedDepartment)}
                                  </span>
                                </Typography.Text>
                              </>
                            )}
                          </Row>
                          <Row>
                            {item?.userRoleFilters?.userROLE?.length > 0 && (
                              <>
                                <Typography.Text>User role</Typography.Text>{' '}
                                <Typography.Text className="ml-5">
                                  {includeStringUserRole} :{' '}
                                </Typography.Text>
                                <Typography.Text
                                  style={
                                    true
                                      ? {
                                          width: 120,
                                        }
                                      : undefined
                                  }
                                  ellipsis={
                                    true
                                      ? {
                                          tooltip:
                                            item?.userRoleFilters?.userROLE?.map(
                                              (role, index) => (
                                                <>
                                                  <span className="fw-500">
                                                    {role === UserRole.sme
                                                      ? capitalizeFirstLetter(
                                                          UserRole?.teamMember
                                                        )
                                                      : capitalizeFirstLetter(
                                                          role
                                                        )}
                                                  </span>{' '}
                                                  {item?.userRoleFilters
                                                    ?.weight && (
                                                    <>
                                                      (W=
                                                      <span className="fw-500">
                                                        {
                                                          item?.userRoleFilters
                                                            ?.weight[index]
                                                        }
                                                      </span>
                                                      )
                                                    </>
                                                  )}
                                                  {index <
                                                    item?.userRoleFilters
                                                      ?.userROLE?.length -
                                                      1 && <span>, </span>}
                                                </>
                                              )
                                            ),
                                        }
                                      : false
                                  }
                                  className="ml-5"
                                >
                                  {item?.userRoleFilters?.userROLE?.map(
                                    (role, index) => (
                                      <>
                                        <span className="fw-500">
                                          {role === UserRole.sme
                                            ? capitalizeFirstLetter(
                                                UserRole?.teamMember
                                              )
                                            : capitalizeFirstLetter(role)}
                                        </span>{' '}
                                        {item?.userRoleFilters?.weight && (
                                          <>
                                            (W=
                                            <span className="fw-500">
                                              {
                                                item?.userRoleFilters?.weight[
                                                  index
                                                ]
                                              }
                                            </span>
                                            )
                                          </>
                                        )}
                                        {index <
                                          item?.userRoleFilters?.userROLE
                                            ?.length -
                                            1 && <span>, </span>}
                                      </>
                                    )
                                  )}
                                </Typography.Text>
                              </>
                            )}
                          </Row>
                          <Row>
                            {item?.userFilters?.userID?.length > 0 && (
                              <>
                                <Typography.Text>Users</Typography.Text>
                                <Typography.Text className="ml-5">
                                  {includedStringUsers} :{' '}
                                </Typography.Text>
                                <Typography.Text
                                  className="ml-5"
                                  style={
                                    true
                                      ? {
                                          width: 140,
                                        }
                                      : undefined
                                  }
                                  ellipsis={
                                    true
                                      ? {
                                          tooltip:
                                            item?.userFilters?.userID?.map(
                                              (name, index) => (
                                                <>
                                                  <span className="fw-500">
                                                    {ownerList?.find(
                                                      (e) => e?.id === name
                                                    )?.firstName +
                                                      ' ' +
                                                      ownerList?.find(
                                                        (e) => e?.id === name
                                                      )?.lastName}{' '}
                                                  </span>
                                                  {item?.userFilters
                                                    ?.weight && (
                                                    <>
                                                      (W=
                                                      <span className="fw-500">
                                                        {
                                                          item?.userFilters
                                                            ?.weight[index]
                                                        }
                                                      </span>
                                                      )
                                                    </>
                                                  )}
                                                  {index <
                                                    item?.userFilters?.userID
                                                      ?.length -
                                                      1 && <span>, </span>}
                                                </>
                                              )
                                            ),
                                        }
                                      : false
                                  }
                                >
                                  {item?.userFilters?.userID?.map(
                                    (name, index) => (
                                      <>
                                        <span className="fw-500">
                                          {ownerList?.find(
                                            (e) => e?.id === name
                                          )?.firstName +
                                            ' ' +
                                            ownerList?.find(
                                              (e) => e?.id === name
                                            )?.lastName}{' '}
                                        </span>
                                        {item?.userFilters?.weight && (
                                          <>
                                            (W=
                                            <span className="fw-500">
                                              {item?.userFilters?.weight[index]}
                                            </span>
                                            )
                                          </>
                                        )}
                                        {index <
                                          item?.userFilters?.userID?.length -
                                            1 && <span>, </span>}
                                      </>
                                    )
                                  )}
                                </Typography.Text>
                              </>
                            )}
                          </Row>
                        </Col>
                      </Row>
                    </>
                  );
                })}
              </Row>
            )}

            {secondScenario?.rankingFilters?.filters?.length > 0 && (
              <Row className=" mt-10" justify="space-between">
                <Col span={7}>
                  <Typography.Text className="idea-text">Ideas</Typography.Text>
                </Col>
                <Col span={17}>
                  <Typography.Text className="gray-text">
                    Show ideas that match
                  </Typography.Text>
                  <Typography.Text className="f-14 ml-5 fw-bold ">
                    {secondScenario?.rankingFilters?.match === 'ALL'
                      ? 'All conditions'
                      : secondScenario?.rankingFilters?.match === 'ONE'
                      ? 'Any conditions'
                      : null}
                  </Typography.Text>
                </Col>
              </Row>
            )}
            {secondScenario?.rankingFilters?.filters?.map((item) => {
              return (
                <Row className="scenario-filter-list scenario-detail-drawer mt-10">
                  <Col
                    span={24}
                    className={
                      item?.isUpdated
                        ? 'added-filter updated-bg mb-10'
                        : item?.isDeleted
                        ? ' added-filter deleted-bg mb-10'
                        : 'added-filter mb-10'
                    }
                  >
                    <Row>
                      <Col span={22}>
                        <Row>
                          <>
                            {item?.isUpdated && (
                              <img src={warningIcon} alt="" className="mr-5" />
                            )}
                            {item?.isDeleted && <WarningOutlined />}
                            <Typography.Text
                              className={
                                item?.isDeleted
                                  ? 'mr-5 deleted-mf-color'
                                  : 'mr-5'
                              }
                            >
                              {item?.field?.name}
                            </Typography.Text>
                            <Typography.Text
                              className={
                                item?.isDeleted ? 'deleted-mf-color' : ''
                              }
                            >
                              {handleFilterValue(item)}
                            </Typography.Text>
                          </>
                        </Row>
                        <Row>
                          <>
                            {item?.threshold !== null && (
                              <Typography.Text
                                className={
                                  item?.isDeleted
                                    ? 'deleted-mf-color mr-5'
                                    : 'mr-5'
                                }
                              >
                                Threshold :{' '}
                                {item?.field?.type === '+-' ? (
                                  item?.field?.breakpoints?.find(
                                    (el) =>
                                      parseInt(el?.value) ===
                                      parseInt(item?.threshold)
                                  )?.label
                                ) : (
                                  <>
                                    {item?.field?.subType === '$' ? '$' : ''}
                                    {item?.threshold}
                                  </>
                                )}
                              </Typography.Text>
                            )}
                            <Typography.Text
                              className={
                                item?.isDeleted ? 'deleted-mf-color' : ''
                              }
                            >
                              Include blanks : {item?.blanks ? 'true' : 'false'}
                            </Typography.Text>
                          </>
                        </Row>
                        {item?.isUpdated && (
                          <>
                            <Divider className="updated-divider" />
                            <Typography.Text>Update required</Typography.Text>
                          </>
                        )}
                        {item?.isDeleted && (
                          <>
                            <Divider className="updated-divider" />
                            <Typography.Text>
                              Field no longer available
                            </Typography.Text>
                          </>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              );
            })}
          </Col>
        ) : (
          <Col span={8} lg={8} md={12} sm={24} xs={24}>
            <div className="empty-box h-100">
              <Select
                onChange={(e) => {
                  handleSecondScenario(e);
                }}
                className="w-100 compare-dropdown"
                placeholder="Select scenario"
              >
                {listPriorityBatch.map((item, index) => {
                  return (
                    <Option value={item?.id} key={index}>
                      {item?.title}
                    </Option>
                  );
                })}
              </Select>
              {secondScenarioLoader ? (
                <Skeleton active className="mt-10" />
              ) : (
                <Row>
                  <div className="empty-card">
                    <img src={noScenarioIcon} alt="" />
                    <Typography.Title level={5}>
                      Please select the scenario <br />
                      for comparison
                    </Typography.Title>
                  </div>
                </Row>
              )}
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
}
