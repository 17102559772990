import { Button, Modal, Row, Typography } from 'antd';
import { ECHOIMAGES } from 'Assets/Images';
import React, { memo } from 'react';
import KudosAnimation from './KudosAnimation';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setModalTrue } from 'store/slices/compareSlice';
import { formatTime } from 'utils/CommonFunctions';

const KudosModal = ({
  isKudosModal,
  totalSeconds,
  selectedIndex,
  setIsKudosModal,
  setIsTimerPaused,
}) => {
  const { KudosIcon } = ECHOIMAGES.compareIcons;
  const history = useHistory();
  const dispatch = useDispatch();

  const handleContinue = async () => {
    dispatch(setModalTrue(true));
    setIsTimerPaused(false);
    setIsKudosModal(false);
  };

  const handleCancel = () => {
    setIsKudosModal(false);
    dispatch(setModalTrue(true));
    if (history.location.pathname === '/compare') {
      history.push('/');
    }
  };

  return (
    <>
      <Modal
        open={isKudosModal}
        className="kudos-modal"
        maskClosable={true}
        closable={history.location.pathname === '/compare' ? false : true}
        onCancel={handleCancel}
        centered={true}
        footer={[
          <>
            {history.location.pathname === '/compare' && (
              <>
                <Button key="back" onClick={handleCancel} size="middle">
                  Done
                </Button>
                <Button
                  key="submit"
                  type="primary"
                  onClick={handleContinue}
                  size="middle"
                >
                  Continue
                </Button>
              </>
            )}
          </>,
        ]}
      >
        <div className="kudos-animation">
          <KudosAnimation />
        </div>
        <Row justify="center" align="middle">
          <img src={KudosIcon} alt="" />
        </Row>
        <Row justify="center">
          <Typography.Title level={3} className="mt-10">
            Kudos for Your Outstanding Work!
          </Typography.Title>
        </Row>
        <Row justify="center">
          <Typography.Text level={3} className="fs-16">
            Comparisons completed : {selectedIndex}
          </Typography.Text>
          <Typography.Text level={3} className="fs-16 ml-20 mb-15">
            Time : {formatTime(totalSeconds)}
          </Typography.Text>
        </Row>
      </Modal>
    </>
  );
};

export default memo(KudosModal);

