import { message } from 'antd';
import { ECHOIMAGES } from 'Assets/Images';
import CustomDeleteModal from 'Common/CustomDeleteModal';
import React, { memo, useState } from 'react';
import { deleteStageCustom } from '../Services/StageActions';

const DeleteStage = ({
  openDeleteModal,
  setOpenDeleteModal,
  stageID,
  listAllStagesByOrg,
}) => {
  const { deleteModalIcon } = ECHOIMAGES.admin;
  const handleCancelModal = () => {
    setOpenDeleteModal(false);
  };

  const [loading, setLoading] = useState(false);

  const deleteStage = async () => {
    setLoading(true);
    try {
      let inputData = {
        id: stageID,
      };
      await deleteStageCustom(inputData);
      setOpenDeleteModal(false);
      await listAllStagesByOrg();
      message.success('Stage deleted successfully');
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };
  return (
    <CustomDeleteModal
      open={openDeleteModal}
      onOk={deleteStage}
      onCancel={handleCancelModal}
      confirmLoading={loading}
      okText="Delete"
      title="Are you sure that you want to delete this stage?"
      text="This action can not be undone"
    >
      <img src={deleteModalIcon} alt="" />
    </CustomDeleteModal>
  );
};

export default memo(DeleteStage);

