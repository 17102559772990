import React, { memo } from 'react';
import { Select, Space } from 'antd';
import { sortArrayByDate, sortArrayByProperty } from 'utils/commonMethods';
import Typography from 'antd/es/typography/Typography';

const ScenarioSorting = ({
  listPriorityBatch,
  setListPriorityBatch,
  listAddedPriorityBatch,
}) => {
  const firstRow = listPriorityBatch[0];
  const restOfData = listPriorityBatch.slice(1);
  // sort by oldest date
  const sortByOldest = () => {
    const sortedData = sortArrayByDate(restOfData, 'createdAt', true);
    sortedData.unshift(firstRow);
    setListPriorityBatch(sortedData);
  };

  // sort by newest date
  const sortByNewest = () => {
    const sortedData = sortArrayByDate(restOfData, 'createdAt', false);
    sortedData.unshift(firstRow);
    setListPriorityBatch(sortedData);
  };

  // for ascending data by title
  const sortAscending = () => {
    const sortedData = sortArrayByProperty(restOfData, 'title', true);
    sortedData.unshift(firstRow);
    setListPriorityBatch(sortedData);
  };

  // for descending data by title
  const sortDescending = () => {
    const sortedData = sortArrayByProperty(restOfData, 'title', false);
    sortedData.unshift(firstRow);
    setListPriorityBatch(sortedData);
  };

  const showAllData = async () => {
    await listAddedPriorityBatch();
    // setListPriorityBatch(listPriorityBatch);
  };
  return (
    <>
      <Typography.Text className="d-flex m-auto">Sort by</Typography.Text>
      <Select
        placeholder="Select sort"
        className="h-35 ml-10 sorting-common"
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
        defaultValue="None"
        options={[
          {
            value: 'None',
            label: <Space onClick={showAllData}>None</Space>,
          },
          {
            value: 'Created date - Oldest',
            label: <Space onClick={sortByOldest}>Created date - Oldest</Space>,
          },
          {
            value: 'Created date - Newest',
            label: <Space onClick={sortByNewest}>Created date - Newest</Space>,
          },
          {
            value: 'Title - A to Z',
            label: <Space onClick={sortAscending}>Title - A to Z</Space>,
          },
          {
            value: 'Title - Z to A',
            label: <Space onClick={sortDescending}>Title - Z to A</Space>,
          },
        ]}
      />
    </>
  );
};

export default memo(ScenarioSorting);

