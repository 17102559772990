import { Button, Carousel, Col, Divider, Row } from 'antd';
import { ECHOIMAGES } from 'Assets/Images';
import React, { memo, useRef, useState } from 'react';
import image from 'Assets/Integration/image.jpg';
import image4 from 'Assets/Integration/image4.png';
import image7 from 'Assets/Integration/image7.png';

const IntegrationSteps = () => {
  const slider4 = useRef(null);

  const [zapierSlider, setZapierSlider] = useState(0);
  const { step1Icon, step5Icon, step7Icon } = ECHOIMAGES.integration;

  return (
    <div className="background-white mt-10">
      <>
        <Row
          justify="center"
          style={{
            margin: '30px 30px',
            borderRadius: '8px',
          }}
        >
          <Col className="zapier-slider">
            <Carousel dots={false} ref={slider4} adaptiveHeight={true}>
              <div className="zapier-slide">
                <div className="slide-center-container">
                  <div className="zapier-slide-text-container">
                    <div className="zapier-slide-text-title">
                      Step 1: Setup an Account
                    </div>
                    <div className="zapier-slide-text">
                      Please login to your Zapier account and click on the
                      "Create Zap" button.
                    </div>
                  </div>
                  <img src={step1Icon} alt="whatsappSlide1" />
                </div>
              </div>
              <div className="zapier-slide">
                <div className="slide-center-container">
                  <div className="zapier-slide-text-container">
                    <div className="zapier-slide-text-title">
                      Step 2: Trigger App
                    </div>
                    <div className="zapier-slide-text">
                      Please select the appropriate "Trigger App" from the list
                      and carry out the next steps to setup the Events. Then
                      test the Trigger.
                    </div>
                  </div>
                  <img src={image} alt="whatsappSlide1" className="w-100" />
                </div>
              </div>
              <div className="zapier-slide">
                <div className="slide-center-container">
                  <div className="zapier-slide-text-container">
                    <div className="zapier-slide-text-title">
                      Step 3: Select StrAlign
                    </div>
                    <div className="zapier-slide-text">
                      Select StrAlign app from the list and select it's event.
                    </div>
                  </div>
                  <img src={image} alt="whatsappSlide1" className="w-100" />
                </div>
              </div>
              <div className="zapier-slide">
                <div className="slide-center-container">
                  <div className="zapier-slide-text-container">
                    <div className="zapier-slide-text-title">
                      Step 4: Connect your StrAlign Account.
                    </div>
                    <div className="zapier-slide-text">
                      This is where you can connect your StrAlign account.
                    </div>
                  </div>
                  <img src={image4} alt="whatsappSlide1" className="w-100" />
                </div>
              </div>
              <div className="zapier-slide">
                <div className="slide-center-container">
                  <div className="zapier-slide-text-container">
                    <div className="zapier-slide-text-title">
                      Step 5: Enter your secret key.
                    </div>
                    <div className="zapier-slide-text">
                      Enter the secret key to access your stralign account.
                    </div>
                  </div>
                  <img src={step5Icon} alt="whatsappSlide1" />
                </div>
              </div>

              <div className="zapier-slide">
                <div className="slide-center-container">
                  <div className="zapier-slide-text-container">
                    <div className="zapier-slide-text-title">
                      Step 6: Fill Action Fields
                    </div>
                    <div className="zapier-slide-text">
                      Configure the Action Fields and Test it.
                    </div>
                  </div>
                  <img src={image7} alt="whatsappSlide1" className="w-100" />
                </div>
              </div>
              <div className="zapier-slide">
                <div className="slide-center-container">
                  <div className="zapier-slide-text-container">
                    <div className="zapier-slide-text-title">
                      Step 7: Publish Zap
                    </div>
                    <div className="zapier-slide-text">
                      Finally, click on the "Publish" button to publish it.
                    </div>
                  </div>
                  <img src={step7Icon} alt="whatsappSlide1" />
                </div>
              </div>
            </Carousel>
            <div className="zapier-slider-controls">
              <Button
                className={`slider-button ${
                  zapierSlider === 0 ? 'current-slide' : ''
                }`}
                onClick={() => {
                  setZapierSlider(0);
                  slider4.current.goTo(0);
                }}
              >
                1
              </Button>
              <Button
                className={`slider-button ${
                  zapierSlider === 1 ? 'current-slide' : ''
                }`}
                onClick={() => {
                  setZapierSlider(1);
                  slider4.current.goTo(1);
                }}
              >
                2
              </Button>
              <Button
                className={`slider-button ${
                  zapierSlider === 2 ? 'current-slide' : ''
                }`}
                onClick={() => {
                  setZapierSlider(2);
                  slider4.current.goTo(2);
                }}
              >
                3
              </Button>
              <Button
                className={`slider-button ${
                  zapierSlider === 3 ? 'current-slide' : ''
                }`}
                onClick={() => {
                  setZapierSlider(3);
                  slider4.current.goTo(3);
                }}
              >
                4
              </Button>
              <Button
                className={`slider-button ${
                  zapierSlider === 4 ? 'current-slide' : ''
                }`}
                onClick={() => {
                  setZapierSlider(4);
                  slider4.current.goTo(4);
                }}
              >
                5
              </Button>
              <Button
                className={`slider-button ${
                  zapierSlider === 5 ? 'current-slide' : ''
                }`}
                onClick={() => {
                  setZapierSlider(5);
                  slider4.current.goTo(5);
                }}
              >
                6
              </Button>
              <Button
                className={`slider-button ${
                  zapierSlider === 6 ? 'current-slide' : ''
                }`}
                onClick={() => {
                  setZapierSlider(6);
                  slider4.current.goTo(6);
                }}
              >
                7
              </Button>
            </div>
          </Col>
        </Row>
      </>
    </div>
  );
};

export default memo(IntegrationSteps);

