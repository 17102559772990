export const updateUserCustom = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
    }
  }
`;

export const createGammaCustom = /* GraphQL */ `
  mutation CreateGamma(
    $input: CreateGammaInput!
    $condition: ModelGammaConditionInput
  ) {
    createGamma(input: $input, condition: $condition) {
      id
      title
      description
      userID
      organizationID
    }
  }
`;

export const createGammaExtendedCustom = /* GraphQL */ `
  mutation CreateGammaExtended($input: CreateGammaExtendedInput!) {
    createGammaExtended(input: $input) {
      title
      id
    }
  }
`;

export const createLinkedGammasCustom = /* GraphQL */ `
  mutation CreateLinkedGammas(
    $input: CreateLinkedGammasInput!
    $condition: ModelLinkedGammasConditionInput
  ) {
    createLinkedGammas(input: $input, condition: $condition) {
      createdAt
      updatedAt
      gammaLinkedToId
      linkedGammasLinkedGammaId
    }
  }
`;

export const createAttachmentCustom = /* GraphQL */ `
  mutation CreateAttachment(
    $input: CreateAttachmentInput!
    $condition: ModelAttachmentConditionInput
  ) {
    createAttachment(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createUploadedFileCustom = /* GraphQL */ `
  mutation CreateUploadedFile(
    $input: CreateUploadedFileInput!
    $condition: ModelUploadedFileConditionInput
  ) {
    createUploadedFile(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createGammasAndContributorsCustom = /* GraphQL */ `
  mutation CreateGammasAndContributors(
    $input: CreateGammasAndContributorsInput!
    $condition: ModelGammasAndContributorsConditionInput
  ) {
    createGammasAndContributors(input: $input, condition: $condition) {
      userId
      gammaId
    }
  }
`;

export const deleteGammasAndContributorsCustom = /* GraphQL */ `
  mutation DeleteGammasAndContributors(
    $input: DeleteGammasAndContributorsInput!
    $condition: ModelGammasAndContributorsConditionInput
  ) {
    deleteGammasAndContributors(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createHiddenUsersAndHiddenGammasCustom = /* GraphQL */ `
  mutation CreateHiddenUsersAndHiddenGammas(
    $input: CreateHiddenUsersAndHiddenGammasInput!
    $condition: ModelHiddenUsersAndHiddenGammasConditionInput
  ) {
    createHiddenUsersAndHiddenGammas(input: $input, condition: $condition) {
      id
      userId
      gammaId
    }
  }
`;
export const deleteHiddenUsersAndHiddenGammasCustom = /* GraphQL */ `
  mutation DeleteHiddenUsersAndHiddenGammas(
    $input: DeleteHiddenUsersAndHiddenGammasInput!
    $condition: ModelHiddenUsersAndHiddenGammasConditionInput
  ) {
    deleteHiddenUsersAndHiddenGammas(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createObjectiveCustom = /* GraphQL */ `
  mutation CreateObjective(
    $input: CreateObjectiveInput!
    $condition: ModelObjectiveConditionInput
  ) {
    createObjective(input: $input, condition: $condition) {
      id
    }
  }
`;
export const createUserRatingCustom = /* GraphQL */ `
  mutation CreateUserRating(
    $input: CreateUserRatingInput!
    $condition: ModelUserRatingConditionInput
  ) {
    createUserRating(input: $input, condition: $condition) {
      gammaID
      rating
      userID
      userRatingObjectiveId
    }
  }
`;

export const updateUserRatingCustom = /* GraphQL */ `
  mutation UpdateUserRating(
    $input: UpdateUserRatingInput!
    $condition: ModelUserRatingConditionInput
  ) {
    updateUserRating(input: $input, condition: $condition) {
      id
    }
  }
`;

export const deleteUserRatingCustom = /* GraphQL */ `
  mutation DeleteUserRating(
    $input: DeleteUserRatingInput!
    $condition: ModelUserRatingConditionInput
  ) {
    deleteUserRating(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createPriorityBatchCustom = /* GraphQL */ `
  mutation CreatePriorityBatch(
    $input: CreatePriorityBatchInput!
    $condition: ModelPriorityBatchConditionInput
  ) {
    createPriorityBatch(input: $input, condition: $condition) {
      id
    }
  }
`;
export const createPriorityBatchesAndUsersCustom = /* GraphQL */ `
  mutation CreatePriorityBatchesAndUsers(
    $input: CreatePriorityBatchesAndUsersInput!
    $condition: ModelPriorityBatchesAndUsersConditionInput
  ) {
    createPriorityBatchesAndUsers(input: $input, condition: $condition) {
      id
    }
  }
`;
export const deletePriorityBatchesAndUsersCustom = /* GraphQL */ `
  mutation DeletePriorityBatchesAndUsersExtended(
    $input: [DeletePriorityBatchesAndUsersInput!]
  ) {
    deletePriorityBatchesAndUsersExtended(input: $input) {
      id
    }
  }
`;
export const updatePriorityBatchCustom = /* GraphQL */ `
  mutation UpdatePriorityBatch(
    $input: UpdatePriorityBatchInput!
    $condition: ModelPriorityBatchConditionInput
  ) {
    updatePriorityBatch(input: $input, condition: $condition) {
      id
    }
  }
`;

export const deletePriorityBatchCustom = /* GraphQL */ `
  mutation DeletePriorityBatch(
    $input: DeletePriorityBatchInput!
    $condition: ModelPriorityBatchConditionInput
  ) {
    deletePriorityBatch(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updateGammaCustom = /* GraphQL */ `
  mutation UpdateGamma(
    $input: UpdateGammaInput!
    $condition: ModelGammaConditionInput
  ) {
    updateGamma(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updateNotificationCustom = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updateUserSettingCustom = /* GraphQL */ `
  mutation UpdateOrganization(
    $input: UpdateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    updateOrganization(input: $input, condition: $condition) {
      id
      organizationDefaultPrioirtybatchId
    }
  }
`;

export const createDepartmentUserCustom = /* GraphQL */ `
  mutation CreateDepartment(
    $input: CreateDepartmentInput!
    $condition: ModelDepartmentConditionInput
  ) {
    createDepartment(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updateDepartmentUserCustom = /* GraphQL */ `
  mutation UpdateDepartment(
    $input: UpdateDepartmentInput!
    $condition: ModelDepartmentConditionInput
  ) {
    updateDepartment(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createLevelChangePromoteCustom = /* GraphQL */ `
  mutation CreateLevelChange(
    $input: CreateLevelChangeInput!
    $condition: ModelLevelChangeConditionInput
  ) {
    createLevelChange(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createDepartmentGammaCustom = /* GraphQL */ `
  mutation CreateDepartmentGamma(
    $input: CreateDepartmentGammaInput!
    $condition: ModelDepartmentGammaConditionInput
  ) {
    createDepartmentGamma(input: $input, condition: $condition) {
      id
    }
  }
`;

export const deleteDepartmentGammaCustom = /* GraphQL */ `
  mutation DeleteDepartmentGamma(
    $input: DeleteDepartmentGammaInput!
    $condition: ModelDepartmentGammaConditionInput
  ) {
    deleteDepartmentGamma(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updateObjectiveCustom = /* GraphQL */ `
  mutation UpdateObjective(
    $input: UpdateObjectiveInput!
    $condition: ModelObjectiveConditionInput
  ) {
    updateObjective(input: $input, condition: $condition) {
      id
    }
  }
`;

export const deleteLinkedGammasCustom = /* GraphQL */ `
  mutation DeleteLinkedGammas(
    $input: DeleteLinkedGammasInput!
    $condition: ModelLinkedGammasConditionInput
  ) {
    deleteLinkedGammas(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updateOrganizationCustom = /* GraphQL */ `
  mutation UpdateOrganization(
    $input: UpdateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    updateOrganization(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createScoreCalculationStatusCustom = /* GraphQL */ `
  mutation CreateScoreCalculationStatus(
    $input: CreateScoreCalculationStatusInput!
    $condition: ModelScoreCalculationStatusConditionInput
  ) {
    createScoreCalculationStatus(input: $input, condition: $condition) {
      organizationID
    }
  }
`;

export const createObjectivesAndDepartmentsCustom = /* GraphQL */ `
  mutation CreateObjectivesAndDepartments(
    $input: CreateObjectivesAndDepartmentsInput!
    $condition: ModelObjectivesAndDepartmentsConditionInput
  ) {
    createObjectivesAndDepartments(input: $input, condition: $condition) {
      id
    }
  }
`;

export const deleteObjectivesAndDepartmentsCustom = /* GraphQL */ `
  mutation DeleteObjectivesAndDepartments(
    $input: DeleteObjectivesAndDepartmentsInput!
    $condition: ModelObjectivesAndDepartmentsConditionInput
  ) {
    deleteObjectivesAndDepartments(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createStageCustom = /* GraphQL */ `
  mutation CreateStage(
    $input: CreateStageInput!
    $condition: ModelStageConditionInput
  ) {
    createStage(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updateStageCustom = /* GraphQL */ `
  mutation UpdateStage(
    $input: UpdateStageInput!
    $condition: ModelStageConditionInput
  ) {
    updateStage(input: $input, condition: $condition) {
      id
      name
      level
      form {
        layout
        __typename
      }
    }
  }
`;

export const deleteStageCustom = /* GraphQL */ `
  mutation DeleteStage(
    $input: DeleteStageInput!
    $condition: ModelStageConditionInput
  ) {
    deleteStage(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const createFormDraftCustom = /* GraphQL */ `
  mutation CreateFormDraft(
    $input: CreateFormDraftInput!
    $condition: ModelFormDraftConditionInput
  ) {
    createFormDraft(input: $input, condition: $condition) {
      stageID
      version
    }
  }
`;
export const updateFormDraftCustom = /* GraphQL */ `
  mutation UpdateFormDraft(
    $input: UpdateFormDraftInput!
    $condition: ModelFormDraftConditionInput
  ) {
    updateFormDraft(input: $input, condition: $condition) {
      stageID
      version
    }
  }
`;

export const createMeasurementFieldCustom = /* GraphQL */ `
  mutation CreateMeasurementField(
    $input: CreateMeasurementFieldInput!
    $condition: ModelMeasurementFieldConditionInput
  ) {
    createMeasurementField(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updateMeasurementFieldCustom = /* GraphQL */ `
  mutation UpdateMeasurementField(
    $input: UpdateMeasurementFieldInput!
    $condition: ModelMeasurementFieldConditionInput
  ) {
    updateMeasurementField(input: $input, condition: $condition) {
      id
    }
  }
`;
export const deleteMeasurementFieldCustom = /* GraphQL */ `
  mutation DeleteMeasurementField(
    $input: DeleteMeasurementFieldInput!
    $condition: ModelMeasurementFieldConditionInput
  ) {
    deleteMeasurementField(input: $input, condition: $condition) {
      id
    }
  }
`;

