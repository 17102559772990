import { Modal, Radio, Space, Typography, message } from 'antd';
import React, { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getStageLevel } from 'store/slices/StageSlice';
import { capitalizeFirstLetter } from 'utils/Constants';

const RequiredFiledModal = ({
  requiredFieldModalOpen,
  handleUpdateFormData,
  setRequiredFieldModalOpen,
  stageLevel,
  getStageNameElement,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const getStages = useSelector(getStageLevel);

  const handleRadioChange = (e) => {
    setSelectedOption(e.target.value);
  };
  const handleSave = () => {
    if (selectedOption === null) {
      message.error('Please select an option.');
      return;
    }
    handleUpdateFormData('', selectedOption);
    setRequiredFieldModalOpen(false);
  };
  const handleCancel = () => {
    setRequiredFieldModalOpen(false);
  };

  return (
    <>
      <Modal
        closeIcon={false}
        open={requiredFieldModalOpen}
        className="publish-icon confirm-modal"
        okText="Save"
        centered={true}
        onOk={handleSave}
        onCancel={handleCancel}
      >
        <Typography.Text className="fs-16  fw-bold">
          Apply Changes To :
        </Typography.Text>{' '}
        <br />
        <Radio.Group
          onChange={handleRadioChange}
          value={selectedOption}
          className="mt-10"
        >
          <Space direction="vertical">
            <Radio value="local">
              {capitalizeFirstLetter(getStageNameElement?.name)} Stage
            </Radio>
            {getStages?.length !== stageLevel && (
              <Radio value="incremental">Current and Subsequent Stages</Radio>
            )}
            <Radio value="all">All Stages</Radio>
          </Space>
        </Radio.Group>
      </Modal>
    </>
  );
};

export default memo(RequiredFiledModal);

