import { API, Auth } from 'aws-amplify';
import * as queriesdefault from '../graphql/queries';
import * as queriesCustom from '../graphql/customQueris';
import * as mutationsdefault from '../graphql/mutations';
import * as mutationsCustom from '../graphql/customMutation';

const queries = { ...queriesdefault, ...queriesCustom };
const mutations = { ...mutationsdefault, ...mutationsCustom };

// Global functions created to create functions dynamically and make it reusable
export const ExecuteQuery = (
  query,
  inputData,
  primarObj = {},
  filter,
  nextToken,
  limit,
  sort
) => {
  return ((input = inputData) => {
    return API.graphql({
      query: queries[query],
      variables: { input, filter, nextToken, ...primarObj, limit, sort },
    });
  })()
    .then((res) => {
      query = fetchQueryName(query);
      return res?.data?.[query] || res;
    })
    .catch((err) => {
      console.log(err);

      if (err.message && err.message.includes('No current user')) {
        Auth.signOut({
          global: false,
        })
          .then(() => {
            localStorage.clear();
            window.location.reload();
          })
          .catch((err) => console.log(err));
      }
      return err;
    });
};

export const ExecuteMutation = (query, inputData, filter) => {
  return ((input = inputData) => {
    return API.graphql({
      query: mutations[query],
      variables: { input, filter },
    });
  })()
    .then((res) => {
      query = fetchQueryName(query);
      return res?.data?.[query] || res;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const ExecuteMutationLambda = (query, inputData, filter, authToken) => {
  return ((input = inputData) => {
    return API.graphql({
      query: mutations[query],
      variables: { input, filter },
      authMode: 'AWS_LAMBDA',
      authToken: authToken,
    });
  })()
    .then((res) => {
      query = fetchQueryName(query);
      return res?.data?.[query] || res;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

/**
 *
 * @param {*} query
 * @param {*} input
 * @param {*} id
 * @param {*} filter
 * @param {*} nextTokenParam
 * @param {*} data
 * @param {*} limit
 * @returns
 */
export const ExecuteQueryCustom = async (
  query,
  input,
  id,
  filter,
  nextTokenParam,
  data,
  limit
) => {
  let list = data || [];
  let nextToken = nextTokenParam;
  do {
    const res = await API.graphql({
      query: queries[query],
      variables: { input, filter, nextToken, ...id, limit },
    }).catch((e) => {
      console.log('Error:', e);
    });
    let customQueryName;
    if (String(query).endsWith('Custom')) {
      customQueryName = query.split('Custom')[0];
    }
    if (String(query).endsWith('ForCount')) {
      customQueryName = query.split('ForCount')[0];
    }
    nextToken = res?.data?.[customQueryName || query]?.nextToken;
    list = [...list, ...res?.data?.[customQueryName || query]?.items];
    if (!nextToken) {
      return list;
    }
  } while (nextToken);
};

export const ExecuteQueryDefault = async (
  query,
  fixedRank,
  organizationID,
  filter,
  data,
  limit
) => {
  return API.graphql({
    query: queries[query],
    variables: { fixedRank, organizationID, filter, data, limit },
  })
    .then((res) => {
      query = fetchQueryName(query);
      return res?.data?.[query] || res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

function fetchQueryName(query) {
  let customQueryName = query;
  if (String(query).endsWith('Custom')) {
    customQueryName = query.split('Custom')[0];
  }
  if (String(query).endsWith('ForCount')) {
    customQueryName = query.split('ForCount')[0];
  }
  return customQueryName;
}
