import { Select, Space, Typography } from 'antd';
import React, { memo, useState } from 'react';
import { RESTGetRankingFilter } from 'utils/RESTApi';
import { useSelector } from 'react-redux';
import {
  getCurrentOrganization,
  getCurrnetUserData,
} from 'store/slices/loginSlice';
import { getDefaultBatchID } from 'store/slices/compareSlice';
import dayjs from 'dayjs';

const RankingSort = ({
  setDashboardData,
  setSortTableColumn,
  setSortTableOrder,
  sortTableColumn,
  sortTableOrder,
  setCurrentPage,
  currentPage,
  rankingFilter,
  selectedDates,
  setYearlyBackupRankData,
  setGraphData,
  selectedDays,
  selectedTopItems,
  searchText,
  showAll,
}) => {
  const currentOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const orgId = currentOrg?.id || loginUsrOrg?.organizationID;
  const userId = currentOrg?.adminID || loginUsrOrg?.id;

  const priorityDefaultBatchID =
    currentOrg?.defaultPrioirtybatch?.id ||
    loginUsrOrg?.Organization?.organizationDefaultPrioirtybatchId;

  const fetchDefaultBatchID = useSelector(getDefaultBatchID);
  let defaultPriorityBatchID = fetchDefaultBatchID
    ? fetchDefaultBatchID
    : priorityDefaultBatchID;
  const [nextTokenData, setNextToken] = useState(null);
  // const [currentPage, setCurrentPage] = useState(1);

  const handleSort = async (column, order, page) => {
    let tempPage = page || currentPage;
    let allData = [];
    let nextToken = nextTokenData;
    let sort_direction = sortTableOrder;
    let sort_field = sortTableColumn;
    if (column && order) {
      sort_direction = order;
      sort_field = column;
    }
    let getStartDateFromLS = JSON.parse(
      localStorage.getItem('selectedDate')
    )?.[0]?.split('T')[0];
    let getEndDateFromLS = JSON.parse(
      localStorage.getItem('selectedDate')
    )?.[1]?.split('T')[0];
    const lastSixWeekStart = dayjs()
      .subtract(6, 'week')
      .startOf('week')
      .add(6, 'days')
      .format('YYYY-MM-DD');
    const lastSixWeekEnd = dayjs().format('YYYY-MM-DD');
    let chart = {
      Top: selectedTopItems ? selectedTopItems : '10',
      Calender: [
        getStartDateFromLS
          ? getStartDateFromLS
          : selectedDates?.[0]?.format('YYYY-MM-DD')
          ? selectedDates?.[0]?.format('YYYY-MM-DD')
          : lastSixWeekStart,
        getEndDateFromLS
          ? getEndDateFromLS
          : selectedDates?.[1]?.format('YYYY-MM-DD')
          ? selectedDates?.[1]?.format('YYYY-MM-DD')
          : lastSixWeekEnd,
      ],
      Interval: localStorage.getItem('days')
        ? localStorage.getItem('days') === '1 Day'
          ? 'day'
          : localStorage.getItem('days') === '1 Week'
          ? 'week'
          : localStorage.getItem('days') === '1 Month'
          ? 'month'
          : selectedDays === '1 Day'
          ? 'day'
          : selectedDays === '1 Week'
          ? 'week'
          : selectedDays === '1 Month'
          ? 'month'
          : 'week'
        : 'week',
    };
    // eslint-disable-next-line max-len
    let rankingApi = `/rankings?id=${orgId}&priorityBatchID=${defaultPriorityBatchID}&userID=${userId}&page=${
      selectedTopItems === -1 ? -1 : tempPage
    }&sort_direction=${sort_direction}&sort_field=${sort_field}&show_all=${showAll}`;
    if (nextToken) {
      rankingApi += `&nextToken=${nextToken}`;
    }
    if (searchText !== undefined && searchText !== '') {
      rankingApi += `&search=${searchText}`;
    }
    try {
      const res = await RESTGetRankingFilter(rankingApi, rankingFilter, chart);
      const temp = res?.body?.items.map((item, index) => ({
        ...item?.Gamma,
        chart: {
          [item?.Gamma.id]: item?.Gamma?.rankHistory,
        },
        rank:
          item?.Gamma?.fixedRank > 0
            ? item?.Gamma?.fixedRank
            : item?.Gamma?.rank,
        isRank: item.isRanked,
      }));
      setNextToken(res?.body?.nextToken);
      allData = [...allData, ...temp];
      const chartData = allData.reduce((acc, curr) => {
        return { ...acc, ...curr.chart };
      }, {});
      setGraphData(chartData);
      setYearlyBackupRankData(chartData);
      setDashboardData(allData);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Typography.Text>Sort by</Typography.Text>
      <Select
        placeholder="Select sort"
        className="h-35 ml-10 sorting-common"
        dropdownMatchSelectWidth={false}
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
        onChange={(value) => {
          const [column, order] = value.split(':');
          setSortTableColumn(column);
          setSortTableOrder(order);
          setCurrentPage(1);
          handleSort(column, order, 1);
        }}
        defaultValue="rank:ASC"
      >
        <Select.Option value="rank:ASC">
          {' '}
          <Space>Rank - High to Low</Space>
        </Select.Option>
        <Select.Option value="rank:DESC">
          <Space>Rank - Low to High</Space>{' '}
        </Select.Option>
        <Select.Option value="createdAt:ASC">
          <Space>Created date - Oldest</Space>
        </Select.Option>
        <Select.Option value="createdAt:DESC">
          <Space>Created date - Newest</Space>
        </Select.Option>
        <Select.Option value="title:ASC">
          {' '}
          <Space>Title - A to Z</Space>{' '}
        </Select.Option>
        <Select.Option value="title:DESC">
          {' '}
          <Space>Title - Z to A</Space>{' '}
        </Select.Option>
      </Select>
    </>
  );
};

export default memo(RankingSort);

