import {
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Grid,
  Radio,
  Row,
  Switch,
  Typography,
} from 'antd';
import React, { memo, useState } from 'react';
import { filterDataByTimeRange } from 'utils/commonMethods';

const ScenarioFilter = ({
  listPriorityBatchBackup,
  setListPriorityBatch,
  setOpen,
  open,
  userId,
}) => {
  const { RangePicker } = DatePicker;

  const [ownerFilter, setOwnerFilter] = useState([]);
  const [timeRange, setTimeRange] = useState('');
  const [customRange, setCustomRange] = useState();
  const [selectAll, setSelectAll] = useState(false);
  const [goalAddedByOwner, setGoalAddedByOwner] = useState(false);

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const onClose = () => {
    setOpen(false);
  };

  const onFilterApply = () => {
    let temp = [];
    if (goalAddedByOwner) {
      const tempArray = [];
      const batchOwner = listPriorityBatchBackup.map((item) => {
        if (item?.owners.filter((el) => el?.id === userId)?.length) {
          return tempArray.push(item);
        }
        return item;
      });
      tempArray.map((item) => {
        if (ownerFilter.length) {
          const data = item?.owners.map((el) => {
            return ownerFilter.filter((e) => e.includes(el?.id));
          });
          if (data.length) {
            temp.push(item);
          }
        } else {
          temp.push(item);
        }
        return temp;
      });
      if (!temp.length) {
        temp = batchOwner;
      }
    } else {
      listPriorityBatchBackup.map((item) => {
        if (ownerFilter.length) {
          const data = item?.owners?.map((el) => {
            return ownerFilter.filter((e) => e?.includes(el?.id))?.[0];
          });
          if (data.filter((vl) => vl !== undefined).length) {
            temp.push(item);
          }
        }

        return temp;
      });
    }
    const filterDate = filterDataByTimeRange(
      timeRange,
      temp.length ? temp : listPriorityBatchBackup,
      customRange
    );
    setOpen(false);
    setListPriorityBatch(filterDate);
  };

  const uniqueFirstNames = [];
  const uniqueLastNames = [];
  const ownerData = [];

  listPriorityBatchBackup?.filter((item) =>
    item?.owners?.map((e) => {
      if (
        !uniqueFirstNames.includes(e?.firstName) &&
        !uniqueLastNames.includes(e?.lastName)
      ) {
        ownerData.push(e);
        uniqueFirstNames.push(e?.firstName);
        uniqueLastNames.push(e?.lastName);
        return true;
      }
      return false;
    })
  );

  return (
    <>
      <Drawer
        className="related-item-drawer brawer-with-btn"
        title={
          <Row justify="space-between" align="middle">
            <Typography.Title level={3} className="mb-0 ">
              Filters
            </Typography.Title>
            <Button
              type="text"
              className="text-blue fs-14 p-0"
              onClick={() => {
                setOwnerFilter([]);
                setTimeRange('');
                setSelectAll(false);
              }}
            >
              <strong>Clear All</strong>
            </Button>
          </Row>
        }
        closeIcon={null}
        closable={false}
        height={screens.xs ? '83vh' : ''}
        placement={screens.xs ? 'bottom' : 'right'}
        onClose={onClose}
        open={open}
      >
        <div>
          <Row justify="space-between" align="middle">
            <Typography.Title
              level={5}
              className="mb-0 fw-400 fs-14 text-black"
            >
              Scenarios added by me
            </Typography.Title>
            <Switch
              checked={goalAddedByOwner}
              onChange={(e) => setGoalAddedByOwner(e)}
              size="small"
            />
          </Row>

          <Form className="mt-20">
            <div className="mb-20">
              <Row justify="space-between" align="middle">
                <Typography.Title level={5} className="mb-0 text-primary">
                  Owner
                </Typography.Title>
                <Checkbox
                  className="select-all"
                  checked={selectAll}
                  onChange={(e) => {
                    setOwnerFilter(
                      e.target.checked ? ownerData.map((data) => data?.id) : []
                    );
                    setSelectAll(e.target.checked);
                  }}
                >
                  Select All
                </Checkbox>
              </Row>
              <Divider className="mt-10 mb-10" />

              <Checkbox.Group
                className="vertical-box"
                onChange={(e) => setOwnerFilter(e)}
                value={ownerFilter}
              >
                {ownerData.map((item, index) => {
                  return (
                    <Checkbox key={index} value={item?.id}>
                      {item?.firstName + ' ' + item?.lastName}
                    </Checkbox>
                  );
                })}
              </Checkbox.Group>
            </div>

            <div className="mb-20">
              <Row justify="space-between" align="middle">
                <Typography.Title level={5} className="mb-0 text-primary">
                  Created date
                </Typography.Title>
              </Row>
              <Divider className="mt-10 mb-10" />

              <Radio.Group className="vertical-box">
                <Radio
                  value="today"
                  checked={timeRange === 'today'}
                  onChange={() => setTimeRange('today')}
                >
                  Today
                </Radio>
                <Radio
                  value="last7days"
                  checked={timeRange === 'last7days'}
                  onChange={() => setTimeRange('last7days')}
                >
                  Last 7 days
                </Radio>
                <Radio
                  value="lastWeek"
                  checked={timeRange === 'lastWeek'}
                  onChange={() => setTimeRange('lastWeek')}
                >
                  Last week
                </Radio>
                <Radio
                  value="thisMonth"
                  checked={timeRange === 'thisMonth'}
                  onChange={() => setTimeRange('thisMonth')}
                >
                  This month
                </Radio>
                <Radio
                  value="lastMonth"
                  checked={timeRange === 'lastMonth'}
                  onChange={() => setTimeRange('lastMonth')}
                >
                  Last month
                </Radio>
                <Radio
                  value="customRange"
                  checked={timeRange === 'customRange'}
                  onChange={() => setTimeRange('customRange')}
                >
                  Custom range
                </Radio>
                {timeRange === 'customRange' && (
                  <RangePicker onChange={(e) => setCustomRange(e)} />
                )}
              </Radio.Group>
            </div>
          </Form>
        </div>

        <div className="drawer-btn">
          <Button className="drawer-btn-child" onClick={onClose} size="middle">
            Cancel
          </Button>
          <Button
            type="primary"
            className="drawer-btn-child"
            htmlType="submit"
            onClick={onFilterApply}
            size="middle"
          >
            Apply
          </Button>
        </div>
      </Drawer>
    </>
  );
};

export default memo(ScenarioFilter);

