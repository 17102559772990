import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Col,
  Dropdown,
  Row,
  Typography,
  Menu,
  Input,
  Button,
  Table,
  Form,
  Modal,
  message,
  Grid,
  Select,
  Collapse,
} from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import {
  getCurrentOrganization,
  getCurrnetUserData,
} from 'store/slices/loginSlice';
import { createDepartmentUser, updateDepartmentUser } from './SettingFunction';
import { debounceFn, searchTextInArrayOfObject } from 'utils/commonMethods';
import DeleteDepartment from './Components/DeleteDepartment';
import MergeDepartment from './Components/MergeDepartment';
import { useHistory } from 'react-router-dom';
import { getUsersByOrganizationIDCustom } from 'Pages/SuperAdmin/SuperAdminFun';
import { UserRole, userStatus } from 'utils/Constants';
import {
  departmentsByOrganizationIDCustom,
  DepartmentUseresCustom,
} from './Services/SettingActions';
import TableSkeleton from 'Common/TableSkeleton';

export default function () {
  const [form] = Form.useForm();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const history = useHistory();
  const { Search } = Input;

  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;

  const [departmentList, setDepartmentList] = useState([]);
  const [departmentBackList, setDepartmentBackList] = useState([]);
  const [editData, setEditData] = useState('');
  const [openaModal, setOpenModal] = useState(false);
  const [departmentData, setDepartmentData] = useState();
  const [mergeDepartmentOpen, setMergeDepartmentOpen] = useState(false);
  const [managerValue, setManagerValue] = useState([]);
  const [selectedManager, setSelectedManager] = useState([]);
  const [usersCount, setUsersCount] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openDeleteModal = () => {
    setOpenModal(true);
  };

  const openMergeDepartmentModal = () => {
    setMergeDepartmentOpen(true);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  //list department data
  const listDepartments = async () => {
    try {
      setTableLoading(true);
      const res = await departmentsByOrganizationIDCustom({
        organizationID: orgId,
      });
      let items = res?.items;
      if (items) {
        let result = items.map((item) => {
          return {
            ...item,
            key: item?.id,
            Department: item?.name,
            TotalUsers: item?.Users?.items?.length,
            Manager: item?.Manager,
          };
        });
        setDepartmentList(result);
        setDepartmentBackList(result);
      }
      setTableLoading(false);
    } catch (error) {
      setTableLoading(false);
      message.error(error.message);
    }
  };

  //to get manager list
  const getManagers = async () => {
    try {
      let data = {
        status: { eq: userStatus.active },
        or: [
          { role: { eq: UserRole.admin } },
          { role: { eq: UserRole.leader } },
          { role: { eq: UserRole.sme } },
        ],
      };
      let id = { organizationID: orgId };
      let res = await getUsersByOrganizationIDCustom(data, id);
      setManagerValue(res?.items);
    } catch (err) {
      console.log(err);
    }
  };

  //to get users count based on department
  const getDepartmentUsersCount = async () => {
    try {
      let filter = {
        organizationID: { eq: orgId },
      };
      let res = await DepartmentUseresCustom(filter);
      setUsersCount(
        res?.data?.searchUsers?.aggregateItems?.[0]?.result?.buckets
      );
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    listDepartments();
    getManagers();
    getDepartmentUsersCount();
    // eslint-disable-next-line
  }, []);

  const handleCancel = () => {
    setIsModalOpen(false);
    setEditData('');
    form.resetFields();
  };

  const openAddUpdateModal = (edit) => {
    if (edit) {
      setEditData(edit);
      form.setFieldsValue({ DepartmentName: edit.name });
      form.setFieldsValue({ manager: edit.Manager?.id });
    }
    setIsModalOpen(true);
  };
  // create user department function
  const createDepartment = async (e) => {
    setLoading(true);
    const input = {
      name: form.getFieldValue().DepartmentName,
      organizationID: orgId,
    };
    if (selectedManager?.length > 0) {
      input.managerID = selectedManager;
    }
    try {
      await createDepartmentUser(input);
      message.success(`Department Added Successfully`);
      listDepartments();
      setLoading(false);
      handleCancel();
    } catch (error) {
      message.error(error.message);
      setLoading(false);
    }
  };

  // update user department function
  const updateDepartment = async (e) => {
    setLoading(true);
    const input = {
      id: editData?.id,
      name: form.getFieldValue().DepartmentName,
    };
    if (selectedManager?.length > 0) {
      input.managerID = selectedManager;
    }
    try {
      await updateDepartmentUser(input);
      message.success(`Department Updated Successfully`);
      listDepartments();
      setLoading(false);
      handleCancel();
    } catch (error) {
      message.error(error.message);
      setLoading(false);
    }
  };

  const onFinish = () => {
    if (!form.getFieldValue().DepartmentName) {
      form.setFields([
        {
          name: 'DepartmentName',
          errors: ['Please enter department name'],
        },
      ]);
      return false;
    }
    if (editData) {
      updateDepartment();
    } else {
      createDepartment();
    }
  };

  const onSearch = (searchText) => {
    if (searchText !== '') {
      const keysToSearch = ['Department', 'TotalUsers'];
      const returnFilterData = searchTextInArrayOfObject(
        searchText,
        keysToSearch,
        departmentBackList
      );
      setDepartmentList(returnFilterData);
    } else {
      setDepartmentList(departmentBackList);
    }
  };

  const debouncedHandleInput = debounceFn(onSearch, 500);

  const handleInputSearch = (searchText) => {
    debouncedHandleInput(searchText);
  };

  const handleRowClick = (record, event) => {
    history.push({
      pathname: `/department-details/${record.id}`,
      state: { data: record },
    });
  };

  //columns
  const columns = [
    {
      title: 'Department',
      dataIndex: 'Department',
      key: 'Department',
      width: 300,
      className: 'department-color',
      render: (text, row) => {
        return <span className="fs-14">{text}</span>;
      },
    },
    {
      title: 'Total Users',
      dataIndex: 'TotalUsers',
      key: 'TotalUsers',
      width: '20%',
      align: 'center',
      render: (text, row) => {
        const count = usersCount.filter((item) => item?.key === row?.id)?.[0]
          ?.doc_count;
        return count ? count : '-';
      },
    },
    {
      title: 'Assigned Manager',
      dataIndex: 'Manager',
      key: 'Manager',
      width: '30%',

      render: (text, row) => {
        return (
          <span className="capitalize-text fs-14">
            {row?.Manager !== null
              ? row?.Manager?.firstName + ' ' + row?.Manager?.lastName
              : '-'}
          </span>
        );
      },
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      fixed: 'right',
      width: '10%',
      align: 'center',
      onCell: () => ({
        onClick: (event) => {
          event.stopPropagation();
        },
      }),
      render: (text, row) => (
        <Dropdown
          overlay={
            <Menu
              items={[
                {
                  label: <span>Edit</span>,
                  key: 0,
                  onClick: () => {
                    openAddUpdateModal(row);
                  },
                },
                {
                  label: <span>Delete</span>,
                  key: 1,
                  onClick: () => {
                    openDeleteModal();
                    setDepartmentData(row);
                  },
                },
              ]}
            />
          }
          trigger={['click']}
        >
          <MoreOutlined style={{ color: '#5AACC9' }} className="dot-style" />
        </Dropdown>
      ),
    },
  ];

  return (
    <>
      <div>
        <Row justify="center idea">
          <Col span={24}>
            <Typography.Title level={4} className="mb-20">
              Department
            </Typography.Title>
            <Row justify="space-between" align="middle">
              <Search
                className="searchbar-style"
                placeholder="Search"
                onChange={(e) => handleInputSearch(e.target.value)}
              />
              <div className="d-flex">
                {selectedRowKeys?.length > 1 && (
                  <Button
                    className="ml-10 bordered-button"
                    onClick={openMergeDepartmentModal}
                    size="middle"
                  >
                    Merge Departments
                  </Button>
                )}
                <Button
                  className="ml-10  bordered-button"
                  onClick={(e) => {
                    openAddUpdateModal('');
                    setSelectedManager([]);
                  }}
                  size="middle"
                >
                  Add New Department
                </Button>
              </div>
            </Row>
          </Col>
        </Row>
        {tableLoading ? (
          <TableSkeleton />
        ) : (
          <Table
            rowSelection={rowSelection}
            className="admin-table mt-20 cursor-pointer"
            columns={columns}
            dataSource={departmentList}
            pagination={false}
            scroll={{ x: 1000 }}
            size={screens.xxl ? 'middle' : 'small'}
            onRow={(record) => ({
              onClick: () => handleRowClick(record),
            })}
          />
        )}
      </div>

      {/* Add new department modal  */}
      <Modal
        open={isModalOpen}
        onOk={onFinish}
        onCancel={handleCancel}
        centered
        okText={editData ? 'Save' : 'Add'}
        className="delete-user-modal add-department"
        confirmLoading={loading}
      >
        <Row>
          <Col span={24}>
            <Typography.Title level={3}>
              {editData ? 'Edit department' : 'Add New department'}
            </Typography.Title>
          </Col>
          <Col span={24}>
            <Form
              layout="vertical"
              requiredMark={false}
              className="mt-10"
              form={form}
              onFinish={onFinish}
            >
              <Form.Item
                label={<span>Department Name</span>}
                name="DepartmentName"
                rules={[
                  {
                    required: true,
                    message: 'Please enter Department Name',
                  },
                ]}
              >
                <Input placeholder="Please enter department name" />
              </Form.Item>
              <Form.Item name="manager" label={<span>Assign Manager</span>}>
                <Select
                  placeholder="Select Manager"
                  value={selectedManager}
                  onChange={(e) => setSelectedManager(e)}
                  allowClear
                  className="capitalize-text"
                >
                  {managerValue.map((e, index) => {
                    return (
                      <Option
                        value={e.id}
                        key={index}
                        className="capitalize-text"
                      >
                        {e.firstName + ' ' + e.lastName}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>
      <DeleteDepartment
        openaModal={openaModal}
        departmentData={departmentData}
        setOpenModal={setOpenModal}
        listDepartments={listDepartments}
        orgId={orgId}
      />
      <MergeDepartment
        mergeDepartmentOpen={mergeDepartmentOpen}
        selectedRowKeys={selectedRowKeys}
        setMergeDepartmentOpen={setMergeDepartmentOpen}
        orgId={orgId}
        listDepartments={listDepartments}
        setSelectedRowKeys={setSelectedRowKeys}
      />
    </>
  );
}


