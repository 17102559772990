import {
  Button,
  Form,
  InputNumber,
  Modal,
  Row,
  Typography,
  message,
} from 'antd';
import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getDefaultBatchID } from 'store/slices/compareSlice';
import {
  getCurrentOrganization,
  getCurrnetUserData,
} from 'store/slices/loginSlice';
import { updateGammaNewCustom } from 'utils/Actions';
import { RESTGetAuthorization, RESTUpdateRanking } from 'utils/RESTApi';
import { getGammas } from './OpportunityFunctions';

const ReAssignedRankModal = ({
  isModalOpen,
  setIsModalOpen,
  editData,
  getGammaByOrg,
  detailPageRank,
  setCurrentPage,
}) => {
  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;
  const userId = currnetOrg?.adminID || loginUsrOrg?.id;

  const [form] = Form.useForm();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [switchLoading, setSwitchLoading] = useState(false);
  const [defaultRank, setDefaultRank] = useState();
  const priorityDefaultBatchID =
    currnetOrg?.defaultPrioirtybatch?.id ||
    loginUsrOrg?.Organization?.organizationDefaultPrioirtybatchId;

  const fetchDefaultBatchID = useSelector(getDefaultBatchID);

  const defaultPriorityBatchID = fetchDefaultBatchID
    ? fetchDefaultBatchID
    : priorityDefaultBatchID;

  useEffect(() => {
    form.setFieldsValue({
      rank: detailPageRank?.fixedRank || editData.fixedRank,
    });

    // eslint-disable-next-line
  }, [editData]);
  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const handleSubmitRank = async () => {
    if (!form.getFieldValue().rank) {
      form.setFields([
        {
          name: 'rank',
          errors: ['Please enter Rank'],
        },
      ]);
      return false;
    }
    let input = { id: editData?.id, fixedRank: form.getFieldValue().rank };
    setLoading(true);
    try {
      let rankingApis = `/rankings/valid?id=${
        editData?.id
      }&organizationID=${orgId}&priorityBatchID=${defaultPriorityBatchID}&userID=${userId}&rank=${
        form.getFieldValue().rank
      }`;
      let res = await RESTGetAuthorization(rankingApis);
      if (res?.body) {
        await updateGammaNewCustom(input);
        message.success(`Rank Re-Assigned Successfully`);
        createScoreCalculationStatus();
      } else {
        message.error('You can not assigned this rank');
      }
      setLoading(false);
      handleCancel();
    } catch (error) {
      message.error(error.message);
      setLoading(false);
    }
  };

  const handleSwitchToDefaultRank = async () => {
    let input = { id: editData?.id, fixedRank: -1 };
    setSwitchLoading(true);
    try {
      await updateGammaNewCustom(input);
      message.success(`Rank Re-Assigned Successfully`);
      setSwitchLoading(false);
      createScoreCalculationStatus();
      handleCancel();
    } catch (error) {
      message.error(error.message);
      setSwitchLoading(false);
    }
  };

  const createScoreCalculationStatus = async () => {
    try {
      let inputData = {
        organization_id: orgId,
      };
      await RESTUpdateRanking(inputData);
      setCurrentPage(1);
      getGammaByOrg(
        null,
        null,
        null,
        1,
        null,
        '',
        null,
        null,
        null,
        null,
        false,
        false,
        false
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getGammaRank = async () => {
    if (id) {
      try {
        let res = await getGammas({ id: id });
        setDefaultRank(res?.rank?.[1]);
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    getGammaRank();
    // eslint-disable-next-line
  }, []);

  return (
    <Modal
      open={isModalOpen}
      onOk={() => {
        handleSubmitRank();
        setCurrentPage(1);
      }}
      onCancel={handleCancel}
      closable={false}
      centered
      okText="Save"
      cancelText="Switch to default rank"
      className="submit-idea-modal footer-space-between"
      confirmLoading={loading}
      cancelLoading={switchLoading}
      footer={[
        <Button
          key="default-update"
          loading={switchLoading}
          onClick={handleSwitchToDefaultRank}
          warning
          size="middle"
          className="bordered-button"
        >
          Switch to default rank
        </Button>,
        <Button
          key="save-form"
          type="primary"
          loading={loading}
          onClick={() => {
            handleSubmitRank();
            setCurrentPage(1);
          }}
          className="submit-idea-modal footer-space-between blue-filled-btn"
          size="middle"
        >
          Save
        </Button>,
      ]}
    >
      <Row className="w-100">
        <div className="w-100">
          <Typography.Title level={4} className=" fw-600">
            Edit Rank
          </Typography.Title>
          <Typography.Title level={5} className="mb-20 text-black">
            {editData?.title}
          </Typography.Title>

          <Typography.Title level={5} className="mb-20">
            Default Rank:{' '}
            {id ? (
              <span className="text-primary fw-bolder mr-10">
                {defaultRank > 0 || defaultRank === 0
                  ? defaultRank
                  : editData?.rank}
              </span>
            ) : (
              <span className="text-primary fw-bolder mr-10">
                {editData.defaultRank > 0 || editData.defaultRank === 0
                  ? editData.defaultRank
                  : editData?.rank}
              </span>
            )}
            Assigned Rank:{' '}
            <span className="text-blue fw-bolder mr-10">
              {detailPageRank?.fixedRank || editData?.fixedRank}
            </span>{' '}
          </Typography.Title>
          <Form
            layout="vertical"
            form={form}
            onFinish={handleSubmitRank}
            requiredMark={false}
          >
            <Form.Item
              className="mb-0"
              label={
                <Typography.Title level={5} className="mb-0">
                  Edit Assigned Rank :
                </Typography.Title>
              }
              type="number"
              name="rank"
              rules={[
                {
                  required: true,
                  message: 'Please enter Rank',
                },
              ]}
            >
              <InputNumber min={1} className="w-100" />
            </Form.Item>
          </Form>
        </div>
      </Row>
    </Modal>
  );
};

export default memo(ReAssignedRankModal);

