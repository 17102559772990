import React, { memo, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Button,
  Col,
  Input,
  Row,
  Table,
  Typography,
  Tooltip,
  Grid,
  Dropdown,
  Menu,
} from 'antd';
import { DoubleLeftOutlined, MoreOutlined } from '@ant-design/icons';
import { usersByDepartmentIDCustom } from '../Services/SettingActions';
import TableSkeleton from 'Common/TableSkeleton';
import { debounceFn, searchTextInArrayOfObject } from 'utils/commonMethods';
import { UserRole } from 'utils/Constants';

const DepartmentDetails = () => {
  const { Search } = Input;
  const { id } = useParams();
  const history = useHistory();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const [departmentList, setDepartmentList] = useState([]);
  const [departmentBackList, setDepartmentBackList] = useState([]);
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      className: 'capitalize-text',
      render: (text, row) => {
        return <span className="fs-14">{text}</span>;
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (text, row) => {
        return <span className="fs-14">{text}</span>;
      },
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: (text, row) => {
        const roleName = text === UserRole.sme ? UserRole?.teamMember : text;
        return <span className="fs-14">{roleName}</span>;
      },
    },
    {
      title: 'Manager',
      dataIndex: 'manager',
      key: 'manager',
      className: 'capitalize-text',
      render: (text, row) => {
        return (
          <span className="fs-14">
            {row?.Manager !== null
              ? row?.Manager?.firstName + '  ' + row?.Manager?.lastName
              : '-'}
          </span>
        );
      },
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      fixed: 'right',
      width: 80,
      render: (text, row) => (
        <Dropdown
          overlay={
            <Menu
              items={[
                {
                  label: <span>Edit</span>,
                  key: '1',
                  onClick: () => {
                    history.push({
                      pathname: `/edit-active-user/${row.id}`,
                      state: { departmentTab: true, deptID: id },
                    });
                  },
                },
              ]}
            />
          }
          trigger={['click']}
        >
          <MoreOutlined style={{ color: '#5AACC9' }} className="dot-style" />
        </Dropdown>
      ),
    },
  ];

  //to get employees list by department
  const getUsersByDepartment = async () => {
    try {
      setLoading(true);
      let res = await usersByDepartmentIDCustom({ departmentID: id });
      let items = res?.items;
      if (items) {
        let result = items.map((item) => {
          return {
            ...item,
            key: item?.id,
            name: item?.firstName + ' ' + item?.lastName,
          };
        });
        setDepartmentList(result);
        setDepartmentBackList(result);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const onSearch = (searchText) => {
    if (searchText !== '') {
      const keysToSearch = ['name', 'email', 'manager'];
      const returnFilterData = searchTextInArrayOfObject(
        searchText,
        keysToSearch,
        departmentBackList
      );
      setDepartmentList(returnFilterData);
    } else {
      setDepartmentList(departmentBackList);
    }
  };

  const debouncedHandleInput = debounceFn(onSearch, 500);

  const handleInputSearch = (searchText) => {
    debouncedHandleInput(searchText);
  };

  useEffect(() => {
    getUsersByDepartment();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="background-white p-20 w-100">
      <Row justify="center idea">
        <Col span={24}>
          <div className="d-flex">
            <Tooltip title="Back">
              <DoubleLeftOutlined
                className="back-icon cursor-pointer"
                onClick={() =>
                  history.push({
                    pathname: '/settings',
                    state: { departmentTab: true },
                  })
                }
              />
            </Tooltip>
            <Typography.Title level={4}>
              {departmentList?.[0]?.Department?.name} Details
            </Typography.Title>
          </div>
          <Row justify="space-between" align="middle">
            <Search
              className="searchbar-style"
              placeholder="Search"
              onChange={(e) => handleInputSearch(e.target.value)}
            />
            <div className="d-flex">
              <Button
                className="ml-10 bordered-button"
                onClick={() =>
                  history.push({
                    pathname: `/add-user/${id}`,
                  })
                }
                size="middle"
              >
                Add User
              </Button>
            </div>
          </Row>
        </Col>
      </Row>
      {loading ? (
        <TableSkeleton />
      ) : (
        <Table
          columns={columns}
          dataSource={departmentList}
          className="mt-20"
          size={screens.xxl ? 'middle' : 'small'}
          pagination={false}
        />
      )}
    </div>
  );
};

export default memo(DepartmentDetails);

