import React, { memo, useEffect, useState } from 'react';
import { Modal, Typography, Row, Form, Input, Button, message } from 'antd';
import { updateOrganizationCustom } from '../Service/GoalActions';

const VisionModal = ({
  visionModal,
  setVisionModal,
  orgId,
  visionDetails,
  getVisionDetails,
}) => {
  const { TextArea } = Input;
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const handleCancel = () => {
    setVisionModal(false);
  };

  const handleSubmitVision = async (e) => {
    try {
      setLoading(true);
      let visionObject = {
        id: orgId,
        vision: { key: e.title, value: e.description },
      };
      await updateOrganizationCustom(visionObject);
      await getVisionDetails();
      setLoading(false);
      setVisionModal(false);
      message.success('Text edited successfully');
    } catch (err) {
      setLoading(false);
      console.log(err);
      throw err;
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      title: visionDetails?.key,
      description: visionDetails?.value,
    });
    // eslint-disable-next-line
  }, [visionDetails]);

  return (
    <Modal
      open={visionModal}
      closable={false}
      centered
      className="vision-banner"
      footer={false}
    >
      <Row className="w-100">
        <div className="w-100">
          <Typography.Title level={4} className="fw-600">
            Edit Vision
          </Typography.Title>
          <Typography.Title
            level={5}
            className="mb-20 text-black"
          ></Typography.Title>
          <Form
            layout="vertical"
            form={form}
            onFinish={(e) => handleSubmitVision(e)}
            requiredMark={false}
          >
            <Form.Item
              label={
                <Typography.Title level={5} className="mb-0 fw-600">
                  Title :
                </Typography.Title>
              }
              name="title"
              rules={[
                {
                  required: true,
                  message: 'Please enter title',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={
                <Typography.Title level={5} className="mb-0">
                  Description :
                </Typography.Title>
              }
              name="description"
              rules={[
                {
                  required: true,
                  message: 'Please enter description',
                },
              ]}
            >
              <TextArea className="h-120" maxLength={300} showCount />
            </Form.Item>
            <Row>
              <Button
                className="mr-10 bordered-button"
                size="middle"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                htmlType="submit"
                className="blue-filled-btn "
                size="middle"
                loading={loading}
              >
                Save
              </Button>
            </Row>
          </Form>
        </div>
      </Row>
    </Modal>
  );
};

export default memo(VisionModal);

