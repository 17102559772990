import { Dropdown, Menu, Table } from 'antd';
import React, { memo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { MoreOutlined } from '@ant-design/icons';
import moment from 'moment';
import { getOrganizationCustom } from 'utils/Actions';
import { useDispatch } from 'react-redux';
import { viewAs } from 'store/slices/loginSlice';
import { setDefaultBatchID } from 'store/slices/compareSlice';
import { setScopingIds } from 'store/slices/brandingSlice';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

const PartnerTable = ({
  partnersDetails,
  sortTableData,
  sortOrder,
  sortColumn,
  handelInfiniteScroll,
}) => {
  const dispatch = useDispatch();

  const history = useHistory();
  const handleRowClick = (record, event) => {
    history.push({
      pathname: `/partner/detail/${record.id}`,
      state: { data: record },
    });
  };

  const getOrgByID = async (e) => {
    let data = await getOrganizationCustom({ id: e?.id });
    dispatch(viewAs(data));
    dispatch(setScopingIds([e?.id]));
    dispatch(setDefaultBatchID(''));
  };

  const formatContact = (contact) => {
    if (!contact) return '-';
    const phoneNumber = parsePhoneNumberFromString(contact);
    if (phoneNumber) {
      const countryCode = phoneNumber.country;
      switch (countryCode) {
        case 'US':
          return phoneNumber.formatNational({
            format: 'NATIONAL',
            spaces: true,
          });
        default:
          return phoneNumber.formatInternational();
      }
    }
    return '-';
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sortOrder:
        sortColumn === 'name'
          ? sortOrder === 'ASC'
            ? 'ascend'
            : 'descend'
          : null,
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: true,
      render: (text, row) => (
        <Link
          className="text-primary"
          onClick={(e) => {
            e.stopPropagation();
            getOrgByID(row);
          }}
          to={{ pathname: '/', state: { orgId: row?.id } }}
        >
          <span>{text}</span>
        </Link>
      ),
    },
    {
      title: 'Website',
      dataIndex: 'website',
      key: 'website',
      sortOrder:
        sortColumn === 'website'
          ? sortOrder === 'ASC'
            ? 'ascend'
            : 'descend'
          : null,
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, row) => {
        return (
          <a className="text-primary" href={row.website} target="_blank">
            {row?.website}
          </a>
        );
      },
    },
    {
      title: 'Contact number',
      dataIndex: 'contact',
      key: 'contact',
      width: 200,
      render: (text, row) => {
        return (
          <span>
            {row?.contact !== undefined && formatContact(row?.contact)}
          </span>
        );
      },
      sortOrder:
        sortColumn === 'contact'
          ? sortOrder === 'ASC'
            ? 'ascend'
            : 'descend'
          : null,
      sorter: (a, b) => {
        return a - b;
      },
      sortDirections: ['ascend', 'descend', 'ascend'],
    },

    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 130,
      sortOrder:
        sortColumn === 'createdAt'
          ? sortOrder === 'ASC'
            ? 'ascend'
            : 'descend'
          : null,
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      render: (text) => <span>{moment(text).format('l')}</span>,
    },
    {
      title: 'Organizations',
      dataIndex: 'organizations',
      key: 'organizations',
      width: 80,
      sortOrder:
        sortColumn === 'organizations'
          ? sortOrder === 'ASC'
            ? 'ascend'
            : 'descend'
          : null,
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a, b) => {
        return a - b;
      },
      render: (text) => <span>{text}</span>,
    },
    {
      title: 'Users',
      dataIndex: 'users',
      key: 'users',
      width: 80,
      sortOrder:
        sortColumn === 'users'
          ? sortOrder === 'ASC'
            ? 'ascend'
            : 'descend'
          : null,
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a, b) => {
        return a - b;
      },
      render: (text) => <span>{text}</span>,
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      fixed: 'right',
      width: 50,
      onCell: () => ({
        onClick: (event) => {
          event.stopPropagation();
        },
      }),
      render: (text, row) => (
        <Dropdown
          overlay={
            <Menu
              items={[
                {
                  label: <span>Edit</span>,
                  key: '1',
                  onClick: () => {
                    history.push(`/partner/edit-partner/${row?.id}`);
                  },
                },
              ]}
            />
          }
          trigger={['click']}
        >
          <MoreOutlined style={{ color: '#5AACC9' }} className="fs-20" />
        </Dropdown>
      ),
    },
  ];
  return (
    <div
      style={{ maxHeight: 'calc(100vh - 140px)', overflowY: 'auto' }}
      onScroll={handelInfiniteScroll}
    >
      <Table
        onChange={(pagination, filter, sorter) => {
          sortTableData(sorter);
        }}
        className="admin-table cursor-pointer"
        columns={columns}
        dataSource={partnersDetails}
        pagination={false}
        onRow={(record) => ({
          onClick: () => handleRowClick(record),
        })}
      />
    </div>
  );
};

export default memo(PartnerTable);

