import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  groups: null,
  token: null,
  userData: null,
  viewingas: null,
  currnetUserData: null,
  orgnizationData: null,
  formLayout: [],
  homeTour: false,
  mainTour: false,
  addIdeaTour: false,
  rankingTour: false,
  rateTour: false,
  rateDetailTour: false,
  compareTour: false,
  alreadyTourStarted: false,
  profilePhoto: null,
  congratsModal: false,
  usersData: [],
  comparisonIDs: [],
  partner: null,
};

const loginSlice = createSlice({
  name: 'loginSlice',
  initialState,
  reducers: {
    signIn: (state, { payload }) => {
      state.token = payload.token;
      state.groups = payload.groups;
      state.userData = payload.userData;
    },
    viewAs: (state, { payload }) => {
      state.viewingas = payload;
    },
    currentUser: (state, { payload }) => {
      state.currnetUserData = payload;
    },
    setOrgarnizationData: (state, { payload }) => {
      state.orgnizationData = payload;
    },

    setFormLayout: (state, { payload }) => {
      state.formLayout = payload;
    },

    setHomeTour: (state, { payload }) => {
      state.homeTour = payload;
    },
    setMainTour: (state, { payload }) => {
      state.mainTour = payload;
    },
    setAddIdeaTour: (state, { payload }) => {
      state.addIdeaTour = payload;
    },
    setRankingTour: (state, { payload }) => {
      state.rankingTour = payload;
    },
    setRateTour: (state, { payload }) => {
      state.rateTour = payload;
    },
    setRateDetailTour: (state, { payload }) => {
      state.rateDetailTour = payload;
    },
    setCompareTour: (state, { payload }) => {
      state.compareTour = payload;
    },
    setAlreadyStartedTour: (state, { payload }) => {
      state.alreadyTourStarted = payload;
    },
    setProfilePic: (state, { payload }) => {
      state.profilePhoto = payload;
    },
    setCongratsModal: (state, { payload }) => {
      state.congratsModal = payload;
    },
    setUseresData: (state, { payload }) => {
      state.usersData = payload;
    },
    setComparisonIDs: (state, { payload }) => {
      state.comparisonIDs = payload;
    },
    setIsPartner: (state, { payload }) => {
      state.partner = payload;
    },
  },
});

export const getCurrentToken = (state) => state?.loginReducer?.token;
export const getCurrentUser = (state) => state?.loginReducer?.userData;
export const getCurrentOrganization = (state) => state?.loginReducer?.viewingas;
export const getCurrentGroups = (state) => state?.loginReducer?.groups;
export const getOrganizationData = (state) =>
  state?.loginReducer?.orgnizationData;

export const isSuperAdmin = (state) =>
  state?.loginReducer?.groups === 'SUPERADMIN';
export const isLeader = (state) => state?.loginReducer?.groups === 'LEADER';
export const isSME = (state) => state?.loginReducer?.groups?.includes('SME');
export const isAdmin = (state) => state?.loginReducer?.groups === 'ADMIN';

export const getCurrnetUserData = (state) => state.loginReducer.currnetUserData;
export const getFormLayout = (state) => state?.loginReducer?.formLayout;
export const getHomeTour = (state) => state?.loginReducer?.homeTour;

export const getMainTour = (state) => state?.loginReducer?.mainTour;
export const getAddIdeaTour = (state) => state?.loginReducer?.addIdeaTour;
export const getRankingTour = (state) => state?.loginReducer?.rankingTour;
export const getRateTour = (state) => state?.loginReducer?.rateTour;
export const getRateDetailTour = (state) => state?.loginReducer?.rateDetailTour;
export const getCompareTour = (state) => state?.loginReducer?.compareTour;

export const getAlreadyStartedTour = (state) =>
  state?.loginReducer?.alreadyTourStarted;
export const getProfilePhoto = (state) => state?.loginReducer?.profilePhoto;
export const getCongratsModal = (state) => state?.loginReducer?.congratsModal;
export const getUsersData = (state) => state?.loginReducer?.usersData;
export const getComparisonIDs = (state) => state?.loginReducer?.comparisonIDs;
export const getIsPartner = (state) => state?.loginReducer?.partner;

const { actions, reducer } = loginSlice;
export const {
  signIn,
  viewAs,
  currentUser,
  setOrgarnizationData,
  setFormLayout,
  setHomeTour,
  setMainTour,
  setAddIdeaTour,
  setRankingTour,
  setRateTour,
  setRateDetailTour,
  setCompareTour,
  setAlreadyStartedTour,
  setProfilePic,
  setCongratsModal,
  setUseresData,
  setComparisonIDs,
  setIsPartner,
} = actions;
export default reducer;

