import { Col, Row, Typography, Form, Input, Button, message } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import '../common.less';
import React, { useState } from 'react';
import { ECHOIMAGES } from '../../Assets/Images';
import { Auth } from 'aws-amplify';
import { RESTResetUserPassword } from 'utils/RESTApi';

export default function ForgotPassword() {
  const { forgotPassword, Logo, Background, AuthLogo } = ECHOIMAGES.Auth;
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handleResetPassword = async (e) => {
    let emailData = e.email.toLowerCase();
    setLoading(true);
    try {
      await Auth.forgotPassword(emailData);
      setLoading(false);
      history.push({
        pathname: '/reset-password',
        state: { userName: emailData },
      });
    } catch (error) {
      if (
        error.message === 'User password cannot be reset in the current state.'
      ) {
        let response = await Auth.signIn(emailData);
        let input = {
          user_id: response.challengeParam.USERNAME,
        };
        await RESTResetUserPassword(input)
          .then((res) => {
            message.info(
              'Please check your inbox, a reset password has been sent to your email'
            );
            history.push('/login');
          })
          .catch((err) => {
            console.log(err);
          });
      }
      setLoading(false);
    }
  };
  return (
    <>
      <Row className="login forgot-password">
        <Col
          span={12}
          lg={12}
          md={0}
          xs={0}
          sm={0}
          style={{ backgroundImage: `url(${Background})` }}
        >
          <div className="login-left">
            <Typography.Title level={1}>Show your ideas</Typography.Title>
            <Typography.Title level={4}>
              Share your ideas and track the impact you're having
            </Typography.Title>
            <img src={forgotPassword} alt="" />
          </div>
        </Col>

        <Col span={12} lg={12} md={24} xs={24} sm={24} className="login-form">
          <div className="wrapper">
            <img src={AuthLogo} alt="" />

            <Typography.Title level={3}>Forgot your password?</Typography.Title>
            <Typography.Title level={5}>
              No worries. We'll send reset instructions to your email.
            </Typography.Title>

            <Form
              layout="vertical"
              requiredMark={false}
              onFinish={(e) => handleResetPassword(e)}
            >
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Please input your email',
                  },
                ]}
              >
                <Input className="input" />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="primary-btn"
                  loading={loading}
                >
                  Reset password
                </Button>
              </Form.Item>

              <Typography.Title level={5} className="signup-text">
                <Link to="/login">Back to login</Link>
              </Typography.Title>
            </Form>
          </div>
          <Typography.Title level={5} className="footer">
            © {new Date().getFullYear()} Echo Consulting, LLC
          </Typography.Title>
        </Col>
      </Row>
    </>
  );
}
