import { Button, Drawer, Input, Checkbox, Row, Space, Typography } from 'antd';
import React, { memo, useEffect, useState } from 'react';
import { searchOrganizationsCustom } from 'utils/Actions';
import { RESTListOrg } from 'utils/RESTApi';

const FilterByPartnerDrawer = ({
  filterDrawerOpen,
  setFilterDrawerOpen,
  sortTableColumn,
  sortTableOrder,
  searchText,
  setCurrentPage,
  currentPage,
  setOrganizationDetail,
  setTableLoading,
}) => {
  const { Search } = Input;
  const [partnerListing, setPartnerListing] = useState([]);
  const [selectedPartnerID, setSelectedPartnerID] = useState([]);
  const [loading, setLoading] = useState(false);

  //function to apply filter by partner
  const handleFilter = async (page) => {
    setLoading(true);
    let tempPage = page || currentPage;
    let allData = [];
    try {
      let inputData = {
        sort_field: sortTableColumn,
        sort_direction: sortTableOrder,
        page: tempPage,
        filters: {
          Partner: selectedPartnerID,
        },
      };
      if (searchText !== undefined && searchText !== '') {
        inputData.filters = {
          Search: searchText,
        };
      }
      let response = await RESTListOrg(inputData);
      const temp = response?.body?.map((item) => {
        return {
          ...item,
        };
      });
      allData = [...allData, ...temp];
      setOrganizationDetail(allData);
      setTableLoading(false);
      setFilterDrawerOpen(false);
      setLoading(false);
    } catch (error) {
      setTableLoading(false);
      setLoading(false);
      console.log(error);
    }
  };

  //function to list partners
  const listPartner = async () => {
    try {
      const filter = {
        type: {
          eq: 'PARTNER',
        },
      };
      let sort = { direction: 'asc', field: 'name' };
      let nextToken = null;
      let allData = [];
      while (true) {
        let response = await searchOrganizationsCustom(filter, nextToken, sort);
        allData.push(...response?.items);
        if (!response?.nextToken) {
          break;
        }
        nextToken = response?.nextToken;
      }
      await processUserData(allData);
    } catch (err) {
      console.log(err);
    }
  };

  //function to handle nextToken
  const processUserData = async (userData) => {
    setPartnerListing(userData);
  };

  //function to search partner
  const onSearch = async (e) => {
    try {
      const filter = {
        type: {
          eq: 'PARTNER',
        },
      };
      if (e !== undefined) {
        filter.name = { wildcard: '*' + e + '*' };
      }
      let sort = { direction: 'asc', field: 'name' };
      let nextToken = null;
      let response = await searchOrganizationsCustom(filter, nextToken, sort);
      setPartnerListing(response?.items);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    listPartner();
    // eslint-disable-next-line
  }, []);

  return (
    <Drawer
      className="related-item-drawer brawer-with-btn"
      open={filterDrawerOpen}
      onClose={() => setFilterDrawerOpen(false)}
      closeIcon={false}
      title={
        <Row justify="space-between" align="middle">
          <Typography.Title level={3} className="mb-0 ">
            Filters
          </Typography.Title>
          <Button
            type="text"
            className="text-blue fs-14 p-0"
            onClick={() => setSelectedPartnerID([])}
          >
            <strong>Clear All</strong>
          </Button>
        </Row>
      }
    >
      <>
        <div>
          <Typography.Text className="fs-16 fw-500">
            Partners List
          </Typography.Text>
          <Row>
            <Search
              className="searchbar-style mt-10"
              placeholder="input search text"
              onChange={(e) => onSearch(e.target.value)}
            />
          </Row>

          <Checkbox.Group
            className="mt-10"
            onChange={(e) => setSelectedPartnerID(e)}
            value={selectedPartnerID}
          >
            <Space direction="vertical">
              {partnerListing?.map((e, index) => {
                return (
                  <Checkbox value="abc" key={index} value={e?.id}>
                    {e?.name}
                  </Checkbox>
                );
              })}
            </Space>
          </Checkbox.Group>
        </div>
        <div className="drawer-btn">
          <Button
            className="drawer-btn-child"
            onClick={() => setFilterDrawerOpen(false)}
            size="middle"
          >
            Cancel
          </Button>
          <Button
            type="primary"
            className="drawer-btn-child"
            htmlType="submit"
            size="middle"
            onClick={() => {
              handleFilter(1);
              setCurrentPage(1);
            }}
            loading={loading}
          >
            Apply
          </Button>
        </div>
      </>
    </Drawer>
  );
};

export default memo(FilterByPartnerDrawer);

