import { Carousel, Col, message, Row, Typography } from 'antd';
import '../common.less';
import React, { useState } from 'react';
import { ECHOIMAGES } from '../../Assets/Images';
import Step1 from './Step1';
import Step2 from './Step2';
import { API, Auth } from 'aws-amplify';
import { getUsers } from 'utils/Actions';
import { useDispatch } from 'react-redux';
import { currentUser, signIn } from 'store/slices/loginSlice';
import { FetchUser } from 'Pages/Idea/IdeaFunction';
import { useHistory } from 'react-router-dom';
import { RESTPost, RESTSignup } from 'utils/RESTApi';

export default function Signup() {
  const [renderSteps, setRenderSteps] = useState(1);
  const [loading, setLaoding] = useState(false);
  const [showVerified, setShowVerified] = useState(false);
  const [otp, setOtp] = useState();
  const [userEmail, setUserEmail] = useState('');
  const [domain, setDomain] = useState('');
  const [organizationEmail, setOrganizationEmail] = useState('');
  const [signupLoading, setSignupLoading] = useState(false);
  const [organizationID, setOrganizationID] = useState('');
  const [email, setEmail] = useState('');
  const [organizationLoader, setOrganizationLoader] = useState(true);

  const history = useHistory();
  const handleChange = (otp) => {
    setOtp(otp);
  };
  const dispatch = useDispatch();

  // for signup
  const handleSignup = async (e) => {
    setLaoding(true);
    let emailData = e.Email.toLowerCase();
    if (e.Password === e.ConfirmPassword) {
      await Auth.signUp({
        username: emailData,
        password: e.Password,
        attributes: {
          email: emailData,
        },
      })
        .then(() => {
          setShowVerified(true);
          setLaoding(false);
          localStorage.setItem('User', window.btoa(e.Email));
        })
        .catch((err) => {
          setLaoding(false);
          message.error(err.message);
        });
    } else {
      message.warning('Please enter the same Password and Confirm Password ');
      setLaoding(false);
    }
  };
  // to fetch verified user information
  const fetchVerifiedUser = async () => {
    const res = await Auth.currentAuthenticatedUser();
    const emailStr = res?.attributes?.email.split('@')[1];
    setDomain(emailStr);
    setUserEmail(!res?.attributes?.given_name);
    setRenderSteps(2);
  };

  // for verify accont(confirmation code)
  const handleConfirmSignUp = async (e) => {
    try {
      setLaoding(true);
      if (e.Email && e.code) {
        await Auth.confirmSignUp(e.Email, e.code)
          .then(async () => {
            const emailStr = e?.Email.split('@')[1];
            setEmail(e?.Email);
            setDomain(emailStr);
            setRenderSteps(2);
            await Auth.signIn(e.Email, e.Password);
            const res = await Auth.currentAuthenticatedUser();
            const organisationData = await getUsers({ id: res?.username });
            setOrganizationEmail(organisationData?.name);
            setOrganizationID(organisationData?.id);
            setOrganizationLoader(false);
            setLaoding(false);
          })
          .catch((error) => {
            setLaoding(false);
            message.error(error.message);
          });
      } else {
        message.error();
        setLaoding(false);
      }
    } catch (error) {
      if (
        error.message.includes(
          'User cannot be confirmed. Current status is CONFIRMED'
        )
      ) {
        setLaoding(false);
        message.success('Already Verified');
      }
      setLaoding(false);
    }
  };

  // for auth update and create organization
  const updateAuth = async (e) => {
    setSignupLoading(true);
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, {
        given_name: e.firstname,
        family_name: e.lastname,
      });
      if (!organizationEmail) {
        const apiName = 'StrAlignRest';
        const path = '/signup';
        const body = {
          organization_name: e.organisationname,
          email: email.toLowerCase(),
          first_name: e.firstname,
          last_name: e.lastname,
        };
        await RESTSignup(body);
        // await API.post(apiName, path, { body: body });
        if (localStorage.getItem('SSO') === 'true') {
          await Auth.signIn(email)
            .then(async (signinres) => {
              const authUser = await Auth.currentAuthenticatedUser();
              await Auth.sendCustomChallengeAnswer(
                signinres,
                authUser?.attributes?.['custom:msalIdToken']
              );
              if (authUser?.attributes?.given_name) {
                localStorage.setItem(
                  'given_name',
                  authUser?.attributes?.given_name
                );
                const signInUserData = {
                  token: authUser?.signInUserSession?.accessToken?.jwtToken,
                  groups:
                    authUser?.signInUserSession?.accessToken?.payload[
                      'cognito:groups'
                    ],
                  userData: authUser?.attributes,
                };
                dispatch(signIn(signInUserData));
                const loginUserData = await FetchUser({
                  id: authUser?.attributes?.sub,
                });
                dispatch(currentUser(loginUserData));
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          history.push('/login');
        }

        message.success('Organization created successfully');
        setSignupLoading(false);
      } else {
        const apiName = 'StrAlignRest';
        const path = '/signup';
        const body = {
          organization_id: organizationID,
          organization_name: e.organisationname,
          email: email,
          first_name: e.firstname,
          last_name: e.lastname,
        };
        await RESTSignup(body);
        // await API.post(apiName, path, { body: body });
        await Auth.signIn(email)
          .then(async () => {
            const authUser = await Auth.currentAuthenticatedUser();
            if (authUser?.attributes?.given_name) {
              localStorage.setItem(
                'given_name',
                authUser?.attributes?.given_name
              );
              const signInUserData = {
                token: authUser?.signInUserSession?.accessToken?.jwtToken,
                groups:
                  authUser?.signInUserSession?.accessToken?.payload[
                    'cognito:groups'
                  ],
                userData: authUser?.attributes,
              };
              dispatch(signIn(signInUserData));
              const loginUserData = await FetchUser({
                id: authUser?.attributes?.sub,
              });
              dispatch(currentUser(loginUserData));
            }
          })
          .catch((error) => {
            console.log(error);
          });
        message.success('Organization created successfully');
        setSignupLoading(false);
      }
    } catch (error) {
      message.error(error.message);
      setSignupLoading(false);
    }
  };

  const { signupSlide1, signupSlide2, signupSlide3, Background, AuthLogo } =
    ECHOIMAGES.Auth;
  return (
    <>
      <Row className="login signup">
        <Col
          span={12}
          lg={12}
          md={0}
          xs={0}
          sm={0}
          style={{ backgroundImage: `url(${Background})` }}
        >
          <Carousel autoplay>
            <div className="login-left">
              <div className="vertical-middle">
                <Typography.Title level={1}>COMPARE IDEAS</Typography.Title>
                <Typography.Title level={4}>
                  Share your insight on which projects will have the most
                  positive impact.
                </Typography.Title>
                <img src={signupSlide1} alt="" />
                {/* <img src={Background} alt="" /> */}
              </div>
            </div>
            <div className="login-left">
              <div className="vertical-middle">
                <Typography.Title level={1}>
                  PRIORITIZE CONFIDENTLY
                </Typography.Title>
                <Typography.Title level={4}>
                  Align projects to the strategic goals for better results.
                  Bridge the gap between strategy and execution.
                </Typography.Title>
                <img src={signupSlide2} alt="" />
              </div>
            </div>
            <div className="login-left">
              <div className="vertical-middle">
                <Typography.Title level={1}>
                  STAY FOCUSED ON TOP PRIORITIES
                </Typography.Title>
                <Typography.Title level={4}>
                  Things Change! Know your goals. Unlock your potential.
                </Typography.Title>
                <img src={signupSlide3} alt="" />
              </div>
            </div>
          </Carousel>
        </Col>

        <Col span={12} lg={12} md={24} xs={24} sm={24} className="login-form">
          <div className="wrapper">
            <Typography.Title level={5} className="steps">
              {renderSteps === 1 ? 'Step 1 of 2' : 'Step 2 of 2'}
            </Typography.Title>
            <img src={AuthLogo} alt="" />
            {renderSteps === 1 ? (
              <Step1
                setRenderSteps={setRenderSteps}
                handleSignup={handleSignup}
                loading={loading}
                showVerified={showVerified}
                handleConfirmSignUp={handleConfirmSignUp}
                handleChange={handleChange}
                otp={otp}
                userEmail={userEmail}
              />
            ) : (
              <Step2
                flow={'signup'}
                fetchVerifiedUser={fetchVerifiedUser}
                domain={domain}
                organizationEmail={organizationEmail}
                updateAuth={updateAuth}
                signupLoading={signupLoading}
                organizationLoader={organizationLoader}
              />
            )}
          </div>

          <Typography.Title level={5} className="footer">
            © {new Date().getFullYear()} Echo Consulting, LLC
          </Typography.Title>
        </Col>
      </Row>
    </>
  );
}
