import {
  Button,
  Row,
  Typography,
  Slider,
  Col,
  Collapse,
  Popover,
  Divider,
  Tag,
  message,
  Empty,
  Grid,
  Skeleton,
  Tooltip,
} from 'antd';
import React, { useState, useEffect } from 'react';
import './rate.less';
import '../AllPriorities/idea.less';
import {
  DownOutlined,
  QuestionCircleOutlined,
  InfoCircleOutlined,
  LeftCircleFilled,
  RightCircleFilled,
  CaretRightOutlined,
  CaretLeftOutlined,
} from '@ant-design/icons';
import OpportunityView from '../AllPriorities/OpportunityView';
import { Link, useHistory } from 'react-router-dom';
import { deleteLink, getLinkedIdeas } from '../AllPriorities/IdeaFunction';
import { userRatingsByUserID } from './RateFunctions';
import { useSelector } from 'react-redux';
import {
  getCurrentOrganization,
  getCurrnetUserData,
  getHomeTour,
  getRateDetailTour,
  isSME,
} from '../../store/slices/loginSlice';
import {
  createUserRatingCustom,
  updateUserRatingCustom,
} from 'graphql/customMutation';
import { API } from 'aws-amplify';
import { RESTGetAuthorization, RESTGetRanking } from 'utils/RESTApi';
import { levelColors } from 'utils/Constants';
import OnboardingRateDetail from 'Common/OnboardingRateDetail';
import { getDefaultBatchID } from 'store/slices/compareSlice';
import { deleteUserRatingCustom, listObjectiveCustom } from 'utils/Actions';
import moment from 'moment';

export default function RateDetails() {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const { Paragraph } = Typography;
  const { Panel } = Collapse;
  const history = useHistory();
  const [sliderColor, SetSliderColor] = useState([50]);
  const [opportunityDetail, setOpportunityFields] = useState({});
  const [linkedGamma, setLinkedGamma] = useState([]);
  const [listObjective, setListObjective] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [sliderObjective, setSliderObjective] = useState({});
  const [currentObjective, setCurrentObjective] = useState({});
  const [ratedObjective, setRatedObjective] = useState([]);
  const [ratingValue, setRatingValue] = useState([]);
  const [loading, setLoading] = useState(false);
  const [disableSave, setDisableSave] = useState(false);
  const [rateLoading, setRateLoading] = useState(false);
  const [sliderIndex, setSliderIndex] = useState([]);
  const [ratedObjectiveID, setRatedObjectiveID] = useState();
  const [savedGoal, setSavedGoal] = useState();
  const [formLayoutByGamma, setFormLayoutByGamma] = useState([]);
  const [scaleValue, setScaleValue] = useState({});
  const [animateSave, setAnimateSave] = useState(false);
  const [animationClass, setAnimationClass] = useState('');
  const [newSlider, setNewSlider] = useState(false);
  const [resettRating, setResetRating] = useState(false);

  // collapse status
  const [activeCollapse, setActiveCollapse] = useState(false);
  const RateDetails = history.location.state.data;
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const currnetOrg = useSelector(getCurrentOrganization);
  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;
  const userWeight = loginUsrOrg?.weight || currnetOrg?.weight;
  const userId = currnetOrg?.adminID || loginUsrOrg?.id;
  const priorityDefaultBatchID =
    currnetOrg?.defaultPrioirtybatch?.id ||
    loginUsrOrg?.Organization?.organizationDefaultPrioirtybatchId;

  const fetchDefaultBatchID = useSelector(getDefaultBatchID);

  const defaultPriorityBatchID = fetchDefaultBatchID
    ? fetchDefaultBatchID
    : priorityDefaultBatchID;

  const isSMEGroup = useSelector(isSME);
  const startFirstTimeTour = useSelector(getHomeTour);
  const startRateDetailTour = useSelector(getRateDetailTour);

  const listRelatedIdea = async () => {
    try {
      const res = await getLinkedIdeas({ id: RateDetails?.id });
      setLinkedGamma(res?.data?.getGamma?.linkedTo?.items);
    } catch (error) {
      console.log(error);
    }
  };
  const unLinkIdeas = async (id) => {
    try {
      await deleteLink(id);
      message.success('Gamma unlink successfully');
      await listRelatedIdea();
    } catch (error) {
      console.log(error);
    }
  };
  const createRanking = async () => {
    setLoading(true);
    try {
      const inputData = {
        gammaID: RateDetails?.id,
        organizationID: orgId,
        userID: userId,
        rating: (sliderColor[currentIndex] / 50 - 1) * 100,
        userRatingObjectiveId: currentObjective?.id,
        weight: userWeight,
        organizationID: orgId,
      };
      if (!resettRating && ratedObjectiveID) {
        inputData.id = ratedObjectiveID;
      }
      await API.graphql({
        query: inputData.id ? updateUserRatingCustom : createUserRatingCustom,
        variables: { input: inputData },
      });
      message.success('Objective rated successfully');
      setLoading(false);
      handleNextClick();
      if (!(currentIndex !== listObjective?.items?.length - 1)) {
        setDisableSave(true);
      }
      setAnimateSave(true);
      setResetRating(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const onSkip = async () => {
    if (currentIndex < listObjective?.items?.length - 1) {
      setSliderIndex([...sliderIndex, currentIndex]);
      setCurrentObjective(sliderObjective[currentIndex + 1]);
      setCurrentIndex(currentIndex + 1);
      if (!sliderIndex.includes(currentIndex)) {
        SetSliderColor([...sliderColor, 50]);
      }
      getSavedRanking();
    }
  };

  const getUserRatings = async () => {
    setRateLoading(true);
    try {
      let id = {
        userID: userId,
        gammaID: { eq: RateDetails?.id },
      };
      let temp = [];
      let selectedObjectives = [];
      let notSelectedObjectives = [];
      let res = await userRatingsByUserID(id);
      const filter = {
        organizationObjectivesId: orgId,
        filter: {
          active: { eq: true },
        },
      };
      const obj = await listObjectiveCustom(filter);
      obj?.data?.objectivesByOrganizationObjectivesId?.items?.map((item) => {
        let data = res?.data?.userRatingsByUserIDAndGammaID?.items?.filter(
          (e) => e.userRatingObjectiveId === item?.id
        );
        if (data?.length === 0) {
          notSelectedObjectives.push(item);
        } else {
          selectedObjectives.push(item);
        }
      });
      let combineObjectives = notSelectedObjectives.concat(selectedObjectives);
      setListObjective({ items: combineObjectives });
      setSliderObjective(
        combineObjectives?.map((item) => {
          return { ...item, value: '' };
        })
      );
      setCurrentObjective(combineObjectives?.[0]);
      const matchedData =
        obj?.data?.objectivesByOrganizationObjectivesId?.items?.filter(
          (item1) =>
            res?.data?.userRatingsByUserIDAndGammaID?.items?.some(
              (item2) => item1.id === item2.userRatingObjectiveId
            )
        );

      setRatedObjective(matchedData);

      const ratedGoals =
        res?.data?.userRatingsByUserIDAndGammaID?.items?.filter((item1) =>
          obj?.data?.objectivesByOrganizationObjectivesId?.items?.some(
            (item2) => item1.userRatingObjectiveId === item2.id
          )
        );
      ratedGoals?.forEach((item) => {
        temp.push({
          ...item,
          rate: (item?.rating / 100 + 1) * 50,
        });
      });
      let tempColor = [];
      combineObjectives?.forEach((item) => {
        let data = ratedGoals?.filter(
          (el) => el.userRatingObjectiveId === item.id
        );
        if (data?.length) {
          tempColor.push((data?.[0]?.rating / 100 + 1) * 50);
        } else {
          tempColor.push(50);
        }
      });
      SetSliderColor(tempColor);
      setRatingValue(temp);
      setRateLoading(false);
    } catch (err) {
      console.log(err);
      setRateLoading(false);
    }
  };

  const getSavedRanking = async (id) => {
    try {
      let params = {
        userID: userId,
        gammaID: { eq: RateDetails?.id },
        filter: {
          userRatingObjectiveId: { eq: id?.id },
        },
      };
      let res = await userRatingsByUserID(params);
      setSavedGoal(res?.data?.userRatingsByUserIDAndGammaID?.items?.[0]);
      setRatedObjectiveID(
        res?.data?.userRatingsByUserIDAndGammaID?.items?.[0]?.id
      );
    } catch (err) {
      console.log(err);
    }
  };

  const fetchFormLayoutByGamma = async () => {
    let rankingApis = `/rankings/get?id=${RateDetails?.id}&priorityBatchID=${defaultPriorityBatchID}`;
    let res = await RESTGetAuthorization(rankingApis);
    let tempScaleValue = {};
    res?.body?.layout?.map(async (item) => {
      if (item.type === 'measurement') {
        tempScaleValue[item.id] = item.value;
      }
      return item;
    });
    setScaleValue(tempScaleValue);
    setFormLayoutByGamma(res?.body);
  };

  useEffect(() => {
    listRelatedIdea();
    fetchFormLayoutByGamma();
    getUserRatings();
    // eslint-disable-next-line
  }, []);

  let SliderStyles = null;
  useEffect(() => {
    const Slider = document.querySelector('.rate-slider');
    // eslint-disable-next-line
    SliderStyles = Slider?.style;
    if (sliderColor[currentIndex] >= 0 && sliderColor[currentIndex] < 20) {
      SliderStyles?.setProperty(
        '--OuterDotColor',
        `linear-gradient(180deg, #FC5957 0%, #F93432 100%)`
      );
      SliderStyles?.setProperty(
        '--InnerDotColor',
        `linear-gradient(180deg, #C60807 0%, #ED2623 22.67%, #FF7171 100%)`
      );
    } else if (
      sliderColor[currentIndex] >= 20 &&
      sliderColor[currentIndex] < 40
    ) {
      SliderStyles?.setProperty(
        '--OuterDotColor',
        `linear-gradient(180deg, #FFAD58 0%, #FA8B14 100%)`
      );
      SliderStyles?.setProperty(
        '--InnerDotColor',
        `linear-gradient(180deg, #E48632 0%, #E48632 22.67%, #FEBA71 100%)`
      );
    } else if (
      sliderColor[currentIndex] >= 40 &&
      sliderColor[currentIndex] < 60
    ) {
      SliderStyles?.setProperty(
        '--OuterDotColor',
        `linear-gradient(180deg, #CDCDCD 0%, #858484 100%)`
      );
      SliderStyles?.setProperty(
        '--InnerDotColor',
        `linear-gradient(180deg, #838383 0%, #9A9A9A 22.67%, #C2C2C2 100%)`
      );
    } else if (
      sliderColor[currentIndex] >= 60 &&
      sliderColor[currentIndex] < 80
    ) {
      SliderStyles?.setProperty(
        '--OuterDotColor',
        `linear-gradient(180deg, #7CBF66 0%, #55B04E 100%)`
      );
      SliderStyles?.setProperty(
        '--InnerDotColor',
        `linear-gradient(180deg, #379225 0%, #3DA628 22.67%, #9ACF8F 100%)`
      );
    } else if (
      sliderColor[currentIndex] >= 80 &&
      sliderColor[currentIndex] < 100
    ) {
      SliderStyles?.setProperty(
        '--OuterDotColor',
        `linear-gradient(180deg, #17D971 0%, #0F7D42 100%)`
      );
      SliderStyles?.setProperty(
        '--InnerDotColor',
        `linear-gradient(180deg, #089348 0%, #0E9D51 22.67%, #2CD279 100%)`
      );
    }
  }, [sliderColor, currentIndex]);

  const onChange = (e) => {
    const temp = [...sliderColor];
    temp[currentIndex] = e;
    SetSliderColor(temp);
  };

  const formatter = (e) => {
    if (e >= 0 && e < 20) {
      return 'Very Negative Impact';
    } else if (e >= 20 && e < 40) {
      return 'Negative Impact';
    } else if (e >= 40 && e < 60) {
      return 'Neutral';
    } else if (e >= 60 && e < 80) {
      return 'Positive Impact';
    } else if (e >= 80 && e <= 100) {
      return 'Very positive impact';
    }
  };

  const handlePreviousClick = async () => {
    setAnimateSave(true);
    if (currentIndex >= 1) {
      setCurrentObjective(sliderObjective[currentIndex - 1]);
      setCurrentIndex(currentIndex - 1);
      SetSliderColor(sliderColor);
      setDisableSave(false);
      if (currentObjective?.id) {
        await getSavedRanking(sliderObjective[currentIndex - 1]);
      }
    }
  };

  const handleNextClick = async () => {
    setAnimateSave(true);
    if (currentIndex < listObjective?.items?.length - 1) {
      setSliderIndex([...sliderIndex, currentIndex]);
      setCurrentObjective(sliderObjective[currentIndex + 1]);
      setCurrentIndex(currentIndex + 1);
      if (!sliderIndex.includes(currentIndex)) {
        SetSliderColor([...sliderColor, 50]);
      }
      if (currentObjective?.id) {
        await getSavedRanking(sliderObjective[currentIndex + 1]);
      }
    }
  };

  const rateTooltip = (
    <>
      You can rate the impact you feel this Opportunity or Project will make on
      each goal{' '}
      <a
        href="https://stralign.com/help-rankings"
        target="_blank"
        rel="noopener noreferrer"
      >
        Click here for a video overview.
      </a>
    </>
  );
  const learnMoreTooltip = (
    <>
      Here you will rate the impact you feel this opportunity or project will
      have on each goal. Move the circle on the slider to represent your view.
      <br />
      Positive = darker the green and further right you go the more positive the
      impact on the goal. <br />
      Neutral = you feel that this opportunity will have no impact on the goal
      and you leave the circle in the center.
      <br />
      Negative = darker the red and further left you go the more negative the
      impact on the goal. Need more help?.{' '}
      <a
        href="https://stralign.com/help-rankings"
        target="_blank"
        rel="noopener noreferrer"
      >
        Click here for a video overview.
      </a>
    </>
  );

  const getDotSize = (index) => {
    return index === currentIndex ? 14 : 10;
  };

  const getDotColor = (index) => {
    return index === currentIndex ? '#0070C0' : '#D9D9D9';
  };

  useEffect(() => {
    if (animateSave) {
      setAnimationClass('slide-left');
      const timer = setTimeout(() => {
        setAnimateSave(false);
        setNewSlider(true);
      }, 700);

      return () => clearTimeout(timer);
    }
  }, [animateSave]);

  useEffect(() => {
    if (newSlider) {
      setAnimationClass('slide-right');
      const timer = setTimeout(() => {
        setAnimationClass('');
        setNewSlider(false);
      }, 700);

      return () => clearTimeout(timer);
    }
  }, [newSlider]);

  const [skipAnimation, setSkipAnimation] = useState('');

  useEffect(() => {
    if (skipAnimation) {
      const timer = setTimeout(() => {
        setSkipAnimation('');
        onSkip();
      }, 700);

      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line
  }, [skipAnimation, onSkip]);

  const handleSkip = () => {
    setSkipAnimation('dissolve');
  };
  const [resetLaoder, setResetLoader] = useState(false);

  const onReset = async () => {
    setResetRating(true);
    let temp = [...ratingValue];

    setRatingValue(
      temp.filter((i) => currentObjective?.id !== i.userRatingObjectiveId)
    );
    setSavedGoal(
      temp.filter((i) => currentObjective?.id !== i.userRatingObjectiveId)
    );
    let tempColor = [...sliderColor];
    tempColor[currentIndex] = 50;
    SetSliderColor(tempColor);
    try {
      setResetLoader(true);
      const inputData = {
        id: ratingValue?.filter(
          (goal) => goal?.userRatingObjectiveId === currentObjective?.id
        )?.[0]?.id,
      };
      await deleteUserRatingCustom(inputData);
      message.success('Rating reset successfully');
      setResetLoader(false);
    } catch (err) {
      setResetLoader(false);
      console.log(err);
    }
  };

  return (
    <div className="plr-0 pt-0">
      <Row align="middle" className="breadcrums rate"></Row>

      <Row justify="space-between" align="middle">
        <Col span={24} lg={24} md={24} sm={24} xs={24}>
          <Typography.Title level={5} className="mb-0 mt-10  title">
            Rate the impact this item will have on the following goals
            <Tooltip title={rateTooltip}>
              <QuestionCircleOutlined className="question-icon" />
            </Tooltip>
          </Typography.Title>
        </Col>
      </Row>
      <div className="rate-details opportunity-detail">
        <Typography.Title level={4} className="title fs-24">
          {RateDetails?.title}
        </Typography.Title>
        <Typography.Text level={5} className="mt-5 text fs-16">
          {formLayoutByGamma?.description}
        </Typography.Text>

        <Collapse
          expandIconPosition="end"
          expandIcon={({ isActive }) => {
            setActiveCollapse(isActive);
            return (
              <DownOutlined className="fs-16" rotate={isActive ? 180 : 0} />
            );
          }}
          className="idea"
        >
          <Panel header={activeCollapse ? 'View less' : 'View more'} key="1">
            {/* for stage detail page */}
            <OpportunityView
              opportunityFields={opportunityDetail?.data?.getGamma}
              formLayoutByGamma={formLayoutByGamma}
              scaleValue={scaleValue}
              setScaleValue={setScaleValue}
            />

            <Row gutter={[20, 25]} className="mt-30">
              <Col span={24}>
                <Typography.Title level={5} className="mb-0">
                  Related items
                </Typography.Title>
              </Col>
              {linkedGamma?.length ? (
                linkedGamma?.map((item, index) => {
                  return (
                    <Col span={12} lg={12} md={24} sm={24} xs={24} key={index}>
                      <div className="idea-box idea">
                        <div className="h-100 wrapper">
                          <Row>
                            <Link to="/idea-detail">
                              <Paragraph
                                className="title"
                                ellipsis={{
                                  tooltip: 'Live Chat Support',
                                }}
                              >
                                {item?.linkedGamma?.title}
                              </Paragraph>

                              <Paragraph
                                ellipsis={{
                                  rows: 3,
                                  expandable: false,
                                  symbol: 'more',
                                }}
                              >
                                {item?.linkedGamma?.description}
                              </Paragraph>
                            </Link>
                          </Row>

                          <Row
                            align="middle"
                            justify="space-between"
                            className="w-100"
                          >
                            <Divider />
                            <Row align="middle">
                              <Typography.Title level={5} className="mb-0">
                                <span>ID : </span>{' '}
                                {item?.linkedGamma?.friendlyId
                                  ? item?.linkedGamma?.friendlyId
                                      .toString()
                                      .padStart(6, '0')
                                  : '-'}{' '}
                              </Typography.Title>
                              <Typography.Title
                                level={5}
                                className="mt-0 mb-0 ml-10"
                              >
                                <span>Type : </span>{' '}
                                <Tag
                                  className="tag-font"
                                  key={item?.linkedGamma?.level?.id}
                                  color={
                                    levelColors[item?.linkedGamma?.level?.level]
                                  }
                                >
                                  {item?.linkedGamma?.level?.name}
                                </Tag>
                              </Typography.Title>
                            </Row>

                            <Popover
                              content={'Idea link copied'}
                              placement="bottom"
                              trigger="clicked"
                            >
                              <Button
                                className="unlink-btn fw-bold"
                                onClick={() => unLinkIdeas(item?.id)}
                                size="middle"
                              >
                                Unlink
                              </Button>
                            </Popover>
                          </Row>
                        </div>
                      </div>
                    </Col>
                  );
                })
              ) : (
                <Empty
                  className="empty-data"
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={<span>No Related Items</span>}
                />
              )}
            </Row>
          </Panel>
        </Collapse>
      </div>
      {rateLoading ? (
        <Skeleton.Button
          active
          block={true}
          className="skeleton-button mt-10"
        />
      ) : listObjective?.items?.length ? (
        <>
          <Row align="middle">
            <Button
              disabled={currentIndex === 0}
              className="rate-detail-arrow-btn left-btn"
              icon={
                <CaretLeftOutlined
                  disabled={currentIndex === 0}
                  onClick={handlePreviousClick}
                />
              }
            />
            <div
              className={`${
                ratingValue?.filter(
                  (goal) =>
                    goal?.userRatingObjectiveId === currentObjective?.id &&
                    goal.rate !== 0
                )?.[0]?.updatedAt
                  ? 'rated-slider-box'
                  : 'slider-box'
              } ${animationClass} ${skipAnimation}`}
            >
              <div className="rate-step1">
                <Row justify="space-between" align="middle">
                  <Typography.Title
                    level={3}
                    id="title"
                    className="mb-0 title-fs17"
                  >
                    {currentObjective?.name}
                  </Typography.Title>

                  <Row align="middle" className="mt-0 count-btn rate-step4">
                    <span className="text-primary fw-bolder">
                      {currentIndex + 1}
                    </span>{' '}
                    <Typography.Text>of</Typography.Text>
                    <span>{listObjective?.items?.length}</span>
                  </Row>
                </Row>
                <Row>
                  {ratingValue?.filter(
                    (goal) =>
                      goal?.userRatingObjectiveId === currentObjective?.id
                  )?.[0]?.updatedAt ? (
                    <Typography.Text className="date-text">
                      Last rated{' '}
                      {
                        ratingValue
                          ?.filter(
                            (goal) =>
                              goal?.userRatingObjectiveId ===
                              currentObjective?.id
                          )?.[0]
                          ?.updatedAt?.split('T')[0]
                      }
                    </Typography.Text>
                  ) : savedGoal?.userRatingObjectiveId ===
                      currentObjective?.id && savedGoal?.updatedAt ? (
                    <Typography.Text className="date-text">
                      Last rated{' '}
                      {moment(savedGoal?.updatedAt).format('MM/DD/YYYY')}
                    </Typography.Text>
                  ) : null}
                </Row>
                <Row justify="center" className="mt-20">
                  <Col span={24} md={24} sm={24} xs={24}>
                    <Slider
                      className="rate-slider"
                      onChange={onChange}
                      tooltip={{ open: false }}
                      value={
                        ratingValue?.find(
                          (goal) =>
                            goal?.userRatingObjectiveId === currentObjective?.id
                        )?.rate
                          ? ratingValue?.find(
                              (goal) =>
                                goal?.userRatingObjectiveId ===
                                currentObjective?.id
                            )?.rate
                          : sliderColor[currentIndex]
                      }
                      tipFormatter={formatter}
                    />
                  </Col>
                  <Row justify="space-between" className="w-100">
                    <Col span={10} md={10} sm={10} xs={10} className="mt-10">
                      {/* <Row>
                        <Row align="middle"> */}
                      <div className="one mr-10"></div>
                      <Typography.Title level={5} className="mb-0 mr-10">
                        Negative
                      </Typography.Title>
                      {/* </Row>
                      </Row> */}
                    </Col>
                    <Col span={6} md={6} sm={6} xs={6} className="mt-10">
                      <Row>
                        <Row align="middle">
                          <div className="one mr-10"></div>
                          <Typography.Title level={5} className="mb-0 mr-10">
                            Neutral
                          </Typography.Title>
                        </Row>
                      </Row>
                    </Col>
                    <Col span={6} md={6} sm={6} xs={6} className="mt-10">
                      <Row align="middle" justify="end">
                        <Row align="middle">
                          <div className="five mr-10"></div>
                          <Typography.Title level={5} className="mb-0">
                            Positive
                          </Typography.Title>
                        </Row>
                      </Row>
                    </Col>
                  </Row>
                </Row>
              </div>
              <Row
                justify="space-between"
                align="middle"
                className="mt-30"
                gutter={[0, 20]}
              >
                <Button type="text" className="text-blue p-0">
                  <strong className="mr-10">Learn more</strong>
                  <Tooltip title={learnMoreTooltip}>
                    <InfoCircleOutlined />
                  </Tooltip>
                </Button>
                <Row>
                  {ratingValue?.filter(
                    (goal) =>
                      goal?.userRatingObjectiveId === currentObjective?.id
                  )?.length > 0 && (
                    <Button
                      className="white-btn mr-10 rate-step3"
                      onClick={onReset}
                      size="middle"
                      loading={resetLaoder}
                    >
                      Reset
                    </Button>
                  )}
                  {currentIndex !== listObjective?.items?.length - 1 && (
                    <Button
                      className="white-btn mr-10 rate-step3"
                      onClick={handleSkip}
                      disabled={
                        ratingValue?.filter(
                          (goal) =>
                            goal?.userRatingObjectiveId === currentObjective?.id
                        )?.length > 0
                      }
                      size="middle"
                    >
                      Skip
                    </Button>
                  )}
                  <Button
                    type="primary"
                    className={`blue-filled-btn rate-step2 ${
                      animateSave ? 'slide-right' : ''
                    }`}
                    size="middle"
                    onClick={createRanking}
                    loading={loading}
                    disabled={
                      disableSave ||
                      ratingValue?.filter(
                        (goal) =>
                          goal?.userRatingObjectiveId === currentObjective?.id
                      )?.length > 0
                    }
                  >
                    Save
                  </Button>
                </Row>
              </Row>
            </div>

            <Button
              disabled={currentIndex === listObjective?.items?.length - 1}
              className="rate-detail-arrow-btn right-btn"
              icon={<CaretRightOutlined onClick={handleNextClick} />}
            />
          </Row>
          <div style={{ textAlign: 'center', marginTop: 10 }}>
            {[...Array(listObjective?.items?.length)].map((_, index) => (
              <span
                key={index}
                style={{
                  borderRadius: '50%',
                  width: getDotSize(index),
                  height: getDotSize(index),
                  backgroundColor: getDotColor(index),
                  display: 'inline-block',
                  margin: '0 5px',
                  transition: 'all 0.3s',
                }}
              />
            ))}
          </div>
        </>
      ) : null}
      {((isSMEGroup && startFirstTimeTour) ||
        (isSMEGroup && startRateDetailTour)) && (
        <OnboardingRateDetail listObjective={listObjective} />
      )}
    </div>
  );
}
