import React, { memo, useState } from 'react';
import { useSelector } from 'react-redux';

import { Button, message, Modal, Row, Typography } from 'antd';

import { ECHOIMAGES } from 'Assets/Images';
import {
  getCurrentOrganization,
  isAdmin,
  isSuperAdmin,
} from 'store/slices/loginSlice';
import { updateRole } from '../SuperAdminFun';
import { RESTUpdateRole } from 'utils/RESTApi';

const ActiveUserModal = ({
  setIsActiveModalOpen,
  userID,
  getActiveUsersByOrganization,
  getAllUsers,
  isActiveModalOpen,
  getDisabledUsersByOrganization,
  setSelectedRowKeys,
  selectedRowKeys,
  activeTab,
  setInitalFlag,
}) => {
  const { DeleteIcon } = ECHOIMAGES.superAdminIcons;
  const currnetOrg = useSelector(getCurrentOrganization);
  const isAdminGroup = useSelector(isAdmin);
  const isSuperAdminGroup = useSelector(isSuperAdmin);

  const [loading, setLoading] = useState(false);

  const handleActiveCancel = () => {
    setIsActiveModalOpen(false);
  };

  const handleActiveUser = async () => {
    setLoading(true);

    try {
      if (selectedRowKeys.length) {
        await Promise.all(
          selectedRowKeys.map(async (e) => {
            let activeUser = {
              id: e,
              status: 'ENABLED',
            };
            let disabledUserRest = {
              user_id: e,
              events: ['enable'],
            };
            await RESTUpdateRole(disabledUserRest);
            await updateRole(activeUser);
          })
        );
      } else {
        let activeUser = {
          id: userID,
          status: 'ENABLED',
        };
        let disabledUserRest = {
          user_id: userID,
          events: ['enable'],
        };
        await RESTUpdateRole(disabledUserRest);
        await updateRole(activeUser);
      }
      if (isAdminGroup || currnetOrg?.id) {
        if (activeTab === '1') {
          await getActiveUsersByOrganization();
        } else if (activeTab === '2') {
          await getDisabledUsersByOrganization();
        }
      }
      if (isSuperAdminGroup && currnetOrg === null) {
        await getAllUsers();
      }

      setSelectedRowKeys([]);
      setLoading(false);
      setIsActiveModalOpen(false);
      message.success('User enabled successfully');
      setInitalFlag(true);
    } catch (error) {
      setLoading(false);
      message.error(error.message);
      throw error;
    }
  };
  return (
    <>
      <Modal
        open={isActiveModalOpen}
        onOk={handleActiveUser}
        onCancel={handleActiveCancel}
        confirmLoading={loading}
        centered
        okText="Yes"
        className="delete-user-modal"
      >
        <Row justify="center">
          <img src={DeleteIcon} alt="" />
        </Row>
        <Row justify="center">
          <Typography.Title level={4}>
            Do you really want to enable this user?
          </Typography.Title>
        </Row>
      </Modal>
    </>
  );
};

export default memo(ActiveUserModal);

