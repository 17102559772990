import { message } from 'antd';
import {
  getUserWeightSettingCustom,
  getUserCompareSettingCustom,
  updateUserSettingCustom,
  getUserRatingSettingCustom,
  getDepartmentUserListCustom,
  createDepartmentUserCustom,
  updateDepartmentUserCustom,
} from '../../utils/Actions';

export const getUserWeightSetting = async (id) => {
  try {
    const getWeightList = await getUserWeightSettingCustom(id);
    return getWeightList;
  } catch (error) {
    message.error(error.message);
  }
};

export const updateUserSetting = async (input) => {
  try {
    const addLink = await updateUserSettingCustom(input);
    return addLink;
  } catch (error) {
    message.error(error.message);
  }
};

export const getUserCompareSetting = async (id) => {
  try {
    const getWeightList = await getUserCompareSettingCustom(id);
    return getWeightList;
  } catch (error) {
    message.error(error.message);
  }
};

export const getUserRatingSetting = async (id) => {
  try {
    const getWeightList = await getUserRatingSettingCustom(id);
    return getWeightList;
  } catch (error) {
    message.error(error.message);
  }
};

export const getDepartmentUserList = async (id) => {
  try {
    const departmentList = await getDepartmentUserListCustom(id);
    return departmentList;
  } catch (error) {
    message.error(error.message);
  }
};

export const createDepartmentUser = async (input) => {
  try {
    const addLink = await createDepartmentUserCustom(input);
    return addLink;
  } catch (error) {
    message.error(error?.errors[0]?.message);
  }
};

export const updateDepartmentUser = async (input) => {
  try {
    const addLink = await updateDepartmentUserCustom(input);
    return addLink;
  } catch (error) {
    message.error(error?.errors[0]?.message);
  }
};
