import { message } from 'antd';
import { objectivesByOwnerIdCustom } from '../../utils/Actions';
import { ExecuteQuery, ExecuteQueryCustom } from '../../utils/Api';

export const listGammas = (id) => {
  return ExecuteQuery('gammasByOrganizationIDCustom', undefined, id);
};

export const getGoalCount = async (id) => {
  try {
    const goalCount = await objectivesByOwnerIdCustom(id);
    return goalCount;
  } catch (error) {
    message.error(error.message);
  }
};
