import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Button, Col, Row, Select, Typography, Grid, Input } from 'antd';
import { uniqBy } from 'lodash';

import {
  getOrganizations,
  getUsersByOrganizationIDCustom,
} from './SuperAdminFun';
import { ECHOIMAGES } from '../../Assets/Images';
import {
  getCurrentOrganization,
  getCurrnetUserData,
  isAdmin,
  isSuperAdmin,
} from '../../store/slices/loginSlice';
import { userStatus } from '../../utils/Constants';
import AdminGlobalFilter from './AdminGlobalFilter';
import { searchUsersCustom } from 'utils/Actions';
import ActiveUsersTable from './components/ActiveUsersTable';
import DisableUserModal from './components/DisableUserModal';
import ActiveUserModal from './components/ActiveUserModal';
import AssignRoleModal from './components/AssignRoleModal';
import AssignDepartmentModal from './components/AssignDepartmentModal';
import DeleteUserModal from './components/DeleteUserModal';
import ChangeOrgModal from './components/ChangeOrgModal';
import ChangeUserOrgModal from './components/ChangeUserOrgModal';
import { fetchOrgDepartments } from 'utils/CommonFunctions';
import {
  debounceFn,
  GetFileFromS3,
  searchTextInArrayOfObject,
} from 'utils/commonMethods';
import FilterButton from 'Common/FilterButton';
import { useHistory } from 'react-router-dom';
import { RESTGetManagement } from 'utils/RESTApi';

export default function ActiveUsers({
  userCount,
  setUserCount,
  setInitalFlag,
  activeTab,
}) {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const { Search } = Input;

  const [allUsers, setAllUsers] = useState([]);
  const [allActiveUsers, setAllActiveUsers] = useState([]);
  const [backupAllUsers, setBackupAllUsers] = useState([]);
  const [selectdRole, setSelectedRole] = useState('');
  const [userDataID, setUserDataID] = useState('');
  const [isRoleModalOpen, setIsRoleModalOpen] = useState(false);
  const [isDepartmentModalOpen, setIsDepartmentModalOpen] = useState(false);
  const [selectdDepartment, setSelectedDepartment] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userID, setUserID] = useState('');
  const [isActiveModalOpen, setIsActiveModalOpen] = useState(false);
  const [listAllDepartments, setListAllDepartments] = useState([]);
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const [selectdOrg, setSelectedOrg] = useState('');
  const [selectdOrgid, setSelectedOrgID] = useState('');
  const [organizationModal, setOrganizationModal] = useState(false);
  const [isChangeModal, setIsChangeModal] = useState(false);
  const [organizationDetail, setOrganizationDetail] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadData, setLoadData] = useState(false);

  const isSuperAdminGroup = useSelector(isSuperAdmin);
  const { FilterIcon } = ECHOIMAGES.ideaIcons;
  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;
  const isAdminGroup = useSelector(isAdmin);

  const [nextTokenData, setNextToken] = useState(null);
  const [sortTableOrder, setSortTableOrder] = useState('ASC');
  const [sortTableColumn, setSortTableColumn] = useState('createdAt');
  const [filterChange, setFilterChange] = useState(false);
  const [adminFilter, setAdminFilter] = useState({});
  const [selectedOrganiationID, setSelectedOrganizationID] = useState();
  const [searchField, setSearchField] = useState('');
  const history = useHistory();

  const [currentPage, setCurrentPage] = useState(1);
  const getAllUsers = async (sortOrder, sortColumn, page, filterChange, id) => {
    try {
      let tempPage = page || currentPage;
      let sort_direction = sortTableOrder;
      let sort_field = sortTableColumn;
      if (sortOrder && sortColumn) {
        sort_direction = sortOrder;
        sort_field = sortColumn;
      }
      let data = {
        or: [
          { status: { eq: userStatus.confirm } },
          { status: { eq: userStatus.enable } },
          { status: { eq: userStatus.active } },
          { status: { eq: userStatus.disabled } },
        ],
      };
      let response = await searchUsersCustom(data);
      let activeUser =
        response?.data?.searchUsers?.aggregateItems?.[0]?.result?.buckets.find(
          (item) => item?.key === userStatus.active
        );
      let disabledUser =
        response?.data?.searchUsers?.aggregateItems?.[0]?.result?.buckets.find(
          (item) => item?.key === userStatus.disabled
        );
      setUserCount({
        ...userCount,
        activeUserLength: activeUser?.doc_count || 0,
        disabledUserLength: disabledUser?.doc_count || 0,
      });

      let inputData = {
        organization_id: orgId,
        sort_field: sort_field,
        sort_direction: sort_direction,
        scope: 'SUPERADMIN',
        page: tempPage,
        filters: adminFilter,
      };

      adminFilter.Status = activeTab == 1 ? 'ACTIVE' : 'DISABLED';
      if (id !== 'All') {
        adminFilter.Organization = id;
      } else {
        delete adminFilter.Organization;
      }

      let res = await RESTGetManagement(inputData);
      const users = res?.body || [];
      let allActiveUsers = filterChange
        ? users
        : tempPage > 1
        ? [...allUsers, ...users]
        : users;
      setAllUsers(allActiveUsers);
      setBackupAllUsers(allActiveUsers);
      setLoading(false);
      setLoadData(false);
    } catch (err) {
      console.log(err);
    }
  };
  //for admin
  const getActiveUsersByOrganization = async (
    sortOrder,
    sortColumn,
    page,
    filterChange,
    search
  ) => {
    let tempPage = page || currentPage;
    let list = [];
    try {
      let sort_direction = sortTableOrder;
      let sort_field = sortTableColumn;
      if (sortOrder && sortColumn) {
        sort_direction = sortOrder;
        sort_field = sortColumn;
      }
      let filter = {
        organizationID: { eq: orgId },
      };
      if (search === '') {
        delete adminFilter.Search;
      } else {
        adminFilter.Search = search;
      }

      let inputData = {
        organization_id: orgId,
        sort_field: sort_field,
        sort_direction: sort_direction,
        scope: 'ADMIN',
        page: tempPage,
        filters: adminFilter,
      };
      adminFilter.Status = activeTab == 1 ? 'ACTIVE' : 'DISABLED';
      let res = await RESTGetManagement(inputData);

      const users = res?.body;
      list = filterChange
        ? users
        : tempPage > 1
        ? [...allActiveUsers, ...users]
        : users;

      setAllActiveUsers(list);
      setBackupAllUsers(list);

      let totalUsersCount = await searchUsersCustom(filter);
      let activeUser =
        totalUsersCount?.data?.searchUsers?.aggregateItems?.[0]?.result?.buckets.find(
          (item) => item?.key === userStatus.active
        );
      let disabledUser =
        totalUsersCount?.data?.searchUsers?.aggregateItems?.[0]?.result?.buckets.find(
          (item) => item?.key === userStatus.disabled
        );
      let invitedUser =
        totalUsersCount?.data?.searchUsers?.aggregateItems?.[0]?.result?.buckets.find(
          (item) => item?.key === userStatus.invited
        );
      setUserCount({
        ...userCount,
        activeUserLength: activeUser?.doc_count || 0,
        disabledUserLength: disabledUser?.doc_count || 0,
        invitesUserLength: invitedUser?.doc_count || 0,
      });

      setLoading(false);
      setLoadData(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const sortTableData = async (sortData) => {
    let sortOrder = sortData.order === 'ascend' ? 'ASC' : 'DESC';
    let sortColumn = sortData.field;
    setSortTableOrder(sortOrder);
    setSortTableColumn(sortColumn);
    setCurrentPage(1);
    if (isSuperAdminGroup && currnetOrg === null) {
      await getAllUsers(sortOrder, sortColumn, 1, true, selectedOrganiationID);
    }
    if (isAdminGroup || currnetOrg?.id) {
      await getActiveUsersByOrganization(
        sortOrder,
        sortColumn,
        1,
        true,
        searchField
      );
    }
  };

  const handelInfiniteScroll = async (e) => {
    const { target } = e;
    const isBottom =
      Math.round(target.scrollHeight) - Math.round(target.scrollTop) ===
      Math.round(target.clientHeight);
    if (isAdminGroup || currnetOrg?.id) {
      if (isBottom) {
        setCurrentPage((prevPage) => prevPage + 1);
        setLoadData(true);
        await getActiveUsersByOrganization(
          sortTableOrder,
          sortTableColumn,
          currentPage + 1,
          null,
          searchField
        );
      }
    }
    if (isSuperAdminGroup && currnetOrg === null) {
      if (isBottom && allUsers?.length > 0) {
        setCurrentPage((prevPage) => prevPage + 1);
        setLoadData(true);
        await getAllUsers(
          sortTableOrder,
          sortTableColumn,
          currentPage + 1,
          null,
          selectedOrganiationID
        );
      }
    }
  };

  const handleChange = async (e) => {
    setSelectedOrganizationID(e);
    await getAllUsers(sortTableOrder, sortTableColumn, 1, true, e);
  };

  const getSignedUrlImg = async (fileKey) => {
    const signedURL = await GetFileFromS3(fileKey, {
      level: 'public',
    });
    return signedURL;
  };

  const listOrgDepartments = async () => {
    const departments = await fetchOrgDepartments(orgId);
    setListAllDepartments(departments);
  };
  const onSearch = async (searchText) => {
    if (searchText !== '') {
      setCurrentPage(1);
      setSearchField(searchText);
      await getActiveUsersByOrganization(
        sortTableOrder,
        sortTableColumn,
        1,
        true,
        searchText
      );
    } else {
      setCurrentPage(1);
      setSearchField('');
      await getActiveUsersByOrganization(
        sortTableOrder,
        sortTableColumn,
        1,
        true,
        ''
      );
    }
  };

  const debouncedHandleInput = debounceFn(onSearch, 1000);

  const handleInputSearch = (searchText) => {
    debouncedHandleInput(searchText);
  };

  const fetchOrganizations = async () => {
    try {
      const data = await getOrganizations();
      if (data?.length) {
        setOrganizationDetail(data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (isAdminGroup || currnetOrg?.id) {
      getActiveUsersByOrganization();
    }
    if (isSuperAdminGroup && currnetOrg === null) {
      getAllUsers();
      fetchOrganizations();
    }
    listOrgDepartments();
    // eslint-disable-next-line
  }, []);

  //drawer stuff
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="background-white p-20 idea">
        <Row justify="center">
          <Col span={24}>
            <Row justify="space-between" align="middle">
              {currnetOrg?.id || isAdminGroup ? (
                <Search
                  className="searchbar-style"
                  placeholder="Search"
                  onChange={(e) => handleInputSearch(e.target.value)}
                />
              ) : null}
              {isSuperAdminGroup && !currnetOrg?.id ? (
                <div className="filter-div">
                  <div className="dropdown-div">
                    <Typography.Title level={5} className="mb-0">
                      Select Organization :{' '}
                    </Typography.Title>{' '}
                    <Select
                      showSearch
                      className="select ml-10 h-35"
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                      onChange={handleChange}
                      options={[
                        {
                          label: 'All',
                          value: 'All',
                        },

                        ...uniqBy(
                          [
                            ...organizationDetail.map((item) => {
                              return {
                                label: item?.name,
                                value: item?.id,
                              };
                            }),
                          ],
                          'label'
                        ),
                      ]}
                    />
                  </div>
                  <FilterButton onClick={showDrawer} />
                </div>
              ) : null}

              {currnetOrg?.id || isAdminGroup ? (
                <div className="filter-add-btn">
                  <FilterButton onClick={showDrawer} />
                  <Button
                    type="primary"
                    size="middle"
                    onClick={() => history.push('/add-user')}
                    className="ml-10"
                  >
                    Add User
                  </Button>
                  {/* <Button
                    className="ml-10 h-35 filter-btn border-color"
                    onClick={showDrawer}
                  >
                    <img
                      src={FilterIcon}
                      alt=""
                      className={screens.xs ? '' : 'mr-10'}
                    />{' '}
                    {screens.xs ? '' : 'Filter'}
                  </Button> */}
                </div>
              ) : null}
            </Row>
          </Col>
        </Row>
        <ActiveUsersTable
          setOrganizationModal={setOrganizationModal}
          setIsRoleModalOpen={setIsRoleModalOpen}
          allActiveUsers={allActiveUsers}
          allUsers={allUsers}
          setUserDataID={setUserDataID}
          setSelectedOrg={setSelectedOrg}
          setSelectedOrgID={setSelectedOrgID}
          setSelectedRole={setSelectedRole}
          setIsModalOpen={setIsModalOpen}
          setUserID={setUserID}
          setIsDepartmentModalOpen={setIsDepartmentModalOpen}
          setSelectedDepartment={setSelectedDepartment}
          setDeleteUserModal={setDeleteUserModal}
          loading={loading}
          userID={userID}
          handleScroll={handelInfiniteScroll}
          loadData={loadData}
          sortTableData={sortTableData}
          sortOrder={sortTableOrder}
          sortColumn={sortTableColumn}
        />
      </div>

      {/* Disable user modal  */}
      <DisableUserModal
        isModalOpen={isModalOpen}
        userID={userID}
        getActiveUsersByOrganization={getActiveUsersByOrganization}
        getAllUsers={getAllUsers}
        setIsModalOpen={setIsModalOpen}
        setInitalFlag={setInitalFlag}
      />

      {/* Active user modal  */}
      <ActiveUserModal
        isActiveModalOpen={isActiveModalOpen}
        userID={userID}
        getActiveUsersByOrganization={getActiveUsersByOrganization}
        getAllUsers={getAllUsers}
        setIsActiveModalOpen={setIsActiveModalOpen}
        activeTab={activeTab}
      />

      {/* Filter Drawer  */}
      <AdminGlobalFilter
        tab="active"
        onClose={onClose}
        open={open}
        setListAllDepartments={setListAllDepartments}
        listAllDepartments={listAllDepartments}
        backupAllUsers={backupAllUsers}
        setAllActiveUsers={setAllActiveUsers}
        setOpen={setOpen}
        activeTab={activeTab}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        sortTableColumn={sortTableColumn}
        sortTableOrder={sortTableOrder}
        setFilterChange={setFilterChange}
        filterChange={filterChange}
        setAdminFilter={setAdminFilter}
        searchField={searchField}
        setAllUsers={setAllUsers}
      />
      {/* Assign Role Modal*/}
      <AssignRoleModal
        isRoleModalOpen={isRoleModalOpen}
        setIsRoleModalOpen={setIsRoleModalOpen}
        getActiveUsersByOrganization={getActiveUsersByOrganization}
        getAllUsers={getAllUsers}
        userDataID={userDataID}
        selectdRole={selectdRole}
        setSelectedRole={setSelectedRole}
      />
      <AssignDepartmentModal
        isDepartmentModalOpen={isDepartmentModalOpen}
        setIsDepartmentModalOpen={setIsDepartmentModalOpen}
        userDataID={userDataID}
        selectdDepartment={selectdDepartment}
        setSelectedDepartment={setSelectedDepartment}
        listAllDepartments={listAllDepartments}
        getActiveUsersByOrganization={getActiveUsersByOrganization}
      />
      <DeleteUserModal
        deleteUserModal={deleteUserModal}
        userID={userID}
        getActiveUsersByOrganization={getActiveUsersByOrganization}
        getAllUsers={getAllUsers}
        setDeleteUserModal={setDeleteUserModal}
      />
      <ChangeOrgModal
        organizationModal={organizationModal}
        setIsChangeModal={setIsChangeModal}
        setOrganizationModal={setOrganizationModal}
        organizationDetail={organizationDetail}
        selectdOrg={selectdOrg}
        setSelectedOrg={setSelectedOrg}
      />
      <ChangeUserOrgModal
        isChangeModal={isChangeModal}
        getAllUsers={getAllUsers}
        setIsChangeModal={setIsChangeModal}
        userDataID={userDataID}
        selectdOrgid={selectdOrgid}
        selectdOrg={selectdOrg}
      />
    </>
  );
}

