import { Skeleton } from 'antd';
import React from 'react';
import './custom.less';

function FormSkeleton() {
  return (
    <Skeleton.Button
      active
      block={true}
      className="skeleton-loader formSekeleton"
    />
  );
}

export default FormSkeleton;

