import { Divider } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import Joyride from 'react-joyride';
import { getRateDetailTour, setRateDetailTour } from 'store/slices/loginSlice';
import { useDispatch, useSelector } from 'react-redux';
import { ECHOIMAGES } from 'Assets/Images';

export default function OnboardingRateDetail({ listObjective }) {
  const [runTour, setRunTour] = useState(false);
  const startRateDetailTour = useSelector(getRateDetailTour);
  const dispatch = useDispatch();

  useEffect(() => {
    if (listObjective?.items?.length > 0) {
      setRunTour(startRateDetailTour);
    }
  }, [listObjective, startRateDetailTour]);

  const handleJoyrideCallback = (data) => {
    if (data.type === 'step:after' && data.action === 'close') {
      // The user closed the tooltip, stop the tour
      localStorage.setItem('firstTimeinRate', false);
      setRunTour(false);
      dispatch(setRateDetailTour(false));
    }
  };

  const steps = [
    {
      target: '.rate-step1',
      spotlightClicks: true,
      content: (
        <div className="joyride-style">
          <div className="title-content">Rate the Impact You Think</div>
          <div
            className="details-content"
            style={{ fontWeight: '400px !important' }}
          >
            Here you will rate the impact you feel this opportunity or project
            will have on each goal. Move the circle on the slider to represent
            your view.
          </div>
          <Divider />
          <div
            style={{
              position: 'absolute',
              left: '0px',
              right: '0px',
              bottom: '10px',
              color: '#0070C0',
            }}
          >
            1/4
          </div>
        </div>
      ),
      disableBeacon: true,
      placement: 'right',
    },
    {
      target: '.rate-step2',
      content: (
        <div className="joyride-style">
          <div className="title-content">Save Your Impact</div>
          <div className="details-content">
            Click on “Save” to store your rating on the goal.
          </div>
          <Divider />
          <div
            style={{
              position: 'absolute',
              left: '0px',
              right: '0px',
              bottom: '10px',
              color: '#0070C0',
            }}
          >
            2/4
          </div>
        </div>
      ),
      disableBeacon: true,
    },
    {
      target: '.rate-step3',
      content: (
        <div className="joyride-style">
          <div className="title-content">Don't be concerned </div>
          <div className="details-content">
            You may skip the comparison if it does not apply to you.
          </div>
          <Divider />
          <div
            style={{
              position: 'absolute',
              left: '0px',
              right: '0px',
              bottom: '10px',
              color: '#0070C0',
            }}
          >
            3/4
          </div>
        </div>
      ),
      disableBeacon: true,
      styles: {
        options: {
          width: 300, // Adjust the width of the highlight
          height: 900,
        },
      },
    },
    {
      target: '.rate-step4',
      content: (
        <div className="joyride-style">
          <div className="title-content">Rate Through Goals</div>
          <div className="details-content">
            You can jump through various goals by using the next and previous
            arrows, and you can save your impact by rating goals.
          </div>
          <Divider />
          <div
            style={{
              position: 'absolute',
              left: '0px',
              right: '0px',
              bottom: '10px',
              color: '#0070C0',
            }}
          >
            4/4
          </div>
        </div>
      ),
      disableBeacon: true,
      spotlightClicks: true,
      styles: {
        options: {
          width: 300, // Adjust the width of the highlight
          height: 900,
        },
      },
    },
    {
      target: '.idea-step1',
      content: (
        <div className="joyride-style">
          <div className="title-content">Craft Your Idea</div>
          <div className="details-content">
            Please provide details about your idea. Also you can link your idea
            with similar concepts.
          </div>
        </div>
      ),
      disableBeacon: true,
      hideBackButton: true,
      spotlightClicks: true,
      placement: 'right',
    },
    {
      target: '.idea-step2',
      content: (
        <div className="joyride-style">
          <div className="title-content">Rate Through Goals</div>
          <div className="details-content">
            You can jump through various goals by using the next and previous
            arrows, and you can save your impact by rating goals.
          </div>
        </div>
      ),
      disableBeacon: true,
      hideBackButton: true,
      spotlightClicks: true,
      placement: 'left',
    },
    {
      target: '.idea-step3',
      content: (
        <div className="joyride-style">
          <div className="title-content">Craft Your Idea</div>
          <div className="details-content">
            Please provide details about your idea. Also you can link your idea
            with similar concepts.
          </div>
        </div>
      ),
      disableBeacon: true,
      hideBackButton: true,
      spotlightClicks: true,
      placement: 'right',
    },
  ];

  const { NextArrow, BackArrow } = ECHOIMAGES.icons;

  const customLocale = {
    back: (
      <span
        style={{
          position: 'absolute',
          left: '20px',
          color: 'black',
          bottom: '13px',
        }}
      >
        <img src={BackArrow} alt="" />
      </span>
    ),
    next: (
      <span
        style={{
          backgroundColor: 'transparent !important',
          color: 'black',
        }}
      >
        <img
          src={NextArrow}
          alt=""
          style={{
            position: 'absolute',
            right: '20px',
            bottom: '13px',
          }}
        />
      </span>
    ),
    skipWithCount: (current, total) => (
      <span>
        {current} of {total}
      </span>
    ),
  };
  return (
    <Joyride
      steps={steps}
      run={runTour}
      continuous
      showProgress
      disableCloseOnEsc
      disableOverlayClose
      locale={customLocale}
      disableScrolling
      callback={handleJoyrideCallback}
    />
  );
}

