import { ExecuteQuery, ExecuteMutation } from 'utils/Api';

export const updateOrganization = (inputData) => {
  return ExecuteMutation('updateOrganizationCustom', inputData);
};

export const listUsersComparisonCount = (id) => {
  return ExecuteQuery('listUsersComparisonCount', undefined, id);
};

export const listUsersRatingCount = (id) => {
  return ExecuteQuery('listUsersRatingCount', undefined, id);
};

