import React from 'react';
import { Col, Row, Tabs } from 'antd';
import GeneralSettings from './GeneralSettings';
import NotificationSettings from './NotificationSettings';
import './settings.less';
import Department from './Department';
import { useHistory } from 'react-router-dom';
import CompanyDetails from './Components/CompanyDetails';
import CustomBranding from './Components/CustomBranding';
import { getIsPartner } from '../../store/slices/loginSlice';
import { useSelector } from 'react-redux';

export default function Settings() {
  const history = useHistory();
  const departmentTab = history?.location?.state?.departmentTab || false;
  const isPartner = useSelector(getIsPartner);
  const items = [
    {
      key: '1',
      label: <span>Company Details</span>,
      children: <CompanyDetails />,
      isPartnerOnly: true,
    },
    {
      key: '2',
      label: <span>Customize Branding</span>,
      children: <CustomBranding />,
      isPartnerOnly: true,
    },
    {
      key: '3',
      label: <span>General Settings</span>,
      children: <GeneralSettings />,
    },
    {
      key: '4',
      label: <span>Department</span>,
      children: <Department />,
    },
    {
      key: '5',
      label: <span>Notification Settings</span>,
      children: <NotificationSettings />,
    },
  ];

  const filteredItems = items.filter(
    (item) => !item.isPartnerOnly || (item.isPartnerOnly && isPartner)
  );

  return (
    <div className="background-white p-20">
      <Row justify="center">
        <Col span={24}>
          <Tabs
            defaultActiveKey={departmentTab ? '4' : '1'}
            items={filteredItems}
            tabPosition="left"
            className="setting-tab"
          />
        </Col>
      </Row>
    </div>
  );
}

