import {
  Layout,
  Menu,
  Row,
  Button,
  Badge,
  Avatar,
  Dropdown,
  Typography,
  Drawer,
  Modal,
} from 'antd';
import { ECHOIMAGES } from 'Assets/Images';
import React, { memo, useEffect, useState } from 'react';
import SubMenu from 'antd/lib/menu/SubMenu';
import { Link, useParams, useLocation } from 'react-router-dom';
import {
  BellOutlined,
  UserOutlined,
  SettingOutlined,
  ArrowLeftOutlined,
} from '@ant-design/icons';
import Dashboard from 'Pages/Dashboard/Dashboard';

const BrandingPreview = ({
  previewModal,
  setPreviewModal,
  customLogo,
  brandingLogoByOrgId,
  brandingByOrgId,
  orgDetails,
}) => {
  const { Home, Compare, Rate, AllPriorities, Goal } = ECHOIMAGES.sidebarIcons;
  const { Header, Content, Footer, Sider } = Layout;
  const { helpIcon } = ECHOIMAGES.icons;
  const { id } = useParams();

  const [selectedKey, setSelectedKey] = useState('');
  const location = useLocation();

  const initialHeaderMenu = [
    {
      name: 'Home',
      path: '/',
      key: 'Home',
      icon: <img className="sidebar-icon" src={Home} alt="" />,
    },
    {
      name: 'Goals',
      path: '/goal',
      key: 'goal',
      icon: <img className="sidebar-icon" src={Goal} alt="" />,
    },
    {
      name: 'Rankings',
      path: '/all-priorities',
      key: 'all-priorities',
      icon: <img className="sidebar-icon" src={AllPriorities} alt="" />,
    },
    {
      name: 'Compare',
      path: '/compare',
      key: 'compare',
      icon: <img className="sidebar-icon" src={Compare} alt="" />,
    },

    {
      name: 'Rate',
      path: '/rate',
      key: 'rate',
      icon: <img className="sidebar-icon" src={Rate} alt="" />,
    },
  ];
  useEffect(() => {
    switch (window.location.pathname) {
      case `/organization/add-organization`:
        setSelectedKey('Home');
        break;
      case `/organization/edit-organization/${id}`:
        setSelectedKey('Home');
        break;
      case `/organization/detail/${id}`:
        setSelectedKey('Home');
        break;
      case `/settings`:
        setSelectedKey('Home');
        break;
      case `/partner/add-partner`:
        setSelectedKey('Home');
        break;
      case `/partner/detail/${id}`:
        setSelectedKey('Home');
        break;
      case `/partner/edit-partner/${id}`:
        setSelectedKey('Home');
        break;
      default:
        setSelectedKey(() => window.location.pathname.replace('/', ''));
        break;
    }
    // eslint-disable-next-line
  }, [location]);

  return (
    <Modal
      open={previewModal}
      onCancel={() => setPreviewModal(false)}
      width={1200}
      footer={null}
      style={{ height: '500px' }}
    >
      <Typography.Title level={4}>Branding Preview</Typography.Title>
      <Layout
        className="layout pointer-none"
        style={{ backgroundColor: brandingByOrgId?.navigation_color }}
      >
        <Sider
          className={
            location?.pathname === '/stagepreview/' ||
            location?.pathname === '/stagepreview' ||
            location?.pathname === '/stage1preview'
              ? 'sidebar desktop-sidebar blur-menu'
              : 'sidebar desktop-sidebar'
          }
          breakpoint="lg"
          collapsedWidth="0"
          style={{
            backgroundColor:
              brandingByOrgId?.navigation_color ||
              brandingByOrgId?.navHeaderColor,
          }}
        >
          <div className="image-wrapper">
            <img
              src={customLogo || brandingLogoByOrgId}
              alt=""
              className="logo"
            />
          </div>
          <Menu
            selectedKeys={selectedKey}
            theme="dark"
            mode="inline"
            className="my-first-step"
          >
            {initialHeaderMenu?.map((item) =>
              item?.subMenu?.length ? (
                <SubMenu
                  key={item.key}
                  icon={item.icon}
                  id={item.key}
                  title={item?.name}
                >
                  {item.subMenu.map((subItem, index) => (
                    <Menu.Item key={subItem.key} icon={subItem?.icon}>
                      <Link to={subItem.path}>{subItem.name}</Link>
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={item.key} icon={item.icon} id={item.key}>
                  <Link>{item.name}</Link>
                </Menu.Item>
              )
            )}
          </Menu>

          <Footer className="footer ant-footer-inline">
            <div>
              {process.env.REACT_APP_VERSION
                ? process.env.REACT_APP_VERSION
                : '2.0'}
            </div>
            © {new Date().getFullYear()}{' '}
            {orgDetails?.organization?.name || brandingByOrgId?.name}
            <div>
              <span className="mt-10">Powered by StrAlign</span>
            </div>
          </Footer>
        </Sider>

        <Drawer
          title={<img src={customLogo} alt="" className="logo" />}
          placement="left"
          className="layout sidebar-drawer"
          closeIcon={<></>}
        >
          <div className="sidebar">
            <Menu theme="dark" mode="inline">
              {initialHeaderMenu?.map((item) =>
                item?.subMenu?.length ? (
                  <SubMenu
                    key={item.key}
                    icon={item.icon}
                    id={item.key}
                    title={item?.name}
                  >
                    {item.subMenu.map((subItem, index) => (
                      <Menu.Item key={subItem.key} icon={subItem?.icon}>
                        <Link to={subItem.path}> {subItem.name}</Link>
                      </Menu.Item>
                    ))}
                  </SubMenu>
                ) : (
                  <Menu.Item key={item.key} icon={item.icon} id={item.key}>
                    <Link to={item.path}>{item.name}</Link>
                  </Menu.Item>
                )
              )}
            </Menu>
            <Footer className="footer ant-footer-inline">
              <div>
                {process.env.REACT_APP_VERSION
                  ? process.env.REACT_APP_VERSION
                  : '2.0'}
              </div>
              © {new Date().getFullYear()} Echo Consulting, LLC
            </Footer>
          </div>
        </Drawer>

        <Layout>
          <Header
            className={
              location?.pathname === '/stagepreview/' ||
              location?.pathname === '/stage1preview'
                ? 'main-haeder blur-menu'
                : 'main-haeder'
            }
          >
            <Row align="middle" justify="space-between">
              <Row align="middle" justify="center">
                <Typography.Title
                  level={4}
                  className="mb-0 title text-black fw-600 header-text"
                >
                  <Typography.Title level={5} className="mb-0">
                    <ArrowLeftOutlined className="mr-5 header-back-icon" />
                    Home
                  </Typography.Title>
                  {initialHeaderMenu.map((item) =>
                    item?.subMenu?.length
                      ? item?.subMenu?.map(
                          (i) => window.location.pathname === i.path && i.name
                        )
                      : window.location.pathname === item.path && item.name
                  )}
                </Typography.Title>
              </Row>
              <Row align="middle">
                <Button type="text" className="p-0 ml-15">
                  <SettingOutlined className="fs-20" />
                </Button>
                <Button
                  type="text"
                  className="ml-15 w-auto"
                  onClick={() => window.open('https://stralign.com/help')}
                  icon={<img src={helpIcon} alt="" />}
                ></Button>

                <Badge size="small">
                  <Button type="text" className="p-0 ml-15 mr-15">
                    <BellOutlined className="fs-20" />
                  </Button>
                </Badge>

                <Dropdown trigger={['click']}>
                  <span className="cursor-pointer fs-16 capital-text">
                    <span className="mr-10">
                      {localStorage.getItem('given_name')}
                    </span>

                    <Avatar icon={<UserOutlined />}></Avatar>
                  </span>
                </Dropdown>
              </Row>
            </Row>
          </Header>
          <Content className="main" id="main">
            <Dashboard />
          </Content>
        </Layout>
      </Layout>
    </Modal>
  );
};

export default memo(BrandingPreview);

