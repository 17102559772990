import { message } from 'antd';
import {
  createHiddenUsersAndHiddenGammasCustom,
  deleteHiddenUsersAndHiddenGammasCustom,
  createUserRatingCustom,
  userRatingsByUserIDCustom,
} from '../../utils/Actions';

export const createHiddenGamma = async (data) => {
  try {
    const hideGamma = await createHiddenUsersAndHiddenGammasCustom(data);
    return hideGamma;
  } catch (error) {
    message.error(error.message);
  }
};
export const deleteHideGammas = async (id) => {
  try {
    const UnhideGamma = await deleteHiddenUsersAndHiddenGammasCustom(id);
    return UnhideGamma;
  } catch (error) {
    message.error(error.message);
  }
};

export const createUserRating = async (data) => {
  try {
    const createRate = await createUserRatingCustom(data);
    return createRate;
  } catch (error) {
    message.error(error.message);
  }
};

export const userRatingsByUserID = async (id) => {
  try {
    const objectiveRated = await userRatingsByUserIDCustom(id);
    return objectiveRated;
  } catch (error) {
    message.error(error.message);
  }
};
