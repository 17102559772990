import { Button, message, Row } from 'antd';
import React, { memo, useEffect, useState } from 'react';
import IntegrationSteps from './IntegrationSteps';
import SecretKeyModal from './SecretKeyModal';
import { useSelector } from 'react-redux';
import {
  getCurrentOrganization,
  getCurrnetUserData,
} from 'store/slices/loginSlice';
import { RESTCreatApiKey } from 'utils/RESTApi';
import { apiKeysByOrganizationIDCustom } from '../Services/IntegrationAction';

const IntegrationDetail = () => {
  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [secretKey, setSecretKey] = useState([]);

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const createSecretKey = async () => {
    setLoading(true);
    try {
      let input = {
        organization_id: orgId,
      };
      await RESTCreatApiKey(input);
      message.success('Secret key generated successfully');
      let res = await apiKeysByOrganizationIDCustom({ organizationID: orgId });
      setSecretKey(res);
      setLoading(false);
      handleModalOpen();
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const getSecretKey = async () => {
    try {
      let res = await apiKeysByOrganizationIDCustom({ organizationID: orgId });
      setSecretKey(res);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getSecretKey();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <div className="background-white">
        <Row justify="space-between" className="align-center">
          <div className="zapier-platform-page-title-container">
            <div className="fs-18">Zapier Integration</div>
          </div>
          <Button
            type="primary"
            className="fw-bold"
            onClick={
              secretKey?.items?.length > 0 ? handleModalOpen : createSecretKey
            }
            loading={loading}
            size="middle"
          >
            {secretKey?.items?.length > 0
              ? 'Secret Key'
              : 'Generate Secret Key'}
          </Button>
        </Row>
      </div>
      <IntegrationSteps />
      <SecretKeyModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        secretKey={secretKey}
        getSecretKey={getSecretKey}
      />
    </>
  );
};

export default memo(IntegrationDetail);

