export const listOrganizationsCustom = /* GraphQL */ `
  query ListOrganizations(
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        gammaCount
        adminID
        stages {
          items {
            form {
              layout
            }
            id
            level
            name
            active
          }
        }
        Admin {
          id
          email
          firstName
          lastName
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
        }
        defaultPrioirtybatch {
          id
          title
          public
          updatedAtBy
          createdAt
          updatedAt

          organizationID
        }
        Users {
          items {
            id
            email
            firstName
            lastName
            status
            role
            organizationID
            Organization {
              id
              name
            }
            createdAt
            updatedAt
          }
          nextToken
        }

        winningCriteria
        weightSettings {
          role
          weight
        }
        scoreWeightages
        objectives {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listUsersCustom = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        organizationID
        Organization {
          id
          name
        }
        LoginHistory(limit: 3, sortDirection: DESC) {
          items {
            datetime
          }
        }
        firstName
        lastName
        status
        role
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listGammasCustom = /* GraphQL */ `
  query ListGammas(
    $filter: ModelGammaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGammas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        friendlyId
        levelHistory {
          items {
            changedAt
            createdAt
          }
        }
        hiddenUsers {
          items {
            id
            userId
            gammaId
            createdAt
            updatedAt
          }
          nextToken
        }

        level {
          id
          name
          level
        }
        title
        description
        userID
        organizationID
        Organization {
          id
          name

          gammaCount
          winningCriteria
          scoreWeightages
          createdAt
          updatedAt
        }

        UserVotes {
          nextToken
        }
        user {
          id
          email
          firstName
          lastName
          designation

          status
          weight
          organizationID

          createdAt
          updatedAt
        }

        sponsor {
          id
          email
          firstName
          lastName
          designation

          status
          role
          weight
          organizationID

          createdAt
          updatedAt
        }
        userRatings {
          items {
            id
          }
        }

        createdAt
        updatedAt
        ownerEmail
        linkedTo {
          items {
            linkedGamma {
              id
              description
              title
              level {
                level
                id
                name
              }
              friendlyId
            }
            id
          }
        }
      }
      nextToken
    }
  }
`;

export const listUserRolesCustom = `
query ListRoles{
    __type(name: "USER_ROLE") {
      name
      enumValues {
        name
      }
  }
}
`;

export const getGammaLinkCustom = /* GraphQL */ `
  query GetGamma($id: ID!) {
    getGamma(id: $id) {
      linkedTo {
        items {
          linkedGamma {
            id
            description
            title
            level {
              id
              level
              name
            }
            friendlyId
          }
          id
        }
      }
    }
  }
`;

export const getUserCustom = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      email
      firstName
      lastName
      lastLogin
      designation
      photo
      status
      role
      weight
      organizationID
      LoginHistory {
        items {
          userID
          date
          datetime
          createdAt
          updatedAt
        }
      }
      Organization {
        id
        name
        gammaCount
        winningCriteria
        scoreWeightages
        createdAt
        updatedAt
        type
        organizationDefaultPrioirtybatchId
      }
      departmentID
      Department {
        id
        name
        organizationID
        createdAt
        updatedAt
      }
      managerID
      Manager {
        id
        email
        firstName
        lastName
        role
        weight
        organizationID
        departmentID
        managerID
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

export const getGammaCustom = /* GraphQL */ `
  query GetGamma($id: ID!) {
    getGamma(id: $id) {
      id
      friendlyId
      rank
      level {
        id
        name
        level
      }
      title
      description
      userID
      organizationID
      createdAt
      updatedAt
    }
  }
`;

export const getOrganizationCustom = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      name
      vision {
        key
        value
      }
      type
      scenarioWeightsFlag
      categoryValues
      Departments {
        items {
          id
          name
          organizationID
          createdAt
          updatedAt
        }
        nextToken
      }
      adminID
      gammaCount
      defaultPrioirtybatch {
        id
        title
        priorities {
          weight
          ObjectiveID
        }
        public

        owners
        updatedAtBy
        createdAt
        updatedAt

        organizationID
      }
      stages {
        items {
          form {
            layout
          }
          id
          level
          name
          active
        }
      }
      priorityBatches {
        items {
          id
          title
          public
          owners
          updatedAtBy
          createdAt
          updatedAt
          organizationID
        }
        nextToken
      }
      winningCriteria
      scoreWeightages
      objectives {
        items {
          id
          name
          description
          ownerId
          createdAt
          updatedAt
          organizationObjectivesId
          owner {
            id
            email
          }
        }
        nextToken
      }

      createdAt
      updatedAt
      organizationDefaultPrioirtybatchId
    }
  }
`;

export const listObjectivesCustom = /* GraphQL */ `
  query objectivesByOrganizationObjectivesId(
    $organizationObjectivesId: ID!
    $filter: ModelObjectiveFilterInput
    $limit: Int
    $nextToken: String
  ) {
    objectivesByOrganizationObjectivesId(
      organizationObjectivesId: $organizationObjectivesId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        active
        Departments {
          items {
            id
            department {
              name
              id
            }
            departmentID
          }
        }
        ownerId
        createdAt
        updatedAt
        organizationObjectivesId
        owner {
          id
          email
          firstName
          lastName
        }
      }
      nextToken
    }
  }
`;

export const getObjectiveCustom = /* GraphQL */ `
  query GetObjective($id: ID!) {
    getObjective(id: $id) {
      id
      name
      description
      active
      Departments {
        items {
          id
          department {
            name
            id
          }
          departmentID
        }
      }
      Organization {
        id
        name
        gammaCount
        winningCriteria
        scoreWeightages
        createdAt
        updatedAt
        organizationDefaultPrioirtybatchId
      }
      ownerId
      createdAt
      updatedAt
      organizationObjectivesId
      owner {
        id
        email
        firstName
        lastName
      }
    }
  }
`;

export const listDepartmentsCustom = /* GraphQL */ `
  query ListDepartments(
    $filter: ModelDepartmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDepartments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        organizationID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const objectivesByOwnerIdCustom = /* GraphQL */ `
  query ObjectivesByOwnerId(
    $ownerId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelObjectiveFilterInput
    $limit: Int
    $nextToken: String
  ) {
    objectivesByOwnerId(
      ownerId: $ownerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
      }
    }
  }
`;

export const searchGammasCustom = /* GraphQL */ `
  query SearchGammas(
    $filter: SearchableGammaFilterInput
    $sort: [SearchableGammaSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableGammaAggregationInput]
  ) {
    searchGammas(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      total
    }
  }
`;

export const searchGammasCountCustom = /* GraphQL */ `
  query SearchGammas(
    $filter: SearchableGammaFilterInput
    $sort: [SearchableGammaSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableGammaAggregationInput]
  ) {
    searchGammas(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      total
      aggregateItems {
        result {
          ... on SearchableAggregateBucketResult {
            __typename
            buckets {
              doc_count
              key
            }
          }
        }
      }
    }
  }
`;
export const seacrhUsersCustom = /* GraphQL */ `
  query SearchUsers(
    $filter: SearchableUserFilterInput
    $nextToken: String
    $limit: Int
    $sort: [SearchableUserSortInput]
  ) {
    searchUsers(
      aggregates: { field: status, type: terms, name: "ACTIVE" }
      filter: $filter
      nextToken: $nextToken
      limit: $limit
      sort: $sort
    ) {
      total
      aggregateItems {
        result {
          ... on SearchableAggregateBucketResult {
            __typename
            buckets {
              doc_count
              key
            }
          }
        }
      }
    }
  }
`;

export const seacrhUsersOrganizationCustom = /* GraphQL */ `
  query SearchUsers(
    $filter: SearchableUserFilterInput
    $sort: [SearchableUserSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchUsers(
      aggregates: {
        field: organizationID
        type: terms
        name: "65226aab-039a-45d4-b909-58f39393dc25"
      }
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      total
      nextToken
      aggregateItems {
        result {
          ... on SearchableAggregateBucketResult {
            __typename
            buckets {
              doc_count
              key
            }
          }
        }
      }
    }
  }
`;

export const DepartmentUseresCustom = /* GraphQL */ `
  query SearchUsers($filter: SearchableUserFilterInput) {
    searchUsers(
      aggregates: {
        field: departmentID
        type: terms
        name: "65226aab-039a-45d4-b909-58f39393dc25"
      }
      filter: $filter
    ) {
      total
      aggregateItems {
        result {
          ... on SearchableAggregateBucketResult {
            __typename
            buckets {
              doc_count
              key
            }
          }
        }
      }
    }
  }
`;

export const searchUserVotesCustom = /* GraphQL */ `
  query SearchUserVotes(
    $filter: SearchableUserVoteFilterInput
    $sort: [SearchableUserVoteSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchUserVotes(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: {
        field: gammaID
        type: terms
        name: "41ac3324-2cd2-40e4-8e4c-ee9008e6b8ea"
      }
    ) {
      total
      aggregateItems {
        result {
          ... on SearchableAggregateBucketResult {
            __typename
            buckets {
              doc_count
              key
            }
          }
        }
      }
    }
  }
`;

export const searchUserRatingsCustom = /* GraphQL */ `
  query SearchUserRatings(
    $filter: SearchableUserRatingFilterInput
    $sort: [SearchableUserRatingSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchUserRatings(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: {
        field: gammaID
        name: "41ac3324-2cd2-40e4-8e4c-ee9008e6b8ea"
        type: terms
      }
    ) {
      total
      aggregateItems {
        result {
          ... on SearchableAggregateBucketResult {
            __typename
            buckets {
              doc_count
            }
          }
        }
      }
    }
  }
`;

export const notificationByUserCustom = /* GraphQL */ `
  query NotificationByUser(
    $userID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    NotificationByUser(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Message
        isRead
        type
        userID
        metadata
        UserDetails {
          id
          email
          firstName
          lastName
          designation

          status
          role
          weight
          organizationID

          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getUserWeightSettingCustom = /* GraphQL */ `
  query getOrganization($id: ID!) {
    getOrganization(id: $id) {
      weightSettings {
        role
        weight
      }
      id
    }
  }
`;

export const getUserCompareSettingCustom = /* GraphQL */ `
  query getOrganization($id: ID!) {
    getOrganization(id: $id) {
      comparisonFlags
    }
  }
`;

export const getUserRatingSettingCustom = /* GraphQL */ `
  query getOrganization($id: ID!) {
    getOrganization(id: $id) {
      ratingFlags
    }
  }
`;

export const departmentsByOrganizationIDCustom = /* GraphQL */ `
  query DepartmentsByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDepartmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    departmentsByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        Manager {
          firstName
          lastName
          id
        }
      }
    }
  }
`;

export const getDepartmentUserListCustom = /* GraphQL */ `
  query getOrganization($id: ID!) {
    getOrganization(id: $id) {
      Departments {
        items {
          id
          name
          Manager {
            firstName
            lastName
            managerID
          }
          managerID
          Users {
            items {
              email
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const usersByOrganizationIDCustom = /* GraphQL */ `
  query UsersByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        firstName
        lastName
        contact
        photo
        status
        role
        weight
        organizationID
        departmentID
        managerID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getInviteandInactiveSettingCustom = /* GraphQL */ `
  query getOrganization($id: ID!) {
    getOrganization(id: $id) {
      invitationReminderConfig
      inactivityReminderConfig
    }
  }
`;
export const userRatingsByUserIDCustom = /* GraphQL */ `
  query UserRatingsByUserIDAndGammaID(
    $userID: ID!
    $gammaID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserRatingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userRatingsByUserIDAndGammaID(
      userID: $userID
      gammaID: $gammaID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        gammaID
        rating
        weight
        userRatingObjectiveId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const fuzzySearchGammasCustom = /* GraphQL */ `
  query FuzzySearchGammas(
    $title: String!
    $organizationID: String!
    $limit: Int
    $nextToken: String
  ) {
    fuzzySearchGammas(
      title: $title
      organizationID: $organizationID
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        friendlyId
        level {
          level
          id
          name
        }
        title
        description
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const listAllGammaTypeRolesCustom = `
query ListRoles{
    __type(name: "GammaType") {
      name
      enumValues {
        name
      }
  }
}
`;

export const getGammaRatingCustom = /* GraphQL */ `
  query GetGamma($id: ID!) {
    getGamma(id: $id) {
      id
      rank
      fixedRank
      friendlyId
      level {
        id
        name
        level
      }
      title
      description
      userID
      organizationID
      Organization {
        id
        name
        gammaCount
        winningCriteria
        scoreWeightages
        objectives(filter: { active: { eq: true } }) {
          items {
            id
            name
          }
        }
        createdAt
        updatedAt
        organizationDefaultPrioirtybatchId
      }
    }
  }
`;

export const loginHistoriesByUserIDAndDateCustom = /* GraphQL */ `
  query LoginHistoriesByUserIDAndDate(
    $userID: ID!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLoginHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    loginHistoriesByUserIDAndDate(
      userID: $userID
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        datetime
      }
      nextToken
    }
  }
`;

export const usersByDepartmentIDCustom = /* GraphQL */ `
  query UsersByDepartmentID(
    $departmentID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByDepartmentID(
      departmentID: $departmentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        firstName
        lastName
        role
        Manager {
          id
          email
          firstName
          lastName
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
        }
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt

          __typename
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const stagesByOrganizationIDCustom = /* GraphQL */ `
  query StagesByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelStageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    stagesByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        active
        level
        form {
          layout
          __typename
        }
        draft {
          items {
            name
            layout
            version
            updatedByIAM
            stageID
            organizationID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const getStageCustom = /* GraphQL */ `
  query GetStage($id: ID!) {
    getStage(id: $id) {
      id
      name
      active
      level
      form {
        layout
        __typename
      }
      draft {
        items {
          name
          layout
          version
          updatedByIAM
          stageID
          organizationID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
    }
  }
`;

export const gammasByLevelIDCustom = /* GraphQL */ `
  query GammasByLevelID(
    $levelID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelGammaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    gammasByLevelID(
      levelID: $levelID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
      }
    }
  }
`;

export const gammasByOrganizationIDCustom = /* GraphQL */ `
  query GammasByOrganizationID(
    $organizationID: ID!
    $fixedRank: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGammaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    gammasByOrganizationID(
      organizationID: $organizationID
      fixedRank: $fixedRank
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        friendlyId
        level {
          id
          name
          level
        }
        title
        description
      }
      nextToken
      __typename
    }
  }
`;

export const apiKeysByOrganizationIDCustom = /* GraphQL */ `
  query ApiKeysByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelApiKeyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    apiKeysByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        key
        keyID
        disabled
        createdAt
        updatedAt
        __typename
      }
    }
  }
`;

export const measurementFieldsByOrganizationIDCustom = /* GraphQL */ `
  query MeasurementFieldsByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMeasurementFieldFilterInput
    $limit: Int
    $nextToken: String
  ) {
    measurementFieldsByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        type
        subType
        breakpoints {
          label
          value
          __typename
        }
        organizationID

        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const getMeasurementFieldCustom = /* GraphQL */ `
  query GetMeasurementField($id: ID!) {
    getMeasurementField(id: $id) {
      id
      name
      description
      type
      subType
      breakpoints {
        label
        value
        __typename
      }
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const searchMeasurementFieldsCustom = /* GraphQL */ `
  query SearchMeasurementFields(
    $filter: SearchableMeasurementFieldFilterInput
    $sort: [SearchableMeasurementFieldSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableMeasurementFieldAggregationInput]
  ) {
    searchMeasurementFields(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        name
        description
        type
        subType
        breakpoints {
          label
          value
        }
        organizationID
        createdAt
        updatedAt
      }
    }
  }
`;

export const searchPriorityBatchesCustom = /* GraphQL */ `
  query SearchPriorityBatches(
    $filter: SearchablePriorityBatchFilterInput
    $sort: [SearchablePriorityBatchSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchablePriorityBatchAggregationInput]
  ) {
    searchPriorityBatches(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      total
    }
  }
`;

export const listPartnerCustom = /* GraphQL */ `
  query ListOrganizations(
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
      }
      nextToken
    }
  }
`;

export const searchOrganizationsCustom = /* GraphQL */ `
  query SearchOrganizations(
    $filter: SearchableOrganizationFilterInput
    $sort: [SearchableOrganizationSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableOrganizationAggregationInput]
  ) {
    searchOrganizations(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        name
      }
    }
  }
`;

