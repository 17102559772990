import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { Avatar, Dropdown, Table, Typography, Menu, Grid, Spin } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import moment from 'moment';

import { getCurrentOrganization, isSuperAdmin } from 'store/slices/loginSlice';
import { options, UserRole } from 'utils/Constants';
import { getLastLoginDate } from 'utils/CommonFunctions';
import TableSkeleton from 'Common/TableSkeleton';

const DisabledUserTable = ({
  selectedRowKeys,
  setSelectedRowKeys,
  allDisabledUsers,
  allUsers,
  showActiveModal,
  setUserID,
  loading,
  handleScroll,
  loadData,
  sortTableData,
  sortOrder,
  sortColumn,
}) => {
  const { Text } = Typography;
  const currnetOrg = useSelector(getCurrentOrganization);
  const isSuperAdminGroup = useSelector(isSuperAdmin);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  //for admin
  // table columns
  const adminColumns = [
    {
      title: 'Name',
      dataIndex: 'firstName',
      key: 'firstName',
      sortOrder:
        sortColumn === 'firstName'
          ? sortOrder === 'ASC'
            ? 'ascend'
            : 'descend'
          : null,
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a, b) => {
        return a?.firstName?.localeCompare(b?.firstName);
      },
      render: (text, row) => {
        return (
          <>
            {row?.photo ? (
              <Image
                className="mr-10 "
                style={{
                  width: '30px',
                  height: '30px',
                  lineHeight: '30px',
                  borderRadius: '100px',
                }}
                src={row?.imageURL}
              />
            ) : (
              <Avatar
                size={35}
                alt=""
                className="mr-10 table-font avtar-style"
                style={{ width: '30px', height: '30px', lineHeight: '30px' }}
              >
                {row?.firstName?.[0]?.toUpperCase()}
              </Avatar>
            )}
            <span className="table-font">
              {row?.firstName === null
                ? '-'
                : row?.firstName + ' ' + row?.lastName}
            </span>
          </>
        );
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => {
        return a?.email.localeCompare(b?.email);
      },
      sortOrder:
        sortColumn === 'email'
          ? sortOrder === 'ASC'
            ? 'ascend'
            : 'descend'
          : null,
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a, b) => {
        return a?.email.localeCompare(b?.email);
      },
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, row) => {
        return (
          <div>
            <Text
              style={
                true
                  ? {
                      width: 120,
                    }
                  : undefined
              }
              ellipsis={
                true
                  ? {
                      tooltip: <span>{row?.email}</span>,
                    }
                  : false
              }
              className="table-font"
            >
              {row?.email}
            </Text>
          </div>
        );
      },
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      sorter: (a, b) => {
        return a?.role.localeCompare(b?.role);
      },
      sortOrder:
        sortColumn === 'role'
          ? sortOrder === 'ASC'
            ? 'ascend'
            : 'descend'
          : null,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, row) => {
        let roleName = text === UserRole.sme ? UserRole?.teamMember : text;
        return (
          <>
            <span className="mr-10 table-font">{roleName}</span>
          </>
        );
      },
    },
    {
      title: 'Department',
      dataIndex: 'department',
      key: 'department',
      sorter: (a, b) => {
        return a?.Department?.name?.localeCompare(b?.Department?.name);
      },
      sortOrder:
        sortColumn === 'department'
          ? sortOrder === 'ASC'
            ? 'ascend'
            : 'descend'
          : null,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, row) => {
        return (
          <>
            {' '}
            <span className="mr-10 table-font">{row?.Department?.name}</span>
          </>
        );
      },
    },
    {
      title: 'Manager',
      dataIndex: 'Manager',
      key: 'manager',
      render: (text, row) => (
        <span className="table-font">
          {row?.Manager
            ? row?.Manager?.firstName + ' ' + row?.Manager?.lastName
            : '-'}
        </span>
      ),
    },
    {
      title: 'User Weight',
      dataIndex: 'weight',
      key: 'weight',
      sorter: (a, b) => {
        return a?.weight - b?.weight;
      },
      sortOrder:
        sortColumn === 'weight'
          ? sortOrder === 'ASC'
            ? 'ascend'
            : 'descend'
          : null,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, row) =>
        text
          ? text
          : row?.role === UserRole.admin ||
            row?.role === UserRole.leader ||
            row?.role === UserRole.sme
          ? row?.Organization?.weightSettings.filter(
              (el) => el.role === row?.role
            )[0]?.weight
          : row?.role === UserRole.superAdmin
          ? row?.Organization?.weightSettings.filter(
              (el) => el.role === UserRole.leader
            )[0]?.weight
          : null,
    },
    {
      title: 'Created on',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sortOrder:
        sortColumn === 'createdAt'
          ? sortOrder === 'ASC'
            ? 'ascend'
            : 'descend'
          : null,

      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      render: (text, row) => (
        <span className="table-font">{moment(text).format('l')}</span>
      ),
    },
    {
      title: 'Last Login',
      dataIndex: 'lastLogin',
      key: 'lastLogin',
      sortOrder:
        sortColumn === 'lastLogin'
          ? sortOrder === 'ASC'
            ? 'ascend'
            : 'descend'
          : null,
      sorter: (a, b) => a.lastLogin - b.lastLogin,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, row) => {
        return (
          <span className="table-font">
            <div>
              <Text
                style={
                  true
                    ? {
                        width: 120,
                      }
                    : undefined
                }
                ellipsis={
                  true
                    ? {
                        tooltip: <span> {moment(text).format('l')}</span>,
                      }
                    : false
                }
                className="table-font"
              >
                {row.lastLogin ? moment(text).format('l') : '-'}
              </Text>
            </div>
          </span>
        );
      },
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      fixed: 'right',
      width: 80,
      render: (text, row) => (
        <Dropdown
          overlay={
            <Menu
              items={[
                {
                  label: <span>Enable</span>,
                  key: '1',
                  onClick: () => {
                    showActiveModal();
                    setUserID(row?.id);
                  },
                },
              ]}
            />
          }
          trigger={['click']}
        >
          <MoreOutlined style={{ color: '#5AACC9' }} className="dot-style" />
        </Dropdown>
      ),
    },
  ];

  // table columns
  const superAdmincolumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 350,
      render: (text, row) => <span className="table-font">{text}</span>,
    },
    {
      title: 'Organization',
      dataIndex: 'organization',
      key: 'organization',
      sortOrder:
        sortColumn === 'organization'
          ? sortOrder === 'ASC'
            ? 'ascend'
            : 'descend'
          : null,
      sorter: (a, b) => {
        return a - b;
      },
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, row) => (
        <span className="table-font">{row?.Organization?.name}</span>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => {
        return a?.email.localeCompare(b?.email);
      },
      sortOrder:
        sortColumn === 'email'
          ? sortOrder === 'ASC'
            ? 'ascend'
            : 'descend'
          : null,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, row) => {
        return (
          <div>
            <Text
              style={
                true
                  ? {
                      width: 120,
                    }
                  : undefined
              }
              ellipsis={
                true
                  ? {
                      tooltip: <span>{row?.email}</span>,
                    }
                  : false
              }
              className="table-font"
            >
              {row?.email}
            </Text>
          </div>
        );
      },
    },
    {
      title: 'Name',
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: (a, b) => {
        return a?.firstName?.localeCompare(b?.firstName);
      },
      sortDirections: ['ascend', 'descend', 'ascend'],
      sortOrder:
        sortColumn === 'firstName'
          ? sortOrder === 'ASC'
            ? 'ascend'
            : 'descend'
          : null,
      render: (text, row) => (
        <span className="table-font">
          {row?.firstName === null ? '-' : row?.firstName + ' ' + row?.lastName}
        </span>
      ),
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      sorter: (a, b) => {
        return a?.role?.localeCompare(b?.role);
      },
      sortDirections: ['ascend', 'descend', 'ascend'],
      sortOrder:
        sortColumn === 'role'
          ? sortOrder === 'ASC'
            ? 'ascend'
            : 'descend'
          : null,
      render: (text, row) => {
        let roleName = text === UserRole.sme ? UserRole?.teamMember : text;
        return (
          <>
            <span className="mr-10 table-font">{roleName}</span>
          </>
        );
      },
    },
    {
      title: 'Onboarding Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, row) => <span className="table-font">{text}</span>,
    },
    {
      title: 'Created on',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      sortOrder:
        sortColumn === 'createdAt'
          ? sortOrder === 'ASC'
            ? 'ascend'
            : 'descend'
          : null,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, row) => (
        <span className="table-font">{moment(text).format('l')}</span>
      ),
    },
    {
      title: 'Last Login',
      dataIndex: 'lastLogin',
      key: 'lastLogin',
      sortOrder:
        sortColumn === 'lastLogin'
          ? sortOrder === 'ASC'
            ? 'ascend'
            : 'descend'
          : null,
      sorter: (a, b) => moment(a.lastLogin).unix() - moment(b.lastLogin).unix(),
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, row) => {
        return (
          <span className="table-font">
            <div>
              <Text
                style={
                  true
                    ? {
                        width: 120,
                      }
                    : undefined
                }
                ellipsis={
                  true
                    ? {
                        tooltip: (
                          <span>
                            {' '}
                            {getLastLoginDate(row, {
                              options,
                            })}
                          </span>
                        ),
                      }
                    : false
                }
                className="table-font"
              >
                {getLastLoginDate(row, {
                  options,
                })}
              </Text>
            </div>
          </span>
        );
      },
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      fixed: 'right',
      width: 80,
      render: (text, row) => (
        <Dropdown
          overlay={
            <Menu
              items={[
                {
                  label: <span>Enable</span>,
                  key: '1',
                  onClick: () => {
                    showActiveModal();
                    setUserID(row?.id);
                  },
                },
              ]}
            />
          }
          trigger={['click']}
        >
          <MoreOutlined style={{ color: '#5AACC9' }} className=" dot-style" />
        </Dropdown>
      ),
    },
  ];

  return (
    <>
      {loading ? (
        <>
          <TableSkeleton />
          <TableSkeleton />
        </>
      ) : (
        <div
          style={{ maxHeight: 'calc(100vh - 140px)', overflowY: 'auto' }}
          onScroll={handleScroll}
        >
          <Table
            onChange={(pagination, filter, sorter) => {
              sortTableData(sorter);
            }}
            rowSelection={rowSelection}
            className="admin-table mt-20"
            columns={
              currnetOrg
                ? adminColumns
                : isSuperAdminGroup
                ? superAdmincolumns
                : adminColumns
            }
            dataSource={
              currnetOrg
                ? allDisabledUsers
                : isSuperAdminGroup
                ? allUsers
                : allDisabledUsers
            }
            pagination={false}
            scroll={{
              x: 1700,
            }}
            sticky={{
              offsetHeader: 0,
            }}
            size={screens.xxl ? 'middle' : 'small'}
          />
        </div>
      )}
      {loadData && (
        <Spin style={{ display: 'flex', justifyContent: 'center' }} />
      )}
    </>
  );
};

export default memo(DisabledUserTable);

