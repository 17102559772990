import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Slider,
  Tooltip,
  Typography,
  Upload,
} from 'antd';
import React, { memo, useEffect, useState } from 'react';
import {
  QuestionCircleOutlined,
  UploadOutlined,
  InfoCircleOutlined,
  CloseSquareOutlined,
} from '@ant-design/icons';
import RelatedItems from 'Pages/AllPriorities/RelatedItems';
import { useSelector, useDispatch } from 'react-redux';
import {
  getformElementUpdate,
  getStageName,
  setBackButtonClicked,
  setPreviewFlag,
} from 'store/slices/StageSlice';
import { useHistory, useLocation } from 'react-router-dom';
import { capitalizeFirstLetter } from 'utils/Constants';
import { RESTGet } from 'utils/RESTApi';
import {
  getCurrentOrganization,
  getCurrnetUserData,
} from 'store/slices/loginSlice';

const Stage1Preview = () => {
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const { Dragger } = Upload;
  const updatedFormElement = useSelector(getformElementUpdate);
  const getStageNameElement = useSelector(getStageName);
  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;

  const location = useLocation();
  const history = useHistory();
  const source = location?.state?.source;
  const dispatch = useDispatch();

  const goBack = () => {
    if (source === 'list') {
      history.push('/stages');
    } else if (source === 'detail') {
      history.push({
        pathname: `/stage1/${getStageNameElement?.id}`,
        state: {
          prevForm: getStageNameElement,
        },
      });
    } else {
      history.push({
        pathname: `/stage1-draft-form/${getStageNameElement?.id}`,
        state: {
          prevForm: getStageNameElement,
        },
      });
    }
    dispatch(setBackButtonClicked(false));
    dispatch(setPreviewFlag(false));
  };

  const [mfList, setMFList] = useState([]);

  const getMFList = async () => {
    try {
      let listApi = `/measurement/all?organization_id=${orgId}`;
      let res = await RESTGet(listApi);
      setMFList(res?.body?.items);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getMFList();
    // eslint-disable-next-line
  }, []);

  const secondArrayMap = mfList.reduce((acc, obj) => {
    acc[obj.id] = obj;
    return acc;
  }, {});

  // Retrieve details from second array for matching ids in first array
  const details = updatedFormElement.map(
    (item) => secondArrayMap[item.id] || item
  );

  const marks = {};

  details?.forEach((firstItem) => {
    const matchingItem = mfList?.find(
      (secondItem) => secondItem.id === firstItem.id
    );

    if (
      matchingItem &&
      matchingItem.breakpoints &&
      matchingItem.breakpoints.length > 0
    ) {
      marks[firstItem.id] = {
        0: matchingItem?.breakpoints[0]?.label, // Label at 0
        25: matchingItem?.breakpoints[1]?.label, // Label at 25
        50: matchingItem?.breakpoints[2]?.label, // Label at 50
        75: matchingItem?.breakpoints[3]?.label, // Label at 75
        100: matchingItem?.breakpoints[4]?.label, // Label at 100
      };
    } else {
      marks[firstItem.id] = {
        0: 'Label 0',
        25: 'Label 25',
        50: 'Label 50',
        75: 'Label 75',
        100: 'Label 100',
      };
    }
  });

  const marks1 = {};
  // const numericMarks = () => {
  details?.forEach((firstItem) => {
    const matchingItem = mfList?.find(
      (secondItem) => secondItem.id === firstItem.id
    );

    marks1[firstItem.id] = {
      0: matchingItem?.breakpoints[0]?.value, // Label at 0
      25: matchingItem?.breakpoints[0]?.value,
      50: matchingItem?.breakpoints[0]?.value,
      75: matchingItem?.breakpoints[0]?.value,
      100: matchingItem?.breakpoints[1]?.value, // Label at 100
    };
  });

  return (
    <div className="add-idea plr-0 pt-0">
      <Typography.Title level={5} className="cross-icon">
        <CloseSquareOutlined className="fs-30" onClick={goBack} />
      </Typography.Title>

      <Row gutter={[20, 20]} className="idea-create">
        <Col span={12} md={12} xs={24} sm={24}>
          <div className="background-white">
            <Typography.Title level={3} className="title capitalize-text fs-20">
              Add {capitalizeFirstLetter(getStageNameElement?.name)}{' '}
              <Tooltip>
                <QuestionCircleOutlined className="question-icon" />
              </Tooltip>
            </Typography.Title>
            <Form layout="vertical" form={form} requiredMark={true}>
              {details?.map((item, index) => {
                return (
                  <>
                    {item.inputType === 'singleLine' && (
                      <Form.Item
                        label={<span>{item.label}</span>}
                        name="Ideatitle"
                        label={
                          <Row>
                            <Typography.Title
                              level={5}
                              className="mb-0 p fw-400"
                            >
                              {item.label}
                            </Typography.Title>
                            {item.tooltip && (
                              <Tooltip title={item.tooltip}>
                                <InfoCircleOutlined className="tooltip-icon ml-5" />
                              </Tooltip>
                            )}
                          </Row>
                        }
                        rules={[
                          {
                            required: item.required,
                            message: `Please Enter ${item?.label}!`,
                          },
                        ]}
                      >
                        <Input
                          placeholder={item.placeholder}
                          className="input-font"
                          readOnly
                        />
                      </Form.Item>
                    )}
                    {item.inputType == 'multiLine' && (
                      <Form.Item
                        name="description"
                        label={
                          <p>
                            {item.label}{' '}
                            {item.maxChars !== '' &&
                              item.maxChars !== undefined && (
                                <span className="text-gray">
                                  (Max. {item.maxChars} characters limit)
                                </span>
                              )}
                          </p>
                        }
                        rules={[
                          {
                            required: item.required,
                            message: `Please enter ${item?.label}!`,
                          },
                          {
                            max: item.maxChars,
                            message: 'Max characters limit reached',
                          },
                        ]}
                      >
                        <TextArea
                          autoSize={{
                            minRows: 5,
                            maxRows: 5,
                          }}
                          maxLength={item.maxChars}
                          placeholder={item.placeholder}
                          className="input-font"
                          readOnly
                        />
                      </Form.Item>
                    )}
                    {item.inputType === 'dropdown' && (
                      <Form.Item
                        label={<span>{item.label}</span>}
                        name="Department"
                        rules={[
                          {
                            required: item.required,
                            message: `Please Enter ${item?.label}!`,
                          },
                        ]}
                      >
                        {' '}
                        {item.dropdownValue === 'Multiple' ? (
                          <Select
                            className="input-font"
                            placeholder={item.placeholder}
                            mode="multiple"
                            style={{
                              pointerEvents: 'none',
                              background: '#f5f5f5',
                            }}
                          >
                            <Select.Option></Select.Option>
                          </Select>
                        ) : (
                          <Select
                            className="input-font"
                            placeholder={item.placeholder}
                            style={{
                              pointerEvents: 'none',
                              background: '#f5f5f5',
                            }}
                          >
                            {' '}
                            <Select.Option readOnly></Select.Option>
                          </Select>
                        )}
                      </Form.Item>
                    )}
                    {item.inputType === 'datepicker' && (
                      <Form.Item
                        label={<span>{item?.label}</span>}
                        name="DatePicker"
                        rules={[
                          {
                            required: item.required,
                            message: `Please Enter ${item?.label}!`,
                          },
                        ]}
                      >
                        <DatePicker
                          className="w-100"
                          placeholder={item.placeholder}
                        />
                      </Form.Item>
                    )}
                    {item.inputType === 'link' && (
                      <Form.Item
                        label={<span>{item?.label}</span>}
                        name="link"
                        rules={[
                          {
                            required: item.required,
                            message: `Please Enter ${item?.label}!`,
                          },
                        ]}
                      >
                        <Input placeholder={item.placeholder} />
                      </Form.Item>
                    )}
                    {item.inputType === 'symbol' && (
                      <Form.Item
                        label={
                          <span>
                            {item?.label}{' '}
                            {item.tooltip && (
                              <Tooltip title={item.tooltip}>
                                <InfoCircleOutlined className="tooltip-icon ml-5" />
                              </Tooltip>
                            )}
                          </span>
                        }
                        name="symbol"
                        rules={[
                          {
                            required: item.required,
                            message: `Please Enter ${item?.label}!`,
                          },
                        ]}
                      >
                        <Select
                          mode="multiple"
                          placeholder={item?.placeholder}
                          style={{
                            pointerEvents: 'none',
                          }}
                        ></Select>
                      </Form.Item>
                    )}

                    {item.inputType === 'dragger' && (
                      <Form.Item
                        label={<span>{item?.label}</span>}
                        name="link"
                        rules={[
                          {
                            required: item.required,
                            message: `Please Enter ${item?.label}!`,
                          },
                        ]}
                      >
                        <Dragger maxCount={5}>
                          <p className="ant-upload-drag-icon">
                            <UploadOutlined />
                          </p>
                          <p className="ant-upload-text">
                            Drag and drop files, or{' '}
                            <span className="text-primary">Browse</span>
                          </p>
                          <p className="ant-upload-hint">
                            Supported format: Word, Excel, PPT, Google Sheets
                            with max size of 5 MB. Max 5 files can be upload
                          </p>
                        </Dragger>
                      </Form.Item>
                    )}
                    {(item?.subType === '+-' || item?.subType === '-+') && (
                      <>
                        <Typography.Text>{item?.name}</Typography.Text>

                        <Col className="p-0 mf-slider mb-10 h-120" span={24}>
                          <Slider
                            key={item.id}
                            className={
                              item?.subType === '+-'
                                ? 'rate-slider slider-change-1 m-0'
                                : 'rate-slider slider-change m-0'
                            }
                            value={50}
                            marks={marks[item?.id]}
                            disabled
                          />
                        </Col>
                      </>
                    )}
                    {(item?.subType === '$' || item?.subType === '#') && (
                      <>
                        <Typography.Text>{item?.name}</Typography.Text>
                        <Col
                          className="p-0 preview-slider mb-10 measurement-slider"
                          span={24}
                        >
                          <Slider
                            tooltip={{ open: true }}
                            // step={null}
                            className="numeric-slider mt-45"
                            marks={marks1[item?.id]}
                            tipFormatter={(value) => {
                              const prefix = item?.subType === '$' ? '$' : '#';
                              return `${prefix}${marks1[item?.id][0]}`;
                            }}
                            disabled
                          />
                        </Col>
                      </>
                    )}
                  </>
                );
              })}

              <Form.Item className="mb-0">
                <Button
                  type="primary"
                  className="blue-filled-btn"
                  size="middle"
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Col>
        <Col span={12} md={12} xs={0} sm={24}>
          <RelatedItems />
        </Col>
      </Row>
    </div>
  );
};
export default memo(Stage1Preview);

