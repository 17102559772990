import { Button, Col, Divider, Row, Skeleton, Typography } from 'antd';
import React, { memo, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { GetFileFromS3 } from 'utils/commonMethods';
import { RESTGetAuthorization } from 'utils/RESTApi';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import BrandingPreview from './BrandingPreview';

const OrganizationDetails = () => {
  const { id } = useParams();
  const history = useHistory();
  const [orgDetails, setOrgDetails] = useState({});
  const [brandingByOrgId, setBrandingByOrgId] = useState();
  const [brandingLogoByOrgId, setBrandingLogoByOrgId] = useState();
  const [previewModal, setPreviewModal] = useState(false);

  const formatContactNumber = (contact) => {
    if (!contact) return '-';
    const phoneNumber = parsePhoneNumberFromString(contact);
    if (phoneNumber) {
      const countryCode = phoneNumber.country;
      switch (countryCode) {
        case 'US':
          return phoneNumber.formatNational({
            format: 'NATIONAL',
            spaces: true,
          });
        default:
          return phoneNumber.formatInternational();
      }
    }
    return '-';
  };

  const [loading, setLoading] = useState(false);

  const fetchOrgDetailsByID = async () => {
    try {
      setLoading(true);
      let api = '';
      if (window.location.pathname.includes('/partner/detail')) {
        api = `/organization/partner/get?organizationID=${id}`;
      } else {
        api = `/organization/get?organizationID=${id}`;
      }
      let response = await RESTGetAuthorization(api);

      setOrgDetails(response?.body);
      setBrandingByOrgId(response?.body?.branding);
      let getNavigationLogo = await GetFileFromS3(
        response?.body?.branding?.dark_background_logo.split('public/')[1],
        {
          level: 'public',
        }
      );
      setBrandingLogoByOrgId(getNavigationLogo);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const editPage = () => {
    if (window.location.pathname.includes('/partner/detail')) {
      history.push(`/partner/edit-partner/${id}`);
    } else {
      history.push(`/organization/edit-organization/${id}`);
    }
  };

  useEffect(() => {
    fetchOrgDetailsByID();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="background-white">
      {loading ? (
        <Skeleton active paragraph={{ rows: 6 }} />
      ) : (
        <>
          <Row justify="space-between" align="middle">
            <Typography.Title level={3}>
              {orgDetails?.organization?.name}
            </Typography.Title>
            <Button type="primary" onClick={editPage}>
              Edit
            </Button>
          </Row>
          <Row>
            <Col span={20}>
              <Row>
                <Col span={12}>
                  <Typography.Text level={4} className="fw-500">
                    {' '}
                    Website
                  </Typography.Text>
                  <br />
                  <Typography.Text level={4} className="text-primary">
                    <a href={orgDetails?.organization?.website}>
                      {' '}
                      {orgDetails?.organization?.website}
                    </a>
                  </Typography.Text>
                </Col>
                <Col span={12}>
                  <Typography.Text level={4} className="fw-500">
                    {' '}
                    Contact number
                  </Typography.Text>
                  <br />
                  <Typography.Text level={4}>
                    {orgDetails?.organization?.contact !== undefined
                      ? formatContactNumber(orgDetails?.organization?.contact)
                      : '-'}
                  </Typography.Text>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-20">
            <Typography.Text level={4} className="fw-500">
              Address information
            </Typography.Text>
          </Row>
          {orgDetails?.organization?.address?.street !== '' ||
          orgDetails?.organization?.address?.city !== '' ||
          orgDetails?.organization?.address?.zip !== '' ||
          orgDetails?.organization?.address?.state !== '' ||
          orgDetails?.organization?.address?.country !== '' ? (
            <>
              <Row>
                <Typography.Text level={4}>
                  {orgDetails?.organization?.address?.street}
                </Typography.Text>
              </Row>
              <Row>
                <Typography.Text level={4}>
                  {orgDetails?.organization?.address?.city}
                </Typography.Text>
                <Typography.Text level={4} className="ml-5">
                  {orgDetails?.organization?.address?.zip}
                </Typography.Text>
              </Row>
              <Row>
                <Typography.Text level={4}>
                  {orgDetails?.organization?.address?.state}
                </Typography.Text>
              </Row>
              <Row>
                <Typography.Text level={4}>
                  {orgDetails?.organization?.address?.country}
                </Typography.Text>
              </Row>
            </>
          ) : (
            '-'
          )}
        </>
      )}
      <Divider />
      {loading ? (
        <Skeleton active paragraph={{ rows: 4 }} />
      ) : (
        <>
          <Typography.Title level={5}>Primary Contact(Admin)</Typography.Title>
          <Row>
            <Col span={20}>
              <Row justify="space-between">
                <Col span={12}>
                  <Typography.Text level={4} className="fw-500">
                    First name
                  </Typography.Text>
                  <br />
                  <Typography.Text level={4}>
                    {orgDetails?.admin?.firstName}
                  </Typography.Text>
                </Col>
                <Col span={12}>
                  <Typography.Text level={4} className="fw-500">
                    Last name
                  </Typography.Text>
                  <br />
                  <Typography.Text level={4}>
                    {orgDetails?.admin?.lastName}
                  </Typography.Text>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-10">
            <Col span={20}>
              <Row justify="space-between">
                <Col span={12}>
                  <Typography.Text level={4} className="fw-500">
                    Email
                  </Typography.Text>
                  <br />
                  <Typography.Text level={4}>
                    {orgDetails?.admin?.email}
                  </Typography.Text>
                </Col>
                <Col span={12}>
                  <Typography.Text level={4} className="fw-500">
                    Contact number
                  </Typography.Text>
                  <br />
                  <Typography.Text level={4}>
                    {orgDetails?.admin?.contact !== undefined
                      ? formatContactNumber(orgDetails?.admin?.contact)
                      : '-'}
                  </Typography.Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}
      <Divider />
      {loading ? (
        <Skeleton active paragraph={{ rows: 4 }} />
      ) : (
        <>
          <Row justify="space-between">
            <Typography.Title level={5}>Customize Branding</Typography.Title>
            <Button onClick={() => setPreviewModal(true)}>Preview</Button>
          </Row>
          <Row>
            <Typography.Text level={4} className="fw-500">
              Logo for dark background{' '}
            </Typography.Text>
          </Row>
          <Row className="mt-5">
            <img
              src={brandingLogoByOrgId}
              alt="logo"
              style={{
                background: brandingByOrgId?.navigation_color,
                padding: '10px',
                height: '60px',
              }}
            />
          </Row>
          <Row className="mt-10">
            <Typography.Text level={4} className="fw-500">
              Navigation header color
            </Typography.Text>
          </Row>
          <Row>
            <Typography.Text level={4}>
              {brandingByOrgId?.navigation_color}
            </Typography.Text>
          </Row>
        </>
      )}
      <BrandingPreview
        brandingLogoByOrgId={brandingLogoByOrgId}
        brandingByOrgId={brandingByOrgId}
        previewModal={previewModal}
        setPreviewModal={setPreviewModal}
        orgDetails={orgDetails}
      />
    </div>
  );
};
export default memo(OrganizationDetails);

