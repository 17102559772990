import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentFormElementID: null,
  formElementUpdate: null,
  stageName: null,
  currentStageDetails: null,
  stag1Details: null,
  newStageName: null,
  stage2Name: null,
  stage3Name: null,
  ratingList: 0,
  comparisonList: 0,
  currentStage: false,
  otherStage: false,
  allStage: false,
  publishedForm: [],
  isFormChanged: false,
  stageLevel: null,
  backButtonClicked: false,
  draftForm: null,
  finalForm: false,
  previewFlag: false,
  configureClick: false,
  mfFormChange: false,
  whoFilters: [],
};

const stageSlice = createSlice({
  name: 'stageSlice',
  initialState,
  reducers: {
    setCurrentFormElementID: (state, { payload }) => {
      state.currentFormElementID = payload;
    },
    setFormElementUpdate: (state, { payload }) => {
      state.formElementUpdate = payload;
    },
    setStageName: (state, { payload }) => {
      state.stageName = payload;
    },
    setcurrentStageDetails: (state, { payload }) => {
      state.currentStageDetails = payload;
    },
    setStage1Detail: (state, { payload }) => {
      state.stag1Details = payload;
    },
    setNewStageName: (state, { payload }) => {
      state.newStageName = payload;
    },
    setStage2Name: (state, { payload }) => {
      state.stage2Name = payload;
    },
    setStage3Name: (state, { payload }) => {
      state.stage3Name = payload;
    },
    setComparisonList: (state, { payload }) => {
      state.comparisonList = payload;
    },
    setRatingList: (state, { payload }) => {
      state.ratingList = payload;
    },
    setCurrentStage: (state, { payload }) => {
      state.currentStage = payload;
    },
    setOtherStage: (state, { payload }) => {
      state.otherStage = payload;
    },
    setAllStage: (state, { payload }) => {
      state.allStage = payload;
    },
    setPublishedForm: (state, { payload }) => {
      state.publishedForm = payload;
    },
    setFormChanged: (state, { payload }) => {
      state.isFormChanged = payload;
    },
    setStageLevel: (state, { payload }) => {
      state.stageLevel = payload;
    },
    setBackButtonClicked: (state, { payload }) => {
      state.backButtonClicked = payload;
    },
    setDraftForm: (state, { payload }) => {
      state.draftForm = payload;
    },
    setFinalForm: (state, { payload }) => {
      state.finalForm = payload;
    },
    setPreviewFlag: (state, { payload }) => {
      state.previewFlag = payload;
    },
    setConfigureClick: (state, { payload }) => {
      state.configureClick = payload;
    },
    setMFFormChange: (state, { payload }) => {
      state.mfFormChange = payload;
    },  
    setWhoFilters: (state, { payload }) => {
      state.whoFilters = payload;
    },
  },
});

export const getCurrentFormElementID = (state) =>
  state?.stageReducer?.currentFormElementID;

export const getformElementUpdate = (state) =>
  state?.stageReducer?.formElementUpdate;

export const getStageName = (state) => state?.stageReducer?.stageName;

export const getCurrentStageDetail = (state) =>
  state?.stageReducer?.currentStageDetails;

export const getStag1Details = (state) => state?.stageReducer?.stag1Details;

export const getNewStageName = (state) => state?.stageReducer?.newStageName;

export const getStage2Name = (state) => state?.stageReducer?.stage2Name;

export const getStage3Name = (state) => state?.stageReducer?.stage3Name;

export const getComparisonList = (state) => state?.stageReducer?.comparisonList;

export const getRatingList = (state) => state?.stageReducer?.ratingList;

export const getCurrentStage = (state) => state?.stageReducer?.currentStage;

export const getOtherStage = (state) => state?.stageReducer?.otherStage;

export const getAllStage = (state) => state?.stageReducer?.allStage;

export const getPublishedForm = (state) => state?.stageReducer?.publishedForm;

export const getIsFormChanged = (state) => state?.stageReducer?.isFormChanged;

export const getStageLevel = (state) => state?.stageReducer?.stageLevel;

export const getBackButtonClicked = (state) =>
  state?.stageReducer?.backButtonClicked;
export const getDraftForm = (state) => state?.stageReducer?.draftForm;

export const getFinalForm = (state) => state?.stageReducer?.finalForm;

export const getPreviewFlag = (state) => state?.stageReducer?.previewFlag;

export const getConfigureClick = (state) => state?.stageReducer?.configureClick;

export const getMFFormChange = (state) => state?.stageReducer?.mfFormChange;
export const getWhoFilters = (state) => state?.stageReducer?.whoFilters;

const { actions, reducer } = stageSlice;
export const {
  setCurrentFormElementID,
  setFormElementUpdate,
  setStageName,
  setcurrentStageDetails,
  setStage1Detail,
  setNewStageName,
  setStage2Name,
  setStage3Name,
  setComparisonList,
  setRatingList,
  setCurrentStage,
  setOtherStage,
  setAllStage,
  setPublishedForm,
  setFormChanged,
  setStageLevel,
  setBackButtonClicked,
  setDraftForm,
  setFinalForm,
  setPreviewFlag,
  setConfigureClick,
  setMFFormChange,
  setWhoFilters,
} = actions;
export default reducer;

