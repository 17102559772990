import { Divider } from 'antd';
import React, { memo } from 'react';
import Joyride from 'react-joyride';
import {
  getHomeTour,
  setAlreadyStartedTour,
  setMainTour,
} from 'store/slices/loginSlice';
import './custom.less';
import { useSelector, useDispatch } from 'react-redux';
import { ECHOIMAGES } from 'Assets/Images';

const OnboardingHome = ({ run, setRun, setOpenModal }) => {
  const homeTour = useSelector(getHomeTour);
  const dispatch = useDispatch();
  const { NextArrow, BackArrow } = ECHOIMAGES.icons;

  const handleJoyrideCallback = (data) => {
    if (data.type === 'step:after' && data.action === 'close') {
      // The user closed the tooltip, stop the tour
      setRun(false);
      setOpenModal(false);
      dispatch(setMainTour(false));
      dispatch(setAlreadyStartedTour(true));
    }
  };

  const steps = [
    {
      target: '.my-first-step',

      content: (
        <div className="joyride-style">
          <div className="title-content">Discover the Navigation Rail</div>
          <div
            className="details-content"
            style={{ fontWeight: '400px !important' }}
          >
            Navigation rail offers quick access to essential sections of the
            software. Use these navigation options to seamlessly navigate and
            explore our platform.
          </div>
          <Divider />
          <div
            style={{
              position: 'absolute',
              left: '0px',
              right: '0px',
              bottom: '10px',
              color: '#0070C0',
            }}
          >
            1/7
          </div>
        </div>
      ),
      disableBeacon: true,
      placement: 'right',
    },
    {
      target: '.my-second-step',
      content: (
        <div className="joyride-style">
          <div className="title-content">Top Rankings</div>
          <div className="details-content">
            Your ideas and votes matter! Rankings change based on votes! Check
            out up and coming new ideas and explore top projects and
            opportunities.
          </div>
          <Divider />
          <div
            style={{
              position: 'absolute',
              left: '0px',
              right: '0px',
              bottom: '10px',
              color: '#0070C0',
            }}
          >
            2/7
          </div>
        </div>
      ),
      disableBeacon: true,
    },
    {
      target: '.my-third-step',
      content: (
        <div className="joyride-style">
          <div className="title-content">Stage</div>
          <div className="details-content">
            As ideas gain traction with more positive votes from team members,
            ideas can get promoted up to opportunity and could be selected as a
            project.
          </div>
          <Divider />
          <div
            style={{
              position: 'absolute',
              left: '0px',
              right: '0px',
              bottom: '10px',
              color: '#0070C0',
            }}
          >
            3/7
          </div>
        </div>
      ),
      disableBeacon: true,
      placement: 'top',
      styles: {
        options: {
          width: 300, // Adjust the width of the highlight
          height: 500,
        },
      },
    },
    {
      target: '.my-forth-step',
      content: (
        <div className="joyride-style">
          <div className="title-content">Change</div>
          <div className="details-content">
            if an item gets positive votes and moves from rank #10 to #4 in the
            rankings, the change will be +6.
          </div>
          <Divider />
          <div
            style={{
              position: 'absolute',
              left: '0px',
              right: '0px',
              bottom: '10px',
              color: '#0070C0',
            }}
          >
            4/7
          </div>
        </div>
      ),
      disableBeacon: true,
      placement: 'top',
      styles: {
        options: {
          width: 300, // Adjust the width of the highlight
        },
      },
    },
    {
      target: '.my-fifth-step',
      content: (
        <div className="joyride-style">
          <div className="title-content">Previous</div>
          <div className="details-content">
            if an item moves from the ranking position of #10 to #4, the
            previous ranking will show as #10.
          </div>
          <Divider />
          <div
            style={{
              position: 'absolute',
              left: '0px',
              right: '0px',
              bottom: '10px',
              color: '#0070C0',
            }}
          >
            5/7
          </div>
        </div>
      ),
      disableBeacon: true,
      placement: 'top',
      styles: {
        options: {
          width: 300, // Adjust the width of the highlight
        },
      },
    },
    {
      target: '.my-sixth-step',
      content: (
        <div className="joyride-style">
          <div className="title-content">
            Get ready to see the difference you're making!
          </div>
          <div className="details-content">
            Here, you can track the number of ideas you've submitted,
            comparisons you've completed, and ratings you've given.
          </div>
          <Divider />
          <div
            style={{
              position: 'absolute',
              left: '0px',
              right: '0px',
              bottom: '10px',
              color: '#0070C0',
            }}
          >
            6/7
          </div>
        </div>
      ),
      disableBeacon: true,
      placement: 'top',
    },
    {
      target: '.my-sevent-step',
      content: (
        <div className="joyride-style">
          <div className="title-content">Be a Trailblazer!</div>
          <div className="details-content">
            Share your creative out-of-the-box ideas with your team. Click 'Add
            Idea' to unlock the door to creativity.
          </div>
        </div>
      ),
      disableBeacon: true,
      spotlightClicks: true,
      hideBackButton: true,
    },
  ];

  const customLocale = {
    back: (
      <span
        style={{
          position: 'absolute',
          left: '20px',
          color: 'black',
          bottom: '12px',
        }}
      >
        <img src={BackArrow} alt="" />
      </span>
    ),
    next: (
      <span
        style={{
          backgroundColor: 'transparent !important',
          color: 'black',
        }}
      >
        <img
          src={NextArrow}
          alt=""
          style={{
            position: 'absolute',
            right: '20px',
            bottom: '13px',
          }}
        />
      </span>
    ),
    skipWithCount: (current, total) => (
      <span>
        {current} of {total}
      </span>
    ),
  };

  return (
    <Joyride
      steps={steps}
      run={run}
      continuous
      showProgress
      disableCloseOnEsc
      disableOverlayClose
      locale={customLocale}
      // disableScrolling
      callback={handleJoyrideCallback}
    />
  );
};

export default memo(OnboardingHome);

