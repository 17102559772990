import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getCurrentGroups, getCurrentToken } from '../store/slices/loginSlice';
import FullPageLayout from '../MainLayout/FullPageLayout';

const PublicLayoutRoute = (props) => {
  const { render, ...rest } = props;

  const token = useSelector(getCurrentToken);
  const groups = useSelector(getCurrentGroups);

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <>
          {token && groups?.includes('SUPERADMIN') ? (
            <Redirect from={matchProps.path} to="/organizations" />
          ) : (
            token && <Redirect from={matchProps.path} to="/" />
          )}

          {!token && <FullPageLayout>{render(matchProps)}</FullPageLayout>}
        </>
      )}
    />
  );
};

export default PublicLayoutRoute;
