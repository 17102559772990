import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  navigationColor: '',
  navigationLogo: '',
  brandingForm: false,
  scopingIds: [],
};

const brandingSlice = createSlice({
  name: 'brandingSlice',
  initialState,
  reducers: {
    setNavigationColor: (state, { payload }) => {
      state.navigationColor = payload;
    },
    setNavigationLogo: (state, { payload }) => {
      state.navigationLogo = payload;
    },
    setBrandingForm: (state, { payload }) => {
      state.brandingForm = payload;
    },
    setScopingIds: (state, { payload }) => {
      state.scopingIds = payload;
    },
  },
});
export const getNavigationColor = (state) =>
  state?.brandingReducer?.navigationColor;

export const getNavigationLogo = (state) =>
  state?.brandingReducer?.navigationLogo;

export const getBrandingForm = (state) => state?.brandingReducer?.brandingForm;

export const getScopingIds = (state) => state?.brandingReducer?.scopingIds;

const { actions, reducer } = brandingSlice;

export const {
  setNavigationColor,
  setNavigationLogo,
  setBrandingForm,
  setScopingIds,
} = actions;

export default reducer;

