import { API, graphqlOperation } from 'aws-amplify';
import { searchGammasCountCustom } from 'graphql/customQueris';
import { listAllStages } from 'Pages/Stages/Services/StageActions';
import { listUserRoles } from 'Pages/SuperAdmin/SuperAdminFun';
import {
  listOrganizations,
  searchUserRatingsCustom,
  searchUserVotesCustom,
} from 'utils/Actions';
import { UserRole } from './Constants';
import { departmentsByOrganizationIDCustom } from 'Pages/Settings/Services/SettingActions';

export const getGammasCount = async (
  userId,
  allStages = null,
  id = null,
  orgId = null,
  aggregates
) => {
  try {
    const filter = {};

    Object.assign(
      filter,
      id
        ? { userID: { eq: id } }
        : orgId
        ? { organizationID: { eq: orgId } }
        : { userID: { eq: userId } }
    );

    const res = await API.graphql(
      graphqlOperation(searchGammasCountCustom, {
        filter,
        aggregates: aggregates,
      })
    );
    const submittedIdea = res?.data?.searchGammas?.total;
    const submittedIdeaByOrg =
      res?.data?.searchGammas?.aggregateItems?.[0]?.result?.buckets;

    const searchResults =
      res?.data?.searchGammas?.aggregateItems?.[0]?.result?.buckets;
    const ideaConvertedToOpportunity = searchResults.find((item) => {
      const key = item?.key;

      return allStages?.[1]?.id === key;
    });

    const ideaConvertedToProject = searchResults.find((item) => {
      const key = item?.key;
      return allStages?.[2]?.id === key;
    });

    return {
      submittedIdea,
      opportunityCount: ideaConvertedToOpportunity?.doc_count,
      projectCount: ideaConvertedToProject?.doc_count,
      submittedIdeaByOrg,
    };
  } catch (err) {
    return {
      submittedIdea: 0,
      opportunityCount: 0,
      projectCount: 0,
      submittedIdeaByOrg: 0,
    };
  }
};

export const getComparisonCount = async (userId, id = null) => {
  try {
    const filter = id ? { userID: { eq: id } } : { userID: { eq: userId } };
    const res = await searchUserVotesCustom(filter);
    const comparisonCount = res?.total / 2;
    return {
      comparisonCount,
    };
  } catch (err) {
    return {
      comparisonCount: 0,
    };
  }
};

export const getRatingsCount = async (userId, id = null) => {
  try {
    let filter = id ? { userID: { eq: id } } : { userID: { eq: userId } };
    let res = await searchUserRatingsCustom(filter);
    const ratingsCount = res?.total;
    return {
      ratingsCount,
    };
  } catch (err) {
    return {
      ratingsCount: 0,
    };
  }
};

export const getOrganizations = async () => {
  try {
    const data = await listOrganizations();
    return {
      data,
    };
  } catch (err) {
    throw err;
  }
};

export const fetchRoles = async (isAdminGroup, currnetOrg) => {
  try {
    const data = await listUserRoles();
    let rolesArray = data?.data?.__type?.enumValues;
    if (isAdminGroup || currnetOrg?.id) {
      rolesArray = rolesArray.filter(
        (item) => item?.name !== UserRole.superAdmin
      );
    }
    return rolesArray;
  } catch (error) {
    return [];
  }
};

export const fetchOrgDepartments = async (orgId) => {
  try {
    const getDepartments = await departmentsByOrganizationIDCustom({
      organizationID: orgId,
    });
    return getDepartments?.items;
  } catch (error) {
    return [];
  }
};

export const getLastLoginDate = (row, options) => {
  let lastLoginDate = '';
  let checkTodayDate = new Date(
    row.LoginHistory?.items?.[0]?.datetime
  ).toLocaleString('en-US', options);
  let todayDate = new Date().toLocaleString('en-US', options);
  if (checkTodayDate.split(',')[0] === todayDate.split(',')[0]) {
    lastLoginDate = new Date(
      row.LoginHistory?.items?.[1]?.datetime
    ).toLocaleString('en-US', options);
  } else {
    lastLoginDate = new Date(
      row.LoginHistory?.items?.[0]?.datetime
    ).toLocaleString('en-US', options);
  }
  return lastLoginDate !== undefined && lastLoginDate !== 'Invalid Date'
    ? lastLoginDate
    : '-';
};

export const fetchOrganizationStages = async (organizationID) => {
  try {
    if (organizationID) {
      const response = await listAllStages({ organizationID });
      const sortedLevel = response?.items?.sort((a, b) => a.level - b.level);
      return sortedLevel;
    }
  } catch (err) {
    throw err;
  }
};

export const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
};

