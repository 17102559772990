import {
  Button,
  Col,
  Empty,
  Image,
  Modal,
  Row,
  Table,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import React, { memo, useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { ECHOIMAGES } from 'Assets/Images';
import ScenarioRankings from './ScenarioRankings';
import { useSelector } from 'react-redux';
import {
  getCurrentOrganization,
  getCurrnetUserData,
  isAdmin,
  isSuperAdmin,
} from 'store/slices/loginSlice';
import { InfoCircleOutlined } from '@ant-design/icons';
import { RESTGetRankingFilter } from 'utils/RESTApi';
import { useHistory, useParams } from 'react-router-dom';
import { levelColors } from 'utils/Constants';
import moment from 'moment';

const ScenarioSummary = ({ itemData }) => {
  const { fullScreenIcon, exitScreenIcon } = ECHOIMAGES.PriorityBatches;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { id } = useParams();
  const currentOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const orgId = currentOrg?.id || loginUsrOrg?.organizationID;
  const userId = currentOrg?.adminID || loginUsrOrg?.id;
  const isAdminGroup = useSelector(isAdmin);
  const isSuperAdminGroup = useSelector(isSuperAdmin);
  const { upArrow, downArrow, minusArrow } = ECHOIMAGES.HomeIcons;
  const [nextTokenData, setNextToken] = useState(null);
  const [scenarioRanking, setScenarioRanking] = useState([]);
  const history = useHistory();

  const fetchGammas = async () => {
    let tempPage = 1;
    if (orgId) {
      let allData = [];
      let nextToken = nextTokenData;
      let sort_direction = 'ASC';
      let sort_field = 'rank';

      // eslint-disable-next-line max-len
      let rankingApi = `/rankings?id=${orgId}&priorityBatchID=${id}&userID=${userId}&page=${tempPage}&sort_direction=${sort_direction}&sort_field=${sort_field}`;
      if (nextToken && tempPage > 1) {
        rankingApi += `&nextToken=${nextToken}`;
      }

      try {
        const res = await RESTGetRankingFilter(rankingApi);
        const temp = res?.body?.items?.map((item, index) => {
          return {
            ...item?.Gamma,
            rank:
              item?.Gamma?.fixedRank > 0
                ? item?.Gamma?.fixedRank
                : item?.Gamma?.rank,
            isRank: item.isRanked,
          };
        });

        setNextToken(res?.body?.nextToken);

        allData =
          nextToken && tempPage > 1 ? [...scenarioRanking, ...temp] : temp;
        setScenarioRanking(allData);
      } catch (err) {
        console.log(err);
      }
      return allData;
    }
  };

  const columns = [
    {
      title: 'Rank',
      dataIndex: 'rank',
      key: 'rank',
      width: '3%',
      align: 'center',

      render: (text, row) => {
        return {
          props: {
            className:
              row.fixedRank > 0 && (isAdminGroup || isSuperAdminGroup)
                ? 'editedRank rank'
                : '',
          },
          children: (
            <>
              <span
                className={
                  row.outOfThreshold
                    ? ' fw-400 fs-14 outofthresholdcolor'
                    : 'fw-400 fs-14'
                }
                style={{ borderLeft: row.color }}
              >
                {row.fixedRank > 0
                  ? row.fixedRank
                  : row.isRank === false
                  ? '-'
                  : text || 0}
              </span>
            </>
          ),
        };
      },
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: '40%',
      render: (text, row) => {
        return {
          props: {
            className:
              row.fixedRank > 0 && (isAdminGroup || isSuperAdminGroup)
                ? 'editedRank'
                : '',
          },
          children: (
            <span
              className={
                row?.outOfThreshold
                  ? 'fw-400 capitalize-text fs-14 outofthresholdcolor'
                  : 'fw-400 capitalize-text fs-14'
              }
            >
              {text}
            </span>
          ),
        };
      },
    },
    {
      title: (
        <div
          style={{
            display: 'flex',
            gap: '5px',
            alignItem: 'center',
          }}
        >
          <span>Stage </span>
          <Tooltip
            title="Ideas -> Opportunity -> Project. As ideas gain traction with more positive votes
          from team members, ideas can get promoted up to opportunity and could be selected as a project."
          >
            <InfoCircleOutlined />
          </Tooltip>
        </div>
      ),
      key: 'Type',
      dataIndex: 'level',
      width: '10%',
      render: (text, row) => {
        return {
          props: {
            className:
              row.fixedRank > 0 && (isAdminGroup || isSuperAdminGroup)
                ? 'editedRank'
                : '',
          },
          children: (
            <Tag key={text.id} color={levelColors[text.level]}>
              {text?.name?.toUpperCase()}
            </Tag>
          ),
        };
      },
    },
    {
      title: 'Department',
      dataIndex: 'departments',
      key: 'departments',
      render: (text, row) => {
        const departmentNames = row?.departments?.map((item) => item?.name);
        return {
          children: (
            <Typography.Text
              style={
                true
                  ? {
                      width: 200,
                    }
                  : undefined
              }
              ellipsis={
                true
                  ? {
                      tooltip: departmentNames?.join(', '),
                    }
                  : false
              }
            >
              {departmentNames?.join(', ')}
            </Typography.Text>
          ),
        };
      },
    },
    {
      title: 'Created on',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '11%',
      render: (text, row) => {
        return {
          children: <span>{moment(text)?.format('l')}</span>,
        };
      },
    },
    {
      title: (
        <div
          style={{
            display: 'flex',
            gap: '5px',
            alignItem: 'center',
          }}
        >
          <span>Change</span>
          <Tooltip
            title="Has the rank changed recently? For example, 
          if an idea gets positive votes and moves from rank #10 to #4 in the rankings, the change will be +6."
          >
            <InfoCircleOutlined />
          </Tooltip>
        </div>
      ),
      dataIndex: 'change',
      key: 'change',
      width: '5%',
      align: 'center',
      render: (_, row) => {
        return {
          props: {
            className:
              row.fixedRank > 0 && (isAdminGroup || isSuperAdminGroup)
                ? 'editedRank'
                : '',
          },
          children:
            row?.previousRank['Recent'] - row.rank > 0 ? (
              <>
                {row.isRank === false ? (
                  <>
                    <span className=" fw-700 fs-14">-</span>
                  </>
                ) : (
                  <>
                    {row.fixedRank > 0 ? (
                      row.rank - row.fixedRank === 0 ? (
                        <>
                          <Image src={minusArrow} preview={false} />
                          <span
                            className={
                              row.outOfThreshold
                                ? 'outofthresholdcolor fw-700 fs-14'
                                : 'fw-700 fs-14'
                            }
                          >
                            {' '}
                            {row.rank - row.fixedRank}
                          </span>
                        </>
                      ) : (
                        <>
                          <Image src={upArrow} preview={false} />

                          <span
                            className={
                              row.outOfThreshold
                                ? 'outofthresholdcolor fw-700 fs-14'
                                : 'fw-700 fs-14'
                            }
                          >
                            {' '}
                            +{row?.previousRank['Recent'] - row.rank}
                          </span>
                        </>
                      )
                    ) : (
                      <>
                        <Image src={upArrow} preview={false} />
                        <span
                          className={
                            row.outOfThreshold
                              ? 'outofthresholdcolor fw-700 fs-14'
                              : 'fw-700 fs-14'
                          }
                        >
                          {' '}
                          +{row?.previousRank['Recent'] - row.rank}
                        </span>
                      </>
                    )}
                  </>
                )}
              </>
            ) : (
              <div>
                {row.isRank === false ? (
                  <>
                    <span
                      className={
                        row.outOfThreshold
                          ? 'outofthresholdcolor fw-700 fs-14'
                          : 'fw-700 fs-14'
                      }
                    >
                      -
                    </span>
                  </>
                ) : row.fixedRank !== null ? (
                  row.fixedRank > 0 || row?.previousRank['Recent'] > 0 ? (
                    row.rank - row.fixedRank === 0 ? (
                      <>
                        <Image src={minusArrow} preview={false} />
                        <span
                          className={
                            row.outOfThreshold
                              ? 'outofthresholdcolor fw-700 fs-14'
                              : 'fw-700 fs-14'
                          }
                        >
                          {' '}
                          {row.rank - row.fixedRank}
                        </span>
                      </>
                    ) : row?.previousRank['Recent'] ? (
                      <>
                        {row?.previousRank['Recent'] - row.rank === 0 ? (
                          <>
                            <Image src={minusArrow} preview={false} />
                            <span
                              className={
                                row.outOfThreshold
                                  ? 'outofthresholdcolor fw-700 fs-14'
                                  : 'fw-700 fs-14'
                              }
                            >
                              {' '}
                              0
                            </span>{' '}
                          </>
                        ) : (
                          <>
                            <Image src={downArrow} preview={false} />
                            <span
                              className={
                                row.outOfThreshold
                                  ? 'outofthresholdcolor fw-700 fs-14'
                                  : 'fw-700 fs-14'
                              }
                            >
                              {' '}
                              {row?.previousRank['Recent'] - row.rank}
                            </span>
                          </>
                        )}
                      </>
                    ) : (
                      <div>
                        <img src={minusArrow} alt="" />
                        <span
                          className={
                            row.outOfThreshold
                              ? 'outofthresholdcolor fw-700 fs-14'
                              : 'fw-700 fs-14'
                          }
                        >
                          {' '}
                          0
                        </span>{' '}
                      </div>
                    )
                  ) : (
                    <>
                      <div>
                        <span
                          className={
                            row.outOfThreshold
                              ? 'outofthresholdcolor fw-700 fs-14'
                              : 'fw-700 fs-14'
                          }
                        >
                          {' '}
                          -
                        </span>{' '}
                      </div>
                    </>
                  )
                ) : row?.previousRank['Recent'] ? (
                  <>
                    {' '}
                    {row?.previousRank['Recent'] - row.rank === 0 ? (
                      <div>
                        <img src={minusArrow} alt="" />
                        <span
                          className={
                            row.outOfThreshold
                              ? 'outofthresholdcolor fw-700 fs-14'
                              : 'fw-700 fs-14'
                          }
                        >
                          {' '}
                          0
                        </span>{' '}
                      </div>
                    ) : (
                      <>
                        <Image src={downArrow} preview={false} />
                        <span
                          className={
                            row.outOfThreshold
                              ? 'outofthresholdcolor fw-700 fs-14'
                              : 'fw-700 fs-14'
                          }
                        >
                          {' '}
                          {row?.previousRank['Recent'] - row.rank}
                        </span>
                      </>
                    )}
                  </>
                ) : (
                  '-'
                )}
              </div>
            ),
        };
      },
    },
  ];

  useEffect(() => {
    fetchGammas();
    // eslint-disable-next-line
  }, []);

  const colorMapping = {
    '-2': '#f93232',
    '-1': '#dca231',
    0: '#aaaaaa',
    1: '#68ba4d',
    2: '#169250',
  };

  const [chartData, setChartData] = useState({});
  const getChartOptions = (item) => {
    const dataWithColors = item?.summary?.buckets?.map((bucket) => {
      const breakpoint = item.field.breakpoints.find(
        (bp) => bp.label === bucket.key
      );
      return {
        value: bucket.count,
        name: bucket.key,
        itemStyle: { color: colorMapping[breakpoint.value] },
      };
    });
    return {
      title: {
        left: 'center',
      },
      tooltip: {
        trigger: 'item',
      },
      legend: {
        type: 'scroll',
        orient: 'vertical',
        left: scaleModalVisble ? '450' : '180',
        top: 'middle',
        itemWidth: scaleModalVisble ? 20 : 10,
        itemHeight: scaleModalVisble ? 20 : 10,
        symbol: 'circle',
        textStyle: {
          fontSize: scaleModalVisble ? 15 : 10,
        },
      },
      series: [
        {
          name: item?.field?.name,
          type: 'pie',
          label: false,
          radius: '50%',
          center: scaleModalVisble ? ['25%', '50%'] : ['28%', '50%'],
          data: dataWithColors,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        },
      ],
    };
  };

  const getStageChartOptions = (item) => {
    const dataWithColors = item?.summary?.buckets?.map((bucket) => {
      return {
        value: bucket.count,
        name: bucket.key,
        itemStyle: { color: levelColors[bucket.level] },
      };
    });
    return {
      title: {
        left: 'center',
      },
      tooltip: {
        trigger: 'item',
      },
      legend: {
        type: 'scroll',
        orient: 'vertical',
        left: stageModalVisble ? '450' : '180',
        top: 'middle',
        itemWidth: stageModalVisble ? 20 : 10,
        itemHeight: stageModalVisble ? 20 : 10,
        symbol: 'circle',
        textStyle: {
          fontSize: stageModalVisble ? 15 : 10,
        },
      },
      series: [
        {
          name: item?.field?.name,
          type: 'pie',
          label: false,
          radius: '50%',
          center: stageModalVisble ? ['25%', '50%'] : ['28%', '50%'],
          data: dataWithColors,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        },
      ],
    };
  };

  const getSponsorChartOptions = (item) => {
    return {
      title: {
        left: 'center',
      },
      tooltip: {
        trigger: 'item',
      },
      legend: {
        type: 'scroll',
        orient: 'vertical',
        left: sponsorModalVisble ? '450' : '180',
        top: 'middle',
        itemWidth: sponsorModalVisble ? 20 : 10,
        itemHeight: sponsorModalVisble ? 20 : 10,
        symbol: 'circle',
        textStyle: {
          fontSize: sponsorModalVisble ? 15 : 10,
        },
      },
      series: [
        {
          name: item?.field?.name,
          type: 'pie',
          label: false,
          radius: '50%',
          center: sponsorModalVisble ? ['25%', '50%'] : ['28%', '50%'],
          data: item?.summary?.buckets?.map((bucket) => ({
            value: bucket.count,
            name: bucket.key,
          })),
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        },
      ],
    };
  };

  const barChart = (item) => {
    const prefix = item?.field?.subType === '$' ? '$' : '';

    const buckets = item?.summary?.buckets?.map((bucket) => {
      const rangeMin = bucket.key;
      const rangeMax = rangeMin + 25;
      const x = (rangeMax - rangeMin) / 2 + rangeMin;
      const y = bucket.count;

      return {
        value: [x, y],
        rangeMin,
        rangeMax,
      };
    });

    return {
      xAxis: {
        data: item?.summary?.buckets?.map((bucket) => `${prefix}${bucket.key}`),
      },
      yAxis: {
        type: 'value',
      },
      tooltip: {
        trigger: 'item',
      },
      series: [
        {
          data: item?.summary?.buckets?.map((bucket) => bucket.count),
          type: 'bar',
        },
      ],
    };
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [scaleModalVisble, setScaleModalVisible] = useState(false);
  const [stageModalVisble, setStageModalVisible] = useState(false);
  const [sponsorModalVisble, setSponsorModalVisible] = useState(false);

  const [scaleData, setScaleData] = useState();
  const [sponsorData, setSponsorData] = useState();
  const [stageData, setStageData] = useState();

  const handleFullScreen = (item) => {
    setChartData(item);
    setIsModalVisible(true);
  };

  const handleScaleFullScreen = (item) => {
    setScaleData(item);
    setScaleModalVisible(true);
  };
  const handleStageFullScreen = (item) => {
    setStageData(item);
    setStageModalVisible(true);
  };
  const handleSponsorFullScreen = (item) => {
    setSponsorData(item);
    setSponsorModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    setScaleModalVisible(false);
    setSponsorModalVisible(false);
    setStageModalVisible(false);
  };

  const handleRowClick = (record, event) => {
    history.push({
      pathname: `/ranking-detail/${record.id}`,
      state: { data: record, previousPath: `/scenario-detail/${id}` },
    });
  };

  return (
    <div className="scenario-summary">
      <Row justify="space-between">
        <Typography.Text level={5} className="fs-14">
          {itemData?.rankingFilters?.filters?.length > 0 && (
            <>
              <span
                className={
                  itemData?.rankingFilters?.count === 0
                    ? 'fw-bold zero-items-count'
                    : itemData?.rankingFilters?.count <= 5
                    ? 'fw-bold yellow-items-count'
                    : 'fw-bold'
                }
              >
                {' '}
                {itemData?.rankingFilters?.count}{' '}
              </span>
              <span>items match your scenario</span>
            </>
          )}
        </Typography.Text>
        <div>
          {scenarioRanking?.length > 0 && (
            <Button
              className="view-all fs-16"
              type="text"
              onClick={() => setIsModalOpen(true)}
            >
              View all
            </Button>
          )}
        </div>
      </Row>
      <Row className="mt-10">
        <Table
          columns={columns}
          className="cursor-pointer w-100"
          dataSource={scenarioRanking.slice(0, 10)}
          pagination={false}
          onRow={(record) => ({
            onClick: () => handleRowClick(record),
          })}
        />
      </Row>
      {itemData?.rankingFilters?.filters?.length && (
        <Row className="mt-10">
          <Typography.Text className="fs-16 fw-500">Analytics</Typography.Text>
        </Row>
      )}
      <Row className="mt-10">
        {itemData?.rankingFilters?.filters
          ?.filter((item) => {
            // Check the filtering conditions
            return (
              item?.field?.type === '#' ||
              item?.field?.type === '+-' ||
              item?.id?.split(':')?.[0] === process.env.REACT_APP_STAGE_ID ||
              item?.id?.split(':')?.[0] === process.env.REACT_APP_SPONSOR_ID
            );
          })
          ?.map((item) => {
            return (
              <Col span={8} className="summary-block mr-10 mb-10">
                <Row justify="space-between">
                  <div>
                    <Typography.Text className="fw-bold fs-16">
                      {item?.field?.name}
                    </Typography.Text>
                    {item?.field?.description && (
                      <Tooltip
                        title={item?.field?.description}
                        className="ml-10"
                      >
                        <InfoCircleOutlined />
                      </Tooltip>
                    )}
                  </div>
                  {item?.field?.type === '#' && item?.summary && (
                    <Button
                      onClick={() => handleFullScreen(item)}
                      type="text"
                      className="p-0 fullscreen-btn"
                    >
                      <img
                        src={fullScreenIcon}
                        alt=""
                        className="fullscreen-icon"
                      />
                    </Button>
                  )}
                  {item?.field?.type === '+-' && item?.summary && (
                    <Button
                      onClick={() => handleScaleFullScreen(item)}
                      type="text"
                      className="p-0 fullscreen-btn"
                    >
                      <img
                        src={fullScreenIcon}
                        alt=""
                        className="fullscreen-icon"
                      />
                    </Button>
                  )}
                  {item?.id?.split(':')?.[0] ===
                    process.env.REACT_APP_STAGE_ID &&
                    item?.summary?.buckets?.length > 0 && (
                      <Button
                        onClick={() => handleStageFullScreen(item)}
                        type="text"
                        className="p-0 fullscreen-btn"
                      >
                        <img
                          src={fullScreenIcon}
                          alt=""
                          className="fullscreen-icon"
                        />
                      </Button>
                    )}
                  {item?.id?.split(':')?.[0] ===
                    process.env.REACT_APP_SPONSOR_ID &&
                    item?.summary?.buckets?.length > 0 && (
                      <Button
                        onClick={() => handleSponsorFullScreen(item)}
                        type="text"
                        className="p-0 fullscreen-btn"
                      >
                        <img
                          src={fullScreenIcon}
                          alt=""
                          className="fullscreen-icon"
                        />
                      </Button>
                    )}
                </Row>
                {item?.field?.type === '#' && (
                  <>
                    {item?.summary && (
                      <>
                        <Row>
                          <Typography.Text>
                            <span className="total-text"> Total:</span>{' '}
                            <span>
                              {item?.field?.subType === '$' ? '$' : ''}
                              {item?.summary?.stats?.sum?.toLocaleString(
                                'en-US'
                              )}
                            </span>
                          </Typography.Text>
                        </Row>

                        <Row className="mt-10">
                          <Col span={12}>
                            <Typography.Text>
                              <span className="total-text"> Min: </span>{' '}
                              <span>
                                {item?.field?.subType === '$' ? '$' : ''}
                                {item?.summary?.stats?.min?.toLocaleString(
                                  'en-US'
                                )}
                              </span>
                            </Typography.Text>
                          </Col>
                          <Col span={12}>
                            <Typography.Text>
                              <span className="total-text">Max: </span>{' '}
                              <span>
                                {item?.field?.subType === '$' ? '$' : ''}
                                {item?.summary?.stats?.max?.toLocaleString(
                                  'en-US'
                                )}
                              </span>
                            </Typography.Text>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={12}>
                            <Typography.Text>
                              <span className="total-text">Avg: </span>{' '}
                              <span>
                                {item?.field?.subType === '$' ? '$' : ''}
                                {item?.summary?.stats?.avg?.toLocaleString(
                                  'en-US'
                                )}
                              </span>
                            </Typography.Text>
                          </Col>
                        </Row>
                      </>
                    )}
                  </>
                )}
                {item?.field?.type === '+-' && (
                  <>
                    {item?.summary && (
                      <Typography.Text>
                        <span className="total-text">Average: </span>{' '}
                        <span>{item?.summary?.stats?.avg}</span>
                      </Typography.Text>
                    )}
                  </>
                )}
                {item?.field?.type === '#' && (
                  <div className="barChart-scroll">
                    {item?.summary ? (
                      <ReactECharts
                        option={barChart(item)}
                        style={{ height: '250px', width: '400px' }}
                      />
                    ) : (
                      <Empty className="mt-50" />
                    )}
                  </div>
                )}
                {item?.field?.type === '+-' && (
                  <div>
                    {item?.summary ? (
                      <ReactECharts
                        option={getChartOptions(item)}
                        style={{ top: '-29px' }}
                      />
                    ) : (
                      <Empty className="mt-50" />
                    )}
                  </div>
                )}
                {item?.id?.split(':')?.[0] ===
                  process.env.REACT_APP_STAGE_ID && (
                  <div>
                    {item?.summary?.buckets?.length > 0 ? (
                      <ReactECharts
                        option={getStageChartOptions(item)}
                        style={{ top: '-10px' }}
                      />
                    ) : (
                      <Empty className="mt-50" />
                    )}
                  </div>
                )}
                {item?.id?.split(':')?.[0] ===
                  process.env.REACT_APP_SPONSOR_ID && (
                  <div>
                    {item?.summary?.buckets?.length > 0 ? (
                      <ReactECharts
                        option={getSponsorChartOptions(item)}
                        style={{ top: '-10px' }}
                      />
                    ) : (
                      <Empty className="mt-50" />
                    )}
                  </div>
                )}
              </Col>
            );
          })}
        <Modal
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={null}
          width={800}
          className="numericModal"
          closeIcon={
            <Button
              onClick={() => handleCancel()}
              type="text"
              className="p-0 fullscreen-btn"
            >
              <img src={exitScreenIcon} alt="" className="fullscreen-icon" />
            </Button>
          }
        >
          <Typography.Title level={4} className="fw-bold">
            {chartData?.field?.name}
          </Typography.Title>
          <Row>
            <Typography.Text>
              <span className="total-text"> Total:</span>{' '}
              <span>
                {chartData?.field?.subType === '$' ? '$' : ''}
                {chartData?.summary?.stats?.sum?.toLocaleString('en-US')}
              </span>
            </Typography.Text>
          </Row>

          <Row className="mt-10">
            <Col span={12}>
              <Typography.Text>
                <span className="total-text"> Min: </span>{' '}
                <span>
                  {chartData?.field?.subType === '$' ? '$' : ''}
                  {chartData?.summary?.stats?.min?.toLocaleString('en-US')}
                </span>
              </Typography.Text>
            </Col>
            <Col span={12}>
              <Typography.Text>
                <span className="total-text">Max: </span>{' '}
                <span>
                  {chartData?.field?.subType === '$' ? '$' : ''}
                  {chartData?.summary?.stats?.max?.toLocaleString('en-US')}
                </span>
              </Typography.Text>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Typography.Text>
                <span className="total-text">Avg: </span>{' '}
                <span>
                  {chartData?.field?.subType === '$' ? '$' : ''}
                  {chartData?.summary?.stats?.avg?.toLocaleString('en-US')}
                </span>
              </Typography.Text>
            </Col>
          </Row>

          <ReactECharts
            option={barChart(chartData)}
            style={{ height: '700px', width: '800px' }}
          />
        </Modal>
        <Modal
          visible={scaleModalVisble}
          onCancel={handleCancel}
          footer={null}
          width={800}
          className="scaleModal"
          closeIcon={
            <Button
              onClick={() => handleCancel()}
              type="text"
              className="p-0 fullscreen-btn"
            >
              <img src={exitScreenIcon} alt="" className="fullscreen-icon" />
            </Button>
          }
        >
          <Row>
            <Typography.Title level={4} className="fw-bold">
              {scaleData?.field?.name}
            </Typography.Title>
          </Row>
          <Typography.Text>
            Average:{' '}
            <span className="fw-bold">{scaleData?.summary?.stats?.avg}</span>
          </Typography.Text>

          <ReactECharts
            option={getChartOptions(scaleData)}
            style={{ height: '500px', width: '700px', top: '-50px' }}
          />
        </Modal>
        <Modal
          visible={stageModalVisble}
          onCancel={handleCancel}
          footer={null}
          width={800}
          className="scaleModal"
          closeIcon={
            <Button
              onClick={() => handleCancel()}
              type="text"
              className="p-0 fullscreen-btn"
            >
              <img src={exitScreenIcon} alt="" className="fullscreen-icon" />
            </Button>
          }
        >
          <Row>
            <Typography.Title level={4} className="fw-bold">
              {stageData?.field?.name}
            </Typography.Title>
          </Row>

          <ReactECharts
            option={getStageChartOptions(stageData)}
            style={{ height: '500px', width: '700px', top: '-50px' }}
          />
        </Modal>
        <Modal
          visible={sponsorModalVisble}
          onCancel={handleCancel}
          footer={null}
          width={800}
          className="scaleModal"
          closeIcon={
            <Button
              onClick={() => handleCancel()}
              type="text"
              className="p-0 fullscreen-btn"
            >
              <img src={exitScreenIcon} alt="" className="fullscreen-icon" />
            </Button>
          }
        >
          <Row>
            <Typography.Title level={4} className="fw-bold">
              {sponsorData?.field?.name}
            </Typography.Title>
          </Row>

          <ReactECharts
            option={getSponsorChartOptions(sponsorData)}
            style={{ height: '500px', width: '600px', top: '-50px' }}
          />
        </Modal>
      </Row>
      <ScenarioRankings
        isModalOpen={isModalOpen}
        itemData={itemData}
        setIsModalOpen={setIsModalOpen}
      />
    </div>
  );
};

export default memo(ScenarioSummary);

