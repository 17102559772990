import { Button, message, Modal, Typography } from 'antd';
import { API } from 'aws-amplify';
import {
  createFormDraftCustom,
  updateFormDraftCustom,
} from 'graphql/customMutation';
import React, { memo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  getCurrentOrganization,
  getCurrnetUserData,
} from 'store/slices/loginSlice';
import {
  getCurrentStageDetail,
  setBackButtonClicked,
  setFinalForm,
  setFormChanged,
} from 'store/slices/StageSlice';

const LeavePageModal = ({
  setIsConfirmationVisible,
  isConfirmationVisible,
  updatedFormElement,
  getStageNameElement,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const getStageDetails = useSelector(getCurrentStageDetail);
  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;
  const [loading, setLoading] = useState(false);

  const handleConfirmationOk = () => {
    dispatch(setBackButtonClicked(false));
    history.push('/stages');
    setIsConfirmationVisible(false);
  };

  const handleConfirmationCancel = () => {
    dispatch(setBackButtonClicked(false));
    setIsConfirmationVisible(false);
  };
  const handleSaveDraft = async () => {
    dispatch(setFinalForm(true));
    setLoading(true);
    dispatch(setFormChanged(false));

    try {
      let input = {
        layout: JSON.stringify(updatedFormElement),
        name: getStageNameElement?.name + ' ' + 'Draft',
        organizationID: orgId,
        stageID: getStageNameElement?.id,
        updatedByIAM: false,
        version: 1,
      };
      await API.graphql({
        query:
          getStageDetails?.draft?.items?.[0]?.version === 1
            ? updateFormDraftCustom
            : createFormDraftCustom,
        variables: { input: input },
      });
      setLoading(false);
      message.success('Draft saved successfully');
      history.push('/stages');
      setTimeout(() => {
        dispatch(setFinalForm(false));
        dispatch(setBackButtonClicked(false));
        setIsConfirmationVisible(false);
      }, 2000);
    } catch (err) {
      setLoading(false);
      message.error(err.message);
    }
  };
  return (
    <Modal
      open={isConfirmationVisible}
      onOk={handleConfirmationOk}
      onCancel={handleConfirmationCancel}
      okText="Leave Page"
      cancelText="Stay on Page"
      className="leave-page"
      closable={false}
      footer={[
        <>
          <Button onClick={handleSaveDraft} loading={loading} size="middle">
            {' '}
            Save Draft
          </Button>
          <Button onClick={handleConfirmationCancel} size="middle">
            Stay
          </Button>
          <Button
            className="blue-filled-btn"
            size="middle"
            type="primary"
            onClick={handleConfirmationOk}
          >
            Leave Anyway
          </Button>
        </>,
      ]}
    >
      <Typography.Title level={4} className="title-font">
        Are you sure you want to leave this page?
      </Typography.Title>
      <Typography.Text>
        You have unsaved changes. If you leave the page, these changes will be
        lost.
      </Typography.Text>
    </Modal>
  );
};

export default memo(LeavePageModal);

