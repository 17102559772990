import { Button, Col, Popover, Row, Typography, Tag, Collapse } from 'antd';
import {
  ArrowLeftOutlined,
  ShareAltOutlined,
  DownOutlined,
} from '@ant-design/icons';
import React, { useState } from 'react';

export default function ScenarioPlanningDetail() {
  const { Panel } = Collapse;
  const [activeCollapse, setActiveCollapse] = useState(false);

  return (
    <div className="plr-0 pt-0">
      <Row align="middle" className="breadcrums" justify="space-between">
        <Typography.Title level={5}>
          <ArrowLeftOutlined
            className="mr-10"
            onClick={() => window.history.back()}
          />{' '}
          Opportunity Detail
        </Typography.Title>
      </Row>

      <Row className="w-100 mt-20">
        <Col span={24}>
          <div className="opportunity-detail">
            <Row align="middle" justify="space-between">
              <Typography.Title level={4} className="title">
                Customer Loyalty Program
              </Typography.Title>

              <Popover
                content={'link copied'}
                placement="bottom"
                trigger="clicked"
              >
                <Button type="text" className="share-icon">
                  <ShareAltOutlined />
                  Share
                </Button>
              </Popover>
            </Row>

            <Typography.Title level={5} className="mt-20 text">
              An option for customers to have their purchases gift-wrapped,
              increasing the perceived value of the product. providing an
              incentive for customers to spend more. An option for customers to
              have their purchases gift-wrapped, increasing the perceived value
              of the product. providing an incentive for customers to spend
              more. An option for customers to have their purchases
              gift-wrapped, increasing the perceived value of the product.
              providing an incentive for customers to spend more.
            </Typography.Title>

            <Row className="details">
              <Col span={12} md={12} sm={24} xs={24}>
                <Typography.Title level={5} className="mb-0">
                  <span>Rank : </span> 07
                </Typography.Title>
                <Typography.Title level={5} className="mb-0">
                  <span>ID : </span> 649375
                </Typography.Title>
                <Typography.Title level={5} className="mb-0">
                  <span>Idea created date: </span>
                  05/01/2023
                </Typography.Title>
                <Typography.Title level={5} className="mb-0">
                  <span>Convert to opportunity : </span> 05/01/2023
                </Typography.Title>
              </Col>
              <Col span={12} md={12} sm={24} xs={24}>
                <Typography.Title level={5} className="mb-0">
                  <span>Type: </span>{' '}
                  <Tag className="opportunity capitalize-text">Opportunity</Tag>{' '}
                </Typography.Title>
                <Typography.Title level={5} className="mb-0">
                  <span>Category : </span> Process improvement{' '}
                </Typography.Title>
                <Typography.Title level={5} className="mb-0">
                  <span>Department : </span> Finances, Logistics, Marketing{' '}
                </Typography.Title>
              </Col>
            </Row>

            <Collapse
              expandIconPosition="end"
              expandIcon={({ isActive }) => (
                // eslint-disable-next-line
                setActiveCollapse(isActive),
                (<DownOutlined className="fs-16" rotate={isActive ? 180 : 0} />)
              )}
            >
              <Panel
                header={activeCollapse ? 'View Less' : 'View more'}
                key="1"
              >
                <Row align="middle" justify="space-between">
                  <Typography.Title level={4} className="title">
                    More Details
                  </Typography.Title>
                </Row>
              </Panel>
            </Collapse>
          </div>
        </Col>
      </Row>
    </div>
  );
}
