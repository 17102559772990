import { Modal, Row, Typography } from 'antd';
import { ECHOIMAGES } from 'Assets/Images';
import React, { memo } from 'react';
import { formatTime } from 'utils/CommonFunctions';
import MileStoneAnimation from './MileStoneAnimation';

const MileStoneModal = ({
  setIsMilestoneModal,
  isMilestoneModal,
  getTotalCompares,
  getSeconds,
}) => {
  const { MileStoneIcon } = ECHOIMAGES.compareIcons;

  const handleCancel = () => {
    setIsMilestoneModal(false);
  };
  return (
    <Modal
      open={isMilestoneModal}
      className="kudos-modal"
      footer={null}
      maskClosable={true}
      closable={true}
      onCancel={handleCancel}
      centered={true}
    >
      <div className="milestone-animation-container">
        <MileStoneAnimation />
      </div>
      <Row justify="center" align="middle">
        <img src={MileStoneIcon} alt="" />
      </Row>
      <Row justify="center">
        <Typography.Title level={3} className="mt-10">
          Another Milestone Achieved
        </Typography.Title>
      </Row>
      <Row justify="center">
        <Typography.Text level={3} className="fs-16">
          Comparisons completed : {getTotalCompares}
        </Typography.Text>
        <Typography.Text level={3} className="fs-16 ml-20 mb-15">
          Time : {formatTime(getSeconds)}
        </Typography.Text>
      </Row>
    </Modal>
  );
};

export default memo(MileStoneModal);

