import { Modal, Row } from 'antd';
import React, { memo } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Typography from 'antd/es/typography/Typography';
import { useHistory } from 'react-router-dom';

const EditModal = ({ isModalOpen, setIsModalOpen, editItemId }) => {
  const history = useHistory();

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOnEdit = () => {
    if (editItemId?.type === '#') {
      history.push(`/measurementfield/numeric/${editItemId?.id}`);
    } else {
      history.push(`/measurementfield/scale/${editItemId?.id}`);
    }
  };

  return (
    <Modal
      open={isModalOpen}
      onCancel={handleCancel}
      className="Edit-modal"
      okText="Edit"
      onOk={handleOnEdit}
      closable={false}
    >
      <Row align="middle">
        <ExclamationCircleOutlined className="headsup-icon" />
        <Typography.Title level={5} className="mb-0 ml-15">
          Heads Up
        </Typography.Title>
      </Row>
      <Row className="text-align">
        <Typography.Text>
          In a stage, this measurement field is being <br /> used. There will be
          consequences if you edit.
        </Typography.Text>
      </Row>
    </Modal>
  );
};

export default memo(EditModal);

