import {
  CheckOutlined,
  InfoCircleOutlined,
  MoreOutlined,
} from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Drawer,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Radio,
  Row,
  Select,
  Skeleton,
  Space,
  Switch,
  Table,
  Tag,
  Tooltip,
  Typography,
  Empty,
  Grid,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { ECHOIMAGES } from '../../Assets/Images';
import {
  getCurrentOrganization,
  getCurrnetUserData,
  getHomeTour,
  getRateTour,
  isSME,
  setRateDetailTour,
  setRateTour,
} from '../../store/slices/loginSlice';
import { createHiddenGamma, deleteHideGammas } from './RateFunctions';
import { filterDataByTimeRange } from '../../utils/commonMethods';
import { levelColors } from 'utils/Constants';
import { RESTGet, RESTGetAuthorization } from 'utils/RESTApi';
import {
  fetchOrganizationStages,
  fetchOrgDepartments,
} from 'utils/CommonFunctions';
import Onboarding from 'Common/Onboarding';
import FilterButton from 'Common/FilterButton';

export default function PriorityList() {
  const { Search } = Input;
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const { SortIcon, FilterIcon } = ECHOIMAGES.ideaIcons;
  const [dashboardData, setDashboardData] = useState([]);
  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const isSMEGroup = useSelector(isSME);
  const startFirstTimeTour = useSelector(getHomeTour);
  const startRateTour = useSelector(getRateTour);
  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;
  const userId = currnetOrg?.adminID || loginUsrOrg?.id;

  const [open, setOpen] = useState(false);
  const [backeupData, setBackupData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalObjectives, setTotalObjectives] = useState();
  const [hideItems, setHideItems] = useState(false);
  const [hideItemsFilter, setHideItemsFilter] = useState(false);
  const [filterLevel, setFilterLevel] = useState([]);
  const [listAllDepartments, setListAllDepartments] = useState([]);
  const [departmentFilter, setDepartmentFilter] = useState([]);
  const [timeRange, setTimeRange] = useState('');
  const [customRange, setCustomRange] = useState();
  const [selectAll, setSelectAll] = useState(false);
  const [selectAllType, setSelectAllType] = useState(false);
  const [scaleValue, setScaleValue] = useState({});

  const { RangePicker } = DatePicker;
  const history = useHistory();
  // drawer stuff
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const onSearch = (e) => {
    if (e !== '') {
      const temp = backeupData.filter(
        (item) =>
          item?.title &&
          item?.level?.name &&
          item?.hidden === hideItems &&
          item?.friendlyId &&
          (item?.title.toLowerCase().includes(e.toLowerCase()) ||
            item?.level?.name?.toLowerCase().includes(e.toLowerCase()) ||
            item?.friendlyId.toString().includes(parseInt(e)))
      );
      setDashboardData(temp);
    } else {
      if (hideItems) {
        setDashboardData(backeupData);
      } else {
        setDashboardData(
          backeupData.filter((item) => item?.hidden === hideItems)
        );
      }
    }
  };

  const filterApply = () => {
    const temp = [];
    let hideFilterData = backeupData.filter((item) => item?.hidden);
    if (hideItemsFilter) {
      hideFilterData.map((item) => {
        const typeFilter =
          item?.level?.name &&
          filterLevel
            ?.map((el) => el.toLowerCase())
            ?.includes(item?.level?.name?.levetoLowerCase());
        const data =
          item?.level?.name &&
          item?.departments?.items.filter((e) =>
            departmentFilter.includes(e?.department?.name)
          );
        if (data?.length || typeFilter) {
          temp.push(item);
        }
        return temp;
      });
    } else {
      backeupData.map((item) => {
        const typeFilter =
          item?.level?.name &&
          filterLevel
            ?.map((el) => el.toLowerCase())
            ?.includes(item?.level?.name?.toLowerCase());
        const data =
          item?.level?.name &&
          item?.departments?.items.filter((e) =>
            departmentFilter?.includes(e?.department?.name)
          );
        if (data?.length || typeFilter) {
          temp.push(item);
        }
        return temp;
      });
    }
    let tempFlag = filterLevel?.length || departmentFilter?.length;
    const filterDate = filterDataByTimeRange(
      timeRange,
      tempFlag ? temp : hideItemsFilter ? hideFilterData : backeupData,
      customRange
    );
    setDashboardData(filterDate);
    setOpen(false);
  };
  let isnextToken = null;
  const getNextTokenRatings = async () => {
    let list = [];
    do {
      let rankingApi = `/rate?id=${userId}&organizationID=${orgId}&nextToken=${isnextToken}`;
      let nextTokenResponse = await RESTGetAuthorization(rankingApi);
      isnextToken = nextTokenResponse?.body?.Organization?.gammas?.nextToken;
      const filterGammas = nextTokenResponse?.body?.Organization?.gammas?.items;
      filterGammas?.map((item) => {
        const filterHideItems = item?.hiddenUsers?.items.filter(
          (e) => e?.userId === userId
        );
        if (filterHideItems?.length) {
          item.hidden = true;
          item.hidenID = filterHideItems?.[0]?.id;
        } else {
          item.hidden = false;
        }
        return item;
      });
      list = [...list, ...filterGammas];
      if (!isnextToken) {
        return list;
      }
    } while (isnextToken);
  };
  // for listing gamma ratings
  const listAllGammasRatingCount = async () => {
    let list = [];
    try {
      let rankingApi = `/rate?id=${userId}&organizationID=${orgId}&nextToken=${isnextToken}`;
      let res = await RESTGetAuthorization(rankingApi);
      isnextToken = res?.body?.Organization?.gammas?.nextToken;
      const filterGammas = res?.body?.Organization?.gammas?.items;
      filterGammas?.map((item) => {
        const filterHideItems = item?.hiddenUsers?.items.filter(
          (e) => e?.userId === userId
        );
        if (filterHideItems?.length) {
          item.hidden = true;
          item.hidenID = filterHideItems?.[0]?.id;
        } else {
          item.hidden = false;
        }
        return item;
      });

      const AllGammas = filterGammas?.filter((el) => !el?.hidden);
      list = hideItems ? filterGammas : AllGammas;
      setDashboardData(hideItems ? filterGammas : AllGammas);
      setBackupData(filterGammas);
      setLoading(false);
      setTotalObjectives(res?.body?.Organization?.objectives?.items?.length);
      if (isnextToken) {
        let listData = await getNextTokenRatings();
        list = [...list, ...listData];
        const allRatings = list?.filter((el) => !el?.hidden);
        setDashboardData(hideItems ? list : allRatings);
        setBackupData(list);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);

      console.log(error);
    }
  };

  // to hide gammas
  const hideRateGammas = async (row) => {
    try {
      const inputData = {
        gammaId: row?.id,
        userId: userId,
      };
      await createHiddenGamma(inputData);
      await listAllGammasRatingCount();
      message.success('item hide successfully');
    } catch (error) {
      console.log(error);
    }
  };

  // to unhide gammas
  const UnhideRateGammas = async (row) => {
    try {
      const inputData = {
        id: row.hidenID,
      };
      await deleteHideGammas(inputData);
      await listAllGammasRatingCount();
      message.success('item unhide successfully');
    } catch (error) {
      console.log(error);
    }
  };
  const dispatch = useDispatch();
  const listOrgDepartments = async () => {
    const departments = await fetchOrgDepartments(orgId);
    setListAllDepartments(departments);
  };
  const [joyrideStepIndex, setJoyrideStepIndex] = useState(0);
  const [runTour, setRunTour] = useState(false);

  const handleRowClick = (record) => {
    if (startRateTour) {
      localStorage.setItem('firstTimeinRate', true);
      setRunTour(false);
      dispatch(setRateTour(false));
      dispatch(setRateDetailTour(true));
    }
    setJoyrideStepIndex(1);
    history.push({ pathname: '/rate-detail', state: { data: record } });
  };

  const sortByHighToLow = () => {
    const sortHighToLow = [...dashboardData].sort(
      (a, b) => a.ratingsByUser - b.ratingsByUser
    );
    setDashboardData(sortHighToLow);
  };

  const sortByLowToHigh = () => {
    const sortHighToLow = [...dashboardData].sort(
      (a, b) => b.ratingsByUser - a.ratingsByUser
    );
    setDashboardData(sortHighToLow);
  };

  const showAllData = () => {
    setDashboardData(backeupData);
  };

  const [allStages, setAllStages] = useState([]);

  const getStageName = async () => {
    try {
      const sortedStages = await fetchOrganizationStages(orgId);
      setAllStages(sortedStages);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    listOrgDepartments();
    getStageName();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    listAllGammasRatingCount();
    // eslint-disable-next-line
  }, [hideItems]);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'friendlyId',
      key: 'friendlyId',
      width: '6%',
      sorter: {
        compare: (a, b) => a.friendlyId - b.friendlyId,
      },
      render: (text, row) => (
        <Link to={{ pathname: '/rate-detail', state: { data: row } }}>
          <span className="fs-14">
            {text ? text?.toString().padStart(6, '0') : '-'}
          </span>{' '}
        </Link>
      ),
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: '25%',
      render: (text, row) => (
        <Link to={{ pathname: '/rate-detail', state: { data: row } }}>
          <span className={row.hidden === true ? 'hiddem-item fs-14' : 'fs-14'}>
            {text}
          </span>
        </Link>
      ),
      sorter: (a, b) => a.title.localeCompare(b.title),
    },
    {
      title: (
        <span>
          Stage{' '}
          <Tooltip
            title="Ideas -> Opportunity -> Project. As ideas gain traction with more positive votes 
          from team members, ideas can get promoted up to opportunity and could be selected as a project."
          >
            <InfoCircleOutlined />
          </Tooltip>
        </span>
      ),
      key: 'Type',
      dataIndex: 'level',
      width: '10%',
      render: (text, row, { Type }) => {
        return (
          <Link to={{ pathname: '/rate-detail', state: { data: row } }}>
            <Tag key={text.id} color={levelColors[text.level]}>
              {text?.name?.toUpperCase()}
            </Tag>
          </Link>
        );
      },
      sorter: (a, b) => a.level.level - b.level.level,
    },
    {
      title: 'Goals to Be Rated',
      dataIndex: 'ratingsByUser',
      key: 'ratingsByUser',
      width: '10%',
      align: 'center',
      render: (text, row) => {
        return <span>{totalObjectives - row.ratingsByUser}</span>;
      },
      sorter: (a, b) => a.ratingsByUser - b.ratingsByUser,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      key: 'rate',
      width: '5%',
      render: (text, row) => {
        return (
          <Button className="bordered-button" size="small">
            Rate
          </Button>
        );
      },
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      width: '5%',
      align: 'center',
      onCell: () => ({
        onClick: (event) => {
          event.stopPropagation();
        },
      }),
      render: (text, row) => (
        <Dropdown
          overlay={
            <Menu
              items={[
                row.hidden !== true
                  ? {
                      label: <span>Hide</span>,
                      key: '0',
                      onClick: () => {
                        hideRateGammas(row);
                      },
                    }
                  : {
                      label: <span>Unhide</span>,
                      key: '0',
                      onClick: () => {
                        UnhideRateGammas(row);
                      },
                    },
              ]}
            />
          }
          trigger={['click']}
        >
          <MoreOutlined className="dot-style" />
        </Dropdown>
      ),
    },
  ].filter((item) => !item.hidden);

  const typeOpt = ['Idea', 'Opportunity', 'Project'];

  return (
    <div className="idea background-white rate-page">
      <Row className="header" align="middle">
        <div className="w-100">
          <Row
            justify="space-between"
            align="middle"
            className="idea-header w-100"
          >
            <Search
              className={
                screens.xs ? 'searchbar-style mr-5' : 'searchbar-style mb-10'
              }
              placeholder="Search by keyword or ID"
              onChange={(e) => onSearch(e.target.value)}
            />
            {screens.xs && (
              <>
                <Select
                  placeholder="Select sort"
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  className="h-35"
                  options={[
                    {
                      value: 'None',
                      label: <Space onClick={showAllData}>None</Space>,
                    },
                    {
                      value: 'Rated goal - Low to High',
                      label: (
                        <Space onClick={sortByLowToHigh}>
                          <span
                            role="img"
                            aria-label="China"
                            className="select-icon"
                          >
                            <CheckOutlined />
                          </span>
                          Rated goal - Low to High
                        </Space>
                      ),
                    },
                    {
                      value: 'Rated goal - High to Low',
                      label: (
                        <Space onClick={sortByHighToLow}>
                          <span
                            role="img"
                            aria-label="China"
                            className="select-icon"
                          >
                            <CheckOutlined />
                          </span>
                          Rated goal - High to Low
                        </Space>
                      ),
                    },
                  ]}
                />
                <FilterButton onClick={showDrawer} />
              </>
            )}

            <Row
              gutter={[0, 10]}
              className={screens.xs ? 'mb-20 mt-20' : 'mb-10'}
            >
              <div className="switch-wrapper">
                <Typography.Text className="mr-10 fs-15 fw-bold">
                  View hidden items
                </Typography.Text>
                <Switch
                  size="small"
                  checked={hideItems}
                  onChange={(e) => {
                    setHideItems(e);
                    if (e) {
                      setDashboardData(backeupData);
                    } else {
                      setDashboardData(
                        backeupData.filter((item) => !item.hidden)
                      );
                    }
                  }}
                  className="mr-10"
                />
              </div>
              {!screens.xs && (
                <>
                  <Typography.Text className="d-flex m-auto">
                    Sort byy
                  </Typography.Text>
                  <Select
                    placeholder="Select sort"
                    className="h-35 ml-10 rate-dropdown"
                    defaultValue="None"
                    options={[
                      {
                        value: 'None',
                        label: <Space onClick={showAllData}>None</Space>,
                      },
                      {
                        value: 'Rated goal - Low to High',
                        label: (
                          <Space onClick={sortByLowToHigh}>
                            Rated goal - Low to High
                          </Space>
                        ),
                      },
                      {
                        value: 'Rated goal - High to Low',
                        label: (
                          <Space onClick={sortByHighToLow}>
                            Rated goal - High to Low
                          </Space>
                        ),
                      },
                    ]}
                  />
                  <FilterButton onClick={showDrawer} />
                  {/* <Button
                    className="ml-10 h-35 filter-btn border-color"
                    onClick={showDrawer}
                  >
                    <img
                      src={FilterIcon}
                      alt=""
                      className={screens.xs ? '' : 'mr-10'}
                    />{' '}
                    {screens.xs ? '' : 'Filter'}
                  </Button> */}
                </>
              )}
            </Row>
          </Row>
          <Row>
            <Col span={24}>
              {loading ? (
                <Skeleton
                  active
                  paragraph={{
                    rows: 4,
                  }}
                />
              ) : (
                <Table
                  columns={columns}
                  dataSource={dashboardData}
                  pagination={false}
                  className="cursor-pointer"
                  scroll={{ x: 1000, y: 550 }}
                  onRow={(record) => ({
                    onClick: () => {
                      handleRowClick(record);
                    },
                  })}
                  size={screens.xxl ? 'middle' : 'small'}
                />
              )}
            </Col>
          </Row>

          <Drawer
            className="related-item-drawer brawer-with-btn"
            title={
              <Row justify="space-between" align="middle">
                <Typography.Title level={3} className="mb-0 ">
                  Filters
                </Typography.Title>
                <Button
                  type="text"
                  className="text-blue fs-14 p-0"
                  onClick={() => {
                    setDepartmentFilter([]);
                    setFilterLevel([]);
                    setSelectAll(false);
                    setSelectAllType(false);
                  }}
                >
                  <strong>Clear All</strong>
                </Button>
              </Row>
            }
            closeIcon={null}
            closable={false}
            height={screens.xs ? '83vh' : ''}
            placement={screens.xs ? 'bottom' : 'right'}
            onClose={onClose}
            open={open}
          >
            <Row justify="space-between" align="middle">
              <Typography.Title
                level={5}
                className="mb-0 fw-400 fs-14 text-black"
              >
                View hidden items only
              </Typography.Title>
              <Switch
                defaultChecked
                size="small"
                checked={hideItemsFilter}
                onChange={(e) => setHideItemsFilter(e)}
              />
            </Row>

            <Form className="mt-20">
              <div className="mb-20">
                <Row justify="space-between" align="middle">
                  <Typography.Title level={5} className="mb-0">
                    Stage
                  </Typography.Title>

                  <Checkbox
                    className="select-all"
                    checked={selectAllType}
                    onChange={(e) => {
                      setFilterLevel(
                        e.target.checked
                          ? allStages?.length > 0 &&
                              allStages?.map((data) => data?.name)
                          : []
                      );
                      setSelectAllType(e.target.checked);
                    }}
                  >
                    Select All
                  </Checkbox>
                </Row>
                <Divider className="mt-10 mb-10" />

                <Checkbox.Group
                  className="vertical-box"
                  value={filterLevel}
                  onChange={(e) => setFilterLevel(e)}
                >
                  {allStages
                    ?.filter((item) => item?.active) // Filter items with active set to true
                    .sort((a, b) => a.level - b.level)
                    .map((item) => {
                      return (
                        <Checkbox value={item?.name} key={item?.id}>
                          {item?.name}
                        </Checkbox>
                      );
                    })}
                </Checkbox.Group>
              </div>

              <div className="mb-20">
                <Row justify="space-between" align="middle">
                  <Typography.Title level={5} className="mb-0">
                    Department
                  </Typography.Title>
                  <Checkbox
                    className="select-all"
                    checked={selectAll}
                    onChange={(e) => {
                      setDepartmentFilter(
                        e.target.checked
                          ? listAllDepartments?.map((data) => data?.name)
                          : []
                      );
                      setSelectAll(e.target.checked);
                    }}
                  >
                    Select All
                  </Checkbox>
                </Row>
                <Divider className="mt-10 mb-10" />
                {listAllDepartments?.length ? (
                  <Checkbox.Group
                    className="vertical-box"
                    value={departmentFilter}
                    onChange={(e) => setDepartmentFilter(e)}
                  >
                    {listAllDepartments.map((item, index) => {
                      return (
                        <Checkbox value={item?.name} key={index}>
                          {item?.name}
                        </Checkbox>
                      );
                    })}
                  </Checkbox.Group>
                ) : (
                  <Empty />
                )}
              </div>

              <div className="mb-20">
                <Row justify="space-between" align="middle">
                  <Typography.Title level={5} className="mb-0">
                    Created date
                  </Typography.Title>
                </Row>
                <Divider className="mt-10 mb-10" />

                <Radio.Group className="vertical-box">
                  <Radio
                    value="today"
                    checked={timeRange === 'today'}
                    onChange={() => setTimeRange('today')}
                  >
                    Today
                  </Radio>
                  <Radio
                    value="last7days"
                    checked={timeRange === 'last7days'}
                    onChange={() => setTimeRange('last7days')}
                  >
                    Last 7 days
                  </Radio>
                  <Radio
                    value="lastWeek"
                    checked={timeRange === 'lastWeek'}
                    onChange={() => setTimeRange('lastWeek')}
                  >
                    Last week
                  </Radio>
                  <Radio
                    value="thisMonth"
                    checked={timeRange === 'thisMonth'}
                    onChange={() => setTimeRange('thisMonth')}
                  >
                    This month
                  </Radio>
                  <Radio
                    value="lastMonth"
                    checked={timeRange === 'lastMonth'}
                    onChange={() => setTimeRange('lastMonth')}
                  >
                    Last month
                  </Radio>
                  <Radio
                    value="customRange"
                    checked={timeRange === 'customRange'}
                    onChange={() => setTimeRange('customRange')}
                  >
                    Custom range
                  </Radio>
                  {timeRange === 'customRange' && (
                    <RangePicker onChange={(e) => setCustomRange(e)} />
                  )}
                </Radio.Group>
              </div>
            </Form>

            <div className="drawer-btn">
              <Button
                className="drawer-btn-child"
                onClick={onClose}
                size="middle"
              >
                Cancel
              </Button>
              <Button
                type="primary"
                className="drawer-btn-child"
                htmlType="submit"
                onClick={filterApply}
                size="middle"
              >
                Apply
              </Button>
            </div>
          </Drawer>
        </div>
      </Row>
      {((isSMEGroup && startFirstTimeTour) ||
        (isSMEGroup && startRateTour)) && (
        <Onboarding
          dashboardData={dashboardData}
          joyrideStepIndex={joyrideStepIndex}
          setRunTour={setRunTour}
          runTour={runTour}
          startFirstTimeTour={startFirstTimeTour}
        />
      )}
    </div>
  );
}

