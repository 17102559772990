import { message } from 'antd';
import {
  createIdea,
  getUsers,
  getIdeas,
  searchRelatedItem,
  fetchFuzzySearchGammas,
  LinkedRelatedGammasCustom,
  getLinkIdea,
  deleteLinkGammaCustom,
  listDepartmentsCustom,
  createDepartmentGammaCustom,
  createLevelChangePromoteCustom,
  deleteDepartmentGammaCustom,
} from '../../utils/Actions';
import { UserRole, userStatus } from 'utils/Constants';
import { getUsersByOrganizationIDCustom } from 'Pages/SuperAdmin/SuperAdminFun';

export const submitIdea = async (data) => {
  try {
    const addIdea = await createIdea(data);
    return addIdea;
  } catch (error) {
    message.error(error.message);
  }
};

export const FetchUser = async (data, id, nextToken) => {
  try {
    const listUser = await getUsersByOrganizationIDCustom(data, id, nextToken);
    return listUser;
  } catch (error) {
    message.error(error.message);
  }
};

export const getAllIdeas = async () => {
  try {
    const listIdeas = await getIdeas();
    return listIdeas;
  } catch (error) {
    message.error(error.message);
  }
};

export const fuzzySearchGammas = async (title) => {
  try {
    const listIdeas = await fetchFuzzySearchGammas(title);
    return listIdeas;
  } catch (error) {
    message.error(error.message);
  }
};
export const relatedItems = async () => {
  try {
    const ideaTitle = await searchRelatedItem();
    return ideaTitle;
  } catch (error) {
    console.log(error);
  }
};

export const linkAddIdea = async (data) => {
  try {
    const linkData = await LinkedRelatedGammasCustom(data);
    return linkData;
  } catch (error) {
    console.log(error);
  }
};

export const getLinkedIdeas = async (id) => {
  try {
    const getIdea = await getLinkIdea(id);
    return getIdea;
  } catch (error) {
    console.log(error);
  }
};

export const deleteLink = async (id) => {
  try {
    const unlinkIdea = await deleteLinkGammaCustom(id);
    return unlinkIdea;
  } catch (error) {
    console.log(error);
  }
};
export const listDepartments = async (filter) => {
  try {
    const listAllDepartments = await listDepartmentsCustom(filter);
    return listAllDepartments;
  } catch (error) {
    console.log(error);
  }
};
export const createDepartmentGamma = async (data) => {
  try {
    const addDepartment = await createDepartmentGammaCustom(data);
    return addDepartment;
  } catch (error) {
    message.error(error.message);
  }
};

export const createLevelChangePromote = async (data) => {
  try {
    const addDepartment = await createLevelChangePromoteCustom(data);
    return addDepartment;
  } catch (error) {
    message.error(error?.errors[0]?.message);
  }
};

export const deleteDepartmentGamma = async (id) => {
  try {
    const deleteDepartment = await deleteDepartmentGammaCustom(id);
    return deleteDepartment;
  } catch (error) {
    message.error(error.message);
  }
};
