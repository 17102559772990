import { Dropdown, Grid, Menu, Table, Typography } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { MoreOutlined, EditOutlined } from '@ant-design/icons';
import { viewAs } from 'store/slices/loginSlice';
import { setDefaultBatchID } from 'store/slices/compareSlice';
import PromotePartnerModal from './PromotePartnerModal';
import AssignPartnerModal from './AssignPartnerModal';
import { useDispatch, useSelector } from 'react-redux';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { getOrganizationCustom } from 'utils/Actions';
import { getScopingIds, setScopingIds } from 'store/slices/brandingSlice';

const SuperAdminTable = ({
  organizationDetails,
  showModal,
  setOrgDeleteID,
  sortTableData,
  sortOrder,
  sortColumn,
  handelInfiniteScroll,
  fetchOrganizations,
  setCurrentPage,
}) => {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const dispatch = useDispatch();
  const history = useHistory();
  const fetchScopingIds = useSelector(getScopingIds);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [orgDetail, setOrgDetail] = useState({});

  const [assignModalOpen, setIsAssignModalOpen] = useState(false);
  const [assignPartnerData, setAssignPartnerData] = useState();

  //functio to show contact in country format
  const formatContact = (contact) => {
    if (!contact) return '-';
    const phoneNumber = parsePhoneNumberFromString(contact);
    if (phoneNumber) {
      const countryCode = phoneNumber.country;
      switch (countryCode) {
        case 'US':
          return phoneNumber.formatNational({
            format: 'NATIONAL',
            spaces: true,
          });
        default:
          return phoneNumber.formatInternational();
      }
    }
    return '-';
  };

  //function to fetch organization by id
  const getOrgByID = async (e) => {
    let data = await getOrganizationCustom({ id: e?.id });
    dispatch(viewAs(data));
    dispatch(setScopingIds([...fetchScopingIds, e?.id]));
    dispatch(setDefaultBatchID(''));
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sortOrder:
        sortColumn === 'name'
          ? sortOrder === 'ASC'
            ? 'ascend'
            : 'descend'
          : null,
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: true,
      render: (text, row) => (
        <Link
          className="text-primary"
          onClick={(e) => {
            e.stopPropagation();
            getOrgByID(row);
          }}
          to={{ pathname: '/', state: { orgId: row?.id } }}
        >
          <span>{text}</span>
        </Link>
      ),
    },
    {
      title: 'Website',
      dataIndex: 'website',
      key: 'website',
      sortOrder:
        sortColumn === 'website'
          ? sortOrder === 'ASC'
            ? 'ascend'
            : 'descend'
          : null,
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, row) => {
        return (
          <Typography.Text
            style={
              true
                ? {
                    width: 200,
                  }
                : undefined
            }
            ellipsis={
              true
                ? {
                    tooltip: row?.website,
                  }
                : false
            }
          >
            <a className="text-primary" href={row.website}>
              {row?.website}
            </a>
          </Typography.Text>
        );
      },
    },
    {
      title: 'Contact number',
      dataIndex: 'contact',
      key: 'contact',
      sortOrder:
        sortColumn === 'contact'
          ? sortOrder === 'ASC'
            ? 'ascend'
            : 'descend'
          : null,
      sorter: (a, b) => {
        return a - b;
      },
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, row) => {
        return (
          <span>
            {' '}
            {row?.contact !== undefined ? formatContact(row?.contact) : '-'}
          </span>
        );
      },
    },
    {
      title: 'Partner',
      dataIndex: 'partner',
      key: 'partner',
      render: (text, row) => {
        return (
          <>
            <Typography.Text
              style={
                true
                  ? {
                      width: 130,
                    }
                  : undefined
              }
              ellipsis={
                true
                  ? {
                      tooltip: row?.Partner?.name,
                    }
                  : false
              }
              className="mr-10"
            >
              {row?.Partner?.name ? row?.Partner?.name : '-'}
            </Typography.Text>

            <EditOutlined
              className="editOutlined"
              onClick={(e) => {
                e.stopPropagation();
                setIsAssignModalOpen(true);
                setAssignPartnerData(row);
              }}
            />
          </>
        );
      },
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 130,
      sortOrder:
        sortColumn === 'createdAt'
          ? sortOrder === 'ASC'
            ? 'ascend'
            : 'descend'
          : null,
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      render: (text) => <span>{moment(text).format('l')}</span>,
    },
    {
      title: 'Users',
      dataIndex: 'users',
      key: 'users',
      width: 80,
      sortOrder:
        sortColumn === 'users'
          ? sortOrder === 'ASC'
            ? 'ascend'
            : 'descend'
          : null,
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a, b) => {
        return a - b;
      },
      render: (text) => {
        return <span>{text}</span>;
      },
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      fixed: 'right',
      width: 70,
      onCell: () => ({
        onClick: (event) => {
          event.stopPropagation();
        },
      }),
      render: (text, row) => (
        <Dropdown
          overlay={
            <Menu
              items={[
                {
                  label: <span>Delete</span>,
                  key: '1',
                  onClick: () => {
                    showModal();
                    setOrgDeleteID(row?.id);
                  },
                },
                {
                  label: <span>Edit</span>,
                  key: '2',
                  onClick: () => {
                    history.push(`/organization/edit-organization/${row?.id}`);
                  },
                },
                {
                  label: <span>Promote to Partner</span>,
                  key: '3',
                  onClick: () => {
                    setIsModalOpen(true);
                    setOrgDetail(row);
                  },
                },
              ]}
            />
          }
          trigger={['click']}
        >
          <MoreOutlined style={{ color: '#5AACC9' }} className="fs-20" />
        </Dropdown>
      ),
    },
  ];

  const handleRowClick = (record, event) => {
    history.push({
      pathname: `/organization/detail/${record.id}`,
      state: { data: record },
    });
  };
  return (
    <>
      <div
        style={{ maxHeight: 'calc(100vh - 140px)', overflowY: 'auto' }}
        onScroll={handelInfiniteScroll}
      >
        <Table
          onChange={(pagination, filter, sorter) => {
            sortTableData(sorter);
          }}
          pagination={false}
          columns={columns}
          dataSource={organizationDetails}
          className="admin-table cursor-pointer"
          scroll={{ x: 1000 }}
          size={screens.xxl ? 'middle' : 'small'}
          onRow={(record) => ({
            onClick: () => handleRowClick(record),
          })}
          sticky={{
            offsetHeader: 0,
          }}
        />
      </div>
      <PromotePartnerModal
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        orgDetail={orgDetail}
        fetchOrganizations={fetchOrganizations}
        setCurrentPage={setCurrentPage}
      />
      <AssignPartnerModal
        setIsAssignModalOpen={setIsAssignModalOpen}
        assignModalOpen={assignModalOpen}
        assignPartnerData={assignPartnerData}
        fetchOrganizations={fetchOrganizations}
        setCurrentPage={setCurrentPage}
      />
    </>
  );
};

export default SuperAdminTable;

