import React, { memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Divider,
  Dropdown,
  Empty,
  Grid,
  Image,
  Menu,
  Spin,
  Table,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import { InfoCircleOutlined, MoreOutlined } from '@ant-design/icons';
import { ECHOIMAGES } from 'Assets/Images';
import { levelColors, GraphlevelColors } from 'utils/Constants';
import '../idea.less';
import {
  isAdmin,
  isLeader,
  isSuperAdmin,
  getCurrentOrganization,
  getCurrnetUserData,
} from 'store/slices/loginSlice';
import { fetchOrganizationStages } from 'utils/CommonFunctions';
import moment from 'moment';

const RankingTable = ({
  dashboardData,
  handleClickEditRank,
  handleClickEditPromote,
  showDeleteRankingModal,
  setDeletePriorityID,
  selectedOption,
  setDashboardData,
  jsonData,
  itemLimit,
  selectSwitch,
  sortTableData,
  sortOrder,
  sortColumn,
  handelInfiniteScroll,
  loadData,
  showAllRanking,
}) => {
  const { upArrow, downArrow, minusArrow } = ECHOIMAGES.HomeIcons;
  const { emptyRankingIcon } = ECHOIMAGES.ideaIcons;
  const isAdminGroup = useSelector(isAdmin);
  const isSuperAdminGroup = useSelector(isSuperAdmin);
  const isLeaderGroup = useSelector(isLeader);
  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;

  const history = useHistory();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const [allStages, setAllStages] = useState([]);

  const getStageName = async () => {
    try {
      const sortedStages = await fetchOrganizationStages(orgId);
      setAllStages(sortedStages);
    } catch (err) {
      console.log(err);
    }
  };

  const handleColorChangeData = (obj) => {
    let colorCodeObject = {};
    let graphColorData = [...dashboardData];
    dashboardData.forEach((item) => {
      if (jsonData && jsonData[item.id]) {
        colorCodeObject[item.id] = jsonData[item.id];
      }
    });
    const dataArray = Object.entries(colorCodeObject);
    dataArray.reverse();
    const top5Data = dataArray.slice(0, itemLimit);
    const top5DataObject = Object.fromEntries(top5Data);
    graphColorData.map((i) => {
      if (top5DataObject[i.id]) {
        const getStageLevel = allStages?.filter(
          (item) => item?.id === i.level.id
        )[0];
        const gColor = GraphlevelColors[getStageLevel.level];
        i.color = gColor;
      } else {
        i.color = 'black';
      }
    });
    setDashboardData(graphColorData);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setDashboardData((prevData) => {
      const customSorter = (a, b) => {
        let rankingData = [...prevData];
        if (sorter.order == 'ascend') {
          return rankingData;
        } else {
          return rankingData;
        }
      };
      const sortedData = [...prevData].reverse(customSorter);
      if (selectSwitch) {
        handleColorChangeData(sortedData);
      }
      return sortedData;
    });
  };

  useEffect(() => {
    getStageName();
    // eslint-disable-next-line
  }, []);

  const columns = [
    {
      title: 'Rank',
      dataIndex: 'rank',
      key: 'rank',
      width: '7%',
      align: 'center',
      sortOrder:
        sortColumn === 'rank'
          ? sortOrder === 'ASC'
            ? 'ascend'
            : 'descend'
          : null,
      sorter: (a, b) => {
        return a - b;
      },
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, row) => {
        return {
          props: {
            className:
              row.fixedRank > 0 && (isAdminGroup || isSuperAdminGroup)
                ? 'editedRank rank'
                : '',
          },
          children: (
            <>
              {row.color && row.color !== 'black' && selectSwitch && (
                <Divider
                  type="vertical"
                  orientation="left"
                  style={{ backgroundColor: row.color }}
                />
              )}
              <span
                className={
                  row.outOfThreshold
                    ? ' fw-400 fs-14 outofthresholdcolor'
                    : 'fw-400 fs-14'
                }
                style={{ borderLeft: row.color }}
              >
                {row.fixedRank > 0
                  ? row.fixedRank
                  : row.isRank === false
                  ? '-'
                  : text || 0}
              </span>
            </>
          ),
        };
      },
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: '35%',
      sortOrder:
        sortColumn === 'title'
          ? sortOrder === 'ASC'
            ? 'ascend'
            : 'descend'
          : null,
      render: (text, row) => {
        return {
          props: {
            className:
              row.fixedRank > 0 && (isAdminGroup || isSuperAdminGroup)
                ? 'editedRank'
                : '',
          },
          children: (
            <span
              className={
                row?.outOfThreshold
                  ? 'fw-400 capitalize-text fs-14 outofthresholdcolor'
                  : 'fw-400 capitalize-text fs-14'
              }
            >
              {text}
            </span>
          ),
        };
      },
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: (
        <div
          style={{
            display: 'flex',
            gap: '5px',
            alignItem: 'center',
          }}
        >
          <span>Stage </span>
          <Tooltip
            title="Ideas -> Opportunity -> Project. As ideas gain traction with more positive votes
          from team members, ideas can get promoted up to opportunity and could be selected as a project."
          >
            <InfoCircleOutlined />
          </Tooltip>
        </div>
      ),
      key: 'Type',
      dataIndex: 'level',
      width: '15%',
      sortOrder:
        sortColumn === 'level'
          ? sortOrder === 'ASC'
            ? 'ascend'
            : 'descend'
          : null,
      render: (text, row) => {
        return {
          props: {
            className:
              row.fixedRank > 0 && (isAdminGroup || isSuperAdminGroup)
                ? 'editedRank'
                : '',
          },
          children: (
            <Tag key={text.id} color={levelColors[text.level]}>
              {text?.name?.toUpperCase()}
            </Tag>
          ),
        };
      },
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },

    {
      title: 'Department',
      dataIndex: 'departments',
      key: 'departments',
      render: (text, row) => {
        const departmentNames = row?.departments?.map((item) => item?.name);
        return {
          props: {
            className:
              row.fixedRank > 0 && (isAdminGroup || isSuperAdminGroup)
                ? 'editedRank'
                : '',
          },
          children: (
            <Typography.Text
              style={
                true
                  ? {
                      width: 200,
                    }
                  : undefined
              }
              ellipsis={
                true
                  ? {
                      tooltip: departmentNames?.join(', '),
                    }
                  : false
              }
            >
              {departmentNames?.join(', ')}
            </Typography.Text>
          ),
        };
      },
    },
    {
      title: 'Created on',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '11%',
      render: (text, row) => {
        return {
          props: {
            className:
              row.fixedRank > 0 && (isAdminGroup || isSuperAdminGroup)
                ? 'editedRank'
                : '',
          },
          children: <span>{moment(text)?.format('l')}</span>,
        };
      },
    },
    {
      title: (
        <div
          style={{
            display: 'flex',
            gap: '5px',
            alignItem: 'center',
          }}
        >
          <span>Change</span>
          <Tooltip
            title="Has the rank changed recently? For example, 
          if an idea gets positive votes and moves from rank #10 to #4 in the rankings, the change will be +6."
          >
            <InfoCircleOutlined />
          </Tooltip>
        </div>
      ),
      dataIndex: 'change',
      key: 'change',
      width: '10%',
      align: 'center',
      sortOrder:
        sortColumn === 'change'
          ? sortOrder === 'ASC'
            ? 'ascend'
            : 'descend'
          : null,
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: {
        compare: (a, b) => a.change - b.change,
      },
      render: (_, row) => {
        return {
          props: {
            className:
              row.fixedRank > 0 && (isAdminGroup || isSuperAdminGroup)
                ? 'editedRank'
                : '',
          },
          children:
            row?.previousRank[selectedOption] - row.rank > 0 ? (
              <>
                {row.isRank === false ? (
                  <>
                    <span className=" fw-700 fs-14">-</span>
                  </>
                ) : (
                  <>
                    {row.fixedRank > 0 ? (
                      row.rank - row.fixedRank === 0 ? (
                        <>
                          <Image src={minusArrow} preview={false} />
                          <span
                            className={
                              row.outOfThreshold
                                ? 'outofthresholdcolor fw-700 fs-14'
                                : 'fw-700 fs-14'
                            }
                          >
                            {' '}
                            {row.rank - row.fixedRank}
                          </span>
                        </>
                      ) : (
                        <>
                          <Image src={upArrow} preview={false} />

                          <span
                            className={
                              row.outOfThreshold
                                ? 'outofthresholdcolor fw-700 fs-14'
                                : 'fw-700 fs-14'
                            }
                          >
                            {' '}
                            +{row?.previousRank[selectedOption] - row.rank}
                          </span>
                        </>
                      )
                    ) : (
                      <>
                        <Image src={upArrow} preview={false} />
                        <span
                          className={
                            row.outOfThreshold
                              ? 'outofthresholdcolor fw-700 fs-14'
                              : 'fw-700 fs-14'
                          }
                        >
                          {' '}
                          +{row?.previousRank[selectedOption] - row.rank}
                        </span>
                      </>
                    )}
                  </>
                )}
              </>
            ) : (
              <div>
                {row.isRank === false ? (
                  <>
                    <span
                      className={
                        row.outOfThreshold
                          ? 'outofthresholdcolor fw-700 fs-14'
                          : 'fw-700 fs-14'
                      }
                    >
                      -
                    </span>
                  </>
                ) : row.fixedRank !== null ? (
                  row.fixedRank > 0 || row?.previousRank[selectedOption] > 0 ? (
                    row.rank - row.fixedRank === 0 ? (
                      <>
                        <Image src={minusArrow} preview={false} />
                        <span
                          className={
                            row.outOfThreshold
                              ? 'outofthresholdcolor fw-700 fs-14'
                              : 'fw-700 fs-14'
                          }
                        >
                          {' '}
                          {row.rank - row.fixedRank}
                        </span>
                      </>
                    ) : row?.previousRank[selectedOption] ? (
                      <>
                        {row?.previousRank[selectedOption] - row.rank === 0 ? (
                          <>
                            <Image src={minusArrow} preview={false} />
                            <span
                              className={
                                row.outOfThreshold
                                  ? 'outofthresholdcolor fw-700 fs-14'
                                  : 'fw-700 fs-14'
                              }
                            >
                              {' '}
                              0
                            </span>{' '}
                          </>
                        ) : (
                          <>
                            <Image src={downArrow} preview={false} />
                            <span
                              className={
                                row.outOfThreshold
                                  ? 'outofthresholdcolor fw-700 fs-14'
                                  : 'fw-700 fs-14'
                              }
                            >
                              {' '}
                              {row?.previousRank[selectedOption] - row.rank}
                            </span>
                          </>
                        )}
                      </>
                    ) : (
                      <div>
                        <img src={minusArrow} alt="" />
                        <span
                          className={
                            row.outOfThreshold
                              ? 'outofthresholdcolor fw-700 fs-14'
                              : 'fw-700 fs-14'
                          }
                        >
                          {' '}
                          0
                        </span>{' '}
                      </div>
                    )
                  ) : (
                    <>
                      <div>
                        <span
                          className={
                            row.outOfThreshold
                              ? 'outofthresholdcolor fw-700 fs-14'
                              : 'fw-700 fs-14'
                          }
                        >
                          {' '}
                          -
                        </span>{' '}
                      </div>
                    </>
                  )
                ) : row?.previousRank[selectedOption] ? (
                  <>
                    {' '}
                    {row?.previousRank[selectedOption] - row.rank === 0 ? (
                      <div>
                        <img src={minusArrow} alt="" />
                        <span
                          className={
                            row.outOfThreshold
                              ? 'outofthresholdcolor fw-700 fs-14'
                              : 'fw-700 fs-14'
                          }
                        >
                          {' '}
                          0
                        </span>{' '}
                      </div>
                    ) : (
                      <>
                        <Image src={downArrow} preview={false} />
                        <span
                          className={
                            row.outOfThreshold
                              ? 'outofthresholdcolor fw-700 fs-14'
                              : 'fw-700 fs-14'
                          }
                        >
                          {' '}
                          {row?.previousRank[selectedOption] - row.rank}
                        </span>
                      </>
                    )}
                  </>
                ) : (
                  '-'
                )}
              </div>
            ),
        };
      },
    },

    {
      title: '',
      dataIndex: 'Action',
      key: 'Action',
      fixed: 'right',
      width: '5%',
      align: 'center',
      hidden: isAdminGroup || isSuperAdminGroup || isLeaderGroup ? false : true,
      onCell: () => ({
        onClick: (event) => {
          event.stopPropagation();
        },
      }),
      render: (text, row, index) => {
        return {
          props: {
            className:
              row.fixedRank > 0 && (isAdminGroup || isSuperAdminGroup)
                ? 'editedRank'
                : '',
          },
          children: (
            <Dropdown
              arrow
              overlay={
                <Menu
                  items={[
                    {
                      key: '1',
                      label: 'Edit',
                      onClick: () =>
                        history.push({
                          pathname:
                            row.level.level === 1
                              ? `/edit-idea/${row.id}`
                              : `/ranking-detail/edit/${row.id}`,
                          state: { editPtiority: true },
                        }),
                      disabled: isLeaderGroup ? true : false,
                    },
                    {
                      key: '2',
                      label:
                        !isLeaderGroup && showAllRanking ? (
                          <Tooltip
                            title="To override the rank, uncheck the 'Show All' option. 
                          This will allow you to change and override the default rank."
                          >
                            <span>Override Rank</span>
                          </Tooltip>
                        ) : (
                          'Override Rank'
                        ),
                      onClick: () => handleClickEditRank(row),
                      disabled: isLeaderGroup
                        ? true
                        : showAllRanking
                        ? true
                        : false,
                    },
                    {
                      key: '3',
                      label: 'Promote Ranking',
                      onClick: () => handleClickEditPromote(row),
                    },
                    {
                      key: '4',
                      label: 'Delete',
                      onClick: () => {
                        showDeleteRankingModal();
                        setDeletePriorityID(row?.id);
                      },
                      disabled: isLeaderGroup ? true : false,
                    },
                  ].filter((item) => (isLeaderGroup ? !item.disabled : item))}
                />
              }
            >
              <MoreOutlined className="dot-style" />
            </Dropdown>
          ),
        };
      },
    },
  ].filter((item) => !item.hidden);

  const handleRowClick = (record, event) => {
    history.push({
      pathname: `/ranking-detail/${record.id}`,
      state: { data: record },
    });
  };

  return (
    <>
      <div
        style={{ maxHeight: 'calc(100vh - 140px)', overflowY: 'auto' }}
        onScroll={handelInfiniteScroll}
      >
        {dashboardData?.length > 0 ? (
          <Table
            onChange={(pagination, filter, sorter) => {
              sortTableData(sorter);
              handleTableChange;
            }}
            columns={columns}
            dataSource={dashboardData}
            pagination={false}
            onRow={(record) => ({
              onClick: () => handleRowClick(record),
            })}
            className="cursor-pointer ranking-table"
            scroll={{
              x: 1000,
            }}
            sticky={{
              offsetHeader: 0,
            }}
            size={screens.xxl ? 'middle' : 'small'}
          />
        ) : (
          <>
            <Empty
              className="p-30 pb-10"
              image={emptyRankingIcon}
              description={<span>No ranking matches the scenario.</span>}
            />
          </>
        )}
      </div>
      {loadData && (
        <Spin style={{ display: 'flex', justifyContent: 'center' }} />
      )}
    </>
  );
};

export default memo(RankingTable);

