import { Button, Modal, Typography } from 'antd';
import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setBrandingForm } from 'store/slices/brandingSlice';
import { setMFFormChange } from 'store/slices/StageSlice';
const LeaveMFModal = ({ setIsModalOpen, isModalOpen }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const handleOk = () => {
    setIsModalOpen(false);
    dispatch(setMFFormChange(false));
    dispatch(setBrandingForm(false));
    history.goBack();
  };
  return (
    <Modal
      open={isModalOpen}
      onOk={handleOk}
      onCancel={() => setIsModalOpen(false)}
      okText="Leave Page"
      cancelText="Stay on Page"
      className="leave-page"
      closable={false}
      footer={[
        <>
          <Button size="middle" onClick={handleOk}>
            Leave Anyway
          </Button>
          <Button
            onClick={() => setIsModalOpen(false)}
            size="middle"
            className="blue-filled-btn"
            type="primary"
          >
            Stay
          </Button>
        </>,
      ]}
    >
      <Typography.Title level={4} className="title-font">
        Are you sure you want to leave this page?
      </Typography.Title>
      <Typography.Text>
        You have unsaved changes. If you leave the page, these changes will be
        lost.
      </Typography.Text>
    </Modal>
  );
};
export default memo(LeaveMFModal);

