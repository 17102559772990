import { combineReducers } from 'redux';
import loginReducer from './loginSlice';
import stageReducer from './StageSlice';
import compareReducer from './compareSlice';
import brandingReducer from './brandingSlice';

const appReducer = combineReducers({
  loginReducer: loginReducer,
  stageReducer: stageReducer,
  compareReducer: compareReducer,
  brandingReducer: brandingReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    localStorage.clear();
    state = {};
  }
  return appReducer(state, action);
};

export default rootReducer;
