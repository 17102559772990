import { Storage } from 'aws-amplify';
import { debounce } from 'lodash';
import moment from 'moment';

export const SaveFileToS3 = async (file, level = 'public') => {
  return Storage.put(
    `${file?.name?.replace(
      /\.[^/.]+$/,
      ''
    )}_${Date.now()}.${file?.name?.replace(/(.*)\./g, '')}`.replace(/ /g, '_'),
    file,
    {
      level,
      contentType: file.type,
    }
  );
};

export const GetFileFromS3 = async (key, level = 'public') => {
  return Storage.get(key, { level });
};

export const sortArrayByProperty = (arr, property, ascending = true) => {
  const sortedArray = [...arr].sort((a, b) => {
    if (ascending) {
      return a[property].localeCompare(b[property]);
    } else {
      return b[property].localeCompare(a[property]);
    }
  });

  return sortedArray;
};

export const sortArrayByDate = (arr, property, oldest = true) => {
  const sortedDate = [...arr].sort((a, b) => {
    if (oldest) {
      return new Date(a[property]) - new Date(b[property]);
    } else {
      return new Date(b[property]) - new Date(a[property]);
    }
  });
  return sortedDate;
};

export const filterDataByTimeRange = (timeRange, data, customRange) => {
  const dateFormat = 'YYYY-MM-DD';
  const lastmonthfirstdate = moment()
    .subtract(1, 'months')
    .startOf('month')
    .format(dateFormat);
  const lastmonthlastdate = moment()
    .subtract(1, 'months')
    .endOf('month')
    .format(dateFormat);
  const currentmonthfirstdate = moment().startOf('month').format(dateFormat);
  const currentmonthlastdate = moment().endOf('month').format(dateFormat);
  switch (timeRange) {
    case 'today':
      return data.filter(
        (el) =>
          moment(el.createdAt).format(dateFormat) ===
          moment().format(dateFormat),
        'today'
      );
    case 'last7days':
      return data.filter(
        (el) =>
          moment(el.createdAt).format(dateFormat) <=
            moment().format(dateFormat) &&
          moment().subtract(7, 'd').format(dateFormat) <=
            moment(el.createdAt).format(dateFormat)
      );
    case 'lastWeek':
      return data.filter(
        (el) =>
          moment().subtract(1, 'weeks').startOf('week').format(dateFormat) <=
            moment(el.createdAt).format(dateFormat) &&
          moment(el.createdAt).format(dateFormat) <=
            moment().subtract(1, 'weeks').endOf('week').format(dateFormat)
      );
    case 'thisMonth':
      return data.filter(
        (el) =>
          currentmonthfirstdate <= moment(el.createdAt).format(dateFormat) &&
          moment(el.createdAt).format(dateFormat) <= currentmonthlastdate
      );
    case 'lastMonth':
      return data.filter(
        (el) =>
          lastmonthfirstdate <= moment(el.createdAt).format(dateFormat) &&
          moment(el.createdAt).format(dateFormat) <= lastmonthlastdate
      );
    case 'customRange':
      return data.filter(
        (el) =>
          customRange[0].format(dateFormat) <=
            moment(el.createdAt).format(dateFormat) &&
          customRange[1].format(dateFormat) >=
            moment(el.createdAt).format(dateFormat)
      );
    default:
      return data;
  }
};

/**
 * It takes an array and returns a comma seperated string
 * @param arr - The array to be converted to a comma-separated string.
 * @returns A comma-separated string of the array values.
 */
export const arrToCommaSeperated = (arr) => {
  let commaSeperated = '';
  if (arr && arr.length) commaSeperated = arr.join(', ');
  return commaSeperated;
};

export const searchTextInArrayOfObject = (text, keysToSearch, array) => {
  const results = array.filter((object) => {
    return keysToSearch.some((key) => {
      const value = object[key];
      if (typeof value === 'string') {
        return value.toLowerCase().includes(text.toLowerCase());
      } else if (typeof value === 'number') {
        return String(value).toLowerCase().includes(text.toLowerCase());
      } else if (typeof value === 'boolean') {
        return String(value).toLowerCase().includes(text.toLowerCase());
      } else {
        return false;
      }
    });
  });
  return results;
};

export function debounceFn(fn, delay) {
  const debouncedFn = debounce(fn, delay);
  return debouncedFn;
}
