import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Row,
  Select,
  Slider,
  Typography,
} from 'antd';
import { API } from 'aws-amplify';
import {
  createMeasurementFieldCustom,
  updateMeasurementFieldCustom,
} from 'graphql/customMutation';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import {
  getCurrentOrganization,
  getCurrnetUserData,
} from 'store/slices/loginSlice';
import { setMFFormChange, getMFFormChange } from 'store/slices/StageSlice';
import { getMeasurementFieldCustom } from 'utils/Actions';
import LeaveMFModal from './LeaveMFModal';

const MeasurementScale = () => {
  const { TextArea } = Input;
  const [selectedScaleType, setSelectedScaleType] = useState('-+');
  const [selectNumericType, setSelectNumericType] = useState('$');
  const [sliderColor, SetSliderColor] = useState([50]);
  const [loading, setLoading] = useState(false);
  const [detailsById, setDetailsById] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const history = useHistory();
  const [lowEnd, setLowEnd] = useState(0);
  const [highEnd, setHighEnd] = useState(0);
  const { id } = useParams();
  const [form] = Form.useForm();

  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const [isFormEdited, setIsFormEdited] = useState(false);
  const dispatch = useDispatch();
  const formhasChanged = useSelector(getMFFormChange);
  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;
  const [errors, setErrors] = useState({
    form1: '',
    form2: '',
    form3: '',
    form4: '',
    form5: '',
  });

  const [scaleValues, setScaleValues] = useState({
    form1: 'Very High',
    form2: 'High',
    form3: 'Medium',
    form4: 'Low',
    form5: 'Very Low',
  });

  // Function to update form values based on scale type
  const updateFormValues = (type) => {
    if (type === '-+') {
      // Negative to Positive
      setScaleValues({
        form1: scaleValues.form5 || 'Very High',
        form2: scaleValues.form4 || 'High',
        form3: scaleValues.form3 || 'Medium',
        form4: scaleValues.form2 || 'Low',
        form5: scaleValues.form1 || 'Very Low',
      });
    } else {
      // Positive to Negative
      setScaleValues({
        form1: scaleValues.form5 || 'Very Low',
        form2: scaleValues.form4 || 'Low',
        form3: scaleValues.form3 || 'Medium',
        form4: scaleValues.form2 || 'High',
        form5: scaleValues.form1 || 'Very High',
      });
    }
  };

  const handleScaleTypeChange = (value) => {
    setSelectedScaleType(value);
    updateFormValues(value);
  };
  const getMeasurementDetailsById = async () => {
    try {
      let res = await getMeasurementFieldCustom({ id: id });
      setDetailsById(res);
      setLowEnd(
        res?.breakpoints && res?.breakpoints?.length >= 2
          ? res?.breakpoints?.[0]?.value
          : 0
      );
      setHighEnd(
        res?.breakpoints && res?.breakpoints?.length >= 2
          ? res?.breakpoints?.[1].value
          : 100
      );
      setScaleValues({
        form1: res?.breakpoints?.[0]?.label,
        form2: res?.breakpoints?.[1]?.label,
        form3: res?.breakpoints?.[2]?.labe,
        form4: res?.breakpoints?.[3]?.label,
        form5: res?.breakpoints?.[4]?.label,
      });
      setSelectedScaleType(res?.subType);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (id) {
      getMeasurementDetailsById();
    }
    // eslint-disable-next-line
  }, []);

  let SliderStyles = null;

  useEffect(() => {
    const Slider = document.querySelector('.rate-slider');
    // eslint-disable-next-line
    SliderStyles = Slider?.style;
    if (selectedScaleType === '-+') {
      if (sliderColor >= 0 && sliderColor < 20) {
        SliderStyles?.setProperty(
          '--OuterDotColor',
          `linear-gradient(180deg, #FC5957 0%, #F93432 100%)`
        );
        SliderStyles?.setProperty(
          '--InnerDotColor',
          `linear-gradient(180deg, #C60807 0%, #ED2623 22.67%, #FF7171 100%)`
        );
      } else if (sliderColor >= 20 && sliderColor < 40) {
        SliderStyles?.setProperty(
          '--OuterDotColor',
          `linear-gradient(180deg, #FFAD58 0%, #FA8B14 100%)`
        );
        SliderStyles?.setProperty(
          '--InnerDotColor',
          `linear-gradient(180deg, #E48632 0%, #E48632 22.67%, #FEBA71 100%)`
        );
      } else if (sliderColor >= 40 && sliderColor < 60) {
        SliderStyles?.setProperty(
          '--OuterDotColor',
          `linear-gradient(180deg, #CDCDCD 0%, #858484 100%)`
        );
        SliderStyles?.setProperty(
          '--InnerDotColor',
          `linear-gradient(180deg, #838383 0%, #9A9A9A 22.67%, #C2C2C2 100%)`
        );
      } else if (sliderColor >= 60 && sliderColor < 80) {
        SliderStyles?.setProperty(
          '--OuterDotColor',
          `linear-gradient(180deg, #7CBF66 0%, #55B04E 100%)`
        );
        SliderStyles?.setProperty(
          '--InnerDotColor',
          `linear-gradient(180deg, #379225 0%, #3DA628 22.67%, #9ACF8F 100%)`
        );
      } else if (sliderColor >= 80 && sliderColor < 100) {
        SliderStyles?.setProperty(
          '--OuterDotColor',
          `linear-gradient(180deg, #17D971 0%, #0F7D42 100%)`
        );
        SliderStyles?.setProperty(
          '--InnerDotColor',
          `linear-gradient(180deg, #089348 0%, #0E9D51 22.67%, #2CD279 100%)`
        );
      }
    } else {
      if (sliderColor >= 0 && sliderColor < 20) {
        SliderStyles?.setProperty(
          '--OuterDotColor',
          `linear-gradient(180deg, #17D971 0%, #0F7D42 100%)`
        );
        SliderStyles?.setProperty(
          '--InnerDotColor',
          `linear-gradient(180deg, #089348 0%, #0E9D51 22.67%, #2CD279 100%)`
        );
      } else if (sliderColor >= 20 && sliderColor < 40) {
        SliderStyles?.setProperty(
          '--OuterDotColor',
          `linear-gradient(180deg, #7CBF66 0%, #55B04E 100%)`
        );
        SliderStyles?.setProperty(
          '--InnerDotColor',
          `linear-gradient(180deg, #379225 0%, #3DA628 22.67%, #9ACF8F 100%)`
        );
      } else if (sliderColor >= 40 && sliderColor < 60) {
        SliderStyles?.setProperty(
          '--OuterDotColor',
          `linear-gradient(180deg, #CDCDCD 0%, #858484 100%)`
        );
        SliderStyles?.setProperty(
          '--InnerDotColor',
          `linear-gradient(180deg, #838383 0%, #9A9A9A 22.67%, #C2C2C2 100%)`
        );
      } else if (sliderColor >= 60 && sliderColor < 80) {
        SliderStyles?.setProperty(
          '--OuterDotColor',
          `linear-gradient(180deg, #FFAD58 0%, #FA8B14 100%)`
        );
        SliderStyles?.setProperty(
          '--InnerDotColor',
          `linear-gradient(180deg, #E48632 0%, #E48632 22.67%, #FEBA71 100%)`
        );
      } else if (sliderColor >= 80 && sliderColor < 100) {
        SliderStyles?.setProperty(
          '--OuterDotColor',
          `linear-gradient(180deg, #FC5957 0%, #F93432 100%)`
        );
        SliderStyles?.setProperty(
          '--InnerDotColor',
          `linear-gradient(180deg, #C60807 0%, #ED2623 22.67%, #FF7171 100%)`
        );
      }
    }
    // eslint-disable-next-line
  }, [sliderColor, selectedScaleType]);

  const onChange = (e) => {
    SetSliderColor(e);
  };

  const marks = {
    0: scaleValues.form1 || '\u00A0',
    25: scaleValues.form2 || '\u00A0',
    50: scaleValues.form3 || '\u00A0',
    75: scaleValues.form4 || '\u00A0',
    100: scaleValues.form5 || '\u00A0',
  };

  const [enteredValue, setEnterdValue] = useState();

  const handleLowEndChange = (e) => {
    const value = parseFloat(e.target.value);
    setEnterdValue(true);
    setLowEnd(value);
  };

  const handleHighEndChange = (e) => {
    const value = parseFloat(e.target.value);
    setHighEnd(value);
  };

  const validateHighEnd = (_, value) => {
    // Check if both low end and high end values are provided
    if (value && lowEnd && value < lowEnd) {
      return Promise.reject(
        new Error('High end value cannot be less than the low end value')
      );
    }
    return Promise.resolve();
  };

  const validateLowEnd = (_, value) => {
    // Check if both low end and high end values are provided
    if (value && highEnd && value > highEnd) {
      return Promise.reject(
        new Error('Low end value cannot be greater than the high end value')
      );
    }
    return Promise.resolve();
  };

  const numericMarks = (low, high) => {
    const interval = high && low ? (high - low) / 4 : 25;
    const marks = {};
    if (isNaN(low) && isNaN(high)) {
      marks[0] = 'Low End';
      marks[100] = 'High End';
    }
    if (low === undefined || isNaN(low) || !enteredValue) {
      marks[0] = 'Low End';
    } else {
      marks[low] = (selectNumericType === '$' ? '$' : '#') + ' ' + low;
    }

    if (high === undefined || high === 0 || isNaN(high)) {
      marks[100] = 'High End';
    } else {
      marks[high] = (selectNumericType === '$' ? '$' : '#') + ' ' + high;
    }

    for (let i = 1; i <= 3; i++) {
      marks[low + interval * i] = `Point ${i}`;
    }
    return marks;
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (formhasChanged) {
        event.preventDefault();
        event.returnValue =
          'You have unsaved changes. Are you sure you want to leave?';
      }
    };

    const handleMenuItemClick = (event) => {
      if (formhasChanged) {
        event.preventDefault();
        const path = event.currentTarget.getAttribute('href');
        setIsModalOpen(true);
      }
    };

    // Attach event listeners
    window.addEventListener('beforeunload', handleBeforeUnload);

    const menuItems = document.querySelectorAll('.sidebar');
    menuItems.forEach((item) => {
      item.addEventListener('click', handleMenuItemClick);
    });

    return () => {
      // Clean up event listeners
      window.removeEventListener('beforeunload', handleBeforeUnload);
      menuItems.forEach((item) => {
        item.removeEventListener('click', handleMenuItemClick);
      });
    };
  }, [formhasChanged, history]);

  const addNewScale = async (e) => {
    const isValid = !Object.values(errors).some((error) => error);
    if (isValid) {
      setLoading(true);
      try {
        let input = {
          id: id,
          organizationID: orgId,
          name: e?.title,
          description: e?.description,
          type: e?.scaleType === '-+' || e?.scaleType === '+-' ? '+-' : '#',
          subType: e?.scaleType || e?.numericType,
          breakpoints: [],
        };
        if (e?.numericType === '#' || e?.numericType === '$') {
          const forms = ['Low', 'High'];
          const values = [lowEnd, highEnd];

          forms.forEach((label, index) => {
            input.breakpoints.push({
              label: label,
              value: values[index],
            });
          });
        } else {
          const forms = [
            scaleValues?.form1,
            scaleValues?.form2,
            scaleValues?.form3,
            scaleValues?.form4,
            scaleValues?.form5,
          ];
          const values =
            e?.scaleType === '-+' ? [-2, -1, 0, 1, 2] : [2, 1, 0, -1, -2];

          forms.forEach((label, index) => {
            input.breakpoints.push({
              label: label,
              value: values[index],
            });
          });
        }
        await API.graphql({
          query: input?.id
            ? updateMeasurementFieldCustom
            : createMeasurementFieldCustom,
          variables: { input: input },
        });
        message.success(
          `Measurement field ${input?.id ? 'updated' : 'created'} successfully`
        );
        history.push('/measurementfield-list');

        setLoading(false);
        dispatch(setMFFormChange(false));
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    } else {
      message.error('Please filed required fields');
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      title: detailsById?.name,
      description: detailsById?.description,
      scaleType: selectedScaleType || detailsById?.subType,
      numericType: selectNumericType || detailsById?.subType,
      lowValue: detailsById?.breakpoints?.[0]?.value,
      highValue: detailsById?.breakpoints?.[1]?.value,
    });
    // eslint-disable-next-line
  }, [detailsById]);

  useEffect(() => {
    setScaleValues({
      form1: scaleValues.form1 || detailsById?.breakpoints?.[0]?.label,
      form2: scaleValues.form2 || detailsById?.breakpoints?.[1]?.label,
      form3: scaleValues.form3 || detailsById?.breakpoints?.[2]?.label,
      form4: scaleValues.form4 || detailsById?.breakpoints?.[3]?.label,
      form5: scaleValues.form5 || detailsById?.breakpoints?.[4]?.label,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailsById]);

  const inputRef = useRef(null);
  useEffect(() => {
    inputRef.current.focus();
  }, []);
  const tooltipStyle = {
    backgroundColor: 'black !important', // Change the background color here
    // Add other custom styles as needed
  };

  const validate = (name, value) => {
    let error = '';
    if (value.length === 0) {
      error = 'This information is required';
    }
    return error;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setScaleValues({
      ...scaleValues,
      [name]: value,
    });
    const error = validate(name, value);
    setErrors({
      ...errors,
      [name]: error,
    });
    setIsFormEdited(true);
    dispatch(setMFFormChange(true));
  };

  return (
    <div className="background-white">
      <Form
        className="scale-form"
        layout="vertical"
        form={form}
        onFinish={addNewScale}
        onValuesChange={() => {
          setIsFormEdited(true);
          dispatch(setMFFormChange(true));
        }}
        requiredMark={false}
      >
        <Row justify="space-between">
          <Col span={8}>
            <Form.Item
              name="title"
              rules={[
                {
                  required: true,
                  message: 'Measurement field name is required',
                },
              ]}
            >
              <Input
                className="title-scale fs-24"
                placeholder="Measurement field name"
                ref={inputRef}
              />
            </Form.Item>
          </Col>
          <Col span={16} style={{ textAlign: 'right' }}>
            <div>
              {id && (
                <Button
                  className="mr-10"
                  onClick={() => history.push('/measurementfield-list')}
                >
                  Cancel
                </Button>
              )}
              <Button
                htmlType="submit"
                loading={loading}
                disabled={!isFormEdited || loading}
                type="primary"
              >
                Save
              </Button>
            </div>
          </Col>
        </Row>

        <Row>
          <Col span={8}>
            <Form.Item label="Field info text" name="description">
              <TextArea
                autoSize={{
                  minRows: 3,
                  maxRows: 5,
                }}
                placeholder="Describe about measurement field"
              />
            </Form.Item>
          </Col>
        </Row>
        <Divider className="m-0" />

        <Typography.Title level={5} className="mt-20 fw-500">
          Configuration
        </Typography.Title>
        {window.location.pathname.includes('/measurementfield/numeric') ? (
          <>
            <Row>
              <Col span={8}>
                <Form.Item label="Numeric type" name="numericType">
                  <Select
                    onChange={(e) => setSelectNumericType(e)}
                    value={selectNumericType}
                  >
                    <Select.Option value="$">$ Currency</Select.Option>
                    <Select.Option value="#"># Number</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <Form.Item
                  label="Low end"
                  name="lowValue"
                  rules={[
                    {
                      pattern: /^[0-9]+$/,
                      message: 'Please enter only numeric values',
                    },
                    { validator: validateLowEnd },
                  ]}
                >
                  <Input
                    prefix={
                      selectNumericType === undefined
                        ? detailsById?.subType === '$'
                          ? '$'
                          : '#'
                        : selectNumericType === '$'
                        ? '$'
                        : '#'
                    }
                    placeholder="Enter low end"
                    value={lowEnd}
                    onChange={handleLowEndChange}
                  />
                </Form.Item>
              </Col>
              <Col span={8} className="ml-20">
                <Form.Item
                  label="High end"
                  name="highValue"
                  rules={[
                    {
                      pattern: /^[0-9]+$/,
                      message: 'Please enter only numeric values',
                    },
                    { validator: validateHighEnd },
                  ]}
                >
                  <Input
                    value={highEnd}
                    onChange={handleHighEndChange}
                    prefix={
                      selectNumericType === undefined
                        ? detailsById?.subType === '$'
                          ? '$'
                          : '#'
                        : selectNumericType === '$'
                        ? '$'
                        : '#'
                    }
                    placeholder="Enter high end"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Divider className="mb-0" />
            <Typography.Title level={5} className="mt-10">
              Preview
            </Typography.Title>
            <div className="measurement-slider mf-tooltip">
              <Slider
                tooltip={{ open: true }}
                tooltipStyle={tooltipStyle}
                className="numeric-slider mf-tooltip"
                marks={numericMarks(lowEnd, highEnd)}
                formatter={(value) => {
                  const prefix = selectNumericType === '$' ? '$' : '#';
                  return `${prefix}${value}`;
                }}
                min={lowEnd}
                max={highEnd}
              />
            </div>
          </>
        ) : (
          <>
            <Row className="mt-20">
              <Col span={8}>
                <Form.Item label="Scale type" name="scaleType">
                  <Select
                    value={selectedScaleType}
                    onChange={(e) => handleScaleTypeChange(e)}
                  >
                    <Select.Option value="-+">
                      Negative to Positive
                    </Select.Option>
                    <Select.Option value="+-">
                      Positive to Negative
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row className="label-div">
              <Col span={2} className="label-title fw-500">
                Label Text
              </Col>

              <Col span={22} className="input-col">
                <Row justify="space-between">
                  {selectedScaleType === '-+' ? (
                    <>
                      <Typography.Text className="red-text fw-500">
                        Negative
                      </Typography.Text>
                      <Typography.Text className="green-text fw-500">
                        Positive
                      </Typography.Text>
                    </>
                  ) : (
                    <>
                      <Typography.Text className="green-text fw-500">
                        Positive
                      </Typography.Text>
                      <Typography.Text className="red-text fw-500">
                        Negative
                      </Typography.Text>
                    </>
                  )}
                </Row>
                <Row gutter={[20, 20]}>
                  <Col span={5}>
                    <Input
                      name="form1"
                      onChange={(e) => handleInputChange(e)}
                      value={scaleValues.form1}
                    />
                    {errors.form1 && (
                      <span style={{ color: 'red' }}>{errors.form1}</span>
                    )}
                  </Col>
                  <Col span={5}>
                    <Input
                      name="form2"
                      onChange={(e, index) => handleInputChange(e)}
                      value={scaleValues.form2}
                    />
                    {errors.form2 && (
                      <span style={{ color: 'red' }}>{errors.form2}</span>
                    )}
                  </Col>
                  <Col span={5}>
                    <Input
                      onChange={(e) => handleInputChange(e)}
                      name="form3"
                      value={scaleValues.form3}
                    />
                    {errors.form3 && (
                      <span style={{ color: 'red' }}>{errors.form3}</span>
                    )}
                  </Col>
                  <Col span={5}>
                    <Input
                      onChange={(e) => handleInputChange(e)}
                      name="form4"
                      value={scaleValues.form4}
                    />
                    {errors.form4 && (
                      <span style={{ color: 'red' }}>{errors.form4}</span>
                    )}
                  </Col>
                  <Col span={4}>
                    <Input
                      onChange={(e) => handleInputChange(e)}
                      name="form5"
                      value={scaleValues.form5}
                    />
                    {errors.form5 && (
                      <span style={{ color: 'red' }}>{errors.form5}</span>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Divider className="mb-0" />
            <Typography.Title level={5} className="mt-10">
              Preview
            </Typography.Title>
            <div className="measurement-scale-slider">
              <Slider
                className={
                  selectedScaleType === '+-'
                    ? 'rate-slider slider-change-1'
                    : 'rate-slider slider-change'
                }
                tooltip={{ open: false }}
                onChange={onChange}
                value={sliderColor}
                marks={marks}
                disabled
              />
            </div>
          </>
        )}
      </Form>
      <LeaveMFModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </div>
  );
};

export default MeasurementScale;

