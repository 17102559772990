import { Button } from 'antd';
import React from 'react';
import { FilterOutlined } from '@ant-design/icons';
import './custom.less';

const FilterButton = ({ onClick }) => {
  return (
    <Button className="ml-10 filter-button " onClick={onClick}>
      <FilterOutlined className="filter-icon" />
    </Button>
  );
};

export default FilterButton;

