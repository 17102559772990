import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Col, Row, Skeleton, Typography } from 'antd';
import { ECHOIMAGES } from 'Assets/Images';
import {
  isSME,
  isLeader,
  isSuperAdmin,
  isAdmin,
  getCurrentOrganization,
} from 'store/slices/loginSlice';
import ReadMoreText from './ReadMoreText';
import VisionModal from './VisionModal';

const Vision = ({ orgId, getVisionDetails, loader, visionDetails }) => {
  const [visionModal, setVisionModal] = useState(false);
  const { visionLogo } = ECHOIMAGES.admin;

  const isLeaderGroup = useSelector(isLeader);
  const isSuperAdminGroup = useSelector(isSuperAdmin);
  const isSMEGroup = useSelector(isSME);
  const isAdminGroup = useSelector(isAdmin);
  const currentOrg = useSelector(getCurrentOrganization);

  const showModal = () => {
    setVisionModal(true);
  };

  useEffect(() => {
    getVisionDetails();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      {((isSMEGroup && visionDetails !== null) ||
        isAdminGroup ||
        isSuperAdminGroup ||
        (isLeaderGroup && visionDetails !== null)) && (
        <div className="vision-banner p-17">
          <Row gutter={40}>
            <Col span={16} lg={16} md={24} sm={24} xs={24}>
              {loader ? (
                <Skeleton.Button active block={true} />
              ) : (
                <Typography.Title
                  level={3}
                  className="text-black fw-bolder title-font capitalize-text"
                >
                  {visionDetails?.key}
                </Typography.Title>
              )}

              <Row>
                <Col span={24}>
                  {loader ? (
                    <Skeleton
                      active
                      paragraph={{
                        rows: 2,
                      }}
                      className="mt-10"
                    />
                  ) : visionDetails !== null ? (
                    <ReadMoreText text={visionDetails?.value} maxLength={300} />
                  ) : (
                    <Typography.Title level={3}>Add Text.</Typography.Title>
                  )}
                </Col>
              </Row>
            </Col>

            <Col span={8} lg={8} md={0} sm={0} xs={0}>
              <div className="image-wrapper">
                {(isAdminGroup || currentOrg?.id || isSuperAdminGroup) && (
                  <Button
                    className="edit-btn"
                    onClick={showModal}
                    size="middle"
                  >
                    Edit
                  </Button>
                )}
                <img src={visionLogo} alt="" className="w-100 image" />
              </div>
            </Col>
          </Row>
          <VisionModal
            setVisionModal={setVisionModal}
            visionModal={visionModal}
            orgId={orgId}
            visionDetails={visionDetails}
            getVisionDetails={getVisionDetails}
          />
        </div>
      )}
    </>
  );
};

export default memo(Vision);


