import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import Mainlayout from '../MainLayout/Mainlayout';
import { useSelector } from 'react-redux';
import { getCurrentToken } from '../store/slices/loginSlice';
import jwt from 'jwt-decode';
import { Auth } from 'aws-amplify';

export default function MainLayoutRoute(props) {
  const { render, ...rest } = props;
  const token = useSelector(getCurrentToken);
  if (token) {
    let timeStamp = jwt(token).auth_time;
    const currentUnixTimestamp = Math.floor(Date.now() / 1000);
    if (
      Math.floor((currentUnixTimestamp - timeStamp) / (60 * 60 * 24)) >=
      process.env.COGNITO_REFREST_TOKEN_VALIDITY
    ) {
      Auth.signOut({
        global: false,
      })
        .then(() => {
          localStorage.clear();
          window.location.reload();
        })
        .catch((err) => console.log(err));
    }
  }

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <>
          {!token && <Redirect from={matchProps.path} to={'/login'} />}
          {token && <Mainlayout>{render(matchProps)}</Mainlayout>}
        </>
      )}
    />
  );
}
