import React, { memo } from 'react';
import { Button, Col, Divider, Empty, Row, Typography } from 'antd';
import { HolderOutlined } from '@ant-design/icons';
import { ECHOIMAGES } from 'Assets/Images';
import { Draggable } from 'react-beautiful-dnd';
import { useHistory } from 'react-router-dom';

const CommonFormElement = ({ updatedFormElement, mfList }) => {
  const {
    multiLineIcon,
    singleLineIcon,
    dropDownIcon,
    linkIcon,
    calenderIcon,
    symbolIcon,
  } = ECHOIMAGES.admin;
  const { dollarIcon, scaleIcon, hashIcon } = ECHOIMAGES.MeasurementIcon;

  let addedDragger = updatedFormElement?.filter(
    (item) => item?.inputType === 'dragger'
  );

  let addedLinks = updatedFormElement?.filter(
    (item) => item?.inputType === 'link'
  );

  const history = useHistory();

  const formArray = [
    {
      type: 'multiLine',
      text: 'Multi-line text',
      img: multiLineIcon,
      icon: <HolderOutlined />,
    },
    {
      type: 'singleLine',
      text: 'Single-line text',
      img: singleLineIcon,
      icon: <HolderOutlined />,
    },
    {
      type: 'dropdown',
      text: 'Dropdown',
      img: dropDownIcon,
      icon: <HolderOutlined />,
    },
    {
      type: 'datepicker',
      text: 'Date',
      img: calenderIcon,
      icon: <HolderOutlined />,
    },
    {
      type: 'link',
      text: 'Link items',
      img: linkIcon,
      icon: <HolderOutlined />,
    },
    {
      type: 'dragger',
      text: 'Upload documents & links',
      img: multiLineIcon,
      icon: <HolderOutlined />,
      className: 'pointer-none',
    },
    {
      type: 'symbol',
      text: 'Symbols',
      img: symbolIcon,
      icon: <HolderOutlined />,
    },
  ];

  return (
    <>
      <Row className="common-form">
        <Typography.Title level={4} className="fs-18">
          Form Elements
        </Typography.Title>
        <Divider className="mt-0 mb-10" />
        <Typography.Text className="fs-14">
          Drag and drop an element to add to a form
        </Typography.Text>
        {formArray.map((item, index) => {
          const isDragger = item?.type === 'dragger';
          const isDraggable = addedDragger?.length === 0 || !isDragger;
          const isLinkDragger = item?.type === 'link';
          const linkDraggable = addedLinks?.length === 0 || !isLinkDragger;
          return (
            <Draggable
              draggableId={item?.type}
              index={index}
              key={item?.type}
              mode="virtual"
            >
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  className={`w-100 ${
                    (isDraggable && linkDraggable) || snapshot.isDragging
                      ? ''
                      : 'pointer-none draggable-ui'
                  }`}
                >
                  <Col span={24} className="form-attribute mt-10">
                    <div className="drag-box p">
                      <div className="drag-icon">{item?.icon}</div>
                      <img src={item?.img} alt="" />
                      <Typography.Text className="fs-16">
                        {item?.text}{' '}
                      </Typography.Text>
                    </div>
                  </Col>
                  {provided.placeholder}
                </div>
              )}
            </Draggable>
          );
        })}
      </Row>
      <Row className="common-form mt-50">
        <div className="d-flex element-title w-100">
          <Typography.Title level={4} className="fs-18 mb-0">
            Measurement Fields
          </Typography.Title>
          <Button
            className="config-text p-0"
            type="text"
            onClick={() => {
              history.push('/measurementfield-list');
            }}
          >
            Configure
          </Button>
        </div>
        <Divider className="mt-0 mb-10" />

        {mfList?.filter(
          (e) => !updatedFormElement?.some((obj) => obj.id === e?.id)
        )?.length > 0 ? (
          <>
            <Typography.Text className="fs-14">
              Drag and drop an element to add to a form
            </Typography.Text>
            {mfList
              ?.filter(
                (e) => !updatedFormElement?.some((obj) => obj.id === e?.id)
              )
              ?.map((item, index) => {
                const uniqueId = `${item.type}_${item.id}_${index}`;
                const notIncrementalMf = item?.isUsed;
                return (
                  <Draggable
                    draggableId={uniqueId}
                    index={index}
                    key={item?.id}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={`w-100 ${
                          notIncrementalMf
                            ? 'pointer-none'
                            : snapshot.isDragging
                            ? ''
                            : 'draggable-ui'
                        }`}
                      >
                        <Col span={24} className="form-attribute mt-10">
                          <div
                            className={
                              notIncrementalMf
                                ? 'mf-disabled-box mf-box'
                                : 'mf-box'
                            }
                          >
                            <div className="drag-icon">
                              {' '}
                              <HolderOutlined />
                            </div>
                            {item?.subType === '+-' ||
                            item?.subType === '-+' ? (
                              <img src={scaleIcon} alt="" className="h-25" />
                            ) : item?.subType === '$' ? (
                              <img src={dollarIcon} alt="" className="h-25" />
                            ) : item?.subType === '#' ? (
                              <img src={hashIcon} alt="" className="h-25" />
                            ) : null}
                            <Typography.Text
                              className="fs-16"
                              style={
                                true
                                  ? {
                                      width: 200,
                                    }
                                  : undefined
                              }
                              ellipsis={
                                true
                                  ? {
                                      tooltip: item?.name,
                                    }
                                  : false
                              }
                            >
                              {item?.name}{' '}
                            </Typography.Text>
                          </div>
                        </Col>
                        {provided.placeholder}
                      </div>
                    )}
                  </Draggable>
                );
              })}
          </>
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            className="m-auto mt-20"
          />
        )}
      </Row>
    </>
  );
};

export default memo(CommonFormElement);

