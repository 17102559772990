import {
  Col,
  Row,
  Typography,
  Form,
  Input,
  Button,
  message,
  Carousel,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import '../common.less';
import { ECHOIMAGES } from '../../Assets/Images';
import { API, Auth } from 'aws-amplify';
import Step2 from '../Signup/Step2';
import { useDispatch } from 'react-redux';
import {
  signIn,
  currentUser,
  setOrgarnizationData,
  setFormLayout,
  setHomeTour,
  setProfilePic,
  setIsPartner,
} from '../../store/slices/loginSlice';
import { FetchUser } from '../../Pages/Idea/IdeaFunction';
import { getUsers } from 'utils/Actions';
import Login_Using_SSO from 'authUser/Login_Using_SSO';
import moment from 'moment';
import { GetFileFromS3 } from 'utils/commonMethods';
import { RESTSignup } from 'utils/RESTApi';

export default function Login() {
  const {
    LoginImg,
    Background,
    signupSlide1,
    signupSlide2,
    signupSlide3,
    AuthLogo,
  } = ECHOIMAGES.Auth;
  const [loginData, setLoginData] = useState({
    userName: '',
    password: '',
  });
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const history = useHistory();
  const [authenticatedUser, setAuthenticatedUser] = useState('');
  const [organizationEmail, setOrganizationEmail] = useState('');
  const [organizationID, setOrganizationID] = useState('');
  const [signupLoading, setSignupLoading] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [currentUserData, setCurrentUserData] = useState();
  const dispatch = useDispatch();

  const getSignedUrlImg = async (fileKey) => {
    const signedURL = await GetFileFromS3(fileKey, {
      level: 'public',
    });
    return signedURL;
  };

  const handleLogin = async () => {
    const formValue = form.getFieldsValue();
    let emailData = formValue?.userName?.toLowerCase();
    let trimedEmail = emailData.trim();
    setLoading(true);
    await Auth.signIn(trimedEmail, formValue.password)
      .then(async (res) => {
        if (res.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setNewPassword(res.challengeName);
          setCurrentUserData(res);
          setLoading(false);
        } else {
          const res = await Auth.currentAuthenticatedUser();
          const organisationData = await getUsers({ id: res?.username });
          setOrgarnizationData(organisationData);
          setOrganizationEmail(organisationData?.name);
          setOrganizationID(organisationData?.id);
          if (res?.attributes?.given_name) {
            localStorage.setItem('given_name', res?.attributes?.given_name);
            const signInUserData = {
              token: res?.signInUserSession?.accessToken?.jwtToken,
              groups: organisationData?.role,
              userData: res?.attributes,
            };

            dispatch(signIn(signInUserData));
            const now = new Date().toISOString();
            const date = { le: moment(now).format('YYYY-MM-DD') };
            const loginUserData = await FetchUser({
              id: res?.attributes?.sub,
              date: date,
            });
            let firstTimeUser = loginUserData?.LoginHistory?.items;
            if (
              firstTimeUser?.length <= 1 &&
              firstTimeUser?.[0]?.createdAt === firstTimeUser?.[0]?.updatedAt
            ) {
              dispatch(setHomeTour(true));
            }
            if (loginUserData?.photo !== null) {
              let imageURL = await getSignedUrlImg(loginUserData?.photo);
              loginUserData.imageURL = imageURL;
              dispatch(setProfilePic(loginUserData?.imageURL));
            }
            dispatch(currentUser(loginUserData));
            dispatch(setFormLayout(loginUserData?.Organization?.stages));
            dispatch(
              setIsPartner(
                loginUserData?.Organization?.type.includes('PARTNER')
              )
            );
          } else {
            setAuthenticatedUser(!res?.attributes?.given_name);
          }
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.error(err.message);
        if (err.message === 'User is not confirmed.') {
          history.push({
            pathname: '/confirm_sign_up',
            state: { feedback: '', userName: formValue.userName },
          });
        }
        setLoginData({ ...loginData, userName: '', password: '' });
      });
  };

  //for reset password
  const submitNewPassword = () => {
    let formValue = form.getFieldsValue();
    setLoading(true);
    if (formValue.newPassword === formValue.confirmPassword) {
      Auth.completeNewPassword(currentUserData, formValue.confirmPassword, [])
        .then(async (user) => {
          setLoading(false);
          message.success('Login with new password.');
          history.push('/');
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else {
      message.warning(
        'Please enter the same new password and confirm password '
      );
      setLoading(false);
    }
  };

  // for auth update and create organization
  const updateAuth = async (e) => {
    setSignupLoading(true);
    try {
      const updatedData = {
        given_name: e.firstname,
        family_name: e.lastname,
      };
      const user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, updatedData);
      localStorage.setItem('given_name', updatedData.given_name);
      if (!organizationEmail) {
        const apiName = 'StrAlignRest';
        const path = '/signup';
        const body = {
          organization_name: e.organisationname,
          email: user?.attributes?.email,
          first_name: e.firstname,
          last_name: e.lastname,
        };
        await RESTSignup(body);
        // await API.post(apiName, path, { body: body });
        if (localStorage.getItem('SSO') === 'true') {
          await Auth.signIn(user?.attributes?.email)
            .then(async (signinres) => {
              await Auth.sendCustomChallengeAnswer(
                signinres,
                user?.attributes?.['custom:msalIdToken']
              );
              const authUser = await Auth.currentAuthenticatedUser();
              if (authUser?.attributes?.given_name) {
                localStorage.setItem(
                  'given_name',
                  authUser?.attributes?.given_name
                );
                const signInUserData = {
                  token: authUser?.signInUserSession?.accessToken?.jwtToken,
                  groups:
                    authUser?.signInUserSession?.accessToken?.payload[
                      'cognito:groups'
                    ],
                  userData: authUser?.attributes,
                };
                dispatch(signIn(signInUserData));
                const loginUserData = await FetchUser({
                  id: authUser?.attributes?.sub,
                });
                dispatch(currentUser(loginUserData));
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          history.push('/login');
        }

        message.success('Organization created successfully');
        history.push('/');
        setSignupLoading(false);
      } else {
        const apiName = 'StrAlignRest';
        const path = '/signup';
        const body = {
          organization_id: organizationID,
          organization_name: e.organisationname,
          email: user?.attributes?.email,
          first_name: e.firstname,
          last_name: e.lastname,
        };
        await RESTSignup(body);
        // await API.post(apiName, path, { body: body });
        await Auth.signIn(user?.attributes?.email)
          .then(async () => {
            const authUser = await Auth.currentAuthenticatedUser();
            if (authUser?.attributes?.given_name) {
              localStorage.setItem(
                'given_name',
                authUser?.attributes?.given_name
              );
              const signInUserData = {
                token: authUser?.signInUserSession?.accessToken?.jwtToken,
                groups:
                  authUser?.signInUserSession?.accessToken?.payload[
                    'cognito:groups'
                  ],
                userData: authUser?.attributes,
              };
              dispatch(signIn(signInUserData));
              const loginUserData = await FetchUser({
                id: authUser?.attributes?.sub,
              });
              dispatch(currentUser(loginUserData));
            }
          })
          .catch((error) => {
            console.log(error);
          });
        message.success('Organization created successfully');
        history.push('/');
        setSignupLoading(false);
      }
    } catch (error) {
      setSignupLoading(false);
      message.error(error.message);
    }
  };

  return (
    <>
      <Row className="signup login">
        {!authenticatedUser && (
          <Col span={12} lg={12} md={0} xs={0} sm={0}>
            <div className="login-left">
              <div className="vertical-middle">
                <Typography.Title level={2}>
                  Reconcile competing priorities and
                  <br /> help teams focus on the highest
                  <br /> impact projects.
                </Typography.Title>
                <img src={LoginImg} alt="" />
              </div>
              <img src={Background} alt="" className="background-img" />
            </div>
          </Col>
        )}
        {authenticatedUser && (
          <Col span={12} lg={12} md={0} xs={0} sm={0}>
            <Carousel autoplay>
              <div className="login-left">
                <div className="vertical-middle">
                  <Typography.Title level={1}>COMPARE IDEAS</Typography.Title>
                  <Typography.Title level={4}>
                    Share your insight on which projects will have the most
                    positive impact.
                  </Typography.Title>
                  <img src={signupSlide1} alt="" />
                </div>
                <img src={Background} alt="" className="background-img" />
              </div>
              <div className="login-left">
                <div className="vertical-middle">
                  <Typography.Title level={1}>
                    PRIORITIZE CONFIDENTLY
                  </Typography.Title>
                  <Typography.Title level={4}>
                    Align projects to the strategic goals for better results.
                    Bridge the gap between strategy and execution.
                  </Typography.Title>
                  <img src={signupSlide2} alt="" />
                </div>
                <img src={Background} alt="" className="background-img" />
              </div>
              <div className="login-left">
                <div className="vertical-middle">
                  <Typography.Title level={1}>
                    STAY FOCUSED ON TOP PRIORITIES
                  </Typography.Title>
                  <Typography.Title level={4}>
                    Things Change! Know your goals. Unlock your potential.
                  </Typography.Title>
                  <img src={signupSlide3} alt="" />
                </div>
                <img src={Background} alt="" className="background-img" />
              </div>
            </Carousel>
          </Col>
        )}
        {!authenticatedUser && (
          <Col span={12} lg={12} md={24} xs={24} sm={24} className="login-form">
            <div className="wrapper">
              <img src={AuthLogo} alt="" />

              <Typography.Title level={3}>Login</Typography.Title>
              <Typography.Title level={5}>
                Welcome! Please enter your details.
              </Typography.Title>

              <Form
                layout="vertical"
                requiredMark={false}
                form={form}
                onFinish={() =>
                  newPassword === 'NEW_PASSWORD_REQUIRED'
                    ? submitNewPassword()
                    : handleLogin()
                }
              >
                <Form.Item
                  label="Email"
                  name="userName"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your email',
                    },
                  ]}
                >
                  <Input
                    className="input"
                    disabled={
                      newPassword === 'NEW_PASSWORD_REQUIRED' ? true : false
                    }
                  />
                </Form.Item>
                {newPassword === 'NEW_PASSWORD_REQUIRED' ? (
                  <>
                    <Form.Item
                      label="New Password"
                      name="newPassword"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your password',
                        },
                      ]}
                    >
                      <Input.Password className="input" />
                    </Form.Item>
                    <Form.Item
                      className="mb-0 "
                      label="Confirm Password"
                      name="confirmPassword"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your password',
                        },
                      ]}
                    >
                      <Input.Password className="input" />
                    </Form.Item>
                  </>
                ) : (
                  <>
                    <Form.Item
                      className="mb-0 "
                      label="Password"
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your password',
                        },
                      ]}
                    >
                      <Input.Password className="input" />
                    </Form.Item>
                    <Form.Item className="forgotpassword-link">
                      <Link to="/forgot-password" className="text-blue fw-500">
                        Forgot Password?
                      </Link>
                    </Form.Item>
                  </>
                )}

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="blue-filled-btn w-100 mt-10 h-40"
                    loading={loading}
                  >
                    Login
                  </Button>
                </Form.Item>

                <Typography.Title level={5} className="signup-text">
                  Don’t have an account?{' '}
                  <Link to="/signup" className="text-blue fw-700">
                    Sign Up
                  </Link>
                </Typography.Title>
              </Form>
            </div>
            <Typography.Title level={5} className="footer">
              © {new Date().getFullYear()} Echo Consulting, LLC
            </Typography.Title>
            <Login_Using_SSO setAuthenticatedUser={setAuthenticatedUser} />
          </Col>
        )}
        {authenticatedUser && (
          <Col span={12} md={12} xs={24} sm={24} className="login-form">
            <div className="wrapper">
              <img src={AuthLogo} alt="" />

              <Step2
                flow={'login'}
                updateAuth={updateAuth}
                organizationEmail={organizationEmail}
                setAuthenticatedUser={setAuthenticatedUser}
                authenticatedUser={authenticatedUser}
                signupLoading={signupLoading}
              />
            </div>
            <Typography.Title level={5} className="footer">
              © {new Date().getFullYear()} Echo Consulting, LLC
            </Typography.Title>
          </Col>
        )}
      </Row>
    </>
  );
}
