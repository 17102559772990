import {
  Col,
  Row,
  Typography,
  Form,
  Input,
  Select,
  Button,
  message,
} from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getCurrentOrganization,
  getCurrnetUserData,
  isAdmin,
} from 'store/slices/loginSlice';
import { FetchUser } from 'Pages/Idea/IdeaFunction';
import {
  getUsersByOrganizationIDCustom,
  updateRole,
} from 'Pages/SuperAdmin/SuperAdminFun';
import { UserRole, userStatus } from 'utils/Constants';
import { getUserWeightSetting } from 'Pages/Settings/SettingFunction';
import { useHistory } from 'react-router-dom';
import { fetchOrgDepartments, fetchRoles } from 'utils/CommonFunctions';
import { RESTUpdateRole } from 'utils/RESTApi';
import { Auth } from 'aws-amplify';

export default function EditProfile() {
  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;
  const userId = currnetOrg?.adminID || loginUsrOrg?.id;
  const isAdminGroup = useSelector(isAdmin);
  const { Option } = Select;
  const [form] = Form.useForm();
  const [listAllDepartments, setListAllDepartments] = useState([]);
  const [managerValue, setManagerValue] = useState([]);
  const [selectdDepartment, setSelectedDepartment] = useState('');
  const [selectedManager, setSelectedManager] = useState([]);
  const [allRoles, setAllRoles] = useState([]);
  const [selectdRole, setSelectedRole] = useState('');
  const [fetchWeight, setFetchWeight] = useState([]);
  const [saveLoading, setSaveLoading] = useState(false);
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [oldRoleName, setOldRoleName] = useState();
  const history = useHistory();

  const getUserDetails = async () => {
    try {
      const userDetails = await FetchUser({ id: userId });
      form.setFieldsValue({
        FirstName: userDetails?.firstName,
        LastName: userDetails?.lastName,
        Email: userDetails?.email,
        UserWeight: userDetails?.weight,
        role: userDetails?.role,
        department: userDetails?.Department?.id,
        manager: userDetails?.Manager?.id,
      });
      setOldRoleName(userDetails?.role);
    } catch (err) {
      console.log(err);
    }
  };

  const listOrgDepartments = async () => {
    const departments = await fetchOrgDepartments(orgId);
    setListAllDepartments(departments);
  };

  const getManagers = async () => {
    try {
      let data = {
        status: { eq: userStatus.active },
        or: [
          { role: { eq: UserRole.admin } },
          { role: { eq: UserRole.leader } },
          { role: { eq: UserRole.sme } },
        ],
      };
      let id = { organizationID: orgId };
      let res = await getUsersByOrganizationIDCustom(data, id);
      setManagerValue(res?.items);
    } catch (err) {
      console.log(err);
    }
  };

  const handleGetRoles = async () => {
    const rolesArray = await fetchRoles(isAdminGroup, currnetOrg);
    setAllRoles(rolesArray);
  };

  const getUsersWeight = async () => {
    try {
      let res = await getUserWeightSetting({ id: orgId });
      setFetchWeight(res?.data?.getOrganization);
    } catch (err) {
      console.log(err);
    }
  };
  const handleRole = async (e) => {
    let weight;
    if (e === UserRole.superAdmin || e === UserRole.leader) {
      weight = fetchWeight?.weightSettings?.filter(
        (el) => el.role === UserRole.leader
      )[0]?.weight;
    } else {
      weight = fetchWeight?.weightSettings?.filter((el) => el.role === e)[0]
        ?.weight;
    }
    form.setFieldsValue({
      UserWeight: weight,
    });
    setSelectedRole(e);
  };

  const editUserProfile = async (e) => {
    setSaveLoading(true);
    try {
      let apiData = {};
      let events = [];
      let inputData = {
        id: userId,
        firstName: e.FirstName,
        lastName: e.LastName,
        email: e.Email,
        role: e.role,
        departmentID: e.department,
        managerID: e.manager,
        weight: e.UserWeight,
      };
      if (firstName || lastName) {
        events.push('name-change');
        apiData['user_id'] = userId;
        apiData['first_name'] = e.FirstName;
        apiData['last_name'] = e.LastName;
      }
      if (selectdRole) {
        events.push('role-change');
        apiData['user_id'] = userId;
        apiData['old_role'] = oldRoleName;
        apiData['new_role'] = selectdRole;
      }
      if (firstName || lastName || selectdRole) {
        apiData['events'] = events;
        await RESTUpdateRole(apiData);
      }

      await updateRole(inputData);
      const res = await Auth.currentUserInfo();
      localStorage.setItem('given_name', res?.attributes?.given_name);
      setSaveLoading(false);
      message.success('User Details updated successfully');
      history.push('/profile');
    } catch (err) {
      setSaveLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    getUserDetails();
    listOrgDepartments();
    getManagers();
    handleGetRoles();
    getUsersWeight();
    // eslint-disable-next-line
  }, []);
  return (
    <div className="add-idea plr-0 pt-0">
      <Typography.Title level={5}>
        <ArrowLeftOutlined
          className="mr-10"
          onClick={() => window.history.back()}
        />{' '}
        Back to profile
      </Typography.Title>

      <Row gutter={[20, 20]} className="idea-create">
        <Col span={24} md={24} xs={24} sm={24}>
          <div className="background-white">
            <Form
              layout="vertical"
              requiredMark={false}
              form={form}
              onFinish={editUserProfile}
              className="opportunity-detail-edit"
            >
              <Row gutter={20}>
                <Col span={12} md={12} xs={24} sm={24}>
                  <Form.Item
                    label="First Name"
                    name="FirstName"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter first name',
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter First Name"
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </Form.Item>
                </Col>
                <Col span={12} md={12} xs={24} sm={24}>
                  <Form.Item
                    label="Last Name"
                    name="LastName"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter last name',
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter Last Name"
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </Form.Item>
                </Col>
                <Col span={12} md={12} xs={24} sm={24}>
                  <Form.Item
                    label="Email Id"
                    name="Email"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter email',
                      },
                    ]}
                  >
                    <Input placeholder="Enter Email Id" disabled />
                  </Form.Item>
                </Col>
                <Col span={12} md={12} xs={24} sm={24}>
                  <Form.Item
                    label="Role"
                    name="role"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter role',
                      },
                    ]}
                  >
                    <Select
                      value={selectdRole}
                      onChange={(e) => handleRole(e)}
                      allowClear
                    >
                      {allRoles?.map((item, index) => {
                        const roleName =
                          item.name === UserRole.sme
                            ? UserRole?.teamMember
                            : item.name;
                        return (
                          <Select.Option key={index} value={item?.name}>
                            {roleName}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12} md={12} xs={24} sm={24}>
                  <Form.Item label="Department" name="department">
                    <Select
                      placeholder="Select Department"
                      value={selectdDepartment}
                      onChange={(e) => setSelectedDepartment(e)}
                      allowClear
                    >
                      {listAllDepartments?.map((item, index) => {
                        return (
                          <Select.Option key={index} value={item?.id}>
                            {item?.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12} md={12} xs={24} sm={24}>
                  <Form.Item label="Manager" name="manager">
                    <Select
                      placeholder="Select Manager"
                      value={selectedManager}
                      onChange={(e) => setSelectedManager(e)}
                      allowClear
                    >
                      {managerValue?.map((e, index) => {
                        return (
                          <Option value={e.id} key={index}>
                            {e.firstName + ' ' + e.lastName}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12} md={12} xs={24} sm={24}>
                  <Form.Item
                    label="User Weight"
                    name="UserWeight"
                    className="mb-0"
                    rules={[
                      {
                        pattern: /^[0-9]+$/,
                        message: 'Please enter a valid number!',
                      },
                    ]}
                  >
                    <Input placeholder="Enter User Weight" />
                  </Form.Item>
                </Col>
                <Col span={24} md={24} xs={24} sm={24}>
                  <Form.Item className="mb-0">
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="blue-filled-btn mt-20"
                      size="middle"
                      loading={saveLoading}
                    >
                      Save
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
}

