import {
  Checkbox,
  message,
  Row,
  Select,
  Typography,
  Input,
  Button,
  Col,
} from 'antd';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { FetchUser } from 'Pages/AllPriorities/IdeaFunction';
import { getUserWeightSetting } from 'Pages/Settings/SettingFunction';
import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getCurrentOrganization,
  getCurrnetUserData,
  isAdmin,
} from 'store/slices/loginSlice';
import { fetchRoles } from 'utils/CommonFunctions';
import { capitalizeFirstLetter, UserRole, userStatus } from 'utils/Constants';
import { v4 as uuidv4 } from 'uuid';

const CreateFilter = ({
  setAppliedFilters,
  listAllDepartments,
  savedFilters,
  appliedFilters,
  setAddFilter,
  whoList,
  setWhoList,
  whoOptionsObj,
  setSelectedValue,
  selectedValue,
}) => {
  const includeExclude = [
    {
      key: 'include',
      value: 'to include',
    },
    {
      key: 'exclude',
      value: 'to exclude',
    },
  ];

  const isAdminGroup = useSelector(isAdmin);

  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;

  const [userData, setUserData] = useState([]);
  const [allRoles, setAllRoles] = useState([]);

  const handleGetRoles = async () => {
    const rolesArray = await fetchRoles(isAdminGroup, currnetOrg);
    setAllRoles(rolesArray);
  };

  const getAllUsers = async () => {
    try {
      let data = {
        status: { eq: userStatus.active },
        or: [
          { role: { eq: UserRole.admin } },
          { role: { eq: UserRole.leader } },
          { role: { eq: UserRole.superAdmin } },
          { role: { eq: UserRole.sme } },
        ],
      };
      let id = { organizationID: orgId };
      let nextToken = null;
      let allUserData = [];
      while (true) {
        let res = await FetchUser(data, id, nextToken);
        allUserData.push(...res.items);
        if (!res?.nextToken) {
          break;
        }
        nextToken = res?.nextToken;
      }
      await processUserData(allUserData);
    } catch (err) {
      console.log(err);
    }
  };
  const processUserData = async (userData) => {
    setUserData(userData);
  };

  const [roleWeight, setRoleWeight] = useState();

  function validateWhoOptions(whoOptionsObj) {
    // Check if departmentToInclude is either null or a valid value
    if (
      whoOptionsObj.departmentToInclude !== null &&
      typeof whoOptionsObj.departmentToInclude !== 'string'
    ) {
      return false;
    }

    // Check if departmentValue is an array
    if (!Array.isArray(whoOptionsObj.departmentValue)) {
      return false;
    }

    // Check if userRoleToInclude is either null or a valid value
    if (
      whoOptionsObj.userRoleToInclude !== null &&
      typeof whoOptionsObj.userRoleToInclude !== 'string'
    ) {
      return false;
    }

    // Check if userRoleValue is an array
    if (!Array.isArray(whoOptionsObj.userRoleValue)) {
      return false;
    }

    // Check if customUserRoleWeightCheckbox is a boolean
    if (typeof whoOptionsObj.customUserRoleWeightCheckbox !== 'boolean') {
      return false;
    }

    // Check if userRoleandWeight is an object
    if (typeof whoOptionsObj.userRoleandWeight !== 'object') {
      return false;
    }

    // Check if usersToInclude is either null or a valid value
    if (
      whoOptionsObj.usersToInclude !== null &&
      typeof whoOptionsObj.usersToInclude !== 'string'
    ) {
      return false;
    }

    // Check if usersValue is an array
    if (!Array.isArray(whoOptionsObj.usersValue)) {
      return false;
    }

    // Check if customUsersWeightCheckbox is a boolean
    if (typeof whoOptionsObj.customUsersWeightCheckbox !== 'boolean') {
      return false;
    }

    // Check if usersandWeight is an object
    if (typeof whoOptionsObj.usersandWeight !== 'object') {
      return false;
    }

    // All validations passed, return true
    return true;
  }

  const listUserWeight = async () => {
    try {
      const res = await getUserWeightSetting({ id: orgId });
      let weights = res?.data?.getOrganization?.weightSettings;
      setRoleWeight(weights);
    } catch (error) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    handleGetRoles();
    getAllUsers();
    listUserWeight();
    // eslint-disable-next-line
  }, []);

  const handleAddField = () => {
    setWhoList([...whoList, whoOptionsObj]);
  };

  const [crossIconVisible, setCrossIconVisible] = useState(
    Array(whoList.length).fill(false)
  );
  const handleDataEntry = (index) => {
    if (whoList[index].departmentToInclude !== '') {
      const newCrossIconVisible = [...crossIconVisible];
      newCrossIconVisible[index] = true;
      setCrossIconVisible(newCrossIconVisible);
    }
  };

  const handleWhoList = async (value, index, field, key, subField) => {
    let tempWhoList = [...whoList];
    if (field === 'departmentValue') {
      tempWhoList[index][field] = value;
      let userFilterValuse = userData
        ?.filter((i) => value.includes(i.departmentID))
        .map((i) => i.id);
      tempWhoList[index].usersValue = tempWhoList[index].usersValue.filter(
        (i) => userFilterValuse.includes(i)
      );
    } else if (field === 'userRoleValue') {
      tempWhoList[index][field] = value;
      let userFilterValuse = userData
        ?.filter((i) => value.includes(i.role))
        .map((i) => i.id);
      tempWhoList[index].usersValue = tempWhoList[index].usersValue.filter(
        (i) => userFilterValuse.includes(i)
      );
    } else {
      if (key !== undefined) {
        if (tempWhoList[index][field][subField]) {
          tempWhoList[index][field][subField] = value;
        } else {
          let subFieldObj = { ...tempWhoList[index][field], [subField]: 0 };
          subFieldObj[subField] = value;
          tempWhoList[index][field] = subFieldObj;
        }
      } else {
        tempWhoList[index][field] = value;
        handleDataEntry(index);
      }
    }
    setWhoList(tempWhoList);
  };

  const handleRemoveField = (indexToRemove) => {
    setWhoList(whoList.filter((item, index) => index !== indexToRemove));
  };

  const handleClearDepartmentValue = (index) => {
    let tempWhoList = [...whoList];
    tempWhoList[index].departmentToInclude = null;
    tempWhoList[index].departmentValue = [];
    setWhoList(tempWhoList);
  };

  const handleClearUserRoleValue = (index) => {
    let tempWhoList = [...whoList];
    tempWhoList[index].userRoleToInclude = null;
    tempWhoList[index].userRoleValue = [];
    tempWhoList[index].customUserRoleWeightCheckbox = false;
    setWhoList(tempWhoList);
  };

  const handleClearUsersValue = (index) => {
    let tempWhoList = [...whoList];
    tempWhoList[index].usersToInclude = null;
    tempWhoList[index].usersValue = [];
    tempWhoList[index].customUsersWeightCheckbox = false;
    setWhoList(tempWhoList);
  };

  const handleUserData = (item) => {
    let temp = [];
    let deptInclude = item.departmentToInclude === 'to include' ? true : false;
    let userRoleIncluse =
      item.userRoleToInclude === 'to include' ? true : false;

    const checkDeptIncludeExclude = (deptId) => {
      if (deptInclude) {
        return item.departmentValue.includes(deptId);
      } else {
        return !item.departmentValue.includes(deptId);
      }
    };

    const checkUserRoleIncludeExclude = (ur) => {
      if (userRoleIncluse) {
        return item.userRoleValue.includes(ur);
      } else {
        return !item.userRoleValue.includes(ur);
      }
    };

    if (item.departmentValue.length && item.userRoleValue.length) {
      temp = userData.filter(
        (i) =>
          checkDeptIncludeExclude(i.departmentID) &&
          checkUserRoleIncludeExclude(i.role)
      );
    } else if (item.departmentValue.length) {
      temp = userData.filter((i) => checkDeptIncludeExclude(i.departmentID));
    } else if (item.userRoleValue.length) {
      temp = userData.filter((i) => checkUserRoleIncludeExclude(i.role));
    } else {
      temp = userData;
    }

    return temp?.map((el, index) => {
      return (
        <Option key={index} value={el?.id}>
          {el?.firstName + ' ' + el?.lastName}
        </Option>
      );
    });
  };

  return (
    <>
      <div className="scenario-filter selected-border mt-0 filter-scroll">
        <div className="mt-10">
          <div>
            <Typography.Text className="gray-text">
              Use votes that match
            </Typography.Text>

            <Select
              value={selectedValue}
              variant="borderless"
              className="condition-modal-selector w-auto "
              onChange={(e) => {
                setSelectedValue(e);
              }}
              style={{
                flex: 1,
                width: '150px',
              }}
              options={[
                {
                  value: 'ALL',
                  label: 'All conditions',
                },
                {
                  value: 'ONE',
                  label: 'Any conditions',
                },
              ]}
            />
          </div>
        </div>
        {whoList.map((item, index) => {
          return (
            <Row justify="space-between" align="middle">
              <Col span={23} className="idea-filter mb-10">
                <Row align="middle" className="gap-10">
                  <Col span={3}>
                    <Typography.Text level={4} className="fw-500 ">
                      Department :
                    </Typography.Text>
                  </Col>
                  <Col span={4}>
                    <Select
                      className="mt-10 user-dropdown fw-400 w-100"
                      onChange={(e) =>
                        handleWhoList(e, index, 'departmentToInclude')
                      }
                      allowClear
                      placeholder="Select Operator"
                      value={item?.departmentToInclude}
                    >
                      {includeExclude.map((item, index) => {
                        return (
                          <Option
                            key={index}
                            value={item?.value}
                            className="fw-400"
                          >
                            {item?.value}
                          </Option>
                        );
                      })}
                    </Select>
                  </Col>
                  <Col span={7}>
                    <Select
                      placeholder="Select one or more department"
                      mode="multiple"
                      className="mt-10 w-100 dropdown-style"
                      value={item.departmentValue}
                      onChange={(e) =>
                        handleWhoList(e, index, 'departmentValue')
                      }
                      disabled={!item.departmentToInclude}
                      maxTagCount="responsive"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0 ||
                        option.props.value
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {listAllDepartments.map((item, index) => {
                        return (
                          <Option key={index} value={item?.id}>
                            {item?.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Col>
                  <Col span={8}></Col>
                  {item?.departmentToInclude && (
                    <Col span={1} className="text-right">
                      <Button
                        type="text"
                        className="clear-btn"
                        onClick={() => handleClearDepartmentValue(index)}
                      >
                        Clear
                      </Button>
                    </Col>
                  )}
                </Row>

                <Row align="middle" className="gap-10">
                  <Col span={3}>
                    <Typography.Text level={4} className="fw-500">
                      User role :
                    </Typography.Text>
                  </Col>
                  <Col span={4}>
                    <Select
                      className="mt-10 user-dropdown w-100"
                      onChange={(e) =>
                        handleWhoList(e, index, 'userRoleToInclude')
                      }
                      value={item?.userRoleToInclude}
                      placeholder="Select Operator"
                    >
                      {includeExclude.map((item, index) => {
                        return (
                          <Option key={index} value={item?.value}>
                            {item?.value}
                          </Option>
                        );
                      })}
                    </Select>
                  </Col>
                  <Col span={7}>
                    <Select
                      placeholder="Select one or more user role"
                      mode="multiple"
                      className="mt-10 w-100 dropdown-style"
                      onChange={(e) => handleWhoList(e, index, 'userRoleValue')}
                      disabled={!item.userRoleToInclude}
                      value={item?.userRoleValue}
                      maxTagCount="responsive"
                    >
                      {allRoles.map((item, index) => {
                        const roleName =
                          item?.name === UserRole.sme
                            ? UserRole?.teamMember
                            : item?.name;
                        return (
                          <Select.Option key={index} value={item?.name}>
                            {capitalizeFirstLetter(roleName)}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Col>
                  <Col span={8}>
                    <Checkbox
                      checked={item.customUserRoleWeightCheckbox}
                      disabled={
                        item.userRoleValue?.length === 0 ||
                        item?.userRoleToInclude === 'to exclude'
                      }
                      onChange={(e) =>
                        handleWhoList(
                          e.target.checked,
                          index,
                          'customUserRoleWeightCheckbox'
                        )
                      }
                    >
                      Custom user role weight(W){' '}
                    </Checkbox>
                  </Col>
                  {item?.userRoleToInclude && (
                    <Col span={1}>
                      <Button
                        type="text"
                        onClick={() => handleClearUserRoleValue(index)}
                        className="clear-btn"
                      >
                        Clear
                      </Button>
                    </Col>
                  )}
                </Row>

                <Row gutter={10}>
                  <Col span={3}></Col>
                  <Col span={21}>
                    {item.customUserRoleWeightCheckbox &&
                      item.userRoleValue?.map((el, key) => {
                        const matchedRole = roleWeight?.find(
                          (role) => role?.role === el
                        );
                        if (matchedRole) {
                          return (
                            <Row
                              key={key}
                              align="middle"
                              className="mt-5 ml-10"
                            >
                              <span>
                                {matchedRole.role === UserRole.sme
                                  ? capitalizeFirstLetter(UserRole?.teamMember)
                                  : capitalizeFirstLetter(
                                      matchedRole.role
                                    )}{' '}
                                Weight:{' '}
                              </span>

                              <Input
                                className="weightBox"
                                onChange={(e) =>
                                  handleWhoList(
                                    e.target.value,
                                    index,
                                    'userRoleandWeight',
                                    key,
                                    el
                                  )
                                }
                                value={item?.userRoleandWeight[el]}
                              />
                            </Row>
                          );
                        }
                        return null;
                      })}
                  </Col>
                </Row>
                <Row align="middle" className="gap-10">
                  <Col span={3}>
                    <Typography.Text level={4} className="fw-500">
                      User:
                    </Typography.Text>
                  </Col>
                  <Col span={4}>
                    <Select
                      style={{
                        width: '100%',
                      }}
                      className="mt-10 user-dropdown w-100"
                      onChange={(e) =>
                        handleWhoList(e, index, 'usersToInclude')
                      }
                      placeholder="Select Operator"
                      value={item?.usersToInclude}
                    >
                      {includeExclude.map((item, index) => {
                        return (
                          <Option key={index} value={item?.value}>
                            {item?.value}
                          </Option>
                        );
                      })}
                    </Select>
                  </Col>
                  <Col span={7}>
                    <Select
                      placeholder="Select one or more user"
                      style={{
                        width: '100%',
                      }}
                      mode="multiple"
                      className="mt-10 w-100 dropdown-style"
                      onChange={(e) => handleWhoList(e, index, 'usersValue')}
                      disabled={!item.usersToInclude}
                      value={item?.usersValue}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0 ||
                        option.props.value
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      maxTagCount="responsive"
                    >
                      {handleUserData(item)}
                    </Select>
                  </Col>
                  <Col span={8}>
                    <Checkbox
                      checked={item.customUsersWeightCheckbox}
                      disabled={
                        item.usersValue?.length === 0 ||
                        item?.usersToInclude === 'to exclude'
                      }
                      onChange={(e) =>
                        handleWhoList(
                          e.target.checked,
                          index,
                          'customUsersWeightCheckbox'
                        )
                      }
                    >
                      Custom user weight(W){' '}
                    </Checkbox>
                  </Col>
                  {item?.usersToInclude && (
                    <Col span={1}>
                      <Button
                        type="text"
                        onClick={() => handleClearUsersValue(index)}
                        className="clear-btn"
                      >
                        Clear
                      </Button>
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col span={3}></Col>
                  <Col span={17} className="d-flex custom-weight-col">
                    {item.customUsersWeightCheckbox &&
                      item.usersValue?.map((el, key) => {
                        const userName =
                          userData.find((e) => e?.id === el)?.firstName +
                          ' ' +
                          userData.find((e) => e?.id === el)?.lastName;
                        return (
                          <Row align="middle" key={key} className="mt-5">
                            {userName}

                            <Input
                              className="weightBox-users"
                              onChange={(e) =>
                                handleWhoList(
                                  e.target.value,
                                  index,
                                  'usersandWeight',
                                  key,
                                  el
                                )
                              }
                              value={item?.usersandWeight[key]}
                            />
                          </Row>
                        );
                      })}
                  </Col>
                </Row>
              </Col>
              <Col span={1}>
                {(index > 0 || crossIconVisible[index]) && (
                  <Button
                    onClick={() => handleRemoveField(index)}
                    className="fs-12 close-btn"
                    size="small"
                  >
                    <CloseOutlined />
                  </Button>
                )}
              </Col>
            </Row>
          );
        })}

        <Row>
          <Button
            onClick={handleAddField}
            className="fs-12 fw-500 blue-filled-btn"
            type="primary"
            size="small"
          >
            <PlusOutlined />
          </Button>
        </Row>
      </div>
    </>
  );
};

export default memo(CreateFilter);

