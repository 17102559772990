import {
  Button,
  Row,
  Typography,
  Form,
  Col,
  Input,
  Select,
  Modal,
  message,
  Grid,
} from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { ECHOIMAGES } from '../../Assets/Images';
import { useHistory, useParams } from 'react-router-dom';
import {
  getUsersByOrganizationIDCustom,
  listUserRoles,
  updateRole,
} from './SuperAdminFun';
import { UserRole, userStatus } from '../../utils/Constants';
import { useSelector } from 'react-redux';
import {
  getCurrentOrganization,
  getCurrnetUserData,
  isAdmin,
} from '../../store/slices/loginSlice';
import { FetchUser } from 'Pages/Idea/IdeaFunction';
import { GetFileFromS3, SaveFileToS3 } from '../../utils/commonMethods';
import { Storage } from 'aws-amplify';
import { getUserWeightSetting } from 'Pages/Settings/SettingFunction';
import Impact from 'Pages/Profile/Impact';
import '../Profile/profile.less';

import {
  fetchOrganizationStages,
  fetchOrgDepartments,
  getComparisonCount,
  getGammasCount,
  getRatingsCount,
} from 'utils/CommonFunctions';
import { RESTUpdateRole } from 'utils/RESTApi';

export default function EditActiveUser() {
  const { Option } = Select;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { DisableIcon } = ECHOIMAGES.icons;
  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;

  const [managerValue, setManagerValue] = useState([]);
  const [listAllDepartments, setListAllDepartments] = useState([]);
  const [selectdDepartment, setSelectedDepartment] = useState('');
  const [allRoles, setAllRoles] = useState([]);
  const [selectdRole, setSelectedRole] = useState('');
  const [selectedManager, setSelectedManager] = useState([]);
  const [profilePhoto, setProfilePhoto] = useState();
  const [userDetail, setUserDetail] = useState([]);
  const [ProfileLogo, setProfileLogo] = useState();
  const [projectCount, setProjectCount] = useState('');
  const [opportunityCount, setOpportunityCount] = useState('');
  const [gammaCount, setGammaCount] = useState('');
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [fetchWeight, setFetchWeight] = useState([]);
  const [comparisonCompleted, setComparisonCompletedCount] = useState('');
  const [ratingsGiven, setRatingsGiven] = useState('');
  const [oldRoleName, setOldRoleName] = useState('');
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const weightSettings =
    currnetOrg?.weightSettings || loginUsrOrg?.Organization?.weightSettings;
  const isAdminGroup = useSelector(isAdmin);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const history = useHistory();
  const [form] = Form.useForm();
  const { id } = useParams();
  const userId = currnetOrg?.adminID || loginUsrOrg?.id;
  const getManagers = async () => {
    try {
      let data = {
        status: { eq: userStatus.active },
        or: [
          { role: { eq: UserRole.admin } },
          { role: { eq: UserRole.leader } },
          { role: { eq: UserRole.sme } },
        ],
      };
      let id = { organizationID: orgId };
      let res = await getUsersByOrganizationIDCustom(data, id);
      setManagerValue(res?.items);
    } catch (err) {
      console.log(err);
    }
  };

  const listOrgDepartments = async () => {
    const departments = await fetchOrgDepartments(orgId);
    setListAllDepartments(departments);
  };

  const handleGetRoles = async () => {
    try {
      const data = await listUserRoles();
      let rolesArray = data?.data?.__type?.enumValues;
      if (isAdminGroup || currnetOrg?.id) {
        rolesArray = rolesArray.filter(
          (item) => item?.name !== UserRole.superAdmin
        );
      }
      setAllRoles(rolesArray);
    } catch (error) {
      console.log(error);
    }
  };

  const props = {
    name: 'file',
    accept: 'image/*',

    async beforeUpload(file) {
      const isJpgOrPng =
        file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/jpg' ||
        file.type === 'image/gif';
      if (!isJpgOrPng) {
        message.error('svg file format is not supported');
      }

      const uploadData = await SaveFileToS3(file);
      setProfilePhoto(uploadData?.key);
      await getSignedUrlImg(uploadData?.key);
      let inputData = {
        id: id,
        photo: uploadData?.key,
      };
      await updateRole(inputData);
      return false && isJpgOrPng;
    },
  };

  const updateUserProfile = async (e) => {
    setSaveLoading(true);
    try {
      let roleUpdateData = {};
      let events = [];
      let inputData = {
        id: id,
        firstName: e.username,
        lastName: e.LastName,
        email: e.email,
        role: e.role,
        departmentID: e.department,
        managerID: e.manager,
        weight: e.UserWeight ? e.UserWeight : null,
        photo: profilePhoto,
      };
      if (firstName || lastName) {
        events.push('name-change');
        roleUpdateData['user_id'] = id;
        roleUpdateData['first_name'] = e.username;
        roleUpdateData['last_name'] = e.LastName;
      }
      if (selectdRole) {
        events.push('role-change');
        roleUpdateData['user_id'] = id;
        roleUpdateData['old_role'] = oldRoleName;
        roleUpdateData['new_role'] = selectdRole;
      }
      if (firstName || lastName || selectdRole) {
        roleUpdateData['events'] = events;
        await RESTUpdateRole(roleUpdateData);
      }
      await updateRole(inputData);
      setSaveLoading(false);
      message.success('User Details updated successfully');
      history.push('/user-management');
    } catch (err) {
      console.log(err);
      setSaveLoading(false);
    }
  };

  const getUserProfile = async (e) => {
    const userDetails = await FetchUser({ id: id });
    form.setFieldsValue({
      username: userDetails?.firstName,
      LastName: userDetails?.lastName,
      Email: userDetails?.email,
      UserWeight: userDetails?.weight,
      role: userDetails?.role,
      department: userDetails?.Department?.id,
      manager: userDetails?.Manager?.id,
    });
    setUserDetail(userDetails);
    setOldRoleName(userDetails?.role);
    if (userDetails?.photo) {
      setProfilePhoto(userDetails?.photo);
      await getSignedUrlImg(userDetails?.photo);
    }
  };

  const [allStages, setAllStages] = useState([]);

  const getStageName = async () => {
    try {
      const sortedStages = await fetchOrganizationStages(orgId);
      setAllStages(sortedStages);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchGammasCount = async () => {
    const aggregates = [{ field: 'levelID', type: 'terms', name: ' ' }];
    const { submittedIdea, opportunityCount, projectCount } =
      await getGammasCount(userId, allStages, id, null, aggregates);
    setGammaCount(submittedIdea);
    setOpportunityCount(opportunityCount);
    setProjectCount(projectCount);
  };

  const fetchComparisonCount = async () => {
    const { comparisonCount } = await getComparisonCount(userId, id);
    setComparisonCompletedCount(comparisonCount);
  };

  const fetchRatingsCount = async () => {
    const { ratingsCount } = await getRatingsCount(userId, id);
    setRatingsGiven(ratingsCount);
  };

  const getSignedUrlImg = async (fileKey) => {
    const signedURL = await GetFileFromS3(fileKey, {
      level: 'public',
    });
    setProfileLogo(signedURL);
  };

  const removeProfilePic = async () => {
    await Storage.remove(profilePhoto);
    let inputData = {
      id: id,
      photo: '',
    };
    await updateRole(inputData);
    setProfileLogo('');
    setProfilePhoto('');
  };

  const handleDeleteUser = async () => {
    setLoading(true);
    try {
      let disableUser = {
        id: id,
        status: userStatus.disabled,
      };
      let disabledUserRest = {
        user_id: id,
        events: ['disable'],
      };
      await RESTUpdateRole(disabledUserRest);
      await updateRole(disableUser);
      setLoading(false);
      setIsModalOpen(false);
      message.success('User disabled successfully');
      history.push({
        pathname: '/user-management',
        state: { disabledTab: true },
      });
    } catch (error) {
      setLoading(false);
      console.log(error);
      message.error(error.message);
    }
  };

  const getUsersWeight = async () => {
    try {
      let res = await getUserWeightSetting({ id: orgId });
      setFetchWeight(res?.data?.getOrganization);
    } catch (err) {
      console.log(err);
    }
  };
  const handleRole = async (e) => {
    let weight;
    if (e === UserRole.superAdmin || e === UserRole.leader) {
      weight = fetchWeight?.weightSettings?.filter(
        (el) => el.role === UserRole.leader
      )[0]?.weight;
    } else {
      weight = fetchWeight?.weightSettings?.filter((el) => el.role === e)[0]
        ?.weight;
    }
    form.setFieldsValue({
      UserWeight: weight,
    });
    setSelectedRole(e);
  };

  useEffect(() => {
    listOrgDepartments();
    handleGetRoles();
    getUserProfile();
    getUsersWeight();
    getManagers();
    getStageName();
    fetchComparisonCount();
    fetchRatingsCount();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchGammasCount();
    // eslint-disable-next-line
  }, [allStages]);

  return (
    <>
      <div className="plr-0 pt-0 active-user">
        {/* <Typography.Title level={5} className="mb-20">
          <ArrowLeftOutlined
            className="mr-10"
            onClick={() => window.history.back()}
          />{' '}
          Back to User management
        </Typography.Title> */}
        <div className="background-white mb-30 p-20">
          <Row justify="space-between" align="middle">
            <Row align="middle" className="gap-20">
              {/* {ProfileLogo ? (
                <Image className="profile-img" src={ProfileLogo} />
              ) : (
                userDetail?.firstName?.[0]
              )}
              <Upload {...props}>
                <Button
                  type="text"
                  className="text-primary fw-bold fs-16 h-100 mb-0"
                >
                  Upload profile picture
                </Button>
              </Upload>
              <Button
                type="text"
                className="fw-bold fs-16"
                onClick={removeProfilePic}
              >
                Remove
              </Button> */}
            </Row>

            <Button
              className="bordered-button"
              onClick={showModal}
              size="middle"
            >
              Disable
            </Button>
          </Row>

          <Form
            layout="vertical"
            requiredMark={false}
            form={form}
            onFinish={(e) => updateUserProfile(e)}
            className="opportunity-detail-edit"
          >
            <Row gutter={[20, 0]}>
              <Col span={12} md={12} sm={24} xs={24}>
                <Form.Item
                  label={<span className="fs-14">First Name</span>}
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your username',
                    },
                  ]}
                >
                  <Input
                    placeholder="Please input your User Name"
                    className="fs-14"
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </Form.Item>
              </Col>

              <Col span={12} md={12} sm={24} xs={24}>
                <Form.Item
                  label={<span className="fs-14">Last Name</span>}
                  name="LastName"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your last name',
                    },
                  ]}
                >
                  <Input
                    placeholder="Please input your Last Name"
                    className="fs-14"
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </Form.Item>
              </Col>

              <Col span={12} md={12} sm={24} xs={24}>
                <Form.Item
                  label={<span className="fs-14">Email Id</span>}
                  name="Email"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your email id',
                    },
                  ]}
                >
                  <Input
                    placeholder="Please input your Email Id"
                    disabled
                    className="fs-14"
                  />
                </Form.Item>
              </Col>
              <Col span={12} md={12} sm={24} xs={24}>
                <Form.Item
                  label={<span className="fs-14">Role</span>}
                  name="role"
                  rules={[
                    {
                      required: true,
                      message: 'Please input role',
                    },
                  ]}
                >
                  <Select
                    value={selectdRole}
                    onChange={(e) => handleRole(e)}
                    allowClear
                  >
                    {allRoles?.map((item, index) => {
                      const roleName =
                        item?.name === UserRole.sme
                          ? UserRole?.teamMember
                          : item?.name;
                      return (
                        <Select.Option
                          key={index}
                          value={item?.name}
                          className="fs-14"
                        >
                          {roleName}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12} md={12} sm={24} xs={24}>
                <Form.Item
                  name="department"
                  label={<span className="fs-14">Department</span>}
                >
                  <Select
                    placeholder="Select Department"
                    value={selectdDepartment}
                    onChange={(e) => setSelectedDepartment(e)}
                    allowClear
                  >
                    {listAllDepartments?.map((item, index) => {
                      return (
                        <Select.Option
                          key={index}
                          value={item?.id}
                          className="fs-14"
                        >
                          {item?.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12} md={12} sm={24} xs={24}>
                <Form.Item
                  name="manager"
                  label={<span className="fs-14">Manager</span>}
                >
                  <Select
                    placeholder="Select Manager"
                    value={selectedManager}
                    onChange={(e) => setSelectedManager(e)}
                    allowClear
                  >
                    {managerValue.map((e, index) => {
                      return (
                        <Option value={e.id} key={index} className="fs-14">
                          {e.firstName + ' ' + e.lastName}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={12} md={12} sm={24} xs={24}>
                <Form.Item
                  label={<span className="fs-14">User Weight</span>}
                  name="UserWeight"
                  rules={[
                    {
                      pattern: /^\d*\.?\d+$/,
                      message: 'Please enter a valid number',
                    },
                  ]}
                >
                  <Input
                    placeholder="Please input your User Weight"
                    className="fs-14"
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Button
                  type="primary"
                  className="blue-filled-btn"
                  size="middle"
                  htmlType="submit"
                  loading={saveLoading}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        </div>

        <div className="background-white p-20">
          <Row gutter={[20, 20]}>
            <Col span={24}>
              <Typography.Title level={3} className="mb-0 capitalize-text">
                Contributions from {userDetail?.firstName}
              </Typography.Title>
            </Col>

            <Impact
              userDetail={userDetail}
              opportunityCount={opportunityCount}
              projectCount={projectCount}
              gammaCount={gammaCount}
              comparisonCompleted={comparisonCompleted}
              ratingsGiven={ratingsGiven}
            />
          </Row>
        </div>
      </div>

      {/* Disable user modal  */}
      <Modal
        open={isModalOpen}
        onOk={handleDeleteUser}
        onCancel={handleCancel}
        confirmLoading={loading}
        centered
        okText="Disable"
        className="delete-user-modal"
      >
        <Row justify="center">
          <img src={DisableIcon} alt="" />
        </Row>
        <Row justify="center">
          <Typography.Title level={4}>
            Do you really want to Disable this user?
          </Typography.Title>
        </Row>
      </Modal>
    </>
  );
}

