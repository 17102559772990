import {
  Button,
  Divider,
  Drawer,
  Empty,
  Grid,
  Input,
  Row,
  Tag,
  Typography,
  message,
} from 'antd';
import React, { memo } from 'react';
import { deleteLink, linkAddIdea } from '../IdeaFunction';
import { useParams } from 'react-router-dom';
import { levelColors } from 'utils/Constants';

const RelatedItemDrawer = ({
  onClose,
  open,
  backeupData,
  setDashboardData,
  dashboardData,
  listRelatedIdea,
  setOpen,
  opportunityDetail,
  relatedIdeaIds,
  relatedIdea,
}) => {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const { id } = useParams();
  const { Search } = Input;
  const { Paragraph } = Typography;

  //for search related items
  const onSearch = (e) => {
    if (e !== '') {
      const temp = backeupData.filter(
        (item) =>
          item?.title &&
          (item?.title.toLowerCase().includes(e.toLowerCase()) ||
            item?.description.toLowerCase().includes(e.toLowerCase()) ||
            item?.friendlyId.toString().includes(parseInt(e)))
      );
      setDashboardData(temp);
    } else {
      setDashboardData(backeupData);
    }
  };

  // to unlink gamma
  const unLinkRelatedIdeas = async (id) => {
    try {
      await deleteLink(id);
      message.success('Gamma unlink successfully');
      setOpen(false);
      await listRelatedIdea();
    } catch (error) {
      console.log(error);
    }
  };

  // to link gamma
  const linkIdeas = async (objid) => {
    try {
      const inputData = {
        gammaLinkedToId: opportunityDetail?.id || id,
        linkedGammasLinkedGammaId: objid,
      };
      await linkAddIdea(inputData);
      await listRelatedIdea();
      message.success('Items linked successfully');
      setOpen(false);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Drawer
      width="40%"
      height={screens.xs ? '83vh' : ''}
      placement={screens.xs ? 'bottom' : 'right'}
      className="related-item-drawer"
      title={
        <Typography.Title level={4} className="mb-0 fs-24">
          Add related item
        </Typography.Title>
      }
      onClose={onClose}
      open={open}
    >
      <Search
        className="searchbar-style"
        placeholder="Search by keyword or ID"
        onChange={(e) => onSearch(e.target.value)}
      />
      {dashboardData?.length ? (
        dashboardData
          ?.filter((item) => item?.id !== id)
          ?.map((item) => {
            return (
              (item?.title || item?.description) && (
                <Row className="idea mt-20" gutter={[0, 20]} key={item?.id}>
                  <div className="idea-box idea w-100">
                    <Paragraph className="title capitalize-text fs-16">
                      {item?.title}
                    </Paragraph>

                    <Paragraph
                      ellipsis={{
                        rows: 3,
                        expandable: false,
                        symbol: 'more',
                        tooltip: item?.description,
                      }}
                      className="fs-14 mb-0"
                    >
                      {item?.description}
                    </Paragraph>

                    <Row
                      align="middle"
                      justify="space-between"
                      className="w-100"
                    >
                      <Divider />
                      <Row align="middle">
                        <Typography.Title level={5} className="mb-0 fs-14">
                          <span className="fw-bold text-black">ID : </span>
                          <span className="fw-400 text-black">
                            {item?.friendlyId &&
                              item?.friendlyId.toString().padStart(6, '0')}{' '}
                          </span>
                        </Typography.Title>
                        <Typography.Title className="mt-0 mb-0 ml-10 fs-14">
                          <span className="fw-bold">Type : </span>{' '}
                          <Tag
                            className="fs-14"
                            key={item?.level?.id}
                            color={levelColors[item?.level?.level]}
                          >
                            {item?.level?.name}
                          </Tag>
                        </Typography.Title>
                      </Row>

                      {relatedIdeaIds?.includes(item?.id) ? (
                        <Button
                          type="primary"
                          className="unlink-btn"
                          onClick={() =>
                            unLinkRelatedIdeas(
                              relatedIdea.filter(
                                (el) => el?.linkedGamma?.id === item.id
                              )[0]?.id
                            )
                          }
                          size="middle"
                        >
                          Unlink
                        </Button>
                      ) : (
                        <Button
                          type="primary"
                          className="blue-filled-btn "
                          size="middle"
                          onClick={() => linkIdeas(item?.id)}
                        >
                          Link
                        </Button>
                      )}
                    </Row>
                  </div>
                </Row>
              )
            );
          })
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </Drawer>
  );
};

export default memo(RelatedItemDrawer);

