import React, { memo } from 'react';

import { Modal, Row, Select, Typography } from 'antd';

const ChangeOrgModal = ({
  organizationModal,
  setIsChangeModal,
  setOrganizationModal,
  organizationDetail,
  selectdOrg,
  setSelectedOrg,
}) => {
  const showOrganizationChangeModal = () => {
    setOrganizationModal(false);
    setIsChangeModal(true);
  };
  const handleOrgCancel = () => {
    setOrganizationModal(false);
  };
  return (
    <>
      <Modal
        open={organizationModal}
        onOk={showOrganizationChangeModal}
        onCancel={handleOrgCancel}
        centered
        okText="Assign"
        className="delete-user-modal"
      >
        <Row justify="center">
          <Typography.Title level={4}>Change Organization</Typography.Title>
        </Row>
        <Row justify="center">
          <Select
            style={{
              width: 200,
            }}
            value={selectdOrg}
            onChange={(e) => setSelectedOrg(e)}
          >
            {organizationDetail?.map((item, index) => {
              return (
                <Select.Option key={index} value={item?.id}>
                  {item?.name}
                </Select.Option>
              );
            })}
          </Select>
        </Row>
      </Modal>
    </>
  );
};

export default memo(ChangeOrgModal);

