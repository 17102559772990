import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Dropdown, Table, Menu, message, Grid, Tooltip } from 'antd';
import { MoreOutlined, InfoCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { API } from 'aws-amplify';
import { updatePriorityBatchCustom } from 'graphql/customMutation';

import { arrToCommaSeperated } from 'utils/commonMethods';
import {
  getCurrentOrganization,
  getCurrnetUserData,
  isAdmin,
  isLeader,
  isSuperAdmin,
} from 'store/slices/loginSlice';
import { deletePriorityBatches } from '../PriorityFunction';
import TableSkeleton from 'Common/TableSkeleton';
import { updateUserSettingCustom } from 'utils/Actions';
import { RESTScenarioCopy, RESTUpdateRanking } from 'utils/RESTApi';

const ScenarioTable = ({
  listPriorityBatch,
  priorityOwner,
  listAddedPriorityBatch,
  loading,
  orgDefaultScenario,
  getOrganizationPriorityBatch,
  setSelectedRowKeys,
  selectedRowKeys,
}) => {
  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const userId = currnetOrg?.adminID || loginUsrOrg?.id;
  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;
  const isSuperAdminGroup = useSelector(isSuperAdmin);
  const isAdminGroup = useSelector(isAdmin);
  const isLeaderGroup = useSelector(isLeader);
  const history = useHistory();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  // create priorities
  const createDuplicatePriorityBatch = async (e) => {
    const inputData = {
      id: e?.id,
      organizationID: orgId,
      userID: userId,
    };

    let res = await RESTScenarioCopy(inputData);
    message.success('Scenario copied successfully');
    history.push({
      pathname: `/scenario-detail/${res?.body?.[0]?.id}`,
    });
  };
  const markDuplicateScenario = async (e) => {
    try {
      let input = {
        id: orgId,
        organizationDefaultPrioirtybatchId: e.id,
      };

      if (!e?.public) {
        let inputData = {
          id: e.id,
          public: true,
        };
        await API.graphql({
          query: updatePriorityBatchCustom,
          variables: { input: inputData },
        });
      }
      await updateUserSettingCustom(input);
      message.success('Default scenario changed successfully');
      RESTUpdateRanking({
        organization_id: orgId,
        priority_batch_id: e.id,
        aggregates: true,
      });

      await listAddedPriorityBatch();
      await getOrganizationPriorityBatch();
    } catch (err) {
      console.log(err);
    }
  };

  //delete priority
  const deletePriorityList = async (id) => {
    try {
      await deletePriorityBatches({ id: id });
      message.success('Scenario deleted');
      await listAddedPriorityBatch();
    } catch (err) {
      console.log(err);
    }
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    columnTitle: (
      <Tooltip
        title={
          <>
            Not sure which scenario to use in Scenario Planning? Compare
            different scenarios to see which goals are selected and how they are
            weighted!
            <br /> TIP - did you know you can "duplicate" a scenario if you want
            to tweak it just a little bit?{' '}
          </>
        }
      >
        <InfoCircleOutlined />
      </Tooltip>
    ),
    getCheckboxProps: (record) => ({
      disabled:
        (selectedRowKeys.length === 3 &&
          !selectedRowKeys.includes(record?.key)) ||
        record?.key === orgDefaultScenario,
    }),
  };

  const columns = [
    {
      title: 'Scenario Name',
      dataIndex: 'title',
      key: 'PriorityBatchName',

      render: (text, row) => {
        return row?.id === orgDefaultScenario ? (
          <>
            <span className="table-font">{text}</span>
            <Tooltip
              className="ml-10"
              title={
                <>
                  The weighting assigned within a given scenario is how ranking
                  in StrAlign is derived. Click into Default Scenario {'>'}{' '}
                  Goals and Filters to see how weighting is assigned for this
                  scenario.
                </>
              }
            >
              <InfoCircleOutlined />
            </Tooltip>
          </>
        ) : (
          <span className="table-font">{text}</span>
        );
      },
    },
    {
      title: 'Owners',
      dataIndex: 'owners',
      key: 'Owners',
      render: (text, row) => {
        const owners = text?.map((el) => el?.firstName + ' ' + el?.lastName);
        return (
          <span className="table-font">
            {arrToCommaSeperated(owners?.length > 0 ? owners : ['-'])}
          </span>
        );
      },
    },
    {
      title: 'No. of Goals',
      dataIndex: 'priorities',
      key: 'NoOfGoals',
      align: 'center',
      render: (text, row) => {
        return row?.priorities?.length ? (
          <span className="table-font">{row?.priorities?.length}</span>
        ) : (
          '0'
        );
      },
    },
    {
      title: 'Created Date',
      dataIndex: 'createdAt',
      key: 'CreatedDate',
      align: 'center',
      render: (text, row) => {
        return <span className="table-font">{moment(text).format('l')}</span>;
      },
    },
    {
      title: 'Last Modified Date',
      dataIndex: 'updatedAt',
      key: 'LastModifiedDate',
      align: 'center',
      render: (text, row) => {
        return <span className="table-font">{moment(text).format('l')}</span>;
      },
    },
    {
      title: '',
      dataIndex: 'Action',
      key: 'Action',
      width: '7%',
      align: 'center',
      onCell: () => ({
        onClick: (event) => {
          event.stopPropagation();
        },
      }),
      render: (text, row, index) => {
        let ownerIdArray = row?.owners?.map((i) => i?.id);
        priorityOwner?.[index]?.includes(userId) ||
          isSuperAdminGroup ||
          isAdminGroup;

        let disableCondition =
          row?.id === orgDefaultScenario
            ? isSuperAdminGroup || isAdminGroup
              ? false
              : true
            : ownerIdArray?.includes(userId) ||
              isSuperAdminGroup ||
              isAdminGroup
            ? false
            : true;
        return (
          <Dropdown
            overlay={
              <Menu
                items={[
                  {
                    key: '1',
                    label: 'Edit',
                    disabled: disableCondition,
                    onClick() {
                      history.push({
                        pathname: `/add-scenario/${row?.id}`,
                      });
                    },
                  },
                  {
                    key: '2',
                    label: 'Duplicate scenario',
                    onClick: () => createDuplicatePriorityBatch(row),
                  },
                  {
                    key: '3',
                    label: 'Delete',
                    disabled:
                      row?.id === orgDefaultScenario ? true : disableCondition,
                    onClick: () => {
                      deletePriorityList(row?.id);
                    },
                  },
                  {
                    key: '4',
                    label: 'Mark as Default scenario',
                    onClick: () => markDuplicateScenario(row),
                    disabled:
                      isLeaderGroup ||
                      (currnetOrg?.id === undefined && isSuperAdminGroup) ||
                      row?.id === orgDefaultScenario,
                  },
                ].filter((item) => !item.disabled)}
              />
            }
          >
            <a href="/#" onClick={(e) => e.preventDefault()}>
              <MoreOutlined className="dot-style" />
            </a>
          </Dropdown>
        );
      },
    },
  ];

  const rowClassName = (record, index) => {
    if (record?.id === orgDefaultScenario) {
      return 'first-row-batch';
    }
  };

  const handleRowClick = (record) => {
    history.push({
      pathname: `/scenario-detail/${record?.id}`,
    });
  };

  return (
    <>
      {loading ? (
        <>
          <TableSkeleton />
        </>
      ) : (
        <Table
          rowSelection={rowSelection}
          columns={columns}
          scroll={{ x: 1000 }}
          dataSource={listPriorityBatch}
          className="mt-10 cursor-pointer scenario-table"
          pagination={false}
          onRow={(record) => ({
            onClick: () => handleRowClick(record),
          })}
          rowClassName={rowClassName}
          size={screens.xxl ? 'middle' : 'small'}
        />
      )}
    </>
  );
};

export default memo(ScenarioTable);

