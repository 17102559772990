import { Button, Row, Typography, Divider, Col, Form, Tag } from 'antd';
import React, { memo, useEffect, useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import PublishModal from '../Components/PublishModal';
import MultiLineInput from '../Components/MultiLineInput';
import { getFormByStage, updateStageCustom } from '../Services/StageActions';
import { v4 as uuidv4 } from 'uuid';
import {
  getDraftForm,
  getformElementUpdate,
  getPublishedForm,
  getStageName,
  setBackButtonClicked,
  setcurrentStageDetails,
  setDraftForm,
  setFormElementUpdate,
  setPreviewFlag,
  setPublishedForm,
  setStageName,
} from 'store/slices/StageSlice';
import { useDispatch, useSelector } from 'react-redux';

import EditStageName from '../Components/EditStageName';
import {
  getCurrentOrganization,
  getCurrnetUserData,
  setFormLayout,
} from 'store/slices/loginSlice';
import FormSkeleton from 'Common/FormSkeleton';
import { Droppable, DragDropContext } from 'react-beautiful-dnd';
import CommonFormElement from '../Components/CommonFormElement';
import ConfirmationModal from '../Components/ConfirmationModal';
import LeavePageModal from '../Components/LeavePageModal';
import { capitalizeFirstLetter } from 'utils/Constants';
import Stage1DetailForm from '../Components/Stage1DetailForm';
import SaveDraft from '../Components/SaveDraft';
import {
  createFormDraftCustom,
  updateFormDraftCustom,
} from 'graphql/customMutation';
import { API } from 'aws-amplify';
import { RESTGet } from 'utils/RESTApi';
import _isEqual from 'lodash/isEqual';

const Stage1DetailPage = () => {
  const history = useHistory();
  const [form] = Form.useForm();

  const [IseditNameModalOpen, setIsEditNameModalOpen] = useState(false);
  const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
  const [formBody, setFormBody] = useState([]);
  const [selectedElement, setSelectedElement] = useState({});
  const updatedFormElement = useSelector(getformElementUpdate);
  const [stageLevel, setStageLevel] = useState();
  const getStageNameElement = useSelector(getStageName);
  const [currentStage, setCurrentStage] = useState({});
  const [formLoading, setFormLoading] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;
  const [copyofPublishedForm, setIsCopyofPublishedForm] = useState([]);
  const [mfList, setMFList] = useState([]);
  const stageStatus = history.location?.state?.prevForm;
  const draftForms = useSelector(getDraftForm);

  const { id } = useParams();
  const dispatch = useDispatch();

  const handleEditModalOpen = () => {
    setIsEditNameModalOpen(true);
  };

  const handlePublishModalOpen = () => {
    setIsPublishModalOpen(true);
  };

  const handleConfirmationModalOpen = () => {
    setConfirmationModal(true);
  };
  // Fetches form layout data for a specific stage
  const getFormLayout = async () => {
    setFormLoading(true);
    try {
      let res;
      let formLayout;
      if (window?.location?.pathname.includes('/stage1-draft-form')) {
        res = await getFormByStage({ id: id });

        formLayout = JSON.parse(res?.draft?.items?.[0]?.layout);
        dispatch(setDraftForm(res?.draft?.items?.[0]?.layout));
        dispatch(setPublishedForm(res?.form?.layout));
      } else {
        res = await getFormByStage({ id: id });

        formLayout = JSON.parse(res?.form?.layout);
        dispatch(setPublishedForm(res?.form?.layout));
      }
      setDraftVersion(res?.draft?.items?.[0]?.version);
      setBackupFormVersion(res?.draft?.items?.[1]?.version);
      dispatch(setcurrentStageDetails(res));
      setStageLevel(res.level);
      setIsCopyofPublishedForm(res?.form?.layout);
      formLayout = formLayout.map((item, index) => ({
        ...item,
        id: item?.id || uuidv4(),
      }));
      if (updatedFormElement) {
        dispatch(setFormElementUpdate(updatedFormElement));
        setFormBody(updatedFormElement);
        dispatch(setStageName(res));
      } else {
        dispatch(setFormElementUpdate(formLayout));
        setFormBody(formLayout);
        dispatch(setStageName(res));
      }
      setFormLoading(false);
    } catch (err) {
      setFormLoading(false);
      console.log(err);
    }
  };

  //Function to update the stage form
  const [draftVersion, setDraftVersion] = useState();
  const [backupFormVersion, setBackupFormVersion] = useState();
  const fetchPublishedForm = useSelector(getPublishedForm);
  const draftLocation = useLocation();

  const updateStageForm = async () => {
    setLoading(true);
    try {
      let res;
      let updatedForm;
      let inputData1 = {
        layout: JSON.stringify(updatedFormElement),
        name: getStageNameElement?.name + ' ' + 'Draft',
        organizationID: orgId,
        stageID: getStageNameElement?.id,
        updatedByIAM: false,
        version: 1,
      };
      const firstApiCall = await API.graphql({
        query:
          draftVersion === 1 ? updateFormDraftCustom : createFormDraftCustom,
        variables: { input: inputData1 },
      });
      let inputData2 = {
        layout: fetchPublishedForm,
        name: getStageNameElement?.name + ' ' + 'Draft',
        organizationID: orgId,
        stageID: getStageNameElement?.id,
        updatedByIAM: false,
        version: 0,
      };
      const secondApiCall = await API.graphql({
        query:
          backupFormVersion === 0
            ? updateFormDraftCustom
            : createFormDraftCustom,
        variables: { input: inputData2 },
      });
      await Promise.all([firstApiCall, secondApiCall]);

      let inputData = {
        id: id,
        active: true,
        form: {
          layout: JSON.stringify(updatedFormElement),
        },
        updatedByIAM: false,
      };
      res = await updateStageCustom(inputData);
      updatedForm = JSON.parse(res?.form?.layout);

      dispatch(setFormElementUpdate(updatedForm));
      dispatch(setFormLayout(res?.Organization?.stages));
      setLoading(false);
      if (stageStatus?.active) {
        setConfirmationModal(false);
      }
      handlePublishModalOpen();
      setTimeout(() => {
        setIsPublishModalOpen(false);
        history.push('/stages');
      }, 2000);
      setIsFormChanged(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const handleDragStart = (e, elementType) => {
    e.dataTransfer.setData('elementType', elementType);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const generatePrefixedId = (level) => {
    const prefix = level < 10 ? `${level}` : `${level}`;
    const uuid = uuidv4();
    return `${prefix}-${uuid}`;
  };

  //Function for drag form element
  const handleDrop = (e) => {
    e.preventDefault();
    const elementType = e.dataTransfer.getData('elementType');
    // Create a new form item based on the dropped type
    const newFormItem = {
      id: generatePrefixedId(stageLevel),
      inputType: elementType,
      label: 'Label', // You can customize the label
      source: 'created',
      default: false,
      hide: false,
    };
    dispatch(setFormElementUpdate([...updatedFormElement, newFormItem]));
    setFormBody([...formBody, newFormItem]);

    const element = document.getElementById('to-scroll');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  //Function to drop form element
  const onDragEnd = ({ source, destination, draggableId }) => {
    if (!destination) return;
    if (source.droppableId !== destination.droppableId) {
      const newList2 = Array.from(updatedFormElement);
      const elementType = draggableId;

      const measurementType = draggableId.split('_');
      let type;
      if (elementType === 'singleLine' || elementType === 'multiLine') {
        type = 'text';
      } else if (elementType === 'link') {
        type = 'link';
      } else if (elementType === 'dropdown') {
        type = 'dropdown';
      } else if (measurementType?.[0] === '$' || measurementType?.[0] === '#') {
        type = 'measurement';
      } else if (
        measurementType?.[0] === '-+' ||
        measurementType?.[0] === '+-'
      ) {
        type = 'measurement';
      }

      let newFormItem = {};
      if (type === 'measurement') {
        newFormItem = {
          id: measurementType[1],
          type: type,
          stageID: id,
        };
      } else {
        newFormItem = {
          id: generatePrefixedId(stageLevel),
          inputType: elementType,
          label: 'Label', // You can customize the label
          type: type,
          source: 'created',
        };
      }

      newList2.splice(destination.index, 0, newFormItem);
      dispatch(setFormElementUpdate(newList2));
      setIsFormChanged(true);
    }
  };

  const handleFormChange = () => {
    setIsFormChanged(true);
  };

  useEffect(() => {
    const pathname = window.location.pathname;
    const isFormChanged =
      pathname?.includes('/draft-form') ||
      pathname?.includes('/stage1-draft-form')
        ? updatedFormElement !== null &&
          !_isEqual(updatedFormElement, JSON?.parse(draftForms))
        : updatedFormElement !== null &&
          !_isEqual(updatedFormElement, JSON?.parse(fetchPublishedForm));
    const handleBeforeUnload = (event) => {
      if (isFormChanged) {
        event.preventDefault();
        event.returnValue =
          'You have unsaved changes. Are you sure you want to leave?';
      }
    };

    const handleMenuItemClick = (event) => {
      if (isFormChanged) {
        event.preventDefault();
        const path = event.currentTarget.getAttribute('href');
        setIsConfirmationVisible(true);
        dispatch(setBackButtonClicked(true));
      }
    };

    // Attach event listeners
    window.addEventListener('beforeunload', handleBeforeUnload);

    const menuItems = document.querySelectorAll('.sidebar');
    menuItems.forEach((item) => {
      item.addEventListener('click', handleMenuItemClick);
    });

    return () => {
      // Clean up event listeners
      window.removeEventListener('beforeunload', handleBeforeUnload);
      menuItems.forEach((item) => {
        item.removeEventListener('click', handleMenuItemClick);
      });
    };
  }, [updatedFormElement, history, draftForms, fetchPublishedForm]);

  useEffect(() => {
    getFormLayout();
    // eslint-disable-next-line
  }, []);

  const getMFList = async () => {
    try {
      let listApi = `/measurement/all?organization_id=${orgId}`;
      let res = await RESTGet(listApi);
      setMFList(res?.body?.items);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getMFList();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="stage-details background-white">
      <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
        <Row justify="space-between" className="w-100">
          <Col className="header-text" span={16}>
            <div className="d-flex">
              <Typography.Title level={4} className="capitalize-text">
                {getStageNameElement?.name &&
                  capitalizeFirstLetter(getStageNameElement?.name)}
              </Typography.Title>
              <Typography.Title level={4} className="mt-0 ml-10 stage-text ">
                - Stage {getStageNameElement?.level}
              </Typography.Title>
              <EditOutlined
                className="ml-10 editicon"
                onClick={() => {
                  handleEditModalOpen();
                  setCurrentStage(getStageNameElement);
                }}
              />
            </div>
            <div>
              {draftLocation?.pathname.includes('/stage1-draft-form') ||
              !stageStatus?.active ? (
                <Tag color="gold" className=" mb-10">
                  Draft Version
                </Tag>
              ) : (
                <Tag color="blue" className="mb-10">
                  Published Version
                </Tag>
              )}
            </div>
          </Col>

          <Col className="d-flex" span={7}>
            <Button
              className="bordered-button mr-10"
              onClick={() => {
                dispatch(setPreviewFlag(true));
                dispatch(setBackButtonClicked(true));
                history.push({
                  pathname: '/stage1preview',
                  state: {
                    source: draftLocation?.pathname.includes(
                      '/stage1-draft-form'
                    )
                      ? 'draft-form'
                      : 'detail',
                  },
                });
              }}
            >
              Preview
            </Button>

            <SaveDraft
              updatedFormElement={updatedFormElement}
              getStageNameElement={getStageNameElement}
              fetchPublishedForm={fetchPublishedForm}
            />
            <Button
              className="blue-filled-btn"
              onClick={() =>
                stageStatus?.active
                  ? handleConfirmationModalOpen()
                  : updateStageForm()
              }
              disabled={formLoading}
              size="middle"
            >
              {stageStatus?.active &&
              draftLocation?.pathname.includes('/stage1-draft-form')
                ? 'Publish'
                : stageStatus?.active
                ? 'Update'
                : 'Publish'}
            </Button>
          </Col>
        </Row>

        <Row justify="space-between" className="mt-20">
          <Col span={16} className="scrollable ">
            {/* <Divider className="mt-0 divider-w" /> */}

            <Row gutter={20} className="mr-0">
              <Col span={16}>
                <Droppable droppableId="list2">
                  {(provided) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={{ margin: '10px' }}
                    >
                      <Form
                        layout="vertical"
                        form={form}
                        requiredMark={false}
                        onValuesChange={handleFormChange}
                      >
                        {formLoading ? (
                          <>
                            <FormSkeleton />
                            <FormSkeleton />
                            <FormSkeleton />
                            <FormSkeleton />
                          </>
                        ) : (
                          <div>
                            <Stage1DetailForm
                              updatedFormElement={updatedFormElement}
                              setSelectedElement={setSelectedElement}
                              stageLevel={stageLevel}
                              mfList={mfList}
                              setMFList={setMFList}
                              id={id}
                            />
                          </div>
                        )}
                      </Form>
                    </div>
                  )}
                </Droppable>
              </Col>

              {updatedFormElement?.map((currentElement, index) => {
                return (
                  <>
                    {currentElement?.inputType === 'related items' && (
                      <Col
                        span={7}
                        className="background-white related-item-block"
                      >
                        <Row className="p-15">
                          <Typography.Text>
                            Please link any other items you believe <br /> are
                            related to this one.
                          </Typography.Text>
                        </Row>
                        <Row className="p-15">
                          <Button
                            type="primary"
                            className="primary-btn related-btn"
                          >
                            Add Related Items
                          </Button>
                        </Row>
                      </Col>
                    )}
                  </>
                );
              })}
            </Row>
          </Col>
          <Col span={1} className="stage-divider">
            <Divider type="vertical" style={{ height: '100vh' }} />
          </Col>
          <Col span={7} onDragOver={handleDragOver} onDrop={handleDrop}>
            {selectedElement?.inputType === 'singleLine' ||
            selectedElement?.inputType === 'multiLine' ||
            selectedElement?.inputType === 'dropdown' ||
            selectedElement?.inputType === 'datepicker' ||
            selectedElement?.inputType === 'link' ||
            selectedElement?.inputType === 'symbol' ||
            selectedElement?.inputType === 'dragger' ? (
              <MultiLineInput
                selectedElement={selectedElement}
                setFormBody={setFormBody}
                formBody={formBody}
                setSelectedElement={setSelectedElement}
                stageLevel={stageLevel}
                copyofPublishedForm={copyofPublishedForm}
                getStageNameElement={getStageNameElement}
              />
            ) : (
              <Droppable droppableId="list1">
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{ margin: '10px' }}
                  >
                    <CommonFormElement
                      handleDragStart={handleDragStart}
                      updatedFormElement={updatedFormElement}
                      mfList={mfList}
                    />
                  </div>
                )}
              </Droppable>
            )}
          </Col>
        </Row>

        <EditStageName
          IseditNameModalOpen={IseditNameModalOpen}
          setIsEditNameModalOpen={setIsEditNameModalOpen}
          currentStage={currentStage}
          getFormLayout={getFormLayout}
        />
        <PublishModal
          isPublishModalOpen={isPublishModalOpen}
          setIsPublishModalOpen={setIsPublishModalOpen}
          stageStatus={stageStatus}
        />
        <ConfirmationModal
          confirmationModal={confirmationModal}
          setConfirmationModal={setConfirmationModal}
          updateStageForm={updateStageForm}
          loading={loading}
        />
        <LeavePageModal
          isConfirmationVisible={isConfirmationVisible}
          setIsConfirmationVisible={setIsConfirmationVisible}
          updatedFormElement={updatedFormElement}
          getStageNameElement={getStageNameElement}
          orgId={orgId}
        />
      </DragDropContext>
    </div>
  );
};

export default memo(Stage1DetailPage);

