import {
  Button,
  Checkbox,
  Col,
  Form,
  message,
  Row,
  Typography,
  Input,
} from 'antd';
import { listAllStages } from 'Pages/Stages/Services/StageActions';
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCurrentOrganization,
  getCurrnetUserData,
  isAdmin,
} from 'store/slices/loginSlice';
import { setComparisonList, setRatingList } from 'store/slices/StageSlice';
import { getOrganizationCustom } from 'utils/Actions';
import { fetchRoles } from 'utils/CommonFunctions';
import { capitalizeFirstLetter, UserRole } from 'utils/Constants';
import {
  getUserCompareSetting,
  getUserRatingSetting,
  getUserWeightSetting,
  updateUserSetting,
} from './SettingFunction';

const GeneralSettings = () => {
  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;
  const isAdminGroup = useSelector(isAdmin);

  const [allRoles, setAllRoles] = useState([]);
  const [checkRole, setCheckRole] = useState();
  const [compareData, setCompareData] = useState([]);
  const [listStage, setListStage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ratingData, setRatingData] = useState([]);
  const [SMEWeight, setSMEWeight] = useState();
  const [LeaderWeight, setLeaderWeight] = useState();
  const [AdminWeight, setAdminWeight] = useState();
  const dispatch = useDispatch();

  const handleGetRoles = async () => {
    const rolesArray = await fetchRoles(isAdminGroup, currnetOrg);
    setAllRoles(rolesArray);
  };

  const getVisionDetails = async () => {
    try {
      let res = await getOrganizationCustom({ id: orgId });
      let GoalWeightFlag = res?.scenarioWeightsFlag;
      if (GoalWeightFlag) {
        setCheckRole(GoalWeightFlag);
      } else {
        setCheckRole(false);
      }
    } catch (err) {
      throw err;
    }
  };

  const listUserWeight = async () => {
    try {
      const res = await getUserWeightSetting({ id: orgId });
      let weights = res?.data?.getOrganization?.weightSettings;

      if (weights) {
        setSMEWeight(weights.find((val) => val.role === 'SME')?.weight);
        setLeaderWeight(weights.find((val) => val.role === 'LEADER')?.weight);
        setAdminWeight(weights?.find((val) => val.role === 'ADMIN')?.weight);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const listUserCompare = async () => {
    try {
      const res = await getUserCompareSetting({ id: orgId });
      let compareFlage = res?.data?.getOrganization?.comparisonFlags
        ? JSON.parse(res?.data?.getOrganization?.comparisonFlags)
        : '';
      if (compareFlage) {
        const result = Object.keys(compareFlage).filter(
          (key) => compareFlage[key]
        );
        setCompareData(result);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const listAllStagesByOrg = async () => {
    try {
      let response = await listAllStages({ organizationID: orgId });
      let sortedLevel = response.items.sort((a, b) => a.level - b.level);
      setListStage(sortedLevel);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    listUserWeight();
    listUserCompare();
    listAllStagesByOrg();
    listUserRating();
    handleGetRoles();
    getVisionDetails();
    // eslint-disable-next-line
  }, []);

  const listUserRating = async () => {
    try {
      const res = await getUserRatingSetting({ id: orgId });
      let ratingFlage = res?.data?.getOrganization?.ratingFlags
        ? JSON.parse(res?.data?.getOrganization?.ratingFlags)
        : '';
      if (ratingFlage) {
        const result = Object.keys(ratingFlage).filter(
          (key) => ratingFlage[key]
        );
        setRatingData(result);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const updateUserRatingData = async (e) => {
    setLoading(true);
    const ratingFlags = {};
    listStage.forEach((item) => {
      let id = item?.id;
      if (ratingData.includes(id)) {
        ratingFlags[id] = true;
      } else {
        ratingFlags[id] = false;
      }
    });
    const comparisonFlags = {};
    listStage.forEach((item) => {
      let id = item?.id;
      if (compareData.includes(id)) {
        comparisonFlags[id] = true;
      } else {
        comparisonFlags[id] = false;
      }
    });
    const input = {
      id: orgId,
      ratingFlags: JSON.stringify(ratingFlags),
      scenarioWeightsFlag: checkRole,
      comparisonFlags: JSON.stringify(comparisonFlags),
      weightSettings: [
        { role: 'SME', weight: SMEWeight },
        { role: 'LEADER', weight: LeaderWeight },
        { role: 'ADMIN', weight: AdminWeight },
      ],
    };
    try {
      await updateUserSetting(input);
      await getVisionDetails();
      dispatch(setRatingList(ratingData.length));
      dispatch(setComparisonList(compareData?.length));
      message.success(`Changes saved successfully`);
      listUserRating();
      listUserCompare();
      setLoading(false);
    } catch (error) {
      message.error(error.message);
      setLoading(false);
    }
  };

  const [isChanged, setIsChanged] = useState(false);

  return (
    <Row className="general-setting">
      <Col span={22}>
        <Row>
          <Typography.Title level={4}>General Settings</Typography.Title>
        </Row>
        <div>
          {' '}
          <Typography.Title level={5} className="text-black fw-500 mt-20">
            Configure Goal Weight Visibility
          </Typography.Title>
          <div className="goal-weight">
            <div>
              {allRoles?.map((item, index) => {
                const roleName =
                  item.name === UserRole.sme ? UserRole?.teamMember : item.name;
                const isSmeRole = item.name === UserRole?.sme;
                let defaultChecked =
                  item.name === UserRole?.leader ||
                  item.name === UserRole?.admin;
                return (
                  <Checkbox
                    className="p-3"
                    key={index}
                    disabled={!isSmeRole}
                    checked={defaultChecked ? true : checkRole}
                    onChange={(e) => {
                      isSmeRole ? setCheckRole(e.target.checked) : undefined;
                      setIsChanged(true);
                    }}
                  >
                    {capitalizeFirstLetter(roleName)}
                  </Checkbox>
                );
              })}
            </div>
          </div>
        </div>
        <div>
          <Typography.Title level={5} className="text-black fw-500 mt-20">
            Configure User Weight
          </Typography.Title>

          <Row gutter={10}>
            <Col span={6} md={6} sm={24} xs={24}>
              <Input
                placeholder="Please enter Team member Weight"
                value={SMEWeight}
                onChange={(e) => {
                  setSMEWeight(e.target.value);
                  setIsChanged(true);
                }}
              />
            </Col>
            <Col span={6} md={6} sm={24} xs={24}>
              <Input
                placeholder="Please enter Leader Weight"
                value={LeaderWeight}
                onChange={(e) => {
                  setLeaderWeight(e.target.value);
                  setIsChanged(true);
                }}
              />
            </Col>
            <Col span={6} md={6} sm={24} xs={24}>
              <Input
                placeholder="Please enter Admin Weight"
                value={AdminWeight}
                onChange={(e) => {
                  setAdminWeight(e.target.value);
                  setIsChanged(true);
                }}
              />
            </Col>
          </Row>
        </div>
        <div>
          <Typography.Title level={5} className="text-black fw-500 mt-20 m-b-2">
            Item to be shown in comparison
          </Typography.Title>

          <p className="mb-10 fs-12">
            When performing comparisons, all users will be subject to the
            following compare settings.{' '}
          </p>
          <Row>
            <Col span={22} md={22} sm={24} xs={24}>
              <Form.Item
                className="mb-0"
                label={
                  <span className="fs-12">
                    Select items to be shown in comparison
                  </span>
                }
                name="SMEWeight"
              />
              <Checkbox.Group
                onChange={(selectedIds) => {
                  setCompareData(selectedIds);
                  setIsChanged(true);
                }}
                value={compareData}
              >
                {listStage
                  .filter((item) => item?.active)
                  ?.map((compare) => {
                    let checked = compareData.includes(compare.id);
                    return (
                      <Checkbox
                        key={compare.id}
                        value={compare.id}
                        checked={checked}
                      >
                        {compare.name}
                      </Checkbox>
                    );
                  })}
              </Checkbox.Group>
            </Col>
          </Row>
        </div>
        <div>
          <Typography.Title level={5} className="text-black fw-500 mt-20 m-b-2">
            Item to be shown in rating
          </Typography.Title>

          <p className="mb-10 fs-12">
            When performing ratings, all users will be subject to the following
            rating settings.
          </p>
          <Row>
            <Col span={22} md={22} sm={24} xs={24}>
              <Form.Item
                className="mb-0"
                label={
                  <span className="fs-12">
                    Select items to be shown in rating
                  </span>
                }
                name="SMEWeight"
              />
              <Checkbox.Group
                onChange={(selectedIds) => {
                  setRatingData(selectedIds);
                  setIsChanged(true);
                }}
                value={ratingData}
              >
                {listStage
                  .filter((item) => item?.active)
                  ?.map((rate) => {
                    let checked = ratingData.includes(rate.id);
                    return (
                      <Checkbox key={rate.id} value={rate.id} checked={checked}>
                        {rate.name}
                      </Checkbox>
                    );
                  })}
              </Checkbox.Group>
            </Col>
          </Row>
        </div>
      </Col>
      <Col span={2} md={2} sm={24} xs={24}>
        <Row justify="end" align="bottom">
          <Button
            htmlType="submit"
            className="blue-filled-btn "
            size="middle"
            type="primary"
            loading={loading}
            disabled={!isChanged || loading}
            onClick={(e) => updateUserRatingData(e)}
          >
            Save
          </Button>
        </Row>
      </Col>
    </Row>
  );
};
export default memo(GeneralSettings);

