import { Col, Divider, Row, Skeleton, Typography } from 'antd';
import React, { memo } from 'react';
import ScenarioDetailFilterList from './ScenarioDetailFilterList';

const GoalsandFilters = ({ itemData, loading }) => {
  return (
    <Row className="detail-page">
      <Col span={11}>
        <Row justify="space-between" className="mb-10">
          <Typography.Title level={5}>Goals</Typography.Title>
          <Typography.Title level={5} className="mt-0">
            Weight
          </Typography.Title>
        </Row>
        {loading ? (
          <>
            <Skeleton.Input active className="goal-skeleton" />
            <Skeleton.Input active className="goal-skeleton" />
            <Skeleton.Input active className="goal-skeleton" />
          </>
        ) : (
          itemData?.priorities?.map((item, index) => {
            return (
              <Row
                justify="space-between"
                align="middle"
                className="scenario-details"
                gutter={[0, 10]}
                key={index}
              >
                <Typography.Title
                  level={5}
                  className="mb-0 title fs-16 fw-500"
                  style={
                    true
                      ? {
                          width: 250,
                        }
                      : undefined
                  }
                  ellipsis={
                    true
                      ? {
                          tooltip: item?.name,
                        }
                      : false
                  }
                >
                  {item?.name}
                </Typography.Title>
                <Row align="middle">
                  <Typography.Title level={5} className="mb-0 fs-14">
                    <span className="fw-bolder">{item?.weight}%</span>
                  </Typography.Title>
                </Row>
              </Row>
            );
          })
        )}
      </Col>
      <Col span={1} className="scenario-divider">
        <Divider type="vertical" className="h-100" />
      </Col>
      <Col span={12}>
        <ScenarioDetailFilterList itemData={itemData} loading={loading} />
      </Col>
    </Row>
  );
};

export default memo(GoalsandFilters);
