import { Button, Spin } from 'antd';
import { API, Auth } from 'aws-amplify';
import { React, useState } from 'react';
import { useDispatch } from 'react-redux';
import { currentUser, signIn } from 'store/slices/loginSlice';
import { msalInstance } from './MSAL-Instance';
import { FetchUser } from 'Pages/Idea/IdeaFunction';
import { charset } from 'utils/Constants';
import { ECHOIMAGES } from 'Assets/Images';

const Login_Using_SSO = ({ setAuthenticatedUser }) => {
  const { MicrosoftIcon } = ECHOIMAGES.icons;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const generateRandomString = (length) => {
    const passwordString = charset;

    let randomString = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * passwordString.length);
      randomString += passwordString[randomIndex];
    }
    return randomString;
  };

  // for login
  const loginHandler = async () => {
    setLoading(true);
    let data = await msalInstance.handleLogin();
    try {
      localStorage.setItem('SSO', true);
      await Auth.signUp({
        username: data?.idTokenClaims?.email,
        password: generateRandomString(16),
        attributes: {
          email: data?.idTokenClaims?.email,
          'custom:msalIdToken': data?.idToken,
        },
      });
      await Auth.signIn(data?.idTokenClaims?.email)
        .then(async (signinres) => {
          await Auth.sendCustomChallengeAnswer(signinres, data?.idToken);
          const authUser = await Auth.currentAuthenticatedUser();
          if (authUser?.attributes?.given_name) {
            localStorage.setItem(
              'given_name',
              authUser?.attributes?.given_name
            );
            const signInUserData = {
              token: authUser?.signInUserSession?.accessToken?.jwtToken,
              groups:
                authUser?.signInUserSession?.accessToken?.payload[
                  'cognito:groups'
                ],
              userData: authUser?.attributes,
            };
            dispatch(signIn(signInUserData));
            const loginUserData = await FetchUser({
              id: authUser?.attributes?.sub,
            });
            dispatch(currentUser(loginUserData));
          } else {
            setAuthenticatedUser(!authUser?.attributes?.given_name);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      setLoading(false);
    } catch (err) {
      if (err.message === 'An account with the given email already exists.') {
        setLoading(true);
        await Auth.signIn(data?.idTokenClaims?.email)
          .then(async (signinres) => {
            await Auth.sendCustomChallengeAnswer(signinres, data?.idToken);
            const authUser = await Auth.currentAuthenticatedUser();
            if (authUser?.attributes?.given_name) {
              localStorage.setItem(
                'given_name',
                authUser?.attributes?.given_name
              );
              const signInUserData = {
                token: authUser?.signInUserSession?.accessToken?.jwtToken,
                groups:
                  authUser?.signInUserSession?.accessToken?.payload[
                    'cognito:groups'
                  ],
                userData: authUser?.attributes,
              };
              dispatch(signIn(signInUserData));
              const loginUserData = await FetchUser({
                id: authUser?.attributes?.sub,
              });
              dispatch(currentUser(loginUserData));
            } else {
              setAuthenticatedUser(!authUser?.attributes?.given_name);
            }
          })
          .catch((error) => {
            console.log(error);
          });
        setLoading(false);
      }
    }
  };

  return (
    <>
      {loading && (
        <Spin size="large">
          <Button
            className="microsoft-btn"
            onClick={loginHandler}
            icon={<img src={MicrosoftIcon} alt="" className="microsoft-icon" />}
          >
            Sign in with Microsoft
          </Button>
        </Spin>
      )}
      {!loading && (
        <Button
          className="microsoft-btn"
          onClick={loginHandler}
          icon={<img src={MicrosoftIcon} alt="" className="microsoft-icon" />}
        >
          Sign in with Microsoft
        </Button>
      )}
    </>
  );
};

export default Login_Using_SSO;

