import { message } from 'antd';
import { createIdea, getUsers, getIdeas } from '../../utils/Actions';

export const submitIdea = async (data) => {
  try {
    const addIdea = await createIdea(data);
    return addIdea;
  } catch (error) {
    message.error(error.message);
  }
};

export const FetchUser = async (id) => {
  try {
    const listUser = await getUsers(id);
    return listUser;
  } catch (error) {
    message.error(error.message);
  }
};

export const getAllIdeas = async () => {
  try {
    const listIdeas = await getIdeas();
    return listIdeas;
  } catch (error) {
    message.error(error.message);
  }
};
