import { message, Modal, Row, Select, Typography } from 'antd';
import React, { memo, useEffect, useState } from 'react';
import { searchOrganizationsCustom } from 'utils/Actions';
import AssignPartnerConfirmModal from './AssignPartnerConfirmModal';

const AssignPartnerModal = ({
  assignModalOpen,
  setIsAssignModalOpen,
  assignPartnerData,
  fetchOrganizations,
  setCurrentPage,
}) => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [partnetListing, setPartnerListing] = useState([]);
  const [selectedPartnerID, setSelectedPartnerID] = useState();
  const [cleared, setCleared] = useState(false);

  const handleCancel = () => {
    setCleared(true);
    setIsAssignModalOpen(false);
  };

  const handleOpen = () => {
    if (selectedPartnerID !== undefined) {
      setIsAssignModalOpen(false);
      setIsConfirmModalOpen(true);
    } else {
      message.error('Please select partner');
    }
  };

  //function to list partners
  const listPartner = async () => {
    try {
      const filter = {
        type: {
          eq: 'PARTNER',
        },
      };
      let sort = { direction: 'asc', field: 'name' };
      let nextToken = null;
      let allData = [];
      while (true) {
        let response = await searchOrganizationsCustom(filter, nextToken, sort);
        allData.push(...response?.items);
        if (!response?.nextToken) {
          break;
        }
        nextToken = response?.nextToken;
      }
      await processUserData(allData);
    } catch (err) {
      console.log(err);
    }
  };

  //function to handle nextToken
  const processUserData = async (userData) => {
    setPartnerListing(userData);
  };

  //function to search partner
  const onSearch = async (e) => {
    try {
      const filter = {
        type: {
          eq: 'PARTNER',
        },
        name: { wildcard: '*' + e + '*' },
      };

      let sort = { direction: 'asc', field: 'name' };
      let nextToken = null;
      let response = await searchOrganizationsCustom(filter, nextToken, sort);
      setPartnerListing(response?.items);
    } catch (err) {
      console.log(err);
    }
  };

  //to set selected partner name in dropdown
  useEffect(() => {
    if (assignPartnerData || cleared) {
      setSelectedPartnerID(assignPartnerData.partnerID);
    }
  }, [assignPartnerData, cleared]);

  useEffect(() => {
    listPartner();
    // eslint-disable-next-line
  }, []);

  const resetPartnerListing = async () => {
    await listPartner();
  };

  return (
    <>
      <Modal
        className="delete-user-modal"
        centered
        open={assignModalOpen}
        onCancel={handleCancel}
        okText="Continue"
        closeIcon={false}
        onOk={handleOpen}
      >
        <>
          <Row>
            <Typography.Title className="fw-500 fs-16">
              Assign Partner
            </Typography.Title>
          </Row>
          <Select
            className="w-100 mt-10"
            onChange={(e) => setSelectedPartnerID(e)}
            value={selectedPartnerID}
            onSearch={onSearch}
            showSearch
            allowClear
            filterOption={false}
            onClear={() => {
              resetPartnerListing();
            }}
          >
            {partnetListing?.map((e, index) => {
              return (
                <Select.Option value={e?.id} key={index}>
                  {e?.name}
                </Select.Option>
              );
            })}
          </Select>
        </>
      </Modal>
      <AssignPartnerConfirmModal
        setIsConfirmModalOpen={setIsConfirmModalOpen}
        isConfirmModalOpen={isConfirmModalOpen}
        selectedPartnerID={selectedPartnerID}
        assignPartnerData={assignPartnerData}
        fetchOrganizations={fetchOrganizations}
        setCurrentPage={setCurrentPage}
      />
    </>
  );
};

export default memo(AssignPartnerModal);

