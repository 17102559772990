import {
  ExecuteMutation,
  ExecuteQueryCustom,
  ExecuteQuery,
  ExecuteMutationLambda,
} from './Api';

export const listOrganizations = () => {
  return ExecuteQueryCustom('listOrganizationsCustom');
};

export const listUsers = (filter, nextToken, limit) => {
  return ExecuteQuery(
    'listUsersCustom',
    undefined,
    undefined,
    filter,
    nextToken,
    limit
  );
};

export const listUsersCustom = (filter) => {
  return ExecuteQueryCustom('listUsersCustom', undefined, undefined, filter);
};

export const createIdea = (inputData) => {
  return ExecuteMutation('createGammaExtendedCustom', inputData);
};
export const getUsers = (id) => {
  return ExecuteQuery('getUserCustom', undefined, id);
};

export const getIdeas = () => {
  return ExecuteQueryCustom('listGammasCustom');
};

export const deleteUser = (id) => {
  return ExecuteMutation('deleteUser', { id });
};

export const listAllRoles = () => {
  return ExecuteQueryCustom('listUserRolesCustom');
};

export const fetchFuzzySearchGammas = (title) => {
  return ExecuteQuery('fuzzySearchGammasCustom', undefined, title);
};

export const updateUser = (inputData) => {
  return ExecuteMutation('updateUserCustom', inputData);
};

export const searchRelatedItem = () => {
  return ExecuteQuery('fuzzySearchGammas');
};

export const LinkedRelatedGammasCustom = (inputData) => {
  return ExecuteMutation('createLinkedGammasCustom', inputData);
};
export const getLinkIdea = (id) => {
  return ExecuteQuery('getGammaLinkCustom', undefined, id);
};
export const deleteLinkGammaCustom = async (id) => {
  return ExecuteMutation('deleteLinkedGammasCustom', { id });
};
export const createOpportunityCustom = (inputData) => {
  return ExecuteMutation('createGammaExtendedCustom', inputData);
};
export const createContributorsCustom = (inputData) => {
  return ExecuteMutation('createGammasAndContributorsCustom', inputData);
};
export const createAttachmentCustom = (inputData) => {
  return ExecuteMutation('createAttachmentCustom', inputData);
};
export const createUploadfileCustom = (inputData) => {
  return ExecuteMutation('createUploadedFileCustom', inputData);
};
export const getGammaDetailsCustom = (id) => {
  return ExecuteQuery('getGammaCustom', undefined, id);
};
export const getGammaRatingDetailsCustom = (id) => {
  return ExecuteQuery('getGammaRatingCustom', undefined, id);
};

export const updateGammaExtend = (inputData, authToken) => {
  return ExecuteMutationLambda(
    'updateGammaCustom',
    inputData,
    undefined,
    authToken
  );
};
export const createContributors = (inputData, authToken) => {
  return ExecuteMutationLambda(
    'createGammasAndContributorsCustom',
    inputData,
    undefined,
    authToken
  );
};
export const deleteAttachment = (id) => {
  return ExecuteMutation('deleteAttachment', id);
};
export const deleteUploadedFile = (id) => {
  return ExecuteMutation('deleteUploadedFile', id);
};
export const deleteContributors = (id, authToken) => {
  return ExecuteMutationLambda(
    'deleteGammasAndContributorsCustom',
    id,
    undefined,
    authToken
  );
};
export const deleteAttchments = (id) => {
  return ExecuteMutation('deleteAttachment', id);
};

export const deleteUploadFiles = (id) => {
  return ExecuteMutation('deleteUploadedFile', id);
};
export const createHiddenUsersAndHiddenGammasCustom = (inputData) => {
  return ExecuteMutation('createHiddenUsersAndHiddenGammasCustom', inputData);
};
export const deleteHiddenUsersAndHiddenGammasCustom = (id) => {
  return ExecuteMutation('deleteHiddenUsersAndHiddenGammasCustom', id);
};
export const createObjectiveCustom = (inputData) => {
  return ExecuteMutation('createObjectiveCustom', inputData);
};
export const listObjectiveCustom = (params) => {
  return ExecuteQuery('listObjectivesCustom', undefined, params);
};
export const updateObjectiveCustom = (inputData) => {
  return ExecuteMutation('updateObjective', inputData);
};
export const getOrganizationCustom = (id) => {
  return ExecuteQuery('getOrganizationCustom', undefined, id);
};
export const getObjectiveCustom = (id) => {
  return ExecuteQuery('getObjectiveCustom', undefined, id);
};
export const createUserRatingCustom = (inputData) => {
  return ExecuteMutation('createUserRatingCustom', inputData);
};
export const deleteObjectiveCustom = (id) => {
  return ExecuteMutation('deleteObjective', id);
};
export const createPriorityBatchCustom = (inputData) => {
  return ExecuteMutation('createPriorityBatchCustom', inputData);
};
export const createPriorityBatchesAndUsersCustom = (inputData) => {
  return ExecuteMutation('createPriorityBatchesAndUsersCustom', inputData);
};

export const deletePriorityBatchesAndUsersCustom = (id) => {
  return ExecuteMutation('deletePriorityBatchesAndUsersCustom', id);
};
export const updatePriorityBatchCustom = (inputData) => {
  return ExecuteMutation('updatePriorityBatchCustom', inputData);
};
export const deletePriorityBatchCustom = (id) => {
  return ExecuteMutation('deletePriorityBatchCustom', id);
};

export const listDepartmentsCustom = (filter) => {
  return ExecuteQueryCustom(
    'listDepartmentsCustom',
    undefined,
    undefined,
    filter
  );
};
export const createDepartmentGammaCustom = (inputData) => {
  return ExecuteMutation('createDepartmentGammaCustom', inputData);
};
export const deleteDepartmentGammaCustom = (id) => {
  return ExecuteMutation('deleteDepartmentGammaCustom', id);
};

export const objectivesByOwnerIdCustom = (id) => {
  return ExecuteQuery('objectivesByOwnerIdCustom', undefined, id);
};
export const priorityBatchesAndUsersByUserIdCustom = (id) => {
  return ExecuteQuery('priorityBatchesAndUsersByUserIdCustom', undefined, id);
};
export const searchGammasCustom = (filter) => {
  return ExecuteQuery('searchGammasCustom', undefined, undefined, filter);
};

export const searchPriorityBatchesCustom = (filter) => {
  return ExecuteQuery(
    'searchPriorityBatchesCustom',
    undefined,
    undefined,
    filter
  );
};

export const searchUsersCustom = (filter, nextToken, limit, sort) => {
  return ExecuteQuery(
    'seacrhUsersCustom',
    undefined,
    undefined,
    filter,
    nextToken,
    limit,
    sort
  );
};
export const seacrhUsersOrganizationCustom = (nextToken) => {
  return ExecuteQuery(
    'seacrhUsersOrganizationCustom',
    undefined,
    undefined,
    undefined,
    nextToken
  );
};

export const searchGammasCountCustom = (filter) => {
  return ExecuteQuery('searchGammasCountCustom', undefined, undefined, filter);
};
export const searchUserVotesCustom = (filter) => {
  return ExecuteQuery('searchUserVotesCustom', undefined, undefined, filter);
};
export const searchUserRatingsCustom = (filter) => {
  return ExecuteQuery('searchUserRatingsCustom', undefined, undefined, filter);
};

export const notificationByUserCustom = (id) => {
  return ExecuteQuery('notificationByUserCustom', undefined, id);
};

export const updateNotificationCustom = (inputData) => {
  return ExecuteMutation('updateNotificationCustom', inputData);
};

export const getUserWeightSettingCustom = (id) => {
  return ExecuteQuery('getUserWeightSettingCustom', undefined, id);
};

export const getUserCompareSettingCustom = (id) => {
  return ExecuteQuery('getUserCompareSettingCustom', undefined, id);
};

export const getUserRatingSettingCustom = (id) => {
  return ExecuteQuery('getUserRatingSettingCustom', undefined, id);
};

export const updateUserSettingCustom = (inputData) => {
  return ExecuteMutation('updateUserSettingCustom', inputData);
};

export const getDepartmentUserListCustom = (id) => {
  return ExecuteQuery('getDepartmentUserListCustom', undefined, id);
};

export const createDepartmentUserCustom = (inputData) => {
  return ExecuteMutation('createDepartmentUserCustom', inputData);
};

export const updateDepartmentUserCustom = (inputData) => {
  return ExecuteMutation('updateDepartmentUserCustom', inputData);
};
export const usersByOrganizationIDCustom = (filter, id, nextToken) => {
  return ExecuteQuery(
    'usersByOrganizationIDCustom',
    undefined,
    id,
    filter,
    nextToken
  );
};
export const getInviteandInactiveSettingCustom = (id) => {
  return ExecuteQuery('getInviteandInactiveSettingCustom', undefined, id);
};
export const userRatingsByUserIDCustom = (id) => {
  return ExecuteQuery('userRatingsByUserIDCustom', undefined, id);
};
export const userRatingsByUserID = (params) => {
  return ExecuteQuery('userRatingsByUserIDCustom', undefined, params);
};
export const updateGammaNewCustom = (inputData) => {
  return ExecuteMutation('updateGammaCustom', inputData);
};

export const listAllGammaTypeRoles = () => {
  return ExecuteQueryCustom('listAllGammaTypeRolesCustom');
};

export const createLevelChangePromoteCustom = (inputData) => {
  return ExecuteMutation('createLevelChangePromoteCustom', inputData);
};

export const updateGoal = (inputData) => {
  return ExecuteMutation('updateObjectiveCustom', inputData);
};
export const createScoreCalculationStatusCustom = (inputData) => {
  return ExecuteMutation('createScoreCalculationStatusCustom', inputData);
};
export const loginHistoriesByUserIDAndDateCustom = (id) => {
  return ExecuteQuery('loginHistoriesByUserIDAndDateCustom', undefined, id);
};
export const createObjectivesAndDepartmentsCustom = (inputData) => {
  return ExecuteMutation('createObjectivesAndDepartmentsCustom', inputData);
};
export const deleteObjectivesAndDepartmentsCustom = (id) => {
  return ExecuteMutation('deleteObjectivesAndDepartmentsCustom', id);
};

export const measurementFieldsByOrganizationIDCustom = (id, filter) => {
  return ExecuteQuery(
    'measurementFieldsByOrganizationIDCustom',
    undefined,
    id,
    filter
  );
};

export const getMeasurementFieldCustom = (id) => {
  return ExecuteQuery('getMeasurementFieldCustom', undefined, id);
};
export const createMeasurementFieldCustom = (inputData) => {
  return ExecuteMutation('createMeasurementFieldCustom', inputData);
};

export const updateMeasurementFieldCustom = (inputData) => {
  return ExecuteMutation('updateMeasurementFieldCustom', inputData);
};
export const deleteMeasurementFieldCustom = (id) => {
  return ExecuteMutation('deleteMeasurementFieldCustom', id);
};
export const searchMeasurementFieldsCustom = (filter, sort) => {
  return ExecuteQuery(
    'searchMeasurementFieldsCustom',
    undefined,
    undefined,
    filter,
    undefined,
    undefined,
    sort
  );
};

export const deleteUserRatingCustom = (id) => {
  return ExecuteMutation('deleteUserRatingCustom', id);
};

export const listPartnerCustom = (filter, nextToken) => {
  return ExecuteQuery(
    'listPartnerCustom',
    undefined,
    undefined,
    filter,
    nextToken
  );
};

export const searchOrganizationsCustom = (filter, nextToken, sort) => {
  return ExecuteQuery(
    'searchOrganizationsCustom',
    undefined,
    undefined,
    filter,
    nextToken,
    undefined,
    sort
  );
};
