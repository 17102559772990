import React, { memo, useState } from 'react';
import { useSelector } from 'react-redux';

import { message, Modal, Row, Typography } from 'antd';

import { ECHOIMAGES } from 'Assets/Images';
import {
  getCurrentOrganization,
  isAdmin,
  isSuperAdmin,
} from 'store/slices/loginSlice';
import { updateRole } from '../SuperAdminFun';
import { RESTUpdateRole } from 'utils/RESTApi';
import { userStatus } from 'utils/Constants';

const DisableUserModal = ({
  isModalOpen,
  userID,
  getAllUsers,
  getActiveUsersByOrganization,
  setIsModalOpen,
  setInitalFlag,
}) => {
  const { DisableIcon } = ECHOIMAGES.icons;

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [loading, setLoading] = useState(false);

  const currnetOrg = useSelector(getCurrentOrganization);
  const isAdminGroup = useSelector(isAdmin);
  const isSuperAdminGroup = useSelector(isSuperAdmin);

  const handleDeleteUser = async () => {
    setLoading(true);
    try {
      let disableUser = {
        id: userID,
        status: userStatus.disabled,
      };
      let disabledUserRest = {
        user_id: userID,
        events: ['disable'],
      };
      await RESTUpdateRole(disabledUserRest);
      await updateRole(disableUser);
      if (isAdminGroup || currnetOrg?.id) {
        getActiveUsersByOrganization();
      }
      if (isSuperAdminGroup && currnetOrg === null) {
        getAllUsers();
      }

      setLoading(false);
      setIsModalOpen(false);
      message.success('User disabled successfully');
      setInitalFlag(true);
    } catch (error) {
      setLoading(false);
      message.error(error.message);
      throw error;
    }
  };
  return (
    <>
      <div className="modal-filter">
        <Modal
          open={isModalOpen}
          onOk={handleDeleteUser}
          onCancel={handleCancel}
          confirmLoading={loading}
          centered
          okText="Disable"
          className="delete-user-modal "
        >
          <Row justify="center">
            <img src={DisableIcon} alt="" />
          </Row>
          <Row justify="center">
            <Typography.Title level={4}>
              Do you really want to disable this user?
            </Typography.Title>
          </Row>
        </Modal>
      </div>
    </>
  );
};

export default memo(DisableUserModal);

