import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { message, Row, Select, Typography } from 'antd';

import CustomModal from 'Common/CustomModal';
import {
  getCurrentOrganization,
  isAdmin,
  isSuperAdmin,
} from 'store/slices/loginSlice';
import { UserRole } from 'utils/Constants';
import { updateRole } from '../SuperAdminFun';
import { fetchRoles } from 'utils/CommonFunctions';
import { RESTUpdateRole } from 'utils/RESTApi';

const AssignRoleModal = ({
  setIsRoleModalOpen,
  isRoleModalOpen,
  getAllUsers,
  getActiveUsersByOrganization,
  userDataID,
  selectdRole,
  setSelectedRole,
}) => {
  const currnetOrg = useSelector(getCurrentOrganization);
  const isAdminGroup = useSelector(isAdmin);
  const isSuperAdminGroup = useSelector(isSuperAdmin);

  const [roleLoader, setRoleLoader] = useState(false);
  const [allRoles, setAllRoles] = useState([]);

  const handleRoleModalCancel = () => {
    setIsRoleModalOpen(false);
  };

  const handleGetRoles = async () => {
    const rolesArray = await fetchRoles(isAdminGroup, currnetOrg);
    setAllRoles(rolesArray);
  };

  const AssignRole = async () => {
    setRoleLoader(true);
    try {
      const updateRoleRest = {
        user_id: userDataID?.id,
        old_role: userDataID.role,
        new_role: selectdRole,
        events: ['role-change'],
      };
      const updateRoleApi = {
        id: userDataID?.id,
        role: selectdRole,
      };
      await updateRole(updateRoleApi);
      await RESTUpdateRole(updateRoleRest);
      setRoleLoader(false);
      message.success('Role Assigned Successfully');
      setIsRoleModalOpen(false);

      if (isAdminGroup || currnetOrg?.id) {
        getActiveUsersByOrganization();
      }
      if (isSuperAdminGroup && currnetOrg === null) {
        getAllUsers();
      }
    } catch (error) {
      setRoleLoader(false);
      throw error;
    }
  };

  useEffect(() => {
    handleGetRoles();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <CustomModal
        visible={isRoleModalOpen}
        onCancel={handleRoleModalCancel}
        onSubmit={AssignRole}
        buttonLoader={roleLoader}
        centered
      >
        <Row justify="center">
          <Typography.Title level={4}>Assign Role</Typography.Title>
        </Row>
        <Row justify="center">
          <Select
            style={{
              width: 200,
            }}
            value={selectdRole}
            onChange={(e) => setSelectedRole(e)}
          >
            {allRoles?.map((item, index) => {
              const roleName =
                item.name === UserRole.sme ? UserRole?.teamMember : item.name;
              return (
                <Select.Option key={index} value={item?.name}>
                  {roleName}
                </Select.Option>
              );
            })}
          </Select>
        </Row>
      </CustomModal>
    </>
  );
};

export default memo(AssignRoleModal);

