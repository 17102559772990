import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Button, Row, Input, message, Grid } from 'antd';
import '../AllPriorities/idea.less';
import {
  getCurrentOrganization,
  getCurrnetUserData,
} from '../../store/slices/loginSlice';
import {
  debounceFn,
  searchTextInArrayOfObject,
} from '../../utils/commonMethods';
import ScenarioSorting from './components/ScenarioSorting';
import ScenarioFilter from './components/ScenarioFilter';
import ScenarioTable from './components/ScenarioTable';
import { getOrganizationCustom } from 'utils/Actions';
import FilterButton from 'Common/FilterButton';
import { RESTGetAuthorization } from 'utils/RESTApi';

export default function PriorityBatches() {
  const history = useHistory();

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const { Search } = Input;
  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;
  const userId = currnetOrg?.adminID || loginUsrOrg?.id;
  const fromMyPriorities = history.location.state?.myPriorities;

  const [listPriorityBatch, setListPriorityBatch] = useState([]);
  const [listPriorityBatchBackup, setListPriorityBatchBackup] = useState([]);
  const [priorityOwner, setPriorityOwner] = useState([]);
  const [defaultPriorityBatch, setDefaultPriorityBatch] = useState();
  const [orgDefaultScenario, setOrgDefaultScenario] = useState();
  const [loading, setLoading] = useState(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState([orgDefaultScenario]);
  // drawer stuff
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };

  const getOrganizationPriorityBatch = async () => {
    try {
      let res = await getOrganizationCustom({ id: orgId });
      let { organizationDefaultPrioirtybatchId } = res;
      setOrgDefaultScenario(organizationDefaultPrioirtybatchId);
      setSelectedRowKeys([organizationDefaultPrioirtybatchId]);
      await listAddedPriorityBatch();
    } catch (err) {
      console.log(err);
    }
  };

  const listAddedPriorityBatch = async () => {
    try {
      const temp = [];
      const tempData = [];
      let api = `/scenarios?organizationID=${orgId}&userID=${userId}`;
      let res = await RESTGetAuthorization(api);
      let usersPriorityBatch;
      if (fromMyPriorities) {
        res?.body?.items.forEach((item) => {
          const data = item?.owners?.filter((el) => el?.id === userId)?.length;
          if (data) {
            temp.push(item);
          }
        });
        usersPriorityBatch = temp;
      } else {
        usersPriorityBatch = res?.body?.items.map((e) => {
          return {
            ...e,
            key: e?.id,
          };
        });
      }
      let getDefaultBatchData = res?.body?.items.find(
        (val) => val.id === orgDefaultScenario
      );

      res?.body?.items?.forEach((element) => {
        const tempArray = [];
        element?.owners.forEach((owner) => {
          tempArray.push(owner?.id);
        });
        tempData.push(tempArray);
      });
      setPriorityOwner(tempData);
      if (res?.body?.items.length === 1 && getDefaultBatchData) {
        setDefaultPriorityBatch(getDefaultBatchData);
      }
      const newItems = [...usersPriorityBatch];
      // Find the index of the column in the new array
      const priorityIndex = newItems.findIndex(
        (column) => column.id === orgDefaultScenario
      );

      // Remove the id column from its current position in the array
      const newColumn = newItems.splice(priorityIndex, 1)[0];
      // Add the id column back to the beginning of the array
      newItems.unshift(newColumn);
      setListPriorityBatch(newItems);
      setListPriorityBatchBackup(newItems);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error(error.message);
    }
  };

  const onSearch = (searchText) => {
    if (searchText !== '') {
      const keysToSearch = ['title'];
      const returnFilterData = searchTextInArrayOfObject(
        searchText,
        keysToSearch,
        listPriorityBatchBackup
      );
      setListPriorityBatch(returnFilterData);
    } else {
      setListPriorityBatch(listPriorityBatchBackup);
    }
  };

  const debouncedHandleInput = debounceFn(onSearch, 500);

  const handleInputSearch = (searchText) => {
    debouncedHandleInput(searchText);
  };

  useEffect(() => {
    getOrganizationPriorityBatch();
    // eslint-disable-next-line
  }, [orgDefaultScenario]);

  const createScenario = (
    <>
      Creating a new scenario lets you select specific goals to focus on and
      then weight them based on how important you think they are. (Assumption -
      not all goals are created equal).
      <br /> TIP - don't want to start from scratch? You can "Duplicate" an
      existing Scenario and add / subtract goals or change the weights!{' '}
      <a
        href="https://stralign.com/help-create-scenarios"
        target="_blank"
        rel="noopener noreferrer"
      >
        Click here for a video overview.
      </a>
    </>
  );

  const compareScenario = (
    <>
      Not sure which scenario to use in Scenario Planning? Compare different
      scenarios to see which goals are selected and how they are weighted!
      <br /> TIP - did you know you can "duplicate" a scenario if you want to
      tweak it just a little bit?{' '}
    </>
  );

  return (
    <div className="idea overflowX-hidden pt-0 plr-0">
      <>
        <div className="background-white p-30">
          <Row
            justify="space-between"
            align="middle"
            className="idea-header w-100"
          >
            <Search
              className="searchbar-style mb-10"
              placeholder="Search by keyword"
              onChange={(e) => handleInputSearch(e.target.value)}
            />

            <Row gutter={[0, 10]} className="mb-10" align="middle">
              {selectedRowKeys?.length > 1 ? (
                <Button
                  className="ml-10"
                  type="primary"
                  onClick={() =>
                    history.push({
                      pathname: '/compare-scenarios',
                      state: selectedRowKeys,
                    })
                  }
                >
                  Compare Scenario
                </Button>
              ) : (
                <>
                  <ScenarioSorting
                    listPriorityBatch={listPriorityBatch}
                    setListPriorityBatch={setListPriorityBatch}
                    listPriorityBatchBackup={listPriorityBatchBackup}
                    listAddedPriorityBatch={listAddedPriorityBatch}
                  />
                  <FilterButton onClick={showDrawer} />
                  <Button
                    className="ml-10"
                    type="primary"
                    onClick={() => history.push('/add-scenario')}
                  >
                    Create Scenario
                  </Button>
                </>
              )}
            </Row>
          </Row>
          <ScenarioTable
            listPriorityBatch={listPriorityBatch}
            priorityOwner={priorityOwner}
            listAddedPriorityBatch={listAddedPriorityBatch}
            loading={loading}
            orgDefaultScenario={orgDefaultScenario}
            getOrganizationPriorityBatch={getOrganizationPriorityBatch}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
          />
        </div>
        <ScenarioFilter
          listPriorityBatchBackup={listPriorityBatchBackup}
          setListPriorityBatch={setListPriorityBatch}
          setOpen={setOpen}
          open={open}
          userId={userId}
        />
      </>
    </div>
  );
}

