import React, { useEffect } from 'react';
import lottie from 'lottie-web';
import animationData from './animation3.json';

const MileStoneAnimation = () => {
  useEffect(() => {
    const container = document.getElementById('kudos-lottie-container');
    const anim = lottie.loadAnimation({
      container: container,
      renderer: 'svg',
      loop: false,
      autoplay: true,
      animationData: animationData,
    });

    return () => {
      anim.destroy();
    };
  }, []);

  return <div id="kudos-lottie-container"></div>;
};

export default MileStoneAnimation;

