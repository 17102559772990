import React, { useEffect, useState } from 'react';
import { Col, Row, Typography, Form, Input, Button, message } from 'antd';
import { ECHOIMAGES } from '../../Assets/Images';
import OtpInput from 'react-otp-input';
import '../common.less';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';

function ResetPassword(props) {
  const { forgotPassword, Logo, Background, AuthLogo } = ECHOIMAGES.Auth;
  const [form] = Form.useForm();
  const [email, setemail] = useState(
    props.location?.state ? props.location?.state?.userName : ''
  );
  const history = useHistory();
  const [otp, setOtp] = useState();
  const [loading, setloading] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);

  // Function to handle resend code
  const resendConfirmationCode = async () => {
    setResetLoading(true);
    try {
      await Auth.forgotPassword(email).then(() => {
        message.success('Your resend code has been sent');
        setResetLoading(false);
      });
    } catch (error) {
      message.error(error.message);
      setResetLoading(false);
    }
  };

  // Function to confirmation code submit
  const confirmReset = async (e) => {
    setloading(true);
    try {
      await Auth.forgotPasswordSubmit(email, e.code, e.password).then(() => {
        setloading(false);
        history.push({
          pathname: '/login',
          state: { feedback: 'Signin with new password.', userName: '' },
        });
      });
    } catch (error) {
      setloading(false);
      message.error(error.message);
    }
  };

  const handleChange = (otp) => {
    setOtp(otp);
  };

  useEffect(() => {
    if (!props.location.state) {
      if (
        window.location.href.includes('confirmationcode=') &&
        window.location.href.includes('/reset-password') &&
        window.location.href.includes('email=')
      ) {
        const Data = {};
        Data.code = window.location.href
          .split('confirmationcode=')[1]
          .split('&email=')[0];
        form.setFieldsValue({
          code: Data.code,
          password: '',
        });
        setemail(window.location.href.split('email=')[1]);
      } else {
        history.push('/');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Row className="login forgot-password">
        <Col
          span={12}
          lg={12}
          md={0}
          xs={0}
          sm={0}
          style={{ backgroundImage: `url(${Background})` }}
        >
          <div className="login-left">
            <Typography.Title level={1}>Show your ideas</Typography.Title>
            <Typography.Title level={4}>
              Share your ideas and track the impact you're having
            </Typography.Title>
            <img src={forgotPassword} alt="" />
          </div>
        </Col>

        <Col span={12} lg={12} md={24} xs={24} sm={24} className="login-form">
          <div className="wrapper">
            <img src={AuthLogo} alt="" />

            <Typography.Title level={3}>Forgot your password?</Typography.Title>
            <Typography.Title level={5}>
              No worries. We'll send reset instructions to your email.
            </Typography.Title>

            <Form
              layout="vertical"
              requiredMark={false}
              form={form}
              onFinish={(e) => confirmReset(e)}
            >
              <Form.Item
                className="otp"
                name="code"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your verification code.',
                  },
                ]}
              >
                <OtpInput
                  name="code"
                  shouldAutoFocus
                  className="signup otp-input"
                  id="code"
                  key="code"
                  numInputs={6}
                  value={otp}
                  onChange={handleChange}
                  separator={<span style={{ color: 'white' }}> ---</span>}
                />
              </Form.Item>
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your password',
                  },
                  {
                    min: 8,
                    message: 'Please enter more than 8 characters',
                  },
                ]}
              >
                <Input.Password className="input" />
              </Form.Item>
              <Form.Item>
                <Row gutter={10}>
                  <Col span={12}>
                    <Button
                      type="primary"
                      className="primary-btn"
                      onClick={resendConfirmationCode}
                      loading={resetLoading}
                    >
                      Resend Code
                    </Button>
                  </Col>
                  <Col span={12}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="primary-btn"
                      loading={loading}
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form.Item>

              <Typography.Title level={5} className="signup-text">
                {/* <Link to="/login">Back to login</Link> */}
              </Typography.Title>
            </Form>
          </div>
          <Typography.Title level={5} className="footer">
            © {new Date().getFullYear()} Echo Consulting, LLC
          </Typography.Title>
        </Col>
      </Row>
    </>
  );
}

export default ResetPassword;
