import { message, Modal, Row, Typography } from 'antd';
import React, { memo, useState } from 'react';
import { updateOrganization } from '../services/SuperAdminActions';

const PromotePartnerModal = ({
  setIsModalOpen,
  isModalOpen,
  orgDetail,
  fetchOrganizations,
  setCurrentPage,
}) => {
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setIsModalOpen(false);
  };

  //function to promote org to a partner
  const promoteToPartner = async () => {
    try {
      setLoading(true);
      let inputData = {
        id: orgDetail?.id,
        type: 'PARTNER',
      };
      await updateOrganization(inputData);
      setLoading(false);
      setIsModalOpen(false);
      setTimeout(async () => {
        message.success('Organization promote to a partner successfully');
        await fetchOrganizations(null, null, '', 1);
      }, 1000);
      setCurrentPage(1);
    } catch (err) {
      setLoading(false);
      message.error(err);
    }
  };

  return (
    <Modal
      open={isModalOpen}
      onCancel={handleClose}
      centered
      className="delete-user-modal"
      okText="Promote"
      cancelText="Cancel"
      onOk={promoteToPartner}
      confirmLoading={loading}
    >
      <div>
        <Row justify="center">
          <Typography.Title level={4} className="fw-500 text-center">
            Are you sure you want to promote{' '}
            <span className="text-primary">{orgDetail?.name} </span>
            <br /> to a partner?
          </Typography.Title>
        </Row>
      </div>
    </Modal>
  );
};

export default memo(PromotePartnerModal);

