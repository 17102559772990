import { message } from 'antd';
import {
  createOpportunityCustom,
  createAttachmentCustom,
  createUploadfileCustom,
  getGammaDetailsCustom,
  updateGammaExtend,
  deleteAttachment,
  deleteUploadedFile,
  deleteContributors,
  deleteAttchments,
  deleteUploadFiles,
  createContributors,
  getGammaRatingDetailsCustom,
} from '../../utils/Actions';

export const createOpportunity = async (data) => {
  try {
    const addIdea = await createOpportunityCustom(data);
    return addIdea;
  } catch (error) {
    message.error(error.message);
  }
};

export const createContributor = async (input, authToken) => {
  try {
    const addCont = await createContributors(input, authToken);
    return addCont;
  } catch (error) {
    message.error(error.message);
  }
};

export const createAttachmentLink = async (input) => {
  try {
    const addLink = await createAttachmentCustom(input);
    return addLink;
  } catch (error) {
    message.error(error.message);
  }
};
export const createUploadFile = async (input) => {
  try {
    const addLink = await createUploadfileCustom(input);
    return addLink;
  } catch (error) {
    message.error(error.message);
  }
};
export const getGammas = async (id) => {
  try {
    const getGammaDetail = await getGammaDetailsCustom(id);
    return getGammaDetail;
  } catch (error) {
    message.error(error.message);
  }
};
export const getGammaRatingDetail = async (id) => {
  try {
    const getGammaRatingDetail = await getGammaRatingDetailsCustom(id);
    return getGammaRatingDetail;
  } catch (error) {
    message.error(error.message);
  }
};

export const updateGamma = async (data, authToken) => {
  try {
    const updateData = await updateGammaExtend(data, authToken);
    return updateData;
  } catch (error) {
    message.error(error.message);
  }
};
export const updateIdea = async (data, authToken) => {
  try {
    const updateData = await updateGamma(data, authToken);
    return updateData;
  } catch (error) {
    message.error(error.message);
  }
};
export const deleteAddAttachement = async (id) => {
  try {
    const deleteLinks = await deleteAttachment(id);
    return deleteLinks;
  } catch (error) {
    message.error(error.message);
  }
};
export const deleteAddedfiles = async (id) => {
  try {
    const deleteFiles = await deleteUploadedFile(id);
    return deleteFiles;
  } catch (error) {
    message.error(error.message);
  }
};

export const deleteGamaContributors = async (id, authToken) => {
  try {
    const deleteGamaContributor = await deleteContributors(id, authToken);
    return deleteGamaContributor;
  } catch (error) {
    message.error(error.message);
  }
};
export const deleteGamaAttchements = async (id) => {
  try {
    const deleteGamaLinks = await deleteAttchments(id);
    return deleteGamaLinks;
  } catch (error) {
    message.error(error.message);
  }
};
export const deleteGammaFiles = async (id) => {
  try {
    const deleteGamaFiles = await deleteUploadFiles(id);
    return deleteGamaFiles;
  } catch (error) {
    message.error(error.message);
  }
};

export const updateGammaNewCustom = async (input) => {
  try {
    const updateData = await updateGammaNewCustom(input);
    return updateData;
  } catch (error) {
    message.error(error.message);
  }
};
