import { Button, Col, Input, message, Row, Typography } from 'antd';
import React, { memo, useCallback, useEffect, useState } from 'react';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { useSelector, useDispatch } from 'react-redux';
import { setBrandingForm } from 'store/slices/brandingSlice';
import {
  getCurrentOrganization,
  getCurrnetUserData,
} from 'store/slices/loginSlice';
import { RESTAddNewOrg, RESTAddNewPartner } from 'utils/RESTApi';

const CompanyDetailForm = ({
  setEditForm,
  orgDetails,
  fetchOrgDetailsByID,
}) => {
  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);

  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;
  const userId = currnetOrg?.adminID || loginUsrOrg?.id;

  const { TextArea } = Input;
  const [organizationData, setOrganizationData] = useState({
    name: '',
    website: '',
    contactNumber: '',
    street: '',
    zipCode: '',
    city: '',
    state: '',
    country: '',
    id: '',
  });
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e;
    setOrganizationData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === 'website') {
      if (
        !/^(https?:\/\/)?((([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})|localhost)(:\d{2,5})?(\/[^\s]*)?$/.test(
          value
        )
      ) {
        formErrors.website = 'Invalid website format';
      } else {
        delete formErrors.website;
      }
    }
    if (value.length > 0) {
      dispatch(setBrandingForm(true));
    } else {
      dispatch(setBrandingForm(false));
    }
  };

  const handlePhoneChange = (value) => {
    setOrganizationData({ ...organizationData, contactNumber: value });
    if (value && !isValidPhoneNumber(value)) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        contactNumber: 'Invalid contact number',
      }));
    } else {
      setFormErrors((prevErrors) => {
        const { contactNumber, ...others } = prevErrors;
        return others;
      });
    }
  };

  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const fetchLocationData = async (zip) => {
    if (zip.length > 1) {
      try {
        const response = await fetch(
          `https://secure.geonames.org/postalCodeLookupJSON?postalcode=${zip}&username=organization_country`
        );
        if (!response.ok) {
          throw new Error('Invalid ZIP code or data not available.');
        }
        const data = await response.json();
        if (data.postalcodes && data.postalcodes.length > 0) {
          const locationData = data.postalcodes[0];
          setOrganizationData((prevData) => ({
            ...prevData,
            city: locationData.adminName2 || '',
            state: locationData.adminName1 || '',
            country: locationData.countryCode || '',
          }));

          setError('');
        } else {
          setError('No location data found.');
        }
      } catch (err) {
        setError(err.message);
      }
    }
  };
  // eslint-disable-next-line
  const debouncedFetchLocationData = useCallback(
    debounce(fetchLocationData, 500),
    []
  );

  const handleZipCodeChange = (e) => {
    const zip = e.target.value;
    setOrganizationData((prevData) => ({ ...prevData, zipCode: zip }));
    if (zip.trim() !== '') {
      debouncedFetchLocationData(zip);
    } else {
      setError('Please enter a ZIP code.');
      setOrganizationData((prevData) => ({
        ...prevData,
        city: '',
        state: '',
        country: '',
      }));
    }
  };

  useEffect(() => {
    if (orgDetails) {
      setOrganizationData({
        name: orgDetails?.organization?.name,
        website: orgDetails?.organization?.website,
        contactNumber: orgDetails?.organization?.contact,
        street: orgDetails?.organization?.address?.street,
        zipCode: orgDetails?.organization?.address?.zip,
        city: orgDetails?.organization?.address?.city,
        state: orgDetails?.organization?.address?.state,
        country: orgDetails?.organization?.address?.country,
      });
    }
  }, [orgDetails]);

  const validateForm = () => {
    const errors = {};
    const requiredFields = [
      'name',
      'website',
      'contactNumber',
      'street',
      'zipCode',
      'city',
      'state',
      'country',
    ];

    requiredFields.forEach((field) => {
      if (!organizationData[field]) {
        errors[field] = 'This field is required';
      }
    });

    if (
      organizationData.website &&
      !/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/.test(organizationData.website)
    ) {
      errors.website = 'Invalid website format';
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSumbit = async () => {
    if (!validateForm()) {
      message.error('Please fill all required fields.');
      return;
    }
    try {
      setLoading(true);

      let inputData = {
        organization_id: orgId,
        user_id: userId,
        organization: {
          id: orgId,
          name: organizationData.name,
          website: organizationData.website,
          contact: organizationData.contactNumber,
          address: {
            street: organizationData.street,
            city: organizationData.city,
            zip: organizationData.zipCode,
            state: organizationData.state,
            country: organizationData.country,
          },
        },
        admin: {
          id: orgDetails?.admin?.id,
          firstName: orgDetails?.admin?.firstName,
          lastName: orgDetails?.admin?.lastName,
          contact: orgDetails?.admin?.adminContactNumber,
          email: orgDetails?.admin?.email,
        },
        branding: {
          dark_background_logo: orgDetails?.branding?.dark_background_logo,
          light_background_logo: orgDetails?.branding?.light_background_logo,
          primary_color: orgDetails?.branding?.primary_color,
          secondary_color: orgDetails?.branding?.secondary_color,
          navigation_color: orgDetails?.branding?.navigation_color,
          login_page_illustration:
            orgDetails?.branding?.login_page_illustration,
        },
      };
      await RESTAddNewOrg(inputData);
      await fetchOrgDetailsByID();
      message.success('Company details updated added successfully');
      setEditForm(false);
      setLoading(false);
      dispatch(setBrandingForm(false));
    } catch (err) {
      setLoading(false);
    }
  };
  return (
    <>
      <Row justify="space-between">
        <Typography.Title level={4} className="fw-500">
          Company Details
        </Typography.Title>
        <div>
          <Button onClick={() => setEditForm(false)}>Cancel</Button>
          <Button
            type="primary"
            className="ml-10"
            loading={loading}
            onClick={handleSumbit}
          >
            Save
          </Button>
        </div>
      </Row>
      <Row>
        <Col span={15}>
          <Input
            className="title-scale fs-24"
            value={organizationData.name}
            onChange={(e) => handleChange(e.target)}
            name="name"
          />
          {formErrors.name && (
            <span style={{ color: 'red' }}>{formErrors.name}</span>
          )}
        </Col>
      </Row>
      <Row className="mt-20">
        <Col span={8}>
          <span>
            <span className="red-color">*</span> Website
          </span>
          <Input
            className="mt-5"
            name="website"
            value={organizationData.website}
            onChange={(e) => handleChange(e.target)}
          />
          {formErrors.website && (
            <span style={{ color: 'red' }}>{formErrors.website}</span>
          )}
        </Col>
        <Col span={8} className="ml-20">
          <span>
            <span className="red-color">*</span> Contact number
          </span>
          <PhoneInput
            value={organizationData.contactNumber}
            className="mt-5"
            onChange={handlePhoneChange}
          />
          {formErrors.contactNumber && (
            <div className="red-color">{formErrors.contactNumber}</div>
          )}
        </Col>
      </Row>
      <Typography.Title level={5} className="mb-10 mt-20">
        Address Information
      </Typography.Title>
      <Row>
        <Col span={8}>
          <span>
            {' '}
            <span className="red-color">*</span>Street
          </span>

          <TextArea
            rows={2}
            className="mt-5"
            name="street"
            value={organizationData.street}
            onChange={(e) => handleChange(e.target)}
          />
          {formErrors.street && (
            <span style={{ color: 'red' }}>{formErrors.street}</span>
          )}
        </Col>
      </Row>

      <Col span={8} className="mt-20">
        <Row justify="space-between">
          <Col span={10}>
            <span>
              {' '}
              <span className="red-color">*</span>Zip/Postal code
            </span>

            <Input
              className="mt-5"
              value={organizationData.zipCode}
              onChange={handleZipCodeChange}
            />
            {formErrors.zipCode && (
              <span style={{ color: 'red' }}>{formErrors.zipCode}</span>
            )}
          </Col>

          <Col span={10}>
            <span>
              {' '}
              <span className="red-color">*</span>City
            </span>

            <Input className="mt-5" value={organizationData.city} readOnly />
            {formErrors.city && (
              <span style={{ color: 'red' }}>{formErrors.city}</span>
            )}
          </Col>
        </Row>
      </Col>
      <Col span={8} className="mt-20">
        <Row justify="space-between">
          <Col span={10}>
            <span>
              {' '}
              <span className="red-color">*</span>State
            </span>

            <Input className="mt-5" value={organizationData.state} readOnly />
            {formErrors.state && (
              <span style={{ color: 'red' }}>{formErrors.state}</span>
            )}
          </Col>
          <Col span={10}>
            <span>
              {' '}
              <span className="red-color">*</span>Country
            </span>

            <Input className="mt-5" value={organizationData.country} readOnly />
            {formErrors.country && (
              <span style={{ color: 'red' }}>{formErrors.country}</span>
            )}
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default memo(CompanyDetailForm);

