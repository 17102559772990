import {
  Col,
  Row,
  Space,
  Typography,
  Tag,
  Empty,
  Popover,
  Button,
  Divider,
  message,
  Grid,
  Drawer,
  Input,
  Slider,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { arrToCommaSeperated } from 'utils/commonMethods';
import {
  deleteLink,
  fuzzySearchGammas,
  getLinkedIdeas,
  linkAddIdea,
} from './IdeaFunction';
import './opportunity.less';
import './idea.less';
import { levelColors } from 'utils/Constants';
import {
  getCurrentOrganization,
  getCurrnetUserData,
} from 'store/slices/loginSlice';
import { listGammas } from '../Dashboard/DashboardFunctions';
import { orderBy } from 'lodash';
import { useSelector } from 'react-redux';
import { ECHOIMAGES } from 'Assets/Images';

export default function OpportunityView({
  formLayoutByGamma,
  listAllDepartments,
  userData,
  scaleValue,
  setScaleValue,
}) {
  const { Paragraph } = Typography;
  const { Search } = Input;
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const [linkedGamma, setLinkedGamma] = useState([]);
  const [dashboardData, setDashboardData] = useState([]);
  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const [backeupData, setBackeupData] = useState([]);
  const {
    red,
    yellow,
    green,
    blue,
    gray,
    greenSymbol,
    triangle,
    stopIcon,
    rightIcon,
    exclamationMark,
    crossIcon,
    redExclamationMark,
    minusIcon,
    downArrowIcon,
    circle1,
    circle2,
    circle3,
    circle4,
    circle5,
    analytics1,
    analytics2,
    analytics3,
    analytics4,
    analytics5,
    cloudy,
    sunny,
    partlySunny,
    rainy,
    stromy,
    advanced,
    easy,
    intermediate,
    exportsOnly,
    noPain,
    mild,
    moderate,
    severe,
    verySevere,
    extreme,
    empty,
    one,
    two,
    three,
    four,
    five,
    emptyProgress,
    quarter,
    half,
    threeQuarter,
    full,
    up,
    down,
    unChanged,
    emptyHuman,
    oneHuman,
    TwoHuman,
    threeHuman,
    fourHuman,
    fiveHuman,
    downFour,
    angleDown,
    angleUp,
    upFour,
    downFive,
    angleDownFive,
    angleUpFive,
    upFive,
    sideWays,
    emptyDollar,
    oneDollar,
    twoDollar,
    threeDollar,
    fourDollar,
    fiveDollar,
    emptyHeart,
    oneHeart,
    twoHeart,
    threeHeart,
    fourHeart,
    fiveHeart,
    downThree,
    sideWayThree,
    upThree,
    downBlack,
    rightBlack,
    upBlack,
    leftBlack,
  } = ECHOIMAGES.rankings;
  const fourIconsObject = {
    Red: red,
    Yellow: yellow,
    Blue: blue,
    Green: green,
    Gray: gray,
  };

  const shapeIcon = {
    Yes: greenSymbol,
    Hold: triangle,
    No: stopIcon,
  };

  const rightCrossIcon = {
    Yes: rightIcon,
    Hold: exclamationMark,
    No: crossIcon,
  };

  const arrowMinusIcon = {
    High: redExclamationMark,
    Meduim: minusIcon,
    Low: downArrowIcon,
  };

  const pieChartIcon = {
    Empty: circle1,
    Quarter: circle2,
    Half: circle3,
    'Three Quarter': circle4,
    Full: circle5,
  };

  const analytics = {
    Empty: analytics1,
    Quarter: analytics2,
    Half: analytics3,
    'Three Quarter': analytics4,
    Full: analytics5,
  };

  const cloudIcons = {
    Cloudy: cloudy,
    Sunny: sunny,
    'Partly Sunny': partlySunny,
    Rainy: rainy,
    Stromy: stromy,
  };

  const circleSquareIcon = {
    Advanced: advanced,
    Easy: easy,
    Intermediate: intermediate,
    'Exports Only': exportsOnly,
  };

  const expressionIcon = {
    'No Pain': noPain,
    Mild: mild,
    Moderate: moderate,
    Severe: severe,
    'Very Severe': verySevere,
    Extreme: extreme,
  };

  const ratingsIcon = {
    Empty: empty,
    One: one,
    Two: two,
    Three: three,
    Four: four,
    Five: five,
  };

  const progressBarIcon = {
    Empty: emptyProgress,
    Quarter: quarter,
    Half: half,
    'Three Quarter': threeQuarter,
    Full: full,
  };

  const upDownIcon = {
    Up: up,
    Down: down,
    Unchanged: unChanged,
  };

  const fourArrowIcon = {
    Down: downFour,
    'Angle Down': angleDown,
    'Angle Up': angleUp,
    Up: upFour,
  };

  const fiveArrowIcon = {
    Down: downFive,
    'Angle Down': angleDownFive,
    'Angle Up': angleUpFive,
    Up: upFive,
    Sideways: sideWays,
  };

  const humanIcon = {
    Empty: emptyHuman,
    One: oneHuman,
    Two: TwoHuman,
    Three: threeHuman,
    Four: fourHuman,
    Five: fiveHuman,
  };

  const dollarIcon = {
    Empty: emptyDollar,
    One: oneDollar,
    Two: twoDollar,
    Three: threeDollar,
    Four: fourDollar,
    Five: fiveDollar,
  };

  const heartIcon = {
    Empty: emptyHeart,
    One: oneHeart,
    Two: twoHeart,
    Three: threeHeart,
    Four: fourHeart,
    Five: fiveHeart,
  };

  const threeArrowIcon = {
    Down: downThree,
    Sideways: sideWayThree,
    Up: upThree,
  };

  const fourBlackArrowIcon = {
    Down: downBlack,
    Right: rightBlack,
    Up: upBlack,
    Left: leftBlack,
  };

  const [relatedIdeaIds, setRelatedIdeaIds] = useState([]);
  const listRelatedIdea = async () => {
    try {
      const res = await getLinkedIdeas({ id: formLayoutByGamma?.id });
      setLinkedGamma(res?.data?.getGamma?.linkedTo?.items);
      setRelatedIdeaIds(
        res?.data?.getGamma?.linkedTo?.items.map(
          (item) => item?.linkedGamma?.id
        )
      );
    } catch (error) {
      console.log(error);
    }
  };
  const { id } = useParams();
  // to link gamma
  const linkIdeas = async (objid) => {
    try {
      const inputData = {
        gammaLinkedToId: id,
        linkedGammasLinkedGammaId: objid,
      };
      await linkAddIdea(inputData);
      await listRelatedIdea();
      message.success('Items linked successfully');
      setOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  const unLinkIdeas = async (id) => {
    try {
      await deleteLink(id);
      message.success('Item unlink successfully');
      await listRelatedIdea();
    } catch (error) {
      console.log(error);
    }
  };

  const showDropDownItems = (item) => {
    if (item?.label === 'Contributors') {
      let extractDropdown = arrToCommaSeperated(
        item?.value?.items?.map(
          (item) => item?.firstName + ' ' + item?.lastName
        )
      );
      return extractDropdown;
    } else if (item?.id === process.env.REACT_APP_SPONSOR_ID) {
      let sponsorDropdown =
        item?.value && item?.value?.firstName + ' ' + item?.value?.lastName;

      return sponsorDropdown;
    } else if (item?.id === process.env.REACT_APP_DEPARTMENT_ID) {
      let extractDropdown = arrToCommaSeperated(
        item?.value?.items?.map((item) => item?.department?.name)
      );
      return extractDropdown;
    } else {
      //for additional dropdown of department
      const targetIDs = item?.value ? item?.value?.map((e) => e?.text) : [];
      const filteredDepartments = listAllDepartments?.filter((department) =>
        targetIDs?.includes(department.id)
      );

      const departmentNames = filteredDepartments?.map(
        (department) => department.name
      );

      //for additional dropdown of users
      const userIDs = item?.value ? item?.value?.map((item) => item?.text) : [];
      const filteredUsers = userData?.filter((users) =>
        userIDs?.includes(users.id)
      );
      const userNames = filteredUsers?.map(
        (users) => users?.firstName + ' ' + users?.lastName
      );

      if (departmentNames?.length) {
        return arrToCommaSeperated(departmentNames?.map((item) => item));
      } else if (userNames?.length) {
        return arrToCommaSeperated(userNames?.map((item) => item));
      } else {
        let customDropdown = arrToCommaSeperated(
          item?.value?.map((item) => item?.text)
        );
        return customDropdown;
      }
    }
  };

  // to fetch gammas by org

  const fetchGammas = () => {
    listGammas({ organizationID: orgId })
      .then((gammas) => {
        const temp = [];
        gammas?.items?.forEach((item) => {
          temp.push({
            ...item,
          });
        });
        setDashboardData(orderBy(temp));
        setBackeupData(orderBy(temp));
      })
      .catch((err) => console.error(err));
  };

  const fetchFezzySearch = async (e) => {
    const data = await fuzzySearchGammas({
      title: e,
      organizationID: orgId,
    });
    if (data?.items?.length > 0) {
      setDashboardData(data?.items);
    } else {
      setDashboardData(backeupData);
    }
  };

  useEffect(() => {
    listRelatedIdea();
    fetchGammas();
    // eslint-disable-next-line
  }, []);
  const ids = formLayoutByGamma?.layout?.map((item) => item.id);
  const filteredData =
    formLayoutByGamma?.level?.measurementFieldConnection?.items?.filter(
      (item) =>
        item?.measurementField !== null &&
        ids?.includes(item?.measurementField?.id)
    );

  const mappedData = filteredData?.map((item) => item?.measurementField);

  const [sliderColor, SetSliderColor] = useState({});

  useEffect(() => {
    // eslint-disable-next-line

    mappedData?.map((item, index) => {
      const Slider = document.querySelector(`.opportunity-slider-${index}`);
      const SliderStyles = Slider?.style;

      let value = 0;
      if (item?.subType === '-+') {
        value = Math.ceil(((scaleValue[item.id] + 2) * 100) / 4);
      } else if (item?.subType === '+-') {
        value = Math.ceil(-(((scaleValue[item.id] - 2) * 100) / 4));
      }

      if (item?.subType === '-+') {
        if (value >= 0 && value < 20) {
          SliderStyles?.setProperty(
            '--OuterDotColor',
            `linear-gradient(180deg, #FC5957 0%, #F93432 100%)`
          );
          SliderStyles?.setProperty(
            '--InnerDotColor',
            `linear-gradient(180deg, #C60807 0%, #ED2623 22.67%, #FF7171 100%)`
          );
        } else if (value >= 20 && value < 40) {
          SliderStyles?.setProperty(
            '--OuterDotColor',
            `linear-gradient(180deg, #FFAD58 0%, #FA8B14 100%)`
          );
          SliderStyles?.setProperty(
            '--InnerDotColor',
            `linear-gradient(180deg, #E48632 0%, #E48632 22.67%, #FEBA71 100%)`
          );
        } else if (value >= 40 && value < 60) {
          SliderStyles?.setProperty(
            '--OuterDotColor',
            `linear-gradient(180deg, #CDCDCD 0%, #858484 100%)`
          );
          SliderStyles?.setProperty(
            '--InnerDotColor',
            `linear-gradient(180deg, #838383 0%, #9A9A9A 22.67%, #C2C2C2 100%)`
          );
        } else if (value >= 60 && value < 80) {
          SliderStyles?.setProperty(
            '--OuterDotColor',
            `linear-gradient(180deg, #7CBF66 0%, #55B04E 100%)`
          );
          SliderStyles?.setProperty(
            '--InnerDotColor',
            `linear-gradient(180deg, #379225 0%, #3DA628 22.67%, #9ACF8F 100%)`
          );
        } else if (value >= 80 && value < 100) {
          SliderStyles?.setProperty(
            '--OuterDotColor',
            `linear-gradient(180deg, #17D971 0%, #0F7D42 100%)`
          );
          SliderStyles?.setProperty(
            '--InnerDotColor',
            `linear-gradient(180deg, #089348 0%, #0E9D51 22.67%, #2CD279 100%)`
          );
        }
      } else {
        if (value >= 0 && value < 20) {
          SliderStyles?.setProperty(
            '--OuterDotColor',
            `linear-gradient(180deg, #17D971 0%, #0F7D42 100%)`
          );
          SliderStyles?.setProperty(
            '--InnerDotColor',
            `linear-gradient(180deg, #089348 0%, #0E9D51 22.67%, #2CD279 100%)`
          );
        } else if (value >= 20 && value < 40) {
          SliderStyles?.setProperty(
            '--OuterDotColor',
            `linear-gradient(180deg, #7CBF66 0%, #55B04E 100%)`
          );
          SliderStyles?.setProperty(
            '--InnerDotColor',
            `linear-gradient(180deg, #379225 0%, #3DA628 22.67%, #9ACF8F 100%)`
          );
        } else if (value >= 40 && value < 60) {
          SliderStyles?.setProperty(
            '--OuterDotColor',
            `linear-gradient(180deg, #CDCDCD 0%, #858484 100%)`
          );
          SliderStyles?.setProperty(
            '--InnerDotColor',
            `linear-gradient(180deg, #838383 0%, #9A9A9A 22.67%, #C2C2C2 100%)`
          );
        } else if (value >= 60 && value < 80) {
          SliderStyles?.setProperty(
            '--OuterDotColor',
            `linear-gradient(180deg, #FFAD58 0%, #FA8B14 100%)`
          );
          SliderStyles?.setProperty(
            '--InnerDotColor',
            `linear-gradient(180deg, #E48632 0%, #E48632 22.67%, #FEBA71 100%)`
          );
        } else if (value >= 80 && value < 100) {
          SliderStyles?.setProperty(
            '--OuterDotColor',
            `linear-gradient(180deg, #FC5957 0%, #F93432 100%)`
          );
          SliderStyles?.setProperty(
            '--InnerDotColor',
            `linear-gradient(180deg, #C60807 0%, #ED2623 22.67%, #FF7171 100%)`
          );
        }
      }
    });
    // eslint-disable-next-line
  }, [mappedData, sliderColor]);

  const onChange = (value, id) => {
    let adjustedValue = value;
    if (mappedData.find((item) => item.id === id)?.subType === '-+') {
      adjustedValue = (value / 100) * 4 - 2;
    } else if (mappedData.find((item) => item.id === id)?.subType === '+-') {
      adjustedValue = 2 - (value / 100) * 4;
    }
    setScaleValue((prevValues) => ({
      ...prevValues,
      [id]: adjustedValue,
    }));
    SetSliderColor({ ...sliderColor, [id]: adjustedValue });
  };

  const handleNumericChange = (value, id) => {
    let adjustedValue = value;
    setScaleValue((prevValues) => ({
      ...prevValues,
      [id]: adjustedValue,
    }));
  };

  const sliders = mappedData?.map((item, index) => {
    const marks = {};
    if (item?.subType === '-+' || item?.subType === '+-') {
      item?.breakpoints?.forEach((breakpoint, index) => {
        marks[index * 25] = breakpoint?.label;
      });

      let value = 50;
      if (item?.subType === '-+') {
        if (scaleValue[item?.id] !== undefined) {
          value = ((scaleValue[item?.id] + 2) * 100) / 4;
        }
      } else if (item?.subType === '+-') {
        if (scaleValue[item?.id] !== undefined) {
          value = -(((scaleValue[item?.id] - 2) * 100) / 4);
        }
      }

      return (
        <>
          <Col span={24} key={index}>
            <Typography.Text>{item.name}</Typography.Text>
            <div className="ranking-scale-slider">
              <Slider
                className={
                  item.subType === '+-'
                    ? `opportunity-slider opportunity-slider-${index} slider-change-1 m-0`
                    : `opportunity-slider slider-change m-0 opportunity-slider-${index}`
                }
                tooltip={{ open: false }}
                onChange={(value) => onChange(value, item.id)}
                marks={marks}
                value={value}
                disabled
              />
            </div>
          </Col>
        </>
      );
    } else {
      return null;
    }
  });

  const numericSlider = mappedData?.map((item, index) => {
    if (item?.subType === '$' || item?.subType === '#') {
      const numericMarks = (low, high) => {
        const interval = high && low ? (high - low) / 4 : 25;
        const marks = {};
        if (isNaN(low) && isNaN(high)) {
          marks[0] = 'Low End';
          marks[100] = 'High End';
        }
        if (low === undefined || isNaN(low)) {
          marks[0] = 'Low End';
        } else {
          marks[low] = low;
        }

        if (high === undefined || high === 0 || isNaN(high)) {
          marks[100] = 'High End';
        } else {
          marks[high] = high;
        }

        for (let i = 1; i <= 3; i++) {
          marks[low + interval * i] = `Point ${i}`;
        }
        return marks;
      };

      const lowValue = item.breakpoints[0].value;
      const highValue = item.breakpoints[1].value;
      const marks = numericMarks(lowValue, highValue);

      const convertValueToSliderRange = (value, maxValue) => {
        return Math.max(0, Math.min(100, (value * 100) / maxValue));
      };

      // Usage:
      const newValueFromBackend = scaleValue[item?.id]; // Example value received from the backend
      const maxValue = highValue; // Example maximum value

      const sliderValue = convertValueToSliderRange(
        newValueFromBackend,
        maxValue
      );

      return (
        <>
          <Col span={24}>
            <Typography.Text>{item.name}</Typography.Text>
            <div className="ranking-slider">
              <Slider
                tooltip={{ open: true }}
                className="numeric-slider"
                tipFormatter={(value) => {
                  const prefix = item.subType === '$' ? '$' : '#';
                  return `${prefix}${value}`;
                }}
                marks={marks}
                min={lowValue}
                max={highValue}
                value={scaleValue[item?.id]}
                onChange={(value) => handleNumericChange(value, item?.id)}
                disabled
              />
            </div>
          </Col>
        </>
      );
    }
  });

  return (
    <>
      <Row gutter={[20, 20]} className="opportunity-view mt-20">
        {formLayoutByGamma?.layout?.map((item) => {
          return (
            <>
              {item?.inputType === 'singleLine' &&
                !item?.hide &&
                item?.id !== process.env.REACT_APP_TITLE_ID && (
                  <Col span={12} md={12} sm={24} xs={24}>
                    <div className="field-data">
                      <Typography.Title level={4} className="field fs-14">
                        {item?.label}
                      </Typography.Title>
                      <Typography.Title level={5} className="data">
                        {(item?.newValue !== undefined && item?.newValue[0]) ||
                          '-'}
                      </Typography.Title>
                    </div>
                  </Col>
                )}
              {item?.inputType === 'multiLine' &&
                !item?.hide &&
                item?.id !== process.env.REACT_APP_DESCRIPTION_ID && (
                  <Col span={12} md={12} sm={24} xs={24}>
                    <div className="view-card">
                      <Typography.Title
                        level={4}
                        className="field fw-500 fs-14"
                      >
                        {item?.label}
                      </Typography.Title>

                      <Typography.Text level={5} className="data mt-0">
                        {item?.newValue
                          ? item?.newValue[0]
                              ?.split('\n')
                              .map((line, index) => (
                                <React.Fragment key={index}>
                                  {line}
                                  <br />
                                </React.Fragment>
                              ))
                          : '-'}
                      </Typography.Text>
                    </div>
                  </Col>
                )}
              {item?.inputType === 'dropdown' &&
                !item?.hide &&
                item?.id !== process.env.REACT_APP_DEPARTMENT_ID && (
                  <Col span={12} md={12} sm={24} xs={24}>
                    <div className="field-data">
                      <Typography.Title level={4} className="fw-500 fs-14">
                        {item?.label} :
                      </Typography.Title>
                      <Typography.Title level={5} className="data">
                        {showDropDownItems(item) || '-'}
                      </Typography.Title>
                    </div>
                  </Col>
                )}
              {item?.inputType === 'dropdown' &&
                item?.label === 'Sponsor' &&
                item?.label === 'Contributors' && (
                  <Col span={12} md={12} sm={24} xs={24}>
                    <div className="field-data">
                      <Typography.Title level={4} className="field fw-500">
                        {item?.label} :
                      </Typography.Title>
                      <Typography.Text level={5} className="data">
                        {/* {item?.value && item?.value?.map((item) => item?.text)} */}
                      </Typography.Text>
                    </div>
                  </Col>
                )}
              {item?.inputType === 'datepicker' && !item?.hide && (
                <Col span={12} md={12} sm={24} xs={24}>
                  <div className="field-data">
                    <Typography.Title level={4} className="field fw-500 fs-14">
                      {item?.label}
                    </Typography.Title>
                    <Typography.Title level={5} className="data">
                      {item?.value?.[0]?.text}
                    </Typography.Title>
                  </div>
                </Col>
              )}

              {item?.inputType === 'symbol' && !item?.hide && (
                <Col span={12} md={12} sm={24} xs={24}>
                  <div className="field-data">
                    <Typography.Title level={4} className="field fw-500 fs-14">
                      {item?.label}
                    </Typography.Title>
                    <Typography.Title level={5} className="data">
                      {(item?.symbolType === 'threeIcon' ||
                        item?.symbolType === 'fiveIcon' ||
                        item?.symbolType === 'fourIcon') && (
                        <img
                          src={fourIconsObject[item?.value?.[0]?.text]}
                          alt=""
                          className="symbol-height"
                        />
                      )}
                      {item?.symbolType === 'shapeIcon' && (
                        <img
                          src={shapeIcon[item?.value?.[0]?.text]}
                          alt=""
                          className="symbol-height"
                        />
                      )}
                      {item?.symbolType === 'rightCrossIcon' && (
                        <img
                          src={rightCrossIcon[item?.value?.[0]?.text]}
                          alt=""
                          className="symbol-height"
                        />
                      )}
                      {item?.symbolType === 'arrowMinusIcon' && (
                        <img
                          src={arrowMinusIcon[item?.value?.[0]?.text]}
                          alt=""
                          className="symbol-height"
                        />
                      )}
                      {item?.symbolType === 'pieChartIcon' && (
                        <img
                          src={pieChartIcon[item?.value?.[0]?.text]}
                          alt=""
                          className="symbol-height"
                        />
                      )}
                      {item?.symbolType === 'analytics' && (
                        <img
                          src={analytics[item?.value?.[0]?.text]}
                          alt=""
                          className="symbol-height"
                        />
                      )}
                      {item?.symbolType === 'cloudIcon' && (
                        <img
                          src={cloudIcons[item?.value?.[0]?.text]}
                          alt=""
                          className="symbol-height"
                        />
                      )}
                      {item?.symbolType === 'circleSquareIcon' && (
                        <img
                          src={circleSquareIcon[item?.value?.[0]?.text]}
                          alt=""
                          className="symbol-height"
                        />
                      )}
                      {item?.symbolType === 'expressionIcon' && (
                        <img
                          src={expressionIcon[item?.value?.[0]?.text]}
                          alt=""
                          className="symbol-height"
                        />
                      )}
                      {item?.symbolType === 'ratingsIcon' && (
                        <img
                          src={ratingsIcon[item?.value?.[0]?.text]}
                          alt=""
                          className="symbol-height"
                        />
                      )}
                      {item?.symbolType === 'progressBarIcon' && (
                        <img
                          src={progressBarIcon[item?.value?.[0]?.text]}
                          alt=""
                          className="symbol-height"
                        />
                      )}
                      {item?.symbolType === 'upDownIcon' && (
                        <img
                          src={upDownIcon[item?.value?.[0]?.text]}
                          alt=""
                          className="symbol-height"
                        />
                      )}
                      {item?.symbolType === 'fourArrowIcon' && (
                        <img
                          src={fourArrowIcon[item?.value?.[0]?.text]}
                          alt=""
                          className="symbol-height"
                        />
                      )}
                      {item?.symbolType === 'fiveArrowIcon' && (
                        <img
                          src={fiveArrowIcon[item?.value?.[0]?.text]}
                          alt=""
                          className="symbol-height"
                        />
                      )}
                      {item?.symbolType === 'humanIcon' && (
                        <img
                          src={humanIcon[item?.value?.[0]?.text]}
                          alt=""
                          className="symbol-height"
                        />
                      )}
                      {item?.symbolType === 'dollarIcon' && (
                        <img
                          src={dollarIcon[item?.value?.[0]?.text]}
                          alt=""
                          className="symbol-height"
                        />
                      )}
                      {item?.symbolType === 'heartIcon' && (
                        <img
                          src={heartIcon[item?.value?.[0]?.text]}
                          alt=""
                          className="symbol-height"
                        />
                      )}
                      {item?.symbolType === 'threeArrowIcon' && (
                        <img
                          src={threeArrowIcon[item?.value?.[0]?.text]}
                          alt=""
                          className="symbol-height"
                        />
                      )}
                      {item?.symbolType === 'fourBlackArrowIcon' && (
                        <img
                          src={fourBlackArrowIcon[item?.value?.[0]?.text]}
                          alt=""
                          className="symbol-height"
                        />
                      )}
                      <span className="ml-5">{item?.value?.[0]?.text}</span>
                    </Typography.Title>
                  </div>
                </Col>
              )}

              {item?.inputType === 'dragger' && !item?.hide && (
                <Col span={12} md={12} sm={24} xs={24}>
                  <div className="field-data">
                    <Typography.Title
                      level={4}
                      className="field mb-10 fw-500 fs-14"
                    >
                      {item?.label}:
                    </Typography.Title>

                    {item?.value
                      ? item?.value?.map((item) => {
                          return (
                            <div className="verical-box">
                              <Tag
                                className="file-tag cursor-pointer ellipsis-text"
                                onClick={() => window.open(item?.url)}
                              >
                                {item?.text}
                              </Tag>
                            </div>
                          );
                        })
                      : '-'}
                  </div>
                </Col>
              )}
              {item?.inputType === 'link' && !item?.hide && (
                <Col span={12} md={12} sm={24} xs={24}>
                  <div className="field-data">
                    <Typography.Title
                      level={4}
                      className="field mb-10 fw-500 fs-14"
                    >
                      {item?.label}
                    </Typography.Title>
                    {item?.value
                      ? item?.value?.map((item) => {
                          return (
                            <div className="verical-box">
                              <a
                                href={item?.text}
                                target="_blank"
                                rel="noreferrer"
                                className="fw-500 fs-14 text-primary"
                              >
                                {item?.text}
                              </a>
                            </div>
                          );
                        })
                      : '-'}
                  </div>
                </Col>
              )}
            </>
          );
        })}
        {sliders}
        {numericSlider}
        {window.location.pathname !== '/rate-detail' && (
          <Col span={24} md={24} sm={24} xs={24}>
            <Row justify="space-between">
              <Col span={20} className="p-0">
                <Typography.Title level={5} className="mb-0 sub-header-font">
                  Related items
                </Typography.Title>
              </Col>
              {formLayoutByGamma?.level?.level === 1 && (
                <Col span={4} className="p-0">
                  <div>
                    <Button
                      type="primary"
                      className="primary-btn related-btn"
                      onClick={showDrawer}
                      size="middle"
                    >
                      Add Related Items
                    </Button>
                  </div>
                </Col>
              )}
            </Row>
            <Row className="idea related-item mt-20 mb-20" gutter={[20, 20]}>
              {linkedGamma?.length ? (
                linkedGamma?.map((item) => {
                  return (
                    <Col span={12} md={12} sm={24} xs={24}>
                      <div className="idea-box idea">
                        <div className="h-100 wrapper">
                          <Row>
                            <Link to="/ranking-detail">
                              <Paragraph
                                className="fs-16 title"
                                ellipsis={{
                                  tooltip: 'Live Chat Support',
                                }}
                              >
                                {item?.linkedGamma?.title}
                              </Paragraph>

                              <Paragraph
                                ellipsis={{
                                  rows: 3,
                                  expandable: false,
                                  symbol: 'more',
                                }}
                                className="fs-14 mb-0"
                              >
                                {item?.linkedGamma?.description}
                              </Paragraph>
                            </Link>
                          </Row>

                          <Row
                            align="middle"
                            justify="space-between"
                            className="w-100"
                          >
                            <Divider />
                            <Row align="middle">
                              <Typography.Title level={5} className="mb-0 p">
                                <span className="fw-bold">ID : </span>{' '}
                                <span className="fw-400">
                                  {item?.linkedGamma?.friendlyId
                                    ? item?.linkedGamma?.friendlyId
                                        .toString()
                                        .padStart(6, '0')
                                    : '-'}{' '}
                                </span>
                              </Typography.Title>
                              <Typography.Title
                                level={5}
                                className="mt-0 mb-0 ml-10 p"
                              >
                                <span className="fw-bold">Type : </span>{' '}
                                <Tag
                                  className="tag-font"
                                  key={item?.linkedGamma?.level?.id}
                                  color={
                                    levelColors[item?.linkedGamma?.level?.level]
                                  }
                                >
                                  {item?.linkedGamma?.level?.name}
                                </Tag>
                              </Typography.Title>
                            </Row>

                            <Popover
                              content={'Idea link copied'}
                              placement="bottom"
                              trigger="clicked"
                            >
                              <Button
                                className="unlink-btn fw-bold"
                                onClick={() => unLinkIdeas(item?.id)}
                                size="middle"
                              >
                                Unlink
                              </Button>
                            </Popover>
                          </Row>
                        </div>
                      </div>
                    </Col>
                  );
                })
              ) : (
                <Empty
                  className="empty-data"
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={<span>No Related Items</span>}
                />
              )}
            </Row>
          </Col>
        )}
      </Row>
      <Drawer
        width="40%"
        height={screens.xs ? '83vh' : ''}
        placement={screens.xs ? 'bottom' : 'right'}
        className="related-item-drawer"
        title={
          <Typography.Title level={4} className="mb-0 sub-header-font">
            Add related items
          </Typography.Title>
        }
        onClose={onClose}
        open={open}
      >
        <Search
          className="searchbar-style"
          placeholder="Search by keyword or ID"
          onChange={(e) => fetchFezzySearch(e.target.value)}
        />
        {dashboardData?.length ? (
          dashboardData
            ?.filter((item) => item?.id !== id)
            ?.map((item) => {
              return (
                (item?.title || item?.description) && (
                  <Row className="idea mt-20" gutter={[0, 20]} key={item?.id}>
                    <div className="idea-box idea w-100">
                      <Paragraph className="title capitalize-text sub-header-font">
                        {item?.title}
                      </Paragraph>

                      <Paragraph
                        ellipsis={{
                          rows: 3,
                          expandable: false,
                          symbol: 'more',
                          tooltip: item?.description,
                        }}
                        className="p"
                      >
                        {item?.description}
                      </Paragraph>

                      <Row
                        align="middle"
                        justify="space-between"
                        className="w-100"
                      >
                        <Divider />
                        <Row align="middle">
                          <Typography.Title level={5} className="mb-0 p">
                            <span>ID : </span>
                            {item?.friendlyId &&
                              item?.friendlyId.toString().padStart(6, '0')}{' '}
                          </Typography.Title>
                          <Typography.Title className="mt-0 mb-0 ml-10 p">
                            <span className="fw-400">Type : </span>{' '}
                            <Tag
                              className="tag-font"
                              key={item?.level?.id}
                              color={levelColors[item?.level?.level]}
                            >
                              {item?.level?.name}
                            </Tag>
                          </Typography.Title>
                        </Row>
                        {relatedIdeaIds?.includes(item?.id) ? (
                          <Button
                            type="primary"
                            className="unlink-btn"
                            onClick={() => unLinkIdeas(item?.id)}
                            size="middle"
                          >
                            Unlink
                          </Button>
                        ) : (
                          <Button
                            type="primary"
                            className="blue-filled-btn"
                            onClick={() => linkIdeas(item?.id)}
                            size="middle"
                          >
                            Link
                          </Button>
                        )}
                      </Row>
                    </div>
                  </Row>
                )
              );
            })
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </Drawer>
    </>
  );
}
