export const gammaType = {
  project: 'PROJECT',
  opportunity: 'OPPORTUNITY',
  idea: 'IDEA',
  priority: 'priorityBatch',
  gamma: 'gamma',
};

export const userStatus = {
  enable: 'ENABLED',
  confirm: 'CONFIRMED',
  disabled: 'DISABLED',
  invited: 'INVITED',
  active: 'ACTIVE',
  inactive: 'INACTIVE',
};

export const UserRole = {
  admin: 'ADMIN',
  superAdmin: 'SUPERADMIN',
  leader: 'LEADER',
  sme: 'SME',
  teamMember: 'TEAM MEMBER',
};

export const emailPattern = /^[a-z0-9+._-]+@[a-z0-9+.-]+\.[a-z]{2,4}$/;

export const dateTimeFormat = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';

export const charset =
  'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

export const options = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  hour12: true, // Use 12-hour clock with AM/PM
};

export const selectAllDepartment = '00000000-0000-0000-0000-000000000000';

export const levelColors = {
  1: 'gold',
  2: 'blue',
  3: 'purple',
  4: 'magenta',
  5: 'red',
  6: 'volcano',
  7: 'orange',
  8: 'gold',
  9: 'lime',
  10: 'cyan',
  // Add more levels and colors as needed
};

export const GraphlevelColors = {
  1: '#F6BD16',
  2: '#5AD8A6',
  3: '#5B8FF9',
  4: 'magenta',
  5: 'red',
  6: 'volcano',
  7: 'orange',
  8: 'gold',
  9: 'lime',
  10: 'cyan',
  // Add more levels and colors as needed
};

export const capitalizeFirstLetter = (name) => {
  return name?.charAt(0)?.toUpperCase() + name?.slice(1)?.toLowerCase();
};

export const isValidUrlFormat = (url) => {
  const regex = new RegExp(
    '^(https?|ftp):\\/\\/' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3})|' + // OR ip (v4) address
      '\\[?[a-f\\d:]+\\]?)' + // OR ip (v6) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i' // fragment locator
  );
  return !!regex.test(url);
};

export const isValidName = (name) => {
  const regex = /^[a-zA-Z]{2,50}$/;
  return regex.test(name);
};
