import {
  Button,
  Col,
  Divider,
  Input,
  message,
  Row,
  Space,
  Typography,
  Upload,
  Select,
  Skeleton,
} from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { SketchPicker } from 'react-color';
import BrandingPreview from './BrandingPreview';
import { GetFileFromS3, SaveFileToS3 } from 'utils/commonMethods';
import { CloseCircleOutlined, BgColorsOutlined } from '@ant-design/icons';
import {
  RESTAddNewOrg,
  RESTAddNewPartner,
  RESTGetAuthorization,
} from 'utils/RESTApi';
import { useHistory, useParams } from 'react-router-dom';
import { FetchUser } from 'Pages/AllPriorities/IdeaFunction';
import { UserRole, userStatus } from 'utils/Constants';
import { useSelector, useDispatch } from 'react-redux';
import {
  getCurrentOrganization,
  getCurrnetUserData,
} from 'store/slices/loginSlice';
import { getBrandingForm, setBrandingForm } from 'store/slices/brandingSlice';
import LeaveMFModal from 'Pages/MeasurementField/Components/LeaveMFModal';

const AddOrganization = () => {
  const [customLogo, setCustomLogo] = useState();
  const [uploadLogo, setUploadLogo] = useState(true);
  const [imagePreview, setImagePreview] = useState();
  const [error, setError] = useState('');
  const [showPicker, setShowPicker] = useState(false);
  const [previewModal, setPreviewModal] = useState(false);
  const [orgEditDetails, setOrgEditDetails] = useState();
  const [staticBranding, setStaticBranding] = useState();
  const [resetFunction, setResetFunction] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState([]);
  const { id } = useParams();
  const { TextArea } = Input;
  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;
  const userId = currnetOrg?.adminID || loginUsrOrg?.id;

  const pickerRef = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const formhasChanged = useSelector(getBrandingForm);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [previewDisable, setPreviewDisable] = useState(false);
  const [colorChange, setColorChange] = useState(false);

  const handleClickOutside = (event) => {
    if (pickerRef.current && !pickerRef.current.contains(event.target)) {
      setShowPicker(false);
    }
  };

  useEffect(() => {
    if (showPicker) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showPicker]);

  const getAllUsers = async () => {
    try {
      let data = {
        status: { eq: userStatus.active },
        or: [{ role: { eq: UserRole.admin } }],
      };
      let org_id = { organizationID: id };
      let nextToken = null;
      let allUserData = [];
      while (true) {
        let res = await FetchUser(data, org_id, nextToken);
        allUserData.push(...res.items);
        if (!res?.nextToken) {
          break;
        }
        nextToken = res?.nextToken;
      }
      await processUserData(allUserData);
    } catch (err) {
      console.log(err);
    }
  };

  const processUserData = async (userData) => {
    setUserData(userData);
  };

  useEffect(() => {
    if (id) {
      getAllUsers();
    }
    // eslint-disable-next-line
  }, []);

  const [organizationData, setOrganizationData] = useState({
    name: '',
    website: '',
    contactNumber: '',
    street: '',
    zipCode: '',
    city: '',
    state: '',
    country: '',
    firstName: '',
    lastName: '',
    email: '',
    adminContactNumber: '',
    logo: '',
    navHeaderColor: '',
    id: '',
  });
  const props = {
    name: 'file',
    accept: '.svg',
    onChange(info) {
      dispatch(setBrandingForm(true));
      setUploadLogo(true);
      const file = info.fileList[0].originFileObj;
      const reader = new FileReader();
      reader.onload = (e) => {
        setCustomLogo(e.target.result);
        setImagePreview(e.target.result);
        setOrganizationData((prevData) => ({
          ...prevData,
          logo: file,
        }));
      };
      reader.readAsDataURL(file);
    },
  };

  const handleColorChange = (selectedColor) => {
    setOrganizationData((prevData) => ({
      ...prevData,
      navHeaderColor: selectedColor.hex,
    }));
    setColorChange(true);
    dispatch(setBrandingForm(true));
  };

  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const fetchLocationData = async (zip) => {
    if (zip.length > 1) {
      try {
        const response = await fetch(
          `https://secure.geonames.org/postalCodeLookupJSON?postalcode=${zip}&username=organization_country`
        );
        if (!response.ok) {
          throw new Error('Invalid ZIP code or data not available.');
        }
        const data = await response.json();
        if (data.postalcodes && data.postalcodes.length > 0) {
          const usLocations = data.postalcodes.filter(
            (location) => location.countryCode === 'US'
          );
          const locationData =
            usLocations.length > 0 ? usLocations[0] : data.postalcodes[0];
          setOrganizationData((prevData) => ({
            ...prevData,
            city: locationData.adminName2 || '',
            state: locationData.adminName1 || '',
            country: locationData.countryCode || '',
          }));

          setError('');
        } else {
          setError('No location data found.');
        }
      } catch (err) {
        setError(err.message);
      }
    }
  };
  // eslint-disable-next-line
  const debouncedFetchLocationData = useCallback(
    debounce(fetchLocationData, 500),
    []
  );

  const handleZipCodeChange = (e) => {
    const zip = e.target.value;
    setOrganizationData((prevData) => ({ ...prevData, zipCode: zip }));
    if (zip.trim() !== '') {
      debouncedFetchLocationData(zip);
    } else {
      setError('Please enter a ZIP code.');
      setOrganizationData((prevData) => ({
        ...prevData,
        city: '',
        state: '',
        country: '',
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e;
    setOrganizationData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (name === 'email' && id) {
      setOrganizationData((prevData) => ({
        ...prevData,
        lastName: userData.filter((i) => i.email === value)[0]?.lastName,
        firstName: userData.filter((i) => i.email === value)[0]?.firstName,
        adminContactNumber:
          userData.filter((i) => i.email === value)[0]?.contact ||
          organizationData.adminContactNumber,
      }));
    }

    if (name === 'firstName') {
      if (!/^[A-Za-z]+$/.test(value)) {
        formErrors.firstName =
          'First name must contain only alphabetic characters';
      } else {
        delete formErrors.firstName;
      }
    }

    if (name === 'lastName') {
      if (!/^[A-Za-z]+$/.test(value)) {
        formErrors.lastName =
          'Last name must contain only alphabetic characters';
      } else {
        delete formErrors.lastName;
      }
    }

    if (name === 'email') {
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
        formErrors.email = 'Invalid email format';
      } else {
        delete formErrors.email;
      }
    }

    if (name === 'website') {
      if (
        !/^(https?:\/\/)?((([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})|localhost)(:\d{2,5})?(\/[^\s]*)?$/.test(
          value
        )
      ) {
        formErrors.website = 'Invalid website format';
      } else {
        delete formErrors.website;
      }
    }
    if (value.length > 0) {
      dispatch(setBrandingForm(true));
    } else {
      dispatch(setBrandingForm(false));
    }
  };

  const handleSelectChange = (name, value) => {
    handleChange({ name, value });
  };

  const handlePhoneChange = (value) => {
    setOrganizationData({ ...organizationData, contactNumber: value });
    if (value && !isValidPhoneNumber(value)) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        contactNumber: 'Invalid contact number',
      }));
    } else {
      setFormErrors((prevErrors) => {
        const { contactNumber, ...others } = prevErrors;
        return others;
      });
    }
  };

  const handleAdminPhoneChange = (value) => {
    setOrganizationData({ ...organizationData, adminContactNumber: value });
    if (value && !isValidPhoneNumber(value)) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        adminContactNumber: 'Invalid contact number',
      }));
    } else {
      setFormErrors((prevErrors) => {
        const { adminContactNumber, ...others } = prevErrors;
        return others;
      });
    }
  };

  const validateForm = () => {
    const errors = {};
    const requiredFields = [
      'name',
      'website',
      'firstName',
      'lastName',
      'email',
      'navHeaderColor',
      'logo',
    ];

    requiredFields.forEach((field) => {
      if (!organizationData[field]) {
        errors[field] = 'This field is required';
      }
    });

    if (
      organizationData.firstName &&
      !/^[A-Za-z]+$/.test(organizationData.firstName)
    ) {
      errors.firstName = 'First name must contain only alphabetic characters';
    }

    if (
      organizationData.lastName &&
      !/^[A-Za-z]+$/.test(organizationData.lastName)
    ) {
      errors.lastName = 'Last name must contain only alphabetic characters';
    }

    if (
      organizationData.email &&
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(organizationData.email)
    ) {
      errors.email = 'Invalid email format';
    }

    if (
      organizationData.website &&
      !/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/.test(organizationData.website)
    ) {
      errors.website = 'Invalid website format';
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const handleSumbit = async () => {
    if (!validateForm()) {
      message.error('Please fill all required fields.');
      return;
    }
    try {
      setLoading(true);
      let uploadImageinS3 = await SaveFileToS3(organizationData?.logo);
      let inputData = {
        organization_id: orgId,
        user_id: userId,
        organization: {
          id: id,
          name: organizationData.name,
          website: organizationData.website,
          contact: organizationData.contactNumber || '',
          address: {
            street: organizationData.street || '',
            city: organizationData.city,
            zip: organizationData.zipCode,
            state: organizationData.state,
            country: organizationData.country,
          },
        },
        admin: {
          id:
            userData.filter((i) => i.email === organizationData?.email)[0]
              ?.id || orgEditDetails?.admin?.id,
          firstName: organizationData.firstName,
          lastName: organizationData.lastName,
          contact: organizationData.adminContactNumber || '',
          email: organizationData.email,
        },
        branding: {
          dark_background_logo:
            id && !imagePreview && resetFunction
              ? staticBranding?.dark_background_logo
              : id && !imagePreview
              ? orgEditDetails?.branding?.dark_background_logo
              : imagePreview || id
              ? 'public/' + uploadImageinS3?.key
              : resetFunction
              ? staticBranding?.dark_background_logo
              : staticBranding?.dark_background_logo,
          light_background_logo: orgEditDetails?.branding?.light_background_logo
            ? orgEditDetails?.branding?.light_background_logo
            : staticBranding?.light_background_logo,
          primary_color: orgEditDetails?.branding?.primary_color
            ? orgEditDetails?.branding?.primary_color
            : staticBranding?.primary_color,
          secondary_color: orgEditDetails?.branding?.secondary_color
            ? orgEditDetails?.branding?.secondary_color
            : staticBranding?.secondary_color,
          navigation_color: organizationData.navHeaderColor,
          login_page_illustration: orgEditDetails?.branding
            ?.login_page_illustration
            ? orgEditDetails?.branding?.login_page_illustration
            : staticBranding?.login_page_illustration,
        },
      };

      setResetFunction(false);
      if (
        window.location.pathname === '/partner/add-partner' ||
        window.location.pathname.includes('/partner/edit-partner')
      ) {
        await RESTAddNewPartner(inputData);
        message.success(`Partner ${id ? 'updated' : 'added'} successfully`);
        history.push('/partner');
      } else {
        await RESTAddNewOrg(inputData);
        message.success(
          `Organization ${id ? 'updated' : 'created'} successfully`
        );
        history.push('/organizations');
      }
      setLoading(false);
      dispatch(setBrandingForm(false));
    } catch (err) {
      setLoading(false);

      message.error(err?.response?.data?.Message);
    }
  };

  const fetchFileLogoByOrgID = async () => {
    if (id) {
      try {
        let fileKey =
          orgEditDetails?.branding?.dark_background_logo.split('public/')[1];
        let getLogo = await GetFileFromS3(fileKey, { level: 'public' });
        setOrganizationData((prevData) => ({
          ...prevData,
          logo: getLogo,
        }));
        let getBrandingDetails = await GetFileFromS3(
          `${orgId}/branding/Branding.json`,
          {
            level: 'public',
          }
        );
        let data = await fetch(getBrandingDetails);
        let response = await data.json();
        setStaticBranding(response);
      } catch (error) {
        console.error('Error fetching the file from S3', error);
      }
    } else {
      try {
        let getBrandingDetails = await GetFileFromS3(
          `${orgId}/branding/Branding.json`,
          {
            level: 'public',
          }
        );
        let data = await fetch(getBrandingDetails);
        let response = await data.json();
        let getLogo = await GetFileFromS3(
          response.dark_background_logo.split('public/')[1],
          {
            level: 'public',
          }
        );
        setStaticBranding(response);
        setOrganizationData((prevData) => ({
          ...prevData,
          logo: getLogo,
          navHeaderColor: response?.navigation_color,
        }));
      } catch (error) {
        console.error('Error fetching the file from S3', error);
      }
    }
  };

  useEffect(() => {
    fetchFileLogoByOrgID();
    // eslint-disable-next-line
  }, [orgEditDetails]);

  const fetchOrgDetailsByID = async () => {
    setLoader(true);
    try {
      let api = `/organization/get?organizationID=${id}`;
      let response = await RESTGetAuthorization(api);
      setOrgEditDetails(response?.body);
      setLoader(false);
    } catch (err) {
      setLoader(false);
      console.log(err);
    }
  };

  const fetchPartnerDetailsByID = async () => {
    setLoader(true);
    try {
      let api = `/organization/partner/get?organizationID=${id}`;
      let response = await RESTGetAuthorization(api);
      setOrgEditDetails(response?.body);
      setLoader(false);
    } catch (err) {
      setLoader(false);
      console.log(err);
    }
  };

  useEffect(() => {
    if (orgEditDetails) {
      setOrganizationData({
        name: orgEditDetails?.organization?.name,
        website: orgEditDetails?.organization?.website,
        contactNumber: orgEditDetails?.organization?.contact,
        street: orgEditDetails?.organization?.address?.street,
        zipCode: orgEditDetails?.organization?.address?.zip,
        city: orgEditDetails?.organization?.address?.city,
        state: orgEditDetails?.organization?.address?.state,
        country: orgEditDetails?.organization?.address?.country,
        firstName: orgEditDetails?.admin?.firstName,
        lastName: orgEditDetails?.admin?.lastName,
        email: orgEditDetails?.admin?.email,
        adminContactNumber: orgEditDetails?.admin?.contact,
        logo: orgEditDetails?.branding?.dark_background_logo,
        navHeaderColor: orgEditDetails?.branding?.navigation_color,
      });
    }
  }, [orgEditDetails]);

  useEffect(() => {
    if (id && window.location.pathname.includes('/partner/edit-partner')) {
      fetchPartnerDetailsByID();
    } else if (id) {
      fetchOrgDetailsByID();
    }
    // eslint-disable-next-line
  }, []);

  const handleReset = async () => {
    setResetFunction(true);
    setUploadLogo(true);
    try {
      let getBrandingDetails = await GetFileFromS3(
        `${orgId}/branding/Branding.json`,
        {
          level: 'public',
        }
      );
      let data = await fetch(getBrandingDetails);
      let response = await data.json();
      let getLogo = await GetFileFromS3(
        response.dark_background_logo.split('public/')[1],
        {
          level: 'public',
        }
      );
      setOrganizationData((prevData) => ({
        ...prevData,
        logo: getLogo,
        navHeaderColor: response?.navigation_color,
      }));
      setCustomLogo(getLogo);
      setImagePreview();
      setColorChange(false);
    } catch (error) {
      console.error('Error fetching the file from S3', error);
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (formhasChanged) {
        event.preventDefault();
        event.returnValue =
          'You have unsaved changes. Are you sure you want to leave?';
      }
    };

    const handleMenuItemClick = (event) => {
      if (formhasChanged) {
        event.preventDefault();
        const path = event.currentTarget.getAttribute('href');
        setIsModalOpen(true);
      }
    };

    // Attach event listeners
    window.addEventListener('beforeunload', handleBeforeUnload);

    const menuItems = document.querySelectorAll('.sidebar');
    menuItems.forEach((item) => {
      item.addEventListener('click', handleMenuItemClick);
    });

    return () => {
      // Clean up event listeners
      window.removeEventListener('beforeunload', handleBeforeUnload);
      menuItems.forEach((item) => {
        item.removeEventListener('click', handleMenuItemClick);
      });
    };
  }, [formhasChanged, history]);

  return (
    <div className="background-white custom-org">
      <Typography.Title level={5} className="mb-10">
        {window.location.pathname === '/partner/add-partner'
          ? 'Partner Details'
          : 'Organizations Details'}
      </Typography.Title>
      <Row>
        <Col span={8}>
          <span>
            <span className="red-color">*</span> Organization name
          </span>
          <br />
          {loader ? (
            <Skeleton.Input active className="w-100" />
          ) : (
            <Input
              className="mt-5"
              name="name"
              value={organizationData.name}
              onChange={(e) => handleChange(e.target)}
            />
          )}
          {formErrors.name && (
            <span style={{ color: 'red' }}>{formErrors.name}</span>
          )}
        </Col>
      </Row>
      <Row className="mt-20">
        <Col span={8}>
          <span>
            <span className="red-color">*</span> Website
          </span>
          {loader ? (
            <Row>
              <Skeleton.Input active className="w-100" />
            </Row>
          ) : (
            <Input
              className="mt-5"
              name="website"
              value={organizationData.website}
              onChange={(e) => handleChange(e.target)}
            />
          )}

          {formErrors.website && (
            <span style={{ color: 'red' }}>{formErrors.website}</span>
          )}
        </Col>
        <Col span={8} className="ml-20">
          <span> Contact number</span>
          {loader ? (
            <Row>
              <Skeleton.Input active className="w-100" />
            </Row>
          ) : (
            <PhoneInput
              value={organizationData.contactNumber}
              className="mt-5"
              onChange={handlePhoneChange}
            />
          )}
          {formErrors.contactNumber && (
            <div className="red-color">{formErrors.contactNumber}</div>
          )}
        </Col>
      </Row>
      <Typography.Title level={5} className="mb-10 mt-20">
        Address Information
      </Typography.Title>
      <Row>
        <Col span={8}>
          <span> Street</span>
          {loader ? (
            <Row>
              <Skeleton.Input active className="w-100" />
            </Row>
          ) : (
            <TextArea
              rows={2}
              className="mt-5"
              name="street"
              value={organizationData.street}
              onChange={(e) => handleChange(e.target)}
            />
          )}
        </Col>
      </Row>

      <Col span={8} className="mt-20">
        <Row justify="space-between">
          <Col span={10}>
            <span> Zip/Postal code</span>
            {loader ? (
              <Row>
                <Skeleton.Input active className="w-100" />
              </Row>
            ) : (
              <Input
                className="mt-5"
                value={organizationData.zipCode}
                onChange={handleZipCodeChange}
              />
            )}
          </Col>

          <Col span={10}>
            <span> City</span>
            {loader ? (
              <Row>
                <Skeleton.Input active className="w-100" />
              </Row>
            ) : (
              <Input
                className="mt-5"
                name="city"
                value={organizationData.city}
                onChange={(e) => handleChange(e.target)}
              />
            )}
          </Col>
        </Row>
      </Col>
      <Col span={8} className="mt-20">
        <Row justify="space-between">
          <Col span={10}>
            <span> State</span>
            {loader ? (
              <Row>
                <Skeleton.Input active className="w-100" />
              </Row>
            ) : (
              <Input
                className="mt-5"
                name="state"
                value={organizationData.state}
                onChange={(e) => handleChange(e.target)}
              />
            )}
          </Col>
          <Col span={10}>
            <span> Country</span>
            {loader ? (
              <Row>
                <Skeleton.Input active className="w-100" />
              </Row>
            ) : (
              <Input
                className="mt-5"
                name="country"
                value={organizationData.country}
                onChange={(e) => handleChange(e.target)}
              />
            )}
          </Col>
        </Row>
      </Col>

      <>
        <Divider />
        <Typography.Title level={5}>Primary Contact(Admin) </Typography.Title>
        <Row>
          <Col span={8}>
            <span>
              {' '}
              <span className="red-color">*</span>First name
            </span>
            {loader ? (
              <Row>
                <Skeleton.Input active className="w-100" />
              </Row>
            ) : (
              <Input
                className="mt-5"
                name="firstName"
                disabled={id ? true : false}
                value={organizationData.firstName}
                onChange={(e) => handleChange(e.target)}
              />
            )}
            {formErrors.firstName && (
              <span style={{ color: 'red' }}>{formErrors.firstName}</span>
            )}
          </Col>
          <Col span={8} className="ml-20">
            <span>
              {' '}
              <span className="red-color">*</span>Last name
            </span>
            {loader ? (
              <Row>
                <Skeleton.Input active className="w-100" />
              </Row>
            ) : (
              <Input
                className="mt-5"
                name="lastName"
                disabled={id ? true : false}
                value={organizationData.lastName}
                onChange={(e) => handleChange(e.target)}
              />
            )}
            {formErrors.lastName && (
              <span style={{ color: 'red' }}>{formErrors.lastName}</span>
            )}
          </Col>
        </Row>
        <Row className="mt-20">
          <Col span={8}>
            <span>
              <span className="red-color">*</span>
              Email ID{' '}
              {id ? '' : '(An invitation email will be sent to this email)'}
            </span>
            {loader ? (
              <Row>
                <Skeleton.Input active className="w-100" />
              </Row>
            ) : id ? (
              <Select
                name="email"
                onChange={(value) => handleSelectChange('email', value)}
                className="w-100 mt-5"
                value={organizationData.email}
                showSearch
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0 ||
                  option.props.value
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {userData?.map((item, index) => {
                  return (
                    <Option key={index} value={item?.email}>
                      {item?.email}
                    </Option>
                  );
                })}
              </Select>
            ) : (
              <Input
                className="mt-5"
                name="email"
                value={organizationData.email}
                onChange={(e) => handleChange(e.target)}
              />
            )}

            {formErrors.email && (
              <span style={{ color: 'red' }}>{formErrors.email}</span>
            )}
          </Col>
          <Col span={8} className="ml-20">
            <span> Contact number</span>
            {loader ? (
              <Row>
                <Skeleton.Input active className="w-100" />
              </Row>
            ) : (
              <PhoneInput
                value={organizationData.adminContactNumber}
                className="mt-5"
                onChange={handleAdminPhoneChange}
              />
            )}
            {formErrors.adminContactNumber && (
              <span style={{ color: 'red' }}>
                {formErrors.adminContactNumber}
              </span>
            )}
          </Col>
        </Row>
      </>

      <Divider />
      <Row justify="space-between">
        <Typography.Title level={5}>Customize Branding</Typography.Title>
        <div>
          <Button
            onClick={handleReset}
            disabled={
              loader
                ? true
                : resetFunction && !imagePreview
                ? true
                : id
                ? false
                : imagePreview || colorChange
                ? false
                : true
            }
          >
            Reset
          </Button>
          <Button
            className="ml-5"
            onClick={() => setPreviewModal(true)}
            disabled={loader ? true : previewDisable ? true : false}
          >
            Preview
          </Button>
        </div>
      </Row>
      <Row>
        <Typography.Text className="mb-10 fw-500">
          <span className="red-color">*</span> Logo for dark background
        </Typography.Text>
      </Row>
      {imagePreview && uploadLogo ? (
        <div className="static-image">
          <img
            src={imagePreview}
            alt="Organization Logo"
            style={{
              background: organizationData.navHeaderColor,
              padding: '10px',
              height: '60px',
            }}
          />
          <CloseCircleOutlined
            className="close-icon"
            onClick={() => {
              setUploadLogo(false);
              setPreviewDisable(true);
            }}
          />
        </div>
      ) : (
        organizationData.logo &&
        uploadLogo && (
          <div className="static-image">
            <img
              src={organizationData.logo}
              alt="Organization Logo"
              style={{
                background: organizationData.navHeaderColor,
                padding: '10px',
                height: '60px',
              }}
            />
            <CloseCircleOutlined
              className="close-icon"
              onClick={() => {
                setUploadLogo(false);
                setPreviewDisable(true);
              }}
            />
          </div>
        )
      )}

      {!uploadLogo && (
        <Upload {...props}>
          <Button onClick={() => setPreviewDisable(false)}>
            Click to Upload
          </Button>
        </Upload>
      )}
      {!uploadLogo && <span style={{ color: 'red' }}>Please upload logo</span>}
      <Row className="mt-20">
        <Col span={8}>
          <span className="fw-500">Navigation header color</span>

          <Input
            className="mt-5"
            addonAfter={
              <Space>
                <BgColorsOutlined
                  style={{
                    fontSize: '20px',
                    color: organizationData.navHeaderColor,
                  }}
                  onClick={() => setShowPicker(!showPicker)}
                />
              </Space>
            }
            value={organizationData.navHeaderColor}
            readOnly
          />

          {showPicker && (
            <div ref={pickerRef}>
              <SketchPicker
                color={organizationData.navHeaderColor}
                onChange={handleColorChange}
              />
            </div>
          )}
        </Col>
      </Row>
      <Divider />
      {id && (
        <Button
          onClick={() => {
            history.push({ pathname: `/organization/detail/${id}` });
          }}
        >
          Cancel
        </Button>
      )}
      <Button
        type="primary"
        className="ml-5"
        htmlType="submit"
        onClick={handleSumbit}
        disabled={loading ? true : !uploadLogo ? true : false}
        loading={loading}
      >
        Save
      </Button>
      <BrandingPreview
        brandingLogoByOrgId={organizationData.logo}
        brandingByOrgId={organizationData}
        customLogo={customLogo}
        previewModal={previewModal}
        setPreviewModal={setPreviewModal}
      />
      <LeaveMFModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </div>
  );
};

export default AddOrganization;

