/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const updateOrganizationGammaCount = /* GraphQL */ `
  mutation UpdateOrganizationGammaCount(
    $input: UpdateOrganizationGammaCountInput!
  ) {
    updateOrganizationGammaCount(input: $input) {
      id
      name
      website
      contact
      address {
        street
        city
        zip
        state
        country
        __typename
      }
      vision {
        key
        value
        __typename
      }
      apiKeys {
        items {
          id
          organizationID
          key
          keyID
          disabled
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      stages {
        items {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      Departments {
        items {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      adminID
      Admin {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      type
      partnerID
      Partner {
        id
        name
        website
        contact
        address {
          street
          city
          zip
          state
          country
          __typename
        }
        vision {
          key
          value
          __typename
        }
        apiKeys {
          nextToken
          __typename
        }
        stages {
          nextToken
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        adminID
        Admin {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        type
        partnerID
        Partner {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Clients {
          nextToken
          __typename
        }
        Users {
          nextToken
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        gammas {
          nextToken
          __typename
        }
        gammaCount
        organizationDefaultPrioirtybatchId
        defaultPrioirtybatch {
          id
          title
          description
          public
          organizationID
          owners
          updatedAtBy
          createdAt
          updatedAt
          __typename
        }
        priorityBatches {
          nextToken
          __typename
        }
        winningCriteria
        scoreWeightages
        objectives {
          nextToken
          __typename
        }
        categoryValues
        weightSettings {
          role
          weight
          __typename
        }
        comparisonFlags
        ratingFlags
        invitationReminderConfig
        inactivityReminderConfig
        scenarioWeightsFlag
        measurementFields {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      Clients {
        items {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      Users {
        items {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      UserVotes {
        items {
          id
          vote
          weight
          userID
          organizationID
          gammaID
          userVoteObjectiveId
          voteTime
          createdAt
          updatedAt
          userVoteVsGammaId
          __typename
        }
        nextToken
        __typename
      }
      UserRatings {
        items {
          id
          userID
          organizationID
          gammaID
          rating
          weight
          userRatingObjectiveId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      gammas {
        items {
          id
          friendlyId
          levelID
          title
          description
          userID
          organizationID
          departments
          ownerEmail
          sponsorID
          contributors
          fixedRank
          rank
          updatedByIAM
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      gammaCount
      organizationDefaultPrioirtybatchId
      defaultPrioirtybatch {
        id
        title
        description
        priorities {
          weight
          ObjectiveID
          __typename
        }
        voteFilters {
          match
          __typename
        }
        measurementFieldFilters {
          match
          __typename
        }
        fieldFilters {
          match
          __typename
        }
        public
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        owners
        updatedAtBy
        createdAt
        updatedAt
        __typename
      }
      priorityBatches {
        items {
          id
          title
          description
          public
          organizationID
          owners
          updatedAtBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      winningCriteria
      scoreWeightages
      objectives {
        items {
          id
          name
          description
          active
          organizationObjectivesId
          ownerId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      categoryValues
      weightSettings {
        role
        weight
        __typename
      }
      comparisonFlags
      ratingFlags
      invitationReminderConfig
      inactivityReminderConfig
      scenarioWeightsFlag
      measurementFields {
        items {
          id
          name
          description
          type
          subType
          isMeasurement
          organizationID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createApiKey = /* GraphQL */ `
  mutation CreateApiKey(
    $input: CreateApiKeyInput!
    $condition: ModelApiKeyConditionInput
  ) {
    createApiKey(input: $input, condition: $condition) {
      id
      organizationID
      Organization {
        id
        name
        website
        contact
        address {
          street
          city
          zip
          state
          country
          __typename
        }
        vision {
          key
          value
          __typename
        }
        apiKeys {
          nextToken
          __typename
        }
        stages {
          nextToken
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        adminID
        Admin {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        type
        partnerID
        Partner {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Clients {
          nextToken
          __typename
        }
        Users {
          nextToken
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        gammas {
          nextToken
          __typename
        }
        gammaCount
        organizationDefaultPrioirtybatchId
        defaultPrioirtybatch {
          id
          title
          description
          public
          organizationID
          owners
          updatedAtBy
          createdAt
          updatedAt
          __typename
        }
        priorityBatches {
          nextToken
          __typename
        }
        winningCriteria
        scoreWeightages
        objectives {
          nextToken
          __typename
        }
        categoryValues
        weightSettings {
          role
          weight
          __typename
        }
        comparisonFlags
        ratingFlags
        invitationReminderConfig
        inactivityReminderConfig
        scenarioWeightsFlag
        measurementFields {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      key
      keyID
      disabled
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateApiKey = /* GraphQL */ `
  mutation UpdateApiKey(
    $input: UpdateApiKeyInput!
    $condition: ModelApiKeyConditionInput
  ) {
    updateApiKey(input: $input, condition: $condition) {
      id
      organizationID
      Organization {
        id
        name
        website
        contact
        address {
          street
          city
          zip
          state
          country
          __typename
        }
        vision {
          key
          value
          __typename
        }
        apiKeys {
          nextToken
          __typename
        }
        stages {
          nextToken
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        adminID
        Admin {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        type
        partnerID
        Partner {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Clients {
          nextToken
          __typename
        }
        Users {
          nextToken
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        gammas {
          nextToken
          __typename
        }
        gammaCount
        organizationDefaultPrioirtybatchId
        defaultPrioirtybatch {
          id
          title
          description
          public
          organizationID
          owners
          updatedAtBy
          createdAt
          updatedAt
          __typename
        }
        priorityBatches {
          nextToken
          __typename
        }
        winningCriteria
        scoreWeightages
        objectives {
          nextToken
          __typename
        }
        categoryValues
        weightSettings {
          role
          weight
          __typename
        }
        comparisonFlags
        ratingFlags
        invitationReminderConfig
        inactivityReminderConfig
        scenarioWeightsFlag
        measurementFields {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      key
      keyID
      disabled
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteApiKey = /* GraphQL */ `
  mutation DeleteApiKey(
    $input: DeleteApiKeyInput!
    $condition: ModelApiKeyConditionInput
  ) {
    deleteApiKey(input: $input, condition: $condition) {
      id
      organizationID
      Organization {
        id
        name
        website
        contact
        address {
          street
          city
          zip
          state
          country
          __typename
        }
        vision {
          key
          value
          __typename
        }
        apiKeys {
          nextToken
          __typename
        }
        stages {
          nextToken
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        adminID
        Admin {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        type
        partnerID
        Partner {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Clients {
          nextToken
          __typename
        }
        Users {
          nextToken
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        gammas {
          nextToken
          __typename
        }
        gammaCount
        organizationDefaultPrioirtybatchId
        defaultPrioirtybatch {
          id
          title
          description
          public
          organizationID
          owners
          updatedAtBy
          createdAt
          updatedAt
          __typename
        }
        priorityBatches {
          nextToken
          __typename
        }
        winningCriteria
        scoreWeightages
        objectives {
          nextToken
          __typename
        }
        categoryValues
        weightSettings {
          role
          weight
          __typename
        }
        comparisonFlags
        ratingFlags
        invitationReminderConfig
        inactivityReminderConfig
        scenarioWeightsFlag
        measurementFields {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      key
      keyID
      disabled
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createWebhooksSubscription = /* GraphQL */ `
  mutation CreateWebhooksSubscription(
    $input: CreateWebhooksSubscriptionInput!
    $condition: ModelWebhooksSubscriptionConditionInput
  ) {
    createWebhooksSubscription(input: $input, condition: $condition) {
      id
      organizationID
      typeName
      eventName
      hookUrl
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateWebhooksSubscription = /* GraphQL */ `
  mutation UpdateWebhooksSubscription(
    $input: UpdateWebhooksSubscriptionInput!
    $condition: ModelWebhooksSubscriptionConditionInput
  ) {
    updateWebhooksSubscription(input: $input, condition: $condition) {
      id
      organizationID
      typeName
      eventName
      hookUrl
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteWebhooksSubscription = /* GraphQL */ `
  mutation DeleteWebhooksSubscription(
    $input: DeleteWebhooksSubscriptionInput!
    $condition: ModelWebhooksSubscriptionConditionInput
  ) {
    deleteWebhooksSubscription(input: $input, condition: $condition) {
      id
      organizationID
      typeName
      eventName
      hookUrl
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createLoginHistory = /* GraphQL */ `
  mutation CreateLoginHistory(
    $input: CreateLoginHistoryInput!
    $condition: ModelLoginHistoryConditionInput
  ) {
    createLoginHistory(input: $input, condition: $condition) {
      userID
      User {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      date
      datetime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateLoginHistory = /* GraphQL */ `
  mutation UpdateLoginHistory(
    $input: UpdateLoginHistoryInput!
    $condition: ModelLoginHistoryConditionInput
  ) {
    updateLoginHistory(input: $input, condition: $condition) {
      userID
      User {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      date
      datetime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteLoginHistory = /* GraphQL */ `
  mutation DeleteLoginHistory(
    $input: DeleteLoginHistoryInput!
    $condition: ModelLoginHistoryConditionInput
  ) {
    deleteLoginHistory(input: $input, condition: $condition) {
      userID
      User {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      date
      datetime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createLatestRankStatus = /* GraphQL */ `
  mutation CreateLatestRankStatus(
    $input: CreateLatestRankStatusInput!
    $condition: ModelLatestRankStatusConditionInput
  ) {
    createLatestRankStatus(input: $input, condition: $condition) {
      organizationID
      organization {
        id
        name
        website
        contact
        address {
          street
          city
          zip
          state
          country
          __typename
        }
        vision {
          key
          value
          __typename
        }
        apiKeys {
          nextToken
          __typename
        }
        stages {
          nextToken
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        adminID
        Admin {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        type
        partnerID
        Partner {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Clients {
          nextToken
          __typename
        }
        Users {
          nextToken
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        gammas {
          nextToken
          __typename
        }
        gammaCount
        organizationDefaultPrioirtybatchId
        defaultPrioirtybatch {
          id
          title
          description
          public
          organizationID
          owners
          updatedAtBy
          createdAt
          updatedAt
          __typename
        }
        priorityBatches {
          nextToken
          __typename
        }
        winningCriteria
        scoreWeightages
        objectives {
          nextToken
          __typename
        }
        categoryValues
        weightSettings {
          role
          weight
          __typename
        }
        comparisonFlags
        ratingFlags
        invitationReminderConfig
        inactivityReminderConfig
        scenarioWeightsFlag
        measurementFields {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      timestamp
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateLatestRankStatus = /* GraphQL */ `
  mutation UpdateLatestRankStatus(
    $input: UpdateLatestRankStatusInput!
    $condition: ModelLatestRankStatusConditionInput
  ) {
    updateLatestRankStatus(input: $input, condition: $condition) {
      organizationID
      organization {
        id
        name
        website
        contact
        address {
          street
          city
          zip
          state
          country
          __typename
        }
        vision {
          key
          value
          __typename
        }
        apiKeys {
          nextToken
          __typename
        }
        stages {
          nextToken
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        adminID
        Admin {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        type
        partnerID
        Partner {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Clients {
          nextToken
          __typename
        }
        Users {
          nextToken
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        gammas {
          nextToken
          __typename
        }
        gammaCount
        organizationDefaultPrioirtybatchId
        defaultPrioirtybatch {
          id
          title
          description
          public
          organizationID
          owners
          updatedAtBy
          createdAt
          updatedAt
          __typename
        }
        priorityBatches {
          nextToken
          __typename
        }
        winningCriteria
        scoreWeightages
        objectives {
          nextToken
          __typename
        }
        categoryValues
        weightSettings {
          role
          weight
          __typename
        }
        comparisonFlags
        ratingFlags
        invitationReminderConfig
        inactivityReminderConfig
        scenarioWeightsFlag
        measurementFields {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      timestamp
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteLatestRankStatus = /* GraphQL */ `
  mutation DeleteLatestRankStatus(
    $input: DeleteLatestRankStatusInput!
    $condition: ModelLatestRankStatusConditionInput
  ) {
    deleteLatestRankStatus(input: $input, condition: $condition) {
      organizationID
      organization {
        id
        name
        website
        contact
        address {
          street
          city
          zip
          state
          country
          __typename
        }
        vision {
          key
          value
          __typename
        }
        apiKeys {
          nextToken
          __typename
        }
        stages {
          nextToken
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        adminID
        Admin {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        type
        partnerID
        Partner {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Clients {
          nextToken
          __typename
        }
        Users {
          nextToken
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        gammas {
          nextToken
          __typename
        }
        gammaCount
        organizationDefaultPrioirtybatchId
        defaultPrioirtybatch {
          id
          title
          description
          public
          organizationID
          owners
          updatedAtBy
          createdAt
          updatedAt
          __typename
        }
        priorityBatches {
          nextToken
          __typename
        }
        winningCriteria
        scoreWeightages
        objectives {
          nextToken
          __typename
        }
        categoryValues
        weightSettings {
          role
          weight
          __typename
        }
        comparisonFlags
        ratingFlags
        invitationReminderConfig
        inactivityReminderConfig
        scenarioWeightsFlag
        measurementFields {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      timestamp
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserRating = /* GraphQL */ `
  mutation CreateUserRating(
    $input: CreateUserRatingInput!
    $condition: ModelUserRatingConditionInput
  ) {
    createUserRating(input: $input, condition: $condition) {
      id
      userID
      User {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      organizationID
      Organization {
        id
        name
        website
        contact
        address {
          street
          city
          zip
          state
          country
          __typename
        }
        vision {
          key
          value
          __typename
        }
        apiKeys {
          nextToken
          __typename
        }
        stages {
          nextToken
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        adminID
        Admin {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        type
        partnerID
        Partner {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Clients {
          nextToken
          __typename
        }
        Users {
          nextToken
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        gammas {
          nextToken
          __typename
        }
        gammaCount
        organizationDefaultPrioirtybatchId
        defaultPrioirtybatch {
          id
          title
          description
          public
          organizationID
          owners
          updatedAtBy
          createdAt
          updatedAt
          __typename
        }
        priorityBatches {
          nextToken
          __typename
        }
        winningCriteria
        scoreWeightages
        objectives {
          nextToken
          __typename
        }
        categoryValues
        weightSettings {
          role
          weight
          __typename
        }
        comparisonFlags
        ratingFlags
        invitationReminderConfig
        inactivityReminderConfig
        scenarioWeightsFlag
        measurementFields {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      gammaID
      Gamma {
        id
        friendlyId
        levelID
        level {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        levelHistory {
          nextToken
          __typename
        }
        title
        description
        measurementFields {
          measurementFieldID
          value
          __typename
        }
        additionalFields {
          name
          __typename
        }
        rankHistory {
          D
          R
          S
          __typename
        }
        mapping {
          gammaID
          organizationID
          mapping
          metadata
          createdAt
          updatedAt
          __typename
        }
        userID
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departments
        UserVotes {
          nextToken
          __typename
        }
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        ownerEmail
        hiddenUsers {
          nextToken
          __typename
        }
        sponsorID
        sponsor {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        contributors
        linkedTo {
          nextToken
          __typename
        }
        userRatings {
          nextToken
          __typename
        }
        fixedRank
        rank
        updatedByIAM
        createdAt
        updatedAt
        __typename
      }
      rating
      weight
      userRatingObjectiveId
      Objective {
        id
        name
        description
        active
        organizationObjectivesId
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        ownerId
        owner {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserRating = /* GraphQL */ `
  mutation UpdateUserRating(
    $input: UpdateUserRatingInput!
    $condition: ModelUserRatingConditionInput
  ) {
    updateUserRating(input: $input, condition: $condition) {
      id
      userID
      User {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      organizationID
      Organization {
        id
        name
        website
        contact
        address {
          street
          city
          zip
          state
          country
          __typename
        }
        vision {
          key
          value
          __typename
        }
        apiKeys {
          nextToken
          __typename
        }
        stages {
          nextToken
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        adminID
        Admin {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        type
        partnerID
        Partner {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Clients {
          nextToken
          __typename
        }
        Users {
          nextToken
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        gammas {
          nextToken
          __typename
        }
        gammaCount
        organizationDefaultPrioirtybatchId
        defaultPrioirtybatch {
          id
          title
          description
          public
          organizationID
          owners
          updatedAtBy
          createdAt
          updatedAt
          __typename
        }
        priorityBatches {
          nextToken
          __typename
        }
        winningCriteria
        scoreWeightages
        objectives {
          nextToken
          __typename
        }
        categoryValues
        weightSettings {
          role
          weight
          __typename
        }
        comparisonFlags
        ratingFlags
        invitationReminderConfig
        inactivityReminderConfig
        scenarioWeightsFlag
        measurementFields {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      gammaID
      Gamma {
        id
        friendlyId
        levelID
        level {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        levelHistory {
          nextToken
          __typename
        }
        title
        description
        measurementFields {
          measurementFieldID
          value
          __typename
        }
        additionalFields {
          name
          __typename
        }
        rankHistory {
          D
          R
          S
          __typename
        }
        mapping {
          gammaID
          organizationID
          mapping
          metadata
          createdAt
          updatedAt
          __typename
        }
        userID
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departments
        UserVotes {
          nextToken
          __typename
        }
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        ownerEmail
        hiddenUsers {
          nextToken
          __typename
        }
        sponsorID
        sponsor {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        contributors
        linkedTo {
          nextToken
          __typename
        }
        userRatings {
          nextToken
          __typename
        }
        fixedRank
        rank
        updatedByIAM
        createdAt
        updatedAt
        __typename
      }
      rating
      weight
      userRatingObjectiveId
      Objective {
        id
        name
        description
        active
        organizationObjectivesId
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        ownerId
        owner {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserRating = /* GraphQL */ `
  mutation DeleteUserRating(
    $input: DeleteUserRatingInput!
    $condition: ModelUserRatingConditionInput
  ) {
    deleteUserRating(input: $input, condition: $condition) {
      id
      userID
      User {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      organizationID
      Organization {
        id
        name
        website
        contact
        address {
          street
          city
          zip
          state
          country
          __typename
        }
        vision {
          key
          value
          __typename
        }
        apiKeys {
          nextToken
          __typename
        }
        stages {
          nextToken
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        adminID
        Admin {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        type
        partnerID
        Partner {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Clients {
          nextToken
          __typename
        }
        Users {
          nextToken
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        gammas {
          nextToken
          __typename
        }
        gammaCount
        organizationDefaultPrioirtybatchId
        defaultPrioirtybatch {
          id
          title
          description
          public
          organizationID
          owners
          updatedAtBy
          createdAt
          updatedAt
          __typename
        }
        priorityBatches {
          nextToken
          __typename
        }
        winningCriteria
        scoreWeightages
        objectives {
          nextToken
          __typename
        }
        categoryValues
        weightSettings {
          role
          weight
          __typename
        }
        comparisonFlags
        ratingFlags
        invitationReminderConfig
        inactivityReminderConfig
        scenarioWeightsFlag
        measurementFields {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      gammaID
      Gamma {
        id
        friendlyId
        levelID
        level {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        levelHistory {
          nextToken
          __typename
        }
        title
        description
        measurementFields {
          measurementFieldID
          value
          __typename
        }
        additionalFields {
          name
          __typename
        }
        rankHistory {
          D
          R
          S
          __typename
        }
        mapping {
          gammaID
          organizationID
          mapping
          metadata
          createdAt
          updatedAt
          __typename
        }
        userID
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departments
        UserVotes {
          nextToken
          __typename
        }
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        ownerEmail
        hiddenUsers {
          nextToken
          __typename
        }
        sponsorID
        sponsor {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        contributors
        linkedTo {
          nextToken
          __typename
        }
        userRatings {
          nextToken
          __typename
        }
        fixedRank
        rank
        updatedByIAM
        createdAt
        updatedAt
        __typename
      }
      rating
      weight
      userRatingObjectiveId
      Objective {
        id
        name
        description
        active
        organizationObjectivesId
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        ownerId
        owner {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPriorityBatch = /* GraphQL */ `
  mutation CreatePriorityBatch(
    $input: CreatePriorityBatchInput!
    $condition: ModelPriorityBatchConditionInput
  ) {
    createPriorityBatch(input: $input, condition: $condition) {
      id
      title
      description
      priorities {
        weight
        ObjectiveID
        __typename
      }
      voteFilters {
        match
        filters {
          __typename
        }
        __typename
      }
      measurementFieldFilters {
        match
        filters {
          measurementField
          measurementFieldID
          blanks
          threshold
          __typename
        }
        __typename
      }
      fieldFilters {
        match
        filters {
          fieldID
          blanks
          field
          __typename
        }
        __typename
      }
      public
      organizationID
      Organization {
        id
        name
        website
        contact
        address {
          street
          city
          zip
          state
          country
          __typename
        }
        vision {
          key
          value
          __typename
        }
        apiKeys {
          nextToken
          __typename
        }
        stages {
          nextToken
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        adminID
        Admin {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        type
        partnerID
        Partner {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Clients {
          nextToken
          __typename
        }
        Users {
          nextToken
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        gammas {
          nextToken
          __typename
        }
        gammaCount
        organizationDefaultPrioirtybatchId
        defaultPrioirtybatch {
          id
          title
          description
          public
          organizationID
          owners
          updatedAtBy
          createdAt
          updatedAt
          __typename
        }
        priorityBatches {
          nextToken
          __typename
        }
        winningCriteria
        scoreWeightages
        objectives {
          nextToken
          __typename
        }
        categoryValues
        weightSettings {
          role
          weight
          __typename
        }
        comparisonFlags
        ratingFlags
        invitationReminderConfig
        inactivityReminderConfig
        scenarioWeightsFlag
        measurementFields {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      owners
      updatedAtBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePriorityBatch = /* GraphQL */ `
  mutation UpdatePriorityBatch(
    $input: UpdatePriorityBatchInput!
    $condition: ModelPriorityBatchConditionInput
  ) {
    updatePriorityBatch(input: $input, condition: $condition) {
      id
      title
      description
      priorities {
        weight
        ObjectiveID
        __typename
      }
      voteFilters {
        match
        filters {
          __typename
        }
        __typename
      }
      measurementFieldFilters {
        match
        filters {
          measurementField
          measurementFieldID
          blanks
          threshold
          __typename
        }
        __typename
      }
      fieldFilters {
        match
        filters {
          fieldID
          blanks
          field
          __typename
        }
        __typename
      }
      public
      organizationID
      Organization {
        id
        name
        website
        contact
        address {
          street
          city
          zip
          state
          country
          __typename
        }
        vision {
          key
          value
          __typename
        }
        apiKeys {
          nextToken
          __typename
        }
        stages {
          nextToken
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        adminID
        Admin {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        type
        partnerID
        Partner {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Clients {
          nextToken
          __typename
        }
        Users {
          nextToken
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        gammas {
          nextToken
          __typename
        }
        gammaCount
        organizationDefaultPrioirtybatchId
        defaultPrioirtybatch {
          id
          title
          description
          public
          organizationID
          owners
          updatedAtBy
          createdAt
          updatedAt
          __typename
        }
        priorityBatches {
          nextToken
          __typename
        }
        winningCriteria
        scoreWeightages
        objectives {
          nextToken
          __typename
        }
        categoryValues
        weightSettings {
          role
          weight
          __typename
        }
        comparisonFlags
        ratingFlags
        invitationReminderConfig
        inactivityReminderConfig
        scenarioWeightsFlag
        measurementFields {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      owners
      updatedAtBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePriorityBatch = /* GraphQL */ `
  mutation DeletePriorityBatch(
    $input: DeletePriorityBatchInput!
    $condition: ModelPriorityBatchConditionInput
  ) {
    deletePriorityBatch(input: $input, condition: $condition) {
      id
      title
      description
      priorities {
        weight
        ObjectiveID
        __typename
      }
      voteFilters {
        match
        filters {
          __typename
        }
        __typename
      }
      measurementFieldFilters {
        match
        filters {
          measurementField
          measurementFieldID
          blanks
          threshold
          __typename
        }
        __typename
      }
      fieldFilters {
        match
        filters {
          fieldID
          blanks
          field
          __typename
        }
        __typename
      }
      public
      organizationID
      Organization {
        id
        name
        website
        contact
        address {
          street
          city
          zip
          state
          country
          __typename
        }
        vision {
          key
          value
          __typename
        }
        apiKeys {
          nextToken
          __typename
        }
        stages {
          nextToken
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        adminID
        Admin {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        type
        partnerID
        Partner {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Clients {
          nextToken
          __typename
        }
        Users {
          nextToken
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        gammas {
          nextToken
          __typename
        }
        gammaCount
        organizationDefaultPrioirtybatchId
        defaultPrioirtybatch {
          id
          title
          description
          public
          organizationID
          owners
          updatedAtBy
          createdAt
          updatedAt
          __typename
        }
        priorityBatches {
          nextToken
          __typename
        }
        winningCriteria
        scoreWeightages
        objectives {
          nextToken
          __typename
        }
        categoryValues
        weightSettings {
          role
          weight
          __typename
        }
        comparisonFlags
        ratingFlags
        invitationReminderConfig
        inactivityReminderConfig
        scenarioWeightsFlag
        measurementFields {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      owners
      updatedAtBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createObjective = /* GraphQL */ `
  mutation CreateObjective(
    $input: CreateObjectiveInput!
    $condition: ModelObjectiveConditionInput
  ) {
    createObjective(input: $input, condition: $condition) {
      id
      name
      description
      active
      organizationObjectivesId
      Organization {
        id
        name
        website
        contact
        address {
          street
          city
          zip
          state
          country
          __typename
        }
        vision {
          key
          value
          __typename
        }
        apiKeys {
          nextToken
          __typename
        }
        stages {
          nextToken
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        adminID
        Admin {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        type
        partnerID
        Partner {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Clients {
          nextToken
          __typename
        }
        Users {
          nextToken
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        gammas {
          nextToken
          __typename
        }
        gammaCount
        organizationDefaultPrioirtybatchId
        defaultPrioirtybatch {
          id
          title
          description
          public
          organizationID
          owners
          updatedAtBy
          createdAt
          updatedAt
          __typename
        }
        priorityBatches {
          nextToken
          __typename
        }
        winningCriteria
        scoreWeightages
        objectives {
          nextToken
          __typename
        }
        categoryValues
        weightSettings {
          role
          weight
          __typename
        }
        comparisonFlags
        ratingFlags
        invitationReminderConfig
        inactivityReminderConfig
        scenarioWeightsFlag
        measurementFields {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      ownerId
      owner {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      Departments {
        items {
          id
          objectiveID
          departmentID
          ownerID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateObjective = /* GraphQL */ `
  mutation UpdateObjective(
    $input: UpdateObjectiveInput!
    $condition: ModelObjectiveConditionInput
  ) {
    updateObjective(input: $input, condition: $condition) {
      id
      name
      description
      active
      organizationObjectivesId
      Organization {
        id
        name
        website
        contact
        address {
          street
          city
          zip
          state
          country
          __typename
        }
        vision {
          key
          value
          __typename
        }
        apiKeys {
          nextToken
          __typename
        }
        stages {
          nextToken
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        adminID
        Admin {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        type
        partnerID
        Partner {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Clients {
          nextToken
          __typename
        }
        Users {
          nextToken
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        gammas {
          nextToken
          __typename
        }
        gammaCount
        organizationDefaultPrioirtybatchId
        defaultPrioirtybatch {
          id
          title
          description
          public
          organizationID
          owners
          updatedAtBy
          createdAt
          updatedAt
          __typename
        }
        priorityBatches {
          nextToken
          __typename
        }
        winningCriteria
        scoreWeightages
        objectives {
          nextToken
          __typename
        }
        categoryValues
        weightSettings {
          role
          weight
          __typename
        }
        comparisonFlags
        ratingFlags
        invitationReminderConfig
        inactivityReminderConfig
        scenarioWeightsFlag
        measurementFields {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      ownerId
      owner {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      Departments {
        items {
          id
          objectiveID
          departmentID
          ownerID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteObjective = /* GraphQL */ `
  mutation DeleteObjective(
    $input: DeleteObjectiveInput!
    $condition: ModelObjectiveConditionInput
  ) {
    deleteObjective(input: $input, condition: $condition) {
      id
      name
      description
      active
      organizationObjectivesId
      Organization {
        id
        name
        website
        contact
        address {
          street
          city
          zip
          state
          country
          __typename
        }
        vision {
          key
          value
          __typename
        }
        apiKeys {
          nextToken
          __typename
        }
        stages {
          nextToken
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        adminID
        Admin {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        type
        partnerID
        Partner {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Clients {
          nextToken
          __typename
        }
        Users {
          nextToken
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        gammas {
          nextToken
          __typename
        }
        gammaCount
        organizationDefaultPrioirtybatchId
        defaultPrioirtybatch {
          id
          title
          description
          public
          organizationID
          owners
          updatedAtBy
          createdAt
          updatedAt
          __typename
        }
        priorityBatches {
          nextToken
          __typename
        }
        winningCriteria
        scoreWeightages
        objectives {
          nextToken
          __typename
        }
        categoryValues
        weightSettings {
          role
          weight
          __typename
        }
        comparisonFlags
        ratingFlags
        invitationReminderConfig
        inactivityReminderConfig
        scenarioWeightsFlag
        measurementFields {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      ownerId
      owner {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      Departments {
        items {
          id
          objectiveID
          departmentID
          ownerID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserVote = /* GraphQL */ `
  mutation CreateUserVote(
    $input: CreateUserVoteInput!
    $condition: ModelUserVoteConditionInput
  ) {
    createUserVote(input: $input, condition: $condition) {
      id
      vote
      weight
      userID
      User {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      organizationID
      Organization {
        id
        name
        website
        contact
        address {
          street
          city
          zip
          state
          country
          __typename
        }
        vision {
          key
          value
          __typename
        }
        apiKeys {
          nextToken
          __typename
        }
        stages {
          nextToken
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        adminID
        Admin {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        type
        partnerID
        Partner {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Clients {
          nextToken
          __typename
        }
        Users {
          nextToken
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        gammas {
          nextToken
          __typename
        }
        gammaCount
        organizationDefaultPrioirtybatchId
        defaultPrioirtybatch {
          id
          title
          description
          public
          organizationID
          owners
          updatedAtBy
          createdAt
          updatedAt
          __typename
        }
        priorityBatches {
          nextToken
          __typename
        }
        winningCriteria
        scoreWeightages
        objectives {
          nextToken
          __typename
        }
        categoryValues
        weightSettings {
          role
          weight
          __typename
        }
        comparisonFlags
        ratingFlags
        invitationReminderConfig
        inactivityReminderConfig
        scenarioWeightsFlag
        measurementFields {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      gammaID
      Gamma {
        id
        friendlyId
        levelID
        level {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        levelHistory {
          nextToken
          __typename
        }
        title
        description
        measurementFields {
          measurementFieldID
          value
          __typename
        }
        additionalFields {
          name
          __typename
        }
        rankHistory {
          D
          R
          S
          __typename
        }
        mapping {
          gammaID
          organizationID
          mapping
          metadata
          createdAt
          updatedAt
          __typename
        }
        userID
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departments
        UserVotes {
          nextToken
          __typename
        }
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        ownerEmail
        hiddenUsers {
          nextToken
          __typename
        }
        sponsorID
        sponsor {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        contributors
        linkedTo {
          nextToken
          __typename
        }
        userRatings {
          nextToken
          __typename
        }
        fixedRank
        rank
        updatedByIAM
        createdAt
        updatedAt
        __typename
      }
      vsGamma {
        id
        friendlyId
        levelID
        level {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        levelHistory {
          nextToken
          __typename
        }
        title
        description
        measurementFields {
          measurementFieldID
          value
          __typename
        }
        additionalFields {
          name
          __typename
        }
        rankHistory {
          D
          R
          S
          __typename
        }
        mapping {
          gammaID
          organizationID
          mapping
          metadata
          createdAt
          updatedAt
          __typename
        }
        userID
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departments
        UserVotes {
          nextToken
          __typename
        }
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        ownerEmail
        hiddenUsers {
          nextToken
          __typename
        }
        sponsorID
        sponsor {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        contributors
        linkedTo {
          nextToken
          __typename
        }
        userRatings {
          nextToken
          __typename
        }
        fixedRank
        rank
        updatedByIAM
        createdAt
        updatedAt
        __typename
      }
      userVoteObjectiveId
      Objective {
        id
        name
        description
        active
        organizationObjectivesId
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        ownerId
        owner {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      voteTime
      createdAt
      updatedAt
      userVoteVsGammaId
      __typename
    }
  }
`;
export const updateUserVote = /* GraphQL */ `
  mutation UpdateUserVote(
    $input: UpdateUserVoteInput!
    $condition: ModelUserVoteConditionInput
  ) {
    updateUserVote(input: $input, condition: $condition) {
      id
      vote
      weight
      userID
      User {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      organizationID
      Organization {
        id
        name
        website
        contact
        address {
          street
          city
          zip
          state
          country
          __typename
        }
        vision {
          key
          value
          __typename
        }
        apiKeys {
          nextToken
          __typename
        }
        stages {
          nextToken
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        adminID
        Admin {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        type
        partnerID
        Partner {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Clients {
          nextToken
          __typename
        }
        Users {
          nextToken
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        gammas {
          nextToken
          __typename
        }
        gammaCount
        organizationDefaultPrioirtybatchId
        defaultPrioirtybatch {
          id
          title
          description
          public
          organizationID
          owners
          updatedAtBy
          createdAt
          updatedAt
          __typename
        }
        priorityBatches {
          nextToken
          __typename
        }
        winningCriteria
        scoreWeightages
        objectives {
          nextToken
          __typename
        }
        categoryValues
        weightSettings {
          role
          weight
          __typename
        }
        comparisonFlags
        ratingFlags
        invitationReminderConfig
        inactivityReminderConfig
        scenarioWeightsFlag
        measurementFields {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      gammaID
      Gamma {
        id
        friendlyId
        levelID
        level {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        levelHistory {
          nextToken
          __typename
        }
        title
        description
        measurementFields {
          measurementFieldID
          value
          __typename
        }
        additionalFields {
          name
          __typename
        }
        rankHistory {
          D
          R
          S
          __typename
        }
        mapping {
          gammaID
          organizationID
          mapping
          metadata
          createdAt
          updatedAt
          __typename
        }
        userID
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departments
        UserVotes {
          nextToken
          __typename
        }
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        ownerEmail
        hiddenUsers {
          nextToken
          __typename
        }
        sponsorID
        sponsor {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        contributors
        linkedTo {
          nextToken
          __typename
        }
        userRatings {
          nextToken
          __typename
        }
        fixedRank
        rank
        updatedByIAM
        createdAt
        updatedAt
        __typename
      }
      vsGamma {
        id
        friendlyId
        levelID
        level {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        levelHistory {
          nextToken
          __typename
        }
        title
        description
        measurementFields {
          measurementFieldID
          value
          __typename
        }
        additionalFields {
          name
          __typename
        }
        rankHistory {
          D
          R
          S
          __typename
        }
        mapping {
          gammaID
          organizationID
          mapping
          metadata
          createdAt
          updatedAt
          __typename
        }
        userID
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departments
        UserVotes {
          nextToken
          __typename
        }
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        ownerEmail
        hiddenUsers {
          nextToken
          __typename
        }
        sponsorID
        sponsor {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        contributors
        linkedTo {
          nextToken
          __typename
        }
        userRatings {
          nextToken
          __typename
        }
        fixedRank
        rank
        updatedByIAM
        createdAt
        updatedAt
        __typename
      }
      userVoteObjectiveId
      Objective {
        id
        name
        description
        active
        organizationObjectivesId
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        ownerId
        owner {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      voteTime
      createdAt
      updatedAt
      userVoteVsGammaId
      __typename
    }
  }
`;
export const deleteUserVote = /* GraphQL */ `
  mutation DeleteUserVote(
    $input: DeleteUserVoteInput!
    $condition: ModelUserVoteConditionInput
  ) {
    deleteUserVote(input: $input, condition: $condition) {
      id
      vote
      weight
      userID
      User {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      organizationID
      Organization {
        id
        name
        website
        contact
        address {
          street
          city
          zip
          state
          country
          __typename
        }
        vision {
          key
          value
          __typename
        }
        apiKeys {
          nextToken
          __typename
        }
        stages {
          nextToken
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        adminID
        Admin {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        type
        partnerID
        Partner {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Clients {
          nextToken
          __typename
        }
        Users {
          nextToken
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        gammas {
          nextToken
          __typename
        }
        gammaCount
        organizationDefaultPrioirtybatchId
        defaultPrioirtybatch {
          id
          title
          description
          public
          organizationID
          owners
          updatedAtBy
          createdAt
          updatedAt
          __typename
        }
        priorityBatches {
          nextToken
          __typename
        }
        winningCriteria
        scoreWeightages
        objectives {
          nextToken
          __typename
        }
        categoryValues
        weightSettings {
          role
          weight
          __typename
        }
        comparisonFlags
        ratingFlags
        invitationReminderConfig
        inactivityReminderConfig
        scenarioWeightsFlag
        measurementFields {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      gammaID
      Gamma {
        id
        friendlyId
        levelID
        level {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        levelHistory {
          nextToken
          __typename
        }
        title
        description
        measurementFields {
          measurementFieldID
          value
          __typename
        }
        additionalFields {
          name
          __typename
        }
        rankHistory {
          D
          R
          S
          __typename
        }
        mapping {
          gammaID
          organizationID
          mapping
          metadata
          createdAt
          updatedAt
          __typename
        }
        userID
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departments
        UserVotes {
          nextToken
          __typename
        }
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        ownerEmail
        hiddenUsers {
          nextToken
          __typename
        }
        sponsorID
        sponsor {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        contributors
        linkedTo {
          nextToken
          __typename
        }
        userRatings {
          nextToken
          __typename
        }
        fixedRank
        rank
        updatedByIAM
        createdAt
        updatedAt
        __typename
      }
      vsGamma {
        id
        friendlyId
        levelID
        level {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        levelHistory {
          nextToken
          __typename
        }
        title
        description
        measurementFields {
          measurementFieldID
          value
          __typename
        }
        additionalFields {
          name
          __typename
        }
        rankHistory {
          D
          R
          S
          __typename
        }
        mapping {
          gammaID
          organizationID
          mapping
          metadata
          createdAt
          updatedAt
          __typename
        }
        userID
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departments
        UserVotes {
          nextToken
          __typename
        }
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        ownerEmail
        hiddenUsers {
          nextToken
          __typename
        }
        sponsorID
        sponsor {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        contributors
        linkedTo {
          nextToken
          __typename
        }
        userRatings {
          nextToken
          __typename
        }
        fixedRank
        rank
        updatedByIAM
        createdAt
        updatedAt
        __typename
      }
      userVoteObjectiveId
      Objective {
        id
        name
        description
        active
        organizationObjectivesId
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        ownerId
        owner {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      voteTime
      createdAt
      updatedAt
      userVoteVsGammaId
      __typename
    }
  }
`;
export const createDepartment = /* GraphQL */ `
  mutation CreateDepartment(
    $input: CreateDepartmentInput!
    $condition: ModelDepartmentConditionInput
  ) {
    createDepartment(input: $input, condition: $condition) {
      id
      name
      organizationID
      organization {
        id
        name
        website
        contact
        address {
          street
          city
          zip
          state
          country
          __typename
        }
        vision {
          key
          value
          __typename
        }
        apiKeys {
          nextToken
          __typename
        }
        stages {
          nextToken
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        adminID
        Admin {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        type
        partnerID
        Partner {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Clients {
          nextToken
          __typename
        }
        Users {
          nextToken
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        gammas {
          nextToken
          __typename
        }
        gammaCount
        organizationDefaultPrioirtybatchId
        defaultPrioirtybatch {
          id
          title
          description
          public
          organizationID
          owners
          updatedAtBy
          createdAt
          updatedAt
          __typename
        }
        priorityBatches {
          nextToken
          __typename
        }
        winningCriteria
        scoreWeightages
        objectives {
          nextToken
          __typename
        }
        categoryValues
        weightSettings {
          role
          weight
          __typename
        }
        comparisonFlags
        ratingFlags
        invitationReminderConfig
        inactivityReminderConfig
        scenarioWeightsFlag
        measurementFields {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      Users {
        items {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      Objectives {
        items {
          id
          objectiveID
          departmentID
          ownerID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      managerID
      Manager {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDepartment = /* GraphQL */ `
  mutation UpdateDepartment(
    $input: UpdateDepartmentInput!
    $condition: ModelDepartmentConditionInput
  ) {
    updateDepartment(input: $input, condition: $condition) {
      id
      name
      organizationID
      organization {
        id
        name
        website
        contact
        address {
          street
          city
          zip
          state
          country
          __typename
        }
        vision {
          key
          value
          __typename
        }
        apiKeys {
          nextToken
          __typename
        }
        stages {
          nextToken
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        adminID
        Admin {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        type
        partnerID
        Partner {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Clients {
          nextToken
          __typename
        }
        Users {
          nextToken
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        gammas {
          nextToken
          __typename
        }
        gammaCount
        organizationDefaultPrioirtybatchId
        defaultPrioirtybatch {
          id
          title
          description
          public
          organizationID
          owners
          updatedAtBy
          createdAt
          updatedAt
          __typename
        }
        priorityBatches {
          nextToken
          __typename
        }
        winningCriteria
        scoreWeightages
        objectives {
          nextToken
          __typename
        }
        categoryValues
        weightSettings {
          role
          weight
          __typename
        }
        comparisonFlags
        ratingFlags
        invitationReminderConfig
        inactivityReminderConfig
        scenarioWeightsFlag
        measurementFields {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      Users {
        items {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      Objectives {
        items {
          id
          objectiveID
          departmentID
          ownerID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      managerID
      Manager {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDepartment = /* GraphQL */ `
  mutation DeleteDepartment(
    $input: DeleteDepartmentInput!
    $condition: ModelDepartmentConditionInput
  ) {
    deleteDepartment(input: $input, condition: $condition) {
      id
      name
      organizationID
      organization {
        id
        name
        website
        contact
        address {
          street
          city
          zip
          state
          country
          __typename
        }
        vision {
          key
          value
          __typename
        }
        apiKeys {
          nextToken
          __typename
        }
        stages {
          nextToken
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        adminID
        Admin {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        type
        partnerID
        Partner {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Clients {
          nextToken
          __typename
        }
        Users {
          nextToken
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        gammas {
          nextToken
          __typename
        }
        gammaCount
        organizationDefaultPrioirtybatchId
        defaultPrioirtybatch {
          id
          title
          description
          public
          organizationID
          owners
          updatedAtBy
          createdAt
          updatedAt
          __typename
        }
        priorityBatches {
          nextToken
          __typename
        }
        winningCriteria
        scoreWeightages
        objectives {
          nextToken
          __typename
        }
        categoryValues
        weightSettings {
          role
          weight
          __typename
        }
        comparisonFlags
        ratingFlags
        invitationReminderConfig
        inactivityReminderConfig
        scenarioWeightsFlag
        measurementFields {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      Users {
        items {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      Objectives {
        items {
          id
          objectiveID
          departmentID
          ownerID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      managerID
      Manager {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createOrganization = /* GraphQL */ `
  mutation CreateOrganization(
    $input: CreateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    createOrganization(input: $input, condition: $condition) {
      id
      name
      website
      contact
      address {
        street
        city
        zip
        state
        country
        __typename
      }
      vision {
        key
        value
        __typename
      }
      apiKeys {
        items {
          id
          organizationID
          key
          keyID
          disabled
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      stages {
        items {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      Departments {
        items {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      adminID
      Admin {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      type
      partnerID
      Partner {
        id
        name
        website
        contact
        address {
          street
          city
          zip
          state
          country
          __typename
        }
        vision {
          key
          value
          __typename
        }
        apiKeys {
          nextToken
          __typename
        }
        stages {
          nextToken
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        adminID
        Admin {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        type
        partnerID
        Partner {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Clients {
          nextToken
          __typename
        }
        Users {
          nextToken
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        gammas {
          nextToken
          __typename
        }
        gammaCount
        organizationDefaultPrioirtybatchId
        defaultPrioirtybatch {
          id
          title
          description
          public
          organizationID
          owners
          updatedAtBy
          createdAt
          updatedAt
          __typename
        }
        priorityBatches {
          nextToken
          __typename
        }
        winningCriteria
        scoreWeightages
        objectives {
          nextToken
          __typename
        }
        categoryValues
        weightSettings {
          role
          weight
          __typename
        }
        comparisonFlags
        ratingFlags
        invitationReminderConfig
        inactivityReminderConfig
        scenarioWeightsFlag
        measurementFields {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      Clients {
        items {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      Users {
        items {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      UserVotes {
        items {
          id
          vote
          weight
          userID
          organizationID
          gammaID
          userVoteObjectiveId
          voteTime
          createdAt
          updatedAt
          userVoteVsGammaId
          __typename
        }
        nextToken
        __typename
      }
      UserRatings {
        items {
          id
          userID
          organizationID
          gammaID
          rating
          weight
          userRatingObjectiveId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      gammas {
        items {
          id
          friendlyId
          levelID
          title
          description
          userID
          organizationID
          departments
          ownerEmail
          sponsorID
          contributors
          fixedRank
          rank
          updatedByIAM
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      gammaCount
      organizationDefaultPrioirtybatchId
      defaultPrioirtybatch {
        id
        title
        description
        priorities {
          weight
          ObjectiveID
          __typename
        }
        voteFilters {
          match
          __typename
        }
        measurementFieldFilters {
          match
          __typename
        }
        fieldFilters {
          match
          __typename
        }
        public
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        owners
        updatedAtBy
        createdAt
        updatedAt
        __typename
      }
      priorityBatches {
        items {
          id
          title
          description
          public
          organizationID
          owners
          updatedAtBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      winningCriteria
      scoreWeightages
      objectives {
        items {
          id
          name
          description
          active
          organizationObjectivesId
          ownerId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      categoryValues
      weightSettings {
        role
        weight
        __typename
      }
      comparisonFlags
      ratingFlags
      invitationReminderConfig
      inactivityReminderConfig
      scenarioWeightsFlag
      measurementFields {
        items {
          id
          name
          description
          type
          subType
          isMeasurement
          organizationID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateOrganization = /* GraphQL */ `
  mutation UpdateOrganization(
    $input: UpdateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    updateOrganization(input: $input, condition: $condition) {
      id
      name
      website
      contact
      address {
        street
        city
        zip
        state
        country
        __typename
      }
      vision {
        key
        value
        __typename
      }
      apiKeys {
        items {
          id
          organizationID
          key
          keyID
          disabled
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      stages {
        items {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      Departments {
        items {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      adminID
      Admin {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      type
      partnerID
      Partner {
        id
        name
        website
        contact
        address {
          street
          city
          zip
          state
          country
          __typename
        }
        vision {
          key
          value
          __typename
        }
        apiKeys {
          nextToken
          __typename
        }
        stages {
          nextToken
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        adminID
        Admin {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        type
        partnerID
        Partner {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Clients {
          nextToken
          __typename
        }
        Users {
          nextToken
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        gammas {
          nextToken
          __typename
        }
        gammaCount
        organizationDefaultPrioirtybatchId
        defaultPrioirtybatch {
          id
          title
          description
          public
          organizationID
          owners
          updatedAtBy
          createdAt
          updatedAt
          __typename
        }
        priorityBatches {
          nextToken
          __typename
        }
        winningCriteria
        scoreWeightages
        objectives {
          nextToken
          __typename
        }
        categoryValues
        weightSettings {
          role
          weight
          __typename
        }
        comparisonFlags
        ratingFlags
        invitationReminderConfig
        inactivityReminderConfig
        scenarioWeightsFlag
        measurementFields {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      Clients {
        items {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      Users {
        items {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      UserVotes {
        items {
          id
          vote
          weight
          userID
          organizationID
          gammaID
          userVoteObjectiveId
          voteTime
          createdAt
          updatedAt
          userVoteVsGammaId
          __typename
        }
        nextToken
        __typename
      }
      UserRatings {
        items {
          id
          userID
          organizationID
          gammaID
          rating
          weight
          userRatingObjectiveId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      gammas {
        items {
          id
          friendlyId
          levelID
          title
          description
          userID
          organizationID
          departments
          ownerEmail
          sponsorID
          contributors
          fixedRank
          rank
          updatedByIAM
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      gammaCount
      organizationDefaultPrioirtybatchId
      defaultPrioirtybatch {
        id
        title
        description
        priorities {
          weight
          ObjectiveID
          __typename
        }
        voteFilters {
          match
          __typename
        }
        measurementFieldFilters {
          match
          __typename
        }
        fieldFilters {
          match
          __typename
        }
        public
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        owners
        updatedAtBy
        createdAt
        updatedAt
        __typename
      }
      priorityBatches {
        items {
          id
          title
          description
          public
          organizationID
          owners
          updatedAtBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      winningCriteria
      scoreWeightages
      objectives {
        items {
          id
          name
          description
          active
          organizationObjectivesId
          ownerId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      categoryValues
      weightSettings {
        role
        weight
        __typename
      }
      comparisonFlags
      ratingFlags
      invitationReminderConfig
      inactivityReminderConfig
      scenarioWeightsFlag
      measurementFields {
        items {
          id
          name
          description
          type
          subType
          isMeasurement
          organizationID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteOrganization = /* GraphQL */ `
  mutation DeleteOrganization(
    $input: DeleteOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    deleteOrganization(input: $input, condition: $condition) {
      id
      name
      website
      contact
      address {
        street
        city
        zip
        state
        country
        __typename
      }
      vision {
        key
        value
        __typename
      }
      apiKeys {
        items {
          id
          organizationID
          key
          keyID
          disabled
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      stages {
        items {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      Departments {
        items {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      adminID
      Admin {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      type
      partnerID
      Partner {
        id
        name
        website
        contact
        address {
          street
          city
          zip
          state
          country
          __typename
        }
        vision {
          key
          value
          __typename
        }
        apiKeys {
          nextToken
          __typename
        }
        stages {
          nextToken
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        adminID
        Admin {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        type
        partnerID
        Partner {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Clients {
          nextToken
          __typename
        }
        Users {
          nextToken
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        gammas {
          nextToken
          __typename
        }
        gammaCount
        organizationDefaultPrioirtybatchId
        defaultPrioirtybatch {
          id
          title
          description
          public
          organizationID
          owners
          updatedAtBy
          createdAt
          updatedAt
          __typename
        }
        priorityBatches {
          nextToken
          __typename
        }
        winningCriteria
        scoreWeightages
        objectives {
          nextToken
          __typename
        }
        categoryValues
        weightSettings {
          role
          weight
          __typename
        }
        comparisonFlags
        ratingFlags
        invitationReminderConfig
        inactivityReminderConfig
        scenarioWeightsFlag
        measurementFields {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      Clients {
        items {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      Users {
        items {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      UserVotes {
        items {
          id
          vote
          weight
          userID
          organizationID
          gammaID
          userVoteObjectiveId
          voteTime
          createdAt
          updatedAt
          userVoteVsGammaId
          __typename
        }
        nextToken
        __typename
      }
      UserRatings {
        items {
          id
          userID
          organizationID
          gammaID
          rating
          weight
          userRatingObjectiveId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      gammas {
        items {
          id
          friendlyId
          levelID
          title
          description
          userID
          organizationID
          departments
          ownerEmail
          sponsorID
          contributors
          fixedRank
          rank
          updatedByIAM
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      gammaCount
      organizationDefaultPrioirtybatchId
      defaultPrioirtybatch {
        id
        title
        description
        priorities {
          weight
          ObjectiveID
          __typename
        }
        voteFilters {
          match
          __typename
        }
        measurementFieldFilters {
          match
          __typename
        }
        fieldFilters {
          match
          __typename
        }
        public
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        owners
        updatedAtBy
        createdAt
        updatedAt
        __typename
      }
      priorityBatches {
        items {
          id
          title
          description
          public
          organizationID
          owners
          updatedAtBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      winningCriteria
      scoreWeightages
      objectives {
        items {
          id
          name
          description
          active
          organizationObjectivesId
          ownerId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      categoryValues
      weightSettings {
        role
        weight
        __typename
      }
      comparisonFlags
      ratingFlags
      invitationReminderConfig
      inactivityReminderConfig
      scenarioWeightsFlag
      measurementFields {
        items {
          id
          name
          description
          type
          subType
          isMeasurement
          organizationID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createFormDraft = /* GraphQL */ `
  mutation CreateFormDraft(
    $input: CreateFormDraftInput!
    $condition: ModelFormDraftConditionInput
  ) {
    createFormDraft(input: $input, condition: $condition) {
      stageID
      name
      layout
      version
      updatedByIAM
      Stage {
        id
        name
        active
        level
        form {
          layout
          __typename
        }
        draft {
          nextToken
          __typename
        }
        updatedByIAM
        gammas {
          nextToken
          __typename
        }
        measurementFieldConnection {
          nextToken
          __typename
        }
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      organizationID
      Organization {
        id
        name
        website
        contact
        address {
          street
          city
          zip
          state
          country
          __typename
        }
        vision {
          key
          value
          __typename
        }
        apiKeys {
          nextToken
          __typename
        }
        stages {
          nextToken
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        adminID
        Admin {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        type
        partnerID
        Partner {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Clients {
          nextToken
          __typename
        }
        Users {
          nextToken
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        gammas {
          nextToken
          __typename
        }
        gammaCount
        organizationDefaultPrioirtybatchId
        defaultPrioirtybatch {
          id
          title
          description
          public
          organizationID
          owners
          updatedAtBy
          createdAt
          updatedAt
          __typename
        }
        priorityBatches {
          nextToken
          __typename
        }
        winningCriteria
        scoreWeightages
        objectives {
          nextToken
          __typename
        }
        categoryValues
        weightSettings {
          role
          weight
          __typename
        }
        comparisonFlags
        ratingFlags
        invitationReminderConfig
        inactivityReminderConfig
        scenarioWeightsFlag
        measurementFields {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateFormDraft = /* GraphQL */ `
  mutation UpdateFormDraft(
    $input: UpdateFormDraftInput!
    $condition: ModelFormDraftConditionInput
  ) {
    updateFormDraft(input: $input, condition: $condition) {
      stageID
      name
      layout
      version
      updatedByIAM
      Stage {
        id
        name
        active
        level
        form {
          layout
          __typename
        }
        draft {
          nextToken
          __typename
        }
        updatedByIAM
        gammas {
          nextToken
          __typename
        }
        measurementFieldConnection {
          nextToken
          __typename
        }
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      organizationID
      Organization {
        id
        name
        website
        contact
        address {
          street
          city
          zip
          state
          country
          __typename
        }
        vision {
          key
          value
          __typename
        }
        apiKeys {
          nextToken
          __typename
        }
        stages {
          nextToken
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        adminID
        Admin {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        type
        partnerID
        Partner {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Clients {
          nextToken
          __typename
        }
        Users {
          nextToken
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        gammas {
          nextToken
          __typename
        }
        gammaCount
        organizationDefaultPrioirtybatchId
        defaultPrioirtybatch {
          id
          title
          description
          public
          organizationID
          owners
          updatedAtBy
          createdAt
          updatedAt
          __typename
        }
        priorityBatches {
          nextToken
          __typename
        }
        winningCriteria
        scoreWeightages
        objectives {
          nextToken
          __typename
        }
        categoryValues
        weightSettings {
          role
          weight
          __typename
        }
        comparisonFlags
        ratingFlags
        invitationReminderConfig
        inactivityReminderConfig
        scenarioWeightsFlag
        measurementFields {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteFormDraft = /* GraphQL */ `
  mutation DeleteFormDraft(
    $input: DeleteFormDraftInput!
    $condition: ModelFormDraftConditionInput
  ) {
    deleteFormDraft(input: $input, condition: $condition) {
      stageID
      name
      layout
      version
      updatedByIAM
      Stage {
        id
        name
        active
        level
        form {
          layout
          __typename
        }
        draft {
          nextToken
          __typename
        }
        updatedByIAM
        gammas {
          nextToken
          __typename
        }
        measurementFieldConnection {
          nextToken
          __typename
        }
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      organizationID
      Organization {
        id
        name
        website
        contact
        address {
          street
          city
          zip
          state
          country
          __typename
        }
        vision {
          key
          value
          __typename
        }
        apiKeys {
          nextToken
          __typename
        }
        stages {
          nextToken
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        adminID
        Admin {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        type
        partnerID
        Partner {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Clients {
          nextToken
          __typename
        }
        Users {
          nextToken
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        gammas {
          nextToken
          __typename
        }
        gammaCount
        organizationDefaultPrioirtybatchId
        defaultPrioirtybatch {
          id
          title
          description
          public
          organizationID
          owners
          updatedAtBy
          createdAt
          updatedAt
          __typename
        }
        priorityBatches {
          nextToken
          __typename
        }
        winningCriteria
        scoreWeightages
        objectives {
          nextToken
          __typename
        }
        categoryValues
        weightSettings {
          role
          weight
          __typename
        }
        comparisonFlags
        ratingFlags
        invitationReminderConfig
        inactivityReminderConfig
        scenarioWeightsFlag
        measurementFields {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createStage = /* GraphQL */ `
  mutation CreateStage(
    $input: CreateStageInput!
    $condition: ModelStageConditionInput
  ) {
    createStage(input: $input, condition: $condition) {
      id
      name
      active
      level
      form {
        layout
        __typename
      }
      draft {
        items {
          stageID
          name
          layout
          version
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      updatedByIAM
      gammas {
        items {
          id
          friendlyId
          levelID
          title
          description
          userID
          organizationID
          departments
          ownerEmail
          sponsorID
          contributors
          fixedRank
          rank
          updatedByIAM
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      measurementFieldConnection {
        items {
          measurementFieldID
          stageID
          organizationID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      organizationID
      Organization {
        id
        name
        website
        contact
        address {
          street
          city
          zip
          state
          country
          __typename
        }
        vision {
          key
          value
          __typename
        }
        apiKeys {
          nextToken
          __typename
        }
        stages {
          nextToken
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        adminID
        Admin {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        type
        partnerID
        Partner {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Clients {
          nextToken
          __typename
        }
        Users {
          nextToken
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        gammas {
          nextToken
          __typename
        }
        gammaCount
        organizationDefaultPrioirtybatchId
        defaultPrioirtybatch {
          id
          title
          description
          public
          organizationID
          owners
          updatedAtBy
          createdAt
          updatedAt
          __typename
        }
        priorityBatches {
          nextToken
          __typename
        }
        winningCriteria
        scoreWeightages
        objectives {
          nextToken
          __typename
        }
        categoryValues
        weightSettings {
          role
          weight
          __typename
        }
        comparisonFlags
        ratingFlags
        invitationReminderConfig
        inactivityReminderConfig
        scenarioWeightsFlag
        measurementFields {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateStage = /* GraphQL */ `
  mutation UpdateStage(
    $input: UpdateStageInput!
    $condition: ModelStageConditionInput
  ) {
    updateStage(input: $input, condition: $condition) {
      id
      name
      active
      level
      form {
        layout
        __typename
      }
      draft {
        items {
          stageID
          name
          layout
          version
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      updatedByIAM
      gammas {
        items {
          id
          friendlyId
          levelID
          title
          description
          userID
          organizationID
          departments
          ownerEmail
          sponsorID
          contributors
          fixedRank
          rank
          updatedByIAM
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      measurementFieldConnection {
        items {
          measurementFieldID
          stageID
          organizationID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      organizationID
      Organization {
        id
        name
        website
        contact
        address {
          street
          city
          zip
          state
          country
          __typename
        }
        vision {
          key
          value
          __typename
        }
        apiKeys {
          nextToken
          __typename
        }
        stages {
          nextToken
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        adminID
        Admin {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        type
        partnerID
        Partner {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Clients {
          nextToken
          __typename
        }
        Users {
          nextToken
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        gammas {
          nextToken
          __typename
        }
        gammaCount
        organizationDefaultPrioirtybatchId
        defaultPrioirtybatch {
          id
          title
          description
          public
          organizationID
          owners
          updatedAtBy
          createdAt
          updatedAt
          __typename
        }
        priorityBatches {
          nextToken
          __typename
        }
        winningCriteria
        scoreWeightages
        objectives {
          nextToken
          __typename
        }
        categoryValues
        weightSettings {
          role
          weight
          __typename
        }
        comparisonFlags
        ratingFlags
        invitationReminderConfig
        inactivityReminderConfig
        scenarioWeightsFlag
        measurementFields {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteStage = /* GraphQL */ `
  mutation DeleteStage(
    $input: DeleteStageInput!
    $condition: ModelStageConditionInput
  ) {
    deleteStage(input: $input, condition: $condition) {
      id
      name
      active
      level
      form {
        layout
        __typename
      }
      draft {
        items {
          stageID
          name
          layout
          version
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      updatedByIAM
      gammas {
        items {
          id
          friendlyId
          levelID
          title
          description
          userID
          organizationID
          departments
          ownerEmail
          sponsorID
          contributors
          fixedRank
          rank
          updatedByIAM
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      measurementFieldConnection {
        items {
          measurementFieldID
          stageID
          organizationID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      organizationID
      Organization {
        id
        name
        website
        contact
        address {
          street
          city
          zip
          state
          country
          __typename
        }
        vision {
          key
          value
          __typename
        }
        apiKeys {
          nextToken
          __typename
        }
        stages {
          nextToken
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        adminID
        Admin {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        type
        partnerID
        Partner {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Clients {
          nextToken
          __typename
        }
        Users {
          nextToken
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        gammas {
          nextToken
          __typename
        }
        gammaCount
        organizationDefaultPrioirtybatchId
        defaultPrioirtybatch {
          id
          title
          description
          public
          organizationID
          owners
          updatedAtBy
          createdAt
          updatedAt
          __typename
        }
        priorityBatches {
          nextToken
          __typename
        }
        winningCriteria
        scoreWeightages
        objectives {
          nextToken
          __typename
        }
        categoryValues
        weightSettings {
          role
          weight
          __typename
        }
        comparisonFlags
        ratingFlags
        invitationReminderConfig
        inactivityReminderConfig
        scenarioWeightsFlag
        measurementFields {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createLevelChange = /* GraphQL */ `
  mutation CreateLevelChange(
    $input: CreateLevelChangeInput!
    $condition: ModelLevelChangeConditionInput
  ) {
    createLevelChange(input: $input, condition: $condition) {
      id
      previousID
      previous {
        id
        name
        active
        level
        form {
          layout
          __typename
        }
        draft {
          nextToken
          __typename
        }
        updatedByIAM
        gammas {
          nextToken
          __typename
        }
        measurementFieldConnection {
          nextToken
          __typename
        }
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      presentID
      present {
        id
        name
        active
        level
        form {
          layout
          __typename
        }
        draft {
          nextToken
          __typename
        }
        updatedByIAM
        gammas {
          nextToken
          __typename
        }
        measurementFieldConnection {
          nextToken
          __typename
        }
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      gammaLevelHistoryId
      gamma {
        id
        friendlyId
        levelID
        level {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        levelHistory {
          nextToken
          __typename
        }
        title
        description
        measurementFields {
          measurementFieldID
          value
          __typename
        }
        additionalFields {
          name
          __typename
        }
        rankHistory {
          D
          R
          S
          __typename
        }
        mapping {
          gammaID
          organizationID
          mapping
          metadata
          createdAt
          updatedAt
          __typename
        }
        userID
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departments
        UserVotes {
          nextToken
          __typename
        }
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        ownerEmail
        hiddenUsers {
          nextToken
          __typename
        }
        sponsorID
        sponsor {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        contributors
        linkedTo {
          nextToken
          __typename
        }
        userRatings {
          nextToken
          __typename
        }
        fixedRank
        rank
        updatedByIAM
        createdAt
        updatedAt
        __typename
      }
      changedAt
      userID
      changedBy {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateLevelChange = /* GraphQL */ `
  mutation UpdateLevelChange(
    $input: UpdateLevelChangeInput!
    $condition: ModelLevelChangeConditionInput
  ) {
    updateLevelChange(input: $input, condition: $condition) {
      id
      previousID
      previous {
        id
        name
        active
        level
        form {
          layout
          __typename
        }
        draft {
          nextToken
          __typename
        }
        updatedByIAM
        gammas {
          nextToken
          __typename
        }
        measurementFieldConnection {
          nextToken
          __typename
        }
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      presentID
      present {
        id
        name
        active
        level
        form {
          layout
          __typename
        }
        draft {
          nextToken
          __typename
        }
        updatedByIAM
        gammas {
          nextToken
          __typename
        }
        measurementFieldConnection {
          nextToken
          __typename
        }
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      gammaLevelHistoryId
      gamma {
        id
        friendlyId
        levelID
        level {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        levelHistory {
          nextToken
          __typename
        }
        title
        description
        measurementFields {
          measurementFieldID
          value
          __typename
        }
        additionalFields {
          name
          __typename
        }
        rankHistory {
          D
          R
          S
          __typename
        }
        mapping {
          gammaID
          organizationID
          mapping
          metadata
          createdAt
          updatedAt
          __typename
        }
        userID
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departments
        UserVotes {
          nextToken
          __typename
        }
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        ownerEmail
        hiddenUsers {
          nextToken
          __typename
        }
        sponsorID
        sponsor {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        contributors
        linkedTo {
          nextToken
          __typename
        }
        userRatings {
          nextToken
          __typename
        }
        fixedRank
        rank
        updatedByIAM
        createdAt
        updatedAt
        __typename
      }
      changedAt
      userID
      changedBy {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteLevelChange = /* GraphQL */ `
  mutation DeleteLevelChange(
    $input: DeleteLevelChangeInput!
    $condition: ModelLevelChangeConditionInput
  ) {
    deleteLevelChange(input: $input, condition: $condition) {
      id
      previousID
      previous {
        id
        name
        active
        level
        form {
          layout
          __typename
        }
        draft {
          nextToken
          __typename
        }
        updatedByIAM
        gammas {
          nextToken
          __typename
        }
        measurementFieldConnection {
          nextToken
          __typename
        }
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      presentID
      present {
        id
        name
        active
        level
        form {
          layout
          __typename
        }
        draft {
          nextToken
          __typename
        }
        updatedByIAM
        gammas {
          nextToken
          __typename
        }
        measurementFieldConnection {
          nextToken
          __typename
        }
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      gammaLevelHistoryId
      gamma {
        id
        friendlyId
        levelID
        level {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        levelHistory {
          nextToken
          __typename
        }
        title
        description
        measurementFields {
          measurementFieldID
          value
          __typename
        }
        additionalFields {
          name
          __typename
        }
        rankHistory {
          D
          R
          S
          __typename
        }
        mapping {
          gammaID
          organizationID
          mapping
          metadata
          createdAt
          updatedAt
          __typename
        }
        userID
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departments
        UserVotes {
          nextToken
          __typename
        }
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        ownerEmail
        hiddenUsers {
          nextToken
          __typename
        }
        sponsorID
        sponsor {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        contributors
        linkedTo {
          nextToken
          __typename
        }
        userRatings {
          nextToken
          __typename
        }
        fixedRank
        rank
        updatedByIAM
        createdAt
        updatedAt
        __typename
      }
      changedAt
      userID
      changedBy {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createInactivityReminderStatus = /* GraphQL */ `
  mutation CreateInactivityReminderStatus(
    $input: CreateInactivityReminderStatusInput!
    $condition: ModelInactivityReminderStatusConditionInput
  ) {
    createInactivityReminderStatus(input: $input, condition: $condition) {
      userID
      user {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateInactivityReminderStatus = /* GraphQL */ `
  mutation UpdateInactivityReminderStatus(
    $input: UpdateInactivityReminderStatusInput!
    $condition: ModelInactivityReminderStatusConditionInput
  ) {
    updateInactivityReminderStatus(input: $input, condition: $condition) {
      userID
      user {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteInactivityReminderStatus = /* GraphQL */ `
  mutation DeleteInactivityReminderStatus(
    $input: DeleteInactivityReminderStatusInput!
    $condition: ModelInactivityReminderStatusConditionInput
  ) {
    deleteInactivityReminderStatus(input: $input, condition: $condition) {
      userID
      user {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createInvitationReminderStatus = /* GraphQL */ `
  mutation CreateInvitationReminderStatus(
    $input: CreateInvitationReminderStatusInput!
    $condition: ModelInvitationReminderStatusConditionInput
  ) {
    createInvitationReminderStatus(input: $input, condition: $condition) {
      userID
      user {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateInvitationReminderStatus = /* GraphQL */ `
  mutation UpdateInvitationReminderStatus(
    $input: UpdateInvitationReminderStatusInput!
    $condition: ModelInvitationReminderStatusConditionInput
  ) {
    updateInvitationReminderStatus(input: $input, condition: $condition) {
      userID
      user {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteInvitationReminderStatus = /* GraphQL */ `
  mutation DeleteInvitationReminderStatus(
    $input: DeleteInvitationReminderStatusInput!
    $condition: ModelInvitationReminderStatusConditionInput
  ) {
    deleteInvitationReminderStatus(input: $input, condition: $condition) {
      userID
      user {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserSignOutStatus = /* GraphQL */ `
  mutation CreateUserSignOutStatus(
    $input: CreateUserSignOutStatusInput!
    $condition: ModelUserSignOutStatusConditionInput
  ) {
    createUserSignOutStatus(input: $input, condition: $condition) {
      id
      userID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserSignOutStatus = /* GraphQL */ `
  mutation UpdateUserSignOutStatus(
    $input: UpdateUserSignOutStatusInput!
    $condition: ModelUserSignOutStatusConditionInput
  ) {
    updateUserSignOutStatus(input: $input, condition: $condition) {
      id
      userID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserSignOutStatus = /* GraphQL */ `
  mutation DeleteUserSignOutStatus(
    $input: DeleteUserSignOutStatusInput!
    $condition: ModelUserSignOutStatusConditionInput
  ) {
    deleteUserSignOutStatus(input: $input, condition: $condition) {
      id
      userID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createComparisonCreatorStatus = /* GraphQL */ `
  mutation CreateComparisonCreatorStatus(
    $input: CreateComparisonCreatorStatusInput!
    $condition: ModelComparisonCreatorStatusConditionInput
  ) {
    createComparisonCreatorStatus(input: $input, condition: $condition) {
      userID
      expiry
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateComparisonCreatorStatus = /* GraphQL */ `
  mutation UpdateComparisonCreatorStatus(
    $input: UpdateComparisonCreatorStatusInput!
    $condition: ModelComparisonCreatorStatusConditionInput
  ) {
    updateComparisonCreatorStatus(input: $input, condition: $condition) {
      userID
      expiry
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteComparisonCreatorStatus = /* GraphQL */ `
  mutation DeleteComparisonCreatorStatus(
    $input: DeleteComparisonCreatorStatusInput!
    $condition: ModelComparisonCreatorStatusConditionInput
  ) {
    deleteComparisonCreatorStatus(input: $input, condition: $condition) {
      userID
      expiry
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createVoteRatingTriggerStatus = /* GraphQL */ `
  mutation CreateVoteRatingTriggerStatus(
    $input: CreateVoteRatingTriggerStatusInput!
    $condition: ModelVoteRatingTriggerStatusConditionInput
  ) {
    createVoteRatingTriggerStatus(input: $input, condition: $condition) {
      organizationID
      expiry
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateVoteRatingTriggerStatus = /* GraphQL */ `
  mutation UpdateVoteRatingTriggerStatus(
    $input: UpdateVoteRatingTriggerStatusInput!
    $condition: ModelVoteRatingTriggerStatusConditionInput
  ) {
    updateVoteRatingTriggerStatus(input: $input, condition: $condition) {
      organizationID
      expiry
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteVoteRatingTriggerStatus = /* GraphQL */ `
  mutation DeleteVoteRatingTriggerStatus(
    $input: DeleteVoteRatingTriggerStatusInput!
    $condition: ModelVoteRatingTriggerStatusConditionInput
  ) {
    deleteVoteRatingTriggerStatus(input: $input, condition: $condition) {
      organizationID
      expiry
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      photo
      contact
      email
      gammas {
        items {
          id
          friendlyId
          levelID
          title
          description
          userID
          organizationID
          departments
          ownerEmail
          sponsorID
          contributors
          fixedRank
          rank
          updatedByIAM
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      hiddenGammas {
        items {
          id
          userId
          gammaId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sponsoring {
        items {
          id
          friendlyId
          levelID
          title
          description
          userID
          organizationID
          departments
          ownerEmail
          sponsorID
          contributors
          fixedRank
          rank
          updatedByIAM
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      firstName
      lastName
      lastLogin
      designation
      status
      role
      weight
      organizationID
      Organization {
        id
        name
        website
        contact
        address {
          street
          city
          zip
          state
          country
          __typename
        }
        vision {
          key
          value
          __typename
        }
        apiKeys {
          nextToken
          __typename
        }
        stages {
          nextToken
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        adminID
        Admin {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        type
        partnerID
        Partner {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Clients {
          nextToken
          __typename
        }
        Users {
          nextToken
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        gammas {
          nextToken
          __typename
        }
        gammaCount
        organizationDefaultPrioirtybatchId
        defaultPrioirtybatch {
          id
          title
          description
          public
          organizationID
          owners
          updatedAtBy
          createdAt
          updatedAt
          __typename
        }
        priorityBatches {
          nextToken
          __typename
        }
        winningCriteria
        scoreWeightages
        objectives {
          nextToken
          __typename
        }
        categoryValues
        weightSettings {
          role
          weight
          __typename
        }
        comparisonFlags
        ratingFlags
        invitationReminderConfig
        inactivityReminderConfig
        scenarioWeightsFlag
        measurementFields {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      departmentID
      Department {
        id
        name
        organizationID
        organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Users {
          nextToken
          __typename
        }
        Objectives {
          nextToken
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      managerID
      Manager {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      UserVotes {
        items {
          id
          vote
          weight
          userID
          organizationID
          gammaID
          userVoteObjectiveId
          voteTime
          createdAt
          updatedAt
          userVoteVsGammaId
          __typename
        }
        nextToken
        __typename
      }
      LoginHistory {
        items {
          userID
          date
          datetime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      objectives {
        items {
          id
          name
          description
          active
          organizationObjectivesId
          ownerId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      UserRatings {
        items {
          id
          userID
          organizationID
          gammaID
          rating
          weight
          userRatingObjectiveId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      invitationReminderStatus {
        userID
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      inactivityReminderStatus {
        userID
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      photo
      contact
      email
      gammas {
        items {
          id
          friendlyId
          levelID
          title
          description
          userID
          organizationID
          departments
          ownerEmail
          sponsorID
          contributors
          fixedRank
          rank
          updatedByIAM
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      hiddenGammas {
        items {
          id
          userId
          gammaId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sponsoring {
        items {
          id
          friendlyId
          levelID
          title
          description
          userID
          organizationID
          departments
          ownerEmail
          sponsorID
          contributors
          fixedRank
          rank
          updatedByIAM
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      firstName
      lastName
      lastLogin
      designation
      status
      role
      weight
      organizationID
      Organization {
        id
        name
        website
        contact
        address {
          street
          city
          zip
          state
          country
          __typename
        }
        vision {
          key
          value
          __typename
        }
        apiKeys {
          nextToken
          __typename
        }
        stages {
          nextToken
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        adminID
        Admin {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        type
        partnerID
        Partner {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Clients {
          nextToken
          __typename
        }
        Users {
          nextToken
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        gammas {
          nextToken
          __typename
        }
        gammaCount
        organizationDefaultPrioirtybatchId
        defaultPrioirtybatch {
          id
          title
          description
          public
          organizationID
          owners
          updatedAtBy
          createdAt
          updatedAt
          __typename
        }
        priorityBatches {
          nextToken
          __typename
        }
        winningCriteria
        scoreWeightages
        objectives {
          nextToken
          __typename
        }
        categoryValues
        weightSettings {
          role
          weight
          __typename
        }
        comparisonFlags
        ratingFlags
        invitationReminderConfig
        inactivityReminderConfig
        scenarioWeightsFlag
        measurementFields {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      departmentID
      Department {
        id
        name
        organizationID
        organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Users {
          nextToken
          __typename
        }
        Objectives {
          nextToken
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      managerID
      Manager {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      UserVotes {
        items {
          id
          vote
          weight
          userID
          organizationID
          gammaID
          userVoteObjectiveId
          voteTime
          createdAt
          updatedAt
          userVoteVsGammaId
          __typename
        }
        nextToken
        __typename
      }
      LoginHistory {
        items {
          userID
          date
          datetime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      objectives {
        items {
          id
          name
          description
          active
          organizationObjectivesId
          ownerId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      UserRatings {
        items {
          id
          userID
          organizationID
          gammaID
          rating
          weight
          userRatingObjectiveId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      invitationReminderStatus {
        userID
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      inactivityReminderStatus {
        userID
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      photo
      contact
      email
      gammas {
        items {
          id
          friendlyId
          levelID
          title
          description
          userID
          organizationID
          departments
          ownerEmail
          sponsorID
          contributors
          fixedRank
          rank
          updatedByIAM
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      hiddenGammas {
        items {
          id
          userId
          gammaId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sponsoring {
        items {
          id
          friendlyId
          levelID
          title
          description
          userID
          organizationID
          departments
          ownerEmail
          sponsorID
          contributors
          fixedRank
          rank
          updatedByIAM
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      firstName
      lastName
      lastLogin
      designation
      status
      role
      weight
      organizationID
      Organization {
        id
        name
        website
        contact
        address {
          street
          city
          zip
          state
          country
          __typename
        }
        vision {
          key
          value
          __typename
        }
        apiKeys {
          nextToken
          __typename
        }
        stages {
          nextToken
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        adminID
        Admin {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        type
        partnerID
        Partner {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Clients {
          nextToken
          __typename
        }
        Users {
          nextToken
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        gammas {
          nextToken
          __typename
        }
        gammaCount
        organizationDefaultPrioirtybatchId
        defaultPrioirtybatch {
          id
          title
          description
          public
          organizationID
          owners
          updatedAtBy
          createdAt
          updatedAt
          __typename
        }
        priorityBatches {
          nextToken
          __typename
        }
        winningCriteria
        scoreWeightages
        objectives {
          nextToken
          __typename
        }
        categoryValues
        weightSettings {
          role
          weight
          __typename
        }
        comparisonFlags
        ratingFlags
        invitationReminderConfig
        inactivityReminderConfig
        scenarioWeightsFlag
        measurementFields {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      departmentID
      Department {
        id
        name
        organizationID
        organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Users {
          nextToken
          __typename
        }
        Objectives {
          nextToken
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      managerID
      Manager {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      UserVotes {
        items {
          id
          vote
          weight
          userID
          organizationID
          gammaID
          userVoteObjectiveId
          voteTime
          createdAt
          updatedAt
          userVoteVsGammaId
          __typename
        }
        nextToken
        __typename
      }
      LoginHistory {
        items {
          userID
          date
          datetime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      objectives {
        items {
          id
          name
          description
          active
          organizationObjectivesId
          ownerId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      UserRatings {
        items {
          id
          userID
          organizationID
          gammaID
          rating
          weight
          userRatingObjectiveId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      invitationReminderStatus {
        userID
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      inactivityReminderStatus {
        userID
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createLinkedGammas = /* GraphQL */ `
  mutation CreateLinkedGammas(
    $input: CreateLinkedGammasInput!
    $condition: ModelLinkedGammasConditionInput
  ) {
    createLinkedGammas(input: $input, condition: $condition) {
      id
      gammaLinkedToId
      gamma {
        id
        friendlyId
        levelID
        level {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        levelHistory {
          nextToken
          __typename
        }
        title
        description
        measurementFields {
          measurementFieldID
          value
          __typename
        }
        additionalFields {
          name
          __typename
        }
        rankHistory {
          D
          R
          S
          __typename
        }
        mapping {
          gammaID
          organizationID
          mapping
          metadata
          createdAt
          updatedAt
          __typename
        }
        userID
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departments
        UserVotes {
          nextToken
          __typename
        }
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        ownerEmail
        hiddenUsers {
          nextToken
          __typename
        }
        sponsorID
        sponsor {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        contributors
        linkedTo {
          nextToken
          __typename
        }
        userRatings {
          nextToken
          __typename
        }
        fixedRank
        rank
        updatedByIAM
        createdAt
        updatedAt
        __typename
      }
      linkedGammasLinkedGammaId
      linkedGamma {
        id
        friendlyId
        levelID
        level {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        levelHistory {
          nextToken
          __typename
        }
        title
        description
        measurementFields {
          measurementFieldID
          value
          __typename
        }
        additionalFields {
          name
          __typename
        }
        rankHistory {
          D
          R
          S
          __typename
        }
        mapping {
          gammaID
          organizationID
          mapping
          metadata
          createdAt
          updatedAt
          __typename
        }
        userID
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departments
        UserVotes {
          nextToken
          __typename
        }
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        ownerEmail
        hiddenUsers {
          nextToken
          __typename
        }
        sponsorID
        sponsor {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        contributors
        linkedTo {
          nextToken
          __typename
        }
        userRatings {
          nextToken
          __typename
        }
        fixedRank
        rank
        updatedByIAM
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateLinkedGammas = /* GraphQL */ `
  mutation UpdateLinkedGammas(
    $input: UpdateLinkedGammasInput!
    $condition: ModelLinkedGammasConditionInput
  ) {
    updateLinkedGammas(input: $input, condition: $condition) {
      id
      gammaLinkedToId
      gamma {
        id
        friendlyId
        levelID
        level {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        levelHistory {
          nextToken
          __typename
        }
        title
        description
        measurementFields {
          measurementFieldID
          value
          __typename
        }
        additionalFields {
          name
          __typename
        }
        rankHistory {
          D
          R
          S
          __typename
        }
        mapping {
          gammaID
          organizationID
          mapping
          metadata
          createdAt
          updatedAt
          __typename
        }
        userID
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departments
        UserVotes {
          nextToken
          __typename
        }
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        ownerEmail
        hiddenUsers {
          nextToken
          __typename
        }
        sponsorID
        sponsor {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        contributors
        linkedTo {
          nextToken
          __typename
        }
        userRatings {
          nextToken
          __typename
        }
        fixedRank
        rank
        updatedByIAM
        createdAt
        updatedAt
        __typename
      }
      linkedGammasLinkedGammaId
      linkedGamma {
        id
        friendlyId
        levelID
        level {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        levelHistory {
          nextToken
          __typename
        }
        title
        description
        measurementFields {
          measurementFieldID
          value
          __typename
        }
        additionalFields {
          name
          __typename
        }
        rankHistory {
          D
          R
          S
          __typename
        }
        mapping {
          gammaID
          organizationID
          mapping
          metadata
          createdAt
          updatedAt
          __typename
        }
        userID
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departments
        UserVotes {
          nextToken
          __typename
        }
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        ownerEmail
        hiddenUsers {
          nextToken
          __typename
        }
        sponsorID
        sponsor {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        contributors
        linkedTo {
          nextToken
          __typename
        }
        userRatings {
          nextToken
          __typename
        }
        fixedRank
        rank
        updatedByIAM
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteLinkedGammas = /* GraphQL */ `
  mutation DeleteLinkedGammas(
    $input: DeleteLinkedGammasInput!
    $condition: ModelLinkedGammasConditionInput
  ) {
    deleteLinkedGammas(input: $input, condition: $condition) {
      id
      gammaLinkedToId
      gamma {
        id
        friendlyId
        levelID
        level {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        levelHistory {
          nextToken
          __typename
        }
        title
        description
        measurementFields {
          measurementFieldID
          value
          __typename
        }
        additionalFields {
          name
          __typename
        }
        rankHistory {
          D
          R
          S
          __typename
        }
        mapping {
          gammaID
          organizationID
          mapping
          metadata
          createdAt
          updatedAt
          __typename
        }
        userID
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departments
        UserVotes {
          nextToken
          __typename
        }
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        ownerEmail
        hiddenUsers {
          nextToken
          __typename
        }
        sponsorID
        sponsor {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        contributors
        linkedTo {
          nextToken
          __typename
        }
        userRatings {
          nextToken
          __typename
        }
        fixedRank
        rank
        updatedByIAM
        createdAt
        updatedAt
        __typename
      }
      linkedGammasLinkedGammaId
      linkedGamma {
        id
        friendlyId
        levelID
        level {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        levelHistory {
          nextToken
          __typename
        }
        title
        description
        measurementFields {
          measurementFieldID
          value
          __typename
        }
        additionalFields {
          name
          __typename
        }
        rankHistory {
          D
          R
          S
          __typename
        }
        mapping {
          gammaID
          organizationID
          mapping
          metadata
          createdAt
          updatedAt
          __typename
        }
        userID
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departments
        UserVotes {
          nextToken
          __typename
        }
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        ownerEmail
        hiddenUsers {
          nextToken
          __typename
        }
        sponsorID
        sponsor {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        contributors
        linkedTo {
          nextToken
          __typename
        }
        userRatings {
          nextToken
          __typename
        }
        fixedRank
        rank
        updatedByIAM
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createComparison = /* GraphQL */ `
  mutation CreateComparison(
    $input: CreateComparisonInput!
    $condition: ModelComparisonConditionInput
  ) {
    createComparison(input: $input, condition: $condition) {
      id
      gamma1ID
      gamma1 {
        id
        friendlyId
        levelID
        level {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        levelHistory {
          nextToken
          __typename
        }
        title
        description
        measurementFields {
          measurementFieldID
          value
          __typename
        }
        additionalFields {
          name
          __typename
        }
        rankHistory {
          D
          R
          S
          __typename
        }
        mapping {
          gammaID
          organizationID
          mapping
          metadata
          createdAt
          updatedAt
          __typename
        }
        userID
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departments
        UserVotes {
          nextToken
          __typename
        }
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        ownerEmail
        hiddenUsers {
          nextToken
          __typename
        }
        sponsorID
        sponsor {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        contributors
        linkedTo {
          nextToken
          __typename
        }
        userRatings {
          nextToken
          __typename
        }
        fixedRank
        rank
        updatedByIAM
        createdAt
        updatedAt
        __typename
      }
      gamma2ID
      gamma2 {
        id
        friendlyId
        levelID
        level {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        levelHistory {
          nextToken
          __typename
        }
        title
        description
        measurementFields {
          measurementFieldID
          value
          __typename
        }
        additionalFields {
          name
          __typename
        }
        rankHistory {
          D
          R
          S
          __typename
        }
        mapping {
          gammaID
          organizationID
          mapping
          metadata
          createdAt
          updatedAt
          __typename
        }
        userID
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departments
        UserVotes {
          nextToken
          __typename
        }
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        ownerEmail
        hiddenUsers {
          nextToken
          __typename
        }
        sponsorID
        sponsor {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        contributors
        linkedTo {
          nextToken
          __typename
        }
        userRatings {
          nextToken
          __typename
        }
        fixedRank
        rank
        updatedByIAM
        createdAt
        updatedAt
        __typename
      }
      objectiveID
      objective {
        id
        name
        description
        active
        organizationObjectivesId
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        ownerId
        owner {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      preference
      userID
      user {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      expiry
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateComparison = /* GraphQL */ `
  mutation UpdateComparison(
    $input: UpdateComparisonInput!
    $condition: ModelComparisonConditionInput
  ) {
    updateComparison(input: $input, condition: $condition) {
      id
      gamma1ID
      gamma1 {
        id
        friendlyId
        levelID
        level {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        levelHistory {
          nextToken
          __typename
        }
        title
        description
        measurementFields {
          measurementFieldID
          value
          __typename
        }
        additionalFields {
          name
          __typename
        }
        rankHistory {
          D
          R
          S
          __typename
        }
        mapping {
          gammaID
          organizationID
          mapping
          metadata
          createdAt
          updatedAt
          __typename
        }
        userID
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departments
        UserVotes {
          nextToken
          __typename
        }
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        ownerEmail
        hiddenUsers {
          nextToken
          __typename
        }
        sponsorID
        sponsor {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        contributors
        linkedTo {
          nextToken
          __typename
        }
        userRatings {
          nextToken
          __typename
        }
        fixedRank
        rank
        updatedByIAM
        createdAt
        updatedAt
        __typename
      }
      gamma2ID
      gamma2 {
        id
        friendlyId
        levelID
        level {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        levelHistory {
          nextToken
          __typename
        }
        title
        description
        measurementFields {
          measurementFieldID
          value
          __typename
        }
        additionalFields {
          name
          __typename
        }
        rankHistory {
          D
          R
          S
          __typename
        }
        mapping {
          gammaID
          organizationID
          mapping
          metadata
          createdAt
          updatedAt
          __typename
        }
        userID
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departments
        UserVotes {
          nextToken
          __typename
        }
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        ownerEmail
        hiddenUsers {
          nextToken
          __typename
        }
        sponsorID
        sponsor {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        contributors
        linkedTo {
          nextToken
          __typename
        }
        userRatings {
          nextToken
          __typename
        }
        fixedRank
        rank
        updatedByIAM
        createdAt
        updatedAt
        __typename
      }
      objectiveID
      objective {
        id
        name
        description
        active
        organizationObjectivesId
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        ownerId
        owner {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      preference
      userID
      user {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      expiry
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteComparison = /* GraphQL */ `
  mutation DeleteComparison(
    $input: DeleteComparisonInput!
    $condition: ModelComparisonConditionInput
  ) {
    deleteComparison(input: $input, condition: $condition) {
      id
      gamma1ID
      gamma1 {
        id
        friendlyId
        levelID
        level {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        levelHistory {
          nextToken
          __typename
        }
        title
        description
        measurementFields {
          measurementFieldID
          value
          __typename
        }
        additionalFields {
          name
          __typename
        }
        rankHistory {
          D
          R
          S
          __typename
        }
        mapping {
          gammaID
          organizationID
          mapping
          metadata
          createdAt
          updatedAt
          __typename
        }
        userID
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departments
        UserVotes {
          nextToken
          __typename
        }
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        ownerEmail
        hiddenUsers {
          nextToken
          __typename
        }
        sponsorID
        sponsor {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        contributors
        linkedTo {
          nextToken
          __typename
        }
        userRatings {
          nextToken
          __typename
        }
        fixedRank
        rank
        updatedByIAM
        createdAt
        updatedAt
        __typename
      }
      gamma2ID
      gamma2 {
        id
        friendlyId
        levelID
        level {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        levelHistory {
          nextToken
          __typename
        }
        title
        description
        measurementFields {
          measurementFieldID
          value
          __typename
        }
        additionalFields {
          name
          __typename
        }
        rankHistory {
          D
          R
          S
          __typename
        }
        mapping {
          gammaID
          organizationID
          mapping
          metadata
          createdAt
          updatedAt
          __typename
        }
        userID
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departments
        UserVotes {
          nextToken
          __typename
        }
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        ownerEmail
        hiddenUsers {
          nextToken
          __typename
        }
        sponsorID
        sponsor {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        contributors
        linkedTo {
          nextToken
          __typename
        }
        userRatings {
          nextToken
          __typename
        }
        fixedRank
        rank
        updatedByIAM
        createdAt
        updatedAt
        __typename
      }
      objectiveID
      objective {
        id
        name
        description
        active
        organizationObjectivesId
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        ownerId
        owner {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      preference
      userID
      user {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      expiry
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createMeasurementField = /* GraphQL */ `
  mutation CreateMeasurementField(
    $input: CreateMeasurementFieldInput!
    $condition: ModelMeasurementFieldConditionInput
  ) {
    createMeasurementField(input: $input, condition: $condition) {
      id
      name
      description
      type
      subType
      breakpoints {
        label
        value
        __typename
      }
      isMeasurement
      organizationID
      Organization {
        id
        name
        website
        contact
        address {
          street
          city
          zip
          state
          country
          __typename
        }
        vision {
          key
          value
          __typename
        }
        apiKeys {
          nextToken
          __typename
        }
        stages {
          nextToken
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        adminID
        Admin {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        type
        partnerID
        Partner {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Clients {
          nextToken
          __typename
        }
        Users {
          nextToken
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        gammas {
          nextToken
          __typename
        }
        gammaCount
        organizationDefaultPrioirtybatchId
        defaultPrioirtybatch {
          id
          title
          description
          public
          organizationID
          owners
          updatedAtBy
          createdAt
          updatedAt
          __typename
        }
        priorityBatches {
          nextToken
          __typename
        }
        winningCriteria
        scoreWeightages
        objectives {
          nextToken
          __typename
        }
        categoryValues
        weightSettings {
          role
          weight
          __typename
        }
        comparisonFlags
        ratingFlags
        invitationReminderConfig
        inactivityReminderConfig
        scenarioWeightsFlag
        measurementFields {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      StageConnection {
        items {
          measurementFieldID
          stageID
          organizationID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMeasurementField = /* GraphQL */ `
  mutation UpdateMeasurementField(
    $input: UpdateMeasurementFieldInput!
    $condition: ModelMeasurementFieldConditionInput
  ) {
    updateMeasurementField(input: $input, condition: $condition) {
      id
      name
      description
      type
      subType
      breakpoints {
        label
        value
        __typename
      }
      isMeasurement
      organizationID
      Organization {
        id
        name
        website
        contact
        address {
          street
          city
          zip
          state
          country
          __typename
        }
        vision {
          key
          value
          __typename
        }
        apiKeys {
          nextToken
          __typename
        }
        stages {
          nextToken
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        adminID
        Admin {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        type
        partnerID
        Partner {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Clients {
          nextToken
          __typename
        }
        Users {
          nextToken
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        gammas {
          nextToken
          __typename
        }
        gammaCount
        organizationDefaultPrioirtybatchId
        defaultPrioirtybatch {
          id
          title
          description
          public
          organizationID
          owners
          updatedAtBy
          createdAt
          updatedAt
          __typename
        }
        priorityBatches {
          nextToken
          __typename
        }
        winningCriteria
        scoreWeightages
        objectives {
          nextToken
          __typename
        }
        categoryValues
        weightSettings {
          role
          weight
          __typename
        }
        comparisonFlags
        ratingFlags
        invitationReminderConfig
        inactivityReminderConfig
        scenarioWeightsFlag
        measurementFields {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      StageConnection {
        items {
          measurementFieldID
          stageID
          organizationID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteMeasurementField = /* GraphQL */ `
  mutation DeleteMeasurementField(
    $input: DeleteMeasurementFieldInput!
    $condition: ModelMeasurementFieldConditionInput
  ) {
    deleteMeasurementField(input: $input, condition: $condition) {
      id
      name
      description
      type
      subType
      breakpoints {
        label
        value
        __typename
      }
      isMeasurement
      organizationID
      Organization {
        id
        name
        website
        contact
        address {
          street
          city
          zip
          state
          country
          __typename
        }
        vision {
          key
          value
          __typename
        }
        apiKeys {
          nextToken
          __typename
        }
        stages {
          nextToken
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        adminID
        Admin {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        type
        partnerID
        Partner {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Clients {
          nextToken
          __typename
        }
        Users {
          nextToken
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        gammas {
          nextToken
          __typename
        }
        gammaCount
        organizationDefaultPrioirtybatchId
        defaultPrioirtybatch {
          id
          title
          description
          public
          organizationID
          owners
          updatedAtBy
          createdAt
          updatedAt
          __typename
        }
        priorityBatches {
          nextToken
          __typename
        }
        winningCriteria
        scoreWeightages
        objectives {
          nextToken
          __typename
        }
        categoryValues
        weightSettings {
          role
          weight
          __typename
        }
        comparisonFlags
        ratingFlags
        invitationReminderConfig
        inactivityReminderConfig
        scenarioWeightsFlag
        measurementFields {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      StageConnection {
        items {
          measurementFieldID
          stageID
          organizationID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createMeasurementFieldStageConnection = /* GraphQL */ `
  mutation CreateMeasurementFieldStageConnection(
    $input: CreateMeasurementFieldStageConnectionInput!
    $condition: ModelMeasurementFieldStageConnectionConditionInput
  ) {
    createMeasurementFieldStageConnection(
      input: $input
      condition: $condition
    ) {
      measurementFieldID
      stageID
      measurementField {
        id
        name
        description
        type
        subType
        breakpoints {
          label
          value
          __typename
        }
        isMeasurement
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        StageConnection {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      stage {
        id
        name
        active
        level
        form {
          layout
          __typename
        }
        draft {
          nextToken
          __typename
        }
        updatedByIAM
        gammas {
          nextToken
          __typename
        }
        measurementFieldConnection {
          nextToken
          __typename
        }
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      organizationID
      Organization {
        id
        name
        website
        contact
        address {
          street
          city
          zip
          state
          country
          __typename
        }
        vision {
          key
          value
          __typename
        }
        apiKeys {
          nextToken
          __typename
        }
        stages {
          nextToken
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        adminID
        Admin {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        type
        partnerID
        Partner {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Clients {
          nextToken
          __typename
        }
        Users {
          nextToken
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        gammas {
          nextToken
          __typename
        }
        gammaCount
        organizationDefaultPrioirtybatchId
        defaultPrioirtybatch {
          id
          title
          description
          public
          organizationID
          owners
          updatedAtBy
          createdAt
          updatedAt
          __typename
        }
        priorityBatches {
          nextToken
          __typename
        }
        winningCriteria
        scoreWeightages
        objectives {
          nextToken
          __typename
        }
        categoryValues
        weightSettings {
          role
          weight
          __typename
        }
        comparisonFlags
        ratingFlags
        invitationReminderConfig
        inactivityReminderConfig
        scenarioWeightsFlag
        measurementFields {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMeasurementFieldStageConnection = /* GraphQL */ `
  mutation UpdateMeasurementFieldStageConnection(
    $input: UpdateMeasurementFieldStageConnectionInput!
    $condition: ModelMeasurementFieldStageConnectionConditionInput
  ) {
    updateMeasurementFieldStageConnection(
      input: $input
      condition: $condition
    ) {
      measurementFieldID
      stageID
      measurementField {
        id
        name
        description
        type
        subType
        breakpoints {
          label
          value
          __typename
        }
        isMeasurement
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        StageConnection {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      stage {
        id
        name
        active
        level
        form {
          layout
          __typename
        }
        draft {
          nextToken
          __typename
        }
        updatedByIAM
        gammas {
          nextToken
          __typename
        }
        measurementFieldConnection {
          nextToken
          __typename
        }
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      organizationID
      Organization {
        id
        name
        website
        contact
        address {
          street
          city
          zip
          state
          country
          __typename
        }
        vision {
          key
          value
          __typename
        }
        apiKeys {
          nextToken
          __typename
        }
        stages {
          nextToken
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        adminID
        Admin {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        type
        partnerID
        Partner {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Clients {
          nextToken
          __typename
        }
        Users {
          nextToken
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        gammas {
          nextToken
          __typename
        }
        gammaCount
        organizationDefaultPrioirtybatchId
        defaultPrioirtybatch {
          id
          title
          description
          public
          organizationID
          owners
          updatedAtBy
          createdAt
          updatedAt
          __typename
        }
        priorityBatches {
          nextToken
          __typename
        }
        winningCriteria
        scoreWeightages
        objectives {
          nextToken
          __typename
        }
        categoryValues
        weightSettings {
          role
          weight
          __typename
        }
        comparisonFlags
        ratingFlags
        invitationReminderConfig
        inactivityReminderConfig
        scenarioWeightsFlag
        measurementFields {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteMeasurementFieldStageConnection = /* GraphQL */ `
  mutation DeleteMeasurementFieldStageConnection(
    $input: DeleteMeasurementFieldStageConnectionInput!
    $condition: ModelMeasurementFieldStageConnectionConditionInput
  ) {
    deleteMeasurementFieldStageConnection(
      input: $input
      condition: $condition
    ) {
      measurementFieldID
      stageID
      measurementField {
        id
        name
        description
        type
        subType
        breakpoints {
          label
          value
          __typename
        }
        isMeasurement
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        StageConnection {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      stage {
        id
        name
        active
        level
        form {
          layout
          __typename
        }
        draft {
          nextToken
          __typename
        }
        updatedByIAM
        gammas {
          nextToken
          __typename
        }
        measurementFieldConnection {
          nextToken
          __typename
        }
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      organizationID
      Organization {
        id
        name
        website
        contact
        address {
          street
          city
          zip
          state
          country
          __typename
        }
        vision {
          key
          value
          __typename
        }
        apiKeys {
          nextToken
          __typename
        }
        stages {
          nextToken
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        adminID
        Admin {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        type
        partnerID
        Partner {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Clients {
          nextToken
          __typename
        }
        Users {
          nextToken
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        gammas {
          nextToken
          __typename
        }
        gammaCount
        organizationDefaultPrioirtybatchId
        defaultPrioirtybatch {
          id
          title
          description
          public
          organizationID
          owners
          updatedAtBy
          createdAt
          updatedAt
          __typename
        }
        priorityBatches {
          nextToken
          __typename
        }
        winningCriteria
        scoreWeightages
        objectives {
          nextToken
          __typename
        }
        categoryValues
        weightSettings {
          role
          weight
          __typename
        }
        comparisonFlags
        ratingFlags
        invitationReminderConfig
        inactivityReminderConfig
        scenarioWeightsFlag
        measurementFields {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createObjectivesAndDepartments = /* GraphQL */ `
  mutation CreateObjectivesAndDepartments(
    $input: CreateObjectivesAndDepartmentsInput!
    $condition: ModelObjectivesAndDepartmentsConditionInput
  ) {
    createObjectivesAndDepartments(input: $input, condition: $condition) {
      id
      objectiveID
      departmentID
      objective {
        id
        name
        description
        active
        organizationObjectivesId
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        ownerId
        owner {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      department {
        id
        name
        organizationID
        organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Users {
          nextToken
          __typename
        }
        Objectives {
          nextToken
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      ownerID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateObjectivesAndDepartments = /* GraphQL */ `
  mutation UpdateObjectivesAndDepartments(
    $input: UpdateObjectivesAndDepartmentsInput!
    $condition: ModelObjectivesAndDepartmentsConditionInput
  ) {
    updateObjectivesAndDepartments(input: $input, condition: $condition) {
      id
      objectiveID
      departmentID
      objective {
        id
        name
        description
        active
        organizationObjectivesId
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        ownerId
        owner {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      department {
        id
        name
        organizationID
        organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Users {
          nextToken
          __typename
        }
        Objectives {
          nextToken
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      ownerID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteObjectivesAndDepartments = /* GraphQL */ `
  mutation DeleteObjectivesAndDepartments(
    $input: DeleteObjectivesAndDepartmentsInput!
    $condition: ModelObjectivesAndDepartmentsConditionInput
  ) {
    deleteObjectivesAndDepartments(input: $input, condition: $condition) {
      id
      objectiveID
      departmentID
      objective {
        id
        name
        description
        active
        organizationObjectivesId
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        ownerId
        owner {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      department {
        id
        name
        organizationID
        organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Users {
          nextToken
          __typename
        }
        Objectives {
          nextToken
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      ownerID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createHiddenUsersAndHiddenGammas = /* GraphQL */ `
  mutation CreateHiddenUsersAndHiddenGammas(
    $input: CreateHiddenUsersAndHiddenGammasInput!
    $condition: ModelHiddenUsersAndHiddenGammasConditionInput
  ) {
    createHiddenUsersAndHiddenGammas(input: $input, condition: $condition) {
      id
      userId
      gammaId
      user {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      gamma {
        id
        friendlyId
        levelID
        level {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        levelHistory {
          nextToken
          __typename
        }
        title
        description
        measurementFields {
          measurementFieldID
          value
          __typename
        }
        additionalFields {
          name
          __typename
        }
        rankHistory {
          D
          R
          S
          __typename
        }
        mapping {
          gammaID
          organizationID
          mapping
          metadata
          createdAt
          updatedAt
          __typename
        }
        userID
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departments
        UserVotes {
          nextToken
          __typename
        }
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        ownerEmail
        hiddenUsers {
          nextToken
          __typename
        }
        sponsorID
        sponsor {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        contributors
        linkedTo {
          nextToken
          __typename
        }
        userRatings {
          nextToken
          __typename
        }
        fixedRank
        rank
        updatedByIAM
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateHiddenUsersAndHiddenGammas = /* GraphQL */ `
  mutation UpdateHiddenUsersAndHiddenGammas(
    $input: UpdateHiddenUsersAndHiddenGammasInput!
    $condition: ModelHiddenUsersAndHiddenGammasConditionInput
  ) {
    updateHiddenUsersAndHiddenGammas(input: $input, condition: $condition) {
      id
      userId
      gammaId
      user {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      gamma {
        id
        friendlyId
        levelID
        level {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        levelHistory {
          nextToken
          __typename
        }
        title
        description
        measurementFields {
          measurementFieldID
          value
          __typename
        }
        additionalFields {
          name
          __typename
        }
        rankHistory {
          D
          R
          S
          __typename
        }
        mapping {
          gammaID
          organizationID
          mapping
          metadata
          createdAt
          updatedAt
          __typename
        }
        userID
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departments
        UserVotes {
          nextToken
          __typename
        }
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        ownerEmail
        hiddenUsers {
          nextToken
          __typename
        }
        sponsorID
        sponsor {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        contributors
        linkedTo {
          nextToken
          __typename
        }
        userRatings {
          nextToken
          __typename
        }
        fixedRank
        rank
        updatedByIAM
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteHiddenUsersAndHiddenGammas = /* GraphQL */ `
  mutation DeleteHiddenUsersAndHiddenGammas(
    $input: DeleteHiddenUsersAndHiddenGammasInput!
    $condition: ModelHiddenUsersAndHiddenGammasConditionInput
  ) {
    deleteHiddenUsersAndHiddenGammas(input: $input, condition: $condition) {
      id
      userId
      gammaId
      user {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      gamma {
        id
        friendlyId
        levelID
        level {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        levelHistory {
          nextToken
          __typename
        }
        title
        description
        measurementFields {
          measurementFieldID
          value
          __typename
        }
        additionalFields {
          name
          __typename
        }
        rankHistory {
          D
          R
          S
          __typename
        }
        mapping {
          gammaID
          organizationID
          mapping
          metadata
          createdAt
          updatedAt
          __typename
        }
        userID
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departments
        UserVotes {
          nextToken
          __typename
        }
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        ownerEmail
        hiddenUsers {
          nextToken
          __typename
        }
        sponsorID
        sponsor {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        contributors
        linkedTo {
          nextToken
          __typename
        }
        userRatings {
          nextToken
          __typename
        }
        fixedRank
        rank
        updatedByIAM
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createGamma = /* GraphQL */ `
  mutation CreateGamma(
    $input: CreateGammaInput!
    $condition: ModelGammaConditionInput
  ) {
    createGamma(input: $input, condition: $condition) {
      id
      friendlyId
      levelID
      level {
        id
        name
        active
        level
        form {
          layout
          __typename
        }
        draft {
          nextToken
          __typename
        }
        updatedByIAM
        gammas {
          nextToken
          __typename
        }
        measurementFieldConnection {
          nextToken
          __typename
        }
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      levelHistory {
        items {
          id
          previousID
          presentID
          gammaLevelHistoryId
          changedAt
          userID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      title
      description
      measurementFields {
        measurementFieldID
        value
        __typename
      }
      additionalFields {
        name
        value {
          text
          updatedAtBy
          __typename
        }
        __typename
      }
      rankHistory {
        D
        R
        S
        __typename
      }
      mapping {
        gammaID
        organizationID
        Gamma {
          id
          friendlyId
          levelID
          title
          description
          userID
          organizationID
          departments
          ownerEmail
          sponsorID
          contributors
          fixedRank
          rank
          updatedByIAM
          createdAt
          updatedAt
          __typename
        }
        mapping
        metadata
        createdAt
        updatedAt
        __typename
      }
      userID
      organizationID
      Organization {
        id
        name
        website
        contact
        address {
          street
          city
          zip
          state
          country
          __typename
        }
        vision {
          key
          value
          __typename
        }
        apiKeys {
          nextToken
          __typename
        }
        stages {
          nextToken
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        adminID
        Admin {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        type
        partnerID
        Partner {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Clients {
          nextToken
          __typename
        }
        Users {
          nextToken
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        gammas {
          nextToken
          __typename
        }
        gammaCount
        organizationDefaultPrioirtybatchId
        defaultPrioirtybatch {
          id
          title
          description
          public
          organizationID
          owners
          updatedAtBy
          createdAt
          updatedAt
          __typename
        }
        priorityBatches {
          nextToken
          __typename
        }
        winningCriteria
        scoreWeightages
        objectives {
          nextToken
          __typename
        }
        categoryValues
        weightSettings {
          role
          weight
          __typename
        }
        comparisonFlags
        ratingFlags
        invitationReminderConfig
        inactivityReminderConfig
        scenarioWeightsFlag
        measurementFields {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      departments
      UserVotes {
        items {
          id
          vote
          weight
          userID
          organizationID
          gammaID
          userVoteObjectiveId
          voteTime
          createdAt
          updatedAt
          userVoteVsGammaId
          __typename
        }
        nextToken
        __typename
      }
      user {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      ownerEmail
      hiddenUsers {
        items {
          id
          userId
          gammaId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sponsorID
      sponsor {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      contributors
      linkedTo {
        items {
          id
          gammaLinkedToId
          linkedGammasLinkedGammaId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      userRatings {
        items {
          id
          userID
          organizationID
          gammaID
          rating
          weight
          userRatingObjectiveId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      fixedRank
      rank
      updatedByIAM
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateGamma = /* GraphQL */ `
  mutation UpdateGamma(
    $input: UpdateGammaInput!
    $condition: ModelGammaConditionInput
  ) {
    updateGamma(input: $input, condition: $condition) {
      id
      friendlyId
      levelID
      level {
        id
        name
        active
        level
        form {
          layout
          __typename
        }
        draft {
          nextToken
          __typename
        }
        updatedByIAM
        gammas {
          nextToken
          __typename
        }
        measurementFieldConnection {
          nextToken
          __typename
        }
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      levelHistory {
        items {
          id
          previousID
          presentID
          gammaLevelHistoryId
          changedAt
          userID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      title
      description
      measurementFields {
        measurementFieldID
        value
        __typename
      }
      additionalFields {
        name
        value {
          text
          updatedAtBy
          __typename
        }
        __typename
      }
      rankHistory {
        D
        R
        S
        __typename
      }
      mapping {
        gammaID
        organizationID
        Gamma {
          id
          friendlyId
          levelID
          title
          description
          userID
          organizationID
          departments
          ownerEmail
          sponsorID
          contributors
          fixedRank
          rank
          updatedByIAM
          createdAt
          updatedAt
          __typename
        }
        mapping
        metadata
        createdAt
        updatedAt
        __typename
      }
      userID
      organizationID
      Organization {
        id
        name
        website
        contact
        address {
          street
          city
          zip
          state
          country
          __typename
        }
        vision {
          key
          value
          __typename
        }
        apiKeys {
          nextToken
          __typename
        }
        stages {
          nextToken
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        adminID
        Admin {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        type
        partnerID
        Partner {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Clients {
          nextToken
          __typename
        }
        Users {
          nextToken
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        gammas {
          nextToken
          __typename
        }
        gammaCount
        organizationDefaultPrioirtybatchId
        defaultPrioirtybatch {
          id
          title
          description
          public
          organizationID
          owners
          updatedAtBy
          createdAt
          updatedAt
          __typename
        }
        priorityBatches {
          nextToken
          __typename
        }
        winningCriteria
        scoreWeightages
        objectives {
          nextToken
          __typename
        }
        categoryValues
        weightSettings {
          role
          weight
          __typename
        }
        comparisonFlags
        ratingFlags
        invitationReminderConfig
        inactivityReminderConfig
        scenarioWeightsFlag
        measurementFields {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      departments
      UserVotes {
        items {
          id
          vote
          weight
          userID
          organizationID
          gammaID
          userVoteObjectiveId
          voteTime
          createdAt
          updatedAt
          userVoteVsGammaId
          __typename
        }
        nextToken
        __typename
      }
      user {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      ownerEmail
      hiddenUsers {
        items {
          id
          userId
          gammaId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sponsorID
      sponsor {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      contributors
      linkedTo {
        items {
          id
          gammaLinkedToId
          linkedGammasLinkedGammaId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      userRatings {
        items {
          id
          userID
          organizationID
          gammaID
          rating
          weight
          userRatingObjectiveId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      fixedRank
      rank
      updatedByIAM
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteGamma = /* GraphQL */ `
  mutation DeleteGamma(
    $input: DeleteGammaInput!
    $condition: ModelGammaConditionInput
  ) {
    deleteGamma(input: $input, condition: $condition) {
      id
      friendlyId
      levelID
      level {
        id
        name
        active
        level
        form {
          layout
          __typename
        }
        draft {
          nextToken
          __typename
        }
        updatedByIAM
        gammas {
          nextToken
          __typename
        }
        measurementFieldConnection {
          nextToken
          __typename
        }
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      levelHistory {
        items {
          id
          previousID
          presentID
          gammaLevelHistoryId
          changedAt
          userID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      title
      description
      measurementFields {
        measurementFieldID
        value
        __typename
      }
      additionalFields {
        name
        value {
          text
          updatedAtBy
          __typename
        }
        __typename
      }
      rankHistory {
        D
        R
        S
        __typename
      }
      mapping {
        gammaID
        organizationID
        Gamma {
          id
          friendlyId
          levelID
          title
          description
          userID
          organizationID
          departments
          ownerEmail
          sponsorID
          contributors
          fixedRank
          rank
          updatedByIAM
          createdAt
          updatedAt
          __typename
        }
        mapping
        metadata
        createdAt
        updatedAt
        __typename
      }
      userID
      organizationID
      Organization {
        id
        name
        website
        contact
        address {
          street
          city
          zip
          state
          country
          __typename
        }
        vision {
          key
          value
          __typename
        }
        apiKeys {
          nextToken
          __typename
        }
        stages {
          nextToken
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        adminID
        Admin {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        type
        partnerID
        Partner {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Clients {
          nextToken
          __typename
        }
        Users {
          nextToken
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        gammas {
          nextToken
          __typename
        }
        gammaCount
        organizationDefaultPrioirtybatchId
        defaultPrioirtybatch {
          id
          title
          description
          public
          organizationID
          owners
          updatedAtBy
          createdAt
          updatedAt
          __typename
        }
        priorityBatches {
          nextToken
          __typename
        }
        winningCriteria
        scoreWeightages
        objectives {
          nextToken
          __typename
        }
        categoryValues
        weightSettings {
          role
          weight
          __typename
        }
        comparisonFlags
        ratingFlags
        invitationReminderConfig
        inactivityReminderConfig
        scenarioWeightsFlag
        measurementFields {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      departments
      UserVotes {
        items {
          id
          vote
          weight
          userID
          organizationID
          gammaID
          userVoteObjectiveId
          voteTime
          createdAt
          updatedAt
          userVoteVsGammaId
          __typename
        }
        nextToken
        __typename
      }
      user {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      ownerEmail
      hiddenUsers {
        items {
          id
          userId
          gammaId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sponsorID
      sponsor {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      contributors
      linkedTo {
        items {
          id
          gammaLinkedToId
          linkedGammasLinkedGammaId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      userRatings {
        items {
          id
          userID
          organizationID
          gammaID
          rating
          weight
          userRatingObjectiveId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      fixedRank
      rank
      updatedByIAM
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createGammaZapierMapping = /* GraphQL */ `
  mutation CreateGammaZapierMapping(
    $input: CreateGammaZapierMappingInput!
    $condition: ModelGammaZapierMappingConditionInput
  ) {
    createGammaZapierMapping(input: $input, condition: $condition) {
      gammaID
      organizationID
      Gamma {
        id
        friendlyId
        levelID
        level {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        levelHistory {
          nextToken
          __typename
        }
        title
        description
        measurementFields {
          measurementFieldID
          value
          __typename
        }
        additionalFields {
          name
          __typename
        }
        rankHistory {
          D
          R
          S
          __typename
        }
        mapping {
          gammaID
          organizationID
          mapping
          metadata
          createdAt
          updatedAt
          __typename
        }
        userID
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departments
        UserVotes {
          nextToken
          __typename
        }
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        ownerEmail
        hiddenUsers {
          nextToken
          __typename
        }
        sponsorID
        sponsor {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        contributors
        linkedTo {
          nextToken
          __typename
        }
        userRatings {
          nextToken
          __typename
        }
        fixedRank
        rank
        updatedByIAM
        createdAt
        updatedAt
        __typename
      }
      mapping
      metadata
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateGammaZapierMapping = /* GraphQL */ `
  mutation UpdateGammaZapierMapping(
    $input: UpdateGammaZapierMappingInput!
    $condition: ModelGammaZapierMappingConditionInput
  ) {
    updateGammaZapierMapping(input: $input, condition: $condition) {
      gammaID
      organizationID
      Gamma {
        id
        friendlyId
        levelID
        level {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        levelHistory {
          nextToken
          __typename
        }
        title
        description
        measurementFields {
          measurementFieldID
          value
          __typename
        }
        additionalFields {
          name
          __typename
        }
        rankHistory {
          D
          R
          S
          __typename
        }
        mapping {
          gammaID
          organizationID
          mapping
          metadata
          createdAt
          updatedAt
          __typename
        }
        userID
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departments
        UserVotes {
          nextToken
          __typename
        }
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        ownerEmail
        hiddenUsers {
          nextToken
          __typename
        }
        sponsorID
        sponsor {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        contributors
        linkedTo {
          nextToken
          __typename
        }
        userRatings {
          nextToken
          __typename
        }
        fixedRank
        rank
        updatedByIAM
        createdAt
        updatedAt
        __typename
      }
      mapping
      metadata
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteGammaZapierMapping = /* GraphQL */ `
  mutation DeleteGammaZapierMapping(
    $input: DeleteGammaZapierMappingInput!
    $condition: ModelGammaZapierMappingConditionInput
  ) {
    deleteGammaZapierMapping(input: $input, condition: $condition) {
      gammaID
      organizationID
      Gamma {
        id
        friendlyId
        levelID
        level {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        levelHistory {
          nextToken
          __typename
        }
        title
        description
        measurementFields {
          measurementFieldID
          value
          __typename
        }
        additionalFields {
          name
          __typename
        }
        rankHistory {
          D
          R
          S
          __typename
        }
        mapping {
          gammaID
          organizationID
          mapping
          metadata
          createdAt
          updatedAt
          __typename
        }
        userID
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departments
        UserVotes {
          nextToken
          __typename
        }
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        ownerEmail
        hiddenUsers {
          nextToken
          __typename
        }
        sponsorID
        sponsor {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        contributors
        linkedTo {
          nextToken
          __typename
        }
        userRatings {
          nextToken
          __typename
        }
        fixedRank
        rank
        updatedByIAM
        createdAt
        updatedAt
        __typename
      }
      mapping
      metadata
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      Message
      isRead
      type
      userID
      UserDetails {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      metadata
      __typename
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      Message
      isRead
      type
      userID
      UserDetails {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      metadata
      __typename
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      Message
      isRead
      type
      userID
      UserDetails {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      metadata
      __typename
    }
  }
`;
