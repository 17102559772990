import {
  Row,
  Select,
  Input,
  Space,
  Button,
  Typography,
  Drawer,
  Checkbox,
  Radio,
  Divider,
  Form,
  Collapse,
  Col,
  Grid,
  Switch,
  message,
  Empty,
  DatePicker,
  Dropdown,
  Menu,
  Spin,
  Tooltip,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { ECHOIMAGES } from '../../Assets/Images';
import '../AllPriorities/idea.less';
import './goal.less';
import {
  CheckOutlined,
  DownOutlined,
  MoreOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import {
  getCurrentOrganization,
  getCurrnetUserData,
  isAdmin,
  isLeader,
  isSME,
  isSuperAdmin,
} from '../../store/slices/loginSlice';
import { getGoals, listObjective, archiveGoal } from './GoalFunction';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import {
  sortArrayByDate,
  sortArrayByProperty,
  filterDataByTimeRange,
  arrToCommaSeperated,
} from '../../utils/commonMethods';
import TableSkeleton from 'Common/TableSkeleton';
import { selectAllDepartment } from 'utils/Constants';
import DeleteGoalModal from './Components/DeleteGoalModal';
import { updateUserSettingCustom } from 'utils/Actions';
import ArchivedGoalModal from './Components/ArchivedGoalModal';
import FilterButton from 'Common/FilterButton';

export default function Goal({
  setListLeaderGoal,
  listLeaderGoal,
  lisBackupGoal,
  setListBackupGoal,
  listGoals,
  loading,
  defaultGoalWeight,
  goalFlag,
  getVisionDetails,
  originalData,
  setUserCount,
  listActiveGoals,
}) {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const { Search } = Input;
  const { Panel } = Collapse;
  const { SortIcon, FilterIcon } = ECHOIMAGES.ideaIcons;
  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;
  const userId = currnetOrg?.adminID || loginUsrOrg?.id;
  const isLeaderGroup = useSelector(isLeader);
  const isSuperAdminGroup = useSelector(isSuperAdmin);
  const isSMEGroup = useSelector(isSME);
  const isAdminGroup = useSelector(isAdmin);
  const history = useHistory();
  const { RangePicker } = DatePicker;
  const { Text } = Typography;

  // drawer stuff
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  // list goal state

  const [ownerFilter, setOwnerFilter] = useState([]);
  const [timeRange, setTimeRange] = useState('');
  const [customRange, setCustomRange] = useState();
  const [selectAll, setSelectAll] = useState(false);
  const [goalAddedByOwner, setGoalAddedByOwner] = useState(false);
  const [smeLoading, setSmeLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [goalID, setGoalID] = useState('');
  const [archiveModalOpen, setArchiveModalOpen] = useState(false);
  const [archiveGoalID, setArchiveGoalID] = useState();
  const [loader, setLoader] = useState(false);
  const [goalLoading, setGoalLoading] = useState(false);

  const handleModalOpen = () => {
    setDeleteModal(true);
  };

  const onSearch = (e) => {
    if (e !== '') {
      const temp = lisBackupGoal.filter(
        (item) =>
          item?.name && item?.name.toLowerCase().includes(e.toLowerCase())
      );
      setListLeaderGoal(temp);
    } else {
      setListLeaderGoal(lisBackupGoal);
    }
  };

  const showAllData = () => {
    setListLeaderGoal(originalData);
  };

  // sort by oldest date
  const sortByOldest = () => {
    const sortedData = sortArrayByDate(listLeaderGoal, 'createdAt', true);
    setListLeaderGoal(sortedData);
  };

  // sort by newest date
  const sortByNewest = () => {
    const sortedData = sortArrayByDate(listLeaderGoal, 'createdAt', false);
    setListLeaderGoal(sortedData);
  };

  // sort by modifiled date oldest
  const sortByModifiedOldest = () => {
    const sortedData = sortArrayByDate(listLeaderGoal, 'updatedAt', true);
    setListLeaderGoal(sortedData);
  };

  // sort by  modifiled date oldest
  const sortByModifiedNewest = () => {
    const sortedData = sortArrayByDate(listLeaderGoal, 'updatedAt', false);
    setListLeaderGoal(sortedData);
  };
  // for ascending data by title
  const sortAscending = () => {
    const sortedData = sortArrayByProperty(listLeaderGoal, 'name', true);
    setListLeaderGoal(sortedData);
  };

  // for descending data by title
  const sortDescending = () => {
    const sortedData = sortArrayByProperty(listLeaderGoal, 'name', false);
    setListLeaderGoal(sortedData);
  };

  // Helper function to sort by department
  const sortArrayByDepartment = (array, ascending) => {
    const departmentSort = [...array].sort((a, b) => {
      const departmentA =
        a.Departments.items.length && a.Departments.items[0].department
          ? a.Departments.items[0].department.name
          : '';
      const departmentB =
        b.Departments.items.length && b.Departments.items[0].department
          ? b.Departments.items[0].department.name
          : '';
      return ascending
        ? departmentA.localeCompare(departmentB)
        : departmentB.localeCompare(departmentA);
    });
    return departmentSort;
  };

  // for ascending data by department
  const sortDepartmentAscending = () => {
    const sortedData = sortArrayByDepartment(listLeaderGoal, true);
    setListLeaderGoal(sortedData);
  };

  // for descending data by department
  const sortDepartmentDescending = () => {
    const sortedData = sortArrayByDepartment(listLeaderGoal, false);
    setListLeaderGoal(sortedData);
  };

  const onFilterApply = () => {
    const temp = [];
    if (goalAddedByOwner) {
      const goalsOwner = lisBackupGoal.filter((el) =>
        el?.owner?.id.includes(userId)
      );
      goalsOwner.map((item) => {
        if (ownerFilter.length) {
          const data = ownerFilter.filter((el) =>
            el.includes(item?.owner?.email)
          );
          if (data?.length) {
            temp.push(item);
          }
        } else {
          temp.push(item);
        }
        return temp;
      });
    } else {
      lisBackupGoal.map((item) => {
        if (ownerFilter.length) {
          const data = ownerFilter.filter((el) =>
            el.includes(item?.owner?.email)
          );
          if (data?.length) {
            temp.push(item);
          }
        } else {
          temp.push(item);
        }
        return temp;
      });
    }
    const filterDate = filterDataByTimeRange(timeRange, temp, customRange);
    setUserCount({ activeGoalLength: filterDate?.length });
    setListLeaderGoal(filterDate);
    setOpen(false);
  };

  const uniqueFirstNames = [];
  const filteredData = lisBackupGoal?.filter((item) => {
    if (
      item?.owner !== null &&
      !uniqueFirstNames.includes(item?.owner?.firstName)
    ) {
      uniqueFirstNames.push(item?.owner?.firstName);
      return true;
    }
    return false;
  });

  const archiveObjectives = async () => {
    setLoader(true);
    try {
      let input = {
        id: archiveGoalID,
        active: false,
      };
      await archiveGoal(input);
      message.success('Goal Archive Successfully');
      setArchiveModalOpen(false);
      await listGoals();
      await listActiveGoals();
      setLoader(false);
    } catch (err) {
      setLoader(false);
      console.log(err);
    }
  };

  // list goal for sme
  const listGoalsSME = async () => {
    setSmeLoading(true);
    try {
      let id = {
        organizationObjectivesId: orgId,
        filter: {
          active: { eq: true },
        },
      };
      const smeGoal = await getGoals({ id: orgId });
      const res = await listObjective(id);
      const temp = [];
      res?.data?.objectivesByOrganizationObjectivesId?.items.forEach((el) => {
        const data = smeGoal?.defaultPrioirtybatch?.priorities?.filter(
          (vl) => vl?.ObjectiveID === el?.id
        );
        if (data?.length > 0) {
          temp.push({ ...data[0], ...el });
        }
      });
      setListLeaderGoal(temp);
      setListBackupGoal(temp);
      setSmeLoading(false);
    } catch (error) {
      message.error(error.message);
      console.log(error);
      setSmeLoading(false);
    }
  };

  const updateGoalSetting = async (e) => {
    setGoalLoading(true);
    try {
      let input = {
        id: orgId,
        scenarioWeightsFlag: e.target.checked,
      };
      await updateUserSettingCustom(input);
      await getVisionDetails();
      message.success(`Changes saved successfully`);
      setGoalLoading(false);
    } catch (err) {
      setGoalLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    getVisionDetails();
    // eslint-disable-next-line
  }, [goalFlag]);

  useEffect(() => {
    if (isSMEGroup) {
      listGoalsSME();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div
        className={
          isSMEGroup
            ? 'idea background-white mb-20 p-20 mt-20'
            : 'idea background-white mb-20 p-20'
        }
      >
        <Row
          gutter={[0, 10]}
          justify="space-between"
          align="middle"
          className="idea-header w-100 "
        >
          <Search
            className="searchbar-measurement"
            placeholder="Search goal"
            onChange={(e) => onSearch(e.target.value)}
            id="goal-search-input"
          />

          <Row gutter={[0, 10]} align="middle">
            <Typography.Text>Sort by</Typography.Text>
            <Select
              placeholder="Select sort"
              className="h-35 ml-10 sorting-common"
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              defaultValue="None"
              options={[
                {
                  value: 'None',
                  label: (
                    <Space
                      onClick={showAllData}
                      className="goal-active-sort-none"
                    >
                      None
                    </Space>
                  ),
                },
                {
                  value: 'Created date - Newest',
                  label: (
                    <Space
                      onClick={sortByNewest}
                      className="goal-active-sort-created-date-newest"
                    >
                      Created date - Newest
                    </Space>
                  ),
                },
                {
                  value: 'Created date - Oldest',
                  label: (
                    <Space
                      onClick={sortByOldest}
                      className="goal-active-sort-created-date-oldest"
                    >
                      Created date - Oldest
                    </Space>
                  ),
                },
                {
                  value: 'Modified date - Newest',
                  label: (
                    <Space
                      onClick={sortByModifiedNewest}
                      className="goal-active-sort-modified-date-newest"
                    >
                      Modified date - Newest
                    </Space>
                  ),
                },
                {
                  value: 'Modified date - Oldest',
                  label: (
                    <Space
                      onClick={sortByModifiedOldest}
                      className="goal-active-sort-modified-date-oldest"
                    >
                      Modified date - Oldest
                    </Space>
                  ),
                },
                {
                  value: 'Title - A to Z',
                  label: (
                    <Space
                      onClick={sortAscending}
                      className="goal-active-sort-title-AtoZ"
                    >
                      Title - A to Z
                    </Space>
                  ),
                },
                {
                  value: 'Title - Z to A',
                  label: (
                    <Space
                      onClick={sortDescending}
                      className="goal-active-sort-title-ZtoA"
                    >
                      Title - Z to A
                    </Space>
                  ),
                },
                {
                  value: 'Department - A to Z',
                  label: (
                    <Space
                      onClick={sortDepartmentAscending}
                      // className="goal-active-sort-title-AtoZ"
                    >
                      Department - A to Z
                    </Space>
                  ),
                },
                {
                  value: 'Department - Z to A',
                  label: (
                    <Space
                      onClick={sortDepartmentDescending}
                      // className="goal-active-sort-title-ZtoA"
                    >
                      Department - Z to A
                    </Space>
                  ),
                },
              ]}
            />
            <FilterButton
              onClick={showDrawer}
              className="goal-active-filter-btn"
            />
          </Row>
        </Row>
        {listLeaderGoal?.length ? (
          <Collapse
            ghost
            accordion
            expandIcon={({ isActive }) => (
              <Typography.Title
                level={4}
                className="mb-0 fw-500 fs-16 goal-view-more"
                id="goal-view-more"
              >
                {screens.xs ? '' : isActive ? 'View less' : 'View more'}
                <DownOutlined className="ml-10" rotate={isActive ? 180 : 0} />
              </Typography.Title>
            )}
            expandIconPosition="end"
            className="goal mt-30 goal-view-more"
          >
            {listLeaderGoal?.map((item, index) => {
              return (
                <Panel
                  className="mb-20"
                  key={index}
                  extra={
                    <>
                      <Row align="middle" className="goal-weight">
                        {(isSuperAdminGroup ||
                          isAdminGroup ||
                          currnetOrg?.id ||
                          isLeaderGroup) && (
                          <Typography.Title
                            level={5}
                            className="mb-0 fw-500 fs-14"
                          >
                            {defaultGoalWeight?.filter(
                              (e) => e?.ObjectiveID === item?.id
                            )?.[0] ? (
                              <>
                                Goal weight :{' '}
                                <span className="text-primary fw-bolder fs-14">
                                  {
                                    defaultGoalWeight?.filter(
                                      (e) => e?.ObjectiveID === item?.id
                                    )?.[0]?.weight
                                  }{' '}
                                  %
                                </span>
                              </>
                            ) : null}
                          </Typography.Title>
                        )}

                        {(isSuperAdminGroup ||
                          isAdminGroup ||
                          (isLeaderGroup && item?.ownerId === userId)) && (
                          <Dropdown
                            arrow
                            overlay={
                              <Menu>
                                <Menu.Item key="1">
                                  <Button
                                    type="text"
                                    size="small"
                                    className="w-100 p-0 text-left hover-off goal-edit"
                                    onClick={() => {
                                      history.push({
                                        pathname: `/add-goal/${item.id}`,
                                      });
                                    }}
                                    id={`goal-edit-${item?.id}`}
                                  >
                                    Edit
                                  </Button>
                                </Menu.Item>
                                <Menu.Item key="2">
                                  <Button
                                    type="text"
                                    size="small"
                                    className="w-100 p-0 text-left hover-off goal-archive"
                                    onClick={() => {
                                      setArchiveModalOpen(true);
                                      setArchiveGoalID(item?.id);
                                    }}
                                    id={`goal-archive-${item?.id}`}
                                  >
                                    Archive Goal
                                  </Button>
                                </Menu.Item>

                                {isSuperAdminGroup && (
                                  <Menu.Item key="3">
                                    <Button
                                      type="text"
                                      size="small"
                                      className="w-100 p-0 text-left hover-off"
                                      onClick={() => {
                                        handleModalOpen();
                                        setGoalID(item?.id);
                                      }}
                                    >
                                      Delete Goal
                                    </Button>
                                  </Menu.Item>
                                )}
                              </Menu>
                            }
                          >
                            <Button
                              type="text"
                              onClick={(e) => e.preventDefault()}
                              className="goal-active-menu"
                            >
                              <MoreOutlined />
                            </Button>
                          </Dropdown>
                        )}
                      </Row>

                      {isSMEGroup && goalFlag && (
                        <Typography.Title
                          level={5}
                          className="mb-0  fw-500 mr-20 fs-14"
                        >
                          Goal weight :{' '}
                          <span className="text-primary fw-bolder fs-14">
                            {
                              defaultGoalWeight?.filter(
                                (e) => e?.ObjectiveID === item?.id
                              )?.[0]?.weight
                            }{' '}
                            %
                          </span>
                        </Typography.Title>
                      )}
                    </>
                  }
                  header={
                    <div className="goal-list">
                      <Typography.Title
                        level={5}
                        className="fw-bolder fs-18"
                        id="goal-active-name"
                      >
                        {item?.name}
                      </Typography.Title>

                      <Row className="head-data mt-20" gutter={[0, 20]}>
                        <Col span={6} lg={6} md={8} sm={24} xs={24}>
                          <Typography.Title
                            level={5}
                            className="mb-0 title fs-14"
                            data-active-goal-created-at={item?.createdAt}
                          >
                            Created date :
                          </Typography.Title>
                          <Typography.Title
                            level={5}
                            className="mb-0 mt-0 data"
                          >
                            {' '}
                            {moment(item?.createdAt).format('l')}
                          </Typography.Title>
                        </Col>
                        <Col span={6} lg={6} md={8} sm={24} xs={24}>
                          <Typography.Title
                            level={5}
                            className="mb-0 title fs-14"
                            data-active-goal-modified-at={item?.updatedAt}
                          >
                            Last modified date :
                          </Typography.Title>
                          <Typography.Title
                            level={5}
                            className="mb-0 mt-0 data"
                          >
                            {' '}
                            {moment(item?.updatedAt).format('l')}
                          </Typography.Title>
                        </Col>
                        <Col span={6} lg={6} md={8} sm={24} xs={24}>
                          <Typography.Title
                            level={5}
                            className="mb-0 title fs-14"
                            data-active-goal-owner={
                              item?.owner?.firstName
                                ? item?.owner?.firstName +
                                  ' ' +
                                  item?.owner?.lastName
                                : '-'
                            }
                          >
                            Owner :
                          </Typography.Title>
                          <Typography.Title
                            level={5}
                            className="mb-0 mt-0 data fs-14"
                          >
                            {' '}
                            <Text
                              style={
                                true
                                  ? {
                                      width: 150,
                                    }
                                  : undefined
                              }
                              ellipsis={
                                true
                                  ? {
                                      tooltip: (
                                        <span>
                                          {' '}
                                          {item?.owner?.firstName
                                            ? item?.owner?.firstName +
                                              ' ' +
                                              item?.owner?.lastName
                                            : '-'}
                                        </span>
                                      ),
                                    }
                                  : false
                              }
                              className="fs-14"
                            >
                              {item?.owner?.firstName
                                ? item?.owner?.firstName +
                                  ' ' +
                                  item?.owner?.lastName
                                : '-'}
                            </Text>
                          </Typography.Title>
                        </Col>
                        <Col span={6} lg={6} md={8} sm={24} xs={24}>
                          <Typography.Title
                            level={5}
                            className="mb-0 title fs-14"
                          >
                            Department :
                          </Typography.Title>
                          <Typography.Title
                            level={5}
                            className="mb-0 mt-0 data fs-14"
                          >
                            {' '}
                            <Text
                              style={
                                true
                                  ? {
                                      width: 120,
                                    }
                                  : undefined
                              }
                              ellipsis={
                                true
                                  ? {
                                      tooltip: (
                                        <span>
                                          {' '}
                                          {item?.Departments?.items?.length
                                            ? arrToCommaSeperated(
                                                item?.Departments?.items?.map(
                                                  (el) => {
                                                    return el?.departmentID ===
                                                      selectAllDepartment
                                                      ? 'All'
                                                      : el?.department?.name;
                                                  }
                                                )
                                              )
                                            : '-'}
                                        </span>
                                      ),
                                    }
                                  : false
                              }
                              className="fs-14"
                            >
                              {item?.Departments?.items?.length
                                ? arrToCommaSeperated(
                                    item?.Departments?.items?.map((el) => {
                                      return el?.departmentID ===
                                        selectAllDepartment
                                        ? 'All'
                                        : el?.department?.name;
                                    })
                                  )
                                : '-'}
                            </Text>
                          </Typography.Title>
                        </Col>
                      </Row>
                    </div>
                  }
                >
                  <p
                    className="goal-details fs-16"
                    data-active-goal-description={item?.description}
                  >
                    {item?.description}
                  </p>
                </Panel>
              );
            })}
          </Collapse>
        ) : loading || smeLoading ? (
          <TableSkeleton />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}

        <Drawer
          className="related-item-drawer brawer-with-btn"
          title={
            <Row justify="space-between" align="middle">
              <Typography.Title level={3} className="mb-0 ">
                Filters
              </Typography.Title>
              <Button
                type="text"
                className="text-blue fs-14 p-0 goal-active-filter-clearall"
                onClick={() => {
                  setOwnerFilter([]);
                  setTimeRange('');
                  setSelectAll(false);
                }}
                size="middle"
              >
                <strong>Clear All</strong>
              </Button>
            </Row>
          }
          closeIcon={null}
          closable={false}
          height={screens.xs ? '83vh' : ''}
          placement={screens.xs ? 'bottom' : 'right'}
          onClose={onClose}
          open={open}
        >
          <div>
            <Row justify="space-between" align="middle">
              <Typography.Title
                level={5}
                className="mb-0 fw-400 fs-14 text-black"
              >
                Goals added by me
              </Typography.Title>
              <Switch
                checked={goalAddedByOwner}
                onChange={(e) => setGoalAddedByOwner(e)}
                size="small"
                className="goal-active-filter-addedbyme"
                id="goal-active-filter-addedbyme"
              />
            </Row>

            <Form className="mt-20">
              <div className="mb-20">
                <Row justify="space-between" align="middle">
                  <Typography.Title level={5} className="mb-0 text-primary">
                    Owner
                  </Typography.Title>
                  <Checkbox
                    className="select-all goal-active-filter-select-all"
                    checked={selectAll}
                    onChange={(e) => {
                      setOwnerFilter(
                        e.target.checked
                          ? filteredData.map((data) => data?.owner?.email)
                          : []
                      );
                      setSelectAll(e.target.checked);
                    }}
                  >
                    Select All
                  </Checkbox>
                </Row>
                <Divider className="mt-10 mb-10" />

                <Checkbox.Group
                  className="vertical-box"
                  onChange={(e) => setOwnerFilter(e)}
                  value={ownerFilter}
                >
                  {filteredData?.map((item, index) => {
                    return (
                      <Checkbox
                        key={index}
                        value={item?.owner?.email}
                        className="goal-active-filter-owner"
                        id={`goal-active-filter-owner-${item?.owner?.email}`}
                      >
                        {item?.owner?.firstName + ' ' + item?.owner?.lastName}
                      </Checkbox>
                    );
                  })}
                </Checkbox.Group>
              </div>

              <div className="mb-20">
                <Row justify="space-between" align="middle">
                  <Typography.Title level={5} className="mb-0 text-primary">
                    Created date
                  </Typography.Title>
                </Row>
                <Divider className="mt-10 mb-10" />

                <Radio.Group className="vertical-box">
                  <Radio
                    value="today"
                    checked={timeRange === 'today'}
                    onChange={() => setTimeRange('today')}
                    id="goal-active-filter-today"
                  >
                    Today
                  </Radio>
                  <Radio
                    value="last7days"
                    checked={timeRange === 'last7days'}
                    onChange={() => setTimeRange('last7days')}
                    id="goal-active-filter-last7days"
                  >
                    Last 7 days
                  </Radio>
                  <Radio
                    value="lastWeek"
                    checked={timeRange === 'lastWeek'}
                    onChange={() => setTimeRange('lastWeek')}
                    id="goal-active-filter-lastweek"
                  >
                    Last week
                  </Radio>
                  <Radio
                    value="thisMonth"
                    checked={timeRange === 'thisMonth'}
                    onChange={() => setTimeRange('thisMonth')}
                    id="goal-active-filter-thismonth"
                  >
                    This month
                  </Radio>
                  <Radio
                    value="lastMonth"
                    checked={timeRange === 'lastMonth'}
                    onChange={() => setTimeRange('lastMonth')}
                    id="goal-active-filter-lastmonth"
                  >
                    Last month
                  </Radio>
                  <Radio
                    value="customRange"
                    checked={timeRange === 'customRange'}
                    onChange={() => setTimeRange('customRange')}
                    id="goal-active-filter-customrange"
                  >
                    Custom range
                  </Radio>
                  {timeRange === 'customRange' && (
                    <RangePicker onChange={(e) => setCustomRange(e)} />
                  )}
                </Radio.Group>
              </div>
            </Form>
          </div>

          <div className="drawer-btn">
            <Button
              className="drawer-btn-child goal-active-filter-cancel-btn"
              onClick={onClose}
              size="middle"
            >
              Cancel
            </Button>
            <Button
              type="primary"
              className="drawer-btn-child goal-active-filter-apply-btn"
              htmlType="submit"
              onClick={onFilterApply}
              size="middle"
            >
              Apply
            </Button>
          </div>
        </Drawer>
      </div>

      <DeleteGoalModal
        setDeleteModal={setDeleteModal}
        deleteModal={deleteModal}
        goalID={goalID}
        listGoals={listGoals}
        orgId={orgId}
      />
      <ArchivedGoalModal
        archiveModalOpen={archiveModalOpen}
        setArchiveModalOpen={setArchiveModalOpen}
        archiveObjectives={archiveObjectives}
        loader={loader}
      />
    </>
  );
}

