import React from 'react';
import { Switch, BrowserRouter, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Login from '../authUser/Login/Login';
import ConfirmSignup from '../authUser/confirmSignup.js/ConfirmSignup';
import Signup from '../authUser/Signup/Signup';
import ForgotPassword from '../authUser/ForgotPassword/ForgotPassword';
import MainLayoutRoute from './MainLayoutRoute';
import Dashboard from '../Pages/Dashboard/Dashboard';
import Compare from '../Pages/Compare/Compare';
import Rate from '../Pages/Rate/Rate';
import Goal from '../Pages/Goal/Goal';
import AllPriorities from '../Pages/AllPriorities/AllPriorities';
import ResetPassword from '../authUser/ForgotPassword/ResetPassword';
import PublicLayoutRoute from './PublicLayoutRoute';
import SuperAdmin from '../Pages/SuperAdmin/SuperAdmin';
import UserDetails from '../Pages/SuperAdmin/UserDetails';
import AddIdea from '../Pages/AllPriorities/AddIdea';
import IdeaDetail from '../Pages/AllPriorities/IdeaDetail';
import ProjectDetail from '../Pages/AllPriorities/ProjectOpportunityDetail';
import OpportunityDetail from '../Pages/AllPriorities/OpportunityDetail';
import UserManagement from '../Pages/SuperAdmin/UserManagement';
import Integration from '../Pages/Integration/integration';
import Support from '../Pages/Support/Support';
import RateDetials from '../Pages/Rate/RateDetails';
import AddGoal from '../Pages/Goal/AddGoal';
import PriorityBatches from '../Pages/PriorityBatches/PriorityBatches';
import AddPriorityBatch from '../Pages/PriorityBatches/AddPriorityBatch';
import PriorityBatchDetail from '../Pages/PriorityBatches/PriorityBatchDetail';
import ComparePriorityBatch from '../Pages/PriorityBatches/ComparePriorityBatch';
import ScenarioPlanning from '../Pages/ScenarioPlanning/ScenarioPlanning';
import ScenarioPlanningDetail from '../Pages/ScenarioPlanning/ScenarioPlanningDetail';
import PendingActions from '../Pages/Dashboard/PendingActions';
import Settings from '../Pages/Settings/Settings';
import EditActiveUser from '../Pages/SuperAdmin/EditActiveUser';
import AddUser from '../Pages/SuperAdmin/AddUser';
import EditInvitedUser from '../Pages/SuperAdmin/EditInvitedUser';
import Import from '../Pages/Import/Import';
import EditIdea from 'Pages/AllPriorities/EditIdea';
import Profile from 'Pages/Profile/Profile';
import EditProfile from 'Pages/Profile/EditProfile';
import AdminGoal from 'Pages/Goal/AdminGoal';
import { useSelector } from 'react-redux';
import {
  isAdmin,
  isLeader,
  isSME,
  isSuperAdmin,
} from 'store/slices/loginSlice';
import DepartmentDetails from 'Pages/Settings/Pages/DepartmentDetails';
import StageList from 'Pages/Stages/Pages/StageList';
import Stage1DetailPage from 'Pages/Stages/Pages/Stage1DetailPage';
import StageDetail from 'Pages/Stages/Pages/StageDetail';
import Stage1Preview from 'Pages/Stages/Pages/Stage1Preview';
import StagePreview from 'Pages/Stages/Pages/StagePreview';
import IntegrationDetail from 'Pages/Integration/Components/IntegrationDetail';
import MeasurementfieldList from 'Pages/MeasurementField/MeasurementfieldList';
import MeasurementScale from 'Pages/MeasurementField/Components/MeasurementScale';
import AddOrganization from 'Pages/SuperAdmin/components/AddOrganization';
import OrganizationDetails from 'Pages/SuperAdmin/components/OrganizationDetails';
import Partner from 'Pages/SuperAdmin/Partner';
export default function Routes() {
  const isSuperAdminGroup = useSelector(isSuperAdmin);
  const isAdminGroup = useSelector(isAdmin);
  const isLeaderGroup = useSelector(isLeader);
  const isSMEGroup = useSelector(isSME);
  return (
    <BrowserRouter basename="/" history={createBrowserHistory()}>
      <Switch>
        <Route
          exact
          path="/login"
          render={(matchProps) => (
            <PublicLayoutRoute
              render={(matchprops) => <Login {...matchprops} />}
            />
          )}
        />
        <Route
          exact
          path="/signup"
          render={(matchProps) => (
            <PublicLayoutRoute
              render={(matchprops) => <Signup {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/forgot-password"
          render={(matchProps) => (
            <PublicLayoutRoute
              render={(matchprops) => <ForgotPassword {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path={'/confirm_sign_up'}
          render={(matchProps) => (
            <PublicLayoutRoute
              render={(matchprops) => <ConfirmSignup {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/confirm_sign_up?confirmationcode=:code"
          render={(matchProps) => (
            <PublicLayoutRoute
              render={(matchprops) => <ConfirmSignup {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/"
          render={(matchProps) => (
            <MainLayoutRoute
              render={(matchprops) => <Dashboard {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/pending-actions"
          render={(matchProps) => (
            <MainLayoutRoute
              render={(matchprops) => <PendingActions {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/compare"
          render={(matchProps) => (
            <MainLayoutRoute
              render={(matchprops) => <Compare {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/rate"
          render={(matchProps) => (
            <MainLayoutRoute
              render={(matchprops) => <Rate {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/rate-detail"
          render={(matchProps) => (
            <MainLayoutRoute
              render={(matchprops) => <RateDetials {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/goal"
          render={(matchProps) => (
            <MainLayoutRoute
              render={(matchprops) => <AdminGoal {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/add-goal"
          render={(matchProps) => (
            <MainLayoutRoute
              render={(matchprops) => <AddGoal {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/add-goal/:id"
          render={(matchProps) => (
            <MainLayoutRoute
              render={(matchprops) => <AddGoal {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/rankings"
          render={(matchProps) => (
            <MainLayoutRoute
              render={(matchprops) => <AllPriorities {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/add-idea"
          render={(matchProps) => (
            <MainLayoutRoute
              render={(matchprops) => <AddIdea {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/edit-idea"
          render={(matchProps) => (
            <MainLayoutRoute
              render={(matchprops) => <EditIdea {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/edit-idea/:id"
          render={(matchProps) => (
            <MainLayoutRoute
              render={(matchprops) => <EditIdea {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/idea-detail"
          render={(matchProps) => (
            <MainLayoutRoute
              render={(matchprops) => <IdeaDetail {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/idea-detail/:id"
          render={(matchProps) => (
            <MainLayoutRoute
              render={(matchprops) => <IdeaDetail {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/project-detail"
          render={(matchProps) => (
            <MainLayoutRoute
              render={(matchprops) => <ProjectDetail {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/ranking-detail"
          render={(matchProps) => (
            <MainLayoutRoute
              render={(matchprops) => <OpportunityDetail {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/ranking-detail/:id"
          render={(matchProps) => (
            <MainLayoutRoute
              render={(matchprops) => <OpportunityDetail {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/ranking-detail/edit/:id"
          render={(matchProps) => (
            <MainLayoutRoute
              render={(matchprops) => <OpportunityDetail {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/organizations"
          render={(matchProps) => (
            <MainLayoutRoute
              render={(matchprops) => <SuperAdmin {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/user-details"
          render={(matchProps) => (
            <MainLayoutRoute
              render={(matchprops) => <UserDetails {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/user-management"
          render={(matchProps) => (
            <MainLayoutRoute
              render={(matchprops) => <UserManagement {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/edit-active-user"
          render={(matchProps) => (
            <MainLayoutRoute
              render={(matchprops) => <EditActiveUser {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/edit-active-user/:id"
          render={(matchProps) => (
            <MainLayoutRoute
              render={(matchprops) => <EditActiveUser {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/edit-invited-user"
          render={(matchProps) => (
            <MainLayoutRoute
              render={(matchprops) => <EditInvitedUser {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/edit-invited-user/:id"
          render={(matchProps) => (
            <MainLayoutRoute
              render={(matchprops) => <EditInvitedUser {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/add-user"
          render={(matchProps) => (
            <MainLayoutRoute
              render={(matchprops) => <AddUser {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/add-user/:id"
          render={(matchProps) => (
            <MainLayoutRoute
              render={(matchprops) => <AddUser {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/integration"
          render={(matchProps) => (
            <MainLayoutRoute
              render={(matchprops) => <Integration {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/integration/integration-detail"
          render={(matchProps) => (
            <MainLayoutRoute
              render={(matchprops) => <IntegrationDetail {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/support"
          render={(matchProps) => (
            <MainLayoutRoute
              render={(matchprops) => <Support {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/scenarios"
          render={(matchProps) => (
            <MainLayoutRoute
              render={(matchprops) => <PriorityBatches {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/add-scenario"
          render={(matchProps) => (
            <MainLayoutRoute
              render={(matchprops) => <AddPriorityBatch {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/add-scenario/:id"
          render={(matchProps) => (
            <MainLayoutRoute
              render={(matchprops) => <AddPriorityBatch {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/compare-scenarios"
          render={(matchProps) => (
            <MainLayoutRoute
              render={(matchprops) => <ComparePriorityBatch {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/import"
          render={(matchProps) => (
            <MainLayoutRoute
              render={(matchprops) => <Import {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/scenario-detail"
          render={(matchProps) => (
            <MainLayoutRoute
              render={(matchprops) => <PriorityBatchDetail {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/scenario-detail/:id"
          render={(matchProps) => (
            <MainLayoutRoute
              render={(matchprops) => <PriorityBatchDetail {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/scenario-planning"
          render={(matchProps) => (
            <MainLayoutRoute
              render={(matchprops) => <ScenarioPlanning {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/scenario-planning-detail"
          render={(matchProps) => (
            <MainLayoutRoute
              render={(matchprops) => (
                <ScenarioPlanningDetail {...matchprops} />
              )}
            />
          )}
        ></Route>
        <Route
          exact
          path="/settings"
          render={(matchProps) => (
            <MainLayoutRoute
              render={(matchprops) => <Settings {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/profile"
          render={(matchProps) => (
            <MainLayoutRoute
              render={(matchprops) => <Profile {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/edit-profile"
          render={(matchProps) => (
            <MainLayoutRoute
              render={(matchprops) => <EditProfile {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/admin-goal"
          render={(matchProps) =>
            isSuperAdminGroup ||
            isAdminGroup ||
            (isLeaderGroup && (
              <MainLayoutRoute
                render={(matchprops) => <AdminGoal {...matchprops} />}
              />
            ))
          }
        ></Route>
        <Route
          exact
          path="/reset-password?confirmationcode=:code+email=:email"
          render={(matchProps) => (
            <PublicLayoutRoute
              render={(matchprops) => <ResetPassword {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/reset-password"
          render={(matchProps) => (
            <PublicLayoutRoute
              render={(matchprops) => <ResetPassword {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/department-details/:id"
          render={(matchProps) => (
            <MainLayoutRoute
              render={(matchprops) => <DepartmentDetails {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/stages"
          render={(matchProps) => (
            <MainLayoutRoute
              render={(matchprops) => <StageList {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/Stage1"
          render={(matchProps) => (
            <MainLayoutRoute
              render={(matchprops) => <Stage1DetailPage {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/Stage1/:id"
          render={(matchProps) => (
            <MainLayoutRoute
              render={(matchprops) => <Stage1DetailPage {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/stageDetail/:id"
          render={(matchProps) => (
            <MainLayoutRoute
              render={(matchprops) => <StageDetail {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/stage1-draft-form/:id"
          render={(matchProps) => (
            <MainLayoutRoute
              render={(matchprops) => <Stage1DetailPage {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/draft-form/:id"
          render={(matchProps) => (
            <MainLayoutRoute
              render={(matchprops) => <StageDetail {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/stage1preview"
          render={(matchProps) => (
            <MainLayoutRoute
              render={(matchprops) => <Stage1Preview {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/stagepreview"
          render={(matchProps) => (
            <MainLayoutRoute
              className="blur-menu"
              render={(matchprops) => <StagePreview {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/measurementfield-list"
          render={(matchProps) => (
            <MainLayoutRoute
              render={(matchprops) => <MeasurementfieldList {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/measurementfield/scale"
          render={(matchProps) => (
            <MainLayoutRoute
              render={(matchprops) => <MeasurementScale {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/measurementfield/scale/:id"
          render={(matchProps) => (
            <MainLayoutRoute
              render={(matchprops) => <MeasurementScale {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/measurementfield/numeric"
          render={(matchProps) => (
            <MainLayoutRoute
              render={(matchprops) => <MeasurementScale {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/measurementfield/numeric/:id"
          render={(matchProps) => (
            <MainLayoutRoute
              render={(matchprops) => <MeasurementScale {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/organization/add-organization"
          render={(matchProps) => (
            <MainLayoutRoute
              render={(matchprops) => <AddOrganization {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/partner/add-partner"
          render={(matchProps) => (
            <MainLayoutRoute
              render={(matchprops) => <AddOrganization {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/organization/edit-organization/:id"
          render={(matchProps) => (
            <MainLayoutRoute
              render={(matchprops) => <AddOrganization {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/partner/edit-partner/:id"
          render={(matchProps) => (
            <MainLayoutRoute
              render={(matchprops) => <AddOrganization {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/organization/detail/:id"
          render={(matchProps) => (
            <MainLayoutRoute
              render={(matchprops) => <OrganizationDetails {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/partner/detail/:id"
          render={(matchProps) => (
            <MainLayoutRoute
              render={(matchprops) => <OrganizationDetails {...matchprops} />}
            />
          )}
        ></Route>
        <Route
          exact
          path="/partner"
          render={(matchProps) => (
            <MainLayoutRoute
              render={(matchprops) => <Partner {...matchprops} />}
            />
          )}
        ></Route>
      </Switch>
    </BrowserRouter>
  );
}

