import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import Joyride from 'react-joyride';
import { useSelector, useDispatch } from 'react-redux';
import { getRateTour, setRateTour } from 'store/slices/loginSlice';

export default function Onboarding({
  dashboardData,
  joyrideStepIndex,
  setRunTour,
  runTour,
  startFirstTimeTour,
}) {
  const startRateTour = useSelector(getRateTour);
  const dispatch = useDispatch();

  useEffect(() => {
    if (startFirstTimeTour && !localStorage.getItem('firstTimeinRate')) {
      dispatch(setRateTour(true));
    }
    // eslint-disable-next-line
  }, [startFirstTimeTour]);

  useEffect(() => {
    if (dashboardData.length > 0) {
      setRunTour(startRateTour);
    }
    // eslint-disable-next-line
  }, [dashboardData, startRateTour]);

  const handleJoyrideCallback = (data) => {
    if (data.type === 'step:after' && data.action === 'close') {
      // The user closed the tooltip, stop the tour
      if (startFirstTimeTour) {
        localStorage.setItem('firstTimeinRate', true);
      }
      setRunTour(false);
      dispatch(setRateTour(false));
    }
  };

  const steps = [
    {
      target: '.ant-table-tbody tr:nth-child(even)',
      spotlightClicks: true,
      content: (
        <div className="joyride-style">
          <div className="title-content">Rate the Impact You Think</div>
          <div
            className="details-content"
            style={{ fontWeight: '400px !important' }}
          >
            Click “Rate” on the Opportunity or Project and rate the impact you
            think it will make on each goal.
          </div>
        </div>
      ),
      disableBeacon: true,
      placement: 'right',
    },
  ];

  const customLocale = {
    back: (
      <span
        style={{
          position: 'absolute',
          left: '20px',
          color: 'black',
          bottom: '13px',
        }}
      >
        <ArrowLeftOutlined />
      </span>
    ),
    next: (
      <span
        style={{
          backgroundColor: 'transparent !important',
          color: 'black',
        }}
      >
        <ArrowRightOutlined
          style={{
            position: 'absolute',
            right: '20px',
            bottom: '13px',
          }}
        />
      </span>
    ),
    skipWithCount: (current, total) => (
      <span>
        {current} of {total}
      </span>
    ),
  };
  return (
    <Joyride
      stepIndex={joyrideStepIndex}
      steps={steps}
      run={runTour}
      continuous
      showProgress
      disableCloseOnEsc
      disableOverlayClose
      locale={customLocale}
      disableScrolling
      callback={handleJoyrideCallback}
      styles={{
        buttonNext: {
          display: 'none',
        },
      }}
    />
  );
}

