export const ECHOIMAGES = {
  Auth: {
    Logo: require('../Assets/logo.svg').default,
    LogoWhite: require('../Assets/white-logo.svg').default,
    StrAlignLogo: require('../Assets/StrAlign-logo.svg').default,
    AuthLogo: require('../Assets/Auth-Logo.svg').default,
    LoginImg: require('../Assets/authPages/login.svg').default,
    signupSlide1: require('../Assets/authPages/signupSlide1.svg').default,
    signupSlide2: require('../Assets/authPages/signupSlide2.svg').default,
    signupSlide3: require('../Assets/authPages/signupSlide3.svg').default,
    forgotPassword: require('../Assets/authPages/forgotPassword.svg').default,
    Background: require('../Assets/authPages/lightBlueBG.svg').default,
    PasswordChanged: require('../Assets/authPages/passwordChanged.svg').default,
  },
  HomeIcons: {
    pendingAction: require('../Assets/home/pendingAction.svg').default,
    pendingComparison: require('../Assets/home/pendingComparison.svg').default,
    pendingRating: require('../Assets/home/pendingRating.svg').default,
    downArrow: require('../Assets/home/downArrow.svg').default,
    upArrow: require('../Assets/home/upArrow.svg').default,
    minusArrow: require('../Assets/home/minusArrow.svg').default,
    tourIcon: require('../Assets/home/TourIcon.svg').default,
    questionCircle: require('../Assets/QuestionCircle.svg').default,
  },
  sidebarIcons: {
    Home: require('../Assets/sidebarIcons/home.svg').default,
    Compare: require('../Assets/sidebarIcons/compareIcon.svg').default,
    Rate: require('../Assets/sidebarIcons/rate.svg').default,
    AllPriorities: require('../Assets/sidebarIcons/ranking.svg').default,
    PriorityBatches: require('../Assets/sidebarIcons/priorityBatches.svg')
      .default,
    Goal: require('../Assets/sidebarIcons/goal.svg').default,
    scenarioPlanning: require('../Assets/sidebarIcons/scenarioPlanning.svg')
      .default,
    GoalBlue: require('../Assets/sidebarIcons/goal-blue-icon.svg').default,
    ImportIcon: require('../Assets/sidebarIcons/importIcon.svg').default,
    AdminIcon: require('../Assets/sidebarIcons/admin.svg').default,
    HelpIcon: require('../Assets/sidebarIcons/help.svg').default,
    IntegrationIcon: require('../Assets/sidebarIcons/integration.svg').default,
  },
  ideaIcons: {
    SortIcon: require('../Assets/Idea/sort-icon.svg').default,
    FilterIcon: require('../Assets/Idea/filter-icon.svg').default,
    EmptyData: require('../Assets/Idea/emptyData.svg').default,
    addIdeaModal: require('../Assets/Idea/addidea-modal.svg').default,
    addIdeaIcon: require('../Assets/Idea/addIdeaIcon.svg').default,
    addIdeaIconWhite: require('../Assets/Idea/addIdeaIcon-white.svg').default,
    PreviousIcon: require('../Assets/Idea/previous-icon.svg').default,
    ballonIcon: require('../Assets/Idea/ballon.png').default,
    BallonIcon: require('../Assets/Idea/ballon-icon.svg').default,
    GreenIcon: require('../Assets/Idea/green-icon.svg').default,
    YellowIcon: require('../Assets/Idea/yellow-icon.svg').default,
    RocketIcon: require('../Assets/Idea/rocketIcon.svg').default,
    emptyRankingIcon: require('../Assets/Idea/emptyRankingIcon.svg').default,
  },
  superAdminIcons: {
    DeleteIcon: require('../Assets/SuperAdmin/deleteUserIcon.svg').default,
    colorPickerIcon: require('../Assets/SuperAdmin/colorPicker.svg').default,
    partnerIcon: require('../Assets/SuperAdmin/partner.svg').default,
  },
  compareIcons: {
    Compare: require('../Assets/compare/compareFinishIcon.svg').default,
    KudosIcon: require('../Assets/compare/kudosImage.svg').default,
    MileStoneIcon: require('../Assets/compare/mileStoneImage.svg').default,
  },
  ImportIcons: {
    UploadSuccess: require('../Assets/Import/uploadIcon.svg').default,
  },
  PriorityBatches: {
    createPriority: require('../Assets/priorityBatches/createPriority.svg')
      .default,
    comparePriority: require('../Assets/priorityBatches/comparePriority.svg')
      .default,
    deletePriorityBatch:
      require('../Assets/priorityBatches/deletePriorityBatch.svg').default,
    PriorityBatchOrange: require('../Assets/priorityBatches/priorityBatch.svg')
      .default,
    infoCircleIcon: require('../Assets/priorityBatches/InfoCircle.svg').default,
    warningIcon: require('../Assets/priorityBatches/warning.svg').default,
    minusButton: require('../Assets/priorityBatches/minusButton.svg').default,
    refreshIcon: require('../Assets/priorityBatches/refreshIcon.svg').default,
    noScenarioIcon: require('../Assets/priorityBatches/noScenarioIcon.svg')
      .default,
    fullScreenIcon: require('../Assets/priorityBatches/fullScreen.svg').default,
    exitScreenIcon: require('../Assets/priorityBatches/exitScreen.svg').default,
  },
  notificationIcons: {
    IdeaNotificationIcon: require('../Assets/notification/ideaNotification.svg')
      .default,
    OpportunityNotificationIcon:
      require('../Assets/notification/opportunityNotification.svg').default,
    notificationIcon: require('../Assets/notification/notificationIcon.svg')
      .default,
  },
  icons: {
    DisableIcon: require('../Assets/Icons/disableIcon.svg').default,
    AddUser: require('../Assets/Icons/addUser.svg').default,
    AddUserWhite: require('../Assets/Icons/addUserWhite.svg').default,
    MicrosoftIcon: require('../Assets/Icons/Microsoft.svg').default,
    BackArrow: require('../Assets/Icons/backArrow.svg').default,
    NextArrow: require('../Assets/Icons/nextArrow.svg').default,
    ArchivedIcon: require('../Assets/Icons/archive.svg').default,
    helpIcon: require('../Assets/Icons/help-icon.svg').default,
  },
  admin: {
    remindLogo: require('../Assets/admin/remindLogo.svg').default,
    visionLogo: require('../Assets/admin/Vision.svg').default,
    stageIcon: require('../Assets/admin/Stage.svg').default,
    deleteIcon: require('../Assets/admin/DeleteIcon.svg').default,
    eyeIcon: require('../Assets/admin/EyeIcon.svg').default,
    disabledDeleteIcon: require('../Assets/admin/DisabledDelteIcon.svg')
      .default,
    deleteModalIcon: require('../Assets/admin/DeleteModalIcon.svg').default,
    multiLineIcon: require('../Assets/admin/MultiLineIcon.svg').default,
    singleLineIcon: require('../Assets/admin/SingleLine.svg').default,
    dropDownIcon: require('../Assets/admin/DropDownIcon.svg').default,
    linkIcon: require('../Assets/admin/LinkIcon.svg').default,
    calenderIcon: require('../Assets/admin/CalenderIcon.svg').default,
    publishIcon: require('../Assets/admin/PublishIcon.svg').default,
    confirmationIcon: require('../Assets/admin/ConfirmationIcon.svg').default,
    infoIcon: require('../Assets/admin/InfoIcon.svg').default,
    questioncircle: require('../Assets/admin/question-circle.svg').default,
    editIcon: require('../Assets/admin/EditIcon.svg').default,
    integrationIcon: require('../Assets/admin/integrationIcon.svg').default,
    zapierIcon: require('../Assets/admin/zappierIcon.svg').default,
    blurImageIcon: require('../Assets/admin/blurImage.png').default,
    filterPlaceholder: require('../Assets/admin/Scenario-place-holder.svg')
      .default,
    measurementFieldIcon: require('../Assets/admin/measurement.svg').default,
    mondayIcon: require('../Assets/admin/MondayIcon.svg').default,
    smartsheetIcon: require('../Assets/admin/SmartsheetIcon.svg').default,
    microsoftIcon: require('../Assets/admin/MicrosoftIcon.svg').default,
    threeColorsIcon: require('../Assets/admin/3Colors.svg').default,
    fourColorsIcon: require('../Assets/admin/4Colors.svg').default,
    fiveColorsIcon: require('../Assets/admin/5Colors.svg').default,
    symbolIcon: require('../Assets/admin/symbolIcon.svg').default,
    shapeIcon: require('../Assets/admin/ShapeIcon.svg').default,
    usersIcon: require('../Assets/admin/UsersIcon.svg').default,
    analytics: require('../Assets/admin/Analytics.svg').default,
    pieChartIcon: require('../Assets/admin/PieChartIcon.svg').default,
    cloudIcon: require('../Assets/admin/CloudIcon.svg').default,
    circleSquareIcon: require('../Assets/admin/CircleSquareIcon.svg').default,
    expressionIcon: require('../Assets/admin/ExpressionIcon.svg').default,
    playPauseIcon: require('../Assets/admin/PlayPauseIcon.svg').default,
    arrowMinusIcon: require('../Assets/admin/ArrowMinusIcon.svg').default,
    rightCrossIcon: require('../Assets/admin/RightCrossIcon.svg').default,
    ratingsIcon: require('../Assets/admin/RatingsIcon.svg').default,
    progressBarIcon: require('../Assets/admin/ProgressbarIcon.svg').default,
    upDownIcon: require('../Assets/admin/UpDownIcon.svg').default,
    fourArrowIcon: require('../Assets/admin/FourArrow.svg').default,
    fiveArrowIcon: require('../Assets/admin/FiveArrow.svg').default,
    humanIcon: require('../Assets/admin/HumanIcon.svg').default,
    dollarIcon: require('../Assets/admin/DollarIcon.svg').default,
    heartIcon: require('../Assets/admin/HeartIcon.svg').default,
    threeArrowIcon: require('../Assets/admin/ThreeArrowIcon.svg').default,
    fourBlackArrowIcon: require('../Assets/admin/FourBlackArrowIcon.svg')
      .default,
  },
  integration: {
    step1Icon: require('../Assets/Integration/step1.svg').default,
    step2Icon: require('../Assets/Integration/image.jpg').default,
    step3Icon: require('../Assets/Integration/image.jpg').default,
    step4Icon: require('../Assets/Integration/step4.svg').default,
    step5Icon: require('../Assets/Integration/step5.svg').default,
    step6Icon: require('../Assets/Integration/step6.svg').default,
    step7Icon: require('../Assets/Integration/step7.svg').default,
  },
  MeasurementIcon: {
    dollarIcon: require('../Assets/Measurement/Dollar.svg').default,
    scaleIcon: require('../Assets/Measurement/Scale.svg').default,
    hashIcon: require('../Assets/Measurement/Hash.svg').default,
  },
  smeProfile: {
    smeProfile: require('../Assets/SMEProfile/smeProfile.svg').default,
  },
  rankings: {
    red: require('../Assets/Ranking/red.svg').default,
    yellow: require('../Assets/Ranking/yellow.svg').default,
    green: require('../Assets/Ranking/green.svg').default,
    blue: require('../Assets/Ranking/blue.svg').default,
    gray: require('../Assets/Ranking/gray.svg').default,
    greenSymbol: require('../Assets/Ranking/threeGroup-Green.svg').default,
    triangle: require('../Assets/Ranking/threeGroup-Triangle.svg').default,
    stopIcon: require('../Assets/Ranking/threeGroup-Stop.svg').default,
    rightIcon: require('../Assets/Ranking/threeGroup-Right.svg').default,
    exclamationMark:
      require('../Assets/Ranking/threeGroup-Exclamation mark.svg').default,
    crossIcon: require('../Assets/Ranking/threeGroup-Cross.svg').default,
    redExclamationMark:
      require('../Assets/Ranking/threeGroup-ExclamationMark.svg').default,
    minusIcon: require('../Assets/Ranking/threeGroup-Minus.svg').default,
    downArrowIcon: require('../Assets/Ranking/threeGroup-DownArrow.svg')
      .default,
    circle1: require('../Assets/Ranking/fiveGroup-circle1.svg').default,
    circle2: require('../Assets/Ranking/fiveGroup-circle2.svg').default,
    circle3: require('../Assets/Ranking/fiveGroup-circle3.svg').default,
    circle4: require('../Assets/Ranking/fiveGroup-circle4.svg').default,
    circle5: require('../Assets/Ranking/fiveGroup-circle5.svg').default,
    analytics1: require('../Assets/Ranking/fiveGroup-Analytics1.svg').default,
    analytics2: require('../Assets/Ranking/fiveGroup-Analytics2.svg').default,
    analytics3: require('../Assets/Ranking/fiveGroup-Analytics3.svg').default,
    analytics4: require('../Assets/Ranking/fiveGroup-Analytics4.svg').default,
    analytics5: require('../Assets/Ranking/fiveGroup-Analytics5.svg').default,
    cloudy: require('../Assets/Ranking/fiveGroup-Cloudy.svg').default,
    sunny: require('../Assets/Ranking/fiveGroup-Sunny.svg').default,
    partlySunny: require('../Assets/Ranking/fiveGroup-PartlySunny.svg').default,
    rainy: require('../Assets/Ranking/fiveGroup-Rainy.svg').default,
    stromy: require('../Assets/Ranking/fiveGroup-Stromy.svg').default,
    advanced: require('../Assets/Ranking/circleSquare/Advanced.svg').default,
    easy: require('../Assets/Ranking/circleSquare/Easy.svg').default,
    intermediate: require('../Assets/Ranking/circleSquare/Intermediate.svg')
      .default,
    exportsOnly: require('../Assets/Ranking/circleSquare/Exports Only.svg')
      .default,
    noPain: require('../Assets/Ranking/expressionIcon/No Pain.svg').default,
    mild: require('../Assets/Ranking/expressionIcon/Mild.svg').default,
    moderate: require('../Assets/Ranking/expressionIcon/Moderate.svg').default,
    severe: require('../Assets/Ranking/expressionIcon/Severe.svg').default,
    verySevere: require('../Assets/Ranking/expressionIcon/Very Severe.svg')
      .default,
    extreme: require('../Assets/Ranking/expressionIcon/Extreme.svg').default,
    empty: require('../Assets/Ranking/ratings/Empty.svg').default,
    one: require('../Assets/Ranking/ratings/One.svg').default,
    two: require('../Assets/Ranking/ratings/Two.svg').default,
    three: require('../Assets/Ranking/ratings/Three.svg').default,
    four: require('../Assets/Ranking/ratings/Four.svg').default,
    five: require('../Assets/Ranking/ratings/Five.svg').default,
    emptyProgress: require('../Assets/Ranking/progressBar/Empty.svg').default,
    quarter: require('../Assets/Ranking/progressBar/Quarter.svg').default,
    half: require('../Assets/Ranking/progressBar/Half.svg').default,
    threeQuarter: require('../Assets/Ranking/progressBar/Three Quarter.svg')
      .default,
    full: require('../Assets/Ranking/progressBar/Full.svg').default,
    up: require('../Assets/Ranking/upDown/Up.svg').default,
    down: require('../Assets/Ranking/upDown/Down.svg').default,
    unChanged: require('../Assets/Ranking/upDown/Unchanged.svg').default,
    emptyHuman: require('../Assets/Ranking/human/Empty.svg').default,
    oneHuman: require('../Assets/Ranking/human/One.svg').default,
    TwoHuman: require('../Assets/Ranking/human/Two.svg').default,
    threeHuman: require('../Assets/Ranking/human/Three.svg').default,
    fourHuman: require('../Assets/Ranking/human/Four.svg').default,
    fiveHuman: require('../Assets/Ranking/human/Five.svg').default,
    downFour: require('../Assets/Ranking/fourArrow/Down.svg').default,
    angleDown: require('../Assets/Ranking/fourArrow/Angle Down.svg').default,
    angleUp: require('../Assets/Ranking/fourArrow/Angle Up.svg').default,
    upFour: require('../Assets/Ranking/fourArrow/Up.svg').default,
    downFive: require('../Assets/Ranking/fiveArrow/Down.svg').default,
    angleDownFive: require('../Assets/Ranking/fiveArrow/Angle Down.svg')
      .default,
    angleUpFive: require('../Assets/Ranking/fiveArrow/Angle Up.svg').default,
    upFive: require('../Assets/Ranking/fiveArrow/Up.svg').default,
    sideWays: require('../Assets/Ranking/fiveArrow/Sideways.svg').default,
    emptyDollar: require('../Assets/Ranking/dollar/Empty.svg').default,
    oneDollar: require('../Assets/Ranking/dollar/One.svg').default,
    twoDollar: require('../Assets/Ranking/dollar/Two.svg').default,
    threeDollar: require('../Assets/Ranking/dollar/Three.svg').default,
    fourDollar: require('../Assets/Ranking/dollar/Four.svg').default,
    fiveDollar: require('../Assets/Ranking/dollar/Five.svg').default,
    emptyHeart: require('../Assets/Ranking/heart/Empty.svg').default,
    oneHeart: require('../Assets/Ranking/heart/One.svg').default,
    twoHeart: require('../Assets/Ranking/heart/Two.svg').default,
    threeHeart: require('../Assets/Ranking/heart/Three.svg').default,
    fourHeart: require('../Assets/Ranking/heart/Four.svg').default,
    fiveHeart: require('../Assets/Ranking/heart/Five.svg').default,
    downThree: require('../Assets/Ranking/threeArrow/Down.svg').default,
    sideWayThree: require('../Assets/Ranking/threeArrow/Sideways.svg').default,
    upThree: require('../Assets/Ranking/threeArrow/Up.svg').default,
    downBlack: require('../Assets/Ranking/fourBlack/Down.svg').default,
    rightBlack: require('../Assets/Ranking/fourBlack/Right.svg').default,
    upBlack: require('../Assets/Ranking/fourBlack/Up.svg').default,
    leftBlack: require('../Assets/Ranking/fourBlack/Left.svg').default,
  },
};

