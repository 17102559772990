import { ECHOIMAGES } from 'Assets/Images';
import CustomDeleteModal from 'Common/CustomDeleteModal';
import React from 'react';

function ActiveGoalModal({
  activeObjectives,
  activeModalOpen,
  setActiveModalOpen,
  loader,
}) {
  const { ArchivedIcon } = ECHOIMAGES.icons;

  const handleCancelModal = () => {
    setActiveModalOpen(false);
  };

  return (
    <CustomDeleteModal
      open={activeModalOpen}
      onCancel={handleCancelModal}
      onOk={activeObjectives}
      confirmLoading={loader}
      okText="Active Goal"
      title="Are you sure that you want to active this goal? "
    >
      <img src={ArchivedIcon} alt="" />
    </CustomDeleteModal>
  );
}

export default ActiveGoalModal;

