import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import moment from 'moment';
import {
  Avatar,
  Dropdown,
  Grid,
  Image,
  Menu,
  Table,
  Tooltip,
  Typography,
  Spin,
} from 'antd';
import {
  MoreOutlined,
  EditOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';

import {
  getCurrentOrganization,
  getCurrnetUserData,
  isSuperAdmin,
} from 'store/slices/loginSlice';
import { options, UserRole } from 'utils/Constants';
import {
  listUsersComparisonCount,
  listUsersRatingCount,
} from '../services/SuperAdminActions';
import {
  fetchOrganizationStages,
  getGammasCount,
  getLastLoginDate,
} from 'utils/CommonFunctions';
import TableSkeleton from 'Common/TableSkeleton';
import ResetPasswordModal from './ResetPasswordModal';
import { getComparisonList, getRatingList } from 'store/slices/StageSlice';
const ActiveUsersTable = ({
  setOrganizationModal,
  allActiveUsers,
  allUsers,
  setUserDataID,
  setSelectedOrg,
  setSelectedOrgID,
  setIsModalOpen,
  setSelectedRole,
  setIsRoleModalOpen,
  setUserID,
  setIsDepartmentModalOpen,
  setSelectedDepartment,
  setDeleteUserModal,
  loading,
  userID,
  handleScroll,
  loadData,
  sortTableData,
  sortOrder,
  sortColumn,
}) => {
  const { Text } = Typography;
  const history = useHistory();

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;

  const comparisonList = useSelector(getComparisonList);
  const ratingList = useSelector(getRatingList);
  const isSuperAdminGroup = useSelector(isSuperAdmin);

  const [submittedIdeas, setSubmittedIdeas] = useState([]);
  const [comparisonCount, setComparisonCount] = useState([]);
  const [ratingsCount, setRatingsCount] = useState([]);
  const [resetpassModalOpen, setResetPassModalOpen] = useState(false);

  const showResetPasswordModal = () => {
    setResetPassModalOpen(true);
  };

  const showOrganizationModal = () => {
    setOrganizationModal(true);
  };
  const showRoleModal = () => {
    setIsRoleModalOpen(true);
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const showDepartmentModal = () => {
    setIsDepartmentModalOpen(true);
  };

  const showDeleteUserModal = () => {
    setDeleteUserModal(true);
  };

  const fetchComparisonscount = async () => {
    try {
      let comparisonCount = await listUsersComparisonCount({
        organizationID: orgId,
      });
      setComparisonCount(comparisonCount?.buckets);
    } catch (error) {
      throw error;
    }
  };

  const fetchRatingscount = async () => {
    try {
      let ratingsCount = await listUsersRatingCount({
        organizationID: orgId,
      });
      setRatingsCount(ratingsCount?.buckets);
    } catch (error) {
      throw error;
    }
  };

  const [allStages, setAllStages] = useState([]);

  const getStageName = async () => {
    try {
      const sortedStages = await fetchOrganizationStages(orgId);
      setAllStages(sortedStages);
    } catch (err) {
      console.log(err);
    }
  };
  const fetchGammasCount = async () => {
    const aggregates = [
      {
        field: 'userID',
        type: 'terms',
        name: '65226aab-039a-45d4-b909-58f39393dc25',
      },
    ];
    const { submittedIdeaByOrg } = await getGammasCount(
      null,
      allStages,
      null,
      orgId,
      aggregates
    );
    setSubmittedIdeas(submittedIdeaByOrg);
  };

  useEffect(() => {
    fetchComparisonscount();
    fetchRatingscount();
    getStageName();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchGammasCount();
    // eslint-disable-next-line
  }, [allStages]);
  // table columns
  const columns = [
    {
      title: 'Name',
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: (a, b) => {
        return a?.firstName?.localeCompare(b?.firstName);
      },
      sortDirections: ['ascend', 'descend', 'ascend'],
      sortOrder:
        sortColumn === 'firstName'
          ? sortOrder === 'ASC'
            ? 'ascend'
            : 'descend'
          : null,
      render: (text, row) => {
        return {
          props: {
            className: row?.status === 'DISABLED' ? 'disabled-row' : '',
          },
          children: (
            <span className="table-font">
              {row?.firstName === null
                ? '-'
                : row?.firstName + ' ' + row?.lastName}
            </span>
          ),
        };
      },
    },
    {
      title: 'Organization',
      dataIndex: 'organization',
      key: 'organization',
      sortOrder:
        sortColumn === 'organization'
          ? sortOrder === 'ASC'
            ? 'ascend'
            : 'descend'
          : null,
      sorter: (a, b) => {
        return a - b;
      },
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, row) => {
        return {
          props: {
            className: row?.status === 'DISABLED' ? 'disabled-row' : '',
          },
          children: (
            <>
              <span className="mr-10 table-font">
                <Text
                  style={
                    true
                      ? {
                          width: 100,
                        }
                      : undefined
                  }
                  ellipsis={
                    true
                      ? {
                          tooltip: <span>{row?.Organization?.name}</span>,
                        }
                      : false
                  }
                  className="table-font"
                >
                  {row?.Organization?.name ? row?.Organization?.name : '-'}
                </Text>
              </span>
              {row?.Organization?.name && (
                <EditOutlined
                  className="editOutlined"
                  onClick={() => {
                    showOrganizationModal();
                    setUserDataID(row);
                    setSelectedOrg(row?.Organization?.name);
                    setSelectedOrgID(row?.Organization?.id);
                  }}
                />
              )}
            </>
          ),
        };
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => {
        return a?.email.localeCompare(b?.email);
      },
      sortOrder:
        sortColumn === 'email'
          ? sortOrder === 'ASC'
            ? 'ascend'
            : 'descend'
          : null,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, row) => {
        return {
          props: {
            className: row?.status === 'DISABLED' ? 'disabled-row' : '',
          },
          children: (
            <div>
              <Text
                style={
                  true
                    ? {
                        width: 120,
                      }
                    : undefined
                }
                ellipsis={
                  true
                    ? {
                        tooltip: <span>{row?.email}</span>,
                      }
                    : false
                }
                className="table-font"
              >
                {row?.email}
              </Text>
            </div>
          ),
        };
      },
    },

    {
      title: 'Role',
      dataIndex: 'role',
      sorter: (a, b) => {
        return a?.role?.localeCompare(b?.role);
      },
      sortDirections: ['ascend', 'descend', 'ascend'],
      sortOrder:
        sortColumn === 'role'
          ? sortOrder === 'ASC'
            ? 'ascend'
            : 'descend'
          : null,
      key: 'role',
      render: (text, row) => {
        const roleName = text === UserRole.sme ? UserRole?.teamMember : text;
        return {
          props: {
            className: row?.status === 'DISABLED' ? 'disabled-row' : '',
          },
          children: (
            <>
              <span className="mr-10 table-font">{roleName}</span>
              <EditOutlined
                onClick={() => {
                  showRoleModal();
                  setUserDataID(row);
                  setSelectedRole(row?.role);
                }}
                className="editOutlined"
              />
            </>
          ),
        };
      },
    },
    {
      title: 'Onboarding Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, row) => {
        return {
          props: {
            className: row?.status === 'DISABLED' ? 'disabled-row' : '',
          },
          children: <span className="table-font">{text}</span>,
        };
      },
    },
    {
      title: 'Created on',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      sortOrder:
        sortColumn === 'createdAt'
          ? sortOrder === 'ASC'
            ? 'ascend'
            : 'descend'
          : null,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, row) => {
        return {
          props: {
            className: row?.status === 'DISABLED' ? 'disabled-row' : '',
          },
          children: (
            <span className="table-font">{moment(text).format('l')}</span>
          ),
        };
      },
    },
    {
      title: 'Last Login',
      dataIndex: 'lastLogin',
      key: 'lastLogin',
      sortOrder:
        sortColumn === 'lastLogin'
          ? sortOrder === 'ASC'
            ? 'ascend'
            : 'descend'
          : null,
      render: (text, row) => {
        return {
          props: {
            className: row?.status === 'DISABLED' ? 'disabled-row' : '',
          },
          children: (
            <span className="table-font">
              <div>
                <Text
                  style={
                    true
                      ? {
                          width: 120,
                        }
                      : undefined
                  }
                  ellipsis={
                    true
                      ? {
                          tooltip: <span> {text}</span>,
                        }
                      : false
                  }
                  className="table-font"
                >
                  {text}
                </Text>
              </div>
            </span>
          ),
        };
      },
      sorter: (a, b) => moment(a.lastLogin).unix() - moment(b.lastLogin).unix(),
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 350,
      render: (text, row) => {
        return {
          props: {
            className: row?.status === 'DISABLED' ? 'disabled-row' : '',
          },
          children: <span className="table-font">{text}</span>,
        };
      },
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      fixed: 'right',
      width: 80,
      render: (text, row) => (
        <Dropdown
          overlay={
            <Menu
              items={[
                {
                  label: <span>Disable</span>,
                  key: '1',
                  onClick: () => {
                    showModal();
                    setUserID(row?.id);
                  },
                },
                {
                  label: <span>Delete User</span>,
                  key: '2',
                  onClick: () => {
                    showDeleteUserModal();
                    setUserID(row?.id);
                  },
                },
              ]}
            />
          }
          trigger={['click']}
        >
          <MoreOutlined style={{ color: '#5AACC9' }} className="dot-style" />
        </Dropdown>
      ),
    },
  ];
  //for admin
  // table columns
  const adminColumns = [
    {
      title: 'Name',
      dataIndex: 'firstName',
      key: 'firstName',
      width: 230,
      sortOrder:
        sortColumn === 'firstName'
          ? sortOrder === 'ASC'
            ? 'ascend'
            : 'descend'
          : null,
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a, b) => {
        return a?.firstName?.localeCompare(b?.firstName);
      },
      render: (text, row) => {
        return (
          <>
            {/* {imageUrl !== undefined ? (
              <Image
                className="mr-10 "
                style={{
                  width: '30px',
                  height: '30px',
                  lineHeight: '30px',
                  borderRadius: '100px',
                }}
                src={imageUrl}
              />
            ) : ( */}
            <Avatar
              size={35}
              alt=""
              className="mr-10 tag-font avtar-style"
              style={{ width: '30px', height: '30px', lineHeight: '30px' }}
            >
              {row?.firstName?.[0]?.toUpperCase()}
            </Avatar>
            {/* )} */}

            <span className="table-font">
              {row?.firstName === null
                ? '-'
                : row?.firstName.charAt(0).toUpperCase() +
                  row?.firstName.slice(1) +
                  ' ' +
                  row?.lastName.charAt(0).toUpperCase() +
                  row?.lastName.slice(1)}
            </span>
          </>
        );
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => {
        return a?.email.localeCompare(b?.email);
      },
      sortOrder:
        sortColumn === 'email'
          ? sortOrder === 'ASC'
            ? 'ascend'
            : 'descend'
          : null,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, row) => {
        return (
          <div>
            <Text
              style={
                true
                  ? {
                      width: 120,
                    }
                  : undefined
              }
              ellipsis={
                true
                  ? {
                      tooltip: <span>{row?.email}</span>,
                    }
                  : false
              }
              className="table-font"
            >
              {row?.email}
            </Text>
          </div>
        );
      },
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      sorter: (a, b) => {
        return a?.role.localeCompare(b?.role);
      },
      sortOrder:
        sortColumn === 'role'
          ? sortOrder === 'ASC'
            ? 'ascend'
            : 'descend'
          : null,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, row) => {
        const roleName = text === UserRole.sme ? UserRole?.teamMember : text;
        return (
          <>
            <span className="mr-10 table-font">{roleName}</span>
            <EditOutlined
              onClick={() => {
                showRoleModal();
                setUserDataID(row);
                setSelectedRole(row?.role);
              }}
              className="editOutlined"
            />
          </>
        );
      },
    },
    {
      title: 'Department',
      dataIndex: 'department',
      key: 'department',
      sorter: (a, b) => {
        return a?.Department?.name?.localeCompare(b?.Department?.name);
      },
      sortOrder:
        sortColumn === 'department'
          ? sortOrder === 'ASC'
            ? 'ascend'
            : 'descend'
          : null,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, row) => {
        return (
          <>
            {' '}
            <span className="mr-10 table-font">
              {row?.Department?.name ? row?.Department?.name : '-'}
            </span>
            {row?.Department?.name ? (
              <EditOutlined
                onClick={() => {
                  showDepartmentModal();
                  setUserDataID(row);
                  setSelectedDepartment(row?.Department?.name);
                }}
                className="editOutlined"
              />
            ) : null}
          </>
        );
      },
    },
    {
      title: (
        <span>
          Contributions{' '}
          <Tooltip
            title={
              <span>
                SI : Submitted Ideas <br />
                {comparisonList > 0 ? (
                  <span>
                    CC : Comparisons completed <br />
                  </span>
                ) : (
                  <></>
                )}
                {ratingList > 0 ? <span>RG : Ratings given</span> : <></>}
              </span>
            }
          >
            <InfoCircleOutlined />
          </Tooltip>
        </span>
      ),
      children: [
        {
          title: 'SI',
          dataIndex: 'SI',
          key: 'SI',
          align: comparisonList > 0 || ratingList > 0 ? 'left' : 'center',
          width: comparisonList > 0 || ratingList > 0 ? 75 : 150,
          render: (text, row) => {
            const count = submittedIdeas?.filter(
              (item) => item?.key === row?.id
            )?.[0]?.doc_count;
            return text ? text : '-';
          },
        },
        comparisonList > 0
          ? {
              title: 'CC',
              dataIndex: 'CC',
              key: 'CC',
              width: 75,
              render: (text, row) => {
                return <span>{text ? text : '-'}</span>;
                // const count =
                //   comparisonCount?.filter((item) => item?.key === row?.id)?.[0]
                //     ?.doc_count / 2;
                // if (count >= 1000) {
                //   const formattedNumber = (count / 1000).toFixed(1) + 'k';
                //   return formattedNumber;
                // }
                // return count ? Math.floor(count) : '-';
              },
            }
          : { width: 0 },
        ratingList > 0
          ? {
              title: 'RG',
              dataIndex: 'RG',
              key: 'RG',
              width: 75,
              render: (text, row) => {
                // const count = ratingsCount?.filter(
                //   (item) => item?.key === row?.id
                // )?.[0]?.doc_count;
                return text ? text : '-';
              },
            }
          : { width: 0 },
      ],
    },
    {
      title: 'Manager',
      dataIndex: 'manager',
      key: 'manager',
      render: (text, row) => (
        <span className="table-font">
          {row?.Manager
            ? row?.Manager?.firstName + ' ' + row?.Manager?.lastName
            : '-'}
        </span>
      ),
    },
    {
      title: 'User Weight',
      dataIndex: 'weight',
      sorter: (a, b) => {
        return a?.weight - b?.weight;
      },
      sortOrder:
        sortColumn === 'weight'
          ? sortOrder === 'ASC'
            ? 'ascend'
            : 'descend'
          : null,
      sortDirections: ['ascend', 'descend', 'ascend'],
      key: 'weight',
      width: 100,
      render: (text, row) =>
        text !== undefined || text !== null
          ? text
          : row?.role === UserRole.admin ||
            row?.role === UserRole.leader ||
            row?.role === UserRole.sme
          ? row?.Organization?.weightSettings.filter(
              (el) => el.role === row?.role
            )[0]?.weight
          : row?.role === UserRole.superAdmin
          ? row?.Organization?.weightSettings.filter(
              (el) => el.role === UserRole.leader
            )[0]?.weight
          : null,
    },
    {
      title: 'Created on',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sortOrder:
        sortColumn === 'createdAt'
          ? sortOrder === 'ASC'
            ? 'ascend'
            : 'descend'
          : null,

      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      render: (text, row) => (
        <span className="table-font">{moment(text).format('l')}</span>
      ),
    },
    {
      title: 'Last Login',
      dataIndex: 'lastLogin',
      key: 'lastLogin',
      sortOrder:
        sortColumn === 'lastLogin'
          ? sortOrder === 'ASC'
            ? 'ascend'
            : 'descend'
          : null,
      sorter: (a, b) => a.lastLogin - b.lastLogin,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, row) => {
        return (
          <span className="table-font">
            <div>
              <Text
                style={
                  true
                    ? {
                        width: 120,
                      }
                    : undefined
                }
                ellipsis={
                  true
                    ? {
                        tooltip: <span> {moment(text).format('l')}</span>,
                      }
                    : false
                }
                className="table-font"
              >
                {row.lastLogin ? moment(text).format('l') : '-'}
              </Text>
            </div>
          </span>
        );
      },
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      fixed: 'right',
      width: 80,
      render: (text, row) => (
        <Dropdown
          overlay={
            <Menu
              items={[
                {
                  label: <span>Edit</span>,
                  key: 0,
                  onClick: () => {
                    history.push(`/edit-active-user/${row.id}`);
                  },
                },
                {
                  label: <span>Disable</span>,
                  key: '1',
                  onClick: () => {
                    showModal();
                    setUserID(row?.id);
                  },
                },
                {
                  label: <span>Reset Password</span>,
                  key: '2',
                  onClick: () => {
                    showResetPasswordModal();
                    setUserID(row);
                  },
                },
              ]}
            />
          }
          trigger={['click']}
        >
          <MoreOutlined style={{ color: '#5AACC9' }} className="dot-style" />
        </Dropdown>
      ),
    },
  ];

  return (
    <>
      {loading ? (
        <>
          <TableSkeleton />
          <TableSkeleton />
        </>
      ) : (
        <div
          style={{ maxHeight: 'calc(100vh - 140px)', overflowY: 'auto' }}
          onScroll={handleScroll}
        >
          <Table
            onChange={(pagination, filter, sorter) => {
              sortTableData(sorter);
            }}
            pagination={false}
            columns={
              currnetOrg?.id
                ? adminColumns
                : isSuperAdminGroup
                ? columns
                : adminColumns
            }
            dataSource={
              currnetOrg?.id
                ? allActiveUsers
                : isSuperAdminGroup
                ? allUsers
                : allActiveUsers
            }
            className="admin-table mt-20"
            scroll={{
              x: 1700,
            }}
            sticky={{
              offsetHeader: 0,
            }}
            size={screens.xxl ? 'middle' : 'small'}
          />
        </div>
      )}
      {loadData && (
        <Spin style={{ display: 'flex', justifyContent: 'center' }} />
      )}
      <ResetPasswordModal
        userID={userID}
        resetpassModalOpen={resetpassModalOpen}
        setResetPassModalOpen={setResetPassModalOpen}
      />
    </>
  );
};

export default memo(ActiveUsersTable);

