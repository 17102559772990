import { Button, Col, message, Modal, Row, Typography, Upload } from 'antd';
import { UploadOutlined, LoadingOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { ECHOIMAGES } from '../../Assets/Images';
import './import.less';
import { GetFileFromS3, SaveFileToS3 } from 'utils/commonMethods';
import { RESTInvite } from 'utils/RESTApi';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  getCurrentOrganization,
  getCurrnetUserData,
} from 'store/slices/loginSlice';
import ImportPriority from './ImportPriority';
export default function Import() {
  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const userId = currnetOrg?.adminID || loginUsrOrg?.id;
  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;
  const { Dragger } = Upload;
  const { UploadSuccess } = ECHOIMAGES.ImportIcons;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState();
  const history = useHistory();
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleViewInvitedUser = () => {
    history.push({ pathname: '/user-management', state: { inviteTab: true } });
  };

  const downloadSampleFile = async () => {
    let downlloadFile;
    downlloadFile = await GetFileFromS3('Sample_User_Import.xlsx', {
      level: 'public',
    });
    window.open(downlloadFile);
  };
  const uploadProps = {
    name: 'file',
    multiple: false,
    async beforeUpload(file) {
      setLoading(true);
      const uploadData = await SaveFileToS3(file);
      let inputData = {
        organization_id: orgId,
        file_key: 'public/' + uploadData.key,
        sender_id: userId,
      };
      await RESTInvite(inputData)
        .then((response) => {
          setLoading(false);
          setCount(response?.user_count);
          message.success('file uploaded successfully');
          showModal();
        })
        .catch((error) => {
          // Handle error here
          setLoading(false);
        });

      return false;
    },
  };

  return (
    <>
      <div className="background-white p-30">
        <Row justify="space-between" align="middle" className="gap-15">
          <Typography.Title level={4} className="mb-0 fw-bolder text-black">
            Import Users
          </Typography.Title>
          <Button
            className="bordered-button"
            onClick={downloadSampleFile}
            size="middle"
          >
            Download Sample File
          </Button>
        </Row>
        {loading ? (
          <div className="progress-bar">
            <LoadingOutlined
              style={{
                fontSize: 80,
                color: '#0082ca',
              }}
              spin
            />
            <Typography.Title level={5} className="mb-0">
              Uploading
            </Typography.Title>
          </div>
        ) : (
          <Dragger className="upload" {...uploadProps}>
            <p className="ant-upload-drag-icon">
              <UploadOutlined className="text-primary" />
            </p>
            <p className="ant-upload-text">
              Select a CSV or Excel file to import
            </p>
            <p className="ant-upload-hint">Or drag and drop it here</p>
          </Dragger>
        )}
      </div>
      <ImportPriority />

      {/* open modal after uploading document */}
      <Modal
        open={isModalOpen}
        onCancel={handleViewInvitedUser}
        centered
        cancelText="View Invited Users"
        okText="Add More User"
        className="submit-idea-modal upload-success"
        onOk={handleCancel}
      >
        <Row justify="center">
          <Col className="text-center">
            <img src={UploadSuccess} alt="" />
            <Typography.Title level={4} className="mt-10">
              File Successfully uploaded.
            </Typography.Title>
            <Typography.Title level={5} className="mb-20">
              {count} users will be invited.
            </Typography.Title>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

