export const onCreateScoreCustom = /* GraphQL */ `
  subscription OnUpdateLatestRankStatus(
    $organizationID: String
    $filter: ModelSubscriptionLatestRankStatusFilterInput
  ) {
    onUpdateLatestRankStatus(filter: $filter, organizationID: $organizationID) {
      organizationID
      organization {
        organizationDefaultPrioirtybatchId
      }
    }
  }
`;

export const onCreateNotificationCustom = /* GraphQL */ `
  subscription OnCreateNotification(
    $filter: ModelSubscriptionNotificationFilterInput
    $userID: String
  ) {
    onCreateNotification(filter: $filter, userID: $userID) {
      id
    }
  }
`;

export const onCreateUserSignOutStatusCustom = /* GraphQL */ `
  subscription OnCreateUserSignOutStatus(
    $filter: ModelSubscriptionUserSignOutStatusFilterInput
    $userID: String
  ) {
    onCreateUserSignOutStatus(filter: $filter, userID: $userID) {
      id
      userID
    }
  }
`;

export const onUpdateOrganization = /* GraphQL */ `
  subscription OnUpdateOrganization(
    $filter: ModelSubscriptionOrganizationFilterInput
  ) {
    onUpdateOrganization(filter: $filter) {
      id
      organizationDefaultPrioirtybatchId
    }
  }
`;

