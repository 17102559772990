import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import {
  Col,
  Row,
  Typography,
  Form,
  Input,
  Button,
  Select,
  message,
  Alert,
} from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

import { getUsersByOrganizationIDCustom } from './SuperAdminFun';
import {
  getCurrentOrganization,
  getCurrnetUserData,
  isAdmin,
} from '../../store/slices/loginSlice';
import { RESTAddUser } from '../../utils/RESTApi';
import { getUserWeightSetting } from '../Settings/SettingFunction';
import { UserRole, userStatus } from 'utils/Constants';
import { fetchOrgDepartments, fetchRoles } from 'utils/CommonFunctions';
import { departmentsByOrganizationIDCustom } from 'Pages/Settings/Services/SettingActions';

export default function AddUser() {
  const { Option } = Select;
  const [allRoles, setAllRoles] = useState([]);
  const [selectdRole, setSelectedRole] = useState('');
  const [selectdDepartment, setSelectedDepartment] = useState('');
  const [listAllDepartments, setListAllDepartments] = useState([]);
  const [managerValue, setManagerValue] = useState([]);
  const [selectedManager, setSelectedManager] = useState([]);
  const [fetchWeight, setFetchWeight] = useState([]);
  const [loading, setLoading] = useState(false);

  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const isAdminGroup = useSelector(isAdmin);
  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;

  const history = useHistory();
  const [form] = Form.useForm();
  const { id } = useParams();

  const handleGetRoles = async () => {
    const rolesArray = await fetchRoles(isAdminGroup, currnetOrg);
    setAllRoles(rolesArray);
  };

  const listOrgDepartments = async () => {
    const departments = await fetchOrgDepartments(orgId);
    setListAllDepartments(departments);
  };

  const addUser = async (e) => {
    setLoading(true);
    try {
      let emailData = e.email.toLowerCase();
      let inputData = {
        email: emailData,
        firstname: e.firstName,
        lastname: e.lastName,
        department: e.department,
        organization: orgId,
        role: e.role,
        manager: e.manager,
        weight: e.weight,
      };
      let res = await RESTAddUser(inputData);
      setLoading(false);

      message.success('User added successfully');
      if (id) {
        history.push(`/department-details/${id}`);
      } else {
        history.push({
          pathname: '/user-management',
          state: { inviteTab: true },
        });
      }
    } catch (err) {
      console.log(err);
      message.error(err?.response?.data?.Message);
      setLoading(false);
    }
  };

  const getUsersWeight = async () => {
    try {
      let res = await getUserWeightSetting({ id: orgId });
      setFetchWeight(res?.data?.getOrganization);
    } catch (err) {
      console.log(err);
    }
  };

  const handleRole = async (e) => {
    let weight;
    if (e === UserRole.superAdmin || e === UserRole.leader) {
      weight = fetchWeight?.weightSettings?.filter(
        (el) => el.role === UserRole.leader
      )[0]?.weight;
    } else {
      weight = fetchWeight?.weightSettings?.filter((el) => el.role === e)[0]
        ?.weight;
    }
    form.setFieldsValue({
      weight: weight,
    });
    setSelectedRole(e);
  };

  const handleDepartment = (e) => {
    let managerValue;
    if (e) {
      managerValue = listAllDepartments.filter((item) => item?.id === e);
    }
    form.setFieldsValue({
      manager:
        managerValue?.[0]?.Manager !== null && managerValue?.[0]?.Manager?.id,
    });
    setSelectedDepartment(e);
  };

  const getManagers = async () => {
    try {
      let data = {
        status: { eq: userStatus.active },
        or: [
          { role: { eq: UserRole.admin } },
          { role: { eq: UserRole.superAdmin } },
          { role: { eq: UserRole.leader } },
          { role: { eq: UserRole.sme } },
        ],
      };
      let id = { organizationID: orgId };
      let res = await getUsersByOrganizationIDCustom(data, id);
      setManagerValue(res?.items);
    } catch (err) {
      console.log(err);
    }
  };

  // get department data
  const listDepartments = async () => {
    try {
      const res = await departmentsByOrganizationIDCustom({
        organizationID: orgId,
      });
      let items = res?.items;
      let dep_id;
      if (id) {
        dep_id = items.filter((i) => i?.id === id);
      } else {
        setListAllDepartments(items);
      }
      if (id) {
        form.setFieldsValue({
          department: dep_id?.[0]?.id,
          manager: dep_id?.[0]?.Manager?.id,
        });
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    listOrgDepartments();
    handleGetRoles();
    getManagers();
    getUsersWeight();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    listDepartments();
    // eslint-disable-next-line
  }, [id]);

  return (
    <>
      <div className="plr-0 pt-0 active-user">
        {/* <Typography.Title level={5} className="mb-20">
          <ArrowLeftOutlined
            className="mr-10"
            onClick={() => window.history.back()}
          />{' '}
          Back to User management
        </Typography.Title> */}

        <div className="background-white mb-30 p-20">
          <Form
            layout="vertical"
            requiredMark={false}
            form={form}
            onFinish={addUser}
          >
            <Row gutter={[20, 0]}>
              <Col span={12} md={12} sm={24} xs={24}>
                <Form.Item
                  label={<span>First Name</span>}
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter First Name',
                    },
                    {
                      pattern: /^[a-zA-Z]+$/,
                      message: 'Symbols, number or whitespace are not allowed',
                    },
                  ]}
                >
                  <Input placeholder="Please enter First Name" />
                </Form.Item>
              </Col>
              <Col span={12} md={12} sm={24} xs={24}>
                <Form.Item
                  label={<span>Last Name</span>}
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Last Name',
                    },
                    {
                      pattern: /^[a-zA-Z]+$/,
                      message: 'Symbols, number or whitespace are not allowed',
                    },
                  ]}
                >
                  <Input placeholder="Please enter Last Name" />
                </Form.Item>
              </Col>
              <Col span={12} md={12} sm={24} xs={24}>
                <Form.Item
                  label={<span>Email Id</span>}
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter email id',
                    },
                  ]}
                >
                  <Input placeholder="Please enter Email Id" />
                </Form.Item>
              </Col>
              <Col span={12} md={12} sm={24} xs={24}>
                <Row gutter={10}>
                  <Col span={17} md={17} sm={24} xs={24}>
                    <Form.Item
                      name="role"
                      label="Role"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select a role"
                        value={selectdRole}
                        onChange={(e) => handleRole(e)}
                      >
                        {allRoles?.map((item, index) => {
                          const roleName =
                            item?.name === UserRole.sme
                              ? UserRole?.teamMember
                              : item?.name;
                          return (
                            <Select.Option key={index} value={item?.name}>
                              {roleName}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={7} md={7} sm={24} xs={24}>
                    <Form.Item name="weight" label="User Weight Override">
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={12} md={12} sm={24} xs={24}>
                {id ? (
                  <Form.Item name="department" label="Department">
                    <Select
                      disabled
                      placeholder="Select Department"
                      value={selectdDepartment}
                      onChange={(e) => handleDepartment(e)}
                    >
                      {listAllDepartments?.map((item, index) => {
                        return (
                          <Select.Option key={index} value={item?.id}>
                            {item?.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                ) : (
                  <Form.Item name="department" label="Department">
                    <Select
                      placeholder="Select Department"
                      value={selectdDepartment}
                      onChange={(e) => handleDepartment(e)}
                    >
                      {listAllDepartments?.map((item, index) => {
                        return (
                          <Select.Option key={index} value={item?.id}>
                            {item?.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                )}
              </Col>
              <Col span={12} md={12} sm={24} xs={24}>
                <Form.Item name="manager" label="Manager">
                  <Select
                    placeholder="Select Manager"
                    value={selectedManager}
                    onChange={(e) => setSelectedManager(e)}
                    allowClear
                    className="capitalize-text"
                  >
                    {managerValue.map((e, index) => {
                      return (
                        <Option
                          value={e.id}
                          key={index}
                          className="capitalize-text"
                        >
                          {e.firstName + ' ' + e.lastName}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Button
                  type="primary"
                  className="blue-filled-btn"
                  size="middle"
                  htmlType="submit"
                  loading={loading}
                >
                  Add User
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </>
  );
}

