import { Skeleton } from 'antd';
import React from 'react';
import './custom.less';

function CommonSkeleton() {
  return <Skeleton.Button active block={true} className="skeleton-button" />;
}

export default CommonSkeleton;

