import React, { memo, useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Col,
  Input,
  message,
  Row,
  Select,
  Typography,
} from 'antd';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { RESTGetAuthorization } from 'utils/RESTApi';
import { useSelector } from 'react-redux';
import {
  getCurrentOrganization,
  getCurrnetUserData,
} from 'store/slices/loginSlice';
import { listAllStages } from 'Pages/Stages/Services/StageActions';
import { FetchUser } from 'Pages/AllPriorities/IdeaFunction';
import { userStatus } from 'utils/Constants';
import { fetchOrgDepartments } from 'utils/CommonFunctions';

const IdeaFilter = ({
  saveRankingFilter,
  ideaList,
  setIdeaList,
  ideaOptionsObj,
  setIdeaMatch,
  ideaMatch,
}) => {
  const [ideaFilterList, setIdeaFilterList] = useState([]);
  const operationList = [
    'is between',
    'is equal to',
    'is not equal to',
    'is greater than',
    'is greater than or equal to',
    'is less than',
    'is less than or equal to',
    'is not between',
  ];
  const operationListData = ['is one of', 'is not one of'];

  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;

  const getMFList = async () => {
    try {
      let listApi = `/measurement/filters?organization_id=${orgId}`;
      let res = await RESTGetAuthorization(listApi);
      setIdeaFilterList(res?.body);
    } catch (err) {
      console.log(err);
    }
  };
  const [listAllDepartments, setListAllDepartments] = useState([]);

  const handleAddField = () => {
    setIdeaList([...ideaList, ideaOptionsObj]);
  };

  const [crossIconVisible, setCrossIconVisible] = useState(
    Array(ideaList.length).fill(false)
  );

  const handleDataEntry = (index) => {
    if (ideaList[index].mfname !== '') {
      const newCrossIconVisible = [...crossIconVisible];
      newCrossIconVisible[index] = true;
      setCrossIconVisible(newCrossIconVisible);
    }
  };

  const handleWhoList = (value, index, field, key) => {
    if (field === 'mfname') {
      let tempWhoList = [...ideaList];
      // Remove the data for lowvalue, highvalue, valueTest, highscalevalue, and extraField
      tempWhoList[index]['lowvalue'] = null;
      tempWhoList[index]['highvalue'] = null;
      tempWhoList[index]['valueTest'] = '';
      tempWhoList[index]['highscalevalue'] = '';
      tempWhoList[index]['extraField'] = [];
      tempWhoList[index]['category'] = '';
      tempWhoList[index][field] = value;
      setIdeaList(tempWhoList);
      handleDataEntry(index);
    } else if (
      field === 'lowvalue' ||
      field === 'highvalue' ||
      field === 'threshold'
    ) {
      if (/^\d*$/.test(value) || value === '') {
        let tempWhoList = [...ideaList];
        tempWhoList[index][field] = value;
        setIdeaList(tempWhoList);
      } else {
        message.error('Only numbers are valid');
      }
    } else {
      let tempWhoList = [...ideaList];
      tempWhoList[index][field] = value;
      setIdeaList(tempWhoList);
      handleDataEntry(index);
    }
  };

  const [listStage, setListStage] = useState([]);
  const listAllStagesByOrg = async () => {
    try {
      let response = await listAllStages({ organizationID: orgId });
      let sortedLevel = response.items.sort((a, b) => a.level - b.level);
      setListStage(sortedLevel);
    } catch (err) {
      console.log(err);
    }
  };

  const listOrgDepartments = async () => {
    const departments = await fetchOrgDepartments(orgId);
    setListAllDepartments(departments);
  };
  const [userData, setUserData] = useState([]);
  // get all users by org
  const getAllUsers = async () => {
    try {
      let data = {
        and: [
          { status: { ne: userStatus.invited } },
          { status: { ne: userStatus.inactive } },
        ],
      };
      let id = { organizationID: orgId };
      let nextToken = null;
      let allUserData = [];
      while (true) {
        let res = await FetchUser(data, id, nextToken);
        allUserData.push(...res.items);
        if (!res?.nextToken) {
          break;
        }
        nextToken = res?.nextToken;
      }
      await processUserData(allUserData);
    } catch (err) {
      console.log(err);
    }
  };
  const processUserData = async (userData) => {
    setUserData(userData);
  };

  useEffect(() => {
    getMFList();
    listAllStagesByOrg();
    getAllUsers();
    listOrgDepartments();
    // eslint-disable-next-line
  }, []);

  const handleRemoveField = (indexToRemove) => {
    setIdeaList(ideaList.filter((item, index) => index !== indexToRemove));
  };
  const getSelectedMfnames = (index) => {
    return ideaList
      .slice(0, index)
      .map((item) => item.mfname)
      .filter((mfname) => mfname);
  };

  return (
    <div className="scenario-filter selected-border mt-0 filter-scroll">
      <div className="mt-10">
        <div>
          <Typography.Text className="gray-text">
            Show ideas that match
          </Typography.Text>
          <Select
            value={ideaMatch}
            variant="borderless"
            className="condition-selector w-auto"
            onChange={(e) => {
              setIdeaMatch(e);
            }}
            style={{
              flex: 1,
            }}
            options={[
              {
                value: 'ALL',
                label: 'All condition',
              },
              {
                value: 'ONE',
                label: 'Any condition',
              },
            ]}
          />
        </div>
      </div>
      {ideaList?.map((item, index) => {
        const saveRankingFilterIds =
          saveRankingFilter?.map((item) => item.id) || [];

        const idsToExclude = new Set([
          ...saveRankingFilterIds,
          ...getSelectedMfnames(index),
        ]);

        return (
          <Row justify="space-between" align="middle">
            <Col span={23} className="idea-filter mb-10">
              <Row gutter={10}>
                <Col span={7}>
                  <Select
                    onChange={(e) => handleWhoList(e, index, 'mfname')}
                    className="w-100"
                    value={item?.mfname}
                    placeholder="Select filter"
                  >
                    {ideaFilterList?.items
                      ?.filter((e) => !idsToExclude.has(e.id))
                      ?.map((e, index) => {
                        return (
                          <Select.Option key={index} value={e?.id}>
                            {e?.name}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Col>
                <Col span={7}>
                  <Select
                    onChange={(e) => handleWhoList(e, index, 'category')}
                    className="w-100"
                    value={item?.category}
                    placeholder="Select operator"
                  >
                    {ideaFilterList?.items?.filter(
                      (e) => e?.id === item?.mfname
                    )?.[0]?.type === '&'
                      ? operationListData?.map((item, index) => {
                          return (
                            <Select.Option key={index} value={item}>
                              {item}
                            </Select.Option>
                          );
                        })
                      : operationList?.map((item, index) => {
                          return (
                            <Select.Option key={index} value={item}>
                              {item}
                            </Select.Option>
                          );
                        })}
                  </Select>
                </Col>
                {ideaFilterList?.items?.filter(
                  (e) => e?.id === item?.mfname
                )?.[0]?.type === '#' ? (
                  <Col span={4}>
                    <Input
                      prefix={
                        ideaFilterList?.items?.filter(
                          (e) => e?.id === item?.mfname
                        )?.[0]?.subType === '$'
                          ? '$'
                          : '#'
                      }
                      onChange={(e) =>
                        handleWhoList(e.target.value, index, 'lowvalue')
                      }
                      value={item?.lowvalue}
                    />
                  </Col>
                ) : ideaFilterList?.items?.filter(
                    (e) => e?.id === item?.mfname
                  )?.[0]?.type === '&' ? (
                  <Col span={10}>
                    <Select
                      className="w-100 dropdown-style"
                      mode="multiple"
                      onChange={(e) => handleWhoList(e, index, 'extraField')}
                      value={item?.extraField}
                      placeholder={
                        item?.mfname?.split(':')?.[0] ===
                        process.env.REACT_APP_DEPARTMENT_ID
                          ? 'Select department'
                          : item?.mfname?.split(':')?.[0] ===
                            process.env.REACT_APP_STAGE_ID
                          ? 'Select stage'
                          : 'Select users'
                      }
                      maxTagCount="responsive"
                    >
                      {item?.mfname?.split(':')?.[0] ===
                      process.env.REACT_APP_STAGE_ID
                        ? listStage?.map((items, index) => {
                            return (
                              <Select.Option value={items?.id} key={index}>
                                {items?.name}
                              </Select.Option>
                            );
                          })
                        : item?.mfname?.split(':')?.[0] ===
                          process.env.REACT_APP_DEPARTMENT_ID
                        ? listAllDepartments?.map((items, index) => {
                            return (
                              <Select.Option value={items?.id} key={index}>
                                {items?.name}
                              </Select.Option>
                            );
                          })
                        : userData?.map((items, index) => {
                            return (
                              <Option value={items?.id} key={index}>
                                {' '}
                                {items?.firstName + ' ' + items?.lastName}
                              </Option>
                            );
                          })}
                    </Select>
                  </Col>
                ) : (
                  <Col span={4}>
                    <Select
                      className="w-100"
                      onChange={(e) => handleWhoList(e, index, 'valueTest')}
                      value={item?.valueTest}
                      placeholder="Select filter"
                    >
                      {ideaFilterList?.items
                        ?.filter((e) => e?.id === item?.mfname)?.[0]
                        ?.breakpoints?.map((item, index) => {
                          return (
                            <Select.Option value={item?.value} key={index}>
                              {item?.label}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Col>
                )}

                {(item?.category === 'is between' ||
                  item?.category === 'is not between') &&
                  (ideaFilterList?.items?.filter(
                    (e) => e?.id === item?.mfname
                  )?.[0]?.type === '#' ? (
                    <Col span={4}>
                      <Input
                        prefix={
                          ideaFilterList?.items?.filter(
                            (e) => e?.id === item?.mfname
                          )?.[0]?.subType === '$'
                            ? '$'
                            : '#'
                        }
                        onChange={(e) =>
                          handleWhoList(e.target.value, index, 'highvalue')
                        }
                        value={item?.highvalue}
                      />
                    </Col>
                  ) : (
                    <Col span={4}>
                      <Select
                        className="w-100"
                        onChange={(e) =>
                          handleWhoList(e, index, 'highscalevalue')
                        }
                        value={item?.highscalevalue}
                        placeholder="Select filter"
                      >
                        {ideaFilterList?.items
                          ?.filter((e) => e?.id === item?.mfname)?.[0]
                          ?.breakpoints?.map((item, index) => {
                            return (
                              <Select.Option value={item?.value} key={index}>
                                {item?.label}
                              </Select.Option>
                            );
                          })}
                      </Select>
                    </Col>
                  ))}
              </Row>
              <Row gutter={10} className="mt-10" align="middle">
                {(ideaFilterList?.items?.filter(
                  (e) => e?.id === item?.mfname
                )?.[0]?.type === '#' ||
                  ideaFilterList?.items?.filter(
                    (e) => e?.id === item?.mfname
                  )?.[0]?.type === '+-') &&
                  (ideaFilterList?.items?.filter(
                    (e) => e?.id === item?.mfname
                  )?.[0]?.type === '#' ? (
                    <Col span={8}>
                      <Row>
                        <Typography.Text>Threshold :</Typography.Text>
                        <Input
                          className="w-auto ml-5"
                          onChange={(e) =>
                            handleWhoList(e.target.value, index, 'threshold')
                          }
                          prefix={
                            ideaFilterList?.items?.filter(
                              (e) => e?.id === item?.mfname
                            )?.[0]?.subType === '$'
                              ? '$'
                              : '#'
                          }
                          value={item?.threshold}
                          allowClear
                        />
                      </Row>
                    </Col>
                  ) : (
                    <Col span={6}>
                      <Row>
                        <Typography.Text>Threshold :</Typography.Text>
                        <Select
                          className="w-60 ml-5"
                          onChange={(e) => handleWhoList(e, index, 'threshold')}
                          value={item?.threshold}
                          allowClear
                        >
                          {ideaFilterList?.items
                            ?.filter((e) => e?.id === item?.mfname)?.[0]
                            ?.breakpoints?.map((item, index) => {
                              return (
                                <Select.Option value={item?.value} key={index}>
                                  {item?.label}
                                </Select.Option>
                              );
                            })}
                        </Select>
                      </Row>
                    </Col>
                  ))}
                <Col span={7}>
                  <Checkbox
                    onChange={(e) =>
                      handleWhoList(e.target.checked, index, 'includeBlanks')
                    }
                    value={item?.includeBlanks}
                    checked={item?.includeBlanks}
                  >
                    Include blanks
                  </Checkbox>
                </Col>
              </Row>
            </Col>

            <Col span={1}>
              {(index > 0 || crossIconVisible[index]) && (
                <Button
                  onClick={() => handleRemoveField(index)}
                  className="fs-12 close-btn"
                  size="small"
                >
                  <CloseOutlined />
                </Button>
              )}
            </Col>
          </Row>
        );
      })}
      <Row>
        <Button
          onClick={handleAddField}
          className="fs-12 fw-500 blue-filled-btn"
          type="primary"
          size="small"
        >
          <PlusOutlined />
        </Button>
      </Row>
    </div>
  );
};

export default memo(IdeaFilter);

