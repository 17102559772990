import React, { memo } from 'react';
import {
  getCurrentFormElementID,
  setCurrentFormElementID,
  setFormElementUpdate,
} from 'store/slices/StageSlice';
import {
  EditOutlined,
  HolderOutlined,
  DeleteOutlined,
  UploadOutlined,
  InfoCircleOutlined,
  MinusOutlined,
} from '@ant-design/icons';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import { useDispatch, useSelector } from 'react-redux';
import { Draggable } from 'react-beautiful-dnd';
import {
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Select,
  Slider,
  Tooltip,
  Typography,
  Upload,
} from 'antd';
import { arrayMoveImmutable } from 'array-move';

const Stage1DetailForm = ({
  updatedFormElement,
  setSelectedElement,
  setMFList,
  mfList,
  id,
}) => {
  const currentFormElementID = useSelector(getCurrentFormElementID);
  const dispatch = useDispatch();
  const { TextArea } = Input;
  const { Dragger } = Upload;

  const inputElementClickHandler = (event, id) => {
    dispatch(setCurrentFormElementID(id));
  };

  const getClickedElementData = (data) => {
    setSelectedElement(data);
  };

  //Function to handles the deletion of a form element at a specified index.
  const handleDelete = (index) => {
    const updatedFormLayout = [...updatedFormElement];
    updatedFormLayout.splice(index, 1);
    dispatch(setFormElementUpdate(updatedFormLayout));
  };

  const TableBodySortable = SortableContainer(({ children }) => {
    return <div>{children}</div>;
  });

  const DragItemHandler = SortableHandle(() => {
    return <HolderOutlined />;
  });

  const marks = {};

  updatedFormElement?.forEach((firstItem) => {
    const matchingItem = mfList?.find(
      (secondItem) => secondItem.id === firstItem.id
    );

    if (
      matchingItem &&
      matchingItem.breakpoints &&
      matchingItem.breakpoints.length > 0
    ) {
      marks[firstItem.id] = {
        0: matchingItem?.breakpoints[0]?.label, // Label at 0
        25: matchingItem?.breakpoints[1]?.label, // Label at 25
        50: matchingItem?.breakpoints[2]?.label, // Label at 50
        75: matchingItem?.breakpoints[3]?.label, // Label at 75
        100: matchingItem?.breakpoints[4]?.label, // Label at 100
      };
    } else {
      marks[firstItem.id] = {
        0: 'Label 0',
        25: 'Label 25',
        50: 'Label 50',
        75: 'Label 75',
        100: 'Label 100',
      };
    }
  });
  const marks1 = {};
  // const numericMarks = () => {
  updatedFormElement?.forEach((firstItem) => {
    const matchingItem = mfList?.find(
      (secondItem) => secondItem.id === firstItem.id
    );

    marks1[firstItem.id] = {
      0: matchingItem?.breakpoints[0]?.value, // Label at 0
      25: matchingItem?.breakpoints[0]?.value,
      50: matchingItem?.breakpoints[0]?.value,
      75: matchingItem?.breakpoints[0]?.value,
      100: matchingItem?.breakpoints[1]?.value, // Label at 100
    };
  });

  const handleMFField = (index1, index) => {
    const deletedItem = mfList[index1];

    deletedItem.isUsed = false;
    setMFList([...mfList]);
    const updatedFormLayout = [...updatedFormElement];
    updatedFormLayout.splice(index, 1);
    dispatch(setFormElementUpdate(updatedFormLayout));
  };

  const ItemRow = SortableElement(({ data: { index, currentElement } }) => {
    let mfInputType = mfList.find((item) => item.id === currentElement?.id);
    let mfIndex = mfList.findIndex((item) => item.id === currentElement?.id);

    return (
      <Draggable
        key={currentElement.id}
        draggableId={currentElement.id}
        index={index}
      >
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            {currentElement?.inputType === 'singleLine' && (
              <Col
                span={24}
                className={`${
                  currentElement?.id === currentFormElementID
                    ? 'highlite-container mb-10 p-0'
                    : 'p-0'
                }`}
                id={currentElement?.id}
                onClick={(e, id = currentElement?.id) => {
                  inputElementClickHandler(e, id);
                }}
                // style={{ backgroundColor: '#f4f5fa' }}
              >
                {currentElement?.id === currentFormElementID && (
                  <>
                    <div className="drag-icon">
                      <DragItemHandler />
                    </div>
                    <div
                      className={
                        currentElement?.id === process.env.REACT_APP_TITLE_ID
                          ? 'edit-highlight mt-15'
                          : 'edit-highlight'
                      }
                    >
                      <EditOutlined
                        onClick={() => getClickedElementData(currentElement)}
                      />
                      {currentElement.source === 'created' && (
                        <>
                          <Divider
                            type="vertical"
                            className="highlight-divider"
                          />
                          <DeleteOutlined onClick={() => handleDelete(index)} />
                        </>
                      )}
                    </div>
                  </>
                )}
                <Form.Item
                  label={
                    <span className="fs-15">
                      {currentElement?.label}
                      {currentElement?.tooltip && (
                        <Tooltip title={currentElement?.tooltip}>
                          <InfoCircleOutlined
                            style={{
                              marginLeft: 4,
                              color: '#808080',
                            }}
                            className="mr-10"
                          />
                        </Tooltip>
                      )}
                    </span>
                  }
                  name="label"
                  className={
                    currentElement?.id === currentFormElementID
                      ? 'w-100 p-10 mb-0'
                      : ''
                  }
                >
                  <Input
                    className="h-40"
                    className="input-font l-height"
                    placeholder={currentElement.placeholder}
                    readOnly
                  />
                </Form.Item>
              </Col>
            )}
            {currentElement?.inputType === 'multiLine' && (
              <Col
                span={24}
                className={`${
                  currentElement?.id === currentFormElementID
                    ? 'highlite-container mb-10 p-0'
                    : 'p-0'
                }`}
                // style={{ backgroundColor: '#f4f5fa' }}
              >
                {currentElement?.id === currentFormElementID && (
                  <>
                    <div className="drag-icon">
                      <DragItemHandler />
                    </div>
                    <div className="edit-highlight">
                      <EditOutlined
                        onClick={() => getClickedElementData(currentElement)}
                      />
                      {currentElement.source === 'created' && (
                        <>
                          <Divider
                            type="vertical"
                            className="highlight-divider"
                          />
                          <DeleteOutlined onClick={() => handleDelete(index)} />
                        </>
                      )}
                    </div>
                  </>
                )}
                <Form.Item
                  name="description"
                  label={
                    <p className="fs-15">
                      {currentElement?.label}
                      {currentElement?.tooltip && (
                        <Tooltip title={currentElement?.tooltip}>
                          <InfoCircleOutlined
                            style={{
                              marginLeft: 4,
                              color: '#808080',
                            }}
                            className="mr-10"
                          />
                        </Tooltip>
                      )}
                      {currentElement?.maxChars !== undefined &&
                        currentElement?.maxChars !== '' && (
                          <span className="text-gray">
                            (Max. {currentElement?.maxChars} limit)
                          </span>
                        )}
                    </p>
                  }
                  className={
                    currentElement?.id === currentFormElementID
                      ? 'w-100 p-10 mb-0'
                      : ''
                  }
                >
                  <TextArea
                    onClick={(e, id = currentElement?.id) => {
                      inputElementClickHandler(e, id);
                    }}
                    id={currentElement?.id}
                    autoSize={{
                      minRows: 5,
                      maxRows: 5,
                    }}
                    maxLength={currentElement?.maxChars}
                    className="input-font"
                    placeholder={currentElement.placeholder}
                    readOnly
                  />
                </Form.Item>
              </Col>
            )}
            {currentElement?.inputType === 'dropdown' && (
              <Col
                span={24}
                className="p-0"
                id={currentElement?.id}
                className={`${
                  currentElement?.id === currentFormElementID
                    ? 'highlite-container mb-10 p-0'
                    : 'p-0'
                }`}
                onClick={(e, id = currentElement?.id) => {
                  inputElementClickHandler(e, id);
                }}
                // style={{ backgroundColor: '#f4f5fa' }}
              >
                {currentElement?.id === currentFormElementID && (
                  <>
                    <div className="drag-icon">
                      <DragItemHandler />
                    </div>
                    <div className="edit-highlight">
                      <EditOutlined
                        onClick={() => getClickedElementData(currentElement)}
                      />
                      {currentElement.source === 'created' && (
                        <>
                          <Divider
                            type="vertical"
                            className="highlight-divider"
                          />
                          <DeleteOutlined onClick={() => handleDelete(index)} />
                        </>
                      )}
                    </div>
                  </>
                )}
                <Form.Item
                  label={<span className="fs-15">{currentElement?.label}</span>}
                  name="Department"
                  className={
                    currentElement?.id === currentFormElementID
                      ? 'w-100 p-10 mb-0'
                      : ''
                  }
                >
                  <Select
                    className="input-font"
                    mode="multiple"
                    placeholder={currentElement.placeholder}
                    style={{
                      pointerEvents: 'none',
                      background: '#f5f5f5',
                    }}
                    maxTagCount="responsive"
                  ></Select>
                </Form.Item>
              </Col>
            )}
            {currentElement?.inputType === 'datepicker' && (
              <Col
                span={24}
                className="p-0"
                id={currentElement?.id}
                className={`${
                  currentElement?.id === currentFormElementID
                    ? 'highlite-container mb-10 p-0'
                    : 'p-0'
                }`}
                onClick={(e, id = currentElement?.id) => {
                  inputElementClickHandler(e, id);
                }}
              >
                {currentElement?.id === currentFormElementID && (
                  <>
                    <div className="drag-icon">
                      <DragItemHandler />
                    </div>
                    <div className="edit-highlight">
                      <EditOutlined
                        onClick={() => getClickedElementData(currentElement)}
                      />
                      {currentElement.source === 'created' && (
                        <>
                          <Divider
                            type="vertical"
                            className="highlight-divider"
                          />
                          <DeleteOutlined onClick={() => handleDelete(index)} />
                        </>
                      )}
                    </div>
                  </>
                )}
                <Form.Item
                  label={<span className="fs-15">{currentElement?.label}</span>}
                  name="DatePicker"
                  className={
                    currentElement?.id === currentFormElementID
                      ? 'w-100 p-10 mb-0'
                      : ''
                  }
                >
                  <DatePicker
                    className="w-100"
                    placeholder={currentElement.placeholder}
                    disabled
                  />
                </Form.Item>
              </Col>
            )}
            {currentElement?.inputType === 'link' && (
              <Col
                span={24}
                className="p-0"
                id={currentElement?.id}
                className={`${
                  currentElement?.id === currentFormElementID
                    ? 'highlite-container mb-10 p-0'
                    : 'p-0'
                }`}
                onClick={(e, id = currentElement?.id) => {
                  inputElementClickHandler(e, id);
                }}
                // style={{ backgroundColor: '#f4f5fa' }}
              >
                {currentElement?.id === currentFormElementID && (
                  <>
                    <div className="drag-icon">
                      <DragItemHandler />
                    </div>
                    <div className="edit-highlight">
                      <EditOutlined
                        onClick={() => getClickedElementData(currentElement)}
                      />
                      {currentElement.source === 'created' && (
                        <>
                          <Divider
                            type="vertical"
                            className="highlight-divider"
                          />
                          <DeleteOutlined onClick={() => handleDelete(index)} />
                        </>
                      )}
                    </div>
                  </>
                )}
                <Form.Item
                  label={<span className="fs-15">{currentElement?.label}</span>}
                  name="link"
                  className={
                    currentElement?.id === currentFormElementID
                      ? 'w-100 p-10 mb-0'
                      : ''
                  }
                >
                  <Input placeholder={currentElement.placeholder} readOnly />
                </Form.Item>
              </Col>
            )}
            {currentElement?.inputType === 'dragger' && (
              <Col
                span={24}
                className="p-0"
                id={currentElement?.id}
                className={`${
                  currentElement?.id === currentFormElementID
                    ? 'highlite-container mb-10 p-0'
                    : 'p-0'
                }`}
                onClick={(e, id = currentElement?.id) => {
                  inputElementClickHandler(e, id);
                }}
                // style={{ backgroundColor: '#f4f5fa' }}
              >
                {currentElement?.id === currentFormElementID && (
                  <>
                    <div className="drag-icon">
                      <DragItemHandler />
                    </div>
                    <div className="edit-highlight">
                      <EditOutlined
                        onClick={() => getClickedElementData(currentElement)}
                      />
                      {currentElement.source === 'created' && (
                        <>
                          <Divider
                            type="vertical"
                            className="highlight-divider"
                          />
                          <DeleteOutlined onClick={() => handleDelete(index)} />
                        </>
                      )}
                    </div>
                  </>
                )}
                <Form.Item
                  label={<span className="fs-15">{currentElement?.label}</span>}
                  name="link"
                  className={
                    currentElement?.id === currentFormElementID
                      ? 'w-100 p-10 mb-0'
                      : ''
                  }
                >
                  <Dragger maxCount={5} disabled>
                    <p className="ant-upload-drag-icon">
                      <UploadOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Drag and drop files, or{' '}
                      <span className="text-primary">Browse</span>
                    </p>
                    <p className="ant-upload-hint">
                      Supported format: Word, Excel, PPT, Google Sheets with max
                      size of 5 MB. Max 5 files can be upload
                    </p>
                  </Dragger>
                </Form.Item>
              </Col>
            )}
            {currentElement?.inputType === 'symbol' && (
              <Col
                span={24}
                className="p-0"
                id={currentElement?.id}
                className={`${
                  currentElement?.id === currentFormElementID
                    ? 'highlite-container mb-10 p-0'
                    : 'p-0'
                }`}
                onClick={(e, id = currentElement?.id) => {
                  inputElementClickHandler(e, id);
                }}
              >
                {currentElement?.id === currentFormElementID && (
                  <>
                    <div className="drag-icon">
                      <DragItemHandler />
                    </div>
                    <div className="edit-highlight">
                      <EditOutlined
                        onClick={() => getClickedElementData(currentElement)}
                      />
                      {currentElement.source === 'created' && (
                        <>
                          <Divider
                            type="vertical"
                            className="highlight-divider"
                          />
                          <DeleteOutlined onClick={() => handleDelete(index)} />
                        </>
                      )}
                    </div>
                  </>
                )}
                <Form.Item
                  label={
                    <span className="fs-15">
                      {currentElement?.label}{' '}
                      {currentElement?.tooltip && (
                        <Tooltip title={currentElement?.tooltip}>
                          <InfoCircleOutlined
                            style={{
                              marginLeft: 4,
                              color: '#808080',
                            }}
                            className="mr-10"
                          />
                        </Tooltip>
                      )}
                    </span>
                  }
                  name="symbol"
                  className={
                    currentElement?.id === currentFormElementID
                      ? 'w-100 p-10 mb-0'
                      : ''
                  }
                >
                  <Select
                    className={
                      currentElement?.id === currentFormElementID
                        ? 'input-font stage-detail-edit'
                        : 'input-font'
                    }
                    mode="multiple"
                    placeholder={currentElement.placeholder}
                    style={{
                      pointerEvents: 'none',
                    }}
                  ></Select>
                </Form.Item>
              </Col>
            )}
            {(mfInputType?.subType === '+-' ||
              mfInputType?.subType === '-+') && (
              <>
                <Typography.Text className="slider-container-p">
                  {mfInputType?.name}
                </Typography.Text>
                <Col
                  // className="p-0 mf-slider mb-10"
                  id={currentElement?.id}
                  className={`${
                    currentElement?.id === currentFormElementID
                      ? 'slider-container mb-10 '
                      : 'p-0  mb-10 mf-slider h-120'
                  }`}
                  onClick={(e, id = currentElement?.id) => {
                    inputElementClickHandler(e, id);
                  }}
                >
                  {currentElement?.id === currentFormElementID && (
                    <>
                      <div className="drag-icon">
                        <DragItemHandler />
                      </div>
                      {currentElement?.stageID === id && (
                        <div className="edit-highlight">
                          <>
                            <MinusOutlined
                              onClick={() => handleMFField(mfIndex, index)}
                            />
                          </>
                        </div>
                      )}
                    </>
                  )}

                  <Slider
                    key={currentElement.id}
                    className={
                      mfInputType?.subType === '+-'
                        ? 'rate-slider slider-change-1 m-0 slider-scale'
                        : 'rate-slider slider-change m-0 slider-scale'
                    }
                    tooltip={{ open: false }}
                    onChange={(value) => onChange(value, currentElement.id)}
                    value={50}
                    marks={marks[currentElement?.id]}
                    disabled
                  />
                </Col>
              </>
            )}
            {(mfInputType?.subType === '$' || mfInputType?.subType === '#') && (
              <>
                <Typography.Text className="slider-container-p">
                  {mfInputType?.name}
                </Typography.Text>
                <Col
                  // className="p-0 mf-slider mb-10"
                  id={currentElement?.id}
                  className={`${
                    currentElement?.id === currentFormElementID
                      ? 'slider-container mb-10 '
                      : 'p-0  mb-10 mf-slider'
                  }`}
                  onClick={(e, id = currentElement?.id) => {
                    inputElementClickHandler(e, id);
                  }}
                >
                  {currentElement?.id === currentFormElementID && (
                    <>
                      <div className="drag-icon">
                        <DragItemHandler />
                      </div>

                      {/* <EditOutlined
                        onClick={(e) =>
                          getClickedElementData(e, currentElement)
                        }
                      /> */}
                      {currentElement?.stageID === id && (
                        <div className="edit-highlight">
                          <>
                            <MinusOutlined
                              onClick={() => handleMFField(mfIndex, index)}
                            />
                          </>
                        </div>
                      )}
                    </>
                  )}

                  <Form.Item
                    name="numeric"
                    className={
                      currentElement?.id === currentFormElementID
                        ? 'w-100 p-10 mb-0'
                        : ''
                    }
                  >
                    <div className="measurement-slider slider-container-p">
                      <Slider
                        tooltip={{ open: true }}
                        // step={null}
                        className="numeric-slider mt-45"
                        marks={marks1[currentElement?.id]}
                        tipFormatter={(value) => {
                          const prefix =
                            mfInputType?.subType === '$' ? '$' : '#';
                          return `${prefix}${marks1[currentElement?.id][0]}`;
                        }}
                        disabled
                      />
                    </div>
                  </Form.Item>
                </Col>
              </>
            )}
            {provided.placeholder}
          </div>
        )}
      </Draggable>
    );
  });

  const onSortEnd = (arr) => {
    let item_links = [...updatedFormElement];
    item_links = arrayMoveImmutable(item_links, arr.oldIndex, arr.newIndex);
    dispatch(setFormElementUpdate(item_links));
  };

  const seenIds = {};

  const uniqueObjects = updatedFormElement?.filter((obj) => {
    if (seenIds[obj.id]) {
      return false;
    } else {
      seenIds[obj.id] = true;
      return true;
    }
  });

  return (
    <TableBodySortable
      onSortEnd={onSortEnd}
      useDragHandle
      helperContainer={() => document.querySelector('.stage-details')}
    >
      {uniqueObjects?.map((currentElement, index) => {
        const lastIndex = updatedFormElement.length - 1;
        return (
          <>
            <ItemRow
              index={index}
              key={index}
              data={{ index, currentElement }}
            />
          </>
        );
      })}
    </TableBodySortable>
  );
};

export default memo(Stage1DetailForm);

