import { Col, Row, Skeleton, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import './profile.less';
import { ECHOIMAGES } from 'Assets/Images';
import CounterComponent from './ImpactNumbers';
import {
  getComparisonList,
  getNewStageName,
  getRatingList,
  getStage2Name,
  getStage3Name,
  setNewStageName,
  setStage2Name,
  setStage3Name,
} from 'store/slices/StageSlice';
import { useSelector, useDispatch } from 'react-redux';
import { fetchOrganizationStages } from 'utils/CommonFunctions';
import {
  getCurrentOrganization,
  getCurrnetUserData,
} from 'store/slices/loginSlice';
import { capitalizeFirstLetter } from 'utils/Constants';
import { getGoalCount } from 'Pages/Dashboard/DashboardFunctions';
import { useHistory } from 'react-router-dom';

function Impact({
  opportunityCount,
  projectCount,
  gammaCount,
  comparisonCompleted,
  ratingsGiven,
}) {
  const { smeProfile } = ECHOIMAGES.smeProfile;
  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;
  const userId = currnetOrg?.adminID || loginUsrOrg?.id;
  const stage1Name = useSelector(getNewStageName);
  const stage2Name = useSelector(getStage2Name);
  const stage3Name = useSelector(getStage3Name);
  const comparisonList = useSelector(getComparisonList);
  const ratingList = useSelector(getRatingList);
  const [goalCount, setGoalCount] = useState();
  const history = useHistory();

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const getStageName = async () => {
    setLoading(true);
    try {
      const sortedStages = await fetchOrganizationStages(orgId);
      if (sortedStages) {
        dispatch(setStage2Name(sortedStages?.[1]?.name));
        dispatch(setStage3Name(sortedStages?.[2]?.name));
        dispatch(setNewStageName(sortedStages?.[0]?.name));
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const getUsersGoalCount = async () => {
    try {
      const res = await getGoalCount({ ownerId: userId });
      setGoalCount(res?.items?.length);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (userId) {
      getUsersGoalCount();
    }
    // eslint-disable-next-line
  }, [userId]);

  useEffect(() => {
    if (orgId) {
      getStageName();
    }
    // eslint-disable-next-line
  }, [orgId]);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      history.push({
        pathname: '/goal',
        state: { myGoals: true },
      });
    }
  };
  return (
    <div className="profile-banner p-30 my-sixth-step">
      <Row gutter={40}>
        <Col span={20} lg={20} md={24} sm={24} xs={24}>
          <Typography.Title
            level={3}
            className="text-white fw-bolder header-font"
          >
            You're Making an Impact
          </Typography.Title>
          <Row>
            <Col span={24}>
              <div className="box-wrapper">
                <div className="box">
                  <Typography.Title level={2}>
                    <CounterComponent initialValue={gammaCount} />
                  </Typography.Title>
                  <Typography.Title level={5} className="fs-14">
                    Submitted{' '}
                    {loading ? (
                      <Skeleton.Button />
                    ) : (
                      capitalizeFirstLetter(stage1Name)
                    )}
                  </Typography.Title>
                </div>

                <div className="box">
                  <Typography.Title level={2}>
                    <CounterComponent initialValue={opportunityCount} />
                  </Typography.Title>
                  <Typography.Title level={5} className="fs-14">
                    {loading ? (
                      <Skeleton.Button />
                    ) : (
                      capitalizeFirstLetter(stage1Name)
                    )}{' '}
                    promoted to{' '}
                    {loading ? (
                      <Skeleton.Button />
                    ) : (
                      capitalizeFirstLetter(stage2Name)
                    )}
                  </Typography.Title>
                </div>
                <div className="box">
                  <Typography.Title level={2}>
                    <CounterComponent initialValue={projectCount} />
                  </Typography.Title>
                  <Typography.Title level={5} className="fs-14">
                    {loading ? (
                      <Skeleton.Button />
                    ) : (
                      capitalizeFirstLetter(stage1Name)
                    )}{' '}
                    promoted to{' '}
                    {loading ? (
                      <Skeleton.Button />
                    ) : (
                      capitalizeFirstLetter(stage3Name)
                    )}
                  </Typography.Title>
                </div>
                {comparisonList > 0 ? (
                  <div className="box">
                    <Typography.Title level={2}>
                      <CounterComponent initialValue={comparisonCompleted} />
                    </Typography.Title>
                    <Typography.Title level={5} className="fs-14">
                      Comparisons completed
                    </Typography.Title>
                  </div>
                ) : (
                  <></>
                )}
                {ratingList > 0 ? (
                  <div className="box">
                    <Typography.Title level={2}>
                      <CounterComponent initialValue={ratingsGiven} />
                    </Typography.Title>
                    <Typography.Title level={5} className="fs-14">
                      Ratings given
                    </Typography.Title>
                  </div>
                ) : (
                  <></>
                )}
                <div
                  role="button"
                  tabIndex="0"
                  className="box"
                  onKeyPress={handleKeyPress}
                  onClick={() =>
                    history.push({
                      pathname: '/goal',
                      state: { myGoals: true },
                    })
                  }
                >
                  <Typography.Title level={2}>
                    <CounterComponent initialValue={goalCount} />
                  </Typography.Title>
                  <Typography.Title level={5} className="fs-14">
                    My Goals
                  </Typography.Title>
                </div>
                <div className="box img-box">
                  <img src={smeProfile} alt="" className="w-100 h-100 image" />
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={4} lg={4} md={0} sm={0} xs={0}>
          <img src={smeProfile} alt="" className="w-100 image" />
        </Col>
      </Row>
    </div>
  );
}

export default Impact;

