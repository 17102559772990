import React, { memo, useEffect, useState } from 'react';
import {
  Button,
  Col,
  Divider,
  Form,
  Row,
  Typography,
  message,
  Tag,
} from 'antd';
import { EditOutlined } from '@ant-design/icons';
import PublishModal from '../Components/PublishModal';
import { getFormByStage, updateStageCustom } from '../Services/StageActions';
import { v4 as uuidv4 } from 'uuid';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import {
  getDraftForm,
  getformElementUpdate,
  getPublishedForm,
  getStageName,
  setBackButtonClicked,
  setCurrentFormElementID,
  setcurrentStageDetails,
  setDraftForm,
  setFinalForm,
  setFormElementUpdate,
  setPreviewFlag,
  setPublishedForm,
  setStageName,
} from 'store/slices/StageSlice';
import { useDispatch, useSelector } from 'react-redux';
import CommonFormElement from '../Components/CommonFormElement';
import MultiLineInput from '../Components/MultiLineInput';
import EditStageName from '../Components/EditStageName';

import {
  getCurrentOrganization,
  getCurrnetUserData,
  setFormLayout,
} from 'store/slices/loginSlice';
import StageDetailPageSkeleton from 'Common/StageDetailPageSkeleton';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import ConfirmationModal from '../Components/ConfirmationModal';
import { capitalizeFirstLetter } from 'utils/Constants';
import StageDetailForm from '../Components/StageDetailForm';
import SaveDraft from '../Components/SaveDraft';
import LeavePageModal from '../Components/LeavePageModal';
import { API } from 'aws-amplify';
import {
  createFormDraftCustom,
  updateFormDraftCustom,
} from 'graphql/customMutation';
import { RESTGet } from 'utils/RESTApi';
import _isEqual from 'lodash/isEqual';

const StageDetail = () => {
  const { id } = useParams();
  const history = useHistory();
  const [formBody, setFormBody] = useState([]);
  const [selectedElement, setSelectedElement] = useState({});
  const updatedFormElement = useSelector(getformElementUpdate);
  const getStageNameElement = useSelector(getStageName);
  const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
  const [IseditNameModalOpen, setIsEditNameModalOpen] = useState(false);
  const [currentStage, setCurrentStages] = useState({});
  const [stageLevel, setStageLevel] = useState();
  const [formLoading, setFormLoading] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [draftLoading, setDraftLoading] = useState(false);
  const [copyofPublishedForm, setIsCopyofPublishedForm] = useState([]);

  const [draftVersion, setDraftVersion] = useState();
  const [backupFormVersion, setBackupFormVersion] = useState();
  const [mfList, setMFList] = useState([]);
  const stageStatus = history?.location?.state?.prevForm;
  const fetchPublishedForm = useSelector(getPublishedForm);
  const draftLocation = useLocation();
  const dispatch = useDispatch();
  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;
  const draftForms = useSelector(getDraftForm);

  const [form] = Form.useForm();

  const inputElementClickHandler = (event, id) => {
    event.stopPropagation();
    event.preventDefault();
    dispatch(setCurrentFormElementID(id));
  };

  const getClickedElementData = (event, data) => {
    event.stopPropagation();
    event.preventDefault();
    setSelectedElement(data);
  };

  const handlePublishModalOpen = () => {
    setIsPublishModalOpen(true);
  };

  const handleEditModalOpen = () => {
    setIsEditNameModalOpen(true);
  };

  const handleConfirmationModalOpen = () => {
    setConfirmationModal(true);
  };

  const generatePrefixedId = (level) => {
    const prefix = level < 10 ? `${level}` : `${level}`;
    const uuid = uuidv4();
    return `${prefix}-${uuid}`;
  };

  const getMFList = async () => {
    try {
      let listApi = `/measurement/all?organization_id=${orgId}`;
      let res = await RESTGet(listApi);
      setMFList(res?.body?.items);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getMFList();
    // eslint-disable-next-line
  }, []);

  // Fetches form layout data for a specific stage
  const getFormLayout = async () => {
    setFormLoading(true);
    try {
      let res;
      let formLayout;
      if (draftLocation?.pathname.includes('/draft-form')) {
        res = await getFormByStage({ id: id });

        formLayout = JSON.parse(res?.draft?.items?.[0]?.layout);
        dispatch(setDraftForm(res?.draft?.items?.[0]?.layout));
        dispatch(setPublishedForm(res?.form?.layout));
      } else {
        res = await getFormByStage({ id: id });
        formLayout = JSON.parse(res?.form?.layout);
        dispatch(setPublishedForm(res?.form?.layout));
      }
      setDraftVersion(res?.draft?.items?.[0]?.version);
      setBackupFormVersion(res?.draft?.items?.[1]?.version);
      dispatch(setcurrentStageDetails(res));
      setStageLevel(res.level);
      setIsCopyofPublishedForm(res?.form?.layout);
      formLayout = formLayout.map((item) => ({
        id: item?.id || uuidv4(),
        ...item,
      }));
      if (updatedFormElement) {
        dispatch(setFormElementUpdate(updatedFormElement));
        setFormBody(updatedFormElement);
        dispatch(setStageName(res));
      } else {
        dispatch(setFormElementUpdate(formLayout));
        setFormBody(formLayout);
        dispatch(setStageName(res));
      }
      setFormLoading(false);
    } catch (err) {
      setFormLoading(false);
    }
  };

  //Function for drag form element
  const handleDragStart = (e, elementType) => {
    e.dataTransfer.setData('elementType', elementType);
  };

  //Function to drop form element
  const onDragEnd = ({ source, destination, draggableId }) => {
    if (!destination) return;
    if (source.droppableId !== destination.droppableId) {
      const newList2 = Array.from(updatedFormElement);

      const elementType = draggableId;
      const measurementType = draggableId.split('_');

      let type;
      if (elementType === 'singleLine' || elementType === 'multiLine') {
        type = 'text';
      } else if (elementType === 'link') {
        type = 'link';
      } else if (elementType === 'dropdown' || elementType === 'symbol') {
        type = 'dropdown';
      } else if (
        measurementType?.[0] === '#' ||
        measurementType?.[0] === '+-'
      ) {
        type = 'measurement';
      }

      let newFormItem = {};
      if (type === 'measurement') {
        newFormItem = {
          id: measurementType[1],
          type: type,
          stageID: id,
        };
      } else {
        newFormItem = {
          id: generatePrefixedId(stageLevel),
          inputType: elementType,
          label: 'Label',
          type: type,
        };
      }

      newList2.splice(destination.index, 0, newFormItem);
      dispatch(setFormElementUpdate(newList2));

      setFormBody(newList2);
    }
  };

  //Function to update the stage form
  const updateStageForm = async () => {
    setLoading(true);
    try {
      let res;
      let updatedForm;
      let inputData1 = {
        layout: JSON.stringify(updatedFormElement),
        name: getStageNameElement?.name + ' ' + 'Draft',
        organizationID: orgId,
        stageID: getStageNameElement?.id,
        updatedByIAM: false,
        version: 1,
      };
      const firstApiCall = await API.graphql({
        query:
          draftVersion === 1 ? updateFormDraftCustom : createFormDraftCustom,
        variables: { input: inputData1 },
      });

      let inputData2 = {
        layout: fetchPublishedForm,
        name: getStageNameElement?.name + ' ' + 'Draft',
        organizationID: orgId,
        stageID: getStageNameElement?.id,
        updatedByIAM: false,
        version: 0,
      };
      const secondApiCall = await API.graphql({
        query:
          backupFormVersion === 0
            ? updateFormDraftCustom
            : createFormDraftCustom,
        variables: { input: inputData2 },
      });
      await Promise.all([firstApiCall, secondApiCall]);

      let inputData = {
        id: id,
        active: true,
        form: {
          layout: JSON.stringify(updatedFormElement),
        },
        updatedByIAM: false,
      };
      res = await updateStageCustom(inputData);
      updatedForm = JSON.parse(res?.form?.layout);

      dispatch(setFormElementUpdate(updatedForm));
      dispatch(setFormLayout(res?.Organization?.stages));
      dispatch(setFinalForm(true));
      setLoading(false);
      if (stageStatus?.active) {
        setConfirmationModal(false);
      }
      handlePublishModalOpen();
      setTimeout(() => {
        setIsPublishModalOpen(false);
        history.push('/stages');
        dispatch(setFinalForm(false));
      }, 2000);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  //Function to save form as draft for inactive stages
  const draftForm = async () => {
    setDraftLoading(true);
    try {
      let inputData = {
        id: id,
        active: false,
        form: {
          layout: JSON.stringify(updatedFormElement),
        },
      };
      let res = await updateStageCustom(inputData);
      let updatedForm = JSON.parse(res?.form?.layout);
      dispatch(setFormElementUpdate(updatedForm));
      message.success('Draft saved successfully!');
      setDraftLoading(false);
    } catch (err) {
      setDraftLoading(false);
      console.log(err);
    }
  };

  const handleFormChange = () => {
    setIsFormChanged(true);
  };

  useEffect(() => {
    getFormLayout();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const pathname = window.location.pathname;
    const isFormChanged =
      pathname?.includes('/draft-form') ||
      pathname?.includes('/stage1-draft-form')
        ? updatedFormElement !== null &&
          !_isEqual(updatedFormElement, JSON?.parse(draftForms))
        : updatedFormElement !== null &&
          !_isEqual(updatedFormElement, JSON?.parse(fetchPublishedForm));
    const handleBeforeUnload = (event) => {
      if (isFormChanged) {
        event.preventDefault();
        event.returnValue =
          'You have unsaved changes. Are you sure you want to leave?';
      }
    };

    const handleMenuItemClick = (event) => {
      if (isFormChanged) {
        event.preventDefault();
        const path = event.currentTarget.getAttribute('href');
        setIsConfirmationVisible(true);
        dispatch(setBackButtonClicked(true));
      }
    };

    // Attach event listeners
    window.addEventListener('beforeunload', handleBeforeUnload);

    const menuItems = document.querySelectorAll('.sidebar');
    menuItems.forEach((item) => {
      item.addEventListener('click', handleMenuItemClick);
    });

    return () => {
      // Clean up event listeners
      window.removeEventListener('beforeunload', handleBeforeUnload);
      menuItems.forEach((item) => {
        item.removeEventListener('click', handleMenuItemClick);
      });
    };
  }, [updatedFormElement, history]);

  return (
    <div className="stage-details background-white">
      <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
        <Row justify="space-between" className="w-100">
          <Col className="header-text" span={16}>
            <div className="d-flex">
              <Typography.Title level={4} className="mt-0 stage-text">
                Stage {getStageNameElement?.level} -
              </Typography.Title>
              <Typography.Title level={4} className="mt-0 ml-10">
                {getStageNameElement?.name &&
                  capitalizeFirstLetter(getStageNameElement?.name)}
              </Typography.Title>
              <EditOutlined
                className="ml-10 editicon"
                onClick={() => {
                  handleEditModalOpen();
                  setCurrentStages(getStageNameElement);
                }}
              />
            </div>
            <div>
              {draftLocation?.pathname.includes('/draft-form') ||
              !stageStatus?.active ? (
                <Tag color="gold" className=" mb-10">
                  Draft Version
                </Tag>
              ) : (
                <Tag color="blue" className="mb-10">
                  Published Version
                </Tag>
              )}
            </div>
          </Col>
          <Col className="d-flex" span={7}>
            <Button
              className="bordered-button mr-10"
              onClick={() => {
                dispatch(setPreviewFlag(true));
                dispatch(setBackButtonClicked(true));
                history.push({
                  pathname: '/stagepreview',
                  state: {
                    source: draftLocation.pathname.includes('/draft-form')
                      ? 'draft-form'
                      : 'detail',
                  },
                });
              }}
            >
              Preview
            </Button>

            {!stageStatus?.active ? (
              <Button
                className="bordered-button mr-10"
                onClick={draftForm}
                loading={draftLoading}
                size="middle"
              >
                Save as Draft
              </Button>
            ) : (
              <SaveDraft
                updatedFormElement={updatedFormElement}
                getStageNameElement={getStageNameElement}
                fetchPublishedForm={fetchPublishedForm}
              />
            )}
            <Button
              className="blue-filled-btn"
              onClick={() =>
                stageStatus?.active
                  ? handleConfirmationModalOpen()
                  : updateStageForm()
              }
              disabled={formLoading}
            >
              {stageStatus?.active &&
              draftLocation.pathname.includes('/draft-form')
                ? 'Publish'
                : stageStatus?.active
                ? 'Update'
                : 'Publish'}
            </Button>
          </Col>
        </Row>

        <Row className="mt-20" justify="space-between" align="top" gutter={10}>
          <Col span={16} className="scrollable">
            <div>
              <Droppable droppableId="list2">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    <Form
                      layout="vertical"
                      form={form}
                      requiredMark={false}
                      onValuesChange={handleFormChange}
                    >
                      {formLoading ? (
                        <StageDetailPageSkeleton />
                      ) : (
                        <StageDetailForm
                          updatedFormElement={updatedFormElement}
                          setSelectedElement={setSelectedElement}
                          inputElementClickHandler={inputElementClickHandler}
                          getClickedElementData={getClickedElementData}
                          stageLevel={stageLevel}
                          mfList={mfList}
                          id={id}
                          setMFList={setMFList}
                        />
                      )}
                    </Form>
                  </div>
                )}
              </Droppable>
            </div>
          </Col>
          <Col span={1} className="stage-divider">
            <Divider type="vertical" style={{ height: '100vh' }} />
          </Col>
          <Col span={7}>
            {selectedElement?.inputType === 'singleLine' ||
            selectedElement?.inputType === 'multiLine' ||
            selectedElement?.inputType === 'dropdown' ||
            selectedElement?.inputType === 'datepicker' ||
            selectedElement?.inputType === 'link' ||
            selectedElement?.inputType === 'symbol' ||
            selectedElement?.inputType === 'dragger' ? (
              <MultiLineInput
                selectedElement={selectedElement}
                formBody={formBody}
                setFormBody={setFormBody}
                setSelectedElement={setSelectedElement}
                setIsFormChanged={setIsFormChanged}
                stageLevel={stageLevel}
                copyofPublishedForm={copyofPublishedForm}
                getStageNameElement={getStageNameElement}
              />
            ) : (
              <Droppable droppableId="list1" className="m-0 form-drag">
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{ margin: '10px', position: 'relative' }}
                  >
                    <CommonFormElement
                      handleDragStart={handleDragStart}
                      updatedFormElement={updatedFormElement}
                      orgId={orgId}
                      mfList={mfList}
                    />
                  </div>
                )}
              </Droppable>
            )}
          </Col>
        </Row>

        <EditStageName
          IseditNameModalOpen={IseditNameModalOpen}
          setIsEditNameModalOpen={setIsEditNameModalOpen}
          currentStage={currentStage}
          getFormLayout={getFormLayout}
        />
        <PublishModal
          isPublishModalOpen={isPublishModalOpen}
          setIsPublishModalOpen={setIsPublishModalOpen}
          stageStatus={stageStatus}
        />
        <ConfirmationModal
          confirmationModal={confirmationModal}
          setConfirmationModal={setConfirmationModal}
          updateStageForm={updateStageForm}
          loading={loading}
        />
        <LeavePageModal
          isConfirmationVisible={isConfirmationVisible}
          setIsConfirmationVisible={setIsConfirmationVisible}
          updatedFormElement={updatedFormElement}
          getStageNameElement={getStageNameElement}
          orgId={orgId}
        />
        {/* <StagePreview mfList={mfList} /> */}
      </DragDropContext>
    </div>
  );
};

export default memo(StageDetail);

