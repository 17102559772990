import { message } from 'antd';
import { ECHOIMAGES } from 'Assets/Images';
import CustomDeleteModal from 'Common/CustomDeleteModal';
import React, { memo, useState } from 'react';
import { RESTDeleteGoal } from 'utils/RESTApi';

const DeleteGoalModal = ({
  setDeleteModal,
  deleteModal,
  goalID,
  listGoals,
  orgId,
}) => {
  const [loading, setLoading] = useState(false);

  const handleCancelModal = () => {
    setDeleteModal(false);
  };
  const { deleteModalIcon } = ECHOIMAGES.admin;

  const deleteObjective = async (id) => {
    setLoading(true);
    try {
      let input = {
        objective_id: goalID,
        org_id: orgId,
      };
      await RESTDeleteGoal(input);
      message.success('Goal deleted successfully');
      handleCancelModal();
      await listGoals();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  return (
    <CustomDeleteModal
      open={deleteModal}
      onCancel={handleCancelModal}
      onOk={deleteObjective}
      confirmLoading={loading}
      title="Do you really want to delete this goal?"
    >
      <img src={deleteModalIcon} alt="" />
    </CustomDeleteModal>
  );
};

export default memo(DeleteGoalModal);

