import { ECHOIMAGES } from 'Assets/Images';
import CustomDeleteModal from 'Common/CustomDeleteModal';
import React, { memo } from 'react';

const ArchivedGoalModal = ({
  archiveModalOpen,
  setArchiveModalOpen,
  archiveObjectives,
  loader,
}) => {
  const { ArchivedIcon } = ECHOIMAGES.icons;

  const handleCancelModal = () => {
    setArchiveModalOpen(false);
  };
  return (
    <CustomDeleteModal
      open={archiveModalOpen}
      onCancel={handleCancelModal}
      onOk={archiveObjectives}
      confirmLoading={loader}
      okText="Archive Goal"
      cancelText="Cancel"
      title="Are you sure that you want to archive this goal? "
    >
      <img src={ArchivedIcon} alt="" />
    </CustomDeleteModal>
  );
};

export default memo(ArchivedGoalModal);

