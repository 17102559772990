import { message, Modal, Row, Typography } from 'antd';
import React, { useState } from 'react';
import { updateOrganization } from '../services/SuperAdminActions';

const AssignPartnerConfirmModal = ({
  setIsConfirmModalOpen,
  isConfirmModalOpen,
  selectedPartnerID,
  assignPartnerData,
  fetchOrganizations,
  setCurrentPage,
}) => {
  const [loading, setLoading] = useState(false);

  const handleCancel = () => {
    setIsConfirmModalOpen(false);
  };

  //function to assign partner
  const assignPartner = async () => {
    setLoading(true);
    try {
      let inputData = {
        id: assignPartnerData?.id,
        partnerID: selectedPartnerID,
      };
      await updateOrganization(inputData);
      setLoading(false);
      setIsConfirmModalOpen(false);
      setCurrentPage(1);
      setTimeout(async () => {
        message.success('Partner assigned successfully');
        await fetchOrganizations(null, null, '', 1);
      }, 1000);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  return (
    <Modal
      open={isConfirmModalOpen}
      onCancel={handleCancel}
      centered
      className="delete-user-modal"
      onOk={assignPartner}
      confirmLoading={loading}
    >
      <Row justify="center">
        <Typography.Title level={4} className="fw-500 text-center">
          Are you sure you want to assign?
        </Typography.Title>
      </Row>
    </Modal>
  );
};

export default AssignPartnerConfirmModal;

