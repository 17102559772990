import React, { memo, useEffect, useState } from 'react';
import {
  CloseSquareOutlined,
  DownOutlined,
  UploadOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  Row,
  Typography,
  Collapse,
  Form,
  Input,
  Select,
  Upload,
  Tag,
  Tooltip,
  DatePicker,
  Slider,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import {
  getformElementUpdate,
  getStageName,
  setBackButtonClicked,
  setPreviewFlag,
} from 'store/slices/StageSlice';
import { useHistory, useLocation } from 'react-router-dom';
import { RESTGet } from 'utils/RESTApi';
import {
  getCurrentOrganization,
  getCurrnetUserData,
} from 'store/slices/loginSlice';

const StagePreview = () => {
  const { Panel } = Collapse;
  const { TextArea } = Input;
  const { Dragger } = Upload;
  const [activeCollapse, setActiveCollapse] = useState(false);
  const updatedFormElement = useSelector(getformElementUpdate);
  const getStageNameElement = useSelector(getStageName);
  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;
  const location = useLocation();
  const history = useHistory();
  const source = location?.state?.source;
  const dispatch = useDispatch();
  const goBack = () => {
    if (source === 'list') {
      history.push('/stages');
    } else if (source === 'detail') {
      history.push({
        pathname: `/stageDetail/${getStageNameElement?.id}`,
        state: {
          prevForm: getStageNameElement,
        },
      });
    } else {
      history.push({
        pathname: `/draft-form/${getStageNameElement?.id}`,
        state: {
          prevForm: getStageNameElement,
        },
      });
    }
    dispatch(setBackButtonClicked(false));
    dispatch(setPreviewFlag(false));
  };

  const [mfList, setMFList] = useState([]);

  const getMFList = async () => {
    try {
      let listApi = `/measurement/all?organization_id=${orgId}`;
      let res = await RESTGet(listApi);
      setMFList(res?.body?.items);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getMFList();
    // eslint-disable-next-line
  }, []);

  const secondArrayMap = mfList.reduce((acc, obj) => {
    acc[obj.id] = obj;
    return acc;
  }, {});
  // Retrieve details from second array for matching ids in first array
  const details = updatedFormElement.map(
    (item) => secondArrayMap[item.id] || item
  );
  const mergedFormElements = updatedFormElement.map((item) => ({
    ...item,
    ...secondArrayMap[item.id],
  }));

  const marks = {};

  mergedFormElements?.forEach((firstItem) => {
    const matchingItem = mfList?.find(
      (secondItem) => secondItem.id === firstItem.id
    );

    if (
      matchingItem &&
      matchingItem.breakpoints &&
      matchingItem.breakpoints.length > 0
    ) {
      marks[firstItem.id] = {
        0: matchingItem?.breakpoints[0]?.label, // Label at 0
        25: matchingItem?.breakpoints[1]?.label, // Label at 25
        50: matchingItem?.breakpoints[2]?.label, // Label at 50
        75: matchingItem?.breakpoints[3]?.label, // Label at 75
        100: matchingItem?.breakpoints[4]?.label, // Label at 100
      };
    } else {
      marks[firstItem.id] = {
        0: 'Label 0',
        25: 'Label 25',
        50: 'Label 50',
        75: 'Label 75',
        100: 'Label 100',
      };
    }
  });

  const marks1 = {};
  // const numericMarks = () => {
  mergedFormElements?.forEach((firstItem) => {
    const matchingItem = mfList?.find(
      (secondItem) => secondItem.id === firstItem.id
    );

    marks1[firstItem.id] = {
      0: matchingItem?.breakpoints[0]?.value, // Label at 0
      25: matchingItem?.breakpoints[0]?.value,
      50: matchingItem?.breakpoints[0]?.value,
      75: matchingItem?.breakpoints[0]?.value,
      100: matchingItem?.breakpoints[1]?.value, // Label at 100
    };
  });
  return (
    <>
      <Typography.Title level={5} className="cross-icon">
        <CloseSquareOutlined className="fs-30" onClick={goBack} />
      </Typography.Title>
      <Row className="w-100">
        <Col span={24}>
          <div className="opportunity-detail">
            <>
              <Row align="middle" justify="space-between">
                <Typography.Title level={4} className="title fs-24">
                  Title
                </Typography.Title>
              </Row>

              <Typography.Title level={5} className="mt-20 text fs-16">
                Description goes here...
              </Typography.Title>

              <Row className="details" gutter={[0, 20]}>
                <Col span={12} md={12} sm={24} xs={24}>
                  <Typography.Title level={5} className="mb-0">
                    <span>Rank : </span>-
                  </Typography.Title>
                  <Typography.Title level={5} className="mb-0">
                    <span>ID : </span> -
                  </Typography.Title>
                  <Typography.Title level={5} className="mb-0">
                    <span>Item created date: </span> -
                  </Typography.Title>
                </Col>
                <Col span={12} md={12} sm={24} xs={24}>
                  <Typography.Title level={5} className="mb-0">
                    <span>Type: </span>{' '}
                    <Tag className="opportunity capitalize-text tag-font">
                      {getStageNameElement?.name}
                    </Tag>{' '}
                  </Typography.Title>
                  <Typography.Title level={5} className="mb-0">
                    <span>Department : </span>-
                  </Typography.Title>
                </Col>
              </Row>
            </>

            <Collapse
              expandIconPosition="end"
              className="idea"
              key="collapse"
              defaultActiveKey={['1']}
              expandIcon={({ isActive }) => (
                // eslint-disable-next-line
                setActiveCollapse(isActive),
                (<DownOutlined className="fs-16" rotate={isActive ? 180 : 0} />)
              )}
            >
              <Panel
                header={activeCollapse ? 'View Less' : 'View more'}
                key="1"
              >
                <Row align="middle" justify="space-between">
                  <Typography.Title level={4} className="title fw-500 fs-18">
                    More Details
                  </Typography.Title>
                </Row>

                <Form
                  layout="vertical"
                  className="mt-20 opportunity-form"
                  requiredMark={true}
                >
                  <Row gutter={20}>
                    <></>
                    {mergedFormElements.map((item) => {
                      return (
                        <>
                          {item.inputType === 'singleLine' && !item?.hide && (
                            <Col span={12} md={12} sm={24} xs={24}>
                              <Form.Item
                                name="item"
                                label={
                                  <Row>
                                    <Typography.Title
                                      level={5}
                                      className="mb-0"
                                    >
                                      {item.label}
                                    </Typography.Title>
                                    {item.tooltip && (
                                      <Tooltip title={item.tooltip}>
                                        <InfoCircleOutlined className="tooltip-icon ml-5" />
                                      </Tooltip>
                                    )}
                                  </Row>
                                }
                                rules={[
                                  {
                                    required: item.required,
                                    message: `Please Enter ${item?.label}!`,
                                  },
                                ]}
                              >
                                <Input
                                  className="input-font"
                                  placeholder={item.placeholder}
                                  readOnly
                                />
                              </Form.Item>
                            </Col>
                          )}
                          {item.inputType === 'multiLine' &&
                            !item?.hide &&
                            (item.id ===
                            process.env.REACT_APP_DESCRIPTION_ID ? (
                              <Col span={24} md={24} sm={24} xs={24}>
                                <Form.Item
                                  name="item"
                                  label={
                                    <Row>
                                      <Typography.Title
                                        level={5}
                                        className="mb-0"
                                      >
                                        {item.label}
                                      </Typography.Title>
                                      {item.tooltip && (
                                        <Tooltip title={item.tooltip}>
                                          <InfoCircleOutlined className="tooltip-icon ml-5" />
                                        </Tooltip>
                                      )}
                                    </Row>
                                  }
                                  rules={[
                                    {
                                      required: item.required,
                                      message: `Please Enter ${item?.label}!`,
                                    },
                                  ]}
                                >
                                  <TextArea
                                    autoSize={{
                                      minRows: 5,
                                      maxRows: 5,
                                    }}
                                    className="input-font"
                                    placeholder={item.placeholder}
                                    readOnly
                                  />
                                </Form.Item>
                              </Col>
                            ) : (
                              <Col span={12} md={12} sm={24} xs={24}>
                                <Form.Item
                                  name="item"
                                  rules={[
                                    {
                                      required: item.required,
                                      message: `Please Enter ${item?.label}!`,
                                    },
                                  ]}
                                  label={
                                    <Row>
                                      <Typography.Title
                                        level={5}
                                        className="mb-0"
                                      >
                                        {item.label}
                                      </Typography.Title>
                                      {item?.maxChars !== '' &&
                                        item?.maxChars !== undefined && (
                                          <span className="text-gray">
                                            (Max. {item?.maxChars} limit)
                                          </span>
                                        )}
                                      {item.tooltip && (
                                        <Tooltip title={item.tooltip}>
                                          <InfoCircleOutlined className="tooltip-icon ml-5" />
                                        </Tooltip>
                                      )}
                                    </Row>
                                  }
                                >
                                  <TextArea
                                    autoSize={{
                                      minRows: 5,
                                      maxRows: 5,
                                    }}
                                    className="input-font"
                                    placeholder={item.placeholder}
                                    readOnly
                                  />
                                </Form.Item>
                              </Col>
                            ))}

                          {item.inputType === 'dropdown' && !item?.hide && (
                            <Col span={12} md={12} sm={24} xs={24}>
                              <Form.Item
                                label={
                                  <Row>
                                    <Typography.Title
                                      level={5}
                                      className="mb-0"
                                    >
                                      {item.label}
                                    </Typography.Title>
                                    {item.tooltip && (
                                      <Tooltip title={item.tooltip}>
                                        <InfoCircleOutlined className="tooltip-icon ml-5" />
                                      </Tooltip>
                                    )}
                                  </Row>
                                }
                                name="status"
                                rules={[
                                  {
                                    required: item.required,
                                    message: `Please Enter ${item?.label}!`,
                                  },
                                ]}
                              >
                                <Select
                                  className="input-font"
                                  placeholder={item.placeholder}
                                ></Select>
                              </Form.Item>
                            </Col>
                          )}

                          {item.inputType === 'related items' &&
                            !item?.hide && (
                              <Col span={24} md={24} sm={24} xs={24}>
                                <div className="details mb-20 mt-0 p">
                                  <Typography.Title level={5} className="title">
                                    Please link any other items you believe are
                                    related to this one.{' '}
                                  </Typography.Title>
                                  <Button
                                    type="primary"
                                    className="orange-btn fw-bold mt-20"
                                  >
                                    Add Related Items
                                  </Button>
                                </div>
                              </Col>
                            )}

                          {item.inputType === 'datepicker' && !item?.hide && (
                            <Col span={12} md={12} sm={24} xs={24}>
                              <Form.Item
                                name="item"
                                label={
                                  <Row>
                                    <Typography.Title
                                      level={5}
                                      className="mb-0"
                                    >
                                      {item.label}
                                    </Typography.Title>
                                    {item.tooltip && (
                                      <Tooltip title={item.tooltip}>
                                        <InfoCircleOutlined className="tooltip-icon ml-5" />
                                      </Tooltip>
                                    )}
                                  </Row>
                                }
                                rules={[
                                  {
                                    required: item.required,
                                    message: `Please Enter ${item?.label}!`,
                                  },
                                ]}
                              >
                                <DatePicker className="w-100" />
                              </Form.Item>
                            </Col>
                          )}
                          {item.inputType === 'symbol' && !item?.hide && (
                            <Col span={12} md={12} sm={24} xs={24}>
                              <Form.Item
                                name="item"
                                label={
                                  <Row>
                                    <Typography.Title
                                      level={5}
                                      className="mb-0"
                                    >
                                      {item.label}
                                    </Typography.Title>
                                    {item.tooltip && (
                                      <Tooltip title={item.tooltip}>
                                        <InfoCircleOutlined className="tooltip-icon ml-5" />
                                      </Tooltip>
                                    )}
                                  </Row>
                                }
                                rules={[
                                  {
                                    required: item.required,
                                    message: `Please Enter ${item?.label}!`,
                                  },
                                ]}
                              >
                                <Select
                                  className="input-font"
                                  placeholder={item.placeholder}
                                ></Select>
                              </Form.Item>
                            </Col>
                          )}

                          {item.inputType === 'dragger' && !item?.hide && (
                            <Col span={24} md={24} sm={24} xs={24}>
                              <Row className="upload-area mb-20 mt-0">
                                <Col span={24} className="mb-20">
                                  <Typography.Title level={5} className="p">
                                    {item?.label}
                                  </Typography.Title>
                                  <Dragger maxCount={5}>
                                    <p className="ant-upload-drag-icon">
                                      <UploadOutlined />
                                    </p>
                                    <p className="ant-upload-text">
                                      Drag and drop files, or{' '}
                                      <span className="text-primary">
                                        Browse
                                      </span>
                                    </p>
                                    <p className="ant-upload-hint">
                                      Supported format: Word, Excel, PPT, Google
                                      Sheets with max size of 5 MB. Max 5 files
                                      can be upload
                                    </p>
                                  </Dragger>
                                </Col>
                              </Row>
                            </Col>
                          )}
                          {item.inputType === 'link' && !item?.hide && (
                            <Col
                              span={24}
                              md={24}
                              sm={24}
                              xs={24}
                              className="mt-10"
                            >
                              <Row className="upload-area mb-20 mt-0">
                                <Col span={24}>
                                  <Row
                                    className="mt-20 attach-link"
                                    gutter={[10, 10]}
                                  >
                                    <Col
                                      span={21}
                                      lg={21}
                                      md={20}
                                      xs={24}
                                      sm={24}
                                    >
                                      <Form.Item
                                        label={
                                          <span className="p">
                                            {item?.label}
                                          </span>
                                        }
                                      >
                                        <Input placeholder="Paste document link here" />
                                      </Form.Item>
                                    </Col>
                                    <Col span={3} lg={3} md={4} xs={24} sm={24}>
                                      <Form.Item label="" className="mb-0">
                                        <Button
                                          type="primary"
                                          className="blue-filled-btn"
                                          size="middle"
                                        >
                                          Attach
                                        </Button>
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Col>
                          )}

                          {(item?.subType === '+-' || item?.subType === '-+') &&
                            !item?.hide && (
                              <>
                                <Typography.Text>{item?.name}</Typography.Text>
                                <Col
                                  className="p-0  mb-10 stage-container-slider"
                                  span={24}
                                >
                                  <Slider
                                    key={item.id}
                                    className={
                                      item?.subType === '+-'
                                        ? 'stage-slider slider-change-1 m-0'
                                        : 'stage-slider slider-change m-0'
                                    }
                                    value={50}
                                    marks={marks[item?.id]}
                                    disabled
                                  />
                                </Col>
                              </>
                            )}
                          {(item?.subType === '$' || item?.subType === '#') &&
                            !item?.hide && (
                              <>
                                <Typography.Text>{item?.name}</Typography.Text>
                                <Col className="mb-10 preview-slider" span={24}>
                                  <div className="measurement-slider">
                                    <Slider
                                      tooltip={{ open: true }}
                                      // step={null}
                                      className="numeric-slider mt-45"
                                      marks={marks1[item?.id]}
                                      tipFormatter={(value) => {
                                        const prefix =
                                          item?.subType === '$' ? '$' : '#';
                                        return `${prefix}${
                                          marks1[item?.id][0]
                                        }`;
                                      }}
                                      disabled
                                    />
                                  </div>
                                </Col>
                              </>
                            )}
                        </>
                      );
                    })}

                    {/* if no related ietms added  */}
                  </Row>
                  <Form.Item>
                    <Button
                      type="primary"
                      className="blue-filled-btn mr-10"
                      size="middle"
                    >
                      Save
                    </Button>
                    <Button size="middle">Cancel</Button>
                  </Form.Item>
                </Form>
              </Panel>
            </Collapse>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default memo(StagePreview);

