import React, { memo } from 'react';
import { Button, Dropdown } from 'antd';
import { ExportOutlined } from '@ant-design/icons';
import '../../../Common/custom.less';

const ExportItemsButton = ({ fetchGammas, exportLoading, setCurrentPage }) => {
  const items = [
    {
      label: 'Rankings only',
      key: '1',
      onClick: () =>
        fetchGammas(
          null,
          null,
          null,
          1,
          null,
          '',
          null,
          null,
          null,
          null,
          'rankings'
        ),
    },
    {
      label: (
        <span>
          Rankings with user votes <br /> and user ratings
        </span>
      ),
      key: '2',
      onClick: () =>
        fetchGammas(
          null,
          null,
          null,
          1,
          null,
          '',
          null,
          null,
          null,
          null,
          null,
          'all'
        ),
    },
  ];

  return (
    <>
      <Dropdown
        menu={{
          items,
        }}
        placement="bottomLeft"
      >
        <Button
          className="ml-10 export-btn border-color typography-color fw-400"
          loading={exportLoading}
        >
          <ExportOutlined className="export-icons" />{' '}
        </Button>
      </Dropdown>
    </>
  );
};
export default memo(ExportItemsButton);

