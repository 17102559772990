import { message } from 'antd';
import { API, graphqlOperation } from 'aws-amplify';
import {
  listAllGammaTypeRolesCustom,
  listUserRolesCustom,
} from '../../graphql/customQueris';
import {
  listOrganizations,
  listUsers,
  deleteUser,
  updateUser,
  usersByOrganizationIDCustom,
  listUsersCustom,
} from '../../utils/Actions';

export const getOrganizations = async () => {
  try {
    const organizationsDetails = await listOrganizations();
    return organizationsDetails;
  } catch (error) {
    message.error(error.message);
    return error;
  }
};

export const getUserDetails = async (filter, nextToken, limit) => {
  try {
    const userDetails = await listUsers(filter, nextToken, limit);
    return userDetails;
  } catch (error) {
    message.error(error.message);
    return error;
  }
};

export const getUsersList = async (filter) => {
  try {
    const userDetails = await listUsersCustom(filter);
    return userDetails;
  } catch (error) {
    message.error(error.message);
    return error;
  }
};

export const deleteUserData = async (id) => {
  try {
    const userDelete = await deleteUser(id);
    return userDelete;
  } catch (error) {
    message.error(error.message);
  }
};

export const listUserRoles = async () => {
  try {
    const result = await API.graphql(graphqlOperation(listUserRolesCustom));
    return result;
  } catch (error) {
    message.error(error.message);
    return error;
  }
};

export const updateRole = async (data) => {
  try {
    const result = await updateUser(data);
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const getUsersByOrganizationIDCustom = async (filter, id, nextToken) => {
  try {
    let userDetails = await usersByOrganizationIDCustom(filter, id, nextToken);
    return userDetails;
  } catch (error) {
    message.error(error.message);
    return error;
  }
};

export const listUserGammaType = async () => {
  try {
    const result = await API.graphql(
      graphqlOperation(listAllGammaTypeRolesCustom)
    );
    return result;
  } catch (error) {
    message.error(error.message);
    return error;
  }
};

