import { Modal, Form, Row, Col, Typography, Input, message } from 'antd';
import React, { useState } from 'react';
import { RESTMergeDepartment } from 'utils/RESTApi';

const MergeDepartment = ({
  selectedRowKeys,
  mergeDepartmentOpen,
  setMergeDepartmentOpen,
  orgId,
  listDepartments,
  setSelectedRowKeys,
}) => {
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();
  const handleCancelModal = () => {
    setMergeDepartmentOpen(false);
  };

  const mergeDepartments = async (e) => {
    try {
      if (!form.getFieldValue().DepartmentName) {
        form.setFields([
          {
            name: 'DepartmentName',
            errors: ['Please enter department name'],
          },
        ]);
        return false;
      }
      setLoading(true);
      let input = {
        departments: selectedRowKeys,
        new_dep_name: form.getFieldValue().DepartmentName,
        org_id: orgId,
      };
      await RESTMergeDepartment(input);
      await listDepartments();
      setLoading(false);
      setMergeDepartmentOpen(false);
      setSelectedRowKeys([]);
      form.setFieldsValue({ DepartmentName: '' });
      message.success('Departments merged successfully');
    } catch (err) {
      setLoading(false);
      console.log(err);
      throw err;
    }
  };
  return (
    <Modal
      open={mergeDepartmentOpen}
      onOk={mergeDepartments}
      onCancel={handleCancelModal}
      centered
      okText="Save"
      className="delete-user-modal add-department"
      confirmLoading={loading}
    >
      <Row>
        <Col span={24}>
          <Typography.Title level={3}>Add New Department Name</Typography.Title>
        </Col>
        <Col span={24}>
          <Form
            layout="vertical"
            requiredMark={false}
            className="mt-10"
            form={form}
            onFinish={mergeDepartments}
          >
            <Form.Item
              name="DepartmentName"
              label={<span>Department Name</span>}
              rules={[
                {
                  required: true,
                  message: 'Please enter Department Name',
                },
              ]}
            >
              <Input placeholder="Please enter Department Name" />
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

export default MergeDepartment;

