import { message } from 'antd';
import {
  createObjectiveCustom,
  deleteObjectiveCustom,
  getOrganizationCustom,
  listObjectiveCustom,
  updateGoal,
  updateObjectiveCustom,
} from '../../utils/Actions';

export const createObjective = async (data) => {
  try {
    const addGoal = await createObjectiveCustom(data);
    return addGoal;
  } catch (error) {
    message.error(error.message);
  }
};
export const listObjective = async (id) => {
  try {
    const listOrgGoal = await listObjectiveCustom(id);
    return listOrgGoal;
  } catch (error) {
    message.error(error.message);
  }
};
export const updateObjective = async (data) => {
  try {
    const updateGoal = await updateObjectiveCustom(data);
    return updateGoal;
  } catch (error) {
    message.error(error.message);
  }
};
export const getGoals = async (id) => {
  try {
    const goalList = await getOrganizationCustom(id);
    return goalList;
  } catch (error) {
    message.error(error.message);
  }
};
export const deleteGoals = async (id) => {
  try {
    const goalList = await deleteObjectiveCustom(id);
    return goalList;
  } catch (error) {
    message.error(error.message);
  }
};
export const archiveGoal = async (data) => {
  try {
    const archiveGoals = await updateGoal(data);
    return archiveGoals;
  } catch (error) {
    message.error(error.message);
  }
};
