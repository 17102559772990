import { Grid, Select, Space } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import React, { memo } from 'react';
import { ECHOIMAGES } from 'Assets/Images';
import Typography from 'antd/es/typography/Typography';

const PreviousRanking = ({ setSelectedOption, selectedOption }) => {
  const { PreviousIcon } = ECHOIMAGES.ideaIcons;
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const handleChange = (value) => {
    setSelectedOption(value);
  };
  return (
    <>
      <Typography.Text className="ml-10">Previous by</Typography.Text>
      <Select
        value={selectedOption}
        onChange={handleChange}
        placeholder="Select"
        className="sorting-common h-35 ml-10 previous-step"
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
        options={[
          {
            value: 'Recent',
            label: <Space>Recent</Space>,
          },
          {
            value: 'Week',
            label: <Space>Week</Space>,
          },
          {
            value: 'Month',
            label: <Space>Month</Space>,
          },
          {
            value: 'Quarter',
            label: <Space>Quarter</Space>,
          },
        ]}
      />
    </>
  );
};

export default memo(PreviousRanking);

