import {
  Avatar,
  Button,
  Col,
  Divider,
  Modal,
  Row,
  Typography,
  Form,
  Input,
  message,
  Skeleton,
  Grid,
  Upload,
  Image,
} from 'antd';
import { ECHOIMAGES } from 'Assets/Images';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Impact from './Impact';
import './profile.less';
import { FetchUser } from 'Pages/Idea/IdeaFunction';
import { useSelector, useDispatch } from 'react-redux';
import {
  getCurrentOrganization,
  getCurrnetUserData,
  isAdmin,
  isSME,
  setProfilePic,
} from 'store/slices/loginSlice';
import moment from 'moment';
import { options, UserRole } from 'utils/Constants';
import { Auth } from 'aws-amplify';

import {
  fetchOrganizationStages,
  getComparisonCount,
  getGammasCount,
  getRatingsCount,
} from 'utils/CommonFunctions';
import { GetFileFromS3, SaveFileToS3 } from 'utils/commonMethods';
import { updateRole } from 'Pages/SuperAdmin/SuperAdminFun';

export default function Profile() {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const history = useHistory();
  const { PasswordChanged } = ECHOIMAGES.Auth;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;
  const userId = currnetOrg?.adminID || loginUsrOrg?.id;
  const [impactData, setImpactData] = useState({});
  const [projectCount, setProjectCount] = useState('');
  const [gammaCount, setGammaCount] = useState('');
  const [opportunityCount, setOpportunityCount] = useState('');
  const [comparisonCompleted, setComparisonCompletedCount] = useState('');
  const [ratingsGiven, setRatingGiven] = useState('');
  const isAdminGroup = useSelector(isAdmin);
  const isSMEGroup = useSelector(isSME);
  const [form] = Form.useForm();
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageLoader, setImageLoader] = useState(false);

  const dispatch = useDispatch();

  const getUserDetails = async () => {
    try {
      setLoading(true);
      const userDetails = await FetchUser({ id: userId });
      if (userDetails?.photo) {
        setProfilePhoto(userDetails?.photo);
        await getSignedUrlImg(userDetails?.photo);
      }
      setImpactData(userDetails);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const handleSubmit = async () => {
    let data = form.getFieldsValue();
    if (
      !form.getFieldValue().currentPassword &&
      !form.getFieldValue().newPassword &&
      !form.getFieldValue().confirmNewPassword
    ) {
      form.setFields([
        {
          name: 'currentPassword',
          errors: ['Please enter old password'],
        },
      ]);
      form.setFields([
        {
          name: 'newPassword',
          errors: ['Please enter new password'],
        },
      ]);
      form.setFields([
        {
          name: 'confirmNewPassword',
          errors: ['Please enter confirm new password'],
        },
      ]);
      return false;
    }
    try {
      setLoader(true);
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const { confirmNewPassword, currentPassword, newPassword } = data;
      if (confirmNewPassword !== newPassword) {
        throw new Error('Confirm New Password does not match');
      }
      await Auth.changePassword(cognitoUser, currentPassword, newPassword);
      form.setFieldsValue({
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
      });
      message.success('Password update successfully!');
      success();
      message.info('Login with new password');
      setTimeout(() => {
        localStorage.clear();
        window.location.reload();
      }, 5000);
    } catch (error) {
      console.log(error);
      setLoader(false);
      if (error?.message === 'Incorrect username or password.') {
        error.message = 'Current Password is incorrect';
      }
      message.error(error.message);
    } finally {
      setLoader(false);
    }
  };

  const [allStages, setAllStages] = useState([]);

  const getStageName = async () => {
    try {
      const sortedStages = await fetchOrganizationStages(orgId);
      setAllStages(sortedStages);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchGammasCount = async () => {
    const aggregates = [{ field: 'levelID', type: 'terms', name: ' ' }];
    const { submittedIdea, opportunityCount, projectCount } =
      await getGammasCount(userId, allStages, null, null, aggregates);
    setGammaCount(submittedIdea);
    setOpportunityCount(opportunityCount);
    setProjectCount(projectCount);
  };

  const fetchComparisonCount = async () => {
    const { comparisonCount } = await getComparisonCount(userId);
    setComparisonCompletedCount(comparisonCount);
  };

  const fetchRatingsCount = async () => {
    const { ratingsCount } = await getRatingsCount(userId);
    setRatingGiven(ratingsCount);
  };
  useEffect(() => {
    getUserDetails();

    fetchComparisonCount();
    fetchRatingsCount();
    getStageName();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchGammasCount();
    // eslint-disable-next-line
  }, [allStages]);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [ProfileLogo, setProfileLogo] = useState();
  const [profilePhoto, setProfilePhoto] = useState();

  const getSignedUrlImg = async (fileKey) => {
    const signedURL = await GetFileFromS3(fileKey, {
      level: 'public',
    });
    setProfileLogo(signedURL);
    dispatch(setProfilePic(signedURL));
  };

  const props = {
    name: 'file',
    accept: 'image/*',

    async beforeUpload(file) {
      const isJpgOrPng =
        file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/jpg' ||
        file.type === 'image/gif';
      setImageLoader(true);
      const uploadData = await SaveFileToS3(file);
      setProfilePhoto(uploadData?.key);
      await getSignedUrlImg(uploadData?.key);
      let inputData = {
        id: userId,
        photo: uploadData?.key,
      };
      await updateRole(inputData);
      setImageLoader(false);
      return false && isJpgOrPng;
    },
  };

  const success = () => {
    setIsModalOpen(false);
    Modal.success({
      content: (
        <Row justify="center" align="middle">
          <Col span={24} className="password-changed">
            <img src={PasswordChanged} alt="" />
            <Typography.Title level={4} className="mt-10 mb-5 fw-600">
              Password Changed
            </Typography.Title>
            <Typography.Title level={5} className="mt-0 fs-15 text-gray">
              Your password has been changed successfully.
            </Typography.Title>
          </Col>
        </Row>
      ),
      okText: 'Close',
      icon: <></>,
      centered: true,
      width: 500,
    });
  };

  return (
    <>
      <Impact
        impactData={impactData}
        projectCount={projectCount}
        opportunityCount={opportunityCount}
        gammaCount={gammaCount}
        comparisonCompleted={comparisonCompleted}
        ratingsGiven={ratingsGiven}
      />
      <div className="background-white p-30 mt-30">
        {loading ? (
          <>
            <Skeleton
              active
              paragraph={{
                rows: 3,
              }}
              className="mt-10"
            />
            <Skeleton
              active
              paragraph={{
                rows: 3,
              }}
              className="mt-10"
            />{' '}
          </>
        ) : (
          <Row className="pt-20 pb-20">
            <Col span={7} md={7} sm={24} xs={24} className="profile-detail">
              {ProfileLogo ? (
                <Image className="profile-img-preview" src={ProfileLogo} />
              ) : (
                <Avatar
                  size={80}
                  style={{
                    backgroundColor: '#FFEBC594',
                  }}
                >
                  <span className="text-blue fw-bold">
                    {impactData?.firstName?.[0]?.toUpperCase()}
                    {impactData?.lastName?.[0]?.toUpperCase()}
                  </span>
                </Avatar>
              )}
              <Upload {...props} className="profile-upload">
                <Button
                  type="dashed"
                  className="text-primary fw-bold fs-16 h-100 mb-0 mt-10 "
                  loading={imageLoader}
                  size="middle"
                >
                  Upload
                </Button>
              </Upload>
              {/* <Button
                  type="text"
                  className="fw-bold fs-16"
                  // onClick={removeProfilePic}
                >
                  Remove
                </Button> */}

              <Typography.Title
                level={4}
                className="mt-10 mb-0 capitalize-text"
              >
                {impactData?.firstName + ' ' + impactData?.lastName}
              </Typography.Title>
              <Typography.Title level={5} className="mt-0 text-gray">
                {impactData?.role === UserRole.sme
                  ? UserRole?.teamMember
                  : impactData?.role}
              </Typography.Title>

              <Typography.Title level={5} className="text-gray  mb-20 fs-14">
                Last Login :
                {impactData?.lastLogin
                  ? new Date(impactData?.lastLogin).toLocaleString(
                      'en-US',
                      options
                    )
                  : '-'}
              </Typography.Title>

              <Button
                onClick={showModal}
                className="fw-bold mb-20"
                size="middle"
              >
                Change Password
              </Button>
            </Col>
            <Col span={1}>
              <Divider type="vertical" className="h-100" />
            </Col>
            <Col span={16} md={16} sm={24} xs={24}>
              {(isAdminGroup || currnetOrg?.id) && (
                <Button
                  type="primary"
                  className="blue-filled-btn profile-edit "
                  size="middle"
                  onClick={() => history.push('/edit-profile')}
                >
                  Edit
                </Button>
              )}
              <Row className="head-data profile-data h-100" align="top">
                <Col span={12} md={12} sm={12} xs={24}>
                  <div className="mb-30">
                    <Typography.Title
                      level={5}
                      className="mb-0 title text-gray fs-14"
                    >
                      Email ID:
                    </Typography.Title>
                    <Typography.Title
                      level={5}
                      className="mb-0 mt-0 data fs-14"
                    >
                      {impactData?.email}
                    </Typography.Title>
                  </div>
                </Col>
                <Col span={12} md={12} sm={12} xs={24}>
                  <div className="mb-30">
                    <Typography.Title
                      level={5}
                      className="mb-0 title text-gray fs-14"
                    >
                      Department:
                    </Typography.Title>
                    <Typography.Title
                      level={5}
                      className="mb-0 mt-0 data fs-14"
                    >
                      {impactData?.Department?.name
                        ? impactData?.Department?.name
                        : '-'}
                    </Typography.Title>
                  </div>
                </Col>
                <Col span={12} md={12} sm={12} xs={24}>
                  <div className="mb-30">
                    <Typography.Title
                      level={5}
                      className="mb-0 title text-gray fs-14"
                    >
                      Manager:
                    </Typography.Title>
                    <Typography.Title
                      level={5}
                      className="mb-0 mt-0 data fs-14"
                    >
                      {impactData?.Manager?.firstName
                        ? impactData?.Manager?.firstName +
                          ' ' +
                          impactData?.Manager?.lastName
                        : '-'}
                    </Typography.Title>
                  </div>
                </Col>
                <Col span={12} md={12} sm={12} xs={24}>
                  <div className="mb-30">
                    <Typography.Title
                      level={5}
                      className="mb-0 title text-gray fs-14"
                    >
                      Created on :
                    </Typography.Title>
                    <Typography.Title
                      level={5}
                      className="mb-0 mt-0 data fs-14"
                    >
                      {moment(impactData?.createdAt).format('l')}
                    </Typography.Title>
                  </div>
                </Col>

                <Col span={12} md={12} sm={12} xs={24}>
                  <div className="mb-30">
                    <Typography.Title
                      level={5}
                      className="mb-0 title text-gray fs-14"
                    >
                      Role:
                    </Typography.Title>
                    <Typography.Title
                      level={5}
                      className="mb-0 mt-0 data fs-14"
                    >
                      {impactData?.role === UserRole.sme
                        ? UserRole?.teamMember
                        : impactData?.role}
                    </Typography.Title>
                  </div>
                </Col>
                {!isSMEGroup && (
                  <Col span={12} md={12} sm={12} xs={24}>
                    <div className="mb-30">
                      <Typography.Title
                        level={5}
                        className="mb-0 title text-gray fs-14"
                      >
                        User weight:
                      </Typography.Title>
                      <Typography.Title
                        level={5}
                        className="mb-0 mt-0 data fs-14"
                      >
                        {impactData?.weight !== undefined ||
                        impactData?.weight !== null
                          ? impactData?.weight
                          : impactData?.role === UserRole.admin ||
                            impactData?.role === UserRole.leader ||
                            impactData?.role === UserRole.sme
                          ? impactData?.Organization?.weightSettings.filter(
                              (el) => el.role === impactData?.role
                            )[0]?.weight
                          : impactData?.role === UserRole.superAdmin
                          ? impactData?.Organization?.weightSettings.filter(
                              (el) => el.role === UserRole.leader
                            )[0]?.weight
                          : null}
                      </Typography.Title>
                    </div>
                  </Col>
                )}
                <Col span={12} md={12} sm={12} xs={24}>
                  <div className="mb-30">
                    <Typography.Title
                      level={5}
                      className="mb-0 title text-gray fs-14"
                    >
                      Status:
                    </Typography.Title>
                    <Typography.Title
                      level={5}
                      className="mb-0 mt-0 data fs-14"
                    >
                      {impactData?.status}
                    </Typography.Title>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </div>

      <Modal
        open={isModalOpen}
        onCancel={handleCancel}
        centered
        okText="Save"
        className="submit-idea-modal footer-end"
        onOk={handleSubmit}
        closable={false}
        confirmLoading={loader}
      >
        <Row justify="center">
          <Col span={24}>
            <Typography.Title level={4} className="mt-10 fw-bolder fs-20">
              Change Password
            </Typography.Title>
          </Col>
          <Col span={24}>
            <Form
              layout="vertical"
              requiredMark={false}
              className="mt-10"
              form={form}
            >
              <Form.Item
                label={<span className="fs-14">Old Password</span>}
                name="currentPassword"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your Old Password!',
                  },
                ]}
              >
                <Input.Password
                  placeholder="Enter old password"
                  className="fs-14"
                />
              </Form.Item>
              <Form.Item
                label={<span className="fs-14">New Password</span>}
                name="newPassword"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your New Password!',
                  },
                ]}
              >
                <Input.Password
                  placeholder="Enter New Password"
                  className="fs-14"
                />
              </Form.Item>
              <Form.Item
                label={<span className="fs-14">Confirm New Password</span>}
                name="confirmNewPassword"
                className="mb-0"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your New Password!',
                  },
                ]}
              >
                <Input.Password
                  placeholder="Enter Confirm New Password"
                  className="fs-14"
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

