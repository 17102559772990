import { Button, Dropdown, Grid, Space } from 'antd';
import { ECHOIMAGES } from 'Assets/Images';
import React, { memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  getCurrentOrganization,
  isAdmin,
  isLeader,
  isSuperAdmin,
  setAddIdeaTour,
  setMainTour,
} from 'store/slices/loginSlice';
import { capitalizeFirstLetter } from 'utils/Constants';
import { CaretDownOutlined } from '@ant-design/icons';

const HeaderButton = ({ startMainTour, getUpdatedtageName, setRun }) => {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const history = useHistory();
  const dispatch = useDispatch();
  const isLeaderGroup = useSelector(isLeader);
  const isAdminGroup = useSelector(isAdmin);
  const isSuperAdminGroup = useSelector(isSuperAdmin);
  const currnetOrg = useSelector(getCurrentOrganization);
  const { AddUser } = ECHOIMAGES.icons;
  const { GoalBlue } = ECHOIMAGES.sidebarIcons;
  const location = useLocation();

  const handleClick = () => {
    history.push('/add-idea');
    setRun(false);
    if (startMainTour) {
      dispatch(setMainTour(false));
      dispatch(setAddIdeaTour(true));
    }
  };
  const items = [
    {
      key: '1',
      label: (
        <span>
          Add {getUpdatedtageName && capitalizeFirstLetter(getUpdatedtageName)}
        </span>
      ),
      onClick: () => handleClick(),
      disabled: location.pathname.includes('/add-idea'),
    },
    {
      key: '2',
      label: <span>Add Goal</span>,
      onClick: () => history.push('/add-goal'),
      disabled: location.pathname.includes('/add-goal'),
      className: 'add-goal-btn',
    },
  ];
  return (
    <>
      {(history.location.pathname === '/goal' && isLeaderGroup) ||
      (history.location.pathname === '/goal' && isSuperAdminGroup) ||
      (history.location.pathname === '/goal' && isAdminGroup) ? (
        <Button
          className="bordered-button"
          onClick={() => history.push('/add-goal')}
          size="middle"
        >
          {screens.xs ? 'Add Goal' : 'Add a New Goal'}
        </Button>
      ) : history.location.pathname === '/add-goal' ? (
        <></>
      ) : (
        <>
          <Button
            className="bordered-button ml-10 capitalize-text my-sevent-step"
            onClick={() => {
              history.push('/add-idea');
              setRun(false);
              if (startMainTour) {
                dispatch(setMainTour(false));
                dispatch(setAddIdeaTour(true));
              }
            }}
            size="middle"
          >
            Add{' '}
            {getUpdatedtageName && capitalizeFirstLetter(getUpdatedtageName)}
          </Button>
        </>
      )}
    </>
  );
};

export default memo(HeaderButton);

