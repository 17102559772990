import { ExecuteQuery } from '../../../utils/Api';

export const usersByDepartmentIDCustom = (id) => {
  return ExecuteQuery('usersByDepartmentIDCustom', undefined, id);
};

export const departmentsByOrganizationIDCustom = (id) => {
  return ExecuteQuery('departmentsByOrganizationIDCustom', undefined, id);
};

export const DepartmentUseresCustom = (filter) => {
  return ExecuteQuery('DepartmentUseresCustom', undefined, undefined, filter);
};

