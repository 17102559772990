import {
  Alert,
  Button,
  Col,
  Input,
  message,
  Modal,
  Row,
  Skeleton,
  Space,
  Typography,
} from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import './superadmin.less';
import {
  getCurrentOrganization,
  getCurrnetUserData,
  isAdmin,
  isSME,
} from '../../store/slices/loginSlice';
import { useSelector } from 'react-redux';
import { HistoryOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { RESTDeleteOrg, RESTDemoOrg, RESTListOrg } from 'utils/RESTApi';
import { ECHOIMAGES } from 'Assets/Images';
import SuperAdminTable from './components/SuperAdminTable';
import { useHistory } from 'react-router-dom';
import { debounceFn } from 'utils/commonMethods';
import FilterButton from 'Common/FilterButton';
import FilterByPartnerDrawer from './components/FilterByPartnerDrawer';
import { UserRole } from 'utils/Constants';
import { Auth } from 'aws-amplify';


function SuperAdmin() {
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const currnetOrg = useSelector(getCurrentOrganization);
  const userId = currnetOrg?.adminID || loginUsrOrg?.id;
  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;
  const { Search } = Input;
  const history = useHistory();
  const [organizationDetails, setOrganizationDetail] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [orgDeleteID, setOrgDeleteID] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [sortTableOrder, setSortTableOrder] = useState('DESC');
  const [sortTableColumn, setSortTableColumn] = useState('createdAt');
  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [showAlert, setShowAlert] = useState(false);
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);

  const isAdminGroup = useSelector(isAdmin);
  const isSMEGroup = useSelector(isSME);

  const { DeleteIcon } = ECHOIMAGES.superAdminIcons;

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  //function to fetch organization
  const fetchOrganizations = async (sortOrder, sortColumn, search, page) => {
    let tempPage = page || currentPage;
    let allData = [];
    try {
      let sort_direction = sortTableOrder;
      let sort_field = sortTableColumn;

      if (sortOrder && sortColumn) {
        sort_direction = sortOrder;
        sort_field = sortColumn;
      }
      let inputData = {
        sort_field: sort_field,
        sort_direction: sort_direction,
        page: tempPage,
        user_id: userId,
        organization_id: orgId,
        scope: isAdminGroup
          ? UserRole?.admin
          : currnetOrg?.id
          ? UserRole?.admin
          : UserRole?.superAdmin,
      };
      if (search !== undefined && search !== '') {
        inputData.filters = {
          Search: search,
        };
      }
      let response = await RESTListOrg(inputData);
      const temp = response?.body?.map((item) => {
        return {
          ...item,
        };
      });
      allData = tempPage === 1 ? temp : [...organizationDetails, ...temp];
      setOrganizationDetail(allData);
      setTableLoading(false);
    } catch (error) {
      setTableLoading(false);
      console.log(error);
    }
  };

  //function to handle infinite scroll for table
  const isFetching = useRef(false);
  const handelInfiniteScroll = async (e) => {
    const { target } = e;
    const isBottom =
      Math.round(target.scrollHeight) - Math.round(target.scrollTop) ===
      Math.round(target.clientHeight);
    if (isBottom && organizationDetails?.length === 20) {
      if (isFetching.current) {
        return; // Exit if the function is already running
      }
      isFetching.current = true;
      setCurrentPage((prevPage) => prevPage + 1);
      await fetchOrganizations(
        sortTableOrder,
        sortTableColumn,
        searchText,
        currentPage + 1
      );
      isFetching.current = false;
    }
  };

  //table sorting
  const sortTableData = async (sortData) => {
    let sortOrder = sortData.order === 'ascend' ? 'ASC' : 'DESC';
    let sortColumn = sortData.field;
    setSortTableOrder(sortOrder);
    setSortTableColumn(sortColumn);
    setCurrentPage(1);
    await fetchOrganizations(sortOrder, sortColumn, searchText, 1);
  };

  const onSearch = async (searchText) => {
    if (searchText !== '') {
      setSearchText(searchText);
      setCurrentPage(1);
      await fetchOrganizations(sortTableOrder, sortTableColumn, searchText, 1);
    } else {
      setSearchText('');
      setCurrentPage(1);
      await fetchOrganizations(sortTableOrder, sortTableColumn, '', 1);
    }
  };

  const debouncedHandleInput = debounceFn(onSearch, 500);

  const handleInputSearch = (searchText) => {
    debouncedHandleInput(searchText);
  };

  //function to delete org
  const deleteOrganization = async () => {
    setLoading(true);
    try {
      let inputData = {
        org_id: orgDeleteID,
      };
      await RESTDeleteOrg(inputData);
      await fetchOrganizations();
      setLoading(false);
      message.warning('Organization delete initiated.... ');
      setIsModalOpen(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  //function to create demo org
  const createDemoOrganization = async () => {
    try {
      let input = {
        organization_id: orgId,
        inviter_email: loginUsrOrg?.email,
        inviter_name: loginUsrOrg?.firstName + ' ' + loginUsrOrg?.lastName,
      };
      setShowAlert(true);
      await RESTDemoOrg(input);
    } catch (err) {
      console.log(err);
    }
  };

  const refreshToken = async () => {
    await Auth.currentAuthenticatedUser({ bypassCache: true });
  };

  useEffect(() => {
    refreshToken();
  }, []);

  useEffect(() => {
    if (orgId) {
      fetchOrganizations();
    }
    // eslint-disable-next-line
  }, [orgId]);

  return (
    <>
      <div className="background-white main-div pt-0">
        <Space>
          {showAlert && (
            <Alert
              className="alert-message"
              // eslint-disable-next-line
              description={
                <>
                  Demo organization creation is in progress. You will <br />{' '}
                  receive the summary via email upon completion.
                </>
              }
              type="info"
              showIcon
              icon={<HistoryOutlined className="inprogress-icon" />}
              closable
              onClose={() => setShowAlert(false)}
              closeIcon={<CloseCircleOutlined className="close-icon" />}
            />
          )}
        </Space>
        <Row justify="space-between">
          <Search
            className="searchbar-style mb-10"
            placeholder="search by name,website,contact"
            onChange={(e) => handleInputSearch(e.target.value)}
          />
          <div className="btn-div">
            <Button onClick={createDemoOrganization}>
              Add Demo Organization
            </Button>
            <Button
              type="primary"
              className="ml-10"
              onClick={() => history.push('/organization/add-organization')}
            >
              Add Organization
            </Button>
            <FilterButton onClick={() => setFilterDrawerOpen(true)} />
          </div>
        </Row>
        <Row
          justify="center"
          className={
            showAlert ? 'row-data idea-header mt-20' : 'row-data idea-header'
          }
        >
          <Col span={24}>
            {tableLoading ? (
              <Skeleton
                active
                paragraph={{
                  rows: 4,
                }}
              />
            ) : (
              <SuperAdminTable
                organizationDetails={organizationDetails}
                showModal={showModal}
                setOrgDeleteID={setOrgDeleteID}
                sortTableData={sortTableData}
                sortOrder={sortTableOrder}
                sortColumn={sortTableColumn}
                handelInfiniteScroll={handelInfiniteScroll}
                fetchOrganizations={fetchOrganizations}
                setCurrentPage={setCurrentPage}
              />
            )}
          </Col>
        </Row>
      </div>
      <Modal
        open={isModalOpen}
        onOk={deleteOrganization}
        onCancel={handleCancel}
        confirmLoading={loading}
        centered
        okText="Yes"
        className="delete-user-modal"
      >
        <Row justify="center">
          <img src={DeleteIcon} alt="" />
        </Row>
        <Row justify="center">
          <Typography.Title level={4}>
            Do you really want to Delete this Organization?
          </Typography.Title>
        </Row>
      </Modal>
      <FilterByPartnerDrawer
        filterDrawerOpen={filterDrawerOpen}
        setFilterDrawerOpen={setFilterDrawerOpen}
        sortTableColumn={sortTableColumn}
        sortTableOrder={sortTableOrder}
        searchText={searchText}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        setOrganizationDetail={setOrganizationDetail}
        setTableLoading={setTableLoading}
      />
    </>
  );
}

export default SuperAdmin;
