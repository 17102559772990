import { Button, message, Row, Typography, Upload, Modal, Col } from 'antd';
import React, { useState } from 'react';
import { UploadOutlined, LoadingOutlined } from '@ant-design/icons';
import { GetFileFromS3, SaveFileToS3 } from 'utils/commonMethods';
import { RESTImportGamma } from 'utils/RESTApi';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  getCurrentOrganization,
  getCurrnetUserData,
} from 'store/slices/loginSlice';
import { ECHOIMAGES } from 'Assets/Images';

function ImportPriority() {
  const { Dragger } = Upload;
  const { UploadSuccess } = ECHOIMAGES.ImportIcons;
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const userEmail = currnetOrg?.Admin?.email || loginUsrOrg?.email;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [count, setCount] = useState();
  
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleViewImportedRankings = () => {
    history.push({
      pathname: '/rankings',
    });
  };
  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;

  const downloadSampleFile = async () => {
    let downlloadFile;
    downlloadFile = await GetFileFromS3(
      `${orgId}/Sample_Priority_Import.xlsx`,
      {
        level: 'public',
      }
    );
    window.open(downlloadFile);
  };

  const uploadProps = {
    name: 'file',
    multiple: false,
    async beforeUpload(file) {
      setLoading(true);
      const uploadData = await SaveFileToS3(file);
      let inputData = {
        file_key: 'public/' + uploadData.key,
        organization_id: orgId,
        sender_email: userEmail,
      };

      await RESTImportGamma(inputData)
        .then((response) => {
          setLoading(false);
          message.success('file uploaded successfully');
          setCount(response?.priorities_count || 0);
          showModal();
        })
        .catch((error) => {
          // Handle error here
          setLoading(false);
        });

      return false;
    },
  };

  return (
    <div className="background-white p-30 mt-30">
      <Row justify="space-between" align="middle" className="gap-15">
        <Typography.Title level={4} className="mb-0 fw-bolder text-black">
          Import Items
        </Typography.Title>
        <Button
          className="bordered-button"
          onClick={downloadSampleFile}
          size="middle"
        >
          Download Sample File
        </Button>
      </Row>
      {loading ? (
        <div className="progress-bar">
          <LoadingOutlined
            style={{
              fontSize: 80,
              color: '#0082ca',
            }}
            spin
          />
          <Typography.Title level={5} className="mb-0">
            Uploading
          </Typography.Title>
        </div>
      ) : (
        <Dragger className="upload" {...uploadProps}>
          <p className="ant-upload-drag-icon">
            <UploadOutlined className="text-primary" />
          </p>
          <p className="ant-upload-text">
            Select a CSV or Excel file to import
          </p>
          <p className="ant-upload-hint">Or drag and drop it here</p>
        </Dragger>
      )}
      {/* open modal after uploading document */}
      <Modal
        open={isModalOpen}
        onCancel={handleViewImportedRankings}
        centered
        cancelText="View Imported Items"
        okText="Import More Items"
        className="submit-idea-modal upload-success"
        onOk={handleCancel}
      >
        <Row justify="center">
          <Col className="text-center">
            <img src={UploadSuccess} alt="" />
            <Typography.Title level={4} className="mt-10">
              File Successfully uploaded.
            </Typography.Title>
            <Typography.Title level={5} className="mb-20">
              {count} items will be imported.
            </Typography.Title>
          </Col>
        </Row>
      </Modal>
    </div>
  );
}

export default ImportPriority;

