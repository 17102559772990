import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  Row,
  Col,
  Input,
  Typography,
  Skeleton,
  Grid,
  Modal,
  Switch,
  Divider,
  message,
  Checkbox,
  Tooltip,
} from 'antd';
import { LoadingOutlined, InfoCircleOutlined } from '@ant-design/icons';

import { ECHOIMAGES } from '../../Assets/Images';
import {
  getCurrentOrganization,
  getCurrnetUserData,
  getHomeTour,
  getRankingTour,
  isSME,
  setRankingTour,
} from '../../store/slices/loginSlice';
import { API, graphqlOperation } from 'aws-amplify';
import { onCreateScoreCustom } from 'graphql/customSubscriptions';
import UpdateRankModal from './UpdateRankModal';
import ReAssignedRankModal from './ReAssignedRankModal';
import { listUserGammaType } from 'Pages/SuperAdmin/SuperAdminFun';
import PromoteModal from './PromoteModal';
import './idea.less';
import {
  RESTDeleteGamma,
  RESTUpdateRanking,
  RESTGetRankingFilter,
} from 'utils/RESTApi';
import ExportItemsButton from './Components/ExportItemsButton';
import RankingFilter from './Components/RankingFilter';
import RankingTable from './Components/RankingTable';
import RankingSort from './Components/RankingSort';
import { fetchOrgDepartments } from 'utils/CommonFunctions';
import { debounceFn, GetFileFromS3 } from 'utils/commonMethods';
import PreviousRanking from './Components/PreviousRanking';
import RankingGraphs from './Components/RankingGraphs';
import Joyride from 'react-joyride';
import { getDefaultBatchID } from 'store/slices/compareSlice';
import FilterButton from 'Common/FilterButton';
import dayjs from 'dayjs';

export default function PriorityList() {
  const { Search } = Input;
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const { DeleteIcon } = ECHOIMAGES.superAdminIcons;
  const [dashboardData, setDashboardData] = useState([]);
  const currentOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const orgId = currentOrg?.id || loginUsrOrg?.organizationID;
  const userId = currentOrg?.adminID || loginUsrOrg?.id;
  const isSMEGroup = useSelector(isSME);
  const [open, setOpen] = useState(false);
  const [backeupData, setBackupData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [opportunityEditData, setOpportunityEditData] = useState('');
  const [opportunityEditPromoteData, setOpportunityEditPromoteData] =
    useState('');
  const [isPromoteModalOpen, setIsPromoteModalOpen] = useState(false);
  const [gammaTypeList, setGammaTypeList] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeletePriorityModal, setIsDeletePriorityModal] = useState(false);
  const [deletePriorityID, setDeletePriorityID] = useState();
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [listAllDepartments, setListAllDepartments] = useState([]);

  const [departmentFilter, setDepartmentFilter] = useState([]);
  const [stageFilterLevel, setStgaestageFilterLevel] = useState([]);
  const [rankValue, setRankValue] = useState([]);
  const [selectAllDepartment, setSelectAllDepartment] = useState(false);
  const [selectAllStage, setSelectAllStage] = useState(false);
  const [selectAllRanks, setSelectAllRanks] = useState(false);
  const [timeRange, setTimeRange] = useState('');
  const [customRange, setCustomRange] = useState();
  const [submittedByOwner, setSubmittedByOwner] = useState(false);
  const [selectSwitch, setIsSwitchSelect] = useState(false);
  const [selectedOption, setSelectedOption] = useState('Recent'); // Initial selected option
  const [filterChange, setFilterChange] = useState(false);
  const [graphData, setGraphData] = useState({});
  const [itemLimit, setItemLimit] = useState(10);
  const [yearlyBackupRankData, setYearlyBackupRankData] = useState({});
  const [searchText, setSearchText] = useState('');
  const [loader, setLoader] = useState(false);
  const [sortTableOrder, setSortTableOrder] = useState('ASC');
  const [sortTableColumn, setSortTableColumn] = useState('rank');
  const [loadData, setLoadData] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const show_all_ranking = localStorage.getItem('showall')
    ? JSON.parse(localStorage.getItem('showall'))
    : false;

  const [showAllRanking, setShowAllRanking] = useState(show_all_ranking);
  const dispatch = useDispatch();
  const isFetching = useRef(false);
  const startFirstTimeTour = useSelector(getHomeTour);
  const startRankingTour = useSelector(getRankingTour);

  const priorityDefaultBatchID =
    currentOrg?.defaultPrioirtybatch?.id ||
    loginUsrOrg?.Organization?.organizationDefaultPrioirtybatchId;

  const showModal = () => {
    setIsModalOpen(true);
  };
  const showDeleteRankingModal = () => {
    setIsDeletePriorityModal(true);
  };
  const handleRankingModalCancel = () => {
    setIsDeletePriorityModal(false);
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const listOrgDepartments = async () => {
    const departments = await fetchOrgDepartments(orgId);
    setListAllDepartments(departments);
  };
  const fetchDefaultBatchID = useSelector(getDefaultBatchID);
  const [nextTokenData, setNextToken] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [rankingFilter, setRankingFilter] = useState({});
  const [selectedDates, setSelectedDates] = useState([]);
  const [selectedTopItem, setSelectedTopItems] = useState();
  const [selectedDays, setSelectedDays] = useState();
  const [graphLoading, setGraphLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);

  const fetchGammas = async (
    id,
    sortOrder,
    sortColumn,
    page,
    filterChange,
    search,
    startDate,
    endDate,
    days,
    selectedTopValues,
    rankings,
    all,
    showranking
  ) => {
    setGraphLoading(true);
    let tempPage = page || currentPage;
    if ((fetchDefaultBatchID || priorityDefaultBatchID) && orgId) {
      let allData = [];
      let nextToken = nextTokenData;
      let sort_direction = sortTableOrder;
      let sort_field = sortTableColumn;
      if (sortOrder && sortColumn) {
        sort_direction = sortOrder;
        sort_field = sortColumn;
      }
      let batchID = fetchDefaultBatchID
        ? fetchDefaultBatchID
        : priorityDefaultBatchID;

      // eslint-disable-next-line max-len
      let rankingApi = `/rankings?id=${orgId}&priorityBatchID=${batchID}&userID=${userId}&page=${
        rankings || all ? '1' : selectedTopValues === -1 ? -1 : tempPage
      }&sort_direction=${sort_direction}&sort_field=${sort_field}&show_all=${
        localStorage.getItem('showall')
          ? localStorage.getItem('showall')
          : showranking !== undefined
          ? showranking
          : showAllRanking
      }`;
      if (nextToken && tempPage > 1) {
        rankingApi += `&nextToken=${nextToken}`;
      }

      if (search !== undefined && search !== '') {
        rankingApi += `&search=${search}`;
      }
      if (rankings) {
        setExportLoading(true);
        rankingApi += `&export=true&exportData=Rankings`;
      }
      if (all) {
        setExportLoading(true);
        rankingApi += `&export=true&exportData=All`;
      }
      let getStartDateFromLS = JSON.parse(
        localStorage.getItem('selectedDate')
      )?.[0]?.split('T')[0];
      let getEndDateFromLS = JSON.parse(
        localStorage.getItem('selectedDate')
      )?.[1]?.split('T')[0];

      const lastSixWeekStart = dayjs()
        .subtract(6, 'week')
        .startOf('week')
        .add(6, 'days')
        .format('YYYY-MM-DD');
      const lastSixWeekEnd = dayjs().format('YYYY-MM-DD');
      let chart = {
        Top: selectedTopValues ? selectedTopValues : '10',
        Calender: [
          getStartDateFromLS
            ? getStartDateFromLS
            : startDate
            ? startDate?.format('YYYY-MM-DD')
            : lastSixWeekStart,
          getEndDateFromLS
            ? getEndDateFromLS
            : endDate
            ? endDate?.format('YYYY-MM-DD')
            : lastSixWeekEnd,
        ],
        Interval: localStorage.getItem('days')
          ? localStorage.getItem('days') === '1 Day'
            ? 'day'
            : localStorage.getItem('days') === '1 Week'
            ? 'week'
            : localStorage.getItem('days') === '1 Month'
            ? 'month'
            : days === '1 Day'
            ? 'day'
            : days === '1 Week'
            ? 'week'
            : days === '1 Month'
            ? 'month'
            : 'week'
          : 'week',
      };
      try {
        const res = await RESTGetRankingFilter(
          rankingApi,
          rankingFilter,
          tempPage === 1 ? chart : {}
        );
        if (rankings || all) {
          if (res?.body?.fileKeys && res?.body?.fileKeys?.length > 0) {
            for (const fileKey of res?.body?.fileKeys) {
              const filename = fileKey.split('/')[1];
              const downLoadFile = await GetFileFromS3(filename);
              window.open(downLoadFile);
            }
          }
          message.success('File exported successfully');
          setGraphLoading(false);
          setLoading(false);
          setExportLoading(false);
          return;
        }

        const temp = res?.body?.items?.map((item, index) => {
          const today = dayjs().format('YYYY-MM-DD');

          const id = item?.Gamma?.id;

          const updatedChart = {
            ...item?.Gamma?.rankHistory,
            [today]: { R: item?.Gamma?.rank, S: item?.Gamma?.level },
          };

          return {
            ...item?.Gamma,
            chart: {
              [id]: updatedChart,
            },
            rank:
              item?.Gamma?.fixedRank > 0
                ? item?.Gamma?.fixedRank
                : item?.Gamma?.rank,
            isRank: item.isRanked,
          };
        });

        setNextToken(res?.body?.nextToken);
        allData =
          filterChange || temp?.rankHistory || showranking || tempPage === 1
            ? temp
            : nextToken
            ? [...dashboardData, ...temp]
            : temp;

        const chartData = allData.reduce((acc, curr) => {
          return { ...acc, ...curr.chart };
        }, {});

        setGraphData(chartData);
        setYearlyBackupRankData(chartData);
        setDashboardData(allData);
        setBackupData(allData);
        setLoading(false);
        setLoadData(false);
        setGraphLoading(false);
        setExportLoading(false);
      } catch (err) {
        console.log(err);
        setGraphLoading(false);
        setLoading(false);
        setLoadData(false);
        setExportLoading(false);
      }
      return allData;
    } else {
      setGraphLoading(false);
      setLoading(false);
      setExportLoading(false);
    }
  };

  const handelInfiniteScroll = async (e) => {
    const { target } = e;
    const isBottom =
      Math.round(target.scrollHeight) - Math.round(target.scrollTop) ===
      Math.round(target.clientHeight);
    if (isBottom && nextTokenData) {
      if (isFetching.current) {
        return;
      }
      isFetching.current = true;
      setCurrentPage((prevPage) => prevPage + 1);
      setLoadData(true);
      await fetchGammas(
        null,
        sortTableOrder,
        sortTableColumn,
        currentPage + 1,
        null,
        searchText
      );
      isFetching.current = false;
    }
  };

  const sortTableData = async (sortData) => {
    let sortOrder = sortData.order === 'ascend' ? 'ASC' : 'DESC';
    let sortColumn = sortData.field;
    setSortTableOrder(sortOrder);
    setSortTableColumn(sortColumn);
    setCurrentPage(1);
    setNextToken(null);
    await fetchGammas(
      null,
      sortOrder,
      sortColumn,
      1,
      true,
      searchText,
      selectedDates?.[0],
      selectedDates?.[1],
      selectedDays,
      selectedTopItem,
      false,
      false,
      showAllRanking
    );
  };

  const onSearch = async (searchText) => {
    if (searchText !== '') {
      setCurrentPage(1);
      setSearchText(searchText);
      await fetchGammas(
        null,
        sortTableOrder,
        sortTableColumn,
        1,
        true,
        searchText
      );
    } else {
      setCurrentPage(1);
      setSearchText('');
      await fetchGammas(null, sortTableOrder, sortTableColumn, 1, true, '');
    }
  };

  const debouncedHandleInput = debounceFn(onSearch, 500);

  const handleInputSearch = (searchText) => {
    debouncedHandleInput(searchText);
  };

  const handleGetGammaType = async () => {
    try {
      const data = await listUserGammaType();
      setGammaTypeList(data?.data?.__type?.enumValues);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetGammaType();
    listOrgDepartments();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const filter = {
      organizationID: orgId,
    };
    const Accountsubscriber = API.graphql(
      graphqlOperation(onCreateScoreCustom, filter)
    ).subscribe({
      next: (d) => {
        // fetchGammas();
        if (
          d?.value?.data?.onUpdateLatestRankStatus?.organization
            ?.organizationDefaultPrioirtybatchId !== priorityDefaultBatchID
        )
          fetchGammas();
      },
      error: (e) => {
        console.log(e);
      },
    });

    return () => {
      Accountsubscriber.unsubscribe();
    };
    // eslint-disable-next-line
  }, [orgId]);

  const handleClickEditRank = (rowData) => {
    setOpportunityEditData(rowData);
    showModal(true);
  };

  const handleClickEditPromote = (rowData) => {
    setOpportunityEditPromoteData(rowData);
    setIsPromoteModalOpen(true);
  };

  //to update the ranking
  const createScoreCalculationStatus = async () => {
    try {
      let inputData = {
        organization_id: orgId,
      };
      await RESTUpdateRanking(inputData);
    } catch (error) {
      console.log(error);
    }
  };

  const deletePriority = async () => {
    try {
      setDeleteLoader(true);
      let inputData = {
        gamma_id: deletePriorityID,
        org_id: orgId,
      };
      await RESTDeleteGamma(inputData);
      await fetchGammas();
      setDeleteLoader(false);
      handleRankingModalCancel();
      createScoreCalculationStatus();
    } catch (err) {
      setDeleteLoader(false);
      console.log(err);
    }
  };

  useEffect(() => {
    if (!isModalOpen) {
      setOpportunityEditData('');
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (!isPromoteModalOpen) {
      setOpportunityEditPromoteData('');
    }
  }, [isPromoteModalOpen]);

  const filterSettings = {
    open,
    setOpen,
    orgId,
    backeupData,
    setDashboardData,
    userId,
    listAllDepartments,
    setDepartmentFilter,
    departmentFilter,
    setStgaestageFilterLevel,
    stageFilterLevel,
    setRankValue,
    rankValue,
    selectAllDepartment,
    setSelectAllDepartment,
    selectAllStage,
    setSelectAllStage,
    selectAllRanks,
    setSelectAllRanks,
    timeRange,
    setTimeRange,
    customRange,
    setCustomRange,
    setSubmittedByOwner,
    submittedByOwner,
  };

  const onChange = (checked) => {
    setIsSwitchSelect(checked);
  };

  useEffect(() => {
    fetchGammas();
    // eslint-disable-next-line
  }, []);

  const steps = [
    {
      target: '.filter-step',
      content: (
        <div className="joyride-style">
          <div className="title-content">Sort and Filter the Rankings</div>
          <div
            className="details-content"
            style={{ fontWeight: '400px !important' }}
          >
            Sort the list from lowest to highest or highest to lowest. Filter
            rankings by stage, department, and date created.
          </div>
          <Divider />
          <div
            style={{
              position: 'absolute',
              left: '0px',
              right: '0px',
              bottom: '10px',
              color: '#0070C0',
            }}
          >
            1/3
          </div>
        </div>
      ),
      disableBeacon: true,
      placement: 'right',
    },
    {
      target: '.previous-step',
      content: (
        <div className="joyride-style">
          <div className="title-content">Previous Position</div>
          <div className="details-content">
            Select a previous position by a time period such as recent, week,
            month, or quarter.
          </div>
          <Divider />
          <div
            style={{
              position: 'absolute',
              left: '0px',
              right: '0px',
              bottom: '10px',
              color: '#0070C0',
            }}
          >
            2/3
          </div>
        </div>
      ),
      disableBeacon: true,
    },
    {
      target: '.chart-step',
      content: (
        <div className="joyride-style">
          <div className="title-content">Chart</div>
          <div className="details-content">
            Observe how the ranking has changed in the chart layout.
          </div>
          <Divider />
        </div>
      ),
      disableBeacon: true,
      styles: {
        options: {
          width: 300, // Adjust the width of the highlight
          height: 900,
        },
      },
    },
  ];
  const { NextArrow, BackArrow } = ECHOIMAGES.icons;
  const customLocale = {
    back: (
      <span
        style={{
          position: 'absolute',
          left: '20px',
          color: 'black',
          bottom: '13px',
        }}
      >
        <img src={BackArrow} alt="" />
      </span>
    ),
    next: (
      <span
        style={{
          backgroundColor: 'transparent !important',
          color: 'black',
        }}
      >
        <img
          src={NextArrow}
          alt=""
          style={{
            position: 'absolute',
            right: '20px',
            bottom: '13px',
          }}
        />
      </span>
    ),
    skipWithCount: (current, total) => (
      <span>
        {current} of {total}
      </span>
    ),
  };

  const [runTour, setRunTour] = useState(false);

  useEffect(() => {
    if (startFirstTimeTour && !localStorage.getItem('firstTimeinRanking')) {
      dispatch(setRankingTour(true));
    }
    // eslint-disable-next-line
  }, [startFirstTimeTour]);

  useEffect(() => {
    setRunTour(startRankingTour);
  }, [startRankingTour]);

  const handleJoyrideCallback = (data) => {
    if (data.type === 'step:after' && data.action === 'close') {
      // The user closed the tooltip, stop the tour
      if (startFirstTimeTour) {
        localStorage.setItem('firstTimeinRanking', true);
      }
      setRunTour(false);
      dispatch(setRankingTour(false));
    }
  };

  const filterRankings = async (e) => {
    setShowAllRanking(e.target.checked);
    localStorage.setItem('showall', JSON.stringify(e.target.checked));
    setCurrentPage(1);
    await fetchGammas(
      null,
      sortTableOrder,
      sortTableColumn,
      1,
      true,
      searchText,
      selectedDates?.[0],
      selectedDates?.[1],
      selectedDays,
      selectedTopItem,
      false,
      false,
      e.target.checked
    );
  };

  return (
    <>
      <div className="w-100">
        <Row
          justify="space-between"
          align="middle"
          className="idea-header ranking-graphs  header-filter w-100"
        >
          <Search
            className="searchbar-style"
            placeholder="Search by title"
            onChange={(e) => handleInputSearch(e.target.value)}
          />

          <Row gutter={[0, 10]}>
            <Row justify="center" align="middle">
              <Checkbox
                className="mr-5"
                checked={showAllRanking}
                onChange={(e) => filterRankings(e)}
              >
                Show All{' '}
                <Tooltip
                  title="Show all items, including those that don't 
                match the currently applied scenario filters."
                >
                  {' '}
                  <InfoCircleOutlined className="info-img" />
                </Tooltip>{' '}
              </Checkbox>
            </Row>
            <Row justify="center" align="middle" className="mr-10">
              <Typography.Text level={5} className="mr-5">
                Show Chart :{' '}
              </Typography.Text>
              <Switch size="small" checked={selectSwitch} onChange={onChange} />
            </Row>
            <div className="filter-step ranking-sorting">
              <RankingSort
                dashboardData={dashboardData}
                setDashboardData={setDashboardData}
                screens={screens}
                setSortTableColumn={setSortTableColumn}
                setSortTableOrder={setSortTableOrder}
                sortTableColumn={sortTableColumn}
                sortTableOrder={sortTableOrder}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                rankingFilter={rankingFilter}
                selectedDates={selectedDates}
                setGraphData={setGraphData}
                setYearlyBackupRankData={setYearlyBackupRankData}
                selectedDays={selectedDays}
                selectedTopItem={selectedTopItem}
                searchText={searchText}
                showAll={showAllRanking}
              />
            </div>
            <div>
              <PreviousRanking
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
              />
            </div>
            <FilterButton onClick={showDrawer} />
            {!isSMEGroup && (
              <ExportItemsButton
                exportLoading={exportLoading}
                fetchGammas={fetchGammas}
                setCurrentPage={setCurrentPage}
              />
            )}
          </Row>
        </Row>
        {/* filter Drawer */}
        <RankingFilter
          {...filterSettings}
          setFilterChange={setFilterChange}
          filterChange={filterChange}
          setRankingFilter={setRankingFilter}
          sortTableColumn={sortTableColumn}
          sortTableOrder={sortTableOrder}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          setNextToken={setNextToken}
          selectedDates={selectedDates}
          setGraphData={setGraphData}
          setYearlyBackupRankData={setYearlyBackupRankData}
          selectedDays={selectedDays}
          selectedTopItem={selectedTopItem}
          searchText={searchText}
          showAll={showAllRanking}
        />
        {/* edit rank modal  */}
        {opportunityEditData?.fixedRank === null ||
        opportunityEditData?.fixedRank < 0 ? (
          <UpdateRankModal
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            editData={opportunityEditData}
            getGammaByOrg={fetchGammas}
            setCurrentPage={setCurrentPage}
            setNextToken={setNextToken}
            orgId={orgId}
          />
        ) : (
          <ReAssignedRankModal
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            editData={opportunityEditData}
            getGammaByOrg={fetchGammas}
            orgId={orgId}
            setCurrentPage={setCurrentPage}
          />
        )}
        {/* Promote modal  */}
        <PromoteModal
          isModalOpen={isPromoteModalOpen}
          setIsModalOpen={setIsPromoteModalOpen}
          editData={opportunityEditPromoteData}
          gammaTypeList={gammaTypeList}
          getGammaByOrg={fetchGammas}
        />
        <Modal
          open={isDeletePriorityModal}
          onOk={deletePriority}
          onCancel={handleRankingModalCancel}
          confirmLoading={deleteLoader}
          centered
          okText="Yes"
          className="submit-idea-modal"
        >
          <Row justify="center">
            <img src={DeleteIcon} alt="" />
          </Row>
          <Row justify="center">
            <Typography.Title level={4}>
              Do you really want to Delete this Ranking?
            </Typography.Title>
          </Row>
        </Modal>
      </div>

      {selectSwitch && (
        <div className="w-100">
          <Row className="idea-header ranking-graphs mt-20">
            <Col span={24}>
              {loader ? (
                <div className="graph-loader">
                  <LoadingOutlined
                    style={{
                      fontSize: 80,
                      color: '#0082ca',
                    }}
                    spin
                  />
                </div>
              ) : (
                <RankingGraphs
                  dashboardData={dashboardData}
                  allData={backeupData}
                  setDashboardData={setDashboardData}
                  filterChange={filterChange}
                  setGraphData={setGraphData}
                  setItemLimit={setItemLimit}
                  setYearlyBackupRankData={setYearlyBackupRankData}
                  yearlyBackupRankData={yearlyBackupRankData}
                  jsonData={graphData}
                  selectSwitch={selectSwitch}
                  loadData={loadData}
                  fetchData={fetchGammas}
                  setSelectedDates={setSelectedDates}
                  setSelectedTopItems={setSelectedTopItems}
                  setSelectedDays={setSelectedDays}
                  graphLoading={graphLoading}
                />
              )}
            </Col>
          </Row>
        </div>
      )}
      <div className="w-100">
        <Row className="idea-header ranking-graphs mt-20">
          <Col span={24}>
            {loading ? (
              <Skeleton
                active
                paragraph={{
                  rows: 4,
                }}
              />
            ) : (
              <RankingTable
                dashboardData={dashboardData}
                handleClickEditRank={handleClickEditRank}
                handleClickEditPromote={handleClickEditPromote}
                showDeleteRankingModal={showDeleteRankingModal}
                setDeletePriorityID={setDeletePriorityID}
                selectedOption={selectedOption}
                setDashboardData={setDashboardData}
                jsonData={graphData}
                itemLimit={itemLimit}
                selectSwitch={selectSwitch}
                sortTableData={sortTableData}
                sortOrder={sortTableOrder}
                sortColumn={sortTableColumn}
                handelInfiniteScroll={handelInfiniteScroll}
                fetchGammas={fetchGammas}
                refreshLoading={refreshLoading}
                showAllRanking={showAllRanking}
              />
            )}
          </Col>
        </Row>
      </div>

      {((isSMEGroup && startFirstTimeTour) ||
        (isSMEGroup && startRankingTour)) && (
        <Joyride
          steps={steps}
          run={runTour}
          continuous
          showProgress
          disableCloseOnEsc
          disableOverlayClose
          locale={customLocale}
          callback={handleJoyrideCallback}
        />
      )}
    </>
  );
}
