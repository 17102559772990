import './typography.less';
import './responsive.less';
import './App.less';
import Routes from './Router/Routes';
import { Auth, Amplify } from 'aws-amplify';
import config from './aws-exports';

const App = () => {
  const updatedAwsConfig = {
    ...config,
    // Set API to use id token. This will ensure email is passed within the token
    API: {
      graphql_headers: async () => {
        const jwtToken = (await Auth.currentSession())
          .getIdToken()
          .getJwtToken();
        localStorage.setItem('idToken', jwtToken);
        return { Authorization: jwtToken };
      },
    },
  };

  Amplify.configure(updatedAwsConfig);
  return (
    <div className="App">
      <Routes />
    </div>
  );
};

export default App;
