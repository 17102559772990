import {
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Grid,
  Radio,
  Row,
  Switch,
  Typography,
} from 'antd';
import moment from 'moment';
import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getDefaultBatchID } from 'store/slices/compareSlice';
import {
  getCurrentOrganization,
  getCurrnetUserData,
} from 'store/slices/loginSlice';
import { fetchOrganizationStages } from 'utils/CommonFunctions';
import { dateTimeFormat } from 'utils/Constants';
import { RESTGetRankingFilter } from 'utils/RESTApi';

const FilterDrawer = ({
  listAllDepartments,
  setOpen,
  open,
  userId,
  setDashboardData,
  setRankingFilter,
  filterChange,
  setFilterChange,
  sortTableOrder,
  sortTableColumn,
  sortColumnField,
  selectedPriority,
  compareScenariosIds,
}) => {
  const [timeRange, setTimeRange] = useState('');
  const [customRange, setCustomRange] = useState();
  const [submittedByOwner, setSubmittedByOwner] = useState(false);
  const [selectAllType, setSelectAllType] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [filterLevel, setFilterLevel] = useState([]);
  const [departmentFilter, setDepartmentFilter] = useState([]);
  const [filterLoader, setFilterLoader] = useState(false);
  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;
  const { RangePicker } = DatePicker;
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const onClose = () => {
    setOpen(false);
  };

  const [allStages, setAllStages] = useState([]);

  const getRankingsByDate = {
    today: [
      moment().startOf('day').format(dateTimeFormat),
      moment().format(dateTimeFormat),
    ],
    last7days: [
      moment().subtract(7, 'd').startOf('day').format(dateTimeFormat),
      moment().format(dateTimeFormat),
    ],
    lastWeek: [
      moment().subtract(1, 'weeks').startOf('week').format(dateTimeFormat),
      moment().subtract(1, 'weeks').endOf('week').format(dateTimeFormat),
    ],
    thisMonth: [
      moment().startOf('month').format(dateTimeFormat),
      moment().format(dateTimeFormat),
    ],
    lastMonth: [
      moment().subtract(1, 'months').startOf('month').format(dateTimeFormat),
      moment().subtract(1, 'months').endOf('month').format(dateTimeFormat),
    ],
    customRange: customRange
      ? [
          customRange[0].format(dateTimeFormat),
          customRange[1].format(dateTimeFormat),
        ]
      : [],
  };

  const getStage = () => {
    const selectedStages = filterLevel;
    if (selectedStages.length) {
      const result = {};
      allStages.forEach((item) => {
        const id = item?.id;
        const isSelected = selectedStages.includes(id);
        result[id] = isSelected;
      });
      return result;
    }
    return {};
  };

  let priorityDefaultBatchID =
    currnetOrg?.defaultPrioirtybatch?.id ||
    loginUsrOrg?.Organization?.organizationDefaultPrioirtybatchId;

  const fetchDefaultBatchID = useSelector(getDefaultBatchID);

  const defaultPriorityBatchID = fetchDefaultBatchID
    ? fetchDefaultBatchID
    : priorityDefaultBatchID;

  const filterApply = async () => {
    setFilterLoader(true);
    const filters = {
      byMe: submittedByOwner ? userId : '',
      Stage: getStage(),
      Department: departmentFilter,
      Created: getRankingsByDate[timeRange] ? getRankingsByDate[timeRange] : [],
    };
    let currentPage = 1;
    let allData = [];

    let rankingApi = '';
    let firstPosition = selectedPriority?.position1
      ? selectedPriority?.position1
      : compareScenariosIds?.[0] !== undefined
      ? compareScenariosIds?.[0]
      : '';
    let secondPosition = selectedPriority?.position2
      ? selectedPriority?.position2
      : compareScenariosIds?.[1] !== undefined
      ? compareScenariosIds?.[1]
      : '';
    if (sortColumnField === 'Position') {
      // eslint-disable-next-line max-len
      rankingApi = `/rankings?id=${orgId}&priorityBatchID=${firstPosition}&priorityBatchID1=${defaultPriorityBatchID}&priorityBatchID2=${secondPosition}&userID=${userId}&page=${currentPage}&sort_direction=${sortTableOrder}&sort_field=${sortTableColumn}`;
    } else if (sortColumnField === 'Position2') {
      // eslint-disable-next-line max-len
      rankingApi = `/rankings?id=${orgId}&priorityBatchID=${secondPosition}&priorityBatchID1=${defaultPriorityBatchID}&priorityBatchID2=${firstPosition}&userID=${userId}&page=${currentPage}&sort_direction=${sortTableOrder}&sort_field=${sortTableColumn}`;
    } else {
      // eslint-disable-next-line max-len
      rankingApi = `/rankings?id=${orgId}&priorityBatchID=${defaultPriorityBatchID}&priorityBatchID1=${firstPosition}&priorityBatchID2=${secondPosition}&userID=${userId}&page=${currentPage}&sort_direction=${sortTableOrder}&sort_field=${sortTableColumn}`;
    }
    try {
      const res = await RESTGetRankingFilter(rankingApi, filters);
      const temp = res?.body?.items.map((item, index) => ({
        ...item?.Gamma,
        rank:
          item?.Gamma?.fixedRank > 0
            ? item?.Gamma?.fixedRank
            : item?.Gamma?.rank,
        isRank: item.isRanked,
      }));
      // totalCount += temp?.length;
      allData = [...allData, ...temp];
      setRankingFilter(filters);
      setDashboardData(allData);
      setFilterChange(!filterChange);

      currentPage++;
      setFilterLoader(false);
    } catch (err) {
      console.log(err);
    }

    setOpen(false);
    // eslint-disable-next-line max-len
  };

  const getStageName = async () => {
    try {
      const sortedStages = await fetchOrganizationStages(orgId);
      setAllStages(sortedStages);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getStageName();
    // eslint-disable-next-line
  }, []);
  return (
    <Drawer
      className="related-item-drawer brawer-with-btn"
      title={
        <Row justify="space-between" align="middle">
          <Typography.Title level={3} className="mb-0 ">
            Filters
          </Typography.Title>
          <Button
            type="text"
            className="text-blue fs-14 p-0"
            onClick={() => {
              setDepartmentFilter([]);
              setFilterLevel([]);
              setSelectAll(false);
              setSelectAllType(false);
            }}
          >
            <strong>Clear All</strong>
          </Button>
        </Row>
      }
      closeIcon={null}
      closable={false}
      height={screens.xs ? '83vh' : ''}
      placement={screens.xs ? 'bottom' : 'right'}
      onClose={onClose}
      open={open}
    >
      <div>
        <Row justify="space-between" align="middle">
          <Typography.Title level={5} className="mb-0 fw-400 fs-14 text-black">
            Submitted by me
          </Typography.Title>
          <Switch
            checked={submittedByOwner}
            onChange={(e) => setSubmittedByOwner(e)}
            size="small"
          />
        </Row>

        <Form className="mt-20">
          <div className="mb-20">
            <Row justify="space-between" align="middle">
              <Typography.Title level={5} className="mb-0">
                Stage
              </Typography.Title>
              <Checkbox
                className="select-all"
                checked={selectAllType}
                onChange={(e) => {
                  setFilterLevel(
                    e.target.checked
                      ? allStages?.length > 0 &&
                          allStages?.map((data) => data?.id)
                      : []
                  );
                  setSelectAllType(e.target.checked);
                }}
              >
                Select All
              </Checkbox>
            </Row>
            <Divider className="mt-10 mb-10" />

            <Checkbox.Group
              className="vertical-box"
              value={filterLevel}
              onChange={(e) => setFilterLevel(e)}
            >
              {allStages
                ?.filter((item) => item?.active) // Filter items with active set to true
                .sort((a, b) => a.level - b.level)
                .map((item) => {
                  let checked = filterLevel.includes(item.id);
                  return (
                    <Checkbox checked={checked} value={item?.id} key={item?.id}>
                      {item?.name}
                    </Checkbox>
                  );
                })}
            </Checkbox.Group>
          </div>

          <div className="mb-20">
            <Row justify="space-between" align="middle">
              <Typography.Title level={5} className="mb-0">
                Department
              </Typography.Title>
              <Checkbox
                className="select-all"
                checked={selectAll}
                onChange={(e) => {
                  setDepartmentFilter(
                    e.target.checked
                      ? listAllDepartments.map((data) => data?.id)
                      : []
                  );
                  setSelectAll(e.target.checked);
                }}
              >
                Select All
              </Checkbox>
            </Row>
            <Divider className="mt-10 mb-10" />

            <Checkbox.Group
              className="vertical-box"
              value={departmentFilter}
              onChange={(e) => setDepartmentFilter(e)}
            >
              {listAllDepartments.map((item, index) => {
                return (
                  <Checkbox value={item?.id} key={index}>
                    {item?.name}
                  </Checkbox>
                );
              })}
            </Checkbox.Group>
          </div>

          <div className="mb-20">
            <Row justify="space-between" align="middle">
              <Typography.Title level={5} className="mb-0">
                Created date
              </Typography.Title>
            </Row>
            <Divider className="mt-10 mb-10" />

            <Radio.Group className="vertical-box">
              <Radio
                value="today"
                checked={timeRange === 'today'}
                onChange={() => setTimeRange('today')}
              >
                Today
              </Radio>
              <Radio
                value="last7days"
                checked={timeRange === 'last7days'}
                onChange={() => setTimeRange('last7days')}
              >
                Last 7 days
              </Radio>
              <Radio
                value="lastWeek"
                checked={timeRange === 'lastWeek'}
                onChange={() => setTimeRange('lastWeek')}
              >
                Last week
              </Radio>
              <Radio
                value="thisMonth"
                checked={timeRange === 'thisMonth'}
                onChange={() => setTimeRange('thisMonth')}
              >
                This month
              </Radio>
              <Radio
                value="lastMonth"
                checked={timeRange === 'lastMonth'}
                onChange={() => setTimeRange('lastMonth')}
              >
                Last month
              </Radio>
              <Radio
                value="customRange"
                checked={timeRange === 'customRange'}
                onChange={() => setTimeRange('customRange')}
              >
                Custom range
              </Radio>
              {timeRange === 'customRange' && (
                <RangePicker onChange={(e) => setCustomRange(e)} />
              )}
            </Radio.Group>
          </div>
        </Form>
      </div>

      <div className="drawer-btn">
        <Button className="drawer-btn-child" onClick={onClose} size="middle">
          Cancel
        </Button>
        <Button
          type="primary"
          className="drawer-btn-child"
          htmlType="submit"
          onClick={filterApply}
          loading={filterLoader}
          size="middle"
        >
          Apply
        </Button>
      </div>
    </Drawer>
  );
};

export default memo(FilterDrawer);

