import {
  Button,
  Col,
  Input,
  message,
  Row,
  Space,
  Typography,
  Upload,
} from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setBrandingForm } from 'store/slices/brandingSlice';
import {
  getCurrentOrganization,
  getCurrnetUserData,
} from 'store/slices/loginSlice';
import { RESTAddNewOrg, RESTGetAuthorization } from 'utils/RESTApi';
import { CloseCircleOutlined, BgColorsOutlined } from '@ant-design/icons';
import { GetFileFromS3, SaveFileToS3 } from 'utils/commonMethods';
import { SketchPicker } from 'react-color';
import BrandingPreview from 'Pages/SuperAdmin/components/BrandingPreview';
import LeaveMFModal from 'Pages/MeasurementField/Components/LeaveMFModal';

function CustomBranding() {
  const [organizationData, setOrganizationData] = useState({
    name: '',
    id: '',
    logo: '',
    navHeaderColor: '',
  });
  const pickerRef = useRef(null);

  const [imagePreview, setImagePreview] = useState();
  const [uploadLogo, setUploadLogo] = useState(true);
  const [orgDetails, setOrgDetails] = useState();
  const [loading, setLoading] = useState(false);
  const [previewDisable, setPreviewDisable] = useState(false);
  const [customLogo, setCustomLogo] = useState();
  const [showPicker, setShowPicker] = useState(false);
  const [previewModal, setPreviewModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [resetFunction, setResetFunction] = useState(false);
  const [colorChange, setColorChange] = useState(false);

  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;
  const userId = currnetOrg?.adminID || loginUsrOrg?.id;

  const dispatch = useDispatch();

  const handleClickOutside = (event) => {
    if (pickerRef.current && !pickerRef.current.contains(event.target)) {
      setShowPicker(false);
    }
  };

  useEffect(() => {
    if (showPicker) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showPicker]);

  const props = {
    name: 'file',
    accept: '.svg',
    onChange(info) {
      dispatch(setBrandingForm(true));
      setUploadLogo(true);
      const file = info.fileList[0].originFileObj;
      const reader = new FileReader();
      reader.onload = (e) => {
        setCustomLogo(e.target.result);
        setImagePreview(e.target.result);
        setOrganizationData((prevData) => ({
          ...prevData,
          logo: file,
        }));
      };
      reader.readAsDataURL(file);
    },
  };

  const handleColorChange = (selectedColor) => {
    setOrganizationData((prevData) => ({
      ...prevData,
      navHeaderColor: selectedColor.hex,
    }));
    setColorChange(true);
    dispatch(setBrandingForm(true));
  };

  const fetchOrgDetailsByID = async () => {
    try {
      setLoading(true);
      let api = `/organization/get?organizationID=${orgId}`;
      let response = await RESTGetAuthorization(api);

      setOrgDetails(response?.body);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const logoByOrgID = async () => {
    try {
      let fileKey =
        orgDetails?.branding?.dark_background_logo.split('public/')[1];
      let getLogo = await GetFileFromS3(fileKey, { level: 'public' });
      setOrganizationData((prevData) => ({
        ...prevData,
        logo: getLogo,
      }));
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async () => {
    try {
      setLoader(true);
      let uploadImageinS3 = await SaveFileToS3(organizationData?.logo);

      let inputData = {
        organization_id: orgId,
        user_id: userId,
        organization: {
          id: orgId,
          name: orgDetails?.organization?.name,
          website: orgDetails?.organization?.website,
          contact: orgDetails?.organization?.contact,
          address: {
            street: orgDetails?.organization?.address?.street,
            city: orgDetails?.organization?.address?.city,
            zip: orgDetails?.organization?.address?.zip,
            state: orgDetails?.organization?.address?.state,
            country: orgDetails?.organization?.address?.country,
          },
        },
        admin: {
          id: orgDetails?.admin?.id,
          firstName: orgDetails?.admin?.firstName,
          lastName: orgDetails?.admin?.lastName,
          contact: orgDetails?.admin?.contact,
          email: orgDetails?.admin?.email,
        },
        branding: {
          dark_background_logo:
            !imagePreview && resetFunction
              ? orgDetails?.branding?.dark_background_logo
              : imagePreview
              ? 'public/' + uploadImageinS3?.key
              : orgDetails?.branding?.dark_background_logo,
          light_background_logo: orgDetails?.branding?.light_background_logo,
          primary_color: orgDetails?.branding?.primary_color,
          secondary_color: orgDetails?.branding?.secondary_color,
          navigation_color: organizationData?.navHeaderColor,
          login_page_illustration:
            orgDetails?.branding?.login_page_illustration,
        },
      };
      setResetFunction(false);
      await RESTAddNewOrg(inputData);
      await fetchOrgDetailsByID();
      message.success('Company details updated added successfully');
      setLoader(false);
    } catch (err) {
      setLoader(false);
      console.log(err);
    }
  };

  const handleReset = async () => {
    setResetFunction(true);
    setUploadLogo(true);
    try {
      let getBrandingDetails = await GetFileFromS3(
        `${orgId}/branding/Branding.json`,
        {
          level: 'public',
        }
      );
      let data = await fetch(getBrandingDetails);
      let response = await data.json();
      let getLogo = await GetFileFromS3(
        response.dark_background_logo.split('public/')[1],
        {
          level: 'public',
        }
      );
      setOrganizationData((prevData) => ({
        ...prevData,
        logo: getLogo,
        navHeaderColor: response?.navigation_color,
      }));
      setCustomLogo(getLogo);
      setImagePreview();
      setColorChange(false);
    } catch (error) {
      console.error('Error fetching the file from S3', error);
    }
  };

  useEffect(() => {
    if (orgDetails) {
      setOrganizationData({
        logo: orgDetails?.branding?.dark_background_logo,
        navHeaderColor: orgDetails?.branding?.navigation_color,
        name: orgDetails?.organization?.name,
      });
    }
  }, [orgDetails]);

  useEffect(() => {
    logoByOrgID();
    // eslint-disable-next-line
  }, [orgDetails]);

  useEffect(() => {
    fetchOrgDetailsByID();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="custom-org ">
      <Row justify="space-between">
        <Typography.Title level={4} className="fw-500">
          Customize Branding
        </Typography.Title>
        <div>
          <Button
            className="mr-5"
            onClick={handleReset}
            disabled={
              loader
                ? true
                : loading
                ? true
                : imagePreview || colorChange
                ? false
                : true
            }
          >
            Reset
          </Button>
          <Button
            className="mr-5"
            onClick={() => setPreviewModal(true)}
            disabled={
              loader ? true : loading ? true : previewDisable ? true : false
            }
          >
            Preview
          </Button>
          <Button
            type="primary"
            onClick={handleSubmit}
            htmlType="submit"
            loading={loader}
            disabled={
              loader
                ? true
                : loading
                ? true
                : colorChange
                ? false
                : !imagePreview
                ? true
                : false
            }
          >
            Save
          </Button>
        </div>
      </Row>
      <Row>
        <Typography.Text className="mb-10 fw-500">
          Logo for dark background
        </Typography.Text>
      </Row>
      {imagePreview && uploadLogo ? (
        <div className="static-image">
          <img
            src={imagePreview}
            alt="Organization Logo"
            style={{
              background: organizationData.navHeaderColor,
              padding: '10px',
              height: '60px',
            }}
          />
          <CloseCircleOutlined
            className="close-icon"
            onClick={() => {
              setUploadLogo(false);
              setPreviewDisable(true);
            }}
          />
        </div>
      ) : (
        organizationData?.logo &&
        uploadLogo && (
          <div className="static-image">
            <img
              src={organizationData.logo}
              alt="Organization Logo"
              style={{
                background: organizationData.navHeaderColor,
                padding: '10px',
                height: '60px',
              }}
            />
            <CloseCircleOutlined
              className="close-icon"
              onClick={() => {
                setUploadLogo(false);
                setPreviewDisable(true);
              }}
            />
          </div>
        )
      )}

      {!uploadLogo && (
        <Upload {...props}>
          <Button onClick={() => setPreviewDisable(false)}>
            Click to Upload
          </Button>
        </Upload>
      )}
      <Row className="mt-20">
        <Col span={8}>
          <span className="fw-500">Navigation header color</span>

          <Input
            className="mt-5"
            addonAfter={
              <Space>
                <BgColorsOutlined
                  style={{
                    fontSize: '20px',
                    color: organizationData.navHeaderColor,
                  }}
                  onClick={() => setShowPicker(!showPicker)}
                />
              </Space>
            }
            value={organizationData.navHeaderColor}
            readOnly
          />

          {showPicker && (
            <div ref={pickerRef}>
              <SketchPicker
                color={organizationData.navHeaderColor}
                onChange={handleColorChange}
              />
            </div>
          )}
        </Col>
      </Row>
      <BrandingPreview
        brandingLogoByOrgId={organizationData.logo}
        brandingByOrgId={organizationData}
        customLogo={customLogo}
        previewModal={previewModal}
        setPreviewModal={setPreviewModal}
      />
      <LeaveMFModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </div>
  );
}

export default CustomBranding;

