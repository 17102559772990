import { ExecuteQuery, ExecuteMutation } from 'utils/Api';

export const listAllStages = (id) => {
  return ExecuteQuery('stagesByOrganizationIDCustom', undefined, id);
};

export const getFormByStage = (id) => {
  return ExecuteQuery('getStageCustom', undefined, id);
};
export const createStage = (inputData) => {
  return ExecuteMutation('createStageCustom', inputData);
};

export const updateStageCustom = (inputData) => {
  return ExecuteMutation('updateStageCustom', inputData);
};
export const gammasByLevelIDCustom = (id) => {
  return ExecuteQuery('gammasByLevelIDCustom', undefined, id);
};
export const deleteStageCustom = (id) => {
  return ExecuteMutation('deleteStageCustom', id);
};

export const createFormDraftCustom = (id) => {
  return ExecuteMutation('createFormDraftCustom', id);
};

