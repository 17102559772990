import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Dropdown,
  Empty,
  Input,
  Menu,
  message,
  Row,
  Skeleton,
  Space,
  Typography,
} from 'antd';
import {
  CaretDownOutlined,
  MoreOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import './Measurement.less';
import { ECHOIMAGES } from 'Assets/Images';
import { useHistory } from 'react-router-dom';
import {
  deleteMeasurementFieldCustom,
  measurementFieldsByOrganizationIDCustom,
  searchMeasurementFieldsCustom,
} from 'utils/Actions';
import { useSelector } from 'react-redux';
import {
  getCurrentOrganization,
  getCurrnetUserData,
} from 'store/slices/loginSlice';
import { RESTGet, RESTGetMeasurementFieldUsedDetail } from 'utils/RESTApi';
import EditModal from './Components/EditModal';
import DeleteModal from './Components/DeleteModal';
const MeasurementfieldList = () => {
  const { dollarIcon, scaleIcon, hashIcon } = ECHOIMAGES.MeasurementIcon;
  const history = useHistory();
  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;

  const [measurementList, setMeasurementList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editItemId, setEditItemId] = useState();
  const [deleteModal, setDeleteModal] = useState();
  const [deleteItemId, setDeleteItemId] = useState();
  const [listLoading, setListLoading] = useState(false);
  const { Search } = Input;

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleDeleteModal = () => {
    setDeleteModal(true);
  };

  const dropdown1Items = [
    {
      key: '1',
      label: <span>Add Scale</span>,
      onClick: () => history.push('/measurementfield/scale'),
    },
    {
      key: '2',
      label: <span>Add Numeric</span>,
      onClick: () => history.push('/measurementfield/numeric'),
    },
  ];

  const checkUsedMeasurementFiledinStage = async (item) => {
    setLoading((prevLoadingState) => ({
      ...prevLoadingState,
      [item.id]: true,
    }));
    try {
      let path = `/measurement/stages?id=${item?.id}`;
      let res = await RESTGet(path);
      if (res?.body?.length === 0) {
        if (item?.type === '#') {
          history.push(`/measurementfield/numeric/${item?.id}`);
        } else {
          history.push(`/measurementfield/scale/${item?.id}`);
        }
      } else {
        handleModalOpen();
        setEditItemId(item);
      }
      setLoading((prevLoadingState) => ({
        ...prevLoadingState,
        [item.id]: false,
      }));
    } catch (err) {
      setLoading((prevLoadingState) => ({
        ...prevLoadingState,
        [item.id]: false,
      }));
      console.log(err);
    }
  };

  const handleEdit = (item) => {
    checkUsedMeasurementFiledinStage(item);
  };

  const deleteMeasurementfield = async (item) => {
    try {
      await deleteMeasurementFieldCustom({ id: item?.id });
      message.success('Measurement field deleted successfully');
      await listMeasurementFileds();
      setDeleteModal(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDelete = async (item) => {
    setLoading((prevLoadingState) => ({
      ...prevLoadingState,
      [item.id]: true,
    }));
    try {
      let path = `/measurement/stages?id=${item?.id}`;
      let res = await RESTGetMeasurementFieldUsedDetail(path);
      if (res?.body?.length === 0) {
        deleteMeasurementfield(item);
      } else {
        handleDeleteModal();
        setDeleteItemId(item);
      }
      setLoading((prevLoadingState) => ({
        ...prevLoadingState,
        [item.id]: false,
      }));
    } catch (err) {
      setLoading((prevLoadingState) => ({
        ...prevLoadingState,
        [item.id]: false,
      }));
      console.log(err);
    }
  };

  const renderMenu = (items) => (
    <Menu>
      {items.map((item) => (
        <Menu.Item key={item.key} onClick={item.onClick}>
          {item.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  const listMeasurementFileds = async () => {
    setListLoading(true);
    try {
      let filter = {
        isMeasurement: { eq: true },
      };
      let response = await measurementFieldsByOrganizationIDCustom(
        { organizationID: orgId },
        filter
      );
      setMeasurementList(response);
      setListLoading(false);
    } catch (err) {
      setListLoading(false);
      console.log(err);
    }
  };

  const handleSearch = async (e) => {
    try {
      let filter = {
        name: { wildcard: e + '*' },
        organizationID: { eq: orgId },
        isMeasurement: { eq: true },
      };
      let sort = { direction: 'asc', field: 'name' };
      let response = await searchMeasurementFieldsCustom(filter, sort);
      setMeasurementList(response);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    listMeasurementFileds();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="background-white">
      <Row justify="space-between">
        <div>
          <Search
            onChange={(e) => handleSearch(e.target.value)}
            className="searchbar-measurement"
            placeholder="Search measurement field"
          />
        </div>
        <div>
          <Dropdown overlay={renderMenu(dropdown1Items)} trigger={['click']}>
            <Space>
              <Button className="blue-filled-btn" size="middle" type="primary">
                Add <CaretDownOutlined />
              </Button>
            </Space>
          </Dropdown>
        </div>
      </Row>
      {listLoading ? (
        <>
          <Row gutter={16}>
            <Col span={8} className="w-30 ">
              <Skeleton.Input
                active={true}
                size="large"
                block={false}
                className="skeleton-loader"
              />
            </Col>
            <Col span={8} className="w-30">
              <Skeleton.Input
                active={true}
                size="large"
                block={false}
                className="skeleton-loader"
              />
            </Col>
            <Col span={8} className="w-30">
              <Skeleton.Input
                active={true}
                size="large"
                block={false}
                className="skeleton-loader"
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8} className="w-30">
              <Skeleton.Input
                active={true}
                size="large"
                block={false}
                className="skeleton-loader"
              />
            </Col>
            <Col span={8} className="w-30">
              <Skeleton.Input
                active={true}
                size="large"
                block={false}
                className="skeleton-loader"
              />
            </Col>
            <Col span={8} className="w-30">
              <Skeleton.Input
                active={true}
                size="large"
                block={false}
                className="skeleton-loader"
              />
            </Col>
          </Row>
        </>
      ) : measurementList?.items?.length ? (
        <Row className="measurement-list gap-20">
          {measurementList?.items?.map((item) => {
            const isItemLoading = loading[item.id];
            const dropdown2Items = [
              {
                key: '1',
                label: <span>Edit</span>,
                onClick: () => handleEdit(item),
              },
              {
                key: '2',
                label: <span>Delete</span>,
                onClick: () => handleDelete(item),
              },
            ];

            return (
              <Col
                span={8}
                md={8}
                className="measurement-box measurement-box-div"
              >
                <div className="d-flex align-center">
                  {item?.subType === '+-' || item?.subType === '-+' ? (
                    <img src={scaleIcon} alt="" />
                  ) : item?.subType === '$' ? (
                    <img src={dollarIcon} alt="" />
                  ) : item?.subType === '#' ? (
                    <img src={hashIcon} alt="" />
                  ) : null}
                  <Typography.Title
                    level={5}
                    className="ml-10 mb-0"
                    style={
                      true
                        ? {
                            width: 200,
                          }
                        : undefined
                    }
                    ellipsis={
                      true
                        ? {
                            tooltip: item?.name,
                          }
                        : false
                    }
                  >
                    {item?.name}
                  </Typography.Title>{' '}
                </div>
                <div>
                  {isItemLoading ? (
                    <LoadingOutlined />
                  ) : (
                    <Dropdown
                      overlay={renderMenu(dropdown2Items)}
                      placement="bottomLeft"
                      arrow
                    >
                      <MoreOutlined className="box-dot cursor-pointer" />
                    </Dropdown>
                  )}
                </div>
              </Col>
            );
          })}
        </Row>
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
      <EditModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        editItemId={editItemId}
      />
      <DeleteModal
        deleteModal={deleteModal}
        setDeleteModal={setDeleteModal}
        deleteItemId={deleteItemId}
        deleteMeasurementfield={deleteMeasurementfield}
      />
    </div>
  );
};

export default MeasurementfieldList;

