import {
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Radio,
  Row,
  Typography,
  Grid,
} from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getCurrentOrganization,
  getCurrnetUserData,
  isAdmin,
} from 'store/slices/loginSlice';
import { filterDataByTimeRange } from 'utils/commonMethods';
import { dateTimeFormat, UserRole } from 'utils/Constants';
import { RESTGetManagement } from 'utils/RESTApi';

function AdminGlobalFilter({
  onClose,
  open,
  setOpen,
  tab,
  listAllDepartments,
  backupAllUsers,
  setAllActiveUsers,
  setAllDisabledUsers,
  setAllInvitedUsers,
  activeTab,
  setCurrentPage,
  currentPage,
  sortTableColumn,
  sortTableOrder,
  filterChange,
  setFilterChange,
  setAdminFilter,
  searchField,
  setAllUsers,
}) {
  const [filterLevel, setFilterLevel] = useState([]);
  const [selectAllType, setSelectAllType] = useState(false);
  const [departmentFilter, setDepartmentFilter] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [timeRange, setTimeRange] = useState('');
  const [customRange, setCustomRange] = useState();

  const { Text } = Typography;
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const { RangePicker } = DatePicker;

  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;
  const isAdminGroup = useSelector(isAdmin);
  const typeRole =
    currnetOrg?.id || isAdminGroup
      ? ['Team Member', 'LEADER', 'ADMIN']
      : ['Team Member', 'LEADER', 'ADMIN', 'SUPERADMIN'];

  const getRankingsByDate = {
    today: [
      moment().startOf('day').format(dateTimeFormat),
      moment().format(dateTimeFormat),
    ],
    last7days: [
      moment().subtract(7, 'd').startOf('day').format(dateTimeFormat),
      moment().format(dateTimeFormat),
    ],
    lastWeek: [
      moment().subtract(1, 'weeks').startOf('week').format(dateTimeFormat),
      moment().subtract(1, 'weeks').endOf('week').format(dateTimeFormat),
    ],
    thisMonth: [
      moment().startOf('month').format(dateTimeFormat),
      moment().format(dateTimeFormat),
    ],
    lastMonth: [
      moment().subtract(1, 'months').startOf('month').format(dateTimeFormat),
      moment().subtract(1, 'months').endOf('month').format(dateTimeFormat),
    ],
    customRange: customRange
      ? [
          customRange[0].format(dateTimeFormat),
          customRange[1].format(dateTimeFormat),
        ]
      : [],
  };
  const filterApply = async (page) => {
    let tempPage = page || currentPage;
    let allData = [];
    let roles = filterLevel.map((item) =>
      item === 'Team Member' ? UserRole.sme : item
    );
    let fil = {
      Role: roles,
      Department: departmentFilter,
      Created: getRankingsByDate[timeRange] ? getRankingsByDate[timeRange] : [],
      Status:
        tab === 'active'
          ? 'ACTIVE'
          : tab === 'disabled'
          ? 'DISABLED'
          : 'INVITED',
      Search: searchField,
    };
    if (searchField === '') {
      delete fil.Search;
    }
    let inputData = {
      organization_id: orgId,
      sort_field: sortTableColumn,
      sort_direction: sortTableOrder,
      scope: currnetOrg?.id || isAdminGroup ? 'ADMIN' : 'SUPERADMIN',
      page: tempPage,
      filters: fil,
    };
    let res = await RESTGetManagement(inputData);
    const temp = res?.body;
    allData = [...allData, ...temp];
    if (tab === 'active') {
      setAllActiveUsers(allData);
      setAllUsers(allData);
      setFilterChange(!filterChange);
    } else if (tab === 'disabled') {
      setAllDisabledUsers(allData);
      setAllUsers(allData);
      setFilterChange(!filterChange);
    } else {
      setAllInvitedUsers(allData);
      setFilterChange(!filterChange);
    }
    setAdminFilter(inputData.filters);
    setOpen(false);
  };

  return (
    <div>
      {/* Filter Drawer  */}
      <Drawer
        className="related-item-drawer brawer-with-btn"
        title={
          <Row justify="space-between" align="middle">
            <Typography.Title level={3} className="mb-0 ">
              Filters
            </Typography.Title>
            <Button
              type="text"
              className="text-blue fs-14 p-0"
              onClick={() => {
                setDepartmentFilter([]);
                setFilterLevel([]);
                setSelectAll(false);
                setSelectAllType(false);
              }}
            >
              <strong>Clear All</strong>
            </Button>
          </Row>
        }
        closeIcon={null}
        closable={false}
        height={screens.xs ? '83vh' : ''}
        placement={screens.xs ? 'bottom' : 'right'}
        onClose={onClose}
        open={open}
      >
        <Form className="mt-20">
          <div className="mb-20">
            <Row justify="space-between" align="middle">
              <Typography.Title level={5} className="mb-0">
                Role
              </Typography.Title>

              <Checkbox
                className="select-all"
                checked={selectAllType}
                onChange={(e) => {
                  setFilterLevel(e.target.checked ? typeRole : []);
                  setSelectAllType(e.target.checked);
                }}
              >
                Select All
              </Checkbox>
            </Row>
            <Divider className="mt-10 mb-10" />

            <Checkbox.Group
              className="vertical-box"
              options={typeRole}
              value={filterLevel}
              onChange={(e) => setFilterLevel(e)}
            />
          </div>

          <div className="mb-20">
            <Row justify="space-between" align="middle">
              <Typography.Title level={5} className="mb-0">
                Department
              </Typography.Title>
              <Checkbox
                className="select-all"
                checked={selectAll}
                onChange={(e) => {
                  setDepartmentFilter(
                    e.target.checked
                      ? listAllDepartments.map((data) => data?.name)
                      : []
                  );
                  setSelectAll(e.target.checked);
                }}
              >
                Select All
              </Checkbox>
            </Row>
            <Divider className="mt-10 mb-10" />

            <Checkbox.Group
              className="vertical-box"
              value={departmentFilter}
              onChange={(e) => setDepartmentFilter(e)}
            >
              {listAllDepartments.map((item, index) => {
                return (
                  <Checkbox value={item?.id} key={index}>
                    {item?.name}
                  </Checkbox>
                );
              })}
            </Checkbox.Group>
          </div>
          <div className="mb-20">
            <Row justify="space-between" align="middle">
              <Typography.Title level={5} className="mb-0">
                Created date
              </Typography.Title>
            </Row>
            <Divider className="mt-10 mb-10" />

            <Radio.Group className="vertical-box">
              <Radio
                value="today"
                checked={timeRange === 'today'}
                onChange={() => setTimeRange('today')}
              >
                Today
              </Radio>
              <Radio
                value="last7days"
                checked={timeRange === 'last7days'}
                onChange={() => setTimeRange('last7days')}
              >
                Last 7 days
              </Radio>
              <Radio
                value="lastWeek"
                checked={timeRange === 'lastWeek'}
                onChange={() => setTimeRange('lastWeek')}
              >
                Last week
              </Radio>
              <Radio
                value="thisMonth"
                checked={timeRange === 'thisMonth'}
                onChange={() => setTimeRange('thisMonth')}
              >
                This month
              </Radio>
              <Radio
                value="lastMonth"
                checked={timeRange === 'lastMonth'}
                onChange={() => setTimeRange('lastMonth')}
              >
                Last month
              </Radio>
              <Radio
                value="customRange"
                checked={timeRange === 'customRange'}
                onChange={() => setTimeRange('customRange')}
              >
                Custom range
              </Radio>
              {timeRange === 'customRange' && (
                <RangePicker onChange={(e) => setCustomRange(e)} />
              )}
            </Radio.Group>
          </div>
        </Form>

        <div className="drawer-btn">
          <Button className="drawer-btn-child" onClick={onClose} size="middle">
            Cancel
          </Button>
          <Button
            type="primary"
            className="drawer-btn-child"
            htmlType="submit"
            size="middle"
            onClick={() => {
              filterApply(1);
              setCurrentPage(1);
            }}
          >
            Apply
          </Button>
        </div>
      </Drawer>
    </div>
  );
}

export default AdminGlobalFilter;

