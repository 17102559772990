import {
  Checkbox,
  Col,
  Divider,
  Input,
  message,
  Modal,
  Row,
  Select,
  Typography,
} from 'antd';
import { FetchUser } from 'Pages/AllPriorities/IdeaFunction';
import { getUserWeightSetting } from 'Pages/Settings/SettingFunction';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getCurrentOrganization,
  getCurrnetUserData,
  isAdmin,
} from 'store/slices/loginSlice';
import { fetchRoles } from 'utils/CommonFunctions';
import { capitalizeFirstLetter, UserRole, userStatus } from 'utils/Constants';

const EditWhoFilterModal = ({
  editWhoFilterModalOpen,
  setEditWhoFilterModalOpen,
  savedFilters,
  listAllDepartments,
  setAppliedFilters,
  appliedFilters,
}) => {
  const [userData, setUserData] = useState([]);
  const [allRoles, setAllRoles] = useState([]);
  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;
  const isAdminGroup = useSelector(isAdmin);
  const includeExclude = [
    {
      key: 'include',
      value: 'to include',
    },
    {
      key: 'exclude',
      value: 'to exclude',
    },
  ];
  const getAllUsers = async () => {
    try {
      let data = {
        status: { eq: userStatus.active },
        or: [
          { role: { eq: UserRole.admin } },
          { role: { eq: UserRole.leader } },
          { role: { eq: UserRole.superAdmin } },
          { role: { eq: UserRole.sme } },
        ],
      };
      let id = { organizationID: orgId };
      let nextToken = null;
      let allUserData = [];
      while (true) {
        let res = await FetchUser(data, id, nextToken);
        allUserData.push(...res.items);
        if (!res?.nextToken) {
          break;
        }
        nextToken = res?.nextToken;
      }
      await processUserData(allUserData);
    } catch (err) {
      console.log(err);
    }
  };
  const processUserData = async (userData) => {
    setUserData(userData);
  };

  const [roleWeight, setRoleWeight] = useState();

  const listUserWeight = async () => {
    try {
      const res = await getUserWeightSetting({ id: orgId });
      let weights = res?.data?.getOrganization?.weightSettings;
      setRoleWeight(weights);
    } catch (error) {
      message.error(error.message);
    }
  };
  const handleGetRoles = async () => {
    const rolesArray = await fetchRoles(isAdminGroup, currnetOrg);
    setAllRoles(rolesArray);
  };
  const [whoList, setWhoList] = useState({});

  const handleWhoList = (value, field, key, subField) => {
    let tempWhoList = { ...whoList };
    if (field === 'departmentValue') {
      tempWhoList[field] = value;
      let userFilterValuse = userData
        ?.filter((i) => value.includes(i.departmentID))
        .map((i) => i.id);
      tempWhoList.usersValue = tempWhoList.usersValue.filter((i) =>
        userFilterValuse.includes(i)
      );
    } else if (field === 'userRoleValue') {
      tempWhoList[field] = value;
      let userFilterValuse = userData
        ?.filter((i) => value.includes(i.role))
        .map((i) => i.id);
      tempWhoList.usersValue = tempWhoList.usersValue.filter((i) =>
        userFilterValuse.includes(i)
      );
    } else {
      if (key !== undefined) {
        if (tempWhoList[field][subField]) {
          tempWhoList[field][subField] = value;
        } else {
          let subFieldObj = { ...tempWhoList[field], [subField]: 0 };
          subFieldObj[subField] = value;
          tempWhoList[field] = subFieldObj;
        }
      } else {
        tempWhoList[field] = value;
      }
    }
    setWhoList(tempWhoList);
  };

  const [editID, setEditID] = useState();
  useEffect(() => {
    if (savedFilters) {
      setEditID(savedFilters.id);
      const deptIDs = savedFilters?.departmentFilters?.departmentID?.map(
        (id) => listAllDepartments.find((dept) => dept.id === id)?.id
      );
      let roleandWeight = {};
      savedFilters.userRoleFilters.userROLE.map((item, index) => {
        roleandWeight[item] = savedFilters?.userRoleFilters?.weight[index];
      });
      let usersandWeight = {};
      savedFilters.userFilters.userID.map((item, index) => {
        usersandWeight[item] = savedFilters?.userFilters?.weight[index];
      });

      const whoEditObject = {
        departmentToInclude:
          savedFilters.departmentFilters.included === true
            ? 'to include'
            : 'to exclude',
        departmentValue: deptIDs,
        userRoleToInclude:
          savedFilters.userRoleFilters.included === true
            ? 'to include'
            : 'to exclude',
        userRoleValue: savedFilters?.userRoleFilters?.userROLE,
        customUserRoleWeightCheckbox: savedFilters?.userRoleFilters?.weight
          ?.length
          ? true
          : false,
        userRoleandWeight: roleandWeight,
        usersToInclude:
          savedFilters?.userFilters?.included === true
            ? 'to include'
            : 'to exclude',
        usersValue: savedFilters?.userFilters?.userID?.map((id) => {
          return userData?.find((e) => e?.id === id)?.id;
        }),
        customUsersWeightCheckbox: savedFilters?.userFilters?.weight?.length
          ? true
          : false,
        usersandWeight: usersandWeight,
      };

      setWhoList(whoEditObject);
    }
    // eslint-disable-next-line
  }, [savedFilters]);

  const handleSubmitFilters = () => {
    let tempApplyFilter = Array.isArray(appliedFilters)
      ? [...appliedFilters]
      : [];

    let result = {};
    //department filter

    result['departmentFilters'] = {
      included:
        whoList.departmentValue?.length > 0 && whoList.departmentToInclude
          ? true
          : false,
      departmentID: whoList.departmentValue,
      field:
        whoList.departmentValue &&
        whoList.departmentValue?.reduce((acc, curr) => {
          acc[curr] = listAllDepartments?.find((e) => curr.includes(e?.id));
          return acc;
        }, {}),
      weight: [''],
    };

    let weightTemp = [];
    Object.entries(whoList?.usersandWeight).map(([key, value]) => {
      if (whoList?.usersValue.includes(key)) {
        if (value !== undefined && whoList?.customUsersWeightCheckbox) {
          weightTemp.push(value);
        }
      }
    });

    let usersRoleWeightTemp = [];
    Object.entries(whoList?.userRoleandWeight).map(([key, value]) => {
      if (whoList?.userRoleValue.includes(key)) {
        if (value !== undefined && whoList?.customUserRoleWeightCheckbox) {
          usersRoleWeightTemp.push(value);
        }
      }
    }),
      // user filter
      (result['userFilters'] = {
        included:
          whoList?.usersValue?.length && whoList.usersToInclude === 'to include'
            ? true
            : false,
        userID: whoList.usersValue || [],
        weight: Array.isArray(weightTemp) ? weightTemp : [],
      }),
      //  user role filter
      (result['userRoleFilters'] = {
        included:
          whoList.userRoleValue && whoList.userRoleToInclude === 'to include'
            ? true
            : false,
        userROLE: whoList.userRoleValue || [],
        weight: Array.isArray(usersRoleWeightTemp) ? usersRoleWeightTemp : [],
      });
    let objectIndex = tempApplyFilter?.findIndex((item) => item?.id === editID);

    if (objectIndex >= 0) {
      tempApplyFilter[objectIndex] = {
        id: editID,
        ...result,
      };
    }

    setAppliedFilters(tempApplyFilter);
    message.success('Filters applied successfully');
    setEditWhoFilterModalOpen(false);
  };
  const handleUserData = () => {
    let temp = [];
    let deptInclude =
      whoList?.departmentToInclude === 'to include' ? true : false;
    let userRoleIncluse =
      whoList?.userRoleToInclude === 'to include' ? true : false;

    const checkDeptIncludeExclude = (deptId) => {
      if (deptInclude) {
        return whoList?.departmentValue?.includes(deptId);
      } else {
        return !whoList?.departmentValue?.includes(deptId);
      }
    };

    const checkUserRoleIncludeExclude = (ur) => {
      if (userRoleIncluse) {
        return whoList?.userRoleValue?.includes(ur);
      } else {
        return !whoList?.userRoleValue?.includes(ur);
      }
    };

    if (whoList?.departmentValue?.length && whoList?.userRoleValue?.length) {
      temp = userData.filter(
        (i) =>
          checkDeptIncludeExclude(i.departmentID) &&
          checkUserRoleIncludeExclude(i.role)
      );
    } else if (whoList?.departmentValue?.length) {
      temp = userData.filter((i) => checkDeptIncludeExclude(i.departmentID));
    } else if (whoList?.userRoleValue?.length) {
      temp = userData.filter((i) => checkUserRoleIncludeExclude(i.role));
    } else {
      temp = userData;
    }

    return temp?.map((el, index) => {
      return (
        <Option key={index} value={el?.id}>
          {el?.firstName + ' ' + el?.lastName}
        </Option>
      );
    });
  };

  useEffect(() => {
    listUserWeight();
    getAllUsers();
    handleGetRoles();
    // eslint-disable-next-line
  }, []);
  return (
    <Modal
      className="scenario-filter-modal"
      open={editWhoFilterModalOpen}
      width={1000}
      onOk={handleSubmitFilters}
      onCancel={() => setEditWhoFilterModalOpen(false)}
    >
      <div className="p-20">
        <Typography.Title level={4} className="scenario-title  mb-20">
          Edit Scenario Filters
        </Typography.Title>
        <Divider />
        <div className="idea-filter">
          <Row align="middle" className="gap-10">
            <Col span={3}>
              <Typography.Text level={4} className="fw-500 ">
                Department :
              </Typography.Text>
            </Col>
            <Col span={4}>
              <Select
                className="mt-10 user-dropdown fw-400 w-100"
                onChange={(e) => handleWhoList(e, 'departmentToInclude')}
                allowClear
                placeholder="Select Operator"
                value={whoList?.departmentToInclude}
              >
                {includeExclude.map((item, index) => {
                  return (
                    <Option key={index} value={item?.value} className="fw-400">
                      {item?.value}
                    </Option>
                  );
                })}
              </Select>
            </Col>
            <Col span={7}>
              <Select
                placeholder="Select one or more department"
                mode="multiple"
                className="mt-10 w-100 dropdown-style"
                allowClear
                onChange={(e) => handleWhoList(e, 'departmentValue')}
                value={whoList?.departmentValue}
                disabled={!whoList.departmentToInclude}
                maxTagCount="responsive"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0 ||
                  option.props.value
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {listAllDepartments.map((item, index) => {
                  return (
                    <Option key={index} value={item?.id}>
                      {item?.name}
                    </Option>
                  );
                })}
              </Select>
            </Col>
          </Row>
          <Row align="middle" className="gap-10">
            <Col span={3}>
              <Typography.Text level={4} className="fw-500">
                User role :
              </Typography.Text>
            </Col>
            <Col span={4}>
              <Select
                className="mt-10 user-dropdown w-100"
                onChange={(e) => handleWhoList(e, 'userRoleToInclude')}
                allowClear
                placeholder="Select Operator"
                value={whoList?.userRoleToInclude}
              >
                {includeExclude.map((item, index) => {
                  return (
                    <Option key={index} value={item?.value}>
                      {item?.value}
                    </Option>
                  );
                })}
              </Select>
            </Col>
            <Col span={7}>
              <Select
                placeholder="Select one or more user role"
                mode="multiple"
                className="mt-10 w-100 dropdown-style"
                allowClear
                onChange={(e) => handleWhoList(e, 'userRoleValue')}
                disabled={!whoList.userRoleToInclude}
                value={whoList.userRoleValue}
                maxTagCount="responsive"
              >
                {allRoles.map((item, index) => {
                  const roleName =
                    item?.name === UserRole.sme
                      ? UserRole?.teamMember
                      : item?.name;
                  return (
                    <Select.Option key={index} value={item?.name}>
                      {capitalizeFirstLetter(roleName)}
                    </Select.Option>
                  );
                })}
              </Select>
            </Col>
            <Col span={6}>
              <Checkbox
                checked={whoList.customUserRoleWeightCheckbox}
                disabled={!whoList.userRoleToInclude}
                onChange={(e) =>
                  handleWhoList(
                    e.target.checked,
                    'customUserRoleWeightCheckbox'
                  )
                }
                value={whoList.customUserRoleWeightCheckbox}
              >
                Custom user role weight(W){' '}
              </Checkbox>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={3}></Col>
            <Col span={21}>
              {whoList.customUserRoleWeightCheckbox &&
                whoList.userRoleValue?.map((el, key) => {
                  const matchedRole = roleWeight?.find(
                    (role) => role?.role === el
                  );
                  if (matchedRole) {
                    return (
                      <Row key={key} align="middle" className="mt-5 ml-10">
                        <span>
                          {matchedRole.role === UserRole.sme
                            ? capitalizeFirstLetter(UserRole?.teamMember)
                            : capitalizeFirstLetter(matchedRole.role)}{' '}
                          Weight:{' '}
                        </span>

                        <Input
                          className="weightBox"
                          onChange={(e) =>
                            handleWhoList(
                              e.target.value,
                              'userRoleandWeight',
                              key,
                              el
                            )
                          }
                          value={whoList?.userRoleandWeight[el]}
                        />
                      </Row>
                    );
                  }
                  return null;
                })}
            </Col>
          </Row>
          <Row align="middle" className="gap-10">
            <Col span={3}>
              <Typography.Text level={4} className="fw-500">
                User:
              </Typography.Text>
            </Col>
            <Col span={4}>
              <Select
                style={{
                  width: '100%',
                }}
                className="mt-10 user-dropdown w-100"
                onChange={(e) => handleWhoList(e, 'usersToInclude')}
                allowClear
                placeholder="Select Operator"
                value={whoList.usersToInclude}
              >
                {includeExclude.map((item, index) => {
                  return (
                    <Option key={index} value={item?.value}>
                      {item?.value}
                    </Option>
                  );
                })}
              </Select>
            </Col>
            <Col span={7}>
              <Select
                placeholder="Select one or more user"
                style={{
                  width: '100%',
                }}
                mode="multiple"
                className="mt-10 w-100 dropdown-style"
                onChange={(e) => handleWhoList(e, 'usersValue')}
                allowClear
                disabled={!whoList.usersToInclude}
                value={whoList?.usersValue}
                maxTagCount="responsive"
              >
                {handleUserData()}
              </Select>
            </Col>
            <Col span={6}>
              <Checkbox
                checked={whoList.customUsersWeightCheckbox}
                disabled={!whoList.usersToInclude}
                onChange={(e) =>
                  handleWhoList(e.target.checked, 'customUsersWeightCheckbox')
                }
              >
                Custom user weight(W){' '}
              </Checkbox>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={3}></Col>
            <Col span={17} className="d-flex custom-weight-col">
              {whoList.customUsersWeightCheckbox &&
                whoList.usersValue?.map((el, key) => {
                  const userName =
                    userData.find((e) => e?.id === el)?.firstName +
                    ' ' +
                    userData.find((e) => e?.id === el)?.lastName;
                  return (
                    <Row align="middle" key={key} className="mt-5">
                      {userName}

                      <Input
                        className="weightBox-users"
                        onChange={(e) =>
                          handleWhoList(
                            e.target.value,
                            'usersandWeight',
                            key,
                            el
                          )
                        }
                        value={whoList?.usersandWeight[el]}
                      />
                    </Row>
                  );
                })}
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
  );
};

export default EditWhoFilterModal;

