import { Col, Row, Skeleton } from 'antd';
import React from 'react';

function StageDetailPageSkeleton() {
  return (
    <>
      <Row gutter={20}>
        <Col span={12}>
          <Skeleton.Button
            active
            block={true}
            className="skeleton-loader formSekeleton"
          ></Skeleton.Button>
        </Col>
        <Col span={12}>
          <Skeleton.Button
            active
            block={true}
            className="skeleton-loader formSekeleton"
          ></Skeleton.Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Skeleton.Button
            active
            block={true}
            className="skeleton-loader formSekeleton"
          ></Skeleton.Button>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col span={12}>
          <Skeleton.Button
            active
            block={true}
            className="skeleton-loader formSekeleton"
          ></Skeleton.Button>
        </Col>
        <Col span={12}>
          <Skeleton.Button
            active
            block={true}
            className="skeleton-loader formSekeleton"
          ></Skeleton.Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Skeleton.Button
            active
            block={true}
            className="skeleton-loader formSekeleton"
          ></Skeleton.Button>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col span={12}>
          <Skeleton.Button
            active
            block={true}
            className="skeleton-loader formSekeleton"
          ></Skeleton.Button>
        </Col>
        <Col span={12}>
          <Skeleton.Button
            active
            block={true}
            className="skeleton-loader formSekeleton"
          ></Skeleton.Button>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col span={12}>
          <Skeleton.Button
            active
            block={true}
            className="skeleton-loader formSekeleton"
          ></Skeleton.Button>
        </Col>
        <Col span={12}>
          <Skeleton.Button
            active
            block={true}
            className="skeleton-loader formSekeleton"
          ></Skeleton.Button>
        </Col>
      </Row>
    </>
  );
}

export default StageDetailPageSkeleton;

