import {
  Layout,
  Menu,
  Grid,
  Row,
  Button,
  Badge,
  Avatar,
  Dropdown,
  Typography,
  Drawer,
  Popconfirm,
  Tabs,
  Tooltip,
  Image,
  Breadcrumb,
  Skeleton,
} from 'antd';
import {
  BellOutlined,
  UserOutlined,
  CloseOutlined,
  ClusterOutlined,
  MenuUnfoldOutlined,
  CloseCircleOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import './mainlayout.less';
import { ECHOIMAGES } from '../Assets/Images';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import {
  getCurrentOrganization,
  viewAs,
  isSuperAdmin,
  isLeader,
  getCurrnetUserData,
  isAdmin,
  isSME,
  getHomeTour,
  setMainTour,
  getMainTour,
  setAddIdeaTour,
  setRankingTour,
  setCompareTour,
  setRateTour,
  setRateDetailTour,
  getAlreadyStartedTour,
  getProfilePhoto,
  getComparisonIDs,
  setProfilePic,
  getIsPartner,
  setIsPartner,
} from '../store/slices/loginSlice';
import { useSelector, useDispatch } from 'react-redux';
import AllNotification from './AllNotification';
import {
  getOrganizationCustom,
  notificationByUserCustom,
  updateNotificationCustom,
} from '../utils/Actions';
import {
  onCreateNotificationCustom,
  onCreateUserSignOutStatusCustom,
  onCreateScoreCustom,
} from '../graphql/customSubscriptions';
import SubMenu from 'antd/lib/menu/SubMenu';
import { capitalizeFirstLetter } from 'utils/Constants';
import { fetchOrganizationStages } from 'utils/CommonFunctions';
import BackComponent from './BackComponent';
import {
  getComparisonList,
  getNewStageName,
  getRatingList,
  setNewStageName,
  setComparisonList,
  setRatingList,
} from 'store/slices/StageSlice';
import {
  getUserCompareSetting,
  getUserRatingSetting,
} from 'Pages/Settings/SettingFunction';
import {
  getModalTrue,
  getTotalCompare,
  getTotalSeconds,
  setModalTrue,
  getAddedCompare,
  setCompareAdd,
  setDefaultBatchID,
} from 'store/slices/compareSlice';
import MileStoneModal from 'Pages/Compare/Components/MileStoneModal';
import KudosModal from 'Pages/Compare/Components/KudosModal';
import StartTourModal from 'Common/StartTourModal';
import OnboardingHome from 'Common/OnboardingHome';
import HeaderButton from 'Common/HeaderButton';
import { FetchUser } from 'Pages/Idea/IdeaFunction';
import { GetFileFromS3 } from 'utils/commonMethods';
import {
  getNavigationColor,
  getNavigationLogo,
  getScopingIds,
  setNavigationColor,
  setNavigationLogo,
  setScopingIds,
} from 'store/slices/brandingSlice';

const { Header, Content, Footer, Sider } = Layout;
const { Text } = Typography;

export default function Mainlayout(props) {
  const { Title } = Typography;

  const { AddUser, helpIcon } = ECHOIMAGES.icons;
  const {
    Home,
    Compare,
    Rate,
    AllPriorities,
    PriorityBatches,
    Goal,
    scenarioPlanning,
    ImportIcon,
    AdminIcon,
    IntegrationIcon,
  } = ECHOIMAGES.sidebarIcons;
  const { addIdeaIconWhite } = ECHOIMAGES.ideaIcons;
  const { stageIcon, questioncircle, measurementFieldIcon } = ECHOIMAGES.admin;
  const { partnerIcon } = ECHOIMAGES.superAdminIcons;
  const [collapsed, setCollpased] = useState(false);

  const [selectedKey, setSelectedKey] = useState('');
  const location = useLocation();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const history = useHistory();
  const dispatch = useDispatch();
  const viewingas = useSelector(getCurrentOrganization);
  const isSuperAdminGroup = useSelector(isSuperAdmin);
  const isLeaderGroup = useSelector(isLeader);
  const isAdminGroup = useSelector(isAdmin);
  const isSMEGroup = useSelector(isSME);
  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const getUpdatedtageName = useSelector(getNewStageName);
  const userId = currnetOrg?.adminID || loginUsrOrg?.id;
  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;
  const getProfilePic = useSelector(getProfilePhoto);
  const comparisonList = useSelector(getComparisonList);
  const ratingList = useSelector(getRatingList);
  const organizationName = currnetOrg?.name || loginUsrOrg?.Organization?.name;
  const [importantNotification, setImportantNotification] = useState([]);
  const [generalNotification, setGeneralNotification] = useState([]);
  const [isReadNotification, setIsReadNotification] = useState([]);
  const [isMilestoneModal, setIsMilestoneModal] = useState(false);
  const [isKudosModal, setIsKudosModal] = useState(false);

  const getSeconds = useSelector(getTotalSeconds);
  const getTotalComparison = useSelector(getTotalCompare);
  const getAlreadyModalTrue = useSelector(getModalTrue);
  const getChangeInCompare = useSelector(getAddedCompare);
  const getStartTourModalTrue = useSelector(getAlreadyStartedTour);

  const startFirstTimeTour = useSelector(getHomeTour);
  const startMainTour = useSelector(getMainTour);
  const fetchSelectedComparisonIDs = useSelector(getComparisonIDs);
  const [run, setRun] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const orgNavigationColor = useSelector(getNavigationColor);
  const orgNavigationLogo = useSelector(getNavigationLogo);
  const isPartner = useSelector(getIsPartner);
  const fetchScopingIds = useSelector(getScopingIds);

  useEffect(() => {
    setRun(startMainTour);
  }, [startMainTour]);

  const fetchFile = async () => {
    try {
      setLoading(true);
      let getBrandingDetails = await GetFileFromS3(
        `${orgId}/branding/Branding.json`,
        {
          level: 'public',
        }
      );
      let data = await fetch(getBrandingDetails);
      let response = await data.json();
      dispatch(setNavigationColor(response));
      let getNavigationLogo = await GetFileFromS3(
        response.dark_background_logo.split('public/')[1],
        {
          level: 'public',
        }
      );
      dispatch(setNavigationLogo(getNavigationLogo));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching the file from S3', error);
    }
  };
  useEffect(() => {
    if (orgId) {
      fetchFile();
    }
    // eslint-disable-next-line
  }, [orgId]);

  const getConditionalModal = () => {
    if (
      getTotalComparison !== null &&
      getTotalComparison > 0 &&
      getTotalComparison < 10 &&
      history?.location?.state === '/compare' &&
      !getAlreadyModalTrue
    ) {
      dispatch(setModalTrue(true));
      setIsKudosModal(true);
    } else if (
      getTotalComparison > 0 &&
      getTotalComparison !== null &&
      history?.location?.state === '/compare' &&
      getChangeInCompare &&
      getAlreadyModalTrue
    ) {
      setIsMilestoneModal(true);
      dispatch(setCompareAdd(false));
    }
  };

  useEffect(() => {
    getConditionalModal();
    // eslint-disable-next-line
  }, [getTotalComparison, history?.location?.state]);

  const getStageName = async () => {
    try {
      const sortedStages = await fetchOrganizationStages(orgId);
      dispatch(setNewStageName(sortedStages?.[0]?.name));
    } catch (err) {
      console.log(err);
    }
  };

  const listAllNotifications = async () => {
    if (userId) {
      try {
        const res = await notificationByUserCustom({
          userID: userId,
          sortDirection: 'DESC',
        });
        setImportantNotification(
          res?.data?.NotificationByUser?.items.filter(
            (item) => item.type === 'IMPORTANT'
          )
        );
        setGeneralNotification(
          res?.data?.NotificationByUser?.items.filter(
            (item) => item.type === 'GENERAL' || item.type === 'IMPORTANT'
          )
        );
        setIsReadNotification(
          res?.data?.NotificationByUser?.items.filter(
            (item) => item.isRead === false
          )
        );
        return res;
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleSignOut = () => {
    Auth.signOut({
      global: false,
    })
      .then(() => {
        localStorage.clear();
        window.location.reload();
      })
      .catch((err) => console.log(err));
  };

  const listUserCompare = async () => {
    try {
      const res = await getUserCompareSetting({ id: orgId });
      let compareFlage = res?.data?.getOrganization?.comparisonFlags
        ? JSON.parse(res?.data?.getOrganization?.comparisonFlags)
        : '';
      if (compareFlage) {
        const result = Object.keys(compareFlage).filter(
          (key) => compareFlage[key]
        );
        dispatch(setComparisonList(result?.length));
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const listUserRating = async () => {
    try {
      const res = await getUserRatingSetting({ id: orgId });
      let ratingFlage = res?.data?.getOrganization?.ratingFlags
        ? JSON.parse(res?.data?.getOrganization?.ratingFlags)
        : '';
      if (ratingFlage) {
        const result = Object.keys(ratingFlage).filter(
          (key) => ratingFlage[key]
        );
        dispatch(setRatingList(result?.length));
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    if (orgId) {
      listUserCompare();
      listUserRating();
    }

    // eslint-disable-next-line
  }, [orgId]);

  const initialHeaderMenu = [
    {
      name: 'Home',
      path: '/',
      key: 'Home',
      icon: <img className="sidebar-icon" src={Home} alt="" />,
    },
    {
      name: 'Goals',
      path: '/goal',
      key: 'goal',
      icon: <img className="sidebar-icon" src={Goal} alt="" />,
    },
    {
      name: 'Rankings',
      path: '/rankings',
      key: 'rankings',
      icon: <img className="sidebar-icon" src={AllPriorities} alt="" />,
    },
    comparisonList > 0
      ? {
          name: 'Compare',
          path: '/compare',
          key: 'compare',
          icon: <img className="sidebar-icon" src={Compare} alt="" />,
        }
      : null,
    ratingList > 0
      ? {
          name: 'Rate',
          path: '/rate',
          key: 'rate',
          icon: <img className="sidebar-icon" src={Rate} alt="" />,
        }
      : null,
  ];

  let headersMenu = initialHeaderMenu?.filter((item) => item !== null);
  const initialLeaderHeaderMenu = [
    {
      name: 'Home',
      path: '/',
      key: 'Home',
      icon: <img className="sidebar-icon" src={Home} alt="" />,
    },
    {
      name: 'Goals',
      path: '/goal',
      key: 'goal',
      icon: <img className="sidebar-icon" src={Goal} alt="" />,
    },
    {
      name: 'Rankings',
      path: '/rankings',
      key: 'rankings',
      icon: <img className="sidebar-icon" src={AllPriorities} alt="" />,
    },
    comparisonList > 0
      ? {
          name: 'Compare',
          path: '/compare',
          key: 'compare',
          icon: <img className="sidebar-icon" src={Compare} alt="" />,
        }
      : null,
    ratingList > 0
      ? {
          name: 'Rate',
          path: '/rate',
          key: 'rate',
          icon: <img className="sidebar-icon" src={Rate} alt="" />,
        }
      : null,
    {
      name: 'Scenarios',
      path: '/scenarios',
      key: 'scenarios',
      icon: <img className="sidebar-icon" src={PriorityBatches} alt="" />,
    },

    {
      name: 'Scenario Planning',
      path: '/scenario-planning',
      key: 'scenario-planning',
      icon: <img className="sidebar-icon" src={scenarioPlanning} alt="" />,
    },
  ];

  let leaderMenu = initialLeaderHeaderMenu.filter((item) => item !== null);

  const initialAdminHeaderMenu = [
    {
      name: 'Home',
      path: '/',
      key: 'Home',
      icon: <img className="sidebar-icon" src={Home} alt="" />,
    },
    {
      name: 'Goals',
      path: '/goal',
      key: 'goal',
      icon: <img className="sidebar-icon" src={Goal} alt="" />,
    },
    {
      name: 'Rankings',
      path: '/rankings',
      key: 'rankings',
      icon: <img className="sidebar-icon" src={AllPriorities} alt="" />,
    },
    comparisonList > 0
      ? {
          name: 'Compare',
          path: '/compare',
          key: 'compare',
          icon: <img className="sidebar-icon" src={Compare} alt="" />,
        }
      : null,
    ratingList > 0
      ? {
          name: 'Rate',
          path: '/rate',
          key: 'rate',
          icon: <img className="sidebar-icon" src={Rate} alt="" />,
        }
      : null,
    {
      name: 'Scenarios',
      path: '/scenarios',
      key: 'scenarios',
      icon: <img className="sidebar-icon" src={PriorityBatches} alt="" />,
    },
    {
      name: 'Scenario Planning',
      path: '/scenario-planning',
      key: 'scenario-planning',
      icon: <img className="sidebar-icon" src={scenarioPlanning} alt="" />,
    },
    {
      name: 'Admin',
      key: 'Admin',
      icon: <img src={AdminIcon} alt="" className="sidebar-icon" />,
      subMenu: [
        {
          name: 'User Management',
          path: '/user-management',
          key: 'user-management',
          icon: <UserOutlined className="icon-size admin-icons" />,
        },
        {
          name: 'Measurement Field',
          path: '/measurementfield-list',
          key: 'measurement-field',
          icon: (
            <img className="sidebar-icon" src={measurementFieldIcon} alt="" />
          ),
        },
        {
          name: 'Stages',
          path: '/stages',
          key: 'stages',
          icon: <img className="sidebar-icon" src={stageIcon} alt="" />,
        },
        {
          name: 'Import',
          path: '/import',
          key: 'import',
          icon: <img className="sidebar-icon" src={ImportIcon} alt="" />,
        },
        {
          name: 'Settings',
          path: '/settings',
          key: 'settings',
        },
        {
          name: 'Integration',
          path: '/integration',
          key: 'integration',
          icon: <img className="sidebar-icon" src={IntegrationIcon} alt="" />,
        },
      ],
    },
    isPartner && {
      name: 'Organizations',
      path: '/organizations',
      key: 'organizations',
      icon: <ClusterOutlined className="icon-size" />,
    },
  ];

  let menuItems = initialAdminHeaderMenu.filter((item) => item !== null);
  const [headerMenu, setHeaderMenu] = useState([]);

  useEffect(() => {
    const addItems = [];
    if (isSuperAdminGroup) {
      addItems.push(
        {
          name: 'Scenarios',
          path: '/scenarios',
          key: 'scenarios',
          icon: <img className="sidebar-icon" src={PriorityBatches} alt="" />,
        },
        {
          name: 'Scenario Planning',
          path: '/scenario-planning',
          key: 'scenario-planning',
          icon: <img className="sidebar-icon" src={scenarioPlanning} alt="" />,
        },
        {
          name: 'Partners',
          path: '/partner',
          key: 'partner',
          icon: <img className="sidebar-icon" src={partnerIcon} alt="" />,
        },
        {
          name: 'Organizations',
          path: '/organizations',
          key: 'organizations',
          icon: <ClusterOutlined className="icon-size" />,
        },

        {
          name: 'User Management',
          path: '/user-management',
          key: 'user-management',
          icon: <UserOutlined className="icon-size" />,
        }
      );
    }
    setHeaderMenu(
      currnetOrg?.id
        ? [...menuItems]
        : isSuperAdminGroup
        ? [...headersMenu, ...addItems]
        : isLeaderGroup
        ? leaderMenu
        : isAdminGroup && isPartner
        ? menuItems
        : isAdminGroup
        ? menuItems
        : headersMenu
    );
    // eslint-disable-next-line
  }, [currnetOrg, comparisonList, ratingList, isPartner]);

  const userMenu = (
    <Menu
      className="dropdown-container"
      items={[
        {
          label: <Text>Profile</Text>,
          key: 'Profile',
          onClick: () => history.push('/profile'),
        },
        {
          label: <Text>Logout</Text>,
          key: 'Logout',
          onClick: () => handleSignOut(),
        },
      ]}
    />
  );

  useEffect(() => {
    // subscribe for account Update
    const Accountsubscriber = API.graphql(
      graphqlOperation(onCreateNotificationCustom, { userID: userId })
    ).subscribe({
      next: (d) => {
        listAllNotifications();
      },
      error: (e) => {},
    });
    listAllNotifications();
    return () => {
      Accountsubscriber.unsubscribe();
    };

    // eslint-disable-next-line
  }, [userId]);

  useEffect(() => {
    const filter = {
      organizationID: orgId,
    };
    const Accountsubscriber = API.graphql(
      graphqlOperation(onCreateScoreCustom, filter)
    ).subscribe({
      next: (d) => {
        dispatch(
          setDefaultBatchID(
            d?.value?.data?.onUpdateLatestRankStatus?.organization
              ?.organizationDefaultPrioirtybatchId
          )
        );
      },
      error: (e) => {
        console.log(e);
      },
    });
    return () => {
      Accountsubscriber.unsubscribe();
    };
    // eslint-disable-next-line
  }, [orgId]);

  useEffect(() => {
    switch (window.location.pathname) {
      case '/':
        setSelectedKey('Home');
        break;
      default:
        setSelectedKey(() => window.location.pathname.replace('/', ''));
        break;
    }
  }, [location]);

  // sidebar drawer
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  // notification drawer
  const [openNotification, setOpenNotification] = useState(false);
  const [allTab, setAllTab] = useState(true);
  const showNotificationDrawer = () => {
    setOpenNotification(true);
  };
  const onCloseNotification = () => {
    setOpenNotification(false);
  };

  const markAsRead = async () => {
    try {
      if (allTab) {
        await Promise.all(
          generalNotification.map(async (item) => {
            const inputData = {
              id: item?.id,
              isRead: true,
            };
            await updateNotificationCustom(inputData);
          })
        );
      } else {
        await Promise.all(
          importantNotification.map(async (item) => {
            const inputData = {
              id: item?.id,
              isRead: true,
            };
            await updateNotificationCustom(inputData);
          })
        );
      }
      await listAllNotifications().then((res) => {
        setImportantNotification(
          res?.data?.NotificationByUser?.items.filter(
            (item) => item.type === 'IMPORTANT'
          )
        );
        setGeneralNotification(
          res?.data?.NotificationByUser?.items.filter(
            (item) => item.type === 'GENERAL'
          )
        ).catch((err) => {
          console.log(err);
        });
      });
      onCloseNotification();
    } catch (err) {
      console.log(err);
    }
  };
  const getUserDetails = async () => {
    try {
      const userDetails = await FetchUser({ id: userId });
      if (userDetails?.photo !== undefined && userDetails?.photo !== null) {
        const signedURL = await GetFileFromS3(userDetails?.photo, {
          level: 'public',
        });
        dispatch(setProfilePic(signedURL));
      }
      dispatch(
        setIsPartner(userDetails?.Organization?.type?.includes('PARTNER'))
      );
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const Accountsubscriber = API.graphql(
      graphqlOperation(onCreateUserSignOutStatusCustom, {
        userID: userId,
      })
    ).subscribe({
      next: (d) => {
        Auth.signOut({
          global: false,
        })
          .then(() => {
            localStorage.clear();
            window.location.reload();
          })
          .catch((err) => console.log(err));
      },
      error: (e) => {},
    });

    return () => {
      Accountsubscriber.unsubscribe();
    };
  }, [userId]);

  useEffect(() => {
    if (orgId) {
      getStageName();
    }
    // eslint-disable-next-line
  }, [orgId]);

  useEffect(() => {
    if (userId) {
      getUserDetails();
    }
    // eslint-disable-next-line
  }, [userId]);

  const items = [
    {
      key: '1',
      label: <span className="button-font">All</span>,

      children: (
        <AllNotification
          generalNotification={generalNotification}
          tab="1"
          setOpenNotification={setOpenNotification}
          listAllNotifications={listAllNotifications}
        />
      ),
    },
    {
      key: '2',
      label: <span className="button-font">Important</span>,

      children: (
        <AllNotification
          importantNotification={importantNotification}
          tab="2"
          setOpenNotification={setOpenNotification}
          listAllNotifications={listAllNotifications}
        />
      ),
    },
  ];
  const tooltipTitle = (
    <>
      Welcome Home! This is where you can see the impact you are making and
      check out current the top ranked priorities.{' '}
      <a href="https://stralign.com" target="_blank" rel="noopener noreferrer">
        Click here for a video overview.
      </a>
    </>
  );
  const rankingTooltip = (
    <>
      Your ideas and votes matter! Rankings change based on votes! Check out up
      and coming new ideas and explore top projects and opportunities.
      <br /> Use this page to search, sort and filter the priorities.
      <br /> You can also click on individual items to get more detail and edit
      (if you are a sponsor or contributor).{' '}
      <a
        href="https://stralign.com/help-rankings"
        target="_blank"
        rel="noopener noreferrer"
      >
        Click here for a video overview.
      </a>
    </>
  );

  const compareTooltip = (
    <>
      Select the idea, project or goal that you think will make the most
      positive impact against the goal. Skip if you are not sure!{' '}
      <a
        href="https://stralign.com/help-compare"
        target="_blank"
        rel="noopener noreferrer"
      >
        Click here for a video overview.
      </a>
    </>
  );

  const rateTooltip = (
    <>
      Click on the Opportunity or Project below and rate the impact you think it
      will make on each goal.{' '}
      <a
        href="https://stralign.com/help-rankings"
        target="_blank"
        rel="noopener noreferrer"
      >
        Click here for a video overview.
      </a>
    </>
  );

  const scenarioTooltip = (
    <>
      A scenario is a set of weighted goals that can be used to identify the
      highest impact projects and ideas.
      <br /> Scenarios are how decision makers like you can evaluate multiple
      alternatives and pick the best portfolio of projects and opportunities for
      your team to focus on.
      <br /> We all know that goal priority evolves as circumstances change.
      Compare different scenarios and pick the best one for now, while also
      looking to the future!{' '}
      <a
        href="https://stralign.com/help-scenarios"
        target="_blank"
        rel="noopener noreferrer"
      >
        Click here for a video overview.
      </a>
    </>
  );

  const scenarioPlanningTooltip = (
    <>
      Perspective matters! Compare different scenarios to see how priorities may
      need to shift if you focus on specific goals more than others. <br />
      Reminder : a scenario is a set of goals that you can weight differently!
      You can create new scenarios by heading over to the scenario page! BE the
      change! - Embrace data driven dynamic prioritization, and utilize the team
      sourced voting data to help you choose the highest value opportunities and
      projects!{' '}
      <a
        href="https://stralign.com/help-scenario-planning"
        target="_blank"
        rel="noopener noreferrer"
      >
        Click here for a video overview.
      </a>
    </>
  );

  const settingTooltip = (
    <>
      You have the power! <br />
      Update your default settings, add new departments, and configure
      notifications for invited and inactive users.{' '}
      <a
        href="https://stralign.com/help-settings-overview"
        target="_blank"
        rel="noopener noreferrer"
      >
        Click here for a video overview.
      </a>
    </>
  );

  const userTooltip = (
    <>
      Add, disable, and edit user information.{' '}
      <a
        href="https://stralign.com/help-import-users-items"
        target="_blank"
        rel="noopener noreferrer"
      >
        Click here for a video overview.
      </a>
    </>
  );

  const importTooltip = (
    <>
      Import users and Items.{' '}
      <a
        href="https://stralign.com/help-import-users-items"
        target="_blank"
        rel="noopener noreferrer"
      >
        Click here for a video overview.
      </a>
    </>
  );
  const goalTooltip = (
    <>
      Clear goals are critical to the success of any organization.
      <br /> When teams don't know where they are trying to go, how can they
      figure out how to get there? In StrAlign, goals are used for voting on
      which ideas and opportunities will provide the most value and highest
      impact.
      <br /> Team members can't just vote on whether they like an idea. They can
      only say how that idea will impact the goals.{' '}
      <a
        href="https://stralign.com/help-add-goal"
        target="_blank"
        rel="noopener noreferrer"
      >
        Click here for a video overview.
      </a>
    </>
  );

  const handleTakeTourState = () => {
    if (location.pathname === '/') {
      dispatch(setMainTour(true));
    } else if (location.pathname === '/rankings') {
      dispatch(setRankingTour(true));
    } else if (location.pathname === '/compare') {
      dispatch(setCompareTour(true));
    } else if (location.pathname === '/rate') {
      dispatch(setRateTour(true));
    } else if (location.pathname === '/rate-detail') {
      dispatch(setRateDetailTour(true));
    } else if (location.pathname === '/add-idea') {
      dispatch(setAddIdeaTour(true));
    }
  };
  const selectedKeys =
    selectedKey === 'rate-detail'
      ? ['rate']
      : selectedKey.startsWith('add-scenario')
      ? ['scenarios']
      : selectedKey.startsWith('ranking-detail') ||
        selectedKey.startsWith('add-idea')
      ? ['rankings']
      : selectedKey === 'add-user' ||
        selectedKey.startsWith('edit-active-user') ||
        selectedKey.startsWith('edit-invited-user')
      ? ['user-management']
      : selectedKey.startsWith('stage1') ||
        selectedKey.startsWith('draft-form') ||
        selectedKey.startsWith('stageDetail')
      ? ['stages']
      : selectedKey.startsWith('department-details') ||
        selectedKey.startsWith('add-user')
      ? ['settings']
      : selectedKey.startsWith('integration')
      ? ['integration']
      : selectedKey.startsWith('add-goal')
      ? ['goal']
      : selectedKey.startsWith('measurementfield-list') ||
        selectedKey.startsWith('measurementfield')
      ? ['measurement-field']
      : selectedKey.startsWith('scenario-detail')
      ? ['scenarios']
      : selectedKey.startsWith('organization/add-organization')
      ? ['organizations']
      : selectedKey.startsWith('organization/edit-organization')
      ? ['organizations']
      : selectedKey.startsWith('organization/detail')
      ? ['organizations']
      : selectedKey.startsWith('partner/edit-partner')
      ? ['partner']
      : selectedKey.startsWith('partner/detail')
      ? ['partner']
      : selectedKey.startsWith('partner/add-partner')
      ? ['partner']
      : [selectedKey];

  const handleScope = async () => {
    let filtersIds = fetchScopingIds?.filter((e) => e !== viewingas?.id);
    dispatch(setScopingIds(filtersIds));
    if (filtersIds?.length) {
      let data = await getOrganizationCustom({
        id: filtersIds[0],
      });
      if (data) {
        dispatch(viewAs(data));
      }
    } else {
      dispatch(viewAs(null));
    }
    dispatch(setDefaultBatchID(''));
    history.push('/organizations');
  };

  return (
    <>
      <Layout className="layout">
        <Sider
          collapsed={screens.xs && collapsed}
          className={
            location?.pathname === '/stagepreview/' ||
            location?.pathname === '/stagepreview' ||
            location?.pathname === '/stage1preview'
              ? 'sidebar desktop-sidebar blur-menu'
              : 'sidebar desktop-sidebar'
          }
          breakpoint="lg"
          width={screens.xxl ? '300px' : '250px'}
          collapsedWidth="0"
          onBreakpoint={(broken) => {}}
          onCollapse={(collapsed, type) => {}}
          style={{ backgroundColor: orgNavigationColor?.navigation_color }}
        >
          <div className="image-wrapper">
            {loading ? (
              <Skeleton.Input active />
            ) : (
              <img src={orgNavigationLogo} alt="" className="logo" />
            )}
          </div>
          <Menu
            theme="dark"
            mode="inline"
            selectedKeys={selectedKeys}
            className="my-first-step"
          >
            {headerMenu?.map((item) =>
              item?.subMenu?.length ? (
                <SubMenu
                  key={item.key}
                  icon={item.icon}
                  id={item.key}
                  title={item?.name}
                >
                  {item.subMenu.map((subItem, index) => (
                    <Menu.Item key={subItem.key} icon={subItem?.icon}>
                      <Link
                        to={subItem.path}
                        onClick={() => {
                          setCollpased(true);
                        }}
                      >
                        {subItem.name}
                      </Link>
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <>
                  {isPartner && item?.key === 'organizations' ? (
                    <Typography.Text className="menu-text ml-20">
                      Clients
                    </Typography.Text>
                  ) : (
                    ''
                  )}
                  <Menu.Item key={item?.key} icon={item?.icon} id={item?.key}>
                    <Link
                      to={{
                        pathname: item?.path,
                        state: history.location.pathname,
                      }}
                      onClick={() => setCollpased(true)}
                    >
                      {item?.name}
                    </Link>
                  </Menu.Item>
                </>
              )
            )}
          </Menu>

          {isSMEGroup && (
            <Button
              type="text"
              className="tour-style mb-20"
              onClick={handleTakeTourState}
              size="middle"
            >
              Take a Tour
            </Button>
          )}
          <Footer
            className={
              screens.xs
                ? collapsed
                  ? 'footer ant-footer-inline-collapsed'
                  : 'footer ant-footer-inline'
                : 'footer ant-footer-inline'
            }
          >
            <div>
              {process.env.REACT_APP_VERSION
                ? process.env.REACT_APP_VERSION
                : '2.0'}
            </div>
            © {new Date().getFullYear()} {organizationName}
            <div>
              <span className="mt-10">Powered by StrAlign</span>
            </div>
          </Footer>
        </Sider>

        <Drawer
          title={<img src={orgNavigationLogo} alt="" className="logo" />}
          placement="left"
          onClose={onClose}
          open={open}
          className={
            history.location.pathname === '/goal'
              ? 'layout sidebar-drawer goal-page'
              : 'layout sidebar-drawer'
          }
          closeIcon={<></>}
        >
          <div className="sidebar">
            {screens.xs ? (
              (history.location.pathname === '/user-management' &&
                isAdminGroup) ||
              (history.location.pathname === '/user-management' &&
                currnetOrg) ? (
                <Button
                  className="bordered-button"
                  onClick={() => {
                    history.push('/add-user');
                    setOpen(false);
                  }}
                  icon={<img src={AddUser} height="20" alt="" />}
                  size="middle"
                >
                  Add User
                </Button>
              ) : (
                <>
                  <Button
                    className="bordered-button  capitalize-text my-sevent-step"
                    onClick={() => {
                      history.push('/add-idea');
                      setOpen(false);
                      setRun(false);
                      if (startMainTour) {
                        dispatch(setMainTour(false));
                        dispatch(setAddIdeaTour(true));
                      }
                    }}
                    size="middle"
                    icon={<img src={addIdeaIconWhite} height="20" alt="" />}
                  >
                    Add{' '}
                    {getUpdatedtageName &&
                      capitalizeFirstLetter(getUpdatedtageName)}
                  </Button>
                  {(history.location.pathname === '/goal' && isLeaderGroup) ||
                  (history.location.pathname === '/goal' &&
                    isSuperAdminGroup) ||
                  (history.location.pathname === '/goal' && isAdminGroup) ? (
                    <Button
                      className="bordered-button"
                      onClick={() => {
                        history.push('/add-goal');
                        setOpen(false);
                      }}
                      icon={<img src={Goal} height="20" alt="" />}
                      size="middle"
                    >
                      {screens.xs ? 'Add Goal' : 'Add a New Goal'}
                    </Button>
                  ) : null}
                </>
              )
            ) : (
              <></>
            )}
            {screens.xs ? (
              <Typography.Title level={5} className="menu-title">
                Menu
              </Typography.Title>
            ) : (
              <></>
            )}
            <Menu theme="dark" mode="inline" selectedKeys={selectedKeys}>
              {headerMenu?.map((item) =>
                item?.subMenu?.length ? (
                  <SubMenu
                    key={item.key}
                    icon={item.icon}
                    id={item.key}
                    title={item?.name}
                  >
                    {item.subMenu.map((subItem, index) => (
                      <Menu.Item key={subItem.key} icon={subItem?.icon}>
                        <Link
                          to={subItem.path}
                          onClick={() => {
                            setCollpased(true);
                            setOpen(false);
                          }}
                        >
                          {' '}
                          {subItem.name}
                        </Link>
                      </Menu.Item>
                    ))}
                  </SubMenu>
                ) : (
                  <Menu.Item key={item?.key} icon={item?.icon} id={item?.key}>
                    <Link
                      to={item?.path}
                      onClick={() => {
                        setCollpased(true);
                        setOpen(false);
                      }}
                    >
                      {item?.name}
                    </Link>
                  </Menu.Item>
                )
              )}
            </Menu>
            <Footer
              className={
                screens.xs
                  ? collapsed
                    ? 'footer ant-footer-inline-collapsed'
                    : 'footer ant-footer-inline'
                  : 'footer ant-footer-inline'
              }
            >
              <div>
                {process.env.REACT_APP_VERSION
                  ? process.env.REACT_APP_VERSION
                  : '2.0'}
              </div>
              © {new Date().getFullYear()} Echo Consulting, LLC
            </Footer>
          </div>
        </Drawer>

        <Layout>
          <Header
            className={
              location?.pathname === '/stagepreview/' ||
              location?.pathname === '/stage1preview'
                ? 'main-haeder blur-menu'
                : 'main-haeder'
            }
          >
            <Row align="middle" justify="space-between">
              <Row align="middle" justify="center">
                <Button
                  type="text"
                  onClick={showDrawer}
                  className="mobile-menu p-0 fs-16 "
                >
                  <MenuUnfoldOutlined />
                </Button>
                <Typography.Title
                  level={4}
                  className="mb-0 title text-black fw-600 header-text"
                >
                  <BackComponent />
                  {history?.location?.state?.previousPath ===
                  '/compare-scenarios' ? (
                    <Breadcrumb className="header-breadcrumb">
                      <Breadcrumb.Item>
                        <Link to="/scenarios">Scenarios</Link>
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>
                        <Link
                          to={{
                            pathname: '/compare-scenarios',
                            state: fetchSelectedComparisonIDs,
                          }}
                        >
                          Compare
                        </Link>
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>Scenario Planning</Breadcrumb.Item>
                    </Breadcrumb>
                  ) : (
                    headerMenu?.map((item) =>
                      item?.subMenu?.length
                        ? item?.subMenu?.map(
                            (i) =>
                              window.location.pathname === i?.path && i?.name
                          )
                        : window.location.pathname === item?.path && item?.name
                    )
                  )}

                  {(window.location.pathname === '/rankings' ||
                    window.location.pathname === '/compare' ||
                    window.location.pathname === '/' ||
                    window.location.pathname === '/rate' ||
                    window.location.pathname === '/scenarios' ||
                    window.location.pathname === '/scenario-planning' ||
                    window.location.pathname === '/settings' ||
                    window.location.pathname === '/user-management' ||
                    window.location.pathname === '/import' ||
                    window.location.pathname === '/goal') && (
                    <Tooltip
                      className="tooltip-boxshadow"
                      title={
                        window.location.pathname === '/rankings'
                          ? rankingTooltip
                          : window.location.pathname === '/compare'
                          ? compareTooltip
                          : window.location.pathname === '/'
                          ? tooltipTitle
                          : window.location.pathname === '/rate'
                          ? rateTooltip
                          : window.location.pathname === '/scenarios'
                          ? scenarioTooltip
                          : window.location.pathname === '/scenario-planning'
                          ? scenarioPlanningTooltip
                          : window.location.pathname === '/settings'
                          ? settingTooltip
                          : window.location.pathname === '/user-management'
                          ? userTooltip
                          : window.location.pathname === '/import'
                          ? importTooltip
                          : window.location.pathname === '/goal'
                          ? goalTooltip
                          : null
                      }
                    >
                      <img
                        src={questioncircle}
                        alt=""
                        className="header-question-icon ml-5"
                      />
                    </Tooltip>
                  )}
                </Typography.Title>
              </Row>
              <Row align="middle">
                {viewingas && (
                  <span
                    style={{
                      float: 'right',
                      paddingRight: 10,
                      zIndex: 999,
                      position: 'relative',
                    }}
                  >
                    <strong className="back-to-admin-link">
                      Viewing as{' '}
                      <strong style={{ color: '#0F61DB' }}>
                        {viewingas?.name && viewingas?.name}{' '}
                        <span style={{ color: '#464646' }}>
                          {viewingas?.type === 'PARTNER'
                            ? '(partner view)'
                            : '(admin view)'}
                        </span>
                      </strong>
                      <Popconfirm
                        className="switchDilog"
                        placement="bottomRight"
                        title={
                          <Title level={5}>
                            Do you want to switch to your profile?
                          </Title>
                        }
                        onConfirm={handleScope}
                        okText="Yes"
                        cancelText="No"
                        icon={''}
                        okType=""
                        okButtonProps={{
                          size: 'middle',
                          height: 'auto',
                          padding: '4px 15px',
                          background: '#416B88',
                          fontSize: 16,
                          borderColor: '#416B88',
                        }}
                        cancelButtonProps={{
                          size: 'large',
                          type: 'secondary',
                        }}
                      >
                        <strong
                          style={{
                            paddingLeft: 8,
                            color: '#A9A9A9',
                            cursor: 'pointer',
                          }}
                        >
                          <CloseOutlined />
                        </strong>
                      </Popconfirm>{' '}
                    </strong>
                  </span>
                )}
                <HeaderButton
                  startMainTour={startMainTour}
                  getUpdatedtageName={getUpdatedtageName}
                  setRun={setRun}
                />
                {(isAdminGroup || currnetOrg?.id) && (
                  <Button
                    type="text"
                    className="p-0 ml-15"
                    onClick={() => history.push('/settings')}
                  >
                    <SettingOutlined className="fs-20" />
                  </Button>
                )}
                <Button
                  type="text"
                  className="ml-15 w-auto"
                  onClick={() => window.open('https://stralign.com/help')}
                  icon={<img src={helpIcon} alt="" />}
                ></Button>

                <Badge count={isReadNotification?.length} size="small">
                  <Button
                    type="text"
                    className="p-0 ml-15 mr-15"
                    onClick={showNotificationDrawer}
                  >
                    <BellOutlined className="fs-20" />
                  </Button>
                </Badge>

                <Dropdown overlay={userMenu} trigger={['click']}>
                  <span className="cursor-pointer fs-16 capital-text">
                    {screens.xs ? (
                      <> </>
                    ) : (
                      <span className={screens.xs ? '' : 'mr-10'}>
                        {localStorage.getItem('given_name')}
                      </span>
                    )}

                    {!currnetOrg?.id && getProfilePic ? (
                      <Image
                        className="mr-10 mb-5"
                        style={{
                          width: '30px',
                          height: '30px',
                          lineHeight: '30px',
                          borderRadius: '100px',
                        }}
                        preview={false}
                        src={getProfilePic}
                      />
                    ) : (
                      <Avatar icon={<UserOutlined />}></Avatar>
                    )}
                  </span>
                </Dropdown>
              </Row>
            </Row>
          </Header>

          <Content className="main" id="main">
            {props.children}
          </Content>
        </Layout>
      </Layout>

      <Drawer
        width={screens.xs ? '100%' : '35%'}
        className="related-item-drawer"
        title={
          <Row justify="space-between" align="middle">
            <Typography.Title level={3} className="mb-0">
              Alerts
            </Typography.Title>
            <Button
              type="text"
              className="text-blue fw-bold "
              onClick={() => markAsRead()}
              size="middle"
            >
              Mark all as read
            </Button>
          </Row>
        }
        placement="right"
        closeIcon={
          <CloseCircleOutlined className="fs-20 text-primary button-font" />
        }
        onClose={onCloseNotification}
        open={openNotification}
      >
        <Tabs
          className="notification-tab"
          defaultActiveKey="1"
          items={items}
          onChange={(e) => setAllTab(e === '1' ? true : false)}
        />
      </Drawer>
      <KudosModal
        setIsKudosModal={setIsKudosModal}
        isKudosModal={isKudosModal}
        totalSeconds={getSeconds}
        selectedIndex={getTotalComparison}
      />
      <MileStoneModal
        setIsMilestoneModal={setIsMilestoneModal}
        isMilestoneModal={isMilestoneModal}
        getTotalCompares={getTotalComparison}
        getSeconds={getSeconds}
      />
      {startMainTour && (
        <OnboardingHome run={run} setRun={setRun} setOpenModal={setOpenModal} />
      )}

      {isSMEGroup && startFirstTimeTour && !getStartTourModalTrue && (
        <StartTourModal
          setOpenModal={setOpenModal}
          openModal={openModal}
          setRun={setRun}
        />
      )}
    </>
  );
}

