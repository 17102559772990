import React, { memo, useState } from 'react';
import { Button, Typography } from 'antd';

const ReadMoreText = ({ text, maxLength }) => {
  const [showFullText, setShowFullText] = useState(false);
  const displayText = showFullText ? text : text?.slice(0, maxLength);

  const toggleShowFullText = () => {
    setShowFullText(!showFullText);
  };

  return (
    <Typography.Text level={5} className="text-black p">
      {displayText}
      {text?.length > maxLength && (
        <div className="mt-5 pb-10">
          {displayText?.length < text?.length && '... '}
          <Button
            type="text"
            onClick={toggleShowFullText}
            className="viewmore-btn mr-5"
          >
            {showFullText ? 'View Less' : 'View More'}
          </Button>
        </div>
      )}
    </Typography.Text>
  );
};

export default memo(ReadMoreText);

