import React, { memo, useState, useEffect } from 'react';
import {
  Button,
  Col,
  Divider,
  Empty,
  Input,
  Modal,
  Row,
  Typography,
} from 'antd';
import { ECHOIMAGES } from 'Assets/Images';

const AddGoal = ({
  setListLeaderGoal,
  listLeaderGoalBackup,
  listLeaderGoal,
  addGoal,
  setRemovedItems,
  removedItems,
  setGoalModalOpen,
  goalModalOpen,
  setGoalWeight,
  goalWeight,
  setAddGoal,
}) => {
  const { Search } = Input;
  const { minusButton } = ECHOIMAGES.PriorityBatches;

  const [localAddGoal, setLocalAddGoal] = useState(addGoal);
  const [localGoalWeight, setLocalGoalWeight] = useState(goalWeight);
  const [localListLeaderGoal, setLocalListLeaderGoal] =
    useState(listLeaderGoal);
  const [localRemovedItems, setLocalRemovedItems] = useState(removedItems);

  const onSearch = (e) => {
    if (e !== '') {
      const temp = listLeaderGoalBackup.filter(
        (item) =>
          item?.name && item?.name.toLowerCase().includes(e.toLowerCase())
      );
      setLocalListLeaderGoal(temp);
    } else {
      setLocalListLeaderGoal(listLeaderGoalBackup);
    }
  };

  const calculateTotal = () => {
    let total = 0;
    for (let i = 0; i < localAddGoal.length; i++) {
      total += parseInt(localAddGoal[i].weight || 0);
    }
    return total;
  };

  const handleInputChange = (event, index) => {
    const res = /^([1-9][0-9]{0,1}|100)$/; // Regex to allow only values between 1 to 100
    const re = /^[0-9]{1,3}$/; // Regex to allow only 1-3 digit numeric values
    if (
      (event.target.value === '' || re.test(event.target.value)) &&
      (event.target.value === '' || res.test(event.target.value))
    ) {
      const inputValue = event.target.value ? event.target.value : '';
      const newValues = [...localGoalWeight];
      newValues[index] = inputValue;
      setLocalGoalWeight(newValues);
      const temp = [...localAddGoal];
      temp[index]['weight'] = inputValue;
      setLocalAddGoal(temp);
    }
  };

  const handleAddGoal = (item) => {
    setLocalAddGoal([...localAddGoal, { ...item, weight: '' }]);
    setLocalListLeaderGoal(localListLeaderGoal.filter((elem) => elem !== item));
    setLocalRemovedItems([...localRemovedItems, item]);
  };

  const handleRemoveGoal = (index, item) => {
    const tempSet = new Set(localListLeaderGoal.map((el) => el.id));

    listLeaderGoalBackup.forEach((el) => {
      if (el.id === item.id && !tempSet.has(el.id)) {
        tempSet.add(el.id);
      }
    });

    const updatedListLeaderGoal = Array.from(tempSet).map((id) =>
      listLeaderGoalBackup.find((el) => el.id === id)
    );

    setLocalListLeaderGoal(updatedListLeaderGoal);
    setLocalRemovedItems(localRemovedItems.filter((_, key) => key !== index));
    setLocalAddGoal(localAddGoal.filter((_, key) => key !== index));
  };

  const handleOk = () => {
    setAddGoal(localAddGoal);
    setGoalWeight(localGoalWeight);
    setListLeaderGoal(localListLeaderGoal);
    setRemovedItems(localRemovedItems);
    setGoalModalOpen(false);
  };

  useEffect(() => {
    setLocalAddGoal(addGoal);
    setLocalGoalWeight(goalWeight);
    setLocalListLeaderGoal(listLeaderGoal);
    setLocalRemovedItems(removedItems);
  }, [addGoal, goalWeight, listLeaderGoal, removedItems]);

  return (
    <Modal
      open={goalModalOpen}
      onCancel={() => setGoalModalOpen(false)}
      width={1000}
      onOk={handleOk}
      className="goal-modal"
    >
      <Row className="goal-box mt-0 selected-border added-goal-modal p-20">
        <Typography.Title level={4} className="m-auto mb-0">
          Add Goal to Scenario
        </Typography.Title>
        <Divider className="modal-divider" />
        <Col span={11}>
          <Typography.Title level={5} className="title fs-16 mb-10 fw-500">
            Added Goals
          </Typography.Title>
          <div className="goal-scroll">
            {localAddGoal?.length > 0 ? (
              localAddGoal?.map((item, index) => {
                return (
                  <Row className="create-goal" key={index}>
                    <Col span={24}>
                      <Row
                        justify="space-between"
                        align="middle"
                        className={
                          item.active
                            ? ' added-goal'
                            : ' added-goal archive-goals'
                        }
                        gutter={[0, 20]}
                      >
                        <Typography.Title
                          level={5}
                          className="mb-0 title fs-16 fw-500"
                          style={
                            true
                              ? {
                                  width: 240,
                                }
                              : undefined
                          }
                          ellipsis={
                            true
                              ? {
                                  tooltip: item?.name,
                                }
                              : false
                          }
                        >
                          {item?.name}
                        </Typography.Title>
                        <Row align="middle">
                          <Typography.Title
                            level={5}
                            className="mb-0 mr-10 fs-14"
                          >
                            {' '}
                          </Typography.Title>
                          <Input
                            value={item.weight}
                            className="input-number"
                            suffix="%"
                            onChange={(event) =>
                              handleInputChange(event, index)
                            }
                          />
                          <Divider type="vertical" className="goal-divider" />
                          <div>
                            <Button
                              onClick={() => handleRemoveGoal(index, item)}
                              className="minus-btn p-0"
                            >
                              <img
                                src={minusButton}
                                alt="minus"
                                className="mr-0"
                              />
                            </Button>
                          </div>
                        </Row>
                      </Row>
                    </Col>
                  </Row>
                );
              })
            ) : (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<span>No added goals</span>}
              />
            )}
          </div>
          {localAddGoal?.length > 0 && (
            <Row justify="space-between" align="middle">
              <Typography.Title></Typography.Title>
              <Typography.Title level={5} className="mt-0 fw-400 fs-14">
                Total weight : {calculateTotal()}%{' '}
              </Typography.Title>
            </Row>
          )}
        </Col>
        <Col span={1}>
          <Divider type="vertical" className="h-auto" />
        </Col>
        <Col span={12}>
          <Typography.Title level={5} className="title fs-16 mb-10 fw-500">
            Goals
          </Typography.Title>
          <Row
            justify="space-between"
            align="middle"
            className="idea-header w-100"
          >
            <Search
              className="searchbar-style mb-10"
              placeholder="Search by keyword or ID"
              onChange={(e) => onSearch(e.target.value)}
            />
          </Row>
          <div className="goal-scroll">
            {localListLeaderGoal?.length ? (
              localListLeaderGoal
                ?.filter(
                  (el) =>
                    el.active && !localAddGoal.map((i) => i.id).includes(el.id)
                )
                ?.map((item, index) => {
                  return (
                    <div
                      className="add-goal mb-10 scrollable-panel"
                      key={index}
                    >
                      <Row justify="space-between" className="w-100">
                        <Typography.Title
                          level={5}
                          className="fw-500 fs-16"
                          style={
                            true
                              ? {
                                  width: 240,
                                }
                              : undefined
                          }
                          ellipsis={
                            true
                              ? {
                                  tooltip: item?.name,
                                }
                              : false
                          }
                        >
                          {item?.name}
                        </Typography.Title>
                        <Button
                          className="add-goal-btn blue-filled-btn"
                          onClick={() => handleAddGoal(item)}
                          size="middle"
                        >
                          Add
                        </Button>
                      </Row>
                      <Row>
                        <Typography.Text>{item?.description}</Typography.Text>
                      </Row>
                      <Row className="goal-owner">
                        <Typography.Text level={5}>Owner :</Typography.Text>
                        <Typography.Text level={5}>
                          {' '}
                          {item?.owner?.firstName
                            ? item?.owner?.firstName +
                              ' ' +
                              item?.owner?.lastName
                            : '-'}
                        </Typography.Text>
                      </Row>
                    </div>
                  );
                })
            ) : (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<span>No added goals</span>}
              />
            )}
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

export default memo(AddGoal);

