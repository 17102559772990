import {
  Col,
  Row,
  Typography,
  Form,
  Input,
  Select,
  Button,
  Modal,
  message,
  Drawer,
  Grid,
  Tooltip,
  Upload,
  DatePicker,
  Slider,
} from 'antd';
import React, { useEffect, useState } from 'react';
import RelatedItems from './RelatedItems';
import { ECHOIMAGES } from '../../Assets/Images';
import './idea.less';
import { FetchUser, fuzzySearchGammas, linkAddIdea } from './IdeaFunction';
import { useSelector, useDispatch } from 'react-redux';
import {
  getAddIdeaTour,
  getCongratsModal,
  getCurrentOrganization,
  getCurrnetUserData,
  getHomeTour,
  isSME,
  setAddIdeaTour,
  setCongratsModal,
} from '../../store/slices/loginSlice';
import {
  ArrowLeftOutlined,
  RightOutlined,
  InfoCircleOutlined,
  QuestionCircleOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import {
  fetchOrganizationStages,
  fetchOrgDepartments,
} from 'utils/CommonFunctions';
import { SaveFileToS3 } from 'utils/commonMethods';
import { RESTAddIdea, RESTGet, RESTNotification } from 'utils/RESTApi';
import FormSkeleton from 'Common/FormSkeleton';
import { Storage } from 'aws-amplify';
import './opportunity.less';
import { capitalizeFirstLetter, UserRole, userStatus } from 'utils/Constants';
import { getNewStageName, setNewStageName } from 'store/slices/StageSlice';
import OnboardingIdea from 'Common/OnboardingIdea';
import SubmitFirstIdeaModal from './Components/SubmitFirstIdeaModal';

export default function AddIdea() {
  const { TextArea } = Input;
  const { Option } = Select;
  const { Paragraph } = Typography;

  const { addIdeaModal } = ECHOIMAGES.ideaIcons;
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState([]);
  const [title, setTitle] = useState('');
  const [relatedItemLoader, setRelatedItemLoader] = useState(false);
  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const getUpdatedtageName = useSelector(getNewStageName);
  const isSMEGroup = useSelector(isSME);
  const startFirstTimeTour = useSelector(getHomeTour);
  const ideaTour = useSelector(getAddIdeaTour);

  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;
  const userId = currnetOrg?.adminID || loginUsrOrg?.id;
  const history = useHistory();
  const [listAllDepartments, setListAllDepartments] = useState([]);
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const [linkedIdea, setLinkedIdea] = useState([]);

  const [formLayout, setFormLayout] = useState([]);
  const [levelID, setLevelID] = useState();
  const [formLoading, setFormLoading] = useState(false);
  const [userData, setUserData] = useState([]);
  const [openAddIdeaModal, setOpenAddIdeaModal] = useState(false);
  const dispatch = useDispatch();
  const showCongratsModal = useSelector(getCongratsModal);
  const [mfList, setMFList] = useState([]);
  const [scaleValue, setScaleValue] = useState({});

  const {
    red,
    yellow,
    green,
    blue,
    gray,
    greenSymbol,
    triangle,
    stopIcon,
    rightIcon,
    exclamationMark,
    crossIcon,
    redExclamationMark,
    minusIcon,
    downArrowIcon,
    circle1,
    circle2,
    circle3,
    circle4,
    circle5,
    analytics1,
    analytics2,
    analytics3,
    analytics4,
    analytics5,
    cloudy,
    sunny,
    partlySunny,
    rainy,
    stromy,
    advanced,
    easy,
    intermediate,
    exportsOnly,
    noPain,
    mild,
    moderate,
    severe,
    verySevere,
    extreme,
    empty,
    one,
    two,
    three,
    four,
    five,
    emptyProgress,
    quarter,
    half,
    threeQuarter,
    full,
    up,
    down,
    unChanged,
    emptyHuman,
    oneHuman,
    TwoHuman,
    threeHuman,
    fourHuman,
    fiveHuman,
    downFour,
    angleDown,
    angleUp,
    upFour,
    downFive,
    angleDownFive,
    angleUpFive,
    upFive,
    sideWays,
    emptyDollar,
    oneDollar,
    twoDollar,
    threeDollar,
    fourDollar,
    fiveDollar,
    emptyHeart,
    oneHeart,
    twoHeart,
    threeHeart,
    fourHeart,
    fiveHeart,
    downThree,
    sideWayThree,
    upThree,
    downBlack,
    rightBlack,
    upBlack,
    leftBlack,
  } = ECHOIMAGES.rankings;

  const getAllUsers = async () => {
    try {
      let data = {
        and: [
          { status: { ne: userStatus.invited } },
          { status: { ne: userStatus.inactive } },
        ],
      };
      let id = { organizationID: orgId };
      let nextToken = null;
      let allUserData = [];
      while (true) {
        let res = await FetchUser(data, id, nextToken);
        allUserData.push(...res.items);

        if (!res?.nextToken) {
          break;
        }
        nextToken = res?.nextToken;
      }
      await processUserData(allUserData);
    } catch (err) {
      console.log(err);
    }
  };

  const processUserData = async (userData) => {
    setUserData(userData);
  };

  const getStageName = async () => {
    setFormLoading(true);
    try {
      const sortedStages = await fetchOrganizationStages(orgId);
      dispatch(setNewStageName(sortedStages?.[0]?.name));
      setLevelID(sortedStages?.[0]?.id);
      let parseJSON = JSON.parse(sortedStages?.[0]?.form?.layout);
      setFormLayout(parseJSON);
      setFormLoading(false);
    } catch (err) {
      setFormLoading(false);
      console.log(err);
    }
  };

  const [fileUpload, setFileUpload] = useState([]);
  const [attachment, setAttachMent] = useState([]);
  const [attachedLinks, setAttchedLinks] = useState([]);
  const [insertLink, setInsertLink] = useState('');
  const [createFile, setCreateFile] = useState([]);

  const { Dragger } = Upload;
  const [joyrideStepIndex, setJoyrideStepIndex] = useState(0);

  const showModal = async () => {
    try {
      await form.validateFields();
      setIsModalOpen(true);
      dispatch(setAddIdeaTour(false));
    } catch (err) {
      console.log(err);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const listOrgDepartments = async () => {
    const departments = await fetchOrgDepartments(orgId);
    setListAllDepartments(departments);
  };

  const fetchFezzySearch = async () => {
    setRelatedItemLoader(true);
    const data = await fuzzySearchGammas({
      title: title,
      organizationID: orgId,
    });
    setData(data?.items);
    setRelatedItemLoader(false);
  };
  const handleDescLength = () => {
    if (form.getFieldsValue()?.description?.length === 350) {
      message.warning('Max characters limit reached');
    }
  };

  const handleIdeaTour = () => {
    if (data?.length > 0) {
      setTimeout(() => {
        setJoyrideStepIndex(1);
      }, 2000);
    } else {
      setTimeout(() => {
        setJoyrideStepIndex(2);
      }, 2000);
    }
  };

  const props = {
    name: 'file',
    accept:
      // eslint-disable-next-line max-len
      'image/*, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, application/vnd.google-apps.spreadsheet',
    multiple: true,
    onChange(info) {
      if (info.fileList?.length >= 5) {
        message.error('You can only upload up to 5 files!');
      } else {
        setCreateFile(info.fileList);
      }
    },
    async beforeUpload(file) {
      return false;
    },
  };

  // for upload filetoS3
  const uploadFileToS3 = async (file) => {
    return await SaveFileToS3(file);
  };

  const deleteAttachLinks = async (el, index) => {
    if (index >= 0 && index < attachedLinks.length) {
      // The item is in the attachedLinks array
      const updatedAttachedLinks = [...attachedLinks];
      updatedAttachedLinks.splice(index, 1);
      setAttchedLinks(updatedAttachedLinks);
    } else if (
      index >= attachedLinks.length &&
      index < attachedLinks.length + attachment.length
    ) {
      // The item is in the attachment array
      const updatedAttachment = [...attachment];
      const attachmentIndex = index - attachedLinks.length;
      updatedAttachment.splice(attachmentIndex, 1);
      setAttachMent(updatedAttachment);
    }
  };

  // to delete upload files from s3 and dynmoDB
  const deleteUploadedFiles = async (item, key) => {
    await Storage.remove(item);
    message.success('File deleted successfully');
    setFileUpload(fileUpload.filter((el, index) => index !== key));
  };

  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState('');

  const handleChangeDate = (value) => {
    const dateString = value ? value.format('YYYY-MM-DD') : '';
    setDate(dateString);
  };

  const updateFormItems = async () => {
    setLoading(true);
    try {
      let formValue = form.getFieldsValue();
      const uploadPromises = [];
      formLayout?.map(async (item) => {
        if (item?.dropdownValue === 'Multiple') {
          if (item?.optionType === 'Custom') {
            item['value'] =
              formValue?.[item?.id] !== undefined &&
              formValue?.[item?.id].map((item) => item || []);
          } else if (item?.label === 'Department') {
            item['value'] = formValue?.[item?.id];
          } else if (item?.label === 'Contributors') {
            const oldID = item?.value?.items.map((item) => item?.userId);
            item['value'] = formValue?.[item?.id];
            const formArray = formValue?.[item?.id] || [];
            const itemIDs = item?.value?.items.map((item) => item?.id) || [];

            const newIDs = formArray.filter(
              (id) => !itemIDs.includes(id) && !oldID?.includes(id)
            );

            if (newIDs.length > 0) {
              newIDs.map(async (item) => {
                await RESTNotification(
                  'createGammasAndContributors',
                  item,
                  id,
                  userId
                );
              });
            }
          } else {
            if (formValue[item?.id] !== undefined) {
              item['value'] = formValue[item?.id];
            } else {
              item['value'] = []; // Set it to an empty array when formValue[item?.id] is undefined
            }
          }
        } else {
          if (
            item?.inputType === 'multiLine' &&
            item?.id !== process.env.REACT_APP_DESCRIPTION_ID
          ) {
            if (formValue[item?.id] !== undefined) {
              item['value'] = [formValue[item?.id]];
            } else {
              item['value'] = []; // Set it to an empty array when formValue[item?.id] is undefined
            }
          } else if (
            item?.inputType === 'singleLine' &&
            item?.id !== process.env.REACT_APP_TITLE_ID
          ) {
            if (formValue[item?.id] !== undefined) {
              item['value'] = [formValue[item?.id]];
            } else {
              item['value'] = []; // Set it to an empty array when formValue[item?.id] is undefined
            }
          } else if (item?.inputType === 'link') {
            item['value'] = attachment;
          } else if (item?.inputType === 'dragger') {
            const uploadFilePromises = createFile?.map(async (el) => {
              const uplaodFile = await uploadFileToS3(el?.originFileObj);
              return uplaodFile;
            });
            uploadPromises.push(...uploadFilePromises);
          } else if (item?.id === process.env.REACT_APP_SPONSOR_ID) {
            item['value'] = { id: formValue?.[item?.id] };
            if (formValue?.[item?.id] !== item?.value?.id) {
              await RESTNotification(
                'updateSponsor',
                item?.value?.id,
                id,
                userId
              );
            }
          } else if (item?.inputType === 'datepicker') {
            if (formValue[item?.id] !== undefined) {
              item['value'] = [date];
            } else {
              item['value'] = [];
            }
          } else if (item?.type === 'measurement') {
            for (const id in scaleValue) {
              const itemToUpdate = formLayout.find((item) => item.id === id);
              if (itemToUpdate) {
                itemToUpdate.value = scaleValue[id];
              }
            }
          } else if (item?.inputType === 'symbol') {
            if (formValue[item?.id] !== undefined) {
              item['newValue'] = [formValue[item?.id]];
            } else {
              item['newValue'] = [];
            }
          } else if (
            item?.dropdownValue === 'Single' &&
            item?.id !== process.env.REACT_APP_SPONSOR_ID
          ) {
            if (formValue[item?.id] !== undefined) {
              item['value'] = Array.isArray(formValue[item?.id])
                ? formValue[item?.id]
                : [formValue[item?.id]];
            } else {
              item['value'] = []; // Set it to an empty array when formValue[item?.id] is undefined
            }
          } else {
            if (item?.label !== 'Department') {
              item['value'] = formValue?.[item?.id];
            }
          }
        }
      });
      const uploadResults = await Promise.all(uploadPromises);
      const objectiveData = uploadResults.map((item) => item?.key);
      formLayout?.forEach((item) => {
        if (item?.inputType === 'dragger') {
          item['value'] = objectiveData?.length > 0 && objectiveData;
        }
      });
      // Now, you can make the API call with the updated formLayoutByGamma
      let res = await RESTAddIdea({
        layout: [...formLayout],
        organizationID: orgId,
        userID: userId,
        levelID: levelID,
      });
      if (linkedIdea && linkedIdea?.length > 0) {
        Promise.all(
          linkedIdea.map(async (item) => {
            const inputData = {
              gammaLinkedToId: res?.body?.id,
              linkedGammasLinkedGammaId: item,
            };
            await linkAddIdea(inputData);
          })
        );
      }
      message.success('item added successfully');
      if (isSMEGroup && startFirstTimeTour) {
        setOpenAddIdeaModal(true);
        setIsModalOpen(false);
        setTimeout(() => {
          setOpenAddIdeaModal(false);
          dispatch(setCongratsModal(true));
          history.push('/rankings');
        }, 4000);
      } else {
        history.push('/rankings');
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const getMFList = async () => {
    try {
      let listApi = `/measurement/all?organization_id=${orgId}`;
      let res = await RESTGet(listApi);
      setMFList(res?.body?.items);
    } catch (err) {
      console.log(err);
    }
  };

  const fourIcons = [
    {
      key: 1,
      item: <img src={red} alt="red" />,
      name: 'Red',
    },
    {
      key: 2,
      item: <img src={yellow} alt="yellow" />,
      name: 'Yellow',
    },
    {
      key: 3,
      item: <img src={blue} alt="blue" />,
      name: 'Blue',
    },
    {
      key: 4,
      item: <img src={green} alt="green" />,
      name: 'Green',
    },
  ];

  const threeIcons = [
    {
      key: 1,
      item: <img src={red} alt="red" />,
      name: 'Red',
    },
    {
      key: 2,
      item: <img src={yellow} alt="yellow" />,
      name: 'Yellow',
    },
    {
      key: 3,
      item: <img src={green} alt="green" />,
      name: 'Green',
    },
  ];

  const fiveIcons = [
    {
      key: 1,
      item: <img src={red} alt="red" />,
      name: 'Red',
    },
    {
      key: 2,
      item: <img src={yellow} alt="yellow" />,
      name: 'Yellow',
    },
    {
      key: 3,
      item: <img src={blue} alt="blue" />,
      name: 'Blue',
    },
    {
      key: 4,
      item: <img src={green} alt="green" />,
      name: 'Green',
    },
    {
      key: 5,
      item: <img src={gray} alt="gray" />,
      name: 'Gray',
    },
  ];

  const shapeIcon = [
    {
      key: 1,
      item: <img src={greenSymbol} alt="" />,
      name: 'Yes',
    },
    {
      key: 2,
      item: <img src={triangle} alt="" />,
      name: 'Hold',
    },
    {
      key: 3,
      item: <img src={stopIcon} alt="" />,
      name: 'No',
    },
  ];

  const rightCrossIcon = [
    {
      key: 1,
      item: <img src={rightIcon} alt="" />,
      name: 'Yes',
    },
    {
      key: 2,
      item: <img src={exclamationMark} alt="" />,
      name: 'Hold',
    },
    {
      key: 3,
      item: <img src={crossIcon} alt="" />,
      name: 'No',
    },
  ];

  const arrowMinusIcon = [
    {
      key: 1,
      item: <img src={redExclamationMark} alt="" />,
      name: 'High',
    },
    {
      key: 2,
      item: <img src={minusIcon} alt="" />,
      name: 'Meduim',
    },
    {
      key: 3,
      item: <img src={downArrowIcon} alt="" />,
      name: 'Low',
    },
  ];
  const pieChartIcon = [
    {
      key: 1,
      item: <img src={circle1} alt="" />,
      name: 'Empty',
    },
    {
      key: 2,
      item: <img src={circle2} alt="" />,
      name: 'Quarter',
    },
    {
      key: 3,
      item: <img src={circle3} alt="" />,
      name: 'Half',
    },
    {
      key: 4,
      item: <img src={circle4} alt="" />,
      name: 'Three Quarter',
    },
    {
      key: 5,
      item: <img src={circle5} alt="" />,
      name: 'Full',
    },
  ];

  const analytics = [
    {
      key: 1,
      item: <img src={analytics1} alt="" />,
      name: 'Empty',
    },
    {
      key: 2,
      item: <img src={analytics2} alt="" />,
      name: 'Quarter',
    },
    {
      key: 3,
      item: <img src={analytics3} alt="" />,
      name: 'Half',
    },
    {
      key: 4,
      item: <img src={analytics4} alt="" />,
      name: 'Three Quarter',
    },
    {
      key: 5,
      item: <img src={analytics5} alt="" />,
      name: 'Full',
    },
  ];

  const cloudIcons = [
    {
      key: 1,
      item: <img src={cloudy} alt="" />,
      name: 'Cloudy',
    },
    {
      key: 2,
      item: <img src={sunny} alt="" />,
      name: 'Sunny',
    },
    {
      key: 3,
      item: <img src={partlySunny} alt="" />,
      name: 'Partly Sunny',
    },
    {
      key: 4,
      item: <img src={rainy} alt="" />,
      name: 'Rainy',
    },
    {
      key: 5,
      item: <img src={stromy} alt="" />,
      name: 'Stromy',
    },
  ];

  const circleSquareIcon = [
    {
      key: 1,
      item: <img src={advanced} alt="" />,
      name: 'Advanced',
    },
    {
      key: 2,
      item: <img src={easy} alt="" />,
      name: 'Easy',
    },
    {
      key: 3,
      item: <img src={intermediate} alt="" />,
      name: 'Intermediate',
    },
    {
      key: 4,
      item: <img src={exportsOnly} alt="" />,
      name: 'Exports Only',
    },
  ];

  const expressionIcon = [
    {
      key: 1,
      item: <img src={noPain} alt="" />,
      name: 'No Pain',
    },
    {
      key: 2,
      item: <img src={mild} alt="" />,
      name: 'Mild',
    },
    {
      key: 3,
      item: <img src={moderate} alt="" />,
      name: 'Moderate',
    },
    {
      key: 4,
      item: <img src={severe} alt="" />,
      name: 'Severe',
    },
    {
      key: 5,
      item: <img src={verySevere} alt="" />,
      name: 'Very Severe',
    },
    {
      key: 6,
      item: <img src={extreme} alt="" />,
      name: 'Extreme',
    },
  ];

  const ratingsIcon = [
    {
      key: 1,
      item: <img src={empty} alt="" />,
      name: 'Empty',
    },
    {
      key: 2,
      item: <img src={one} alt="" />,
      name: 'One',
    },
    {
      key: 3,
      item: <img src={two} alt="" />,
      name: 'Two',
    },
    {
      key: 4,
      item: <img src={three} alt="" />,
      name: 'Three',
    },
    {
      key: 5,
      item: <img src={four} alt="" />,
      name: 'Four',
    },
    {
      key: 6,
      item: <img src={five} alt="" />,
      name: 'Five',
    },
  ];

  const progressBarIcon = [
    {
      key: 1,
      item: <img src={emptyProgress} alt="" />,
      name: 'Empty',
    },
    {
      key: 2,
      item: <img src={quarter} alt="" />,
      name: 'Quarter',
    },
    {
      key: 3,
      item: <img src={half} alt="" />,
      name: 'Half',
    },
    {
      key: 4,
      item: <img src={threeQuarter} alt="" />,
      name: 'Three Quarter',
    },
    {
      key: 5,
      item: <img src={four} alt="" />,
      name: 'Four',
    },
    {
      key: 6,
      item: <img src={full} alt="" />,
      name: 'Full',
    },
  ];

  const upDownIcon = [
    {
      key: 1,
      item: <img src={up} alt="" />,
      name: 'Up',
    },
    {
      key: 2,
      item: <img src={down} alt="" />,
      name: 'Down',
    },
    {
      key: 3,
      item: <img src={unChanged} alt="" />,
      name: 'Unchanged',
    },
  ];
  const fourArrowIcon = [
    {
      key: 1,
      item: <img src={downFour} alt="" />,
      name: 'Down',
    },
    {
      key: 2,
      item: <img src={angleDown} alt="" />,
      name: 'Angle Down',
    },
    {
      key: 3,
      item: <img src={angleUp} alt="" />,
      name: 'Angle Up',
    },
    {
      key: 4,
      item: <img src={upFour} alt="" />,
      name: 'Up',
    },
  ];
  const fiveArrowIcon = [
    {
      key: 1,
      item: <img src={downFive} alt="" />,
      name: 'Down',
    },
    {
      key: 2,
      item: <img src={angleDownFive} alt="" />,
      name: 'Angle Down',
    },
    {
      key: 3,
      item: <img src={angleUpFive} alt="" />,
      name: 'Angle Up',
    },
    {
      key: 4,
      item: <img src={upFive} alt="" />,
      name: 'Up',
    },
    {
      key: 5,
      item: <img src={sideWays} alt="" />,
      name: 'Sideways',
    },
  ];
  const humanIcon = [
    {
      key: 1,
      item: <img src={emptyHuman} alt="" />,
      name: 'Empty',
    },
    {
      key: 2,
      item: <img src={oneHuman} alt="" />,
      name: 'One',
    },
    {
      key: 3,
      item: <img src={TwoHuman} alt="" />,
      name: 'Two',
    },
    {
      key: 4,
      item: <img src={threeHuman} alt="" />,
      name: 'Three',
    },

    {
      key: 5,
      item: <img src={fourHuman} alt="" />,
      name: 'Four',
    },

    {
      key: 6,
      item: <img src={fiveHuman} alt="" />,
      name: 'Five',
    },
  ];

  const dollarIcon = [
    {
      key: 1,
      item: <img src={emptyDollar} alt="" />,
      name: 'Empty',
    },
    {
      key: 2,
      item: <img src={oneDollar} alt="" />,
      name: 'One',
    },
    {
      key: 3,
      item: <img src={twoDollar} alt="" />,
      name: 'Two',
    },
    {
      key: 4,
      item: <img src={threeDollar} alt="" />,
      name: 'Three',
    },
    {
      key: 5,
      item: <img src={fourDollar} alt="" />,
      name: 'Four',
    },
    {
      key: 6,
      item: <img src={fiveDollar} alt="" />,
      name: 'Five',
    },
  ];

  const heartIcon = [
    {
      key: 1,
      item: <img src={emptyHeart} alt="" />,
      name: 'Empty',
    },
    {
      key: 2,
      item: <img src={oneHeart} alt="" />,
      name: 'One',
    },
    {
      key: 3,
      item: <img src={twoHeart} alt="" />,
      name: 'Two',
    },
    {
      key: 4,
      item: <img src={threeHeart} alt="" />,
      name: 'Three',
    },
    {
      key: 5,
      item: <img src={fourHeart} alt="" />,
      name: 'Four',
    },
    {
      key: 6,
      item: <img src={fiveHeart} alt="" />,
      name: 'Five',
    },
  ];

  const threeArrowIcon = [
    {
      key: 1,
      item: <img src={downThree} alt="" />,
      name: 'Down',
    },
    {
      key: 2,
      item: <img src={sideWayThree} alt="" />,
      name: 'Sideways',
    },
    {
      key: 3,
      item: <img src={upThree} alt="" />,
      name: 'Up',
    },
  ];

  const fourBlackArrowIcon = [
    {
      key: 1,
      item: <img src={downBlack} alt="" />,
      name: 'Down',
    },
    {
      key: 2,
      item: <img src={rightBlack} alt="" />,
      name: 'Right',
    },
    {
      key: 3,
      item: <img src={upBlack} alt="" />,
      name: 'Up',
    },
    {
      key: 4,
      item: <img src={leftBlack} alt="" />,
      name: 'Left',
    },
  ];

  useEffect(() => {
    getMFList();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // getUser();
    listOrgDepartments();
    getStageName();
    getAllUsers();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (title) {
        fetchFezzySearch();
      }
    }, 1000);

    return () => {
      clearTimeout(delayDebounceFn);
    };
    // eslint-disable-next-line
  }, [title]);

  // drawer stuff
  const [open, setOpen] = useState(false);
  const onClose = () => {
    setOpen(false);
  };
  const tooltipTitle = (
    <>
      Be a trailblazer! Share your creative out-of-the-box ideas with your team.
      <br />
      You can add new ideas and see if they are related to other awesome ideas,
      opportunities, and projects submitted by your teammates. <br />
      Let's do this!{' '}
      <a
        href="https://stralign.com/help-add-idea"
        target="_blank"
        rel="noopener noreferrer"
      >
        Click here for a video overview.
      </a>
    </>
  );

  const ids = formLayout?.map((item) => item.id);
  const filteredData = mfList?.filter((item) => ids.includes(item.id));

  const mappedData = filteredData.map((item) => item);

  const [sliderColor, SetSliderColor] = useState({});

  const onChange = (value, id) => {
    let adjustedValue = value;
    if (mappedData.find((item) => item.id === id)?.subType === '-+') {
      adjustedValue = (value / 100) * 4 - 2;
    } else if (mappedData.find((item) => item.id === id)?.subType === '+-') {
      adjustedValue = 2 - (value / 100) * 4;
    }
    setScaleValue((prevValues) => ({
      ...prevValues,
      [id]: adjustedValue,
    }));
    SetSliderColor({ ...sliderColor, [id]: adjustedValue });
  };

  const handleNumericChange = (value, id) => {
    let adjustedValue = value;
    setScaleValue((prevValues) => ({
      ...prevValues,
      [id]: adjustedValue,
    }));
  };

  const sliders = mappedData.map((item, index) => {
    const marks = {};
    if (item.subType === '-+' || item.subType === '+-') {
      item.breakpoints.forEach((breakpoint, index) => {
        marks[index * 25] = breakpoint.label;
      });

      let value = 0;
      if (item?.subType === '-+') {
        value = ((scaleValue[item.id] + 2) * 100) / 4;
      } else if (item?.subType === '+-') {
        value = -(((scaleValue[item.id] - 2) * 100) / 4);
      }

      return (
        <Col span={24} key={index} className="ranking-scale-slider">
          <Typography.Text>{item.name}</Typography.Text>

          <Slider
            className={
              item.subType === '+-'
                ? `opportunity-slider opportunity-slider-${index} slider-change-1 m-0`
                : `opportunity-slider slider-change m-0 opportunity-slider-${index}`
            }
            tooltip={{ open: false }}
            onChange={(value) => onChange(value, item.id)}
            marks={marks}
            value={value || 50}
            step={null}
          />
        </Col>
      );
    } else {
      return null;
    }
  });

  useEffect(() => {
    // eslint-disable-next-line

    mappedData.map((item, index) => {
      const Slider = document.querySelector(`.opportunity-slider-${index}`);
      const SliderStyles = Slider?.style;

      let value = 0;
      if (item?.subType === '-+') {
        value = Math.ceil(((scaleValue[item.id] + 2) * 100) / 4);
      } else if (item?.subType === '+-') {
        value = Math.ceil(-(((scaleValue[item.id] - 2) * 100) / 4));
      }

      if (item?.subType === '-+') {
        if (value >= 0 && value < 20) {
          SliderStyles?.setProperty(
            '--OuterDotColor',
            `linear-gradient(180deg, #FC5957 0%, #F93432 100%)`
          );
          SliderStyles?.setProperty(
            '--InnerDotColor',
            `linear-gradient(180deg, #C60807 0%, #ED2623 22.67%, #FF7171 100%)`
          );
        } else if (value >= 20 && value < 40) {
          SliderStyles?.setProperty(
            '--OuterDotColor',
            `linear-gradient(180deg, #FFAD58 0%, #FA8B14 100%)`
          );
          SliderStyles?.setProperty(
            '--InnerDotColor',
            `linear-gradient(180deg, #E48632 0%, #E48632 22.67%, #FEBA71 100%)`
          );
        } else if (value >= 40 && value < 60) {
          SliderStyles?.setProperty(
            '--OuterDotColor',
            `linear-gradient(180deg, #CDCDCD 0%, #858484 100%)`
          );
          SliderStyles?.setProperty(
            '--InnerDotColor',
            `linear-gradient(180deg, #838383 0%, #9A9A9A 22.67%, #C2C2C2 100%)`
          );
        } else if (value >= 60 && value < 80) {
          SliderStyles?.setProperty(
            '--OuterDotColor',
            `linear-gradient(180deg, #7CBF66 0%, #55B04E 100%)`
          );
          SliderStyles?.setProperty(
            '--InnerDotColor',
            `linear-gradient(180deg, #379225 0%, #3DA628 22.67%, #9ACF8F 100%)`
          );
        } else if (value >= 80 && value < 100) {
          SliderStyles?.setProperty(
            '--OuterDotColor',
            `linear-gradient(180deg, #17D971 0%, #0F7D42 100%)`
          );
          SliderStyles?.setProperty(
            '--InnerDotColor',
            `linear-gradient(180deg, #089348 0%, #0E9D51 22.67%, #2CD279 100%)`
          );
        }
      } else {
        if (value >= 0 && value < 20) {
          SliderStyles?.setProperty(
            '--OuterDotColor',
            `linear-gradient(180deg, #17D971 0%, #0F7D42 100%)`
          );
          SliderStyles?.setProperty(
            '--InnerDotColor',
            `linear-gradient(180deg, #089348 0%, #0E9D51 22.67%, #2CD279 100%)`
          );
        } else if (value >= 20 && value < 40) {
          SliderStyles?.setProperty(
            '--OuterDotColor',
            `linear-gradient(180deg, #7CBF66 0%, #55B04E 100%)`
          );
          SliderStyles?.setProperty(
            '--InnerDotColor',
            `linear-gradient(180deg, #379225 0%, #3DA628 22.67%, #9ACF8F 100%)`
          );
        } else if (value >= 40 && value < 60) {
          SliderStyles?.setProperty(
            '--OuterDotColor',
            `linear-gradient(180deg, #CDCDCD 0%, #858484 100%)`
          );
          SliderStyles?.setProperty(
            '--InnerDotColor',
            `linear-gradient(180deg, #838383 0%, #9A9A9A 22.67%, #C2C2C2 100%)`
          );
        } else if (value >= 60 && value < 80) {
          SliderStyles?.setProperty(
            '--OuterDotColor',
            `linear-gradient(180deg, #FFAD58 0%, #FA8B14 100%)`
          );
          SliderStyles?.setProperty(
            '--InnerDotColor',
            `linear-gradient(180deg, #E48632 0%, #E48632 22.67%, #FEBA71 100%)`
          );
        } else if (value >= 80 && value < 100) {
          SliderStyles?.setProperty(
            '--OuterDotColor',
            `linear-gradient(180deg, #FC5957 0%, #F93432 100%)`
          );
          SliderStyles?.setProperty(
            '--InnerDotColor',
            `linear-gradient(180deg, #C60807 0%, #ED2623 22.67%, #FF7171 100%)`
          );
        }
      }
    });
    // eslint-disable-next-line
  }, [mappedData, sliderColor]);

  const numericSlider = mappedData.map((item, index) => {
    if (item?.subType === '$' || item?.subType === '#') {
      const numericMarks = (low, high) => {
        const interval = high && low ? (high - low) / 4 : 25;
        const marks = {};
        if (isNaN(low) && isNaN(high)) {
          marks[0] = 'Low End';
          marks[100] = 'High End';
        }
        if (low === undefined || isNaN(low)) {
          marks[0] = 'Low End';
        } else {
          marks[low] = low;
        }

        if (high === undefined || high === 0 || isNaN(high)) {
          marks[100] = 'High End';
        } else {
          marks[high] = high;
        }

        for (let i = 1; i <= 3; i++) {
          marks[low + interval * i] = `Point ${i}`;
        }
        return marks;
      };

      const lowValue = item.breakpoints[0].value;
      const highValue = item.breakpoints[1].value;
      const marks = numericMarks(lowValue, highValue);

      const convertValueToSliderRange = (value, maxValue) => {
        return Math.max(0, Math.min(100, (value * 100) / maxValue));
      };

      // Usage:
      const newValueFromBackend = scaleValue[item?.id]; // Example value received from the backend
      const maxValue = highValue; // Example maximum value

      const sliderValue = convertValueToSliderRange(
        newValueFromBackend,
        maxValue
      );

      return (
        <Col className="ranking-slider" span={24}>
          <Typography.Text>{item.name}</Typography.Text>
          <Slider
            tooltip={{ open: true }}
            className="numeric-slider"
            tipFormatter={(value) => {
              const prefix = item.subType === '$' ? '$' : '#';
              return `${prefix}${value}`;
            }}
            marks={marks}
            min={lowValue}
            max={highValue}
            value={scaleValue[item?.id]}
            onChange={(value) => handleNumericChange(value, item?.id)}
          />
        </Col>
      );
    }
  });

  return (
    <div className="add-idea plr-0 pt-0">
      <Row gutter={[20, 20]} className="idea-create">
        <Col span={12} md={12} xs={24} sm={24}>
          <div className="background-white">
            <Typography.Title level={3} className="title">
              Add{' '}
              {getUpdatedtageName && capitalizeFirstLetter(getUpdatedtageName)}{' '}
              <Tooltip title={tooltipTitle}>
                <QuestionCircleOutlined className="question-icon" />
              </Tooltip>
            </Typography.Title>
            <Form
              layout="vertical"
              form={form}
              requiredMark={false}
              className="idea-step1"
            >
              {formLoading ? (
                <>
                  <FormSkeleton />
                  <FormSkeleton />
                  <FormSkeleton />
                  <FormSkeleton />
                  <FormSkeleton />
                </>
              ) : (
                formLayout?.map((item, index) => {
                  return (
                    <div>
                      {item?.inputType === 'singleLine' && (
                        <Form.Item
                          className="related-item-text"
                          name={item.id}
                          label={
                            <div className="idea-title">
                              <Row>
                                <span level={5}>{item.label}</span>
                                {item.tooltip && (
                                  <Tooltip title={item.tooltip}>
                                    <InfoCircleOutlined className="tooltip-icon ml-5" />
                                  </Tooltip>
                                )}
                              </Row>
                              {screens.xs ? (
                                <Row justify="end">
                                  <Button
                                    onClick={() => setOpen(true)}
                                    type="text"
                                    className="text-blue fw-bold"
                                    size="middle"
                                  >
                                    Related Items{' '}
                                    <RightOutlined className="fs-12" />
                                  </Button>
                                </Row>
                              ) : (
                                <></>
                              )}
                            </div>
                          }
                          rules={[
                            {
                              required: item?.required,
                              message: `Please enter ${item?.label}`,
                            },
                          ]}
                        >
                          <Input
                            // maxLength={item?.max}
                            placeholder={item?.placeholder}
                            onChange={(e) => {
                              if (item.id === process.env.REACT_APP_TITLE_ID) {
                                setTitle(e.target.value);
                              }
                            }}
                            className="input-font"
                          />
                        </Form.Item>
                      )}
                      {item?.inputType === 'multiLine' && (
                        <Form.Item
                          name={item?.id}
                          label={
                            <Row>
                              <p>
                                {item?.label}{' '}
                                {screens.xs ? (
                                  <></>
                                ) : (
                                  item?.maxChars !== '' &&
                                  item?.maxChars !== undefined && (
                                    <span className="text-gray">
                                      (Max. {item?.maxChars} characters limit)
                                    </span>
                                  )
                                )}
                                {item.tooltip && (
                                  <Tooltip title={item.tooltip}>
                                    <InfoCircleOutlined className="tooltip-icon ml-5" />
                                  </Tooltip>
                                )}
                              </p>
                            </Row>
                          }
                          rules={[
                            {
                              required: item?.required,
                              message: `Please enter ${item?.label}`,
                            },
                            {
                              max: item?.maxChars,
                              message: 'Max characters limit reached',
                            },
                          ]}
                        >
                          <TextArea
                            autoSize={{
                              minRows: 5,
                              maxRows: 5,
                            }}
                            maxLength={item?.maxChars}
                            placeholder={item?.placeholder}
                            onChange={handleDescLength}
                            className="input-font"
                          />
                        </Form.Item>
                      )}
                      {item?.inputType === 'dropdown' && (
                        <Form.Item
                          label={
                            <Row>
                              <span>{item?.label}</span>
                              {item.tooltip && (
                                <Tooltip title={item.tooltip}>
                                  <InfoCircleOutlined className="tooltip-icon ml-5" />
                                </Tooltip>
                              )}
                            </Row>
                          }
                          rules={[
                            {
                              required: item?.required,
                              message: `Please Enter ${item?.label}`,
                            },
                          ]}
                          name={item?.id}
                        >
                          <Select
                            className={
                              item?.dropdownValue === 'Multiple'
                                ? 'dropdown-style'
                                : ''
                            }
                            placeholder={item?.placeholder}
                            mode={
                              item?.dropdownValue === 'Multiple'
                                ? 'multiple'
                                : 'single'
                            }
                            onChange={handleIdeaTour}
                            maxTagCount="responsive"
                          >
                            {item?.optionType === 'Custom' &&
                              item?.custom_values?.map((item, index) => {
                                return (
                                  <Option
                                    value={item}
                                    key={index}
                                    className="fs-14"
                                  >
                                    {item}
                                  </Option>
                                );
                              })}
                            {item?.datasetValue?.includes('department') &&
                              listAllDepartments?.map((item, index) => {
                                return (
                                  <Option
                                    value={item?.id}
                                    key={index}
                                    className="fs-14"
                                  >
                                    {item?.name}
                                  </Option>
                                );
                              })}
                            {item?.datasetValue?.includes('employees') &&
                              userData?.map((item, index) => {
                                return (
                                  <Select.Option
                                    key={index}
                                    value={item?.id}
                                    className="fs-14"
                                    disabled={
                                      item?.status === userStatus.disabled
                                    }
                                  >
                                    {item?.status === userStatus.disabled ? (
                                      <Tooltip title="Disabled user">
                                        {item?.firstName + ' ' + item?.lastName}
                                      </Tooltip>
                                    ) : (
                                      item?.firstName + ' ' + item?.lastName
                                    )}
                                  </Select.Option>
                                );
                              })}
                            {item?.datasetValue?.includes('leaders') &&
                              userData
                                ?.filter(
                                  (item) => item.role === UserRole.leader
                                )
                                ?.map((item, index) => {
                                  return (
                                    <Select.Option
                                      key={index}
                                      value={item?.id}
                                      className="fs-14"
                                      disabled={
                                        item?.status === userStatus.disabled
                                      }
                                    >
                                      {item?.status === userStatus.disabled ? (
                                        <Tooltip title="Disabled user">
                                          {item?.firstName +
                                            ' ' +
                                            item?.lastName}
                                        </Tooltip>
                                      ) : (
                                        item?.firstName + ' ' + item?.lastName
                                      )}
                                    </Select.Option>
                                  );
                                })}
                            {item?.datasetValue?.includes('admin') &&
                              userData
                                ?.filter((item) => item.role === UserRole.admin)
                                ?.map((item, index) => {
                                  return (
                                    <Select.Option
                                      key={index}
                                      value={item?.id}
                                      className="fs-14"
                                      disabled={
                                        item?.status === userStatus.disabled
                                      }
                                    >
                                      {item?.status === userStatus.disabled ? (
                                        <Tooltip title="Disabled user">
                                          {item?.firstName +
                                            ' ' +
                                            item?.lastName}
                                        </Tooltip>
                                      ) : (
                                        item?.firstName + ' ' + item?.lastName
                                      )}
                                    </Select.Option>
                                  );
                                })}
                          </Select>
                        </Form.Item>
                      )}

                      {item.inputType === 'symbol' && !item?.hide && (
                        <Form.Item
                          name={item.id}
                          label={
                            <Row>
                              <Typography.Title
                                level={5}
                                className="mb-0 fs-14"
                              >
                                {item.label}
                              </Typography.Title>
                              {item.tooltip && (
                                <Tooltip title={item.tooltip}>
                                  <InfoCircleOutlined className="tooltip-icon ml-5" />
                                </Tooltip>
                              )}
                            </Row>
                          }
                          rules={[
                            {
                              required: item.required,
                              message: `Please input ${item?.label}!`,
                            },
                          ]}
                        >
                          <Select>
                            {item?.symbolType === 'fourIcon' &&
                              fourIcons.map((item, index) => {
                                return (
                                  <Option value={item?.name} key={index}>
                                    <img
                                      src={item?.item?.props?.src}
                                      className="symbol-height"
                                      alt=""
                                    />
                                    <span className="ml-5">{item?.name}</span>
                                  </Option>
                                );
                              })}
                            {item?.symbolType === 'threeIcon' &&
                              threeIcons.map((item, index) => {
                                return (
                                  <Option value={item?.name} key={index}>
                                    <img
                                      src={item?.item?.props?.src}
                                      className="symbol-height"
                                      alt=""
                                    />
                                    <span className="ml-5">{item?.name}</span>
                                  </Option>
                                );
                              })}
                            {item?.symbolType === 'fiveIcon' &&
                              fiveIcons.map((item, index) => {
                                return (
                                  <Option value={item?.name} key={index}>
                                    <img
                                      src={item?.item?.props?.src}
                                      className="symbol-height"
                                      alt=""
                                    />
                                    <span className="ml-5">{item?.name}</span>
                                  </Option>
                                );
                              })}
                            {item?.symbolType === 'shapeIcon' &&
                              shapeIcon.map((item, index) => {
                                return (
                                  <Option value={item?.name} key={index}>
                                    <img
                                      src={item?.item?.props?.src}
                                      className="symbol-height"
                                      alt=""
                                    />{' '}
                                    <span className="ml-5">{item?.name}</span>
                                  </Option>
                                );
                              })}
                            {item?.symbolType === 'rightCrossIcon' &&
                              rightCrossIcon.map((item, index) => {
                                return (
                                  <Option value={item?.name} key={index}>
                                    <img
                                      src={item?.item?.props?.src}
                                      className="symbol-height"
                                      alt=""
                                    />
                                    <span className="ml-5">{item?.name}</span>
                                  </Option>
                                );
                              })}
                            {item?.symbolType === 'arrowMinusIcon' &&
                              arrowMinusIcon.map((item, index) => {
                                return (
                                  <Option value={item?.name} key={index}>
                                    <img
                                      src={item?.item?.props?.src}
                                      className="symbol-height"
                                      alt=""
                                    />
                                    <span className="ml-5">{item?.name}</span>
                                  </Option>
                                );
                              })}
                            {item?.symbolType === 'pieChartIcon' &&
                              pieChartIcon.map((item, index) => {
                                return (
                                  <Option value={item?.name} key={index}>
                                    <img
                                      src={item?.item?.props?.src}
                                      className="symbol-height"
                                      alt=""
                                    />
                                    <span className="ml-5">{item?.name}</span>
                                  </Option>
                                );
                              })}
                            {item?.symbolType === 'analytics' &&
                              analytics.map((item, index) => {
                                return (
                                  <Option value={item?.name} key={index}>
                                    <img
                                      src={item?.item?.props?.src}
                                      className="symbol-height"
                                      alt=""
                                    />
                                    <span className="ml-5">{item?.name}</span>
                                  </Option>
                                );
                              })}
                            {item?.symbolType === 'cloudIcon' &&
                              cloudIcons.map((item, index) => {
                                return (
                                  <Option value={item?.name} key={index}>
                                    <img
                                      src={item?.item?.props?.src}
                                      className="symbol-height"
                                      alt=""
                                    />
                                    <span className="ml-5">{item?.name}</span>
                                  </Option>
                                );
                              })}
                            {item?.symbolType === 'circleSquareIcon' &&
                              circleSquareIcon.map((item, index) => {
                                return (
                                  <Option value={item?.name} key={index}>
                                    <img
                                      src={item?.item?.props?.src}
                                      className="symbol-height"
                                      alt=""
                                    />
                                    <span className="ml-5">{item?.name}</span>
                                  </Option>
                                );
                              })}

                            {item?.symbolType === 'expressionIcon' &&
                              expressionIcon.map((item, index) => {
                                return (
                                  <Option value={item?.name} key={index}>
                                    <img
                                      src={item?.item?.props?.src}
                                      className="symbol-height"
                                      alt=""
                                    />
                                    <span className="ml-5">{item?.name}</span>
                                  </Option>
                                );
                              })}
                            {item?.symbolType === 'ratingsIcon' &&
                              ratingsIcon.map((item, index) => {
                                return (
                                  <Option value={item?.name} key={index}>
                                    <img
                                      src={item?.item?.props?.src}
                                      className="symbol-height"
                                      alt=""
                                    />
                                    <span className="ml-5">{item?.name}</span>
                                  </Option>
                                );
                              })}
                            {item?.symbolType === 'progressBarIcon' &&
                              progressBarIcon.map((item, index) => {
                                return (
                                  <Option value={item?.name} key={index}>
                                    <img
                                      src={item?.item?.props?.src}
                                      className="symbol-height"
                                      alt=""
                                    />
                                    <span className="ml-5">{item?.name}</span>
                                  </Option>
                                );
                              })}
                            {item?.symbolType === 'upDownIcon' &&
                              upDownIcon.map((item, index) => {
                                return (
                                  <Option value={item?.name} key={index}>
                                    <img
                                      src={item?.name}
                                      className="symbol-height"
                                      alt=""
                                    />
                                    <span className="ml-5">{item?.name}</span>
                                  </Option>
                                );
                              })}
                            {item?.symbolType === 'fourArrowIcon' &&
                              fourArrowIcon.map((item, index) => {
                                return (
                                  <Option value={item?.name} key={index}>
                                    <img
                                      src={item?.item?.props?.src}
                                      className="symbol-height"
                                      alt=""
                                    />
                                    <span className="ml-5">{item?.name}</span>
                                  </Option>
                                );
                              })}
                            {item?.symbolType === 'fiveArrowIcon' &&
                              fiveArrowIcon.map((item, index) => {
                                return (
                                  <Option value={item?.name} key={index}>
                                    <img
                                      src={item?.item?.props?.src}
                                      className="symbol-height"
                                      alt=""
                                    />
                                    <span className="ml-5">{item?.name}</span>
                                  </Option>
                                );
                              })}
                            {item?.symbolType === 'humanIcon' &&
                              humanIcon.map((item, index) => {
                                return (
                                  <Option value={item?.name} key={index}>
                                    <img
                                      src={item?.item?.props?.src}
                                      className="symbol-height"
                                      alt=""
                                    />
                                    <span className="ml-5">{item?.name}</span>
                                  </Option>
                                );
                              })}
                            {item?.symbolType === 'dollarIcon' &&
                              dollarIcon.map((item, index) => {
                                return (
                                  <Option value={item?.name} key={index}>
                                    <img
                                      src={item?.item?.props?.src}
                                      className="symbol-height"
                                      alt=""
                                    />
                                    <span className="ml-5">{item?.name}</span>
                                  </Option>
                                );
                              })}
                            {item?.symbolType === 'heartIcon' &&
                              heartIcon.map((item, index) => {
                                return (
                                  <Option value={item?.name} key={index}>
                                    <img
                                      src={item?.item?.props?.src}
                                      className="symbol-height"
                                      alt=""
                                    />
                                    <span className="ml-5">{item?.name}</span>
                                  </Option>
                                );
                              })}
                            {item?.symbolType === 'threeArrowIcon' &&
                              threeArrowIcon.map((item, index) => {
                                return (
                                  <Option value={item?.name} key={index}>
                                    <img
                                      src={item?.item?.props?.src}
                                      className="symbol-height"
                                      alt=""
                                    />
                                    <span className="ml-5">{item?.name}</span>
                                  </Option>
                                );
                              })}
                            {item?.symbolType === 'fourBlackArrowIcon' &&
                              fourBlackArrowIcon.map((item, index) => {
                                return (
                                  <Option value={item?.name} key={index}>
                                    <img
                                      src={item?.item?.props?.src}
                                      className="symbol-height"
                                      alt=""
                                    />
                                    <span className="ml-5">{item?.name}</span>
                                  </Option>
                                );
                              })}
                          </Select>
                        </Form.Item>
                      )}
                      {item?.inputType === 'datepicker' && (
                        <Form.Item
                          label={
                            <Row>
                              <span>{item?.label}</span>
                              {item.tooltip && (
                                <Tooltip title={item.tooltip}>
                                  <InfoCircleOutlined className="tooltip-icon ml-5" />
                                </Tooltip>
                              )}
                            </Row>
                          }
                          rules={[
                            {
                              required: item?.required,
                              message: `Please Enter ${item?.label}`,
                            },
                          ]}
                          name={item?.id}
                        >
                          <DatePicker
                            className="w-100"
                            placeholder={item?.placeholder}
                            format="YYYY-MM-DD"
                            onChange={handleChangeDate}
                          />
                        </Form.Item>
                      )}

                      {item.inputType === 'dragger' && !item?.hide && (
                        <>
                          <Col
                            span={24}
                            md={24}
                            sm={24}
                            xs={24}
                            className="p-0"
                          >
                            <Row className="upload-area  mt-0">
                              <Col span={24}>
                                <Form.Item
                                  label={
                                    <Row>
                                      <span>{item?.label}</span>
                                      {item.tooltip && (
                                        <Tooltip title={item.tooltip}>
                                          <InfoCircleOutlined className="tooltip-icon ml-5" />
                                        </Tooltip>
                                      )}
                                    </Row>
                                  }
                                  rules={[
                                    {
                                      required: item?.required,
                                      message: `Please Enter ${item?.label}`,
                                    },
                                  ]}
                                  name={item?.id}
                                >
                                  <Dragger
                                    maxCount={5}
                                    {...props}
                                    className="file-upload"
                                  >
                                    <p className="ant-upload-drag-icon">
                                      <UploadOutlined />
                                    </p>
                                    <p className="ant-upload-text">
                                      Drag and drop files, or{' '}
                                      <span className="text-primary">
                                        Browse
                                      </span>
                                    </p>
                                    <p className="ant-upload-hint">
                                      Supported format: Word, Excel, PPT, Google
                                      Sheets with max size of 5 MB. Max 5 files
                                      can be upload
                                    </p>
                                  </Dragger>
                                </Form.Item>
                              </Col>
                            </Row>
                          </Col>
                          {fileUpload?.length > 0 && (
                            <Col span={24}>
                              <Typography.Title level={5}>
                                Uploaded files
                              </Typography.Title>
                              <Row
                                justify="space-between"
                                align="middle"
                                className="attach-link mb-10"
                              >
                                <Col span={24}>
                                  {fileUpload.map((item, index) => {
                                    return (
                                      <>
                                        <Row
                                          justify="space-between"
                                          align="middle"
                                          className="link-added mb-10"
                                          key={item}
                                        >
                                          <Paragraph
                                            ellipsis
                                            className="mb-0 fs-14 text-primary"
                                          >
                                            {item}
                                          </Paragraph>
                                          <Button
                                            type="text"
                                            onClick={() =>
                                              deleteUploadedFiles(item, index)
                                            }
                                            size="middle"
                                          >
                                            Remove
                                          </Button>
                                        </Row>
                                      </>
                                    );
                                  })}
                                </Col>
                              </Row>
                            </Col>
                          )}
                        </>
                      )}
                      {item.inputType === 'link' && !item?.hide && (
                        <Col span={24} md={24} sm={24} xs={24} className="p-0">
                          <Row className="upload-area mt-0">
                            <Col span={24}>
                              <Row
                                className=" attach-link"
                                gutter={[10, 10]}
                                align="middle"
                              >
                                <Col span={20} lg={20} md={20} xs={24} sm={24}>
                                  <Form.Item
                                    label={
                                      <Row>
                                        <span>{item?.label}</span>
                                        {item.tooltip && (
                                          <Tooltip title={item.tooltip}>
                                            <InfoCircleOutlined className="tooltip-icon ml-5" />
                                          </Tooltip>
                                        )}
                                      </Row>
                                    }
                                    rules={[
                                      {
                                        required: item?.required,
                                        message: `Please Enter ${item?.label}`,
                                      },
                                    ]}
                                    name={item?.id}
                                  >
                                    <Input
                                      placeholder="Paste document link here"
                                      value={insertLink}
                                      onChange={(e) =>
                                        setInsertLink(e.target.value)
                                      }
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={4} lg={4} md={4} xs={24} sm={24}>
                                  <Form.Item label="" className="mb-0">
                                    <Button
                                      type="primary"
                                      className="attach-btn  mt-0"
                                      size="middle"
                                      onClick={() => {
                                        if (insertLink) {
                                          setAttachMent([
                                            ...attachment,
                                            insertLink,
                                          ]);
                                          setInsertLink('');
                                        }
                                      }}
                                    >
                                      Attach
                                    </Button>
                                  </Form.Item>
                                </Col>
                                {(attachedLinks?.length > 0 ||
                                  attachment?.length > 0) && (
                                  <Col span={24}>
                                    <Typography.Title className="p fw-400">
                                      Attached link{' '}
                                    </Typography.Title>

                                    {attachedLinks
                                      .concat(attachment)
                                      .map((item, index) => {
                                        return (
                                          <Row
                                            justify="space-between"
                                            align="middle"
                                            className="link-added mb-10"
                                            key={index}
                                          >
                                            <a
                                              href={
                                                item?.url || item?.text || item
                                              }
                                              className="mb-0 fs-14 text-primary p"
                                            >
                                              {item?.url || item?.text || item}
                                            </a>
                                            <Button
                                              type="text"
                                              onClick={() =>
                                                deleteAttachLinks(item, index)
                                              }
                                              size="middle"
                                            >
                                              Remove
                                            </Button>
                                          </Row>
                                        );
                                      })}
                                  </Col>
                                )}
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      )}
                    </div>
                  );
                })
              )}
              {sliders}
              {numericSlider}
              <Form.Item className="mb-0">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="blue-filled-btn idea-step3"
                  onClick={() => showModal()}
                  size="middle"
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Col>
        <Col span={12} md={12} xs={0} sm={24}>
          <RelatedItems
            data={data}
            relatedItemLoader={relatedItemLoader}
            orgId={orgId}
            linkedIdea={linkedIdea}
            setLinkedIdea={setLinkedIdea}
            joyrideStepIndex={joyrideStepIndex}
            setJoyrideStepIndex={setJoyrideStepIndex}
          />
        </Col>
      </Row>

      <Drawer
        width="35%"
        height={screens.xs ? '83vh' : ''}
        className="related-item-drawer compare-drawer"
        title={
          <Typography.Title level={4} className="mb-0">
            Related Items
          </Typography.Title>
        }
        placement="bottom"
        onClose={onClose}
        open={open}
      >
        <RelatedItems
          data={data}
          relatedItemLoader={relatedItemLoader}
          orgId={orgId}
          linkedIdea={linkedIdea}
          setLinkedIdea={setLinkedIdea}
        />
      </Drawer>
      <Modal
        open={isModalOpen}
        onOk={updateFormItems}
        confirmLoading={loading}
        onCancel={handleCancel}
        centered
        okText="Submit"
        cancelText="Review"
        className="submit-idea-modal"
      >
        <Row justify="center" className="reposnsive-text-center">
          <img src={addIdeaModal} alt="" />
          <Typography.Title level={4} className="mt-10">
            Are you sure that you want to submit your idea?
          </Typography.Title>
          <Typography.Text level={5}>
            Once an idea has been submitted, only an Admin can update the idea,
          </Typography.Text>
          <Typography.Text>
            name and description. No bait and switch allowed.
          </Typography.Text>
          <Typography.Title level={5} className="mb-20"></Typography.Title>
        </Row>
      </Modal>

      {((isSMEGroup && startFirstTimeTour) || (isSMEGroup && ideaTour)) && (
        <OnboardingIdea
          form={form}
          joyrideStepIndex={joyrideStepIndex}
          setJoyrideStepIndex={setJoyrideStepIndex}
        />
      )}
      {!showCongratsModal && (
        <SubmitFirstIdeaModal openAddIdeaModal={openAddIdeaModal} />
      )}
    </div>
  );
}
