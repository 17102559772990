import { Breadcrumb } from 'antd';
import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

const HeaderBreadcrumb = ({ state }) => {
  const history = useHistory();
  const location = useLocation();

  return window.location.pathname === '/add-goal' ? (
    <Breadcrumb className="header-breadcrumb">
      <Breadcrumb.Item>
        <Link to="/goal">Goal</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Add Goal</Breadcrumb.Item>
    </Breadcrumb>
  ) : window.location.pathname.includes('/add-goal') ? (
    <Breadcrumb className="header-breadcrumb">
      <Breadcrumb.Item>
        <Link to="/goal">Goal</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Edit Goal</Breadcrumb.Item>
    </Breadcrumb>
  ) : history.location.state?.previousPath?.includes('/scenario-detail') ? (
    <Breadcrumb className="header-breadcrumb">
      <Breadcrumb.Item>
        <Link to="/scenarios">Scenarios</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        {' '}
        <Link
          to={`/scenario-detail/${
            history.location.state?.previousPath?.split('/')[2]
          }`}
        >
          Details
        </Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Edit</Breadcrumb.Item>
    </Breadcrumb>
  ) : window.location.pathname.includes('/ranking-detail') ? (
    <Breadcrumb className="header-breadcrumb">
      <Breadcrumb.Item>
        <Link to="/rankings">Rankings</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Edit</Breadcrumb.Item>
    </Breadcrumb>
  ) : window.location.pathname === '/rate-detail' ? (
    <Breadcrumb className="header-breadcrumb">
      <Breadcrumb.Item>
        <Link to="/rate">Rate</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Details</Breadcrumb.Item>
    </Breadcrumb>
  ) : window.location.pathname === '/add-scenario' ? (
    <Breadcrumb className="header-breadcrumb">
      <Breadcrumb.Item>
        <Link to="/scenarios">Scenarios</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Create Scenario</Breadcrumb.Item>
    </Breadcrumb>
  ) : window.location.pathname === '/compare-scenarios' ? (
    <Breadcrumb className="header-breadcrumb">
      <Breadcrumb.Item>
        <Link to="/scenarios">Scenarios</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Compare Scenarios</Breadcrumb.Item>
    </Breadcrumb>
  ) : window.location.pathname.includes('edit-active-user') ? (
    <Breadcrumb className="header-breadcrumb">
      <Breadcrumb.Item>
        <Link to="/user-management">User Management</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Edit</Breadcrumb.Item>
    </Breadcrumb>
  ) : window.location.pathname === '/add-user' ? (
    <Breadcrumb className="header-breadcrumb">
      <Breadcrumb.Item>
        <Link to="/user-management">User Management</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Add User</Breadcrumb.Item>
    </Breadcrumb>
  ) : window.location.pathname.includes('edit-invited-user') ? (
    <Breadcrumb className="header-breadcrumb">
      <Breadcrumb.Item>
        <Link to="/user-management">User Management</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Edit</Breadcrumb.Item>
    </Breadcrumb>
  ) : window.location.pathname.includes('stage1-draft-form') ? (
    <Breadcrumb className="header-breadcrumb">
      <Breadcrumb.Item>
        <Link to="/stages">Stages</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Edit</Breadcrumb.Item>
    </Breadcrumb>
  ) : window.location.pathname.includes('department-details') ? (
    <Breadcrumb className="header-breadcrumb">
      <Breadcrumb.Item>
        <Link
          to={{
            pathname: '/settings',
            state: { departmentTab: true },
          }}
        >
          Department
        </Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Details</Breadcrumb.Item>
    </Breadcrumb>
  ) : window.location.pathname === '/integration/integration-detail' ? (
    <Breadcrumb className="header-breadcrumb">
      <Breadcrumb.Item>
        <Link to="/integration">Integration</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Zapier</Breadcrumb.Item>
    </Breadcrumb>
  ) : window.location.pathname === '/measurementfield/scale' ? (
    <Breadcrumb className="header-breadcrumb">
      <Breadcrumb.Item>
        <Link to="/measurementfield-list">Measurement Field</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Add Scale</Breadcrumb.Item>
    </Breadcrumb>
  ) : window.location.pathname.includes('/measurementfield/scale') ? (
    <Breadcrumb className="header-breadcrumb">
      <Breadcrumb.Item>
        <Link to="/measurementfield-list">Measurement Field</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Edit Scale</Breadcrumb.Item>
    </Breadcrumb>
  ) : window.location.pathname === '/measurementfield/numeric' ? (
    <Breadcrumb className="header-breadcrumb">
      <Breadcrumb.Item>
        <Link to="/measurementfield-list">Measurement Field</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Add Numeric</Breadcrumb.Item>
    </Breadcrumb>
  ) : window.location.pathname.includes('/measurementfield/numeric') ? (
    <Breadcrumb className="header-breadcrumb">
      <Breadcrumb.Item>
        <Link to="/measurementfield-list">Measurement Field</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Edit Numeric</Breadcrumb.Item>
    </Breadcrumb>
  ) : window.location.pathname.includes('/stage1') ? (
    <Breadcrumb className="header-breadcrumb">
      <Breadcrumb.Item>
        <Link to="/stages">Stages</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Edit</Breadcrumb.Item>
    </Breadcrumb>
  ) : window.location.pathname.includes('/stageDetail') ? (
    <Breadcrumb className="header-breadcrumb">
      <Breadcrumb.Item>
        <Link to="/stages">Stages</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Edit</Breadcrumb.Item>
    </Breadcrumb>
  ) : window.location.pathname.includes('/draft-form') ? (
    <Breadcrumb className="header-breadcrumb">
      <Breadcrumb.Item>
        <Link to="/stages">Stages</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Draft form</Breadcrumb.Item>
    </Breadcrumb>
  ) : window.location.pathname === '/add-idea' ? (
    <Breadcrumb className="header-breadcrumb">
      <Breadcrumb.Item>
        <Link to="/rankings">Rankings</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Add</Breadcrumb.Item>
    </Breadcrumb>
  ) : window.location.pathname.includes('/edit-idea') ? (
    <Breadcrumb className="header-breadcrumb">
      <Breadcrumb.Item>
        <Link to="/rankings">Rankings</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Edit</Breadcrumb.Item>
    </Breadcrumb>
  ) : window.location.pathname === '/scenario-planning' &&
    history.location.state.previousPath === '/compare-scenarios' ? (
    <></>
  ) : window.location.pathname.includes('/scenario-detail') ? (
    <Breadcrumb className="header-breadcrumb">
      <Breadcrumb.Item>
        <Link to="/scenarios">Scenarios</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Details</Breadcrumb.Item>
    </Breadcrumb>
  ) : window.location.pathname === '/add-scenario' ? (
    <Breadcrumb className="header-breadcrumb">
      <Breadcrumb.Item>
        <Link to="/scenarios">Scenarios</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Add</Breadcrumb.Item>
    </Breadcrumb>
  ) : window.location.pathname.includes('/add-scenario') ? (
    <Breadcrumb className="header-breadcrumb">
      <Breadcrumb.Item>
        <Link to="/scenarios">Scenarios</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Edit</Breadcrumb.Item>
    </Breadcrumb>
  ) : window.location.pathname.includes('/organization/add-organization') ? (
    <Breadcrumb className="header-breadcrumb">
      <Breadcrumb.Item>
        <Link to="/organizations">Organization</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Add organization</Breadcrumb.Item>
    </Breadcrumb>
  ) : window.location.pathname.includes('/partner/add-partner') ? (
    <Breadcrumb className="header-breadcrumb">
      <Breadcrumb.Item>
        <Link to="/partner">Partner</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Add Partner</Breadcrumb.Item>
    </Breadcrumb>
  ) : window.location.pathname.includes('/organization/edit-organization') ? (
    <Breadcrumb className="header-breadcrumb">
      <Breadcrumb.Item>
        <Link to="/organizations">Organization</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Edit</Breadcrumb.Item>
    </Breadcrumb>
  ) : window.location.pathname.includes('/partner/edit-partner') ? (
    <Breadcrumb className="header-breadcrumb">
      <Breadcrumb.Item>
        <Link to="/partner">Partner</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Edit</Breadcrumb.Item>
    </Breadcrumb>
  ) : window.location.pathname.includes('/organization/detail') ? (
    <Breadcrumb className="header-breadcrumb">
      <Breadcrumb.Item>
        <Link to="/organizations">Organization</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Details</Breadcrumb.Item>
    </Breadcrumb>
  ) : window.location.pathname.includes('/partner/detail') ? (
    <Breadcrumb className="header-breadcrumb">
      <Breadcrumb.Item>
        <Link to="/partner">Partner</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Details</Breadcrumb.Item>
    </Breadcrumb>
  ) : (
    <Breadcrumb className="header-breadcrumb">
      <Breadcrumb.Item>
        <Link to="/">Home</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Scenario</Breadcrumb.Item>
    </Breadcrumb>
  );
};
export default HeaderBreadcrumb;

