import { Modal, Row } from 'antd';
import React, { memo } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Typography from 'antd/es/typography/Typography';

const DeleteModal = ({
  deleteModal,
  setDeleteModal,
  deleteMeasurementfield,
  deleteItemId,
}) => {
  const handleCancelModal = () => {
    setDeleteModal(false);
  };

  return (
    <Modal
      open={deleteModal}
      closable={false}
      className="Edit-modal"
      okText="Delete"
      onCancel={handleCancelModal}
      onOk={() => deleteMeasurementfield(deleteItemId)}
    >
      <Row align="middle">
        <ExclamationCircleOutlined className="headsup-icon" />
        <Typography.Title level={5} className="mb-0 ml-15">
          Heads Up
        </Typography.Title>
      </Row>
      <Row className="text-align">
        <Typography.Text>
          In a stage, this measurement field is being <br /> used. There will be
          consequences if you delete.
        </Typography.Text>
      </Row>
    </Modal>
  );
};

export default memo(DeleteModal);

