import {
  Row,
  Typography,
  Divider,
  Tag,
  Skeleton,
  Button,
  message,
  Grid,
  Tooltip,
} from 'antd';
import React from 'react';
import './idea.less';
import { ECHOIMAGES } from '../../Assets/Images';
import { linkAddIdea } from './IdeaFunction';
import { InfoCircleOutlined } from '@ant-design/icons';
import { levelColors } from 'utils/Constants';

export default function RelatedItems({
  linkedIdea,
  setLinkedIdea,
  setJoyrideStepIndex,
  joyrideStepIndex,
  ...props
}) {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const { EmptyData } = ECHOIMAGES.ideaIcons;
  const { Paragraph } = Typography;

  const linkIdeas = (id, linkMessage) => {
    let arrTemp = [...linkedIdea];
    if (arrTemp.indexOf(id) !== -1) {
      arrTemp.splice(arrTemp.indexOf(id), 1);
    } else {
      arrTemp.push(id);
    }
    setLinkedIdea(arrTemp);
    message.success(`Items  ${linkMessage ? 'Linked' : 'Unlink'} Sucessfully`);
    if (joyrideStepIndex !== undefined) {
      setTimeout(() => {
        setJoyrideStepIndex(joyrideStepIndex + 1);
      }, 2000);
    }
  };

  return (
    <div
      className={
        screens.xs ? 'empty-card' : 'empty-card background-white h-auto'
      }
    >
      {/* if there are no data available  */}

      {!props?.data?.length ? (
        <Row align="top">
          {screens.xs ? (
            <></>
          ) : (
            <>
              <Row>
                <Typography.Title level={3} className="fs-20">
                  Related items
                  <Tooltip
                    // eslint-disable-next-line max-len
                    title="You are not alone! As you type a new idea title, similar or related ideas, opportunities, or projects that your teammates have shared will appear below.Let's connect! If you think an item is similar or related in some way you can link them to your idea by pressing on the link button."
                  >
                    <InfoCircleOutlined className="info-tooltip" />
                  </Tooltip>
                </Typography.Title>
              </Row>
            </>
          )}
          <img src={EmptyData} alt="" className="w-100" />
        </Row>
      ) : (
        <div className="idea-step2">
          {screens.xs ? (
            <></>
          ) : (
            <>
              <Typography.Title level={3} className="fs-20">
                Related items
                <Tooltip
                  // eslint-disable-next-line max-len
                  title="You are not alone! As you type a new idea title, similar or related ideas, opportunities, or projects that your teammates have shared will appear below.Let's connect! If you think an item is similar or related in some way you can link them to your idea by pressing on the link button."
                >
                  <InfoCircleOutlined className="info-tooltip" />
                </Tooltip>
              </Typography.Title>
            </>
          )}
          <Row className="idea related-item scrollable-panel ">
            {props.relatedItemLoader ? (
              <Skeleton />
            ) : (
              props?.data?.map((item, index) => {
                return (
                  <div className="idea-box w-100">
                    <Paragraph
                      className="title p"
                      ellipsis={{ tooltip: 'Live Chat Support' }}
                    >
                      {item.title}
                    </Paragraph>

                    <Paragraph
                      ellipsis={
                        true
                          ? {
                              rows: 4,
                              expandable: true,
                              symbol: 'more',
                            }
                          : false
                      }
                      className="input-font"
                    >
                      {item.description || 'No description'}
                    </Paragraph>

                    <Divider />
                    <Row
                      align="middle"
                      justify="space-between"
                      className="w-100"
                    >
                      <Typography.Title>
                        <span className="input-font fw-400">Type : </span>{' '}
                        <Tag
                          className="tag-font"
                          key={item?.level?.id}
                          color={levelColors[item?.level?.level]}
                        >
                          {item?.level?.name}
                        </Tag>
                      </Typography.Title>
                      {linkedIdea.includes(item?.id) ? (
                        <Button
                          className="unlink-btn"
                          onClick={() => {
                            linkIdeas(item?.id, false);
                          }}
                          size="middle"
                        >
                          UnLink
                        </Button>
                      ) : (
                        <Button
                          type="primary"
                          onClick={() => {
                            linkIdeas(item?.id, true);
                          }}
                          size="middle"
                        >
                          Link
                        </Button>
                      )}
                    </Row>
                  </div>
                );
              })
            )}
          </Row>
        </div>
      )}
    </div>
  );
}
