import {
  Checkbox,
  Col,
  Divider,
  Input,
  message,
  Modal,
  Row,
  Select,
  Typography,
} from 'antd';
import { FetchUser } from 'Pages/AllPriorities/IdeaFunction';
import { listAllStages } from 'Pages/Stages/Services/StageActions';
import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getCurrentOrganization,
  getCurrnetUserData,
} from 'store/slices/loginSlice';
import { fetchOrgDepartments } from 'utils/CommonFunctions';
import { userStatus } from 'utils/Constants';
import { RESTGetAuthorization, RESTGetCount } from 'utils/RESTApi';

const EditIdeaFilterModal = ({
  savedRankingFilter,
  setEditIdeaFilterModal,
  editIdeaFilterModal,
  setSaveRankingFilter,
  saveRankingFilter,
  setRankingCount,
  ideaMatch,
}) => {
  const [ideaFilterList, setIdeaFilterList] = useState([]);
  const [listAllDepartments, setListAllDepartments] = useState([]);
  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;
  const userId = currnetOrg?.adminID || loginUsrOrg?.id;

  const operationList = [
    'is between',
    'is equal to',
    'is not equal to',
    'is greater than',
    'is greater than or equal to',
    'is less than',
    'is less than or equal to',
    'is not between',
  ];
  const operationListData = ['is one of', 'is not one of'];
  const [editID, setEditID] = useState();
  const [whoList, setWhoList] = useState({});
  const { id } = useParams();

  const handleWhoList = (value, field) => {
    if (field === 'mfname') {
      let tempWhoList = { ...whoList };
      tempWhoList['lowvalue'] = null;
      tempWhoList['highvalue'] = null;
      tempWhoList['valueTest'] = '';
      tempWhoList['highscalevalue'] = '';
      tempWhoList['extraField'] = [];
      tempWhoList['category'] = '';
      tempWhoList[field] = value;
      setWhoList(tempWhoList);
    } else if (field === 'lowvalue' || field === 'highvalue') {
      if (/^\d*$/.test(value) || value === '') {
        let tempWhoList = { ...whoList };
        tempWhoList[field] = value;
        setWhoList(tempWhoList);
      } else {
        message.error('Only numbers are valid');
      }
    } else {
      let tempWhoList = { ...whoList };
      tempWhoList[field] = value;
      setWhoList(tempWhoList);
    }
  };

  useEffect(() => {
    if (savedRankingFilter) {
      setEditID(savedRankingFilter.uniqueID);
      const whoOptionsObj = {
        mfname: savedRankingFilter?.id,
        category: Object.entries(savedRankingFilter?.filter)[0][0],
        lowvalue:
          (Object.entries(savedRankingFilter?.filter)[0][0] === 'is between' &&
            savedRankingFilter?.field?.type === '#') ||
          (Object.entries(savedRankingFilter?.filter)[0][0] ===
            'is not between' &&
            savedRankingFilter?.field?.type === '#')
            ? Object.entries(savedRankingFilter?.filter)[0][1][0]
            : Object.entries(savedRankingFilter?.filter)[0][1],
        extraField: Object.entries(savedRankingFilter?.field)
          .filter(([key, value]) => typeof value !== 'string')
          .map((item) => item?.[1]?.id),
        valueTest:
          Object.entries(savedRankingFilter?.filter)[0][0] === 'is between' ||
          Object.entries(savedRankingFilter?.filter)[0][0] === 'is not between'
            ? parseInt(
                savedRankingFilter?.field?.breakpoints?.find(
                  (b) =>
                    b.value ===
                    Object.entries(savedRankingFilter?.filter)[0][1][0]
                )?.value || 0
              )
            : parseInt(
                savedRankingFilter?.field?.breakpoints?.find(
                  (b) =>
                    b.value === Object.entries(savedRankingFilter?.filter)[0][1]
                )?.value || 0
              ),
        highvalue:
          savedRankingFilter?.field?.type === '#' &&
          Object.entries(savedRankingFilter?.filter)[0][1][1],
        highscalevalue: parseInt(
          savedRankingFilter?.field?.breakpoints?.find(
            (b) =>
              b.value === Object.entries(savedRankingFilter?.filter)[0][1][1]
          )?.value || 0
        ),
        threshold: savedRankingFilter?.threshold,
        includeBlanks: savedRankingFilter?.blanks,
      };

      setWhoList(whoOptionsObj);
    }
    // eslint-disable-next-line
  }, [savedRankingFilter]);

  const handleSubmitFilter = () => {
    let tempApplyFilter = Array.isArray(saveRankingFilter)
      ? [...saveRankingFilter]
      : [];
    let result = {};
    const filteredStageData = whoList?.extraField?.reduce((acc, item) => {
      acc[item] = {
        id: listStage.filter((e) => item === e?.id)?.[0]?.id,
        level: listStage.filter((e) => item === e?.id)?.[0]?.level,
        name: listStage.filter((e) => item === e?.id)?.[0]?.name,
      };
      return acc;
    }, {});

    const filteredUserData = whoList?.extraField?.reduce((acc, item) => {
      acc[item] = {
        id: userData?.filter((e) => e?.id === item)?.[0]?.id,
        firstName: userData?.filter((e) => e?.id === item)?.[0]?.firstName,
        lastName: userData?.filter((e) => e?.id === item)?.[0]?.lastName,
      };
      return acc;
    }, {});

    const filteredDepartmentData = whoList?.extraField?.reduce((acc, item) => {
      acc[item] = {
        id: listAllDepartments?.filter((e) => e?.id === item)?.[0]?.id,
        name: listAllDepartments?.filter((e) => e?.id === item)?.[0]?.name,
      };
      return acc;
    }, {});

    result = {
      id: whoList?.mfname,
      filter: {
        [whoList?.category]: (() => {
          if (
            whoList?.category === 'is between' ||
            whoList?.category === 'is not between'
          ) {
            if (
              ideaFilterList?.items?.filter(
                (e) => e?.id === whoList?.mfname
              )?.[0]?.type === '#'
            ) {
              return [whoList?.lowvalue, whoList?.highvalue];
            } else {
              return [whoList?.valueTest, whoList?.highscalevalue];
            }
          } else if (
            ideaFilterList?.items?.filter((e) => e?.id === whoList?.mfname)?.[0]
              ?.type === '#'
          ) {
            return whoList?.lowvalue;
          } else if (whoList?.extraField?.[0] !== undefined) {
            return whoList?.extraField;
          } else {
            return whoList?.valueTest;
          }
        })(),
      },
      threshold:
        ideaFilterList?.items?.filter((e) => e?.id === whoList?.mfname)?.[0]
          ?.type === '#' ||
        ideaFilterList?.items?.filter((e) => e?.id === whoList?.mfname)?.[0]
          ?.type === '+-'
          ? whoList?.threshold !== ''
            ? whoList?.threshold
            : null
          : null,
      blanks: whoList?.includeBlanks,
      field:
        whoList?.mfname?.split(':')?.[0] === process.env.REACT_APP_SPONSOR_ID ||
        whoList?.mfname?.split(':')?.[0] ===
          process.env.REACT_APP_CONTRIBUTOR_ID
          ? {
              ...filteredUserData,
              name: ideaFilterList?.items?.filter(
                (e) => e?.id === whoList?.mfname
              )?.[0]?.name,
              description: ideaFilterList?.items?.filter(
                (e) => e?.id === whoList?.mfname
              )?.[0]?.description,
            }
          : whoList?.mfname?.split(':')?.[0] === process.env.REACT_APP_STAGE_ID
          ? {
              ...filteredStageData,
              name: ideaFilterList?.items?.filter(
                (e) => e?.id === whoList?.mfname
              )?.[0]?.name,
              description: ideaFilterList?.items?.filter(
                (e) => e?.id === whoList?.mfname
              )?.[0]?.description,
            }
          : whoList?.mfname?.split(':')?.[0] ===
            process.env.REACT_APP_DEPARTMENT_ID
          ? {
              ...filteredDepartmentData,
              name: ideaFilterList?.items?.filter(
                (e) => e?.id === whoList?.mfname
              )?.[0]?.name,
            }
          : ideaFilterList?.items?.filter(
              (e) => e?.id === whoList?.mfname
            )?.[0],
    };
    let objectIndex = tempApplyFilter?.findIndex(
      (item) => item?.uniqueID === editID
    );

    if (objectIndex >= 0) {
      tempApplyFilter[objectIndex] = {
        uniqueID: editID,
        ...result,
      };
    }

    setSaveRankingFilter(tempApplyFilter);
    getRankingFilter(tempApplyFilter);
    message.success('Filters updated successfully');
    setEditIdeaFilterModal(false);
  };

  const getRankingFilter = async (rankingFilter) => {
    try {
      let inputData = {
        userID: userId,
        scenario: {
          organizationID: orgId,
          id: id,
          rankingFilters: {
            match: ideaMatch,
            filters: rankingFilter || [],
          },
        },
      };
      let res = await RESTGetCount(inputData);
      setRankingCount(res?.body);
    } catch (err) {
      console.log(err);
    }
  };

  const [listStage, setListStage] = useState([]);
  const listAllStagesByOrg = async () => {
    try {
      let response = await listAllStages({ organizationID: orgId });
      let sortedLevel = response.items.sort((a, b) => a.level - b.level);
      setListStage(sortedLevel);
    } catch (err) {
      console.log(err);
    }
  };

  const listOrgDepartments = async () => {
    const departments = await fetchOrgDepartments(orgId);
    setListAllDepartments(departments);
  };
  const [userData, setUserData] = useState([]);
  // get all users by org
  const getAllUsers = async () => {
    try {
      let data = {
        and: [
          { status: { ne: userStatus.invited } },
          { status: { ne: userStatus.inactive } },
        ],
      };
      let id = { organizationID: orgId };
      let nextToken = null;
      let allUserData = [];
      while (true) {
        let res = await FetchUser(data, id, nextToken);
        allUserData.push(...res.items);
        if (!res?.nextToken) {
          break;
        }
        nextToken = res?.nextToken;
      }
      await processUserData(allUserData);
    } catch (err) {
      console.log(err);
    }
  };
  const processUserData = async (userData) => {
    setUserData(userData);
  };

  const getMFList = async () => {
    try {
      let listApi = `/measurement/filters?organization_id=${orgId}`;
      let res = await RESTGetAuthorization(listApi);
      setIdeaFilterList(res?.body);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getMFList();
    listAllStagesByOrg();
    listOrgDepartments();
    getAllUsers();
    // eslint-disable-next-line
  }, []);

  // Assume currentSelectedID contains the ID of the currently selected item in the dropdown
  const currentSelectedID = savedRankingFilter?.id;

  // Extract IDs to exclude from dataArray
  const excludeIDs = new Set();
  saveRankingFilter?.forEach((item) => {
    excludeIDs.add(item?.id);
  });

  // Remove the currently selected ID from the set of IDs to exclude
  excludeIDs?.delete(currentSelectedID);

  // Filter ideaFilterList items
  const filteredItems = ideaFilterList?.items?.filter((item) => {
    return !excludeIDs?.has(item.id) || item.id === currentSelectedID;
  });

  return (
    <Modal
      open={editIdeaFilterModal}
      width={1000}
      className="scenario-filter-modal"
      onOk={handleSubmitFilter}
      closable={false}
      onCancel={() => setEditIdeaFilterModal(false)}
    >
      <div className="scenario-filter selected-border mt-0 filter-scroll p-20">
        <Typography.Title level={4} className="scenario-title mb-20">
          Edit Scenario Filters
        </Typography.Title>
        <Divider className="mb-10" />
        <div className="idea-filter">
          <Row gutter={10}>
            <Col span={7}>
              <Select
                onChange={(e) => handleWhoList(e, 'mfname')}
                className="w-100"
                value={whoList?.mfname}
              >
                {filteredItems?.map((item, index) => {
                  return (
                    <Select.Option key={index} value={item?.id}>
                      {item?.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Col>
            <Col span={7}>
              <Select
                onChange={(e) => handleWhoList(e, 'category')}
                className="w-100"
                value={whoList?.category}
              >
                {ideaFilterList?.items?.filter(
                  (e) => e?.id === whoList?.mfname
                )?.[0]?.type === '&'
                  ? operationListData?.map((item, index) => {
                      return (
                        <Select.Option key={index} value={item}>
                          {item}
                        </Select.Option>
                      );
                    })
                  : operationList?.map((item, index) => {
                      return (
                        <Select.Option key={index} value={item}>
                          {item}
                        </Select.Option>
                      );
                    })}
              </Select>
            </Col>
            {ideaFilterList?.items?.filter(
              (e) => e?.id === whoList?.mfname
            )?.[0]?.type === '#' ? (
              <Col span={4}>
                <Input
                  prefix={
                    ideaFilterList?.items?.filter(
                      (e) => e?.id === whoList?.mfname
                    )?.[0]?.subType === '$'
                      ? '$'
                      : '#'
                  }
                  value={whoList?.lowvalue}
                  onChange={(e) => handleWhoList(e.target.value, 'lowvalue')}
                />
              </Col>
            ) : ideaFilterList?.items?.filter(
                (e) => e?.id === whoList?.mfname
              )?.[0]?.type === '&' ? (
              <Col span={10}>
                <Select
                  className="w-100 dropdown-style"
                  mode="multiple"
                  onChange={(e) => handleWhoList(e, 'extraField')}
                  value={whoList?.extraField}
                  maxTagCount="responsive"
                >
                  {whoList?.mfname?.split(':')?.[0] ===
                  process.env.REACT_APP_STAGE_ID
                    ? listStage?.map((items, index) => {
                        return (
                          <Select.Option value={items?.id} key={index}>
                            {items?.name}
                          </Select.Option>
                        );
                      })
                    : whoList?.mfname?.split(':')?.[0] ===
                      process.env.REACT_APP_DEPARTMENT_ID
                    ? listAllDepartments?.map((items, index) => {
                        return (
                          <Select.Option value={items?.id} key={index}>
                            {items?.name}
                          </Select.Option>
                        );
                      })
                    : userData?.map((items, index) => {
                        return (
                          <Select.Option value={items?.id} key={index}>
                            {' '}
                            {items?.firstName + ' ' + items?.lastName}
                          </Select.Option>
                        );
                      })}
                </Select>
              </Col>
            ) : (
              <Col span={4}>
                <Select
                  className="w-100"
                  onChange={(e) => handleWhoList(e, 'valueTest')}
                  value={whoList?.valueTest}
                >
                  {ideaFilterList?.items
                    ?.filter((e) => e?.id === whoList?.mfname)?.[0]
                    ?.breakpoints?.map((item, index) => {
                      return (
                        <Select.Option value={item?.value} key={index}>
                          {item?.label}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Col>
            )}

            {(whoList?.category === 'is between' ||
              whoList?.category === 'is not between') &&
              (ideaFilterList?.items?.filter(
                (e) => e?.id === whoList?.mfname
              )?.[0]?.type === '#' ? (
                <Col span={4}>
                  <Input
                    prefix={
                      ideaFilterList?.items?.filter(
                        (e) => e?.id === whoList?.mfname
                      )?.[0]?.subType === '$'
                        ? '$'
                        : '#'
                    }
                    value={whoList?.highvalue}
                    onChange={(e) => handleWhoList(e.target.value, 'highvalue')}
                  />
                </Col>
              ) : (
                <Col span={4}>
                  <Select
                    className="w-100"
                    onChange={(e) => handleWhoList(e, 'highscalevalue')}
                    value={whoList?.highscalevalue}
                  >
                    {ideaFilterList?.items
                      ?.filter((e) => e?.id === whoList?.mfname)?.[0]
                      ?.breakpoints?.map((item, index) => {
                        return (
                          <Select.Option value={item?.value} key={index}>
                            {item?.label}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Col>
              ))}
          </Row>
          <Row gutter={10} className="mt-10">
            {(ideaFilterList?.items?.filter(
              (e) => e?.id === whoList?.mfname
            )?.[0]?.type === '#' ||
              ideaFilterList?.items?.filter(
                (e) => e?.id === whoList?.mfname
              )?.[0]?.type === '+-') && (
              <Col span={8}>
                {ideaFilterList?.items?.filter(
                  (e) => e?.id === whoList?.mfname
                )?.[0]?.type === '#' ? (
                  <Row>
                    <Typography.Text className="mr-5">
                      Threshold :
                    </Typography.Text>
                    <Input
                      onChange={(e) =>
                        handleWhoList(e.target.value, 'threshold')
                      }
                      prefix={
                        ideaFilterList?.items?.filter(
                          (e) => e?.id === whoList?.mfname
                        )?.[0]?.subType === '$'
                          ? '$'
                          : '#'
                      }
                      value={whoList?.threshold}
                      className="w-auto"
                      allowClear
                    />
                  </Row>
                ) : (
                  <Row>
                    <Typography.Text className="mr-5">
                      Threshold :
                    </Typography.Text>
                    <Select
                      className="w-60"
                      onChange={(e) => handleWhoList(e, 'threshold')}
                      value={whoList?.threshold}
                      allowClear
                    >
                      {ideaFilterList?.items
                        ?.filter((e) => e?.id === whoList?.mfname)?.[0]
                        ?.breakpoints?.map((item, index) => {
                          return (
                            <Select.Option value={item?.value} key={index}>
                              {item?.label}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Row>
                )}
              </Col>
            )}
            <Col span={7}>
              <Checkbox
                onChange={(e) =>
                  handleWhoList(e.target.checked, 'includeBlanks')
                }
                value={whoList?.includeBlanks}
                checked={whoList?.includeBlanks}
              >
                Include blanks
              </Checkbox>
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
  );
};

export default memo(EditIdeaFilterModal);

