import React, { memo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { message, Modal, Row, Typography } from 'antd';
import { RESTResetPassword } from 'utils/RESTApi';

const ResetPasswordModal = ({
  setResetPassModalOpen,
  resetpassModalOpen,
  userID,
}) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const handleCancelModal = () => {
    setResetPassModalOpen(false);
  };

  const resetPasswordByAdmin = async () => {
    setLoading(true);
    try {
      let input = {
        user_id: userID.id,
      };
      await RESTResetPassword(input);
      setLoading(false);
      setResetPassModalOpen(false);
      message.success('Password reset successfully');
    } catch (err) {
      setLoading(false);
      console.log(err);
      throw err;
    }
  };

  return (
    <>
      <Modal
        open={resetpassModalOpen}
        onOk={resetPasswordByAdmin}
        onCancel={handleCancelModal}
        confirmLoading={loading}
        centered
        okText="Reset Password"
        className="delete-user-modal reset-modal"
      >
        <Row justify="center">{/* <img src={DeleteIcon} alt="" /> */}</Row>
        <Row justify="center">
          <Typography.Title level={4}>
            Are you sure you want to reset <br /> the password for{' '}
            <span className="capitalize-text">
              {userID.firstName + ' ' + userID.lastName}
            </span>
            ?
          </Typography.Title>
        </Row>
      </Modal>
    </>
  );
};

export default memo(ResetPasswordModal);

