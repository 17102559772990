import {
  Alert,
  Button,
  Col,
  Form,
  Input,
  message,
  Row,
  Typography,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { ECHOIMAGES } from '../../Assets/Images';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import OtpInput from 'react-otp-input';
import '../common.less';

function ConfirmSignup(props) {
  const { LoginImg, Logo, Background, AuthLogo } = ECHOIMAGES.Auth;
  const [form] = Form.useForm();
  const history = useHistory();
  const [loading, setloading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [error, seterror] = useState('');
  const [email] = useState(props.location.state?.userName);
  useEffect(() => {
    if (!props.location.state) {
      if (
        window.location.href.includes('confirmationcode=') &&
        window.location.href.includes('/confirm_sign_up') &&
        window.location.href.includes('email=')
      ) {
        const Data = {};
        Data.code = window.location.href
          .split('confirmationcode=')[1]
          .split('&email=')[0];
        Data.email = window.location.href.split('email=')[1];
        form.setFieldsValue({
          code: Data.code,
          email: Data.email,
        });
      } else {
        history.push('/');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Function to handle resend Code
  const handleResendCode = async () => {
    setLoader(true);
    seterror('');
    try {
      await Auth.resendSignUp(email);
      message.success('A new confirmation code has been sent.');
      setLoader(false);
    } catch (error) {
      console.log('error signing up:', error);
      seterror(error.message);
      setLoader(false);
    }
  };

  // Fucntion to handle confirmed sign up
  const handleConfirmSignUp = async (e) => {
    try {
      if (e.email && e.code) {
        setloading(true);
        seterror('');
        await Auth.confirmSignUp(e.email, e.code);
        setloading(false);
        history.push({
          pathname: '/login',
          state: {
            feedback: 'Sign in with your new account',
            userName: e.email,
          },
        });
      } else {
        message.error('something went wrong');
      }
    } catch (error) {
      if (
        error.message.includes(
          'User cannot be confirmed. Current status is CONFIRMED'
        )
      ) {
        history.push('/');
        message.success('Already Verified');
      }
      console.log('error signing up:', error);
      seterror(error.message);
      setloading(false);
    }
  };
  return (
    <>
      <Row className="login">
        <Col
          span={12}
          lg={12}
          md={0}
          xs={0}
          sm={0}
          style={{ backgroundImage: `url(${Background})` }}
        >
          <div className="login-left">
            <Typography.Title level={2}>
              Reconcile competing priorities and
              <br /> help teams focus on the highest
              <br /> impact projects.
            </Typography.Title>
            <img src={LoginImg} alt="" />
          </div>
        </Col>

        <Col span={12} lg={12} md={24} xs={24} sm={24} className="login-form">
          <div className="wrapper">
            <img src={AuthLogo} alt="" />

            <Typography.Title level={3}>Confirm your Account</Typography.Title>
            <Typography.Title level={5}>
              Check your email for a confirmation code
            </Typography.Title>
            {error !== '' && (
              <Alert
                style={{ marginBottom: '10px' }}
                type="error"
                showIcon={false}
                message={error}
                banner
                closable
              />
            )}
            <Form
              layout="vertical"
              requiredMark={false}
              form={form}
              onFinish={(e) => handleConfirmSignUp(e)}
            >
              <Form.Item
                label="Email"
                name="email"
                initialValue={email}
                rules={[
                  {
                    required: true,
                    message: 'Please input your Email!',
                  },
                ]}
              >
                <Input className="input" value={email} disabled />
              </Form.Item>
              <Form.Item
                label="Code"
                name="code"
                rules={[
                  {
                    required: true,
                    message: 'Please input your Code!',
                  },
                ]}
              >
                <OtpInput
                  name="code"
                  shouldAutoFocus
                  className="otp-input"
                  id="code"
                  key="code"
                  numInputs={6}
                  separator={<span style={{ color: 'white' }}> ---</span>}
                />
                {/* <Input className="input" /> */}
              </Form.Item>
              <Row gutter={10}>
                <Col span={12}>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="primary-btn"
                      loading={loading}
                    >
                      Confirm
                    </Button>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="primary-btn"
                      onClick={handleResendCode}
                      loading={loader}
                    >
                      Resend Code
                    </Button>
                  </Form.Item>
                </Col>
              </Row>

              <Typography.Title level={5} className="signup-text">
                Back to <a href="/login">Login</a>
              </Typography.Title>
            </Form>
          </div>
          <Typography.Title level={5} className="footer">
            © {new Date().getFullYear()} Echo Consulting, LLC
          </Typography.Title>
        </Col>
      </Row>
    </>
  );
}

export default ConfirmSignup;
