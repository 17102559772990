import { Button, message } from 'antd';
import React, { memo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getCurrentOrganization,
  getCurrnetUserData,
} from 'store/slices/loginSlice';
import {
  getBackButtonClicked,
  getCurrentStageDetail,
  getDraftForm,
  getFinalForm,
  getPreviewFlag,
  setFinalForm,
  setFormChanged,
} from 'store/slices/StageSlice';
import {
  createFormDraftCustom,
  updateFormDraftCustom,
} from 'graphql/customMutation';
import { API } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import _isEqual from 'lodash/isEqual';

const SaveDraft = ({
  updatedFormElement,
  getStageNameElement,
  fetchPublishedForm,
}) => {
  const history = useHistory();
  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;

  const [loading, setLoading] = useState(false);
  const getStageDetails = useSelector(getCurrentStageDetail);
  const isBackButtonClicked = useSelector(getBackButtonClicked);
  const draftForm = useSelector(getDraftForm);
  const getFinalFormValue = useSelector(getFinalForm);
  const showPreviewFlag = useSelector(getPreviewFlag);
  const dispatch = useDispatch();

  const handleSaveDraft = async () => {
    dispatch(setFinalForm(true));
    setLoading(true);
    dispatch(setFormChanged(false));

    try {
      let input = {
        layout: JSON.stringify(updatedFormElement),
        name: getStageNameElement?.name + ' ' + 'Draft',
        organizationID: orgId,
        stageID: getStageNameElement?.id,
        updatedByIAM: false,
        version: 1,
      };
      await API.graphql({
        query:
          getStageDetails?.draft?.items?.[0]?.version === 1
            ? updateFormDraftCustom
            : createFormDraftCustom,
        variables: { input: input },
      });
      setLoading(false);
      message.success('Draft saved successfully');
      history.push('/stages');
      setTimeout(() => {
        dispatch(setFinalForm(false));
      }, 2000);
    } catch (err) {
      setLoading(false);
      message.error(err.message);
    }
  };

  return (
    <Button
      className="bordered-button mr-10"
      onClick={handleSaveDraft}
      loading={loading}
      size="middle"
    >
      {window.location.pathname.includes('/draft-form')
        ? 'Save'
        : 'Save as Draft'}
    </Button>
  );
};

export default memo(SaveDraft);

