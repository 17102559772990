import React, { memo, useState, useEffect } from 'react';
import {
  getCurrentFormElementID,
  setCurrentFormElementID,
  setFormElementUpdate,
} from 'store/slices/StageSlice';
import {
  EditOutlined,
  HolderOutlined,
  DeleteOutlined,
  UploadOutlined,
  InfoCircleOutlined,
  EyeOutlined,
  MinusOutlined,
  EyeInvisibleOutlined,
} from '@ant-design/icons';
import {
  arrayMove,
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import { useDispatch, useSelector } from 'react-redux';
import { Draggable } from 'react-beautiful-dnd';
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Select,
  Tooltip,
  Typography,
  Upload,
  Row,
  Slider,
} from 'antd';

const StageDetailForm = ({
  updatedFormElement,
  setSelectedElement,
  getClickedElementData,
  inputElementClickHandler,
  stageLevel,
  mfList,
  id,
  setMFList,
}) => {
  const dispatch = useDispatch();
  const [sliderColor, SetSliderColor] = useState({});
  const currentFormElementID = useSelector(getCurrentFormElementID);
  const { TextArea } = Input;
  const { Dragger } = Upload;

  //Function to handles the deletion of a form element at a specified index.
  const handleDelete = (index) => {
    const updatedFormLayout = [...updatedFormElement];
    updatedFormLayout.splice(index, 1);
    dispatch(setFormElementUpdate(updatedFormLayout));
  };

  const seenIds = {};

  const uniqueObjects = updatedFormElement?.filter((obj) => {
    if (seenIds[obj.id]) {
      return false;
    } else {
      seenIds[obj.id] = true;
      return true;
    }
  });

  const handleMFField = (index1, id) => {
    let temp = [...mfList];
    temp[index1].isUsed = false;
    setMFList(temp);
    const updatedFormLayout = updatedFormElement?.filter(
      (item) => item?.id !== id
    );

    dispatch(setFormElementUpdate(updatedFormLayout));
  };

  //Function to handles the hide form element at a specified index.
  const handleHideElement = (data) => {
    const newState = updatedFormElement.map((obj) => {
      if (obj.id === data?.id) {
        return { ...obj, hide: !obj.hide };
      }
      return obj;
    });

    dispatch(setFormElementUpdate(newState));
    setSelectedElement();
    dispatch(setCurrentFormElementID());
  };

  const DragItemHandler = SortableHandle(() => {
    return <HolderOutlined />;
  });

  const onSortEnd = (arr) => {
    let item_links = [...updatedFormElement];
    item_links = arrayMove(item_links, arr.oldIndex, arr.newIndex);
    dispatch(setFormElementUpdate(item_links));
  };
  // const onChange = (e) => {
  //   SetSliderColor(e);
  // };

  const onChange = (value, id) => {
    SetSliderColor({ ...sliderColor, [id]: value });
  };

  useEffect(() => {
    const defaultValues = {};
    updatedFormElement?.forEach((currentElement) => {
      defaultValues[currentElement.id] = 50; // Set default value to 50
    });
    SetSliderColor(defaultValues);
  }, [updatedFormElement]);

  const marks = {};

  updatedFormElement?.forEach((firstItem) => {
    const matchingItem = mfList?.find(
      (secondItem) => secondItem.id === firstItem.id
    );

    if (
      matchingItem &&
      matchingItem.breakpoints &&
      matchingItem.breakpoints.length > 0
    ) {
      marks[firstItem.id] = {
        0: matchingItem?.breakpoints[0]?.label, // Label at 0
        25: matchingItem?.breakpoints[1]?.label, // Label at 25
        50: matchingItem?.breakpoints[2]?.label, // Label at 50
        75: matchingItem?.breakpoints[3]?.label, // Label at 75
        100: matchingItem?.breakpoints[4]?.label, // Label at 100
      };
    } else {
      marks[firstItem.id] = {
        0: 'Label 0',
        25: 'Label 25',
        50: 'Label 50',
        75: 'Label 75',
        100: 'Label 100',
      };
    }
  });
  const marks1 = {};
  // const numericMarks = () => {
  updatedFormElement?.forEach((firstItem) => {
    const matchingItem = mfList?.find(
      (secondItem) => secondItem.id === firstItem.id
    );

    marks1[firstItem.id] = {
      0: matchingItem?.breakpoints[0]?.value, // Label at 0
      25: matchingItem?.breakpoints[0]?.value,
      50: matchingItem?.breakpoints[0]?.value,
      75: matchingItem?.breakpoints[0]?.value,
      100: matchingItem?.breakpoints[1]?.value, // Label at 100
    };
  });

  const ItemRow = SortableElement(({ data: { index, currentElement } }) => {
    let currentStageID =
      stageLevel === parseInt(currentElement?.id.split('-')[0]);

    let parentStage = currentElement?.stageID !== id;
    let defaultItemFlag =
      currentElement?.id !== process.env.REACT_APP_CONTRIBUTOR_ID &&
      currentElement?.id !== process.env.REACT_APP_SPONSOR_ID &&
      currentElement?.id !== process.env.REACT_APP_DEPARTMENT_ID;

    let mfInputType = mfList.find((item) => item.id === currentElement?.id);
    let mfIndex = mfList.findIndex((item) => item.id === currentElement?.id);

    return (
      <Draggable
        key={currentElement.id}
        draggableId={currentElement.id}
        index={index}
      >
        {(provided) => (
          <Col
            span={
              currentElement?.inputType === 'singleLine' ||
              currentElement?.inputType === 'dropdown' ||
              currentElement?.inputType === 'symbol'
                ? 11
                : currentElement?.inputType === 'datepicker' ||
                  currentElement?.inputType === 'dragger' ||
                  currentElement?.inputType === 'link' ||
                  currentElement.inputType === 'related items' ||
                  mfInputType?.subType === '$' ||
                  mfInputType?.subType === '+-' ||
                  mfInputType?.subType === '-+' ||
                  mfInputType?.subType === '#'
                ? 24
                : currentElement?.id === process.env.REACT_APP_DESCRIPTION_ID
                ? 24
                : 11
            }
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={{ backgroundColor: 'white' }}
            className={currentElement?.type === 'measurement' ? 'mt-30' : ''}
          >
            {currentElement?.inputType === 'singleLine' && (
              <Col
                className={`${
                  currentElement?.id === currentFormElementID
                    ? 'highlite-container mb-10 p-0'
                    : 'p-0'
                }`}
                id={currentElement?.id}
                onClick={(e, id = currentElement?.id) => {
                  e.preventDefault();
                  inputElementClickHandler(e, id);
                }}
              >
                {currentElement?.id === currentFormElementID && (
                  <>
                    <div className="drag-icon">
                      <DragItemHandler />
                    </div>

                    <div className="edit-highlight">
                      <EditOutlined
                        onClick={(e) =>
                          getClickedElementData(e, currentElement)
                        }
                      />
                      {currentStageID &&
                      currentElement?.id !== process.env.REACT_APP_TITLE_ID ? (
                        <>
                          <Divider
                            type="vertical"
                            className="highlight-divider"
                          />
                          <DeleteOutlined onClick={() => handleDelete(index)} />
                        </>
                      ) : (
                        currentElement?.id !== process.env.REACT_APP_TITLE_ID &&
                        !currentStageID &&
                        !currentElement?.required && (
                          <>
                            <Divider
                              type="vertical"
                              className="highlight-divider"
                            />
                            <DeleteOutlined
                              onClick={() => handleDelete(index)}
                            />
                          </>
                        )
                      )}
                    </div>
                  </>
                )}
                <Form.Item
                  label={
                    <span className="fs-15">
                      {currentElement?.label}
                      {currentElement?.tooltip && (
                        <Tooltip title={currentElement?.tooltip}>
                          <InfoCircleOutlined
                            style={{
                              marginLeft: 4,
                              color: '#808080',
                            }}
                            className="mr-10"
                          />
                        </Tooltip>
                      )}
                    </span>
                  }
                  className={
                    currentElement?.id === currentFormElementID
                      ? 'w-100 p-10 mb-0 stage-detail-edit'
                      : 'w-100'
                  }
                  name="Ideatitle"
                >
                  {!currentStageID &&
                    currentElement?.id !== currentFormElementID && (
                      <Button
                        className={
                          currentElement?.required && !currentElement?.hide
                            ? 'hide-btn hide-btn-disabled'
                            : !currentElement?.hide
                            ? 'hide-btn'
                            : currentElement?.hide
                            ? 'unhide-btn'
                            : ''
                        }
                        disabled={
                          currentElement?.id === process.env.REACT_APP_TITLE_ID
                        }
                        size="small"
                        onClick={() => handleHideElement(currentElement)}
                      >
                        <span>
                          {currentElement?.hide ? (
                            <EyeInvisibleOutlined />
                          ) : (
                            <EyeOutlined />
                          )}
                        </span>
                      </Button>
                    )}
                  <Input
                    className="input-font l-height "
                    placeholder={currentElement.placeholder}
                    readOnly
                  />
                </Form.Item>
              </Col>
            )}

            {currentElement?.inputType === 'dropdown' && (
              <Col
                id={currentElement?.id}
                className={`${
                  currentElement?.id === currentFormElementID
                    ? 'highlite-container mb-10 p-0'
                    : 'p-0'
                }`}
                onClick={(e, id = currentElement?.id) => {
                  e.preventDefault();
                  inputElementClickHandler(e, id);
                }}
              >
                {currentElement?.id === currentFormElementID && (
                  <>
                    <div className="drag-icon">
                      <DragItemHandler />
                    </div>
                    <div className="edit-highlight">
                      <EditOutlined
                        onClick={(e) =>
                          getClickedElementData(e, currentElement)
                        }
                      />
                      {currentStageID && defaultItemFlag ? (
                        <>
                          <Divider
                            type="vertical"
                            className="highlight-divider"
                          />
                          <DeleteOutlined onClick={() => handleDelete(index)} />
                        </>
                      ) : (
                        !currentStageID &&
                        !currentElement?.required &&
                        defaultItemFlag && (
                          <>
                            <Divider
                              type="vertical"
                              className="highlight-divider"
                            />
                            <DeleteOutlined
                              onClick={() => handleDelete(index)}
                            />
                          </>
                        )
                      )}
                    </div>
                  </>
                )}
                <Form.Item
                  label={<span className="fs-15">{currentElement?.label}</span>}
                  name="Department"
                  className={
                    currentElement?.id === currentFormElementID
                      ? 'w-100 p-10 mb-0'
                      : ''
                  }
                >
                  {!currentStageID &&
                    currentElement?.id !== currentFormElementID && (
                      <Button
                        type="text"
                        className={
                          currentElement?.required && !currentElement?.hide
                            ? 'hide-btn hide-btn-disabled'
                            : !currentElement?.hide
                            ? 'hide-btn'
                            : currentElement?.hide
                            ? 'unhide-btn'
                            : ''
                        }
                        size="small"
                        disabled={
                          currentElement?.id ===
                            process.env.REACT_APP_DEPARTMENT_ID ||
                          currentElement?.id ===
                            process.env.REACT_APP_CONTRIBUTOR_ID ||
                          currentElement?.id ===
                            process.env.REACT_APP_SPONSOR_ID
                        }
                        onClick={() => handleHideElement(currentElement)}
                      >
                        {currentElement?.hide ? (
                          <EyeInvisibleOutlined />
                        ) : (
                          <EyeOutlined />
                        )}
                      </Button>
                    )}
                  <Select
                    className={
                      currentElement?.id === currentFormElementID
                        ? 'input-font stage-detail-edit'
                        : 'input-font'
                    }
                    mode="multiple"
                    placeholder={currentElement.placeholder}
                    style={{
                      pointerEvents: 'none',
                    }}
                  ></Select>
                </Form.Item>
              </Col>
            )}

            {currentElement?.inputType === 'symbol' && (
              <Col
                id={currentElement?.id}
                className={`${
                  currentElement?.id === currentFormElementID
                    ? 'highlite-container mb-10 p-0'
                    : 'p-0'
                }`}
                onClick={(e, id = currentElement?.id) => {
                  e.preventDefault();
                  inputElementClickHandler(e, id);
                }}
              >
                {currentElement?.id === currentFormElementID && (
                  <>
                    <div className="drag-icon">
                      <DragItemHandler />
                    </div>
                    <div className="edit-highlight">
                      <EditOutlined
                        onClick={(e) =>
                          getClickedElementData(e, currentElement)
                        }
                      />
                      {currentStageID ? (
                        <>
                          <Divider
                            type="vertical"
                            className="highlight-divider"
                          />
                          <DeleteOutlined onClick={() => handleDelete(index)} />
                        </>
                      ) : (
                        !currentStageID &&
                        !currentElement?.required && (
                          <>
                            <Divider
                              type="vertical"
                              className="highlight-divider"
                            />
                            <DeleteOutlined
                              onClick={() => handleDelete(index)}
                            />
                          </>
                        )
                      )}
                    </div>
                  </>
                )}
                <Form.Item
                  label={
                    <span className="fs-15">
                      {currentElement?.label}{' '}
                      {currentElement?.tooltip && (
                        <Tooltip title={currentElement?.tooltip}>
                          <InfoCircleOutlined
                            style={{
                              marginLeft: 4,
                              color: '#808080',
                            }}
                            className="mr-10"
                          />
                        </Tooltip>
                      )}
                    </span>
                  }
                  name="Symbol"
                  className={
                    currentElement?.id === currentFormElementID
                      ? 'w-100 p-10 mb-0'
                      : ''
                  }
                >
                  {!currentStageID &&
                    currentElement?.id !== currentFormElementID && (
                      <Button
                        type="text"
                        className={
                          currentElement?.required && !currentElement?.hide
                            ? 'hide-btn hide-btn-disabled'
                            : !currentElement?.hide
                            ? 'hide-btn'
                            : currentElement?.hide
                            ? 'unhide-btn'
                            : ''
                        }
                        size="small"
                        onClick={() => handleHideElement(currentElement)}
                      >
                        {currentElement?.hide ? (
                          <EyeInvisibleOutlined />
                        ) : (
                          <EyeOutlined />
                        )}
                      </Button>
                    )}
                  <Select
                    className={
                      currentElement?.id === currentFormElementID
                        ? 'input-font stage-detail-edit'
                        : 'input-font'
                    }
                    mode="multiple"
                    placeholder={currentElement.placeholder}
                    style={{
                      pointerEvents: 'none',
                    }}
                  ></Select>
                </Form.Item>
              </Col>
            )}

            {currentElement?.inputType === 'multiLine' &&
              (currentElement?.id === process.env.REACT_APP_DESCRIPTION_ID ? (
                <Col
                  className={`${
                    currentElement?.id === currentFormElementID
                      ? 'highlite-container mb-10 p-0'
                      : 'p-0'
                  }`}
                >
                  {currentElement?.id === currentFormElementID && (
                    <>
                      <div className="drag-icon">
                        <DragItemHandler />
                      </div>

                      <div className="edit-highlight">
                        <EditOutlined
                          onClick={(e) =>
                            getClickedElementData(e, currentElement)
                          }
                        />
                        {currentElement?.id !==
                          process.env.REACT_APP_DESCRIPTION_ID && (
                          <>
                            <Divider
                              type="vertical"
                              className="highlight-divider"
                            />
                            <DeleteOutlined
                              onClick={() => handleDelete(index)}
                            />
                          </>
                        )}
                      </div>
                    </>
                  )}
                  <Form.Item
                    name="description"
                    label={
                      <p className="fs-15">
                        {currentElement?.label}
                        {currentElement?.tooltip && (
                          <Tooltip title={currentElement?.tooltip}>
                            <InfoCircleOutlined
                              style={{
                                marginLeft: 4,
                                color: '#808080',
                              }}
                              className="mr-10"
                            />
                          </Tooltip>
                        )}
                        {currentElement?.maxChars !== undefined &&
                          currentElement?.maxChars !== '' && (
                            <span className="text-gray">
                              (Max. {currentElement?.maxChars} limit)
                            </span>
                          )}
                      </p>
                    }
                    className={
                      currentElement?.id === currentFormElementID
                        ? 'w-100 p-10 mb-0 stage-detail-edit'
                        : ''
                    }
                  >
                    {!currentStageID &&
                      currentElement?.id !== currentFormElementID && (
                        <Button
                          className={
                            currentElement?.required && !currentElement?.hide
                              ? 'hide-btn hide-btn-disabled'
                              : !currentElement?.hide
                              ? 'hide-btn'
                              : currentElement?.hide
                              ? 'unhide-btn'
                              : ''
                          }
                          size="small"
                          disabled={currentElement?.required}
                          onClick={() => handleHideElement(currentElement)}
                        >
                          {currentElement?.hide ? (
                            <EyeInvisibleOutlined />
                          ) : (
                            <EyeOutlined />
                          )}
                        </Button>
                      )}
                    <TextArea
                      onClick={(e, id = currentElement?.id) => {
                        inputElementClickHandler(e, id);
                      }}
                      id={currentElement?.id}
                      autoSize={{
                        minRows: 5,
                        maxRows: 5,
                      }}
                      maxLength={currentElement?.maxChars}
                      className="input-font"
                      readOnly
                      className={
                        currentElement?.id === currentFormElementID
                          ? 'input-font stage-detail-edit'
                          : 'input-font'
                      }
                    />
                  </Form.Item>
                </Col>
              ) : (
                <Col
                  className={`${
                    currentElement?.id === currentFormElementID
                      ? 'highlite-container mb-10 p-0'
                      : 'p-0'
                  }`}
                >
                  {currentElement?.id === currentFormElementID && (
                    <>
                      <div className="drag-icon">
                        <DragItemHandler />
                      </div>

                      <div className="edit-highlight">
                        <EditOutlined
                          onClick={(e) =>
                            getClickedElementData(e, currentElement)
                          }
                        />
                        {currentStageID ? (
                          <>
                            <Divider
                              type="vertical"
                              className="highlight-divider"
                            />
                            <DeleteOutlined
                              onClick={() => handleDelete(index)}
                            />
                          </>
                        ) : (
                          !currentStageID &&
                          !currentElement?.required && (
                            <>
                              <Divider
                                type="vertical"
                                className="highlight-divider"
                              />
                              <DeleteOutlined
                                onClick={() => handleDelete(index)}
                              />
                            </>
                          )
                        )}
                      </div>
                    </>
                  )}
                  <Form.Item
                    name="description"
                    label={
                      <p className="fs-15">
                        {currentElement?.label}
                        {currentElement?.tooltip && (
                          <Tooltip title={currentElement?.tooltip}>
                            <InfoCircleOutlined
                              style={{
                                marginLeft: 4,
                                color: '#808080',
                              }}
                              className="mr-10"
                            />
                          </Tooltip>
                        )}
                        <br />
                        {currentElement?.maxChars !== undefined &&
                          currentElement?.maxChars !== '' && (
                            <span className="text-gray">
                              (Max. {currentElement?.maxChars} limit)
                            </span>
                          )}
                      </p>
                    }
                    className={
                      currentElement?.id === currentFormElementID
                        ? 'w-100 p-10 mb-0 stage-detail-edit'
                        : ''
                    }
                  >
                    {!currentStageID &&
                      currentElement?.id !== currentFormElementID && (
                        <Button
                          className={
                            currentElement?.required && !currentElement?.hide
                              ? 'hide-btn hide-btn-disabled'
                              : !currentElement?.hide
                              ? 'hide-btn'
                              : currentElement?.hide
                              ? 'unhide-btn'
                              : ''
                          }
                          size="small"
                          // disabled={currentElement?.required}
                          onClick={() => handleHideElement(currentElement)}
                        >
                          {currentElement?.hide ? (
                            <EyeInvisibleOutlined />
                          ) : (
                            <EyeOutlined />
                          )}
                        </Button>
                      )}

                    <TextArea
                      onClick={(e, id = currentElement?.id) => {
                        inputElementClickHandler(e, id);
                      }}
                      id={currentElement?.id}
                      autoSize={{
                        minRows: 5,
                        maxRows: 5,
                      }}
                      maxLength={currentElement?.maxChars}
                      placeholder={currentElement?.placeholder}
                      readOnly
                    />
                  </Form.Item>
                </Col>
              ))}

            {currentElement.inputType === 'related items' && (
              <Col
                id={currentElement?.id}
                className={`${
                  currentElement?.id === currentFormElementID
                    ? 'highlite-container mb-10 p-0'
                    : 'p-0'
                }`}
                onClick={(e, id = currentElement?.id) => {
                  inputElementClickHandler(e, id);
                }}
              >
                {currentElement?.id === currentFormElementID && (
                  <>
                    <div className="drag-icon">
                      <DragItemHandler />
                    </div>
                  </>
                )}
                <div
                  className={
                    currentElement?.id === currentFormElementID
                      ? 'related-items w-100 p-20 mb-0'
                      : 'related-items'
                  }
                >
                  <Row>
                    <Typography.Title className="fs-15" level={4}>
                      Please link any other items you believe are related to
                      this one.
                    </Typography.Title>
                    {!currentStageID &&
                      currentElement?.id !== currentFormElementID && (
                        <Button
                          className={
                            currentElement?.required && !currentElement?.hide
                              ? 'related-hide-btn related-hide-btn-disabled'
                              : currentElement?.hide
                              ? 'related-unhide-btn'
                              : !currentElement?.hide
                              ? 'related-hide-btn'
                              : ''
                          }
                          size="small"
                          disabled={currentElement?.required}
                          onClick={() => handleHideElement(currentElement)}
                        >
                          {currentElement?.hide ? (
                            <EyeInvisibleOutlined />
                          ) : (
                            <EyeOutlined />
                          )}
                        </Button>
                      )}
                  </Row>
                  <Button className="fw-bold mt-20">Add Related Items</Button>
                </div>
              </Col>
            )}

            {currentElement?.inputType === 'datepicker' && (
              <Col
                className="p-0"
                id={currentElement?.id}
                className={`${
                  currentElement?.id === currentFormElementID
                    ? 'highlite-container mb-10 p-0'
                    : 'p-0'
                }`}
                onClick={(e, id = currentElement?.id) => {
                  inputElementClickHandler(e, id);
                }}
              >
                {currentElement?.id === currentFormElementID && (
                  <>
                    <div className="drag-icon">
                      <DragItemHandler />
                    </div>

                    <div className="edit-highlight">
                      <EditOutlined
                        onClick={(e) =>
                          getClickedElementData(e, currentElement)
                        }
                      />
                      {currentStageID ? (
                        <>
                          <Divider
                            type="vertical"
                            className="highlight-divider"
                          />
                          <DeleteOutlined onClick={() => handleDelete(index)} />
                        </>
                      ) : (
                        !currentStageID &&
                        !currentElement?.required && (
                          <>
                            <Divider
                              type="vertical"
                              className="highlight-divider"
                            />
                            <DeleteOutlined
                              onClick={() => handleDelete(index)}
                            />
                          </>
                        )
                      )}
                    </div>
                  </>
                )}
                <Form.Item
                  label={<span className="fs-15">{currentElement?.label}</span>}
                  name="DatePicker"
                  className={
                    currentElement?.id === currentFormElementID
                      ? 'w-100 p-10 mb-0 stage-detail-edit'
                      : ''
                  }
                >
                  {!currentStageID &&
                    currentElement?.id !== currentFormElementID && (
                      <Button
                        className={
                          currentElement?.required && !currentElement?.hide
                            ? 'hide-btn hide-btn-disabled'
                            : !currentElement?.hide
                            ? 'hide-btn'
                            : currentElement?.hide
                            ? 'unhide-btn'
                            : ''
                        }
                        size="small"
                        // disabled={currentElement?.required}
                        onClick={() => handleHideElement(currentElement)}
                      >
                        {currentElement?.hide ? (
                          <EyeInvisibleOutlined />
                        ) : (
                          <EyeOutlined />
                        )}
                      </Button>
                    )}
                  <DatePicker
                    className="w-100"
                    style={{ pointerEvents: 'none' }}
                    placeholder={currentElement?.placeholder}
                  />
                </Form.Item>
              </Col>
            )}

            {currentElement?.inputType === 'link' && (
              <Col
                className="p-0"
                id={currentElement?.id}
                className={`${
                  currentElement?.id === currentFormElementID
                    ? 'highlite-container mb-10 p-0'
                    : 'p-0'
                }`}
                onClick={(e, id = currentElement?.id) => {
                  inputElementClickHandler(e, id);
                }}
              >
                {currentElement?.id === currentFormElementID && (
                  <>
                    <div className="drag-icon">
                      <DragItemHandler />
                    </div>

                    <div className="edit-highlight">
                      <EditOutlined
                        onClick={(e) =>
                          getClickedElementData(e, currentElement)
                        }
                      />
                      {currentStageID ? (
                        <>
                          <Divider
                            type="vertical"
                            className="highlight-divider"
                          />
                          <DeleteOutlined onClick={() => handleDelete(index)} />
                        </>
                      ) : (
                        !currentStageID &&
                        !currentElement?.required && (
                          <>
                            <Divider
                              type="vertical"
                              className="highlight-divider"
                            />
                            <DeleteOutlined
                              onClick={() => handleDelete(index)}
                            />
                          </>
                        )
                      )}
                    </div>
                  </>
                )}
                <Form.Item
                  label={<span className="fs-15">{currentElement?.label}</span>}
                  name="link"
                  className={
                    currentElement?.id === currentFormElementID
                      ? 'w-100 p-10 mb-0 stage-detail-edit'
                      : ''
                  }
                >
                  {!currentStageID &&
                    currentElement?.id !== currentFormElementID && (
                      <Button
                        className={
                          currentElement?.required && !currentElement?.hide
                            ? 'hide-btn hide-btn-disabled'
                            : !currentElement?.hide
                            ? 'hide-btn'
                            : currentElement?.hide
                            ? 'unhide-btn'
                            : ''
                        }
                        size="small"
                        onClick={() => handleHideElement(currentElement)}
                      >
                        {currentElement?.hide ? (
                          <EyeInvisibleOutlined />
                        ) : (
                          <EyeOutlined />
                        )}
                      </Button>
                    )}
                  <Input placeholder={currentElement?.placeholder} readOnly />
                </Form.Item>
              </Col>
            )}
            {currentElement?.inputType === 'dragger' && (
              <Col
                className="p-0"
                id={currentElement?.id}
                className={`${
                  currentElement?.id === currentFormElementID
                    ? 'highlite-container mb-10 p-0'
                    : 'p-0'
                }`}
                onClick={(e, id = currentElement?.id) => {
                  inputElementClickHandler(e, id);
                }}
              >
                {currentElement?.id === currentFormElementID && (
                  <>
                    <div className="drag-icon">
                      <DragItemHandler />
                    </div>
                    <div className="edit-highlight">
                      <EditOutlined
                        onClick={(e) =>
                          getClickedElementData(e, currentElement)
                        }
                      />
                      {currentStageID ? (
                        <>
                          <Divider
                            type="vertical"
                            className="highlight-divider"
                          />
                          <DeleteOutlined onClick={() => handleDelete(index)} />
                        </>
                      ) : (
                        !currentStageID &&
                        !currentElement?.required && (
                          <>
                            <Divider
                              type="vertical"
                              className="highlight-divider"
                            />
                            <DeleteOutlined
                              onClick={() => handleDelete(index)}
                            />
                          </>
                        )
                      )}
                    </div>
                  </>
                )}
                <Form.Item
                  label={<span className="fs-15">{currentElement?.label}</span>}
                  name="link"
                  className={
                    currentElement?.id === currentFormElementID
                      ? 'w-100 p-10 mb-0'
                      : ''
                  }
                >
                  {!currentStageID &&
                    currentElement?.id !== currentFormElementID && (
                      <Button
                        className={
                          currentElement?.required && !currentElement?.hide
                            ? 'hide-btn hide-btn-disabled'
                            : !currentElement?.hide
                            ? 'hide-btn'
                            : currentElement?.hide
                            ? 'unhide-btn'
                            : ''
                        }
                        size="small"
                        onClick={() => handleHideElement(currentElement)}
                      >
                        {currentElement?.hide ? (
                          <EyeInvisibleOutlined />
                        ) : (
                          <EyeOutlined />
                        )}
                      </Button>
                    )}
                  <Dragger maxCount={5} disabled>
                    <p className="ant-upload-drag-icon">
                      <UploadOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Drag and drop files, or{' '}
                      <span className="text-primary">Browse</span>
                    </p>
                    <p className="ant-upload-hint">
                      Supported format: Word, Excel, PPT, Google Sheets with max
                      size of 5 MB. Max 5 files can be upload
                    </p>
                  </Dragger>
                </Form.Item>
              </Col>
            )}

            {(mfInputType?.subType === '+-' ||
              mfInputType?.subType === '-+') && (
              <>
                <Typography.Text>{mfInputType?.name}</Typography.Text>
                <Col
                  id={currentElement?.id}
                  className={`${
                    currentElement?.id === currentFormElementID
                      ? 'slider-container mb-10'
                      : 'p-0  mb-10 stage-container-slider'
                  }`}
                  onClick={(e, id = currentElement?.id) => {
                    inputElementClickHandler(e, id);
                  }}
                >
                  {currentElement?.id === currentFormElementID && (
                    <>
                      <div className="drag-icon">
                        <DragItemHandler />
                      </div>
                      <div className="edit-highlight">
                        {currentElement?.stageID === id && (
                          <>
                            <MinusOutlined
                              onClick={() =>
                                handleMFField(mfIndex, currentElement?.id)
                              }
                            />
                          </>
                        )}
                      </div>
                    </>
                  )}

                  {parentStage && (
                    <Button
                      className={
                        currentElement?.required && !currentElement?.hide
                          ? 'hide-btn hide-btn-disabled'
                          : !currentElement?.hide
                          ? 'hide-btn'
                          : currentElement?.hide
                          ? 'unhide-btn'
                          : ''
                      }
                      size="small"
                      onClick={() => handleHideElement(currentElement)}
                    >
                      <span>
                        {currentElement?.hide ? (
                          <EyeInvisibleOutlined />
                        ) : (
                          <EyeOutlined />
                        )}
                      </span>
                    </Button>
                  )}

                  <Slider
                    key={currentElement.id}
                    className={
                      mfInputType?.subType === '+-'
                        ? 'stage-slider slider-change-1 m-0'
                        : 'stage-slider slider-change m-0 '
                    }
                    tooltip={{ open: false }}
                    onChange={(value) => onChange(value, currentElement.id)}
                    value={50}
                    marks={marks[currentElement?.id]}
                    disabled
                  />
                </Col>
              </>
            )}
            {(mfInputType?.subType === '$' || mfInputType?.subType === '#') && (
              <>
                <Typography.Text>{mfInputType?.name}</Typography.Text>
                <Col
                  id={currentElement?.id}
                  className={`${
                    currentElement?.id === currentFormElementID
                      ? 'slider-container mb-10'
                      : 'p-0  mb-10 mf-slider'
                  }`}
                  onClick={(e, id = currentElement?.id) => {
                    inputElementClickHandler(e, id);
                  }}
                >
                  {currentElement?.id === currentFormElementID && (
                    <>
                      <div className="drag-icon">
                        <DragItemHandler />
                      </div>
                      <div className="edit-highlight">
                        {currentElement?.stageID === id && (
                          <>
                            <MinusOutlined
                              onClick={() =>
                                handleMFField(mfIndex, currentElement?.id)
                              }
                            />
                          </>
                        )}
                      </div>
                    </>
                  )}

                  {parentStage && (
                    <Button
                      className={
                        currentElement?.required && !currentElement?.hide
                          ? 'hide-btn hide-btn-disabled'
                          : !currentElement?.hide
                          ? 'hide-btn'
                          : currentElement?.hide
                          ? 'unhide-btn'
                          : ''
                      }
                      size="small"
                      onClick={() => handleHideElement(currentElement)}
                    >
                      <span>
                        {currentElement?.hide ? (
                          <EyeInvisibleOutlined />
                        ) : (
                          <EyeOutlined />
                        )}
                      </span>
                    </Button>
                  )}

                  <Form.Item
                    name="numeric"
                    className={
                      currentElement?.id === currentFormElementID
                        ? 'w-100 p-10 mb-0'
                        : ''
                    }
                  >
                    <div className="measurement-slider">
                      <Slider
                        tooltip={{ open: true }}
                        className="numeric-slider mt-45"
                        marks={marks1[currentElement?.id]}
                        tipFormatter={(value) => {
                          const prefix =
                            mfInputType?.subType === '$' ? '$' : '#';
                          return `${prefix}${marks1[currentElement?.id][0]}`;
                        }}
                        disabled
                      />
                    </div>
                  </Form.Item>
                </Col>
              </>
            )}
            {provided.placeholder}
          </Col>
        )}
      </Draggable>
    );
  });

  const TableBodySortable = SortableContainer(({ children }) => {
    return (
      <Row justify="space-between" className="sortable-list">
        {children}
      </Row>
    );
  });

  return (
    <TableBodySortable
      onSortEnd={onSortEnd}
      useDragHandle={true}
      helperContainer={() => document.querySelector('.stage-details')}
    >
      {uniqueObjects?.map((currentElement, index) => {
        const lastIndex = updatedFormElement.length - 1;
        return (
          <>
            <ItemRow
              index={index}
              key={index}
              data={{ index, currentElement }}
            />
          </>
        );
      })}
    </TableBodySortable>
  );
};

export default memo(StageDetailForm);

