import { Form, Input, message, Modal, Typography } from 'antd';
import React, { memo, useEffect, useState } from 'react';
import { updateStageCustom } from '../Services/StageActions';
import {
  setNewStageName,
  setStage2Name,
  setStage3Name,
  setStageName,
} from 'store/slices/StageSlice';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

const EditStageName = ({
  IseditNameModalOpen,
  setIsEditNameModalOpen,
  currentStage,
  listAllStagesByOrg,
  getFormLayout,
}) => {
  const handleCancelModal = () => {
    setIsEditNameModalOpen(false);
  };
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const location = useLocation();

  useEffect(() => {
    form.setFieldsValue({ stagename: currentStage?.name });
    // eslint-disable-next-line
  }, [currentStage]);

  const editStageName = async () => {
    setLoading(true);
    try {
      let inputData = {
        id: currentStage?.id,
        name: form.getFieldValue().stagename,
      };
      let res = await updateStageCustom(inputData);
      if (location?.pathname === '/stages') {
        await listAllStagesByOrg();
      } else {
        await getFormLayout();
      }
      if (res?.level === 1) {
        dispatch(setNewStageName(res?.name));
      }
      if (res?.level === 2) {
        dispatch(setStage2Name(res?.name));
      }
      if (res?.level === 3) {
        dispatch(setStage3Name(res?.name));
      }
      message.success('Stage name updated successfully');
      dispatch(setStageName(res));
      setIsEditNameModalOpen(false);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  return (
    <Modal
      open={IseditNameModalOpen}
      onCancel={handleCancelModal}
      okText="Save"
      cancelText="Cancel"
      onOk={editStageName}
      confirmLoading={loading}
    >
      <div>
        <Typography.Title level={4} className="title-font">
          Edit Stage Name
        </Typography.Title>

        <Form form={form} layout="vertical">
          <Form.Item label="Stage" name="stagename">
            <Input />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default memo(EditStageName);

