import { Form, Input, Button, Typography } from 'antd';
import { Link } from 'react-router-dom';
import React, { useEffect } from 'react';
import { LoadingOutlined } from '@ant-design/icons';

export default function Step2(props) {
  const [form] = Form.useForm();
  useEffect(() => {
    if (props?.organizationEmail) {
      form.setFieldsValue({
        organisationname: props?.organizationEmail,
      });
    }
    // eslint-disable-next-line
  }, [props?.organizationEmail]);

  return (
    <>
      <Typography.Title level={3}>Profile details</Typography.Title>
      <Typography.Title level={5}>
        Please enter your profile details
      </Typography.Title>
      <Form
        layout="vertical"
        requiredMark={false}
        form={form}
        onFinish={(e) => props?.updateAuth(e)}
      >
        <Form.Item
          label="First Name"
          name="firstname"
          rules={[
            {
              required: true,
              message: 'Please input your First Name',
            },
            {
              pattern: /^[a-zA-Z]+$/,
              message: 'Symbols, number or whitespace are not allowed',
            },
          ]}
        >
          <Input className="input" placeholder="Enter first name" />
        </Form.Item>
        <Form.Item
          label="Last Name"
          name="lastname"
          rules={[
            {
              required: true,
              message: 'Please input your Last Name',
            },
            {
              pattern: /^[a-zA-Z]+$/,
              message: 'Symbols, number or whitespace are not allowed',
            },
          ]}
        >
          <Input className="input" placeholder="Enter last name" />
        </Form.Item>
        <Form.Item
          label="Organization Name"
          name="organisationname"
          rules={[
            {
              required: true,
              message: 'Please input organization name',
            },
          ]}
        >
          <Input
            className="input"
            placeholder="Enter your organisation name"
            disabled={props.organizationEmail}
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="blue-filled-btn w-100 h-40 mt-10"
            loading={props.signupLoading}
          >
            Sign Up
          </Button>
        </Form.Item>

        <Typography.Title level={5} className="signup-text">
          Already have an account?{' '}
          <Link
            to="/login"
            onClick={() => {
              if (props?.authenticatedUser) {
                props?.setAuthenticatedUser('');
              }
            }}
          >
            Login
          </Link>
        </Typography.Title>
      </Form>
    </>
  );
}
