import { Button, Input, Row, Skeleton, Table } from 'antd';
import React, { memo, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  getCurrentOrganization,
  getCurrnetUserData,
} from 'store/slices/loginSlice';
import { debounceFn } from 'utils/commonMethods';
import { UserRole } from 'utils/Constants';
import { RESTListPartners } from 'utils/RESTApi';
import PartnerTable from './components/PartnerTable';

const Partner = () => {
  const { Search } = Input;
  const [partnersDetails, setPartnetDetails] = useState([]);
  const history = useHistory();
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const currnetOrg = useSelector(getCurrentOrganization);
  const userId = currnetOrg?.adminID || loginUsrOrg?.id;
  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;
  const [loading, setLoading] = useState(true);
  const [sortTableOrder, setSortTableOrder] = useState('DESC');
  const [sortTableColumn, setSortTableColumn] = useState('createdAt');
  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const fetchOrganizations = async (sortOrder, sortColumn, search, page) => {
    let tempPage = page || currentPage;
    let allData = [];
    try {
      let sort_direction = sortTableOrder;
      let sort_field = sortTableColumn;

      if (sortOrder && sortColumn) {
        sort_direction = sortOrder;
        sort_field = sortColumn;
      }
      let inputData = {
        sort_field: sort_field,
        sort_direction: sort_direction,
        page: tempPage,
        user_id: userId,
        organization_id: orgId,
        scope: currnetOrg?.id ? UserRole?.admin : UserRole?.superAdmin,
      };
      if (search !== undefined && search !== '') {
        inputData.filters = {
          Search: search,
        };
      }
      let response = await RESTListPartners(inputData);
      const temp = response?.body?.map((item) => {
        return {
          ...item,
        };
      });
      allData = tempPage === 1 ? temp : [...partnersDetails, ...temp];
      setPartnetDetails(allData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const isFetching = useRef(false);
  const handelInfiniteScroll = async (e) => {
    const { target } = e;
    const isBottom =
      Math.round(target.scrollHeight) - Math.round(target.scrollTop) ===
      Math.round(target.clientHeight);
    if (isBottom && partnersDetails?.length === 20) {
      if (isFetching.current) {
        return; // Exit if the function is already running
      }
      isFetching.current = true;
      setCurrentPage((prevPage) => prevPage + 1);
      await fetchOrganizations(
        sortTableOrder,
        sortTableColumn,
        searchText,
        currentPage + 1
      );
      isFetching.current = false;
    }
  };

  const sortTableData = async (sortData) => {
    let sortOrder = sortData.order === 'ascend' ? 'ASC' : 'DESC';
    let sortColumn = sortData.field;
    setSortTableOrder(sortOrder);
    setSortTableColumn(sortColumn);
    setCurrentPage(1);
    await fetchOrganizations(sortOrder, sortColumn, searchText, 1);
  };

  const onSearch = async (searchText) => {
    if (searchText !== '') {
      setSearchText(searchText);
      setCurrentPage(1);
      await fetchOrganizations(sortTableOrder, sortTableColumn, searchText, 1);
    } else {
      setSearchText('');
      setCurrentPage(1);
      await fetchOrganizations(sortTableOrder, sortTableColumn, '', 1);
    }
  };

  const debouncedHandleInput = debounceFn(onSearch, 500);

  const handleInputSearch = (searchText) => {
    debouncedHandleInput(searchText);
  };

  useEffect(() => {
    fetchOrganizations();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="background-white ">
      <Row justify="space-between" className="mb-10">
        <Search
          className="searchbar-style"
          placeholder="search by name,website,contact"
          onChange={(e) => handleInputSearch(e.target.value)}
        />
        <Button
          type="primary"
          onClick={() => history.push('/partner/add-partner')}
        >
          Add Partner
        </Button>
      </Row>
      {loading ? (
        <Skeleton
          active
          paragraph={{
            rows: 4,
          }}
        />
      ) : (
        <PartnerTable
          partnersDetails={partnersDetails}
          sortTableData={sortTableData}
          sortOrder={sortTableOrder}
          sortColumn={sortTableColumn}
          handelInfiniteScroll={handelInfiniteScroll}
        />
      )}
    </div>
  );
};

export default memo(Partner);

