import { Col, Modal, Row, Typography } from 'antd';
import React from 'react';
import './custom.less';

/**
 *
 * @param {*} children
 * @param {*} visible
 * @param {*} title
 * @param {Boolean} buttonLoader
 * @param {Function} onSubmit Submit Button will visible if passed onSubmit
 * @param {Function} onCancel Cancel Button will visible if passed onCancel
 * @param {Function} cancleButtonText
 * @param {Function} submitButtonText
 * @returns
 */
function CustomModal({
  title,
  children,
  buttonLoader,
  onSubmit,
  onCancel,
  visible,
  submitButtonText,
}) {
  return (
    <Modal
      open={visible}
      onOk={onSubmit}
      onCancel={() => onCancel()}
      confirmLoading={buttonLoader}
      centered
      okText={submitButtonText ? submitButtonText : 'Assign'}
      className="delete-user-modal"
    >
      <Typography.Title level={4}>{title}</Typography.Title>

      {children}
    </Modal>
  );
}

export default CustomModal;

