import React, { memo, useState } from 'react';

import { message, Modal, Row, Typography } from 'antd';

import { ECHOIMAGES } from 'Assets/Images';
import { RESTChangeOrg } from 'utils/RESTApi';

const ChangeUserOrgModal = ({
  isChangeModal,
  setIsChangeModal,
  userDataID,
  selectdOrgid,
  selectdOrg,
  getAllUsers,
}) => {
  const [loading, setLoading] = useState(false);
  const { DeleteIcon } = ECHOIMAGES.superAdminIcons;

  const handleOrgChangeCancel = () => {
    setIsChangeModal(false);
  };
  const handleChangeUsersOrg = async () => {
    setLoading(true);
    try {
      let inputData = {
        user_id: userDataID?.id,
        org_id: selectdOrgid,
        new_org_id: selectdOrg,
      };
      await RESTChangeOrg(inputData);
      await getAllUsers();
      message.warning('User organization change initiated....');
      handleOrgChangeCancel();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      throw err;
    }
  };

  return (
    <>
      <Modal
        open={isChangeModal}
        onOk={handleChangeUsersOrg}
        onCancel={handleOrgChangeCancel}
        confirmLoading={loading}
        centered
        okText="Confirm"
        className="delete-user-modal"
      >
        <Row justify="center">
          <img src={DeleteIcon} alt="" />
        </Row>
        <Row justify="center">
          <Typography.Title level={4}>
            You are about to change user organization.
          </Typography.Title>
        </Row>
      </Modal>
    </>
  );
};

export default memo(ChangeUserOrgModal);

