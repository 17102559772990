import { Form, Input, message, Modal, Typography } from 'antd';
import React, { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getCurrentOrganization,
  getCurrnetUserData,
} from 'store/slices/loginSlice';
import { createStage } from '../Services/StageActions';

const EditStageModal = ({
  setIsEditModalOpen,
  IseditModalOpen,
  listStage,
  listAllStagesByOrg,
}) => {
  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;

  const [loading, setLoading] = useState(false);

  const handleCancelModal = () => {
    setIsEditModalOpen(false);
  };

  const [form] = Form.useForm();

  const createNewStage = async (e) => {
    setLoading(true);
    try {
      const existingStages = listStage?.map((item) => item.level);
      const maxLevel = Math.max(...existingStages);
      const nextLevel = maxLevel + 1;
      const lastStage = listStage.reduce((acc, stage) => {
        return stage.level > (acc.level || 0) ? stage : acc;
      }, {});

      let input = {
        organizationID: orgId,
        name: form.getFieldValue().stagename,
        level: nextLevel,
        active: false,
        form: {
          layout: lastStage?.form?.layout,
        },
      };
      await createStage(input);
      setIsEditModalOpen(false);
      await listAllStagesByOrg();
      message.success('Stages added successfully');
      setLoading(false);
      form.setFieldsValue({ stagename: '' });
    } catch (err) {
      setLoading(false);
      console.log(err);
      throw err;
    }
  };

  return (
    <Modal
      open={IseditModalOpen}
      onCancel={handleCancelModal}
      okText="Save"
      cancelText="Cancel"
      onOk={createNewStage}
      confirmLoading={loading}
      className="add-stage-modal"
    >
      <div>
        <Typography.Title level={4} className="title-font">
          Add New Stage
        </Typography.Title>

        <Form form={form} layout="vertical" requiredMark={false}>
          <Form.Item
            label="Stage"
            name="stagename"
            className="form-label"
            rules={[
              {
                required: true,
                message: 'Please enter a value!',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default memo(EditStageModal);

