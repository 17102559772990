import {
  Button,
  Col,
  Divider,
  Drawer,
  message,
  Row,
  Skeleton,
  Typography,
} from 'antd';
import { ECHOIMAGES } from 'Assets/Images';
import { FetchUser } from 'Pages/AllPriorities/IdeaFunction';
import { listObjective } from 'Pages/Goal/GoalFunction';
import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  getCurrentOrganization,
  getCurrnetUserData,
} from 'store/slices/loginSlice';
import { fetchOrgDepartments } from 'utils/CommonFunctions';
import { arrToCommaSeperated } from 'utils/commonMethods';
import { capitalizeFirstLetter, UserRole, userStatus } from 'utils/Constants';
import { WarningOutlined } from '@ant-design/icons';
const ScenarioDetail = ({
  scenarioDetailDrawer,
  setScenarioDetailDrawer,
  selectedScenarioDetail,
  setPosition1Selected,
  setPosition2Selected,
  scenarioLoader,
}) => {
  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;

  const [goalNameandWeight, setGoalNameandWeight] = useState([]);
  const [totalWeight, setTotalWeight] = useState();
  const [ownerList, setOwnerList] = useState([]);
  const { warningIcon } = ECHOIMAGES.PriorityBatches;

  const history = useHistory();

  const listGoals = async () => {
    try {
      const filter = {
        organizationObjectivesId: orgId,
      };
      const res = await listObjective(filter);

      let totalWeightPercentage = 0;
      const result = selectedScenarioDetail?.priorities?.map(
        ({ weight, ObjectiveID }) => {
          const matchedObjective =
            res.data.objectivesByOrganizationObjectivesId.items.find(
              (item) => item.id === ObjectiveID
            );
          totalWeightPercentage += weight;

          return {
            name: matchedObjective ? matchedObjective.name : '',
            weight: weight,
          };
        }
      );
      setTotalWeight(totalWeightPercentage);
      setGoalNameandWeight(result);
    } catch (error) {
      message.error(error.message);
    }
  };

  const calculateTotal = () => {
    let total = 0;
    for (let i = 0; i < selectedScenarioDetail?.priorities?.length; i++) {
      total += parseInt(selectedScenarioDetail?.priorities[i].weight || 0);
    }
    return total;
  };

  const [listAllDepartments, setListAllDepartments] = useState([]);
  const listOrgDepartments = async () => {
    const departments = await fetchOrgDepartments(orgId);
    setListAllDepartments(departments);
  };

  //get all users by org
  const getAllUsers = async () => {
    try {
      let data = {
        status: { eq: userStatus.active },
        or: [
          { role: { eq: UserRole.admin } },
          { role: { eq: UserRole.leader } },
          { role: { eq: UserRole.superAdmin } },
          { role: { eq: UserRole.sme } },
        ],
      };
      let id = { organizationID: orgId };
      let nextToken = null;
      let allUserData = [];
      while (true) {
        let res = await FetchUser(data, id, nextToken);
        allUserData.push(...res.items);
        if (!res?.nextToken) {
          break;
        }
        nextToken = res?.nextToken;
      }
      await processUserData(allUserData);
    } catch (err) {
      console.log(err);
    }
  };
  const processUserData = async (userData) => {
    setOwnerList(userData);
  };

  const handleFilterValue = (item) => {
    if (item?.field?.type === '#') {
      const symbol = item?.field?.subType === '$' ? '$' : '';
      // If type is '#'
      if (
        Object.entries(item?.filter)[0][0] === 'is between' ||
        Object.entries(item?.filter)[0][0] === 'is not between'
      ) {
        return (
          Object.entries(item?.filter)[0][0] +
          ' ' +
          symbol +
          Object.entries(item?.filter)[0][1][0] +
          '-' +
          symbol +
          Object.entries(item?.filter)[0][1][1]
        );
      } else {
        return (
          Object.entries(item?.filter)[0][0] +
          ' ' +
          symbol +
          Object.entries(item?.filter)[0][1]
        );
      }
    } else if (item?.field?.type === '+-') {
      // If type is '+-'
      if (
        Object.entries(item?.filter)[0][0] === 'is between' ||
        Object.entries(item?.filter)[0][0] === 'is not between'
      ) {
        return (
          Object.entries(item?.filter)[0][0] +
          ' ' +
          item?.field?.breakpoints.find(
            (b) =>
              parseInt(b.value) ===
              parseInt(Object.entries(item?.filter)[0][1][0])
          )?.label +
          '-' +
          item?.field?.breakpoints.find(
            (b) =>
              parseInt(b.value) ===
              parseInt(Object.entries(item?.filter)[0][1][1])
          )?.label
        );
      } else {
        return (
          Object.entries(item?.filter)[0][0] +
          ' ' +
          item?.field?.breakpoints?.find(
            (b) => b.value === Object.entries(item?.filter)[0][1]
          )?.label
        );
      }
    } else if (
      item?.field?.name === 'Sponsor' ||
      item?.field?.name === 'Contributor'
    ) {
      return (
        Object.entries(item?.filter)[0][0] +
        ' ' +
        Object.entries(item?.field)
          .filter(([key, value]) => typeof value !== 'string')
          .map((item) => item?.[1]?.firstName + ' ' + item?.[1]?.lastName)
          .join(', ')
      );
    } else {
      return (
        Object.entries(item?.filter)[0][0] +
        ' ' +
        Object.entries(item?.field)
          .filter(([key, value]) => typeof value !== 'string')
          .map((item) => item?.[1]?.name)
          .join(', ')
      );
    }
  };

  useEffect(() => {
    listGoals();
    listOrgDepartments();
    getAllUsers();
    // eslint-disable-next-line
  }, []);
  return (
    <Drawer
      maskClosable={false}
      open={scenarioDetailDrawer}
      closeIcon={null}
      closable={false}
      onClose={() => {
        setScenarioDetailDrawer(false);
        setPosition1Selected(false);
        setPosition2Selected(false);
      }}
      className="scenario-detail-drawer"
    >
      {scenarioLoader ? (
        <Skeleton active />
      ) : (
        <div>
          <Typography.Title level={4} className="mb-0">
            {selectedScenarioDetail?.title}
          </Typography.Title>
          <Row>
            <Typography.Text level={3} className="mb-5">
              {selectedScenarioDetail?.description}
            </Typography.Text>
          </Row>
          <Typography.Text>
            Created date :{' '}
            {new Date(selectedScenarioDetail?.createdAt).toLocaleDateString(
              'en-GB'
            )}
          </Typography.Text>
          <Row>
            <Typography.Text>
              Last modified date :{' '}
              {new Date(selectedScenarioDetail?.updatedAt).toLocaleDateString(
                'en-GB'
              )}
            </Typography.Text>
          </Row>
          <div className="mt-10">
            <Typography.Text>Owners :</Typography.Text>
            <Row>
              <Typography.Text>
                {selectedScenarioDetail?.owners?.map((item) => {
                  return item?.firstName + ' ' + item?.lastName;
                })}
              </Typography.Text>
            </Row>
          </div>
          {selectedScenarioDetail?.priorities?.length > 0 && (
            <>
              <Row justify="space-between" className="mt-10">
                <Typography.Text>Goals</Typography.Text>
                <Typography.Text>Weight</Typography.Text>
              </Row>
              <Row>
                {selectedScenarioDetail?.priorities?.map((item) => {
                  return (
                    <Col span={24} className="scenario-goals">
                      <Typography.Title level={5} className="mb-0">
                        {item?.name}
                      </Typography.Title>
                      <Typography.Title level={5} className="mt-0 mb-0">
                        {item?.weight}%
                      </Typography.Title>
                    </Col>
                  );
                })}
              </Row>
              <Row className="weight-count">
                <Typography.Text className="mt-10">
                  Total weight : {calculateTotal()}%{' '}
                </Typography.Text>
              </Row>
            </>
          )}
          {(selectedScenarioDetail?.voteFilters?.filters?.length > 0 ||
            selectedScenarioDetail?.rankingFilters?.filters?.length > 0) && (
            <Typography.Title level={5} className="mb-0">
              Scenario Filters
            </Typography.Title>
          )}
          {selectedScenarioDetail?.rankingFilters?.filters?.length > 0 && (
            <Typography.Text>
              <span
                className={
                  selectedScenarioDetail?.rankingFilters?.count === 0
                    ? 'fw-bold zero-items-count'
                    : selectedScenarioDetail?.rankingFilters?.count <= 5
                    ? 'fw-bold yellow-items-count'
                    : 'fw-bold'
                }
              >
                {' '}
                {selectedScenarioDetail?.rankingFilters?.count}
              </span>{' '}
              items match your scenario
            </Typography.Text>
          )}
          {selectedScenarioDetail?.voteFilters?.filters?.length > 0 && (
            <Row className="mt-10">
              <Row className="w-100">
                <Col span={24}>
                  <Typography.Title
                    level={5}
                    className="fs-16 fw-500 idea-text"
                  >
                    Who
                  </Typography.Title>
                </Col>
              </Row>
              {selectedScenarioDetail?.voteFilters?.match?.length > 0 && (
                <Row className="mb-10">
                  <Col span={24}>
                    <Typography.Text level={5} className="fs-14">
                      Use votes that match
                    </Typography.Text>
                    <Typography.Text className="f-14 ml-5 fw-bold">
                      {selectedScenarioDetail?.voteFilters?.match === 'ALL'
                        ? 'All conditions'
                        : selectedScenarioDetail?.voteFilters?.match === 'ONE'
                        ? 'Any conditions'
                        : null}
                    </Typography.Text>
                  </Col>
                </Row>
              )}
              {selectedScenarioDetail?.voteFilters?.filters?.map(
                (item, index) => {
                  let includeStringDepartment = 'to be excluded';
                  let includeStringUserRole = 'to be excluded';
                  let includedStringUsers = 'to be excluded';
                  let addedDepartment = [];
                  if (
                    item.departmentFilters &&
                    item.departmentFilters.included
                  ) {
                    includeStringDepartment = 'to be included';
                  }
                  if (item.userRoleFilters && item.userRoleFilters.included) {
                    includeStringUserRole = 'to be included';
                  }
                  if (item.userFilters && item.userFilters.included) {
                    includedStringUsers = 'to be included';
                  }
                  addedDepartment = item?.departmentFilters?.departmentID?.map(
                    (id) => {
                      return listAllDepartments?.find((e) => e?.id === id)
                        ?.name;
                    }
                  );

                  return (
                    <>
                      <div className="added-filter w-100">
                        <Row>
                          {item?.departmentFilters?.departmentID?.length >
                            0 && (
                            <>
                              <Typography.Text className="fw-500">
                                Department
                              </Typography.Text>
                              <Typography.Text className="ml-5 fw-500">
                                {includeStringDepartment} :{' '}
                              </Typography.Text>
                              <Typography.Text
                                style={
                                  true
                                    ? {
                                        width: 100,
                                      }
                                    : undefined
                                }
                                ellipsis={
                                  true
                                    ? {
                                        tooltip:
                                          arrToCommaSeperated(addedDepartment),
                                      }
                                    : false
                                }
                                className="ml-5"
                              >
                                <span>
                                  {arrToCommaSeperated(addedDepartment)}
                                </span>
                              </Typography.Text>
                            </>
                          )}
                        </Row>
                        <Row>
                          {item?.userRoleFilters?.userROLE?.length > 0 && (
                            <>
                              <Typography.Text className="fw-500">
                                User role
                              </Typography.Text>{' '}
                              <Typography.Text className="ml-5 fw-500">
                                {includeStringUserRole} :{' '}
                              </Typography.Text>
                              <Typography.Text
                                style={
                                  true
                                    ? {
                                        width: 120,
                                      }
                                    : undefined
                                }
                                ellipsis={
                                  true
                                    ? {
                                        tooltip:
                                          item?.userRoleFilters?.userROLE?.map(
                                            (role, index) => (
                                              <>
                                                <span className="fw-500">
                                                  {role === UserRole.sme
                                                    ? capitalizeFirstLetter(
                                                        UserRole?.teamMember
                                                      )
                                                    : capitalizeFirstLetter(
                                                        role
                                                      )}
                                                </span>{' '}
                                                {item?.userRoleFilters?.weight
                                                  ?.length > 0 && (
                                                  <>
                                                    (W=
                                                    <span className="fw-500">
                                                      {
                                                        item?.userRoleFilters
                                                          ?.weight[index]
                                                      }
                                                    </span>
                                                    )
                                                  </>
                                                )}
                                                {index <
                                                  item?.userRoleFilters
                                                    ?.userROLE?.length -
                                                    1 && <span>, </span>}
                                              </>
                                            )
                                          ),
                                      }
                                    : false
                                }
                                className="ml-5"
                              >
                                {item?.userRoleFilters?.userROLE?.map(
                                  (role, index) => (
                                    <>
                                      <span>
                                        {role === UserRole.sme
                                          ? capitalizeFirstLetter(
                                              UserRole?.teamMember
                                            )
                                          : capitalizeFirstLetter(role)}
                                      </span>{' '}
                                      {item?.userRoleFilters?.weight?.length >
                                        0 && (
                                        <>
                                          (W=
                                          <span className="fw-500">
                                            {
                                              item?.userRoleFilters?.weight[
                                                index
                                              ]
                                            }
                                          </span>
                                          )
                                        </>
                                      )}
                                      {index <
                                        item?.userRoleFilters?.userROLE
                                          ?.length -
                                          1 && <span>, </span>}
                                    </>
                                  )
                                )}
                              </Typography.Text>
                            </>
                          )}
                        </Row>
                        <Row>
                          {item?.userFilters?.userID?.length > 0 && (
                            <>
                              <Typography.Text className="fw-500">
                                Users
                              </Typography.Text>
                              <Typography.Text className="ml-5 fw-500">
                                {includedStringUsers} :{' '}
                              </Typography.Text>
                              <Typography.Text
                                className="ml-5"
                                style={
                                  true
                                    ? {
                                        width: 140,
                                      }
                                    : undefined
                                }
                                ellipsis={
                                  true
                                    ? {
                                        tooltip: item?.userFilters?.userID?.map(
                                          (name, index) => (
                                            <>
                                              <span>
                                                {ownerList?.find(
                                                  (e) => e?.id === name
                                                )?.firstName +
                                                  ' ' +
                                                  ownerList?.find(
                                                    (e) => e?.id === name
                                                  )?.lastName}{' '}
                                              </span>
                                              {item?.userFilters?.weight
                                                ?.length > 0 && (
                                                <>
                                                  (W=
                                                  <span className="fw-500">
                                                    {
                                                      item?.userFilters?.weight[
                                                        index
                                                      ]
                                                    }
                                                  </span>
                                                  )
                                                </>
                                              )}
                                              {index <
                                                item?.userFilters?.userID
                                                  ?.length -
                                                  1 && <span>, </span>}
                                            </>
                                          )
                                        ),
                                      }
                                    : false
                                }
                              >
                                {item?.userFilters?.userID?.map(
                                  (name, index) => (
                                    <>
                                      <span>
                                        {ownerList?.find((e) => e?.id === name)
                                          ?.firstName +
                                          ' ' +
                                          ownerList?.find((e) => e?.id === name)
                                            ?.lastName}{' '}
                                      </span>
                                      {item?.userFilters?.weight && (
                                        <>
                                          (W=
                                          <span className="fw-500">
                                            {item?.userFilters?.weight[index]}
                                          </span>
                                          )
                                        </>
                                      )}
                                      {index <
                                        item?.userFilters?.userID?.length -
                                          1 && <span>, </span>}
                                    </>
                                  )
                                )}
                              </Typography.Text>
                            </>
                          )}
                        </Row>
                      </div>
                    </>
                  );
                }
              )}
            </Row>
          )}

          {selectedScenarioDetail?.rankingFilters?.filters?.length > 0 && (
            <div className=" mt-10">
              <div>
                <Typography.Text className="idea-text">Ideas</Typography.Text>
              </div>
              <Typography.Text>Show ideas that match</Typography.Text>
              <Typography.Text className="f-14 ml-5 fw-bold ">
                {selectedScenarioDetail?.rankingFilters?.match === 'ALL'
                  ? 'All conditions'
                  : selectedScenarioDetail?.rankingFilters?.match === 'ONE'
                  ? 'Any conditions'
                  : null}
              </Typography.Text>
            </div>
          )}
          {selectedScenarioDetail?.rankingFilters?.filters?.map((item) => {
            return (
              <Row className="scenario-filter-list scenario-detail-drawer mt-10">
                <Col
                  span={24}
                  className={
                    item?.isUpdated
                      ? 'added-filter updated-bg'
                      : item?.isDeleted
                      ? ' added-filter deleted-bg'
                      : 'added-filter'
                  }
                >
                  <Row>
                    <Col span={22}>
                      <Row>
                        <>
                          {item?.isUpdated && (
                            <img src={warningIcon} alt="" className="mr-5" />
                          )}
                          {item?.isDeleted && <WarningOutlined />}
                          <Typography.Text
                            className={
                              item?.isDeleted
                                ? 'mr-5 deleted-mf-color'
                                : 'mr-5 fw-500'
                            }
                          >
                            {item?.field?.name}
                          </Typography.Text>
                          <Typography.Text
                            className={
                              item?.isDeleted ? 'deleted-mf-color' : ''
                            }
                          >
                            {handleFilterValue(item)}
                          </Typography.Text>
                        </>
                      </Row>
                      <Row>
                        <>
                          {item?.threshold !== null &&
                            item?.threshold !== undefined && (
                              <Typography.Text
                                className={
                                  item?.isDeleted
                                    ? 'deleted-mf-color mr-5'
                                    : 'mr-5'
                                }
                              >
                                <span className="fw-500">Threshold : </span>
                                {item?.field?.type === '+-' ? (
                                  item?.field?.breakpoints?.find(
                                    (el) =>
                                      parseInt(el?.value) ===
                                      parseInt(item?.threshold)
                                  )?.label
                                ) : (
                                  <>
                                    {item?.field?.subType === '$' ? '$' : ''}
                                    {item?.threshold}
                                  </>
                                )}
                              </Typography.Text>
                            )}
                          <Typography.Text
                            className={
                              item?.isDeleted ? 'deleted-mf-color' : ''
                            }
                          >
                            <span className="fw-500"> Include blanks : </span>
                            {item?.blanks ? 'true' : 'false'}
                          </Typography.Text>
                        </>
                      </Row>
                      {item?.isUpdated && (
                        <>
                          <Divider className="updated-divider" />
                          <Typography.Text>Update required</Typography.Text>
                        </>
                      )}
                      {item?.isDeleted && (
                        <>
                          <Divider className="updated-divider" />
                          <Typography.Text>
                            Field no longer available
                          </Typography.Text>
                        </>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            );
          })}
        </div>
      )}
      <div className="drawer-btn-plan">
        <Button
          className="drawer-btn-child"
          onClick={() => {
            setScenarioDetailDrawer(false);
            setPosition1Selected(false);
            setPosition2Selected(false);
          }}
          size="middle"
        >
          Cancel
        </Button>
        <Button
          type="primary"
          className="drawer-btn-child"
          htmlType="submit"
          onClick={() =>
            history.push({
              pathname: `/add-scenario/${selectedScenarioDetail?.id}`,
            })
          }
          size="middle"
          disabled={scenarioLoader}
        >
          Go to Scenario
        </Button>
      </div>
    </Drawer>
  );
};

export default memo(ScenarioDetail);

