import {
  Button,
  Dropdown,
  Menu,
  message,
  Modal,
  Row,
  Select,
  Table,
  Tabs,
  Tooltip,
  Typography,
} from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  EditOutlined,
  DoubleLeftOutlined,
  MoreOutlined,
} from '@ant-design/icons';
import {
  getOrganizations,
  getUserDetails,
  listUserRoles,
  updateRole,
} from './SuperAdminFun';
import { ECHOIMAGES } from '../../Assets/Images';
import { useHistory } from 'react-router-dom';
import { RESTChangeOrg, RESTDeleteUser } from 'utils/RESTApi';
import { useSelector } from 'react-redux';
import {
  getCurrentOrganization,
  getCurrnetUserData,
} from 'store/slices/loginSlice';
import { UserRole, userStatus } from 'utils/Constants';

function UserDetails(props) {
  const { Text } = Typography;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isActiveModalOpen, setIsActiveModalOpen] = useState(false);
  const [isChangeModal, setIsChangeModal] = useState(false);
  const [isRoleModalOpen, setIsRoleModalOpen] = useState(false);
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const [organizationModal, setOrganizationModal] = useState(false);
  const [userID, setUserID] = useState('');
  const [userDataID, setUserDataID] = useState('');
  const [loading, setLoading] = useState(false);
  const [allRoles, setAllRoles] = useState([]);
  const [selectdRole, setSelectedRole] = useState('');
  const [selectdOrg, setSelectedOrg] = useState('');
  const [selectdOrgid, setSelectedOrgID] = useState('');
  const [organizationDetail, setOrganizationDetail] = useState([]);
  const [roleLoader, setRoleLoader] = useState(false);
  const { DeleteIcon } = ECHOIMAGES.superAdminIcons;
  const [data, setData] = useState([]);
  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;
  const userId = currnetOrg?.adminID || loginUsrOrg?.id;
  const history = useHistory();
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showActiveModal = () => {
    setIsActiveModalOpen(true);
  };
  const handleActiveCancel = () => {
    setIsActiveModalOpen(false);
  };
  const showRoleModal = () => {
    setIsRoleModalOpen(true);
  };
  const handleRoleModalCancel = () => {
    setIsRoleModalOpen(false);
  };
  const showDeleteUserModal = () => {
    setDeleteUserModal(true);
  };
  const handleDeleteUserModal = () => {
    setDeleteUserModal(false);
  };
  const showOrganizationModal = () => {
    setOrganizationModal(true);
  };
  const handleOrgCancel = () => {
    setOrganizationModal(false);
  };
  const showOrganizationChangeModal = () => {
    setIsChangeModal(true);
  };
  const handleOrgChangeCancel = () => {
    setIsChangeModal(false);
  };
  const getAllUsers = async () => {
    const filter = {
      organizationID: {
        eq: props.location.state?.orgId,
      },
    };
    await getUserDetails(filter).then((res) => setData(res));
  };

  const fetchOrganizations = async () => {
    try {
      const data = await getOrganizations();
      if (data?.length) {
        setOrganizationDetail(data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleDeleteUser = async () => {
    setLoading(true);
    try {
      const disableUser = {
        id: userID,
        status: 'DISABLED',
      };
      await updateRole(disableUser);
      await getAllUsers();
      setLoading(false);
      setIsModalOpen(false);
      message.success('User disabled successfully');
    } catch (error) {
      setLoading(false);
      console.log(error);
      message.error(error.message);
    }
  };

  const handleActiveUser = async () => {
    setLoading(true);
    try {
      const activeUser = {
        id: userID,
        status: 'ENABLED',
      };
      await updateRole(activeUser);
      await getAllUsers();
      setLoading(false);
      setIsActiveModalOpen(false);
      message.success('User enabled successfully');
    } catch (error) {
      setLoading(false);
      console.log(error);
      message.error(error.message);
    }
  };

  const handleGetRoles = async () => {
    try {
      const data = await listUserRoles();
      setAllRoles(data?.data?.__type?.enumValues);
    } catch (error) {
      console.log(error);
    }
  };

  const AssignRole = async (id) => {
    setRoleLoader(true);
    try {
      const roleData = {
        id: userDataID?.id,
        role: selectdRole,
      };
      await updateRole(roleData);
      setRoleLoader(false);
      message.success('Role Assign Successfully');
      setIsRoleModalOpen(false);
      await getAllUsers();
    } catch (error) {
      console.log(error);
      setRoleLoader(false);
    }
  };

  const deleteOrgUser = async () => {
    setLoading(true);
    try {
      let inputData = {
        user_id: userID,
        org_id: orgId,
      };
      await RESTDeleteUser(inputData);
      await getAllUsers();
      message.success('User Delete Successfully');
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const handleChangeUsersOrg = async () => {
    setLoading(true);
    try {
      let inputData = {
        user_id: userDataID?.id,
        org_id: selectdOrgid,
        new_org_id: selectdOrg,
      };
      await RESTChangeOrg(inputData);
      await getAllUsers();
      message.warning('User organization change initiated....');
      handleOrgChangeCancel();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };
  useEffect(() => {
    handleGetRoles();
    getAllUsers();
    fetchOrganizations();
    // eslint-disable-next-line
  }, []);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 350,
      render: (text, row) => {
        return {
          props: {
            className: row?.status === 'DISABLED' ? 'disabled-row' : '',
          },
          children: text,
        };
      },
    },
    {
      title: 'Created on',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text, row) => {
        return {
          props: {
            className: row?.status === 'DISABLED' ? 'disabled-row' : '',
          },
          children: moment(text).format('l'),
        };
      },
    },
    {
      title: 'Organization',
      dataIndex: 'Organization',
      key: 'Organization',
      render: (text, row) => {
        return {
          props: {
            className: row?.status === 'DISABLED' ? 'disabled-row' : '',
          },
          children: (
            <>
              <span className="mr-10">{text?.name}</span>
              <EditOutlined
                className="editOutlined"
                onClick={() => {
                  showOrganizationModal();
                  setUserDataID(row);
                  setSelectedOrg(row?.Organization?.name);
                  setSelectedOrgID(row?.Organization?.id);
                }}
              />
            </>
          ),
        };
      },
      sorter: (a, b) => {
        return a?.Organization?.name.localeCompare(b?.Organization?.name);
      },
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (text, row) => {
        return {
          props: {
            className: row?.status === 'DISABLED' ? 'disabled-row' : '',
          },
          children: (
            <div>
              <Text
                style={
                  true
                    ? {
                        width: 120,
                      }
                    : undefined
                }
                ellipsis={
                  true
                    ? {
                        tooltip: <span>{row?.email}</span>,
                      }
                    : false
                }
              >
                {row?.email}
              </Text>
            </div>
          ),
        };
      },
      sorter: (a, b) => {
        return a?.email.localeCompare(b?.email);
      },
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, row) => {
        return {
          props: {
            className: row?.status === 'DISABLED' ? 'disabled-row' : '',
          },
          children:
            row?.firstName === null
              ? '-'
              : row?.firstName + ' ' + row?.lastName,
        };
      },
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: (text, row) => {
        let roleName = text === UserRole.sme ? UserRole?.teamMember : text;
        return {
          props: {
            className: row?.status === 'DISABLED' ? 'disabled-row' : '',
          },
          children: (
            <div>
              <span className="mr-10">{roleName}</span>{' '}
              {row.status !== 'DISABLED' && (
                <EditOutlined
                  className="editOutlined"
                  onClick={() => {
                    showRoleModal();
                    setUserDataID(row);
                    setSelectedRole(row?.role);
                  }}
                />
              )}
            </div>
          ),
        };
      },
    },
    {
      title: 'Onboarding Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, row) => {
        return {
          props: {
            className: row?.status === 'DISABLED' ? 'disabled-row' : '',
          },
          children: text,
        };
      },
    },
    {
      title: 'Last Login',
      dataIndex: 'lastLogin',
      key: 'lastLogin',
      render: (text, row) => {
        return {
          props: {
            className: row?.status === 'DISABLED' ? 'disabled-row' : '',
          },
          children:
            new Date(row.lastLogin).toLocaleDateString() +
            ' ' +
            new Date(row.lastLogin).toLocaleTimeString(),
        };
      },
    },
    {
      title: 'Action',
      dataIndex: 'sction',
      key: 'action',
      fixed: 'right',
      width: 80,
      render: (text, row) => (
        <Dropdown
          overlay={
            <Menu
              items={[
                {
                  label: <span>Enable</span>,
                  key: 0,
                  onClick: () => {
                    showActiveModal();
                    setUserID(row?.id);
                  },
                  disabled: row?.status === userStatus.disabled ? false : true,
                },
                {
                  label: <span>Disable</span>,
                  key: '1',
                  onClick: () => {
                    showModal();
                    setUserID(row?.id);
                  },
                  disabled:
                    row?.status === userStatus.active ||
                    row?.status === userStatus.enable ||
                    row?.status === userStatus.confirm ||
                    row?.status === userStatus.invited
                      ? false
                      : true,
                },
                {
                  label: <span>Delete User</span>,
                  key: '2',
                  onClick: () => {
                    showDeleteUserModal();
                    setUserID(row?.id);
                  },
                },
              ].filter((item) => !item.disabled)}
            />
          }
          trigger={['click']}
        >
          <MoreOutlined style={{ color: '#5AACC9' }} className="fs-30" />
        </Dropdown>
      ),
    },
  ];
  const items = [
    {
      key: '1',
      label: `User Management`,
      children: (
        <Table
          pagination={false}
          columns={columns}
          dataSource={data}
          scroll={{
            x: 1700,
          }}
          className="admin-table"
          size={screens.xxl ? 'middle' : 'small'}
        />
      ),
    },
    {
      key: '2',
      label: `Billing Management`,
      children: 'Billing Management',
    },
    {
      key: '3',
      label: `Integration`,
      children: 'Integration',
    },
  ];
  return (
    <>
      <div className="background-white">
        <Tooltip title="Back">
          <DoubleLeftOutlined
            className="icon-size cursor-pointer"
            onClick={() => history.push('/organizations')}
          />
        </Tooltip>
        <Tabs defaultActiveKey="1" items={items} centered />
      </div>
      <Modal
        open={isModalOpen}
        onOk={handleDeleteUser}
        onCancel={handleCancel}
        confirmLoading={loading}
        centered
        okText="Yes"
        className="delete-user-modal"
      >
        <Row justify="center">
          <img src={DeleteIcon} alt="" />
        </Row>
        <Row justify="center">
          <Typography.Title level={4}>
            Do you really want to Disable this user?
          </Typography.Title>
        </Row>
      </Modal>
      <Modal
        open={isActiveModalOpen}
        onOk={handleActiveUser}
        onCancel={handleActiveCancel}
        confirmLoading={loading}
        centered
        okText="Yes"
        className="delete-user-modal"
      >
        <Row justify="center">
          <img src={DeleteIcon} alt="" />
        </Row>
        <Row justify="center">
          <Typography.Title level={4}>
            Do you really want to Active this user?
          </Typography.Title>
        </Row>
      </Modal>
      <Modal
        open={isRoleModalOpen}
        onOk={AssignRole}
        onCancel={handleRoleModalCancel}
        confirmLoading={roleLoader}
        centered
        okText="Assign"
        className="delete-user-modal"
      >
        <Row justify="center">
          <Typography.Title level={4}>Assign Role</Typography.Title>
        </Row>
        <Row justify="center">
          <Select
            style={{
              width: 200,
            }}
            value={selectdRole}
            onChange={(e) => setSelectedRole(e)}
          >
            {allRoles?.map((item, index) => {
              let roleName =
                item.name === UserRole.sme ? UserRole?.teamMember : item.name;
              return (
                <Select.Option key={index} value={item?.name}>
                  {roleName}
                </Select.Option>
              );
            })}
          </Select>
        </Row>
      </Modal>
      <Modal
        open={deleteUserModal}
        onOk={deleteOrgUser}
        onCancel={handleDeleteUserModal}
        confirmLoading={loading}
        centered
        okText="Yes"
        className="delete-user-modal"
      >
        <Row justify="center">
          <img src={DeleteIcon} alt="" />
        </Row>
        <Row justify="center">
          <Typography.Title level={4}>
            Do you really want to Delete this User?
          </Typography.Title>
        </Row>
      </Modal>
      <Modal
        open={organizationModal}
        onOk={showOrganizationChangeModal}
        onCancel={handleOrgCancel}
        centered
        okText="Assign"
        className="delete-user-modal"
      >
        <Row justify="center">
          <Typography.Title level={4}>Change Organization</Typography.Title>
        </Row>
        <Row justify="center">
          <Select
            style={{
              width: 200,
            }}
            value={selectdOrg}
            onChange={(e) => setSelectedOrg(e)}
          >
            {organizationDetail?.map((item, index) => {
              return (
                <Select.Option key={index} value={item?.id}>
                  {item?.name}
                </Select.Option>
              );
            })}
          </Select>
        </Row>
      </Modal>
      <Modal
        open={isChangeModal}
        onOk={handleChangeUsersOrg}
        onCancel={handleOrgChangeCancel}
        confirmLoading={loading}
        centered
        okText="Confirm"
        className="delete-user-modal"
      >
        <Row justify="center">
          <img src={DeleteIcon} alt="" />
        </Row>
        <Row justify="center">
          <Typography.Title level={4}>
            You are about to change user organization.
          </Typography.Title>
        </Row>
      </Modal>
    </>
  );
}

export default UserDetails;
