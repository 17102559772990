import React, { useEffect, useState } from 'react';

const CounterComponent = ({ initialValue }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (initialValue !== '' && count < Number(initialValue)) {
      const intervalId = setInterval(() => {
        setCount((prevCount) => {
          if (prevCount >= initialValue) {
            clearInterval(intervalId);
            return prevCount;
          }

          if (prevCount + 1000 <= initialValue) {
            return prevCount + 1000;
          } else if (prevCount + 100 <= initialValue) {
            return prevCount + 100;
          } else if (prevCount + 10 <= initialValue) {
            return prevCount + 10;
          } else {
            setTimeout(() => {
              setCount(prevCount + 1);
            }, 200); // Wait for 100ms before incrementing the count
            return prevCount;
          }
        });
      }, 50);

      return () => clearInterval(intervalId);
    }
    // eslint-disable-next-line
  }, [initialValue]);

  return (
    <>
      {count
        ? count < 10
          ? '0' + count
          : count
        : count === 0 || count === undefined
        ? '0'
        : 0}
    </>
  );
};

export default CounterComponent;

