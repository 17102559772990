import React, { memo, useEffect, useState } from 'react';
import { Col, Divider, Row, Skeleton, Typography } from 'antd';
import { fetchOrgDepartments } from 'utils/CommonFunctions';
import { FetchUser } from 'Pages/AllPriorities/IdeaFunction';
import { capitalizeFirstLetter, UserRole, userStatus } from 'utils/Constants';
import { useSelector } from 'react-redux';
import {
  getCurrentOrganization,
  getCurrnetUserData,
} from 'store/slices/loginSlice';
import { arrToCommaSeperated } from 'utils/commonMethods';
import { ECHOIMAGES } from 'Assets/Images';
import { WarningOutlined } from '@ant-design/icons';
import NoFilterImage from './NoFilterImage';

const ScenarioDetailFilterList = ({ itemData, loading }) => {
  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;

  const [ownerList, setOwnerList] = useState([]);
  const [listAllDepartments, setListAllDepartments] = useState([]);
  const { warningIcon } = ECHOIMAGES.PriorityBatches;

  const listOrgDepartments = async () => {
    const departments = await fetchOrgDepartments(orgId);
    setListAllDepartments(departments);
  };

  //get all users by org
  const getAllUsers = async () => {
    try {
      let data = {
        status: { eq: userStatus.active },
        or: [
          { role: { eq: UserRole.admin } },
          { role: { eq: UserRole.leader } },
          { role: { eq: UserRole.superAdmin } },
          { role: { eq: UserRole.sme } },
        ],
      };
      let id = { organizationID: orgId };
      let nextToken = null;
      let allUserData = [];
      while (true) {
        let res = await FetchUser(data, id, nextToken);
        allUserData.push(...res.items);
        if (!res?.nextToken) {
          break;
        }
        nextToken = res?.nextToken;
      }
      await processUserData(allUserData);
    } catch (err) {
      console.log(err);
    }
  };
  const processUserData = async (userData) => {
    setOwnerList(userData);
  };
  useEffect(() => {
    listOrgDepartments();
    getAllUsers();
    // eslint-disable-next-line
  }, []);

  const handleFilterValue = (item) => {
    if (item?.field?.type === '#') {
      const symbol = item?.field?.subType === '$' ? '$' : '';
      // If type is '#'
      if (
        Object.entries(item?.filter)[0][0] === 'is between' ||
        Object.entries(item?.filter)[0][0] === 'is not between'
      ) {
        return (
          Object.entries(item?.filter)[0][0] +
          ' ' +
          symbol +
          Object.entries(item?.filter)[0][1][0] +
          '-' +
          symbol +
          Object.entries(item?.filter)[0][1][1]
        );
      } else {
        return (
          Object.entries(item?.filter)[0][0] +
          ' ' +
          symbol +
          Object.entries(item?.filter)[0][1]
        );
      }
    } else if (item?.field?.type === '+-') {
      // If type is '+-'
      if (
        Object.entries(item?.filter)[0][0] === 'is between' ||
        Object.entries(item?.filter)[0][0] === 'is not between'
      ) {
        return (
          Object.entries(item?.filter)[0][0] +
          ' ' +
          item?.field?.breakpoints.find(
            (b) =>
              parseInt(b.value) ===
              parseInt(Object.entries(item?.filter)[0][1][0])
          )?.label +
          '-' +
          item?.field?.breakpoints.find(
            (b) =>
              parseInt(b.value) ===
              parseInt(Object.entries(item?.filter)[0][1][1])
          )?.label
        );
      } else {
        return (
          Object.entries(item?.filter)[0][0] +
          ' ' +
          item?.field?.breakpoints?.find(
            (b) => b.value === Object.entries(item?.filter)[0][1]
          )?.label
        );
      }
    } else if (
      item?.field?.name === 'Sponsor' ||
      item?.field?.name === 'Contributor'
    ) {
      return (
        Object.entries(item?.filter)[0][0] +
        ' ' +
        Object.entries(item?.field)
          .filter(([key, value]) => typeof value !== 'string')
          .map((item) => item?.[1]?.firstName + ' ' + item?.[1]?.lastName)
          .join(', ')
      );
    } else {
      return (
        Object.entries(item?.filter)[0][0] +
        ' ' +
        Object.entries(item?.field)
          .filter(([key, value]) => typeof value !== 'string')
          .map((item) => item?.[1]?.name)
          .join(', ')
      );
    }
  };

  return (
    <>
      <Row justify="space-between">
        <Typography.Title level={5} className="fs-16 mb-0">
          Scenario Filters
        </Typography.Title>
        {itemData?.rankingFilters?.filters?.length > 0 && (
          <Typography.Text level={5} className="fs-14">
            <span
              className={
                itemData?.rankingFilters?.count === 0
                  ? 'fw-bold zero-items-count'
                  : itemData?.rankingFilters?.count <= 5
                  ? 'fw-bold yellow-items-count'
                  : 'fw-bold'
              }
            >
              {' '}
              {itemData?.rankingFilters?.count}{' '}
            </span>{' '}
            items match your scenario
          </Typography.Text>
        )}
      </Row>
      {itemData?.voteFilters?.filters?.length > 0 ||
      itemData?.rankingFilters?.filters?.length > 0 ? (
        <>
          {itemData?.voteFilters?.filters?.length > 0 && (
            <Row justify="space-between">
              <Typography.Text className="idea-text">Who</Typography.Text>
              <div>
                <Typography.Text level={5} className="fs-14">
                  Use votes that match
                </Typography.Text>
                <Typography.Text className="f-14 ml-5 fw-bold ">
                  {itemData?.voteFilters?.match === 'ALL'
                    ? 'All conditions'
                    : itemData?.voteFilters?.match === 'ONE'
                    ? 'Any conditions'
                    : null}
                </Typography.Text>
              </div>
            </Row>
          )}
          <Row className="scenario-filter-list scenario-detail-drawer mt-10">
            {loading ? (
              <>
                <Skeleton.Input active />
                <Skeleton.Input active />
                <Skeleton.Input active />
              </>
            ) : (
              itemData?.voteFilters?.filters?.map((item, index) => {
                let includeStringDepartment = 'to be excluded';
                let includeStringUserRole = 'to be excluded';
                let includedStringUsers = 'to be excluded';

                let addedDepartment = [];
                if (item.departmentFilters && item.departmentFilters.included) {
                  includeStringDepartment = 'to be included';
                }
                if (item.userRoleFilters && item.userRoleFilters.included) {
                  includeStringUserRole = 'to be included';
                }
                if (item.userFilters && item.userFilters.included) {
                  includedStringUsers = 'to be included';
                }
                addedDepartment = item?.departmentFilters?.departmentID?.map(
                  (id) => {
                    return listAllDepartments?.find((e) => e?.id === id)?.name;
                  }
                );

                return (
                  <div className="added-filter w-100">
                    <Row>
                      {item?.departmentFilters?.departmentID?.length > 0 && (
                        <>
                          <Typography.Text className="fw-500">
                            Department
                          </Typography.Text>
                          <Typography.Text className="ml-5 fw-500">
                            {includeStringDepartment} :{' '}
                          </Typography.Text>
                          <Typography.Text
                            style={
                              true
                                ? {
                                    width: 700,
                                  }
                                : undefined
                            }
                            ellipsis={
                              true
                                ? {
                                    tooltip:
                                      arrToCommaSeperated(addedDepartment),
                                  }
                                : false
                            }
                            className="ml-5"
                          >
                            <span>{arrToCommaSeperated(addedDepartment)}</span>
                          </Typography.Text>
                        </>
                      )}
                    </Row>
                    <Row className="mt-5">
                      {item?.userRoleFilters?.userROLE?.length > 0 && (
                        <>
                          <Typography.Text className="fw-500">
                            User role
                          </Typography.Text>{' '}
                          <Typography.Text className="ml-5 fw-500">
                            {includeStringUserRole} :{' '}
                            {item?.userRoleFilters?.userROLE?.map(
                              (role, index) => (
                                <>
                                  <span>
                                    {role === UserRole.sme
                                      ? capitalizeFirstLetter(
                                          UserRole?.teamMember
                                        )
                                      : capitalizeFirstLetter(role)}
                                  </span>{' '}
                                  {item?.userRoleFilters?.weight?.length >
                                    0 && (
                                    <>
                                      <span className="fw-500">
                                        (W=
                                        {item?.userRoleFilters?.weight[index]})
                                      </span>
                                    </>
                                  )}
                                  {index <
                                    item?.userRoleFilters?.userROLE?.length -
                                      1 && <span>, </span>}
                                </>
                              )
                            )}
                          </Typography.Text>
                        </>
                      )}
                    </Row>
                    <Row className="mt-5">
                      {item?.userFilters?.userID?.length > 0 && (
                        <>
                          <Typography.Text className="fw-500">
                            Users
                          </Typography.Text>
                          <Typography.Text className="ml-5 fw-500">
                            {includedStringUsers} :{' '}
                          </Typography.Text>
                          <Typography.Text
                            style={
                              true
                                ? {
                                    width: 700,
                                  }
                                : undefined
                            }
                            ellipsis={
                              true
                                ? {
                                    tooltip: item?.userFilters?.userID?.map(
                                      (name, index) => (
                                        <>
                                          <span className="fw-500">
                                            {ownerList?.find(
                                              (e) => e?.id === name
                                            )?.firstName +
                                              ' ' +
                                              ownerList?.find(
                                                (e) => e?.id === name
                                              )?.lastName}{' '}
                                          </span>
                                          {item?.userFilters?.weight?.length >
                                            0 && (
                                            <>
                                              (W=
                                              <span className="fw-500">
                                                {
                                                  item?.userFilters?.weight[
                                                    index
                                                  ]
                                                }
                                              </span>
                                              )
                                            </>
                                          )}
                                          {index <
                                            item?.userFilters?.userID?.length -
                                              1 && <span>, </span>}
                                        </>
                                      )
                                    ),
                                  }
                                : false
                            }
                            className="ml-5"
                          >
                            {item?.userFilters?.userID?.map((name, index) => (
                              <>
                                <span>
                                  {ownerList?.find((e) => e?.id === name)
                                    ?.firstName +
                                    ' ' +
                                    ownerList?.find((e) => e?.id === name)
                                      ?.lastName}{' '}
                                </span>
                                {item?.userFilters?.weight?.length > 0 && (
                                  <>
                                    <span className="fw-500">
                                      (W=
                                      {item?.userFilters?.weight[index]})
                                    </span>
                                  </>
                                )}
                                {index <
                                  item?.userFilters?.userID?.length - 1 && (
                                  <span>, </span>
                                )}
                              </>
                            ))}
                          </Typography.Text>
                        </>
                      )}
                    </Row>
                  </div>
                );
              })
            )}
          </Row>

          {itemData?.rankingFilters?.filters?.length > 0 && (
            <Row className="mt-10" justify="space-between">
              <Typography.Text className="idea-text">Ideas</Typography.Text>
              <div>
                <Typography.Text>Show ideas that match</Typography.Text>
                <Typography.Text className="f-14 ml-5 fw-bold ">
                  {itemData?.rankingFilters?.match === 'ALL'
                    ? 'All conditions'
                    : itemData?.rankingFilters?.match === 'ONE'
                    ? 'Any conditions'
                    : null}
                </Typography.Text>
              </div>
            </Row>
          )}
          {itemData?.rankingFilters?.filters?.map((item) => {
            return (
              <Row className="scenario-filter-list scenario-detail-drawer mt-10">
                <Col
                  span={24}
                  className={
                    item?.isUpdated
                      ? 'added-filter updated-bg'
                      : item?.isDeleted
                      ? ' added-filter deleted-bg'
                      : 'added-filter'
                  }
                >
                  <Row>
                    <Col span={22}>
                      <Row>
                        <>
                          {item?.isUpdated && (
                            <img src={warningIcon} alt="" className="mr-5" />
                          )}
                          {item?.isDeleted && <WarningOutlined />}
                          <Typography.Text
                            className={
                              item?.isDeleted
                                ? 'mr-5 deleted-mf-color'
                                : 'mr-5 fw-500'
                            }
                          >
                            {item?.field?.name}
                          </Typography.Text>
                          <Typography.Text
                            className={
                              item?.isDeleted ? 'deleted-mf-color' : ''
                            }
                          >
                            {handleFilterValue(item)}
                          </Typography.Text>
                        </>
                      </Row>
                      <Row>
                        <>
                          {item?.threshold !== null &&
                            item?.threshold !== undefined && (
                              <Typography.Text
                                className={
                                  item?.isDeleted
                                    ? 'deleted-mf-color mr-10'
                                    : 'mr-10'
                                }
                              >
                                <span className="fw-500"> Threshold :</span>{' '}
                                {item?.field?.type === '+-' ? (
                                  item?.field?.breakpoints?.find(
                                    (el) =>
                                      parseInt(el?.value) ===
                                      parseInt(item?.threshold)
                                  )?.label
                                ) : (
                                  <>
                                    {item?.field?.subType === '$' ? '$' : ''}
                                    {item?.threshold}
                                  </>
                                )}
                              </Typography.Text>
                            )}
                          <Typography.Text>
                            <span className="fw-500">Include blanks : </span>{' '}
                            {item?.blanks ? 'true' : 'false'}
                          </Typography.Text>
                        </>
                      </Row>
                      {item?.isUpdated && (
                        <>
                          <Divider className="updated-divider" />
                          <Typography.Text>Update required</Typography.Text>
                        </>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            );
          })}
        </>
      ) : (
        <NoFilterImage />
      )}
    </>
  );
};

export default memo(ScenarioDetailFilterList);

