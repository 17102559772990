import { Breadcrumb, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  getDraftForm,
  getformElementUpdate,
  getMFFormChange,
  getPublishedForm,
  getStageName,
  setBackButtonClicked,
} from 'store/slices/StageSlice';
import LeavePageModal from 'Pages/Stages/Components/LeavePageModal';
import _isEqual from 'lodash/isEqual';
import HeaderBreadcrumb from './HeaderBreadcrumb';
import LeaveMFModal from 'Pages/MeasurementField/Components/LeaveMFModal';
import { getBrandingForm } from 'store/slices/brandingSlice';

const BackComponent = () => {
  const history = useHistory();
  const pathname = window.location.pathname;
  const updatedFormElement = useSelector(getformElementUpdate);
  const draftForm = useSelector(getDraftForm);
  const fetchPublishedForm = useSelector(getPublishedForm);
  const getStageNameElement = useSelector(getStageName);
  const dispatch = useDispatch();
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const formhasChanged = useSelector(getMFFormChange);
  const orgFormhasChanged = useSelector(getBrandingForm);

  const handleBackButtonClick = () => {
    const isFormChanged =
      pathname?.includes('/draft-form') ||
      pathname?.includes('/stage1-draft-form')
        ? !_isEqual(updatedFormElement, JSON?.parse(draftForm))
        : !_isEqual(updatedFormElement, JSON?.parse(fetchPublishedForm));
    if (isFormChanged) {
      setIsConfirmationVisible(true);
    } else {
      history.push('/stages');
    }
  };

  const handleMFBack = () => {
    if (formhasChanged) {
      setIsModalOpen(true);
    } else {
      history.push('/measurementfield-list');
    }
  };

  const handleOrgBack = () => {
    if (orgFormhasChanged) {
      setIsModalOpen(true);
    } else if (
      window.location.pathname === '/organization/add-organization' ||
      window.location.pathname.includes('/organization/edit-organization')
    ) {
      history.push('/organizations');
    } else {
      history.push('/partner');
    }
  };

  const handleBack = () => {
    let targetPath = '/'; // Default target path

    // Define custom target paths based on the current pathname
    if (pathname === '/rate-detail') {
      targetPath = '/rate';
    } else if (pathname === '/add-goal' || pathname.includes('/add-goal')) {
      targetPath = '/goal';
    } else if (
      pathname.includes('/ranking-detail') ||
      pathname === '/add-idea' ||
      pathname.includes('/edit-idea')
    ) {
      targetPath = window.history.back();
    } else if (
      pathname.includes('/add-scenario') ||
      pathname === '/compare-scenarios' ||
      pathname.includes('/scenario-detail')
    ) {
      targetPath = '/scenarios';
    } else if (
      history?.location?.state?.deptID &&
      pathname.includes('/edit-active-user')
    ) {
      targetPath = `/department-details/${history?.location?.state?.deptID}`;
    } else if (
      pathname.includes('/edit-active-user') ||
      pathname.includes('/edit-invited-user') ||
      pathname == '/add-user'
    ) {
      targetPath = '/user-management';
    } else if (
      pathname.includes('/stage1') ||
      pathname.includes('/stageDetail') ||
      pathname.includes('/stage1-draft-form') ||
      pathname.includes('/draft-form')
    ) {
      targetPath = '/stages';
    } else if (pathname.includes('/add-user')) {
      targetPath = window.history.back();
    } else if (pathname === '/integration/integration-detail') {
      targetPath = '/integration';
    } else if (
      pathname.includes('/measurementfield/scale') ||
      pathname.includes('/measurementfield/numeric')
    ) {
      targetPath = '/measurementfield-list';
    } else if (pathname.includes('/organization/add-organization')) {
      targetPath = '/organizations';
    } else if (pathname.includes('/partner/add-partner')) {
      targetPath = '/partner';
    } else if (pathname.includes('/organization/edit-organization')) {
      targetPath = '/organizations';
    } else if (pathname.includes('/partner/edit-partner')) {
      targetPath = '/partner';
    } else if (pathname.includes('/organization/detail')) {
      targetPath = '/organizations';
    } else if (pathname.includes('/partner/detail')) {
      targetPath = '/partner';
    } else if (
      pathname === '/scenario-planning' &&
      history.location.state.previousPath === '/compare-scenarios'
    ) {
      targetPath = window.history.back();
    }
    history.push(targetPath);
  };

  const goBakcButton = () => {
    if (
      pathname.includes('/stage1') ||
      pathname.includes('/stageDetail') ||
      pathname.includes('/stage1-draft-form') ||
      pathname.includes('/draft-form')
    ) {
      handleBackButtonClick();
      dispatch(setBackButtonClicked(true));
    } else if (pathname.includes('/measurementfield')) {
      handleMFBack();
    } else if (
      pathname.includes('/organization/edit-organization') ||
      pathname.includes('/organization/add-organization') ||
      pathname.includes('/partner/edit-partner') ||
      pathname.includes('/partner/add-partner')
    ) {
      handleOrgBack();
    } else {
      handleBack();
    }
  };

  return (
    <>
      {(pathname === '/rate-detail' ||
        pathname === '/add-goal' ||
        pathname.includes('/add-goal') ||
        pathname.includes('/ranking-detail') ||
        pathname === '/add-idea' ||
        pathname.includes('/edit-idea') ||
        pathname.includes('/add-scenario') ||
        pathname === '/compare-scenarios' ||
        pathname.includes('/scenario-detail') ||
        pathname.includes('/edit-active-user') ||
        pathname.includes('/edit-invited-user') ||
        pathname == '/add-user' ||
        pathname.includes('/stage1') ||
        pathname.includes('/stageDetail') ||
        pathname.includes('/stage1-draft-form') ||
        pathname.includes('/draft-form') ||
        pathname.includes('/add-user') ||
        pathname.includes('/department-details') ||
        pathname.includes('/measurementfield/scale') ||
        pathname.includes('/measurementfield/numeric') ||
        pathname.includes('/organization/add-organization') ||
        pathname.includes('/partner/add-partner') ||
        pathname.includes('/organization/edit-organization') ||
        pathname.includes('/partner/edit-partner') ||
        pathname.includes('/organization/detail') ||
        pathname.includes('/partner/detail') ||
        (pathname === '/scenario-planning' &&
          history.location.state.previousPath === '/compare-scenarios') ||
        pathname === '/integration/integration-detail') && (
        <>
          <Typography.Title level={5} className="mb-0">
            <ArrowLeftOutlined
              className="mr-5 header-back-icon"
              onClick={goBakcButton}
            />
          </Typography.Title>
          <HeaderBreadcrumb state={history} />
        </>
      )}
      <LeavePageModal
        isConfirmationVisible={isConfirmationVisible}
        setIsConfirmationVisible={setIsConfirmationVisible}
        getStageNameElement={getStageNameElement}
        updatedFormElement={updatedFormElement}
      />
      <LeaveMFModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </>
  );
};

export default BackComponent;

