import { Button, Col, Row, Skeleton, Typography } from 'antd';
import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getCurrentOrganization,
  getCurrnetUserData,
} from 'store/slices/loginSlice';
import { RESTGetAuthorization } from 'utils/RESTApi';
import CompanyDetailForm from './CompanyDetailForm';

const CompanyDetails = () => {
  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);

  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;
  const [loading, setLoading] = useState(false);
  const [orgDetails, setOrgDetails] = useState();
  const [editForm, setEditForm] = useState(false);

  const fetchOrgDetailsByID = async () => {
    try {
      setLoading(true);
      let api = `/organization/get?organizationID=${orgId}`;
      let response = await RESTGetAuthorization(api);
      setOrgDetails(response?.body);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    fetchOrgDetailsByID();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {editForm ? (
        <CompanyDetailForm
          setEditForm={setEditForm}
          fetchOrgDetailsByID={fetchOrgDetailsByID}
          orgDetails={orgDetails}
        />
      ) : (
        <>
          <Row justify="space-between">
            <Typography.Title level={4} className="fw-500">
              Company Details
            </Typography.Title>
            <Button type="primary" onClick={() => setEditForm(true)}>
              Edit
            </Button>
          </Row>
          {loading ? (
            <Skeleton
              paragraph={{
                rows: 2,
              }}
            />
          ) : (
            <>
              <Row>
                <Typography.Title level={3} className="fw-500">
                  {orgDetails?.organization?.name}
                </Typography.Title>
              </Row>
              <Row>
                <Col span={20}>
                  <Row>
                    <Col span={12}>
                      <Row>
                        <Typography.Text className="fw-500">
                          Website
                        </Typography.Text>
                      </Row>
                      <Typography.Text>
                        <a href={orgDetails?.organization?.website}>
                          {orgDetails?.organization?.website}
                        </a>
                      </Typography.Text>
                    </Col>
                    <Col span={12}>
                      <Row>
                        <Typography.Text className="fw-500">
                          Contact number
                        </Typography.Text>
                      </Row>
                      <Typography.Text>
                        {orgDetails?.organization?.contact}
                      </Typography.Text>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="mt-20">
                <Typography.Text className="fw-500">
                  Address information
                </Typography.Text>
              </Row>
              <Row>
                <Typography.Text>
                  {orgDetails?.organization?.address?.street}
                </Typography.Text>
              </Row>
              <Row>
                <Typography.Text>
                  {orgDetails?.organization?.address?.city}
                </Typography.Text>
                <Typography.Text className="ml-5">
                  {orgDetails?.organization?.address?.zip}
                </Typography.Text>
              </Row>
              <Row>
                <Typography.Text>
                  {orgDetails?.organization?.address?.state}
                </Typography.Text>
              </Row>
              <Row>
                <Typography.Text>
                  {orgDetails?.organization?.address?.country}
                </Typography.Text>
              </Row>
            </>
          )}
        </>
      )}
    </>
  );
};

export default memo(CompanyDetails);

