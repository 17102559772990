import {
  Col,
  Grid,
  Image,
  message,
  Row,
  Table,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import { graphqlOperation, API } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import './dashboard.less';
import { onCreateScoreCustom } from '../../graphql/customSubscriptions';
import { InfoCircleOutlined } from '@ant-design/icons';
import {
  getCurrentOrganization,
  getCurrnetUserData,
  isAdmin,
  isLeader,
  isSuperAdmin,
} from '../../store/slices/loginSlice';
import { ECHOIMAGES } from '../../Assets/Images';

import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { getGoalCount } from './DashboardFunctions';
import {
  priorityBatchesAndUsersByUserIdCustom,
  searchPriorityBatchesCustom,
} from '../../utils/Actions';
import Impact from 'Pages/Profile/Impact';
import TableSkeleton from 'Common/TableSkeleton';
import CounterComponent from 'Pages/Profile/ImpactNumbers';
import { RESTGet, RESTGetAuthorization } from 'utils/RESTApi';
import {
  fetchOrganizationStages,
  getComparisonCount,
  getGammasCount,
  getRatingsCount,
} from 'utils/CommonFunctions';
import { levelColors } from 'utils/Constants';
import { getDefaultBatchID } from 'store/slices/compareSlice';
import moment from 'moment';

export default function Dashboard() {
  const [dashboardData, setDashboardData] = useState([]);
  const [dashboardColumns, setDashboardColumns] = useState([]);
  const [projectCount, setProjectCount] = useState('');
  const [opportunityCount, setOpportunityCount] = useState('');
  const [gammaCount, setGammaCount] = useState('');
  const [comparisonCompleted, setComparisonCompletedCount] = useState('');
  const [ratingsGiven, setRatingGiven] = useState('');
  const [goalCount, setGoalCount] = useState();
  const [loading, setLoading] = useState(true);
  const [priorityCount, setPriorityGoalCount] = useState();
  const [allStages, setAllStages] = useState([]);

  const { upArrow, downArrow, minusArrow } = ECHOIMAGES.HomeIcons;
  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;
  const userId = currnetOrg?.adminID || loginUsrOrg?.id;
  const isLeaderGroup = useSelector(isLeader);
  const isAdminGroup = useSelector(isAdmin);
  const isSuperAdminGroup = useSelector(isSuperAdmin);
  const history = useHistory();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const priorityDefaultBatchID =
    currnetOrg?.defaultPrioirtybatch?.id ||
    loginUsrOrg?.Organization?.organizationDefaultPrioirtybatchId;

  const columns = [
    {
      title: 'Rank',
      dataIndex: 'rank',
      key: 'rank',
      width: '5%',
      align: 'center',
      className: '',
      render: (text, row) => {
        return {
          props: {},
          children: (
            <span
              className={
                row.outOfThreshold
                  ? 'table-font fw-400 outofthresholdcolor'
                  : 'table-font fw-400'
              }
            >
              {row.fixedRank > 0
                ? row.fixedRank
                : row.isRank === false
                ? '-'
                : text || 0}
            </span>
          ),
        };
      },
      sorter: (a, b) => a.rank - b.rank,
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: '35%',
      sorter: (a, b) => a.title.localeCompare(b.title),
      render: (text, row) => {
        return {
          props: {},
          children: (
            <span
              className={
                row.outOfThreshold
                  ? 'table-font fw-400 outofthresholdcolor'
                  : 'table-font fw-400'
              }
            >
              {text}
            </span>
          ),
        };
      },
    },
    {
      title: (
        <div
          style={{
            display: 'flex',
            gap: '5px',
            alignItem: 'center',
          }}
        >
          <span>Stage</span>
          <Tooltip
            title="Ideas -> Opportunity -> Project. As ideas gain traction with more positive votes
          from team members, ideas can get promoted up to opportunity and could be selected as a project."
          >
            <InfoCircleOutlined />
          </Tooltip>
        </div>
      ),
      dataIndex: 'level',
      key: 'level',
      className: 'my-third-step',
      width: '10%',
      sorter: (a, b) => a.level.level - b.level.level,

      render: (text, row) => {
        return {
          props: {},
          children: (
            <Tag
              className="tag-font"
              key={text.id}
              color={levelColors[text.level]}
            >
              {text?.name?.toUpperCase()}
            </Tag>
          ),
        };
      },
    },
    {
      title: 'Department',
      dataIndex: 'departments',
      key: 'departments',
      render: (text, row) => {
        const departmentNames = row?.departments?.map((item) => item?.name);
        return {
          children: (
            <Typography.Text
              style={
                true
                  ? {
                      width: 200,
                    }
                  : undefined
              }
              ellipsis={
                true
                  ? {
                      tooltip: departmentNames?.join(', '),
                    }
                  : false
              }
            >
              {departmentNames?.join(', ')}
            </Typography.Text>
          ),
        };
      },
    },
    {
      title: 'Created on',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text, row) => {
        return {
          children: <span>{moment(text)?.format('l')}</span>,
        };
      },
    },
    {
      title: (
        <div
          style={{
            display: 'flex',
            gap: '5px',
            alignItem: 'center',
          }}
        >
          <span>Change</span>
          <Tooltip
            title="Has the rank changed recently? For example, 
          if an idea gets positive votes and moves from rank #10 to #4 in the rankings, the change will be +6."
          >
            <InfoCircleOutlined />
          </Tooltip>
        </div>
      ),
      dataIndex: 'change',
      key: 'change',
      className: 'my-forth-step',
      width: '10%',
      align: 'center',
      sorter: true,
      render: (_, row) => {
        return {
          props: {
            className:
              row.fixedRank > 0 && (isAdminGroup || isSuperAdminGroup)
                ? 'editedRank'
                : '',
          },
          children:
            row?.previousRank['Recent'] - row.rank > 0 ? (
              <div>
                {row.isRank === false ? (
                  <>
                    <span
                      className={
                        row.outOfThreshold
                          ? 'table-font fw-700 outofthresholdcolor'
                          : 'table-font fw-700'
                      }
                    >
                      -
                    </span>
                  </>
                ) : (
                  <>
                    {row.fixedRank > 0 ? (
                      row.rank - row.fixedRank === 0 ? (
                        <>
                          <Image src={minusArrow} preview={false} />
                          <span
                            className={
                              row.outOfThreshold
                                ? 'table-font fw-700 outofthresholdcolor'
                                : 'table-font fw-700'
                            }
                          >
                            {' '}
                            {row.rank - row.fixedRank}
                          </span>
                        </>
                      ) : (
                        <>
                          <Image src={upArrow} preview={false} />

                          <span
                            className={
                              row.outOfThreshold
                                ? 'table-font fw-700 outofthresholdcolor'
                                : 'table-font fw-700'
                            }
                          >
                            {' '}
                            +{row?.previousRank['Recent'] - row.rank}
                          </span>
                        </>
                      )
                    ) : (
                      <>
                        <Image src={upArrow} preview={false} />
                        <span
                          className={
                            row.outOfThreshold
                              ? 'table-font fw-700 outofthresholdcolor'
                              : 'table-font fw-700'
                          }
                        >
                          {' '}
                          +{row?.previousRank['Recent'] - row.rank}
                        </span>
                      </>
                    )}
                  </>
                )}
              </div>
            ) : (
              <div>
                {row.isRank === false ? (
                  <>
                    <span
                      className={
                        row.outOfThreshold
                          ? 'table-font fw-700 outofthresholdcolor'
                          : 'table-font fw-700'
                      }
                    >
                      -
                    </span>
                  </>
                ) : row.fixedRank !== null ? (
                  row.fixedRank > 0 || row?.previousRank['Recent'] > 0 ? (
                    row.rank - row.fixedRank === 0 ? (
                      <>
                        <Image src={minusArrow} preview={false} />
                        <span
                          className={
                            row.outOfThreshold
                              ? 'table-font fw-700 outofthresholdcolor'
                              : 'table-font fw-700'
                          }
                        >
                          {' '}
                          {row.rank - row.fixedRank}
                        </span>
                      </>
                    ) : row?.previousRank['Recent'] ? (
                      <>
                        {row?.previousRank['Recent'] - row.rank === 0 ? (
                          <div>
                            <Image src={minusArrow} preview={false} />
                            <span
                              className={
                                row.outOfThreshold
                                  ? 'table-font fw-700 outofthresholdcolor'
                                  : 'table-font fw-700'
                              }
                            >
                              {' '}
                              0
                            </span>{' '}
                          </div>
                        ) : (
                          <>
                            <Image src={downArrow} preview={false} />
                            <span
                              className={
                                row.outOfThreshold
                                  ? 'table-font fw-700 outofthresholdcolor'
                                  : 'table-font fw-700'
                              }
                            >
                              {' '}
                              {row?.previousRank['Recent'] - row.rank}
                            </span>
                          </>
                        )}
                      </>
                    ) : (
                      <div>
                        <img src={minusArrow} alt="" />
                        <span
                          className={
                            row.outOfThreshold
                              ? 'table-font fw-700 outofthresholdcolor'
                              : 'table-font fw-700'
                          }
                        >
                          {' '}
                          0
                        </span>{' '}
                      </div>
                    )
                  ) : (
                    <>
                      <div>
                        <span
                          className={
                            row.outOfThreshold
                              ? 'table-font fw-700 outofthresholdcolor'
                              : 'table-font fw-700'
                          }
                        >
                          {' '}
                          -
                        </span>{' '}
                      </div>
                    </>
                  )
                ) : row?.previousRank['Recent'] ? (
                  <>
                    {' '}
                    {row?.previousRank['Recent'] - row.rank === 0 ? (
                      <div>
                        <img src={minusArrow} alt="" />
                        <span
                          className={
                            row.outOfThreshold
                              ? 'table-font fw-700 outofthresholdcolor'
                              : 'table-font fw-700'
                          }
                        >
                          {' '}
                          0
                        </span>{' '}
                      </div>
                    ) : (
                      <>
                        <Image src={downArrow} preview={false} />
                        <span
                          className={
                            row.outOfThreshold
                              ? 'table-font fw-700 outofthresholdcolor'
                              : 'table-font fw-700'
                          }
                        >
                          {' '}
                          {row?.previousRank['Recent'] - row.rank}
                        </span>
                      </>
                    )}
                  </>
                ) : (
                  '-'
                )}
              </div>
            ),
        };
      },
    },
  ];

  const getStageName = async () => {
    try {
      const sortedStages = await fetchOrganizationStages(orgId);
      setAllStages(sortedStages);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchGammasCount = async () => {
    const aggregates = [{ field: 'levelID', type: 'terms', name: ' ' }];
    const { submittedIdea, opportunityCount, projectCount } =
      await getGammasCount(userId, allStages, null, null, aggregates);
    setGammaCount(submittedIdea);
    setOpportunityCount(opportunityCount);
    setProjectCount(projectCount);
  };

  const fetchComparisonCount = async () => {
    const { comparisonCount } = await getComparisonCount(userId);
    setComparisonCompletedCount(comparisonCount);
  };

  const fetchRatingsCount = async () => {
    const { ratingsCount } = await getRatingsCount(userId);
    setRatingGiven(ratingsCount);
  };
  const fetchDefaultBatchID = useSelector(getDefaultBatchID);
  const fetchGammas = async () => {
    if ((fetchDefaultBatchID || priorityDefaultBatchID) && orgId) {
      let batchID = fetchDefaultBatchID
        ? fetchDefaultBatchID
        : priorityDefaultBatchID;
      let rankingApi = `/rankings?id=${orgId}&priorityBatchID=${batchID}&userID=${userId}&page=1`;
      await RESTGetAuthorization(rankingApi)
        .then((res) => {
          const temp = [];
          res?.body?.items.map((item, index) => {
            temp.push({
              ...item?.Gamma,
              rank:
                item?.Gamma?.fixedRank > 0 ? item?.Gamma?.fixedRank : index + 1,
              isRank: item.isRanked,
            });
            return item;
          });
          setDashboardColumns([...columns]);
          setDashboardData(temp);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  const filteredData = dashboardData.filter(
    (row) => row?.previousRank['Recent'] !== 0
  );

  const getUsersGoalCount = async () => {
    try {
      const res = await getGoalCount({ ownerId: userId });
      setGoalCount(res?.items?.length);
    } catch (err) {
      console.log(err);
    }
  };

  const getPriorityBatchCount = async () => {
    try {
      let filter = {
        owners: { match: userId },
      };
      let res = await searchPriorityBatchesCustom(filter);

      setPriorityGoalCount(res?.total);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (userId) {
      getUsersGoalCount();
      getPriorityBatchCount();
      fetchComparisonCount();
      fetchRatingsCount();
      fetchGammasCount();
    }
    // eslint-disable-next-line
  }, [userId, allStages]);

  useEffect(() => {
    // subscribe for account Update
    const filter = {
      organizationID: orgId,
    };
    const Accountsubscriber = API.graphql(
      graphqlOperation(onCreateScoreCustom, filter)
    ).subscribe({
      next: (d) => {
        // fetchGammas();
      },
      error: (e) => {
        console.log(e);
      },
    });
    fetchGammas();
    return () => {
      Accountsubscriber.unsubscribe();
    };
    // eslint-disable-next-line
  }, [orgId]);

  useEffect(() => {
    if (orgId !== undefined) {
      getStageName();
    }
    // eslint-disable-next-line
  }, [orgId]);

  return (
    <>
      {/* {(isLeaderGroup || isAdminGroup || currnetOrg?.id) && (
        <Row className="background-white m-0 mb-30" gutter={[10, 20]}>
          <Col span={12} md={12} sm={24} xs={24}>
            <div className="box-wrapper counting cursor-pointer">
              <Row
                className="box"
                onClick={() =>
                  history.push({ pathname: '/goal', state: { myGoals: true } })
                }
              >
                <Typography.Title level={1} className="mb-0">
                  <CounterComponent initialValue={goalCount} />
                </Typography.Title>
                <Typography.Title level={3} className="mb-0 pt-0 mt-0 fs-20">
                  My Goals
                </Typography.Title>
              </Row>
            </div>
          </Col>

          <Col span={12} md={12} sm={24} xs={24}>
            <div className="box-wrapper counting cursor-pointer">
              <Row
                className="box"
                onClick={() =>
                  history.push({
                    pathname: '/scenarios',
                    state: { myPriorities: true },
                  })
                }
              >
                <Typography.Title level={1} className="mb-0">
                  <CounterComponent initialValue={priorityCount} />
                </Typography.Title>
                <Typography.Title level={3} className="mb-0 pt-0 mt-0 fs-20">
                  My Scenarios
                </Typography.Title>
              </Row>
            </div>
          </Col>
        </Row>
      )} */}
      <Impact
        opportunityCount={opportunityCount}
        projectCount={projectCount}
        gammaCount={gammaCount}
        comparisonCompleted={comparisonCompleted}
        ratingsGiven={ratingsGiven}
      />
      <div className="background-white mt-30 p-20">
        <Row justify="space-between" align="middle">
          <Typography.Title
            level={3}
            className="mb-0 title-fs20 sub-header-font"
          >
            Top Rankings
          </Typography.Title>
          <Link to="/rankings" className="fw-bold fs-16 view-all">
            View all
          </Link>
        </Row>
        {loading ? (
          <TableSkeleton />
        ) : (
          <Table
            className="dashboard-table my-second-step"
            dataSource={filteredData.slice(0, 10)}
            columns={dashboardColumns}
            pagination={false}
            scroll={{ x: 1000 }}
            size={screens.xxl ? 'middle' : 'small'}
          />
        )}
      </div>
    </>
  );
}
