import { Col, Row, Form, Input, Button, message, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import './goal.less';
import { useSelector } from 'react-redux';
import {
  getCurrentOrganization,
  getCurrnetUserData,
} from '../../store/slices/loginSlice';
import { useHistory, useParams } from 'react-router-dom';
import { API } from 'aws-amplify';
import {
  createObjectiveCustom,
  updateObjectiveCustom,
} from '../../graphql/customMutation';
import {
  createObjectivesAndDepartmentsCustom,
  deleteObjectivesAndDepartmentsCustom,
  getObjectiveCustom,
} from 'utils/Actions';
import { fetchOrgDepartments } from 'utils/CommonFunctions';
import { selectAllDepartment } from 'utils/Constants';

export default function AddGoal() {
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;
  const [loading, setLoading] = useState(false);
  const [ownerID, setOwnerID] = useState('');
  const [listAllDepartments, setListAllDepartments] = useState([]);
  const [backupListAllDepartments, setBackupListAllDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState([]);
  const [objectiveDetails, setObjectiveDetails] = useState([]);
  const { id } = useParams();
  const history = useHistory();

  const getObjectiveById = async () => {
    try {
      let res = await getObjectiveCustom({ id: id });
      form.setFieldsValue({
        name: res?.name,
        description: res?.description,
        Department: res?.Departments?.items?.map((el) =>
          el?.departmentID === selectAllDepartment ? 'All' : el?.departmentID
        ),
      });
      let findAll = res?.Departments?.items?.filter(
        (el) => el?.departmentID === selectAllDepartment
      );
      if (findAll?.length) {
        setSelectedDepartment(selectAllDepartment);
      }
      setObjectiveDetails(res);
      setOwnerID(res?.ownerId);
    } catch (err) {
      console.log(err);
    }
  };
  const listOrgDepartments = async () => {
    const departments = await fetchOrgDepartments(orgId);
    setListAllDepartments(departments);
    setBackupListAllDepartments(departments);
  };
  const handleChange = (e) => {
    let temp = [];
    if (e.includes('All')) {
      form.setFieldsValue({
        Department: ['All'],
      });
      setSelectedDepartment(selectAllDepartment);
    } else {
      backupListAllDepartments.forEach((item) => {
        if (e.includes(item?.id)) {
          temp.push(item);
        }
      });
      setSelectedDepartment(temp);
    }
  };
  // Add goal function
  const addNewGoal = async (e) => {
    setLoading(true);
    let trimmedName = e.name.trim();
    let trimmedDescription = e.description.trim();
    if (trimmedName === '' || trimmedDescription === '') {
      message.error('Goal title and description cannot be empty');
    } else {
      try {
        const inputData = {
          ownerId:
            ownerID !== ''
              ? ownerID
              : currnetOrg
              ? currnetOrg?.adminID
              : currnetOrg?.adminID === null
              ? ''
              : loginUsrOrg?.id,
          id: id,
          organizationObjectivesId: orgId,
          name: e.name,
          description: e.description,
        };
        let res = await API.graphql({
          query: inputData.id ? updateObjectiveCustom : createObjectiveCustom,
          variables: { input: inputData },
        });
        if (
          objectiveDetails?.length === 0 ||
          objectiveDetails?.Department?.items?.length === 0
        ) {
          if (selectedDepartment === selectAllDepartment) {
            const input = {
              departmentID: selectedDepartment,
              objectiveID: res?.data?.createObjective?.id || id,
              ownerID:
                ownerID !== ''
                  ? ownerID
                  : currnetOrg
                  ? currnetOrg?.adminID
                  : currnetOrg?.adminID === null
                  ? ''
                  : loginUsrOrg?.id,
            };
            await createObjectivesAndDepartmentsCustom(input);
          } else {
            await Promise.all(
              selectedDepartment?.map(async (el) => {
                const input = {
                  departmentID: el?.id,
                  objectiveID: res?.data?.createObjective?.id || id,
                  ownerID:
                    ownerID !== ''
                      ? ownerID
                      : currnetOrg
                      ? currnetOrg?.adminID
                      : currnetOrg?.adminID === null
                      ? ''
                      : loginUsrOrg?.id,
                };
                await createObjectivesAndDepartmentsCustom(input);
              })
            );
          }
        } else {
          const removeDepartments = objectiveDetails?.Departments?.items.filter(
            (el) => !e.Department.includes(el.departmentID)
          );
          const currentDepartments =
            objectiveDetails?.Departments?.items.filter((el) =>
              e.Department.includes(el.departmentID)
            );
          const newDepartments = [];
          e?.Department.forEach((el) => {
            const data = currentDepartments?.filter(
              (vl) => el === vl.departmentID
            );
            if (data?.length === 0) {
              newDepartments.push(el);
            }
          });
          if (removeDepartments.length > 0) {
            await Promise.all(
              removeDepartments.map(async (item) => {
                const departmentInput = {
                  id: item.id,
                };
                const departments = await deleteObjectivesAndDepartmentsCustom(
                  departmentInput
                );
                return departments;
              })
            );
          }

          if (selectedDepartment === selectAllDepartment) {
            const input = {
              departmentID: selectedDepartment,
              objectiveID: res?.data?.createObjective?.id || id,
              ownerID:
                ownerID !== ''
                  ? ownerID
                  : currnetOrg
                  ? currnetOrg?.adminID
                  : currnetOrg?.adminID === null
                  ? ''
                  : loginUsrOrg?.id,
            };
            await createObjectivesAndDepartmentsCustom(input);
          } else {
            await Promise.all(
              newDepartments?.map(async (el) => {
                const input = {
                  departmentID: el,
                  objectiveID: res?.data?.createObjective?.id || id,
                  ownerID:
                    ownerID !== ''
                      ? ownerID
                      : currnetOrg
                      ? currnetOrg?.adminID
                      : currnetOrg?.adminID === null
                      ? ''
                      : loginUsrOrg?.id,
                };
                await createObjectivesAndDepartmentsCustom(input);
              })
            );
          }
        }
        setLoading(false);
        message.success(
          `Goal ${inputData.id ? 'updated' : 'added'} successfully`
        );
        history.push('/goal');
      } catch (error) {
        setLoading(false);
        message.error(error.message);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (id) {
      getObjectiveById();
    }
    listOrgDepartments();
    // eslint-disable-next-line
  }, []);

  const detailTooltip = (
    <>
      Goals should be clear, concise and easily understood by the entire
      organization.
      <br /> Use details to call out key metrics or success factors for more
      context{' '}
      <a
        href="https://stralign.com/help-add-goal"
        target="_blank"
        rel="noopener noreferrer"
      >
        Click here for a video overview.
      </a>
    </>
  );

  return (
    <div className="add-idea plr-0 pt-0">
      <Row className="background-white p-20">
        <Col span={12} lg={12} md={24} sm={24} xs={24}>
          <Form
            layout="vertical"
            form={form}
            onFinish={addNewGoal}
            requiredMark={false}
          >
            <Form.Item
              label={<span className="fs-14">Goal title</span>}
              name="name"
              className="form-label "
              rules={[
                {
                  required: true,
                  message: 'Please Enter Goal Title!',
                },
              ]}
            >
              <Input
                placeholder="Enter goal title"
                className="h-40 input-font goal-add-title"
              />
            </Form.Item>
            <Form.Item
              label={<span className="fs-14">Goal description</span>}
              name="description"
              className="form-label"
              rules={[
                {
                  required: true,
                  message: 'Please Enter Description!',
                },
              ]}
            >
              <TextArea
                placeholder="Enter Goal description"
                autoSize={{
                  minRows: 3,
                  maxRows: 5,
                }}
                className="input-font goal-add-description"
              />
            </Form.Item>
            <Form.Item
              label={<span className="fs-14">Department</span>}
              name="Department"
              className="form-label"
              rules={[
                {
                  required: true,
                  message: 'Please Enter Department!',
                },
              ]}
            >
              <Select
                className="fs-14 dropdown-style goal-add-department"
                placeholder="Select department"
                mode="multiple"
                onChange={handleChange}
                value={selectedDepartment}
                filterOption={false}
                style={{
                  width: '100%',
                }}
                maxTagCount="responsive"
                id="goal-add-department"
              >
                <Option
                  value="All"
                  key="all-option"
                  className="fs-14"
                  id="goal-add-all"
                >
                  All
                </Option>
                {listAllDepartments?.map((item, index) => (
                  <Option
                    value={item?.id}
                    key={item?.id}
                    className="fs-14 goal-add-department-option"
                    id={`goal-add-department-option${index}`}
                  >
                    {item?.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="blue-filled-btn goal-add-btn"
                size="middle"
                loading={loading}
              >
                {id ? 'Edit Goal' : 'Add Goal'}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
}
