import { Button, Modal, Row, Typography } from 'antd';
import { ECHOIMAGES } from 'Assets/Images';
import React, { memo, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getAlreadyStartedTour,
  setAlreadyStartedTour,
  setMainTour,
} from 'store/slices/loginSlice';

const StartTourModal = ({ setRun, setOpenModal, openModal }) => {
  const { tourIcon } = ECHOIMAGES.HomeIcons;
  const getStartTourModalTrue = useSelector(getAlreadyStartedTour);

  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      setOpenModal(true);
    }, 2000);
    // eslint-disable-next-line
  }, []);
  return (
    <Modal
      footer={null}
      className="tour-modal"
      open={openModal}
      centered
      closable={false}
    >
      <Row justify="center">
        <img src={tourIcon} alt="" />
      </Row>
      <Row justify="center">
        <Typography.Title level={4} className="tour-title">
          Welcome to <span className="title-color">STRALIGN</span>
        </Typography.Title>
        <Typography.Text className="tour-text">
          StrAlign is a revolutionary strategic decision makingsoftware, helping
          organizations bridge <br /> the gap between strategic goals, idea
          evaluation, and project prioritization.
        </Typography.Text>
      </Row>
      <Row justify="center" className="mt-10 mb-20">
        <Button
          type="primary"
          className="tour-btn"
          onClick={() => {
            dispatch(setAlreadyStartedTour(true));
            dispatch(setMainTour(true));
            setRun(true);
            setOpenModal(false);
          }}
          size="middle"
        >
          Start Tour
        </Button>
      </Row>
    </Modal>
  );
};

export default memo(StartTourModal);

