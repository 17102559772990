import { Badge, Button, Col, Row, Tabs } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentOrganization, isAdmin } from '../../store/slices/loginSlice';
import ActiveUsers from './ActiveUsers';
import DisabledUsers from './DisabledUsers';
import InvitedUsers from './InvitedUsers';
import './superadmin.less';
import { useHistory } from 'react-router-dom';

export default function UserManagement() {
  const [userCount, setUserCount] = useState({
    activeUserLength: 0,
    disabledUserLength: 0,
    invitesUserLength: 0,
  });

  const [initalFlag, setInitalFlag] = useState(false);
  const [activeTab, setActiveTab] = useState('1');
  const currnetOrg = useSelector(getCurrentOrganization);
  const isAdminGroup = useSelector(isAdmin);
  const history = useHistory();
  const inviteTab = history?.location?.state?.inviteTab || false;
  const disabledTab = history?.location?.state?.disabledTab || false;
  const editInvite = history?.location?.state?.editInvite || false;

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  let items = [
    {
      key: '1',
      label: (
        <span>
          Active Users{' '}
          <Badge
            count={userCount.activeUserLength}
            showZero
            overflowCount={999}
          />
        </span>
      ),
      children: (
        <ActiveUsers
          setUserCount={setUserCount}
          userCount={userCount}
          setInitalFlag={setInitalFlag}
          initalFlag={initalFlag}
          activeTab={activeTab}
        />
      ),
      hide: false,
    },
    {
      key: '2',
      label: (
        <span>
          Disabled users{' '}
          <Badge
            count={userCount.disabledUserLength}
            showZero
            overflowCount={999}
          />
        </span>
      ),
      children: (
        <DisabledUsers
          setUserCount={setUserCount}
          userCount={userCount}
          setInitalFlag={setInitalFlag}
          initalFlag={initalFlag}
          activeTab={activeTab}
        />
      ),
      hide: false,
    },
    {
      key: '3',
      label: (
        <span>
          Invited users{' '}
          <Badge
            count={userCount.invitesUserLength}
            showZero
            overflowCount={999}
          />
        </span>
      ),
      children: (
        <InvitedUsers
          setUserCount={setUserCount}
          userCount={userCount}
          activeTab={activeTab}
        />
      ),
      hide: currnetOrg || isAdminGroup ? false : true,
    },
  ].filter((el) => !el.hide);

  return (
    <>
      <div className="plr-0 pt-0">
        <Row justify="center user-management">
          <Col span={24}>
            <Tabs
              defaultActiveKey={
                inviteTab ? '3' : disabledTab ? '2' : editInvite ? '3' : '1'
              }
              items={items}
              activeKey={activeTab}
              onChange={handleTabChange}
            />
          </Col>
        </Row>
      </div>
    </>
  );
}
