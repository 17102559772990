import { API, Auth } from 'aws-amplify';

export const RESTPost = async (path, bodyData) => {
  try {
    return API.post('StrAlignRest', path, {
      body: bodyData,
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    });
  } catch (e) {
    return { error: 'Something went wrong' };
  }
};

export const RESTPostNoAuthorization = async (path, bodyData) => {
  try {
    return API.post('StrAlignRest', path, {
      body: bodyData,
    });
  } catch (e) {
    return { error: 'Something went wrong' };
  }
};

const RESTDelete = async (path, bodyData) => {
  try {
    return API.del('StrAlignRest', path, {
      body: bodyData,
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    });
  } catch (e) {
    return { error: 'Something went wrong' };
  }
};

export const RESTGet = async (path) => {
  return API.get('StrAlignRest', path, {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
  });
};

export const RESTExportPost = (data) => {
  const path = '/export/rankings';
  return RESTPost(path, data);
};

export const RESTSignup = (data) => {
  const path = '/signup';
  return RESTPost(path, data);
};

export const RESTCompare = (data) => {
  const path = '/compare';
  return RESTPost(path, data);
};

export const RESTScenarioUpdate = async (data) => {
  return API.post('StrAlignRest', '/scenarios/update', {
    body: data,
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
  });
};

export const RESTScenarioCopy = async (data) => {
  return API.post('StrAlignRest', '/scenarios/copy', {
    body: data,
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
  });
};

export const RESTGetAuthorization = async (path) => {
  return API.get('StrAlignRest', path, {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
  });
};

export const RESTGetRanking = async (path) => {
  return API.get('StrAlignRest', path, {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
  });
};

export const RESTGetRankingFilter = async (path, filter = {}, chart = {}) => {
  const filterString = encodeURIComponent(JSON.stringify(filter));
  const updatedFilter = encodeURIComponent(JSON.stringify(chart));
  const fullPath = `${path}&filter=${filterString}&chart=${updatedFilter}`;

  return API.get('StrAlignRest', fullPath, {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
  });
};

export const RESTRanking = (data) => {
  const path = '/rankings';
  return RESTPost(path, data);
};

export const RESTGetCount = (data) => {
  const path = '/scenarios/rankings';
  return RESTPost(path, data);
};

export const RESTGetUsers = (data) => {
  const path = '/user/list';
  return RESTPost(path, data);
};

export const RESTGetManagement = async (data) => {
  return API.post('StrAlignRest', '/user/management', {
    body: data,
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
  });
};

export const RESTGetMeasurementFieldUsedDetail = async (path) => {
  return API.get('StrAlignRest', path, {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
  });
};
export const RESTNotification = (batchType, userId, id, senderID) => {
  const path = '/notify/users';
  const bodyData = {
    type: batchType,
    user_id: userId,
    object_id: id,
    sender_id: senderID,
  };
  return RESTPost(path, bodyData);
};
export const RESTAddOrg = (data) => {
  const path = '/user/signup';
  return RESTPost(path, data);
};
export const RESTListOrg = (data) => {
  const path = '/organization/management';
  return RESTPost(path, data);
};
export const RESTAddUser = (data) => {
  const path = '/user/invite';
  return RESTPost(path, data);
};

export const RESTExport = (data) => {
  const path = '/export/rankings';
  return RESTPost(path, data);
};

export const RESTReminder = (data) => {
  const path = '/user/remind';
  return RESTPost(path, data);
};

export const RESTInvite = (data) => {
  const path = '/user/bulk-invite';
  return RESTPost(path, data);
};

export const RESTUpdateRanking = (data) => {
  const path = '/update-rankings';
  return RESTPost(path, data);
};
export const RESTUpdateRankingAggregates = (data) => {
  const path = '/update-rankings';
  return RESTPost(path, data);
};

export const RESTUpdateRankingHistory = (data) => {
  const path = '/update-rank-history';
  return RESTPost(path, data);
};

export const RESTImportGamma = (data) => {
  const path = '/priorities/bulk-import';
  return RESTPost(path, data);
};

export const RESTDeleteGoal = (data) => {
  const path = '/delete/goal';
  return RESTDelete(path, data);
};

export const RESTDeleteOrg = (data) => {
  const path = '/delete/org';
  return RESTDelete(path, data);
};

export const RESTDeleteUser = (data) => {
  const path = '/delete/user';
  return RESTDelete(path, data);
};
export const RESTChangeOrg = (data) => {
  const path = '/move/user';
  return RESTDelete(path, data);
};

export const RESTDeleteGamma = (data) => {
  const path = '/delete/priority';
  return RESTDelete(path, data);
};

export const RESTGetRankings = (data) => {
  const path = '/rankings';
  return RESTGet(path, data);
};
export const RESTUpdateRole = (data) => {
  const path = '/user/update';
  return RESTPost(path, data);
};

export const RESTDeleteDepartment = (data) => {
  const path = '/delete/department';
  return RESTDelete(path, data);
};
export const RESTMergeDepartment = (data) => {
  const path = '/move/department';
  return RESTDelete(path, data);
};
export const RESTResetPassword = (data) => {
  const path = '/user/reset-password';
  return RESTPost(path, data);
};
export const RESTResetUserPassword = (data) => {
  const path = '/reset-password';
  return RESTPostNoAuthorization(path, data);
};
export const RESTGetRankingDetails = (data) => {
  const path = '/rankings/get';
  return RESTGet(path, data);
};

export const RESTSubmitform = async (data) => {
  return API.post('StrAlignRest', '/rankings/update', {
    body: data,
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
  });
};

export const RESTAddIdea = async (data) => {
  return API.post('StrAlignRest', '/rankings/add', {
    body: data,
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
  });
};

export const RESTDemoOrg = (data) => {
  const path = '/organization/demo';
  return RESTPost(path, data);
};

export const RESTCreatApiKey = (data) => {
  const path = '/apikey/create';
  return RESTPost(path, data);
};

export const RESTDeleteApiKey = (data) => {
  const path = '/apikey/delete';
  return RESTPost(path, data);
};
export const RESTAddNewOrg = (data) => {
  const path = '/organization/update';
  return RESTPost(path, data);
};

export const RESTNotificationGamma = (gammaID, userId) => {
  const path = '/notify/gammapromote';
  const bodyData = {
    gamma_id: gammaID,
    user_id: userId,
  };
  return RESTPost(path, bodyData);
};

export const RESTAddNewPartner = (data) => {
  const path = '/organization/partner/update';
  return RESTPost(path, data);
};

export const RESTListPartners = (data) => {
  const path = '/organization/partner/management';
  return RESTPost(path, data);
};
