/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { Avatar, Badge, List } from 'antd';
import { UserOutlined, NotificationOutlined } from '@ant-design/icons';
import { ECHOIMAGES } from '../Assets/Images';
import './notification.less';
import { gammaType } from '../utils/Constants';
import { useHistory } from 'react-router-dom';
import { updateNotificationCustom } from '../utils/Actions';
import moment from 'moment';

export default function AllNotification(props) {
  const {
    IdeaNotificationIcon,
    OpportunityNotificationIcon,
    notificationIcon,
  } = ECHOIMAGES.notificationIcons;
  const [showNotificationDetail, setShowNotificationDetail] = useState([]);
  const [importantNotification, setShowImportantNotification] = useState([]);

  const history = useHistory();

  const listAllNotifications = () => {
    const res = props.generalNotification;
    const important = props.importantNotification;

    setShowNotificationDetail(res);
    setShowImportantNotification(important);
  };

  const updateNotificationItem = async (id) => {
    try {
      const inputData = {
        id: id,
        isRead: true,
      };
      await updateNotificationCustom(inputData);
      await props
        .listAllNotifications()
        .then((res) => {
          setShowNotificationDetail(
            res?.data?.NotificationByUser?.items.filter(
              (item) => item.type === 'GENERAL'
            )
          );
          setShowImportantNotification(
            res?.data?.NotificationByUser?.items.filter(
              (item) => item.type === 'IMPORTANT'
            )
          );
          props.setOpenNotification(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    listAllNotifications();
    // eslint-disable-next-line
  }, [props]);

  return (
    <>
      {props.tab === '1' ? (
        <List
          itemLayout="horizontal"
          className="notification-list"
          dataSource={showNotificationDetail}
          renderItem={(item) => (
            <>
              <List.Item
                className={
                  !item.isRead ? 'unread cursor-pointer' : 'cursor-pointer'
                }
                onClick={() => {
                  updateNotificationItem(item?.id);
                  JSON.parse(item?.metadata)?.redirect_data?.type ===
                  gammaType?.gamma
                    ? history.push({
                        pathname: `/ranking-detail/${
                          JSON.parse(item?.metadata)?.redirect_data?.id
                        }`,
                        state: item,
                      })
                    : JSON.parse(item?.metadata)?.redirect_data?.type ===
                      'defaultBatch'
                    ? history.push({
                        pathname: `/scenario-detail/${
                          JSON.parse(item?.metadata)?.redirect_data?.id
                        }`,
                      })
                    : history.push({
                        pathname: `/add-scenario/${
                          JSON.parse(item?.metadata)?.redirect_data?.id
                        }`,
                        state: item,
                      });
                }}
              >
                {/** ********* NOTE: show this badge only when the notification is unread and add class "unread" */}
                {!item.isRead ? (
                  <Badge dot={true} className="dot"></Badge>
                ) : (
                  <></>
                )}
                <List.Item.Meta
                  avatar={
                    JSON.parse(item?.metadata)?.redirect_data?.type ===
                      gammaType?.priority ||
                    (JSON.parse(item?.metadata)?.redirect_data?.type ===
                      gammaType?.gamma &&
                      JSON.parse(item?.metadata)?.icon) ? (
                      <Avatar icon={<UserOutlined />} size="large" />
                    ) : (
                      <img src={notificationIcon} alt="" />
                    )
                  }
                  title={
                    <span
                      className={
                        !item.isRead
                          ? 'notification-text-msg input-font'
                          : 'input-font'
                      }
                      onClick={() => {
                        JSON.parse(item?.metadata)?.redirect_data?.type ===
                        gammaType?.gamma
                          ? history.push({
                              pathname: `/ranking-detail/${
                                JSON.parse(item?.metadata)?.redirect_data?.id
                              }`,
                              state: item,
                            })
                          : JSON.parse(item?.metadata)?.redirect_data?.type ===
                            'defaultBatch'
                          ? history.push({
                              pathname: `/scenario-detail/${
                                JSON.parse(item?.metadata)?.redirect_data?.id
                              }`,
                            })
                          : history.push({
                              pathname: `/add-scenario/${
                                JSON.parse(item?.metadata)?.redirect_data?.id
                              }`,
                              state: item,
                            });
                      }}
                    >
                      {item.Message}
                    </span>
                  }
                  description={
                    <span className="input-font">
                      {moment(item?.createdAt).startOf('minutes').fromNow()}
                    </span>
                  }
                />
              </List.Item>
            </>
          )}
        />
      ) : (
        <List
          itemLayout="horizontal"
          className="notification-list"
          dataSource={importantNotification}
          renderItem={(item) => (
            <>
              <List.Item
                className={
                  !item.isRead
                    ? 'unread cursor-pointer input-font'
                    : 'cursor-pointer input-font'
                }
                onClick={() => {
                  updateNotificationItem(item?.id);
                  JSON.parse(item?.metadata)?.redirect_data?.type ===
                  gammaType?.gamma
                    ? history.push({
                        pathname: `/ranking-detail/${
                          JSON.parse(item?.metadata)?.redirect_data?.id
                        }`,
                        state: item,
                      })
                    : history.push({
                        pathname: `/add-scenario/${
                          JSON.parse(item?.metadata)?.redirect_data?.id
                        }`,
                        state: item,
                      });
                }}
              >
                {/** ********* NOTE: show this badge only when the notification is unread and add class "unread" */}
                {!item.isRead ? (
                  <Badge dot={true} className="dot"></Badge>
                ) : (
                  <></>
                )}
                <List.Item.Meta
                  avatar={
                    JSON.parse(item?.metadata)?.redirect_data?.type ===
                      gammaType?.priority ||
                    (JSON.parse(item?.metadata)?.redirect_data?.type ===
                      gammaType?.gamma &&
                      JSON.parse(item?.metadata)?.icon) ? (
                      <Avatar icon={<UserOutlined />} size="large" />
                    ) : (
                      <img src={notificationIcon} alt="" />
                    )
                  }
                  title={
                    <span
                      className={
                        !item.isRead
                          ? 'notification-text-msg input-font'
                          : 'input-font'
                      }
                      onClick={() => {
                        JSON.parse(item?.metadata)?.redirect_data?.type ===
                        gammaType?.gamma
                          ? history.push({
                              pathname: `/ranking-detail/${
                                JSON.parse(item?.metadata)?.redirect_data?.id
                              }`,
                              state: item,
                            })
                          : history.push({
                              pathname: `/add-scenario/${
                                JSON.parse(item?.metadata)?.redirect_data?.id
                              }`,
                              state: item,
                            });
                      }}
                    >
                      {item.Message}
                    </span>
                  }
                  description={
                    <span className="input-font">
                      {moment(item?.createdAt).startOf('minutes').fromNow()}
                    </span>
                  }
                />
              </List.Item>
            </>
          )}
        />
      )}
    </>
  );
}
