import React, { memo } from 'react';
import { Modal, Row, Typography } from 'antd';
import KudosAnimation from '../../Compare/Components/KudosAnimation';
import { ECHOIMAGES } from 'Assets/Images';
const SubmitFirstIdeaModal = ({ openAddIdeaModal }) => {
  const { RocketIcon } = ECHOIMAGES.ideaIcons;
  return (
    <Modal
      open={openAddIdeaModal}
      centered={true}
      footer={null}
      closable={false}
      className="kudos-modal firstIdea"
    >
      <div className="kudos-animation">
        <KudosAnimation />
      </div>
      <Row justify="center" align="middle">
        <img src={RocketIcon} alt="" />
      </Row>
      <Row justify="center">
        <Typography.Title level={3} className="mt-10">
          Congratulations on Submitting Your First Idea!
        </Typography.Title>
      </Row>
      <Row justify="center">
        <Typography.Text className="text-center">
          With this small step, you've started a journey that can lead <br /> to
          incredible discoveries and positive change.
        </Typography.Text>
      </Row>
    </Modal>
  );
};

export default memo(SubmitFirstIdeaModal);


