import { Col, Row, Form, Input, Button, Select, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { updateRole } from './SuperAdminFun';
import { useSelector } from 'react-redux';
import {
  getCurrentOrganization,
  getCurrnetUserData,
  isAdmin,
} from '../../store/slices/loginSlice';
import { UserRole } from 'utils/Constants';
import { fetchOrgDepartments, fetchRoles } from 'utils/CommonFunctions';
import { RESTUpdateRole } from 'utils/RESTApi';
import { FetchUser } from 'Pages/Idea/IdeaFunction';

export default function EditInvitedUser() {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [allRoles, setAllRoles] = useState([]);
  const [selectdRole, setSelectedRole] = useState('');
  const [listAllDepartments, setListAllDepartments] = useState([]);
  const [selectdDepartment, setSelectedDepartment] = useState('');
  const [oldRoleName, setOldRoleName] = useState('');
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [loading, setLoading] = useState(false);
  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;
  const isAdminGroup = useSelector(isAdmin);
  const history = useHistory();

  const getUserProfile = async (e) => {
    const userDetails = await FetchUser({ id: id });
    form.setFieldsValue({
      username: userDetails?.firstName,
      LastName: userDetails?.lastName,
      Email: userDetails?.email,
      role: userDetails?.role,
      department: userDetails?.Department?.id,
    });
    setOldRoleName(userDetails?.role);
    setUsersList(userDetails);
  };

  const handleGetRoles = async () => {
    const rolesArray = await fetchRoles(isAdminGroup, currnetOrg);
    setAllRoles(rolesArray);
  };

  const listOrgDepartments = async () => {
    const departments = await fetchOrgDepartments(orgId);
    setListAllDepartments(departments);
  };

  const EditInvitedUser = async (e) => {
    setLoading(true);
    try {
      let roleUpdateData = {};
      let events = [];
      let inputData = {
        id: id,
        firstName: e.username,
        lastName: e.LastName,
        email: e.email,
        role: e.role,
        departmentID: e.department,
      };
      if (firstName || lastName) {
        events.push('name-change');
        roleUpdateData['user_id'] = id;
        roleUpdateData['first_name'] = e.username;
        roleUpdateData['last_name'] = e.LastName;
      }
      if (selectdRole) {
        events.push('role-change');
        roleUpdateData['user_id'] = id;
        roleUpdateData['old_role'] = oldRoleName;
        roleUpdateData['new_role'] = selectdRole;
      }
      if (firstName || lastName || selectdRole) {
        roleUpdateData['events'] = events;
        await RESTUpdateRole(roleUpdateData);
      }
      await updateRole(inputData);
      setLoading(false);
      message.success('User Details Edit Successfully');
      history.push({
        pathname: '/user-management',
        state: { editInvite: true },
      });
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    getUserProfile();
    handleGetRoles();
    listOrgDepartments();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <div className="plr-0 pt-0 active-user">
        <div className="background-white mb-30 p-20">
          <Form
            layout="vertical"
            requiredMark={false}
            form={form}
            onFinish={(e) => EditInvitedUser(e)}
            className="opportunity-detail-edit"
          >
            <Row gutter={[20, 0]}>
              <Col span={12} md={12} sm={24} xs={24}>
                <Form.Item
                  label={<span className="p">First Name</span>}
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter first name',
                    },
                  ]}
                >
                  <Input
                    placeholder="Please enter First Name"
                    className="input-font"
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col span={12} md={12} sm={24} xs={24}>
                <Form.Item
                  label={<span className="p">Last Name</span>}
                  name="LastName"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter last name',
                    },
                  ]}
                >
                  <Input
                    placeholder="Please enter Last Name"
                    className="input-font"
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col span={12} md={12} sm={24} xs={24}>
                <Form.Item
                  label={<span className="p">Email Id</span>}
                  name="Email"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter email id',
                    },
                  ]}
                >
                  <Input
                    placeholder="Please enter Email Id"
                    disabled
                    className="input-font"
                  />
                </Form.Item>
              </Col>
              <Col span={12} md={12} sm={24} xs={24}>
                <Form.Item
                  name="role"
                  label={<span className="p">Role</span>}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    value={selectdRole}
                    onChange={(e) => setSelectedRole(e)}
                    allowClear
                    className="input-font"
                  >
                    {allRoles?.map((item, index) => {
                      const roleName =
                        item?.name === UserRole.sme
                          ? UserRole?.teamMember
                          : item?.name;
                      return (
                        <Select.Option
                          key={index}
                          value={item?.name}
                          className="input-font"
                        >
                          {roleName}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12} md={12} sm={24} xs={24}>
                <Form.Item
                  name="department"
                  label={<span className="p">Department</span>}
                >
                  <Select
                    placeholder="Select Department"
                    value={selectdDepartment}
                    onChange={(e) => setSelectedDepartment(e)}
                    allowClear
                    className="input-font"
                  >
                    {listAllDepartments?.map((item, index) => {
                      return (
                        <Select.Option
                          key={index}
                          value={item?.id}
                          className="input-font"
                        >
                          {item?.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Button
                  type="primary"
                  className="blue-filled-btn"
                  htmlType="submit"
                  size="middle"
                  loading={loading}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </>
  );
}

