import { message } from 'antd';
import { ECHOIMAGES } from 'Assets/Images';
import CustomDeleteModal from 'Common/CustomDeleteModal';
import React, { useState } from 'react';
import { RESTDeleteDepartment } from 'utils/RESTApi';

const DeleteDepartment = ({
  openaModal,
  departmentData,
  setOpenModal,
  listDepartments,
  orgId,
}) => {
  const { DeleteIcon } = ECHOIMAGES.superAdminIcons;

  const [loading, setLoading] = useState(false);

  const handleDeleteDepartment = async () => {
    try {
      setLoading(true);
      let input = {
        dep_id: departmentData.id,
        org_id: orgId,
      };
      await RESTDeleteDepartment(input);
      await listDepartments();
      setLoading(false);
      setOpenModal(false);
      message.success('Department deleted successfully');
    } catch (err) {
      setLoading(false);
      console.log(err);
      throw err;
    }
  };

  const handleCancelModal = () => {
    setOpenModal(false);
  };
  return (
    <CustomDeleteModal
      open={openaModal}
      onOk={handleDeleteDepartment}
      onCancel={handleCancelModal}
      confirmLoading={loading}
      title="Do you really want to Delete this Department?"
    >
      <img src={DeleteIcon} alt="" />
    </CustomDeleteModal>
  );
};

export default DeleteDepartment;

