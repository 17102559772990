import React, { memo, useEffect, useState } from 'react';
import { Alert, Button, Col, Row, Tag, Typography } from 'antd';
import { WarningOutlined, EditOutlined } from '@ant-design/icons';
import '../stage.less';
import { ECHOIMAGES } from 'Assets/Images';
import DeleteStage from '../Components/DeleteStage';
import { useHistory } from 'react-router-dom';
import EditStageModal from '../Components/EditStageModal';
import { gammasByLevelIDCustom, listAllStages } from '../Services/StageActions';
import { useSelector, useDispatch } from 'react-redux';
import {
  getCurrentOrganization,
  getCurrnetUserData,
} from 'store/slices/loginSlice';
import {
  setFormElementUpdate,
  setStageLevel,
  setStageName,
} from 'store/slices/StageSlice';
import TableSkeleton from 'Common/TableSkeleton';
import moment from 'moment';
import EditStageName from '../Components/EditStageName';
import { capitalizeFirstLetter } from 'utils/Constants';

const StageList = () => {
  const { deleteIcon, eyeIcon, disabledDeleteIcon, editIcon } =
    ECHOIMAGES.admin;
  const history = useHistory();
  const dispatch = useDispatch();

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [IseditModalOpen, setIsEditModalOpen] = useState(false);
  const [listStage, setListStage] = useState([]);
  const [stageID, setStageID] = useState();
  const [stageItems, setStageItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentStage, setCurrentStage] = useState({});
  const [IseditNameModalOpen, setIsEditNameModalOpen] = useState(false);

  const [hoveredItems, setHoveredItems] = useState([]);
  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;

  const handleMouseEnter = (index) => {
    const updatedHoveredItems = [...hoveredItems];
    updatedHoveredItems[index] = true;
    setHoveredItems(updatedHoveredItems);
  };

  const handleMouseLeave = (index) => {
    const updatedHoveredItems = [...hoveredItems];
    updatedHoveredItems[index] = false;
    setHoveredItems(updatedHoveredItems);
  };

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const handleEditModalOpen = () => {
    setIsEditModalOpen(true);
  };

  //Function to list all the stages by organization
  const listAllStagesByOrg = async () => {
    setLoading(true);
    try {
      let response = await listAllStages({ organizationID: orgId });
      let sortedLevel = response.items.sort((a, b) => a.level - b.level);
      dispatch(setStageLevel(response?.items));
      setListStage(sortedLevel);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  //Function to check if stage has any items or not while deleting stage
  const getItemsByStage = async (id) => {
    try {
      let inputData = {
        levelID: id,
        limit: 1,
      };
      let res = await gammasByLevelIDCustom(inputData);
      const modifiedResponse = res?.items?.map((item) => ({
        ...item,
        stageID: id,
      }));
      setStageItems(modifiedResponse);
      if (res?.items?.length === 0) {
        handleOpenDeleteModal();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleEditNameModalOpen = (item) => {
    setCurrentStage(item);
    setIsEditNameModalOpen(true);
  };

  useEffect(() => {
    listAllStagesByOrg();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="background-white p-20 w-100">
      <Row className="stage-list">
        <Col span={24}>
          <Typography.Title level={4} className="mb-20">
            Stage Configuration
          </Typography.Title>
          {loading ? (
            <TableSkeleton />
          ) : (
            listStage?.map((item, index) => {
              const isDeleteItem =
                item?.active && item.id === stageItems?.[0]?.stageID;
              return (
                <Row align="middle">
                  <div className="mb-20 mr-10">
                    <Typography.Text
                      level={3}
                      className={
                        item?.active
                          ? 'stage-title fs-14'
                          : 'stage-title fs-14 draf-stage'
                      }
                    >
                      Stage {item.level}
                    </Typography.Text>
                  </div>
                  {/*add error block class while deleting stage */}
                  <div
                    className={
                      item.active && !isDeleteItem
                        ? 'stage-block'
                        : item.active && isDeleteItem
                        ? 'error-block mb-20'
                        : 'stage-block draft-stage-border'
                    }
                    key={index}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={() => handleMouseLeave(index)}
                  >
                    <div>
                      <span className="mr-10 capitalize-text fs-16 fw-500">
                        {capitalizeFirstLetter(item?.name)}
                      </span>
                      {hoveredItems[index] && (
                        <EditOutlined
                          className={
                            !item?.active ? 'stage-edit mr-10' : 'stage-edit'
                          }
                          onClick={() => {
                            handleEditNameModalOpen(item);
                          }}
                        />
                      )}
                      {!item?.active && <Tag color="gold">Draft</Tag>}

                      <div className="mt-10">
                        <span className="block-text">Last updated</span>
                        <span className="fw-500">
                          {' '}
                          :{' '}
                          {moment(item?.updatedAt).format('YYYY-MM-DD hh:mm A')}
                        </span>
                      </div>
                      {item?.draft?.items?.length > 0 && (
                        <Button
                          className="p-0 draft-ui"
                          type="text"
                          onClick={() => {
                            history.push({
                              pathname:
                                item?.level === 1
                                  ? `stage1-draft-form/${item.id}`
                                  : `draft-form/${item.id}`,
                              state: {
                                prevForm: listStage[index],
                              },
                            });
                            dispatch(setFormElementUpdate(null));
                            dispatch(setStageName(null));
                          }}
                          size="middle"
                        >
                          <span className="draft-text">Saved Draft</span>
                        </Button>
                      )}
                      {isDeleteItem && (
                        <Row className="mt-5">
                          <Alert
                            message="Only Empty stages can be deleted. Please move items before deleting the stage."
                            type="error"
                            icon={<WarningOutlined />}
                            showIcon
                            className="alert-msg"
                          />
                        </Row>
                      )}
                    </div>

                    <div className="d-flex gap-20">
                      <Button
                        className="editicon"
                        type="text"
                        onClick={() => {
                          history.push({
                            pathname:
                              item?.level === 1
                                ? `stage1/${item.id}`
                                : `stageDetail/${item.id}`,
                            state: {
                              prevForm: listStage[index],
                            },
                          });
                          dispatch(setFormElementUpdate(null));
                          dispatch(setStageName(null));
                        }}
                      >
                        <img src={editIcon} alt="" />
                      </Button>

                      <Button
                        className="preview-btn"
                        onClick={() => {
                          let parseFormLayout = JSON.parse(item?.form?.layout);
                          dispatch(setFormElementUpdate(parseFormLayout));
                          dispatch(setStageName(item));
                          history.push({
                            pathname:
                              item?.level === 1
                                ? '/stage1preview'
                                : '/stagepreview/',
                            state: {
                              source: 'list',
                            },
                          });
                        }}
                      >
                        <img src={eyeIcon} alt="" />
                      </Button>
                      {item.level === 1 || item.level === 2 ? (
                        <img src={disabledDeleteIcon} alt="" />
                      ) : (
                        <Button
                          type="text"
                          className="p-0 delete-stage"
                          onClick={() => {
                            getItemsByStage(item.id), setStageID(item?.id);
                          }}
                        >
                          <img src={deleteIcon} alt="" />
                        </Button>
                      )}
                    </div>
                  </div>
                </Row>
              );
            })
          )}

          <Row className="mt-20">
            <Col span={1} offset={1}>
              <Button
                className="add-stage"
                onClick={() => handleEditModalOpen()}
                disabled={loading}
                size="middle"
              >
                Add New Stage
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <DeleteStage
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        stageID={stageID}
        listAllStagesByOrg={listAllStagesByOrg}
      />

      <EditStageModal
        IseditModalOpen={IseditModalOpen}
        setIsEditModalOpen={setIsEditModalOpen}
        listStage={listStage}
        listAllStagesByOrg={listAllStagesByOrg}
      />
      <EditStageName
        IseditNameModalOpen={IseditNameModalOpen}
        setIsEditNameModalOpen={setIsEditNameModalOpen}
        currentStage={currentStage}
        listAllStagesByOrg={listAllStagesByOrg}
      />
    </div>
  );
};

export default memo(StageList);

