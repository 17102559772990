import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Typography,
  message,
  Alert,
  Tooltip,
} from 'antd';
import React, { memo, useEffect, useState } from 'react';
import {
  ArrowLeftOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  HolderOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import {
  getformElementUpdate,
  setCurrentFormElementID,
  setFormChanged,
  setFormElementUpdate,
} from 'store/slices/StageSlice';
import { useDispatch, useSelector } from 'react-redux';
import PreviousStageConfirmation from './PreviousStageConfirmation';
import RequireFiledModal from './RequireFiledModal';
import { ECHOIMAGES } from 'Assets/Images';

const MultiLineInput = ({
  selectedElement,
  setFormBody,
  setSelectedElement,
  stageLevel,
  copyofPublishedForm,
  getStageNameElement,
}) => {
  const [showInput, setShowInput] = useState(false);
  const [value, setValue] = useState(1);
  const [selectedValue, setSelectedValue] = useState();
  const [selectedLimit, setSelectedLimit] = useState(false);
  const [previousFiledConfirmationModal, setPreviousFiledConfirmationModal] =
    useState(false);
  const [requiredFieldModalOpen, setRequiredFieldModalOpen] = useState(false);
  const [savedData, setSavedData] = useState({});
  const updatedFormElement = useSelector(getformElementUpdate);
  const [requiredField, setRequiredField] = useState(selectedElement.required);
  const [checkedItems, setCheckedItems] = useState({
    employees: false,
    leaders: false,
    admin: false,
    department: false,
  });
  const [formFieldsChanged, setFormFieldsChanged] = useState(false);
  const [selectedFieldTypes, setSelecteFieldTypes] = useState(
    selectedElement?.inputType === 'multiLine' ? 'multi' : 'single'
  );
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const {
    threeColorsIcon,
    fourColorsIcon,
    fiveColorsIcon,
    shapeIcon,
    analytics,
    pieChartIcon,
    cloudIcon,
    circleSquareIcon,
    expressionIcon,
    arrowMinusIcon,
    rightCrossIcon,
    ratingsIcon,
    progressBarIcon,
    upDownIcon,
    fourArrowIcon,
    fiveArrowIcon,
    humanIcon,
    dollarIcon,
    heartIcon,
    threeArrowIcon,
    fourBlackArrowIcon,
  } = ECHOIMAGES.admin;

  const dispatch = useDispatch();
  const onChangeRadioButton = (e) => {
    setValue(e.target.value);
  };
  const [labelChanged, setLabelChanged] = useState(false);

  const onChange = (e) => {
    setRequiredField(e.target.checked);
  };

  const onChangeReq = (e) => {
    setShowInput(e.target.checked);
    setSelectedLimit(e.target.checked);
  };

  const handleChange = (value) => {
    setSelectedValue(value);
  };

  const handleRequiredModalOpen = (e) => {
    setSavedData(e);
    setRequiredFieldModalOpen(true);
  };

  //Function to save local changes
  const handleUpdateFormData = (e, changeType) => {
    const formData =
      previousFiledConfirmationModal || requiredFieldModalOpen ? savedData : e;
    const newState = updatedFormElement.map((obj) => {
      if (obj.id === selectedElement?.id) {
        if (
          selectedElement?.inputType === 'singleLine' ||
          selectedElement?.inputType === 'multiLine'
        ) {
          const inputType =
            selectedFieldTypes === 'single' ? 'singleLine' : 'multiLine';

          const updatedObj = {
            ...obj,
            ...formData,
            required: requiredField,
            inputType,
            changeType,
          };
          return updatedObj;
        }
        const updatedObj = {
          ...obj,
          ...formData,
          required: requiredField,
        };

        if (updatedObj.optionType === 'Custom') {
          const { datasetValue, ...rest } = updatedObj;
          return { ...rest, changeType };
        }
        if (updatedObj.optionType === 'Extract data from dataset') {
          const { custom_values, ...rest } = updatedObj;
          return { ...rest, changeType };
        }

        return { ...updatedObj, changeType };
      }
      return obj;
    });

    dispatch(setFormElementUpdate(newState));
    setFormBody(newState);
    setSelectedElement();
    dispatch(setCurrentFormElementID());
    message.success('Changes saved successfully');
  };

  //To set form values
  useEffect(() => {
    form.resetFields();
    if (selectedElement?.datasetValue) {
      let datasetValueArray = selectedElement?.datasetValue;
      setCheckedItems({
        employees: datasetValueArray?.includes('employees'),
        leaders: datasetValueArray?.includes('leaders'),
        admin: datasetValueArray?.includes('admin'),
        department: datasetValueArray?.includes('department'),
      });
    } else {
      setSelectedValue();
      setValue();
    }
    if (selectedElement?.optionType) {
      setSelectedValue(selectedElement?.optionType);
    } else {
      setSelectedValue();
      setValue();
    }
    if (selectedElement?.maxChars) {
      setSelectedLimit(true);
    } else {
      setSelectedLimit(false);
    }
    if (selectedElement?.required) {
      setRequiredField(true);
    } else {
      setRequiredField(false);
    }

    form.setFieldsValue(selectedElement);

    // eslint-disable-next-line
  }, [selectedElement]);

  //Function to handle checkbox values based on selected value
  const handleCheckboxChange = (itemName) => {
    const updatedCheckedItems = { ...checkedItems };
    updatedCheckedItems[itemName] = !updatedCheckedItems[itemName];
    if (itemName === 'employees' && updatedCheckedItems.employees) {
      Object.keys(updatedCheckedItems).forEach((key) => {
        if (key !== 'employees') {
          updatedCheckedItems[key] = false;
        }
      });
    } else if (
      (itemName === 'admin' || itemName === 'leaders') &&
      updatedCheckedItems.admin &&
      updatedCheckedItems.leaders
    ) {
      updatedCheckedItems.employees = false;
      updatedCheckedItems.department = false;
    } else if (itemName === 'department' && updatedCheckedItems.department) {
      Object.keys(updatedCheckedItems).forEach((key) => {
        if (key !== 'department') {
          updatedCheckedItems[key] = false;
        }
      });
    }
    setCheckedItems(updatedCheckedItems);
  };

  //Function to check if user has made any changes in form
  const handleFormChange = (changedValues) => {
    if ('label' in changedValues) {
      setLabelChanged(true);
    } else {
      dispatch(setFormChanged(true));
      setFormFieldsChanged(true);
    }
  };

  //Function to execute function based on the different condition
  const handleFormSubmit = (e) => {
    if (labelChanged && !formFieldsChanged) {
      handleUpdateFormData(e);
    } else if ((labelChanged && formFieldsChanged) || selectedFieldTypes) {
      if (
        JSON.parse(copyofPublishedForm).some(
          (id) => id?.id === selectedElement?.id
        )
      ) {
        handleRequiredModalOpen(e);
      } else {
        handleUpdateFormData(e);
      }
    }
  };

  return (
    <div className="edit-block">
      <Row
        align="middle"
        className="gap-10 cursor-pointer"
        onClick={() => setSelectedElement()}
      >
        <ArrowLeftOutlined />
        <Typography.Title level={4} className="mb-0 fw-500">
          Back
        </Typography.Title>
      </Row>
      <Divider className="edit-divider" />
      {(selectedElement?.inputType === 'singleLine' ||
        selectedElement?.inputType === 'multiLine') && (
        <div className="field-type">
          <Typography.Text className="fw-bold fs-16 mb-5">
            Change Field Type :{' '}
          </Typography.Text>
          <div>
            <Radio.Group
              onChange={(e) => setSelecteFieldTypes(e.target.value)}
              value={selectedFieldTypes}
            >
              <Radio value="single">Single-line text</Radio>
              <Radio value="multi">Multi-line text</Radio>
            </Radio.Group>
          </div>
        </div>
      )}
      <Typography.Title level={5} className="mt-5">
        {selectedElement?.inputType === 'singleLine' &&
        selectedFieldTypes === 'single'
          ? ' Single-line text'
          : selectedElement?.inputType === 'multiLine' &&
            selectedFieldTypes === 'single'
          ? ' Single-line text'
          : selectedElement?.inputType === 'multiLine' &&
            selectedFieldTypes === 'multi'
          ? ' Multi-line text'
          : selectedElement?.inputType === 'singleLine' &&
            selectedFieldTypes === 'multi'
          ? ' Multi-line text'
          : selectedElement?.inputType === 'dropdown'
          ? ' Dropdown'
          : selectedElement?.inputType === 'datepicker'
          ? 'Date'
          : selectedElement?.inputType === 'link'
          ? 'link'
          : selectedElement?.inputType === 'symbol'
          ? 'Symbol'
          : 'Upload'}
      </Typography.Title>
      <Row>
        <Col span={24} md={24}>
          <Form
            layout="vertical"
            form={form}
            onFinish={(e) => handleFormSubmit(e)}
            onValuesChange={handleFormChange}
          >
            <Form.Item name="required">
              <Checkbox
                onChange={onChange}
                value={requiredField}
                checked={requiredField}
                disabled={
                  selectedElement?.id ===
                    process.env.REACT_APP_DESCRIPTION_ID ||
                  selectedElement?.id === process.env.REACT_APP_TITLE_ID ||
                  selectedElement?.id === process.env.REACT_APP_DEPARTMENT_ID
                }
              >
                Make this field required
              </Checkbox>
            </Form.Item>

            {((selectedElement?.inputType === 'multiLine' &&
              selectedFieldTypes === 'multi') ||
              (selectedElement?.inputType === 'singleLine' &&
                selectedFieldTypes === 'multi')) && (
              <>
                <Form.Item>
                  <Checkbox onChange={onChangeReq} checked={selectedLimit}>
                    Max. characters limit
                  </Checkbox>
                </Form.Item>
                {showInput || selectedElement?.maxChars ? (
                  <Form.Item name="maxChars">
                    <Input />
                  </Form.Item>
                ) : null}
              </>
            )}
            {(selectedElement?.inputType === 'singleLine' ||
              selectedFieldTypes === 'single' ||
              selectedElement?.inputType === 'dropdown' ||
              selectedElement?.inputType === 'symbol' ||
              selectedElement?.inputType === 'multiLine' ||
              selectedElement?.inputType === 'datepicker' ||
              selectedElement?.inputType === 'link' ||
              selectedElement?.inputType === 'dragger') && (
              <Form.Item name="label" label="Name">
                <Input
                  disabled={
                    selectedElement?.id === process.env.REACT_APP_SPONSOR_ID ||
                    selectedElement?.id ===
                      process.env.REACT_APP_CONTRIBUTOR_ID ||
                    selectedElement?.id === process.env.REACT_APP_DEPARTMENT_ID
                  }
                />
              </Form.Item>
            )}
            {(selectedElement?.inputType !== 'dropdown' ||
              selectedElement?.inputType !== 'symbol') && (
              <>
                <Form.Item name="tooltip" label="Help text">
                  <TextArea />
                </Form.Item>
                <Form.Item name="placeholder" label="Placeholder">
                  <Input />
                </Form.Item>
              </>
            )}

            {selectedElement?.inputType === 'dropdown' && (
              <>
                <Form.Item name="dropdownValue">
                  <Radio.Group onChange={onChangeRadioButton}>
                    <Radio
                      value="Single"
                      disabled={
                        selectedElement?.id ===
                        process.env.REACT_APP_CONTRIBUTOR_ID
                      }
                    >
                      Single Select List
                    </Radio>
                    <Radio
                      value="Multiple"
                      disabled={
                        selectedElement?.id === process.env.REACT_APP_SPONSOR_ID
                      }
                    >
                      Multiple Select List
                    </Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item name="optionType" label="Dropdown value">
                  <Select
                    onChange={handleChange}
                    value={selectedValue}
                    disabled={
                      value === undefined && !selectedElement?.dropdownValue
                    }
                  >
                    <Select.Option
                      value="Custom"
                      label="Custom"
                      disabled={
                        selectedElement?.id ===
                          process.env.REACT_APP_SPONSOR_ID ||
                        selectedElement?.id ===
                          process.env.REACT_APP_CONTRIBUTOR_ID ||
                        selectedElement?.id ===
                          process.env.REACT_APP_DEPARTMENT_ID
                      }
                    >
                      Custom
                    </Select.Option>
                    <Select.Option
                      value="Extract data from dataset"
                      label="Extract data from dataset"
                    >
                      Extract data from dataset
                    </Select.Option>
                  </Select>
                </Form.Item>

                {selectedValue === 'Custom' ? (
                  <Form.List name="custom_values">
                    {(fields, { add, remove }) => (
                      <div className="custom-dropdown">
                        <div className="value-block">
                          <Typography.Title
                            level={4}
                            className="header-text fw-500"
                          >
                            Value
                          </Typography.Title>
                          <Divider className="dropdown-divider " />
                        </div>

                        {fields.map(({ key, name, ...restField }) => (
                          <Space
                            key={key}
                            style={{
                              display: 'flex',
                              marginBottom: 8,
                            }}
                            align="baseline"
                          >
                            <Row align="middle">
                              <Col span={24} className="dropdown-box">
                                <div className="drag-icon">
                                  <HolderOutlined />
                                </div>
                                <Form.Item
                                  {...restField}
                                  name={[name]}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Missing first name',
                                    },
                                  ]}
                                >
                                  <Input className="input-width" />
                                </Form.Item>

                                <MinusCircleOutlined
                                  onClick={() => remove(name)}
                                  className="minus-icon"
                                />
                              </Col>
                            </Row>
                          </Space>
                        ))}
                        <Form.Item>
                          <Button
                            onClick={() => add()}
                            type="text"
                            icon={<PlusOutlined className="option-btn" />}
                            className="option-btn"
                            size="middle"
                          >
                            Add an option
                          </Button>
                        </Form.Item>
                      </div>
                    )}
                  </Form.List>
                ) : (
                  selectedValue === 'Extract data from dataset' && (
                    <>
                      <Form.Item
                        name="datasetValue"
                        className="pb-0"
                        label={
                          <Typography.Text level={4}>
                            Select group of data as dropdown value
                          </Typography.Text>
                        }
                      >
                        <Checkbox.Group
                          style={{
                            width: '100%',
                          }}
                        >
                          <Row>
                            <Col span={24}>
                              <Checkbox
                                value="employees"
                                checked={checkedItems.employees}
                                onChange={() =>
                                  handleCheckboxChange('employees')
                                }
                                disabled={
                                  selectedElement?.id ===
                                    process.env.REACT_APP_DEPARTMENT_ID ||
                                  checkedItems.admin ||
                                  checkedItems.leaders ||
                                  checkedItems.department
                                }
                              >
                                List of Users
                              </Checkbox>
                            </Col>
                            <Col span={24}>
                              <Checkbox
                                value="leaders"
                                checked={checkedItems.leaders}
                                onChange={() => handleCheckboxChange('leaders')}
                                disabled={
                                  selectedElement?.id ===
                                    process.env.REACT_APP_DEPARTMENT_ID ||
                                  checkedItems.employees ||
                                  checkedItems.department
                                }
                              >
                                List of Leaders
                              </Checkbox>
                            </Col>
                            <Col span={24}>
                              <Checkbox
                                value="admin"
                                checked={checkedItems.admin}
                                onChange={() => handleCheckboxChange('admin')}
                                disabled={
                                  selectedElement?.id ===
                                    process.env.REACT_APP_DEPARTMENT_ID ||
                                  checkedItems.employees ||
                                  checkedItems.department
                                }
                              >
                                List of Admin
                              </Checkbox>
                            </Col>
                            <Col span={24}>
                              <Checkbox
                                value="department"
                                checked={checkedItems.department}
                                onChange={() =>
                                  handleCheckboxChange('department')
                                }
                                disabled={
                                  selectedElement?.id ===
                                    process.env.REACT_APP_SPONSOR_ID ||
                                  selectedElement?.id ===
                                    process.env.REACT_APP_CONTRIBUTOR_ID ||
                                  checkedItems.admin ||
                                  checkedItems.leaders ||
                                  checkedItems.employees
                                }
                              >
                                List of Department
                              </Checkbox>
                            </Col>
                          </Row>
                        </Checkbox.Group>
                      </Form.Item>
                    </>
                  )
                )}
              </>
            )}

            {selectedElement?.inputType === 'symbol' && (
              <>
                <Form.Item
                  label={
                    <span>
                      Symbol
                      <Tooltip
                        className="ml-5"
                        title="Symbols can be used to visually represent the status, priority, 
                        or type of information at a glance,
                       enhancing clarity and enabling quick identification and tracking of important data points"
                      >
                        <InfoCircleOutlined />
                      </Tooltip>
                    </span>
                  }
                  name="symbolType"
                >
                  <Select>
                    <Select.Option value="threeIcon">
                      <img
                        src={threeColorsIcon}
                        alt=""
                        className="symbol-height"
                      />
                    </Select.Option>
                    <Select.Option value="fourIcon">
                      <img
                        src={fourColorsIcon}
                        alt=""
                        className="symbol-height"
                      />
                    </Select.Option>
                    <Select.Option value="fiveIcon">
                      <img
                        src={fiveColorsIcon}
                        alt=""
                        className="symbol-height"
                      />
                    </Select.Option>
                    <Select.Option value="shapeIcon">
                      <img src={shapeIcon} alt="" className="symbol-height" />
                    </Select.Option>
                    <Select.Option value="analytics">
                      <img src={analytics} alt="" className="symbol-height" />
                    </Select.Option>
                    <Select.Option value="pieChartIcon">
                      <img
                        src={pieChartIcon}
                        alt=""
                        className="symbol-height"
                      />
                    </Select.Option>
                    <Select.Option value="cloudIcon">
                      <img src={cloudIcon} alt="" className="symbol-height" />
                    </Select.Option>
                    <Select.Option value="circleSquareIcon">
                      <img
                        src={circleSquareIcon}
                        alt=""
                        className="symbol-height"
                      />
                    </Select.Option>
                    <Select.Option value="expressionIcon">
                      <img
                        src={expressionIcon}
                        alt=""
                        className="symbol-height"
                      />
                    </Select.Option>
                    <Select.Option value="arrowMinusIcon">
                      <img
                        src={arrowMinusIcon}
                        alt=""
                        className="symbol-height"
                      />
                    </Select.Option>
                    <Select.Option value="rightCrossIcon">
                      <img
                        src={rightCrossIcon}
                        alt=""
                        className="symbol-height"
                      />
                    </Select.Option>
                    <Select.Option value="ratingsIcon">
                      <img src={ratingsIcon} alt="" className="symbol-height" />
                    </Select.Option>
                    <Select.Option value="progressBarIcon">
                      <img
                        src={progressBarIcon}
                        alt=""
                        className="symbol-height"
                      />
                    </Select.Option>
                    <Select.Option value="upDownIcon">
                      <img src={upDownIcon} alt="" className="symbol-height" />
                    </Select.Option>
                    <Select.Option value="fourArrowIcon">
                      <img
                        src={fourArrowIcon}
                        alt=""
                        className="symbol-height"
                      />
                    </Select.Option>
                    <Select.Option value="fiveArrowIcon">
                      <img
                        src={fiveArrowIcon}
                        alt=""
                        className="symbol-height"
                      />
                    </Select.Option>
                    <Select.Option value="humanIcon">
                      <img src={humanIcon} alt="" className="symbol-height" />
                    </Select.Option>
                    <Select.Option value="dollarIcon">
                      <img src={dollarIcon} alt="" className="symbol-height" />
                    </Select.Option>
                    <Select.Option value="heartIcon">
                      <img src={heartIcon} alt="" className="symbol-height" />
                    </Select.Option>
                    <Select.Option value="threeArrowIcon">
                      <img
                        src={threeArrowIcon}
                        alt=""
                        className="symbol-height"
                      />
                    </Select.Option>
                    <Select.Option value="fourBlackArrowIcon">
                      <img
                        src={fourBlackArrowIcon}
                        alt=""
                        className="symbol-height"
                      />
                    </Select.Option>
                  </Select>
                </Form.Item>
              </>
            )}
            <Button
              className="blue-filled-btn related-btn fw-bold mt-20"
              size="middle"
              htmlType="submit"
              type="primary"
            >
              Save
            </Button>
          </Form>
        </Col>
      </Row>

      <RequireFiledModal
        requiredFieldModalOpen={requiredFieldModalOpen}
        handleUpdateFormData={handleUpdateFormData}
        setRequiredFieldModalOpen={setRequiredFieldModalOpen}
        stageLevel={stageLevel}
        getStageNameElement={getStageNameElement}
      />
      <PreviousStageConfirmation
        previousFiledConfirmationModal={previousFiledConfirmationModal}
        setPreviousFiledConfirmationModal={setPreviousFiledConfirmationModal}
        handleUpdateFormData={handleUpdateFormData}
      />
    </div>
  );
};

export default memo(MultiLineInput);

