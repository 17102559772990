import { Button, Row } from 'antd';
import React from 'react';
import AllIdea from './AllIdea';
import { useHistory } from 'react-router';
import './idea.less';
import PriorityList from './PriorityList';

export default function AllPriorities() {
  const history = useHistory();

  return (
    <div className="idea  pt-0 plr-0">
      <Row className="header" align="middle">
        {/* <AllIdea /> */}
        <PriorityList />
      </Row>
    </div>
  );
}
