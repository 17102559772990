import React, { useEffect, useState } from 'react';
import {
  Form,
  Switch,
  Input,
  Row,
  Col,
  Button,
  message,
  Typography,
} from 'antd';
import { useSelector } from 'react-redux';
import {
  getCurrentOrganization,
  getCurrnetUserData,
} from 'store/slices/loginSlice';
import { updateUserSetting } from './SettingFunction';
import { getInviteandInactiveSettingCustom } from 'utils/Actions';

export default function NotificationSettings() {
  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;
  const [loading, setLoading] = useState(false);
  const [inviteSwitch, setInviteSwitch] = useState(false);
  const [inactiveSwitch, setInactiveSwitch] = useState(false);

  const [form] = Form.useForm();

  const onChange = (e) => {
    setInviteSwitch(e);
  };

  const inActiveSwitch = (e) => {
    setInactiveSwitch(e);
  };

  const inviteNotification = async (e) => {
    setLoading(true);
    try {
      let inputData = {
        id: orgId,
        invitationReminderConfig: JSON.stringify({
          inviteFlag: inviteSwitch,
          reminderFrequency: e.reminderFrequency,
          firstReminder: e.firstReminder,
        }),
        inactivityReminderConfig: JSON.stringify({
          inactiveFlag: inactiveSwitch,
          reminderFrequency: e.inactiveReminderFrequency,
          firstReminder: e.inactiveFirstReminder,
        }),
      };
      await updateUserSetting(inputData);
      message.success('Notification settings updated successfully');
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const getInviteSetting = async () => {
    try {
      let res = await getInviteandInactiveSettingCustom({ id: orgId });
      let inviteData = res?.data?.getOrganization?.invitationReminderConfig
        ? JSON.parse(res?.data?.getOrganization?.invitationReminderConfig)
        : null;
      let inactiveData = res?.data?.getOrganization?.inactivityReminderConfig
        ? JSON.parse(res?.data?.getOrganization?.inactivityReminderConfig)
        : null;
      if (inviteData) {
        form.setFieldsValue({
          inviteFlag: inviteData?.inviteFlag ? true : false,
          firstReminder: inviteData?.firstReminder,
          reminderFrequency: inviteData?.reminderFrequency,
        });
        setInviteSwitch(inviteData?.inviteFlag);
      }
      if (inactiveData) {
        form.setFieldsValue({
          inactiveFlag: inactiveData?.inactiveFlag,
          inactiveFirstReminder: inactiveData?.firstReminder,
          inactiveReminderFrequency: inactiveData?.reminderFrequency,
        });
        setInactiveSwitch(inactiveData?.inactiveFlag);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getInviteSetting();
    // eslint-disable-next-line
  }, []);
  const [isChanged, setIsChanged] = useState(false);

  return (
    <>
      <div className="notification-setting">
        <Form
          requiredMark={false}
          onFinish={(e) => inviteNotification(e)}
          form={form}
          onFieldsChange={() => setIsChanged(true)}
        >
          <Row>
            <Col span={20} md={20} sm={24} xs={24}>
              <Row>
                <Typography.Title level={4}>
                  Notification Settings
                </Typography.Title>
              </Row>
              <Form.Item name="inviteFlag" className="mt-20">
                <Switch
                  size="small"
                  valuePropName="checked"
                  checked={inviteSwitch}
                  onChange={(e) => {
                    onChange(e);
                    setIsChanged(true);
                  }}
                />
                <span>
                  {' '}
                  Turn on reminder email notifications for users who have been
                  invited to join but have not logged in / activated their
                  accounts yet
                </span>
              </Form.Item>
              <Form.Item
                label="Send the first reminder email after [X] days of not logging in :"
                name="firstReminder"
                rules={[
                  {
                    pattern: /^[0-9]+$/,
                    message: 'Please enter a valid number!',
                  },
                ]}
              >
                <Input size="small" suffix="Days" className="w-80px h-30" />
              </Form.Item>
              <Form.Item
                label="Keep sending reminders every [X] days until user logs in :"
                name="reminderFrequency"
                rules={[
                  {
                    pattern: /^[0-9]+$/,
                    message: 'Please enter a valid number!',
                  },
                ]}
              >
                <Input size="small" suffix="Days" className="w-80px h-30" />
              </Form.Item>

              <Form.Item valuePropName="checked" name="inactiveFlag">
                <Switch
                  size="small"
                  onChange={(e) => {
                    inActiveSwitch(e);
                    setIsChanged(true);
                  }}
                  checked={inactiveSwitch}
                />
                <span className="ml-10">
                  Turn on reminder email notifications for users who have not
                  logged in recently
                </span>
              </Form.Item>
              <Form.Item
                label="Send the first reminder email after [X] days of not logging in :"
                name="inactiveFirstReminder"
                rules={[
                  {
                    pattern: /^[0-9]+$/,
                    message: 'Please enter a valid number!',
                  },
                ]}
              >
                <Input size="small" suffix="Days" className="w-80px h-30" />
              </Form.Item>
              <Form.Item
                label="Keep sending reminders every [X] days until user logs in :"
                name="inactiveReminderFrequency"
                rules={[
                  {
                    pattern: /^[0-9]+$/,
                    message: 'Please enter a valid number!',
                  },
                ]}
              >
                <Input size="small" suffix="Days" className="w-80px h-30" />
              </Form.Item>
            </Col>
            <Col span={4} md={4} sm={24} xs={24}>
              <Row justify="end">
                <Button
                  htmlType="submit"
                  className="blue-filled-btn "
                  size="middle"
                  type="primary"
                  loading={loading}
                  disabled={!isChanged || loading}
                >
                  Save
                </Button>
              </Row>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
}

