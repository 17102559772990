import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Grid,
  Radio,
  Row,
  Switch,
  Typography,
} from 'antd';
import {
  getCurrentOrganization,
  getCurrnetUserData,
  isAdmin,
  isSuperAdmin,
} from 'store/slices/loginSlice';
import { fetchOrganizationStages } from 'utils/CommonFunctions';
import { getDefaultBatchID } from 'store/slices/compareSlice';
import { RESTGetRankingFilter } from 'utils/RESTApi';
import moment from 'moment';
import { dateTimeFormat } from 'utils/Constants';
import dayjs from 'dayjs';

const RankingFilter = ({
  open,
  setOpen,
  setDashboardData,
  userId,
  listAllDepartments,
  setDepartmentFilter,
  departmentFilter,
  setStgaestageFilterLevel,
  stageFilterLevel,
  setRankValue,
  rankValue,
  selectAllDepartment,
  setSelectAllDepartment,
  selectAllStage,
  setSelectAllStage,
  selectAllRanks,
  setSelectAllRanks,
  timeRange,
  setTimeRange,
  customRange,
  setCustomRange,
  setSubmittedByOwner,
  submittedByOwner,
  filterChange,
  setFilterChange,
  setRankingFilter,
  sortTableColumn,
  sortTableOrder,
  currentPage,
  setCurrentPage,
  setNextToken,
  selectedDates,
  setYearlyBackupRankData,
  setGraphData,
  selectedDays,
  selectedTopItems,
  searchText,
  showAll,
}) => {
  const isAdminGroup = useSelector(isAdmin);
  const isSuperAdminGroup = useSelector(isSuperAdmin);
  const [form] = Form.useForm();
  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;

  const [filterLoader, setFilterLoader] = useState(false);

  const { RangePicker } = DatePicker;
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const onClose = () => {
    setOpen(false);
  };
  const rankType = ['Assigned Ranks', 'Default Ranks'];

  let priorityDefaultBatchID =
    currnetOrg?.defaultPrioirtybatch?.id ||
    loginUsrOrg?.Organization?.organizationDefaultPrioirtybatchId;

  const fetchDefaultBatchID = useSelector(getDefaultBatchID);

  const defaultPriorityBatchID = fetchDefaultBatchID
    ? fetchDefaultBatchID
    : priorityDefaultBatchID;

  const getRankingsByDate = {
    today: [
      moment().startOf('day').format(dateTimeFormat),
      moment().format(dateTimeFormat),
    ],
    last7days: [
      moment().subtract(7, 'd').startOf('day').format(dateTimeFormat),
      moment().format(dateTimeFormat),
    ],
    lastWeek: [
      moment().subtract(1, 'weeks').startOf('week').format(dateTimeFormat),
      moment().subtract(1, 'weeks').endOf('week').format(dateTimeFormat),
    ],
    thisMonth: [
      moment().startOf('month').format(dateTimeFormat),
      moment().format(dateTimeFormat),
    ],
    lastMonth: [
      moment().subtract(1, 'months').startOf('month').format(dateTimeFormat),
      moment().subtract(1, 'months').endOf('month').format(dateTimeFormat),
    ],
    customRange: customRange
      ? [
          customRange[0].format(dateTimeFormat),
          customRange[1].format(dateTimeFormat),
        ]
      : [],
  };
  const exportRankingsFlag = (array, value) => {
    return array.includes(value);
  };
  const getRanks = () => {
    if (rankValue.length) {
      return {
        Default: exportRankingsFlag(rankValue, 'Default Ranks'),
        Assigned: exportRankingsFlag(rankValue, 'Assigned Ranks'),
      };
    }
    return {};
  };

  const getStage = () => {
    const selectedStages = stageFilterLevel;

    if (selectedStages.length) {
      const result = {};

      allStages.forEach((item) => {
        const id = item?.id;
        const isSelected = selectedStages.includes(id);
        result[id] = isSelected;
      });
      return result;
    }
    return {};
  };
  const filterApply = async (page) => {
    let tempPage = page || currentPage;
    setFilterLoader(true);
    const filters = {
      byMe: submittedByOwner ? userId : '',
      Ranks: getRanks(),
      Stage: getStage(),
      Department: departmentFilter,
      Created: getRankingsByDate[timeRange] ? getRankingsByDate[timeRange] : [],
    };

    let allData = [];
    let getStartDateFromLS = JSON.parse(
      localStorage.getItem('selectedDate')
    )?.[0]?.split('T')[0];
    let getEndDateFromLS = JSON.parse(
      localStorage.getItem('selectedDate')
    )?.[1]?.split('T')[0];
    const lastSixWeekStart = dayjs()
      .subtract(6, 'week')
      .startOf('week')
      .add(6, 'days')
      .format('YYYY-MM-DD');
    const lastSixWeekEnd = dayjs().format('YYYY-MM-DD');
    let chart = {
      Top: selectedTopItems ? selectedTopItems : '10',
      Calender: [
        getStartDateFromLS
          ? getStartDateFromLS
          : selectedDates?.[0]?.format('YYYY-MM-DD')
          ? selectedDates?.[0]?.format('YYYY-MM-DD')
          : lastSixWeekStart,
        getEndDateFromLS
          ? getEndDateFromLS
          : selectedDates?.[1]?.format('YYYY-MM-DD')
          ? selectedDates?.[1]?.format('YYYY-MM-DD')
          : lastSixWeekEnd,
      ],
      Interval: localStorage.getItem('days')
        ? localStorage.getItem('days') === '1 Day'
          ? 'day'
          : localStorage.getItem('days') === '1 Week'
          ? 'week'
          : localStorage.getItem('days') === '1 Month'
          ? 'month'
          : selectedDays === '1 Day'
          ? 'day'
          : selectedDays === '1 Week'
          ? 'week'
          : selectedDays === '1 Month'
          ? 'month'
          : 'week'
        : 'week',
    };

    // eslint-disable-next-line max-len
    let rankingApi = `/rankings?id=${orgId}&priorityBatchID=${defaultPriorityBatchID}&userID=${userId}&page=${
      selectedTopItems === -1 ? -1 : tempPage
    }&sort_direction=${sortTableOrder}&sort_field=${sortTableColumn}&show_all=${showAll}`;
    if (searchText !== undefined && searchText !== '') {
      rankingApi += `&search=${searchText}`;
    }
    try {
      const res = await RESTGetRankingFilter(rankingApi, filters, chart);
      const temp = res?.body?.items?.map((item, index) => ({
        ...item?.Gamma,
        chart: {
          [item?.Gamma.id]: item?.Gamma?.rankHistory,
        },
        rank:
          item?.Gamma?.fixedRank > 0
            ? item?.Gamma?.fixedRank
            : item?.Gamma?.rank,

        isRank: item.isRanked,
      }));

      allData = [...allData, ...temp];
      const chartData = allData.reduce((acc, curr) => {
        return { ...acc, ...curr.chart };
      }, {});

      setGraphData(chartData);
      setYearlyBackupRankData(chartData);
      setNextToken(res?.body?.nextToken);
      setRankingFilter(filters);
      setDashboardData(allData);
      setFilterChange(!filterChange);

      setFilterLoader(false);
    } catch (err) {
      console.log(err);
      setFilterLoader(false);
    }

    setOpen(false);
    // eslint-disable-next-line max-len
  };

  const [allStages, setAllStages] = useState([]);

  const getStageName = async () => {
    try {
      const sortedStages = await fetchOrganizationStages(orgId);
      setAllStages(sortedStages);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getStageName();
    // eslint-disable-next-line
  }, []);

  return (
    <Drawer
      className="related-item-drawer brawer-with-btn"
      title={
        <Row justify="space-between" align="middle">
          <Typography.Title level={3} className="mb-0 ">
            Filters
          </Typography.Title>
          <Button
            type="text"
            className="text-blue fs-14 p-0"
            onClick={() => {
              setDepartmentFilter([]);
              setStgaestageFilterLevel([]);
              setRankValue([]);
              setSelectAllDepartment(false);
              setSelectAllStage(false);
              setSelectAllRanks(false);
            }}
          >
            <strong>Clear All</strong>
          </Button>
        </Row>
      }
      closeIcon={null}
      closable={false}
      height={screens.xs ? '83vh' : ''}
      placement={screens.xs ? 'bottom' : 'right'}
      onClose={onClose}
      open={open}
    >
      <div>
        <Row justify="space-between" align="middle">
          <Typography.Title level={5} className="mb-0 fw-400 fs-14 text-black">
            Submitted by me
          </Typography.Title>
          <Switch
            checked={submittedByOwner}
            onChange={(e) => setSubmittedByOwner(e)}
            size="small"
          />
        </Row>

        <Form className="mt-20" form={form}>
          {(isAdminGroup || isSuperAdminGroup) && (
            <div className="mb-20">
              <Row justify="space-between" align="middle">
                <Typography.Title level={5} className="mb-0">
                  Ranks
                </Typography.Title>
                <Checkbox
                  className="select-all"
                  checked={selectAllRanks}
                  onChange={(e) => {
                    setRankValue(e.target.checked ? rankType : []);
                    setSelectAllRanks(e.target.checked);
                  }}
                >
                  Select All
                </Checkbox>
              </Row>
              <Divider className="mt-10 mb-10" />

              <Checkbox.Group
                className="vertical-box"
                options={rankType}
                value={rankValue}
                onChange={(e) => setRankValue(e)}
              />
            </div>
          )}

          <div className="mb-20">
            <Row justify="space-between" align="middle">
              <Typography.Title level={5} className="mb-0">
                Stage
              </Typography.Title>
              <Checkbox
                className="select-all"
                checked={selectAllStage}
                onChange={(e) => {
                  setStgaestageFilterLevel(
                    e.target.checked
                      ? allStages?.length > 0 &&
                          allStages?.map((data) => data?.id)
                      : []
                  );
                  setSelectAllStage(e.target.checked);
                }}
              >
                Select All
              </Checkbox>
            </Row>
            <Divider className="mt-10 mb-10" />

            <Checkbox.Group
              className="vertical-box"
              value={stageFilterLevel}
              onChange={(selectedIds) => setStgaestageFilterLevel(selectedIds)}
            >
              {allStages
                ?.filter((item) => item?.active)
                .sort((a, b) => a.level - b.level)
                .map((item) => {
                  let checked = stageFilterLevel.includes(item.id);
                  return (
                    <Checkbox checked={checked} key={item?.id} value={item.id}>
                      {item?.name}
                    </Checkbox>
                  );
                })}
            </Checkbox.Group>
          </div>

          <div className="mb-20">
            <Row justify="space-between" align="middle">
              <Typography.Title level={5} className="mb-0">
                Department
              </Typography.Title>
              <Checkbox
                className="select-all"
                checked={selectAllDepartment}
                onChange={(e) => {
                  setDepartmentFilter(
                    e.target.checked
                      ? listAllDepartments?.length > 0 &&
                          listAllDepartments?.map((data) => data?.id)
                      : []
                  );
                  setSelectAllDepartment(e.target.checked);
                }}
              >
                Select All
              </Checkbox>
            </Row>
            <Divider className="mt-10 mb-10" />

            <Checkbox.Group
              className="vertical-box"
              value={departmentFilter}
              onChange={(e) => setDepartmentFilter(e)}
            >
              {listAllDepartments?.length > 0 &&
                listAllDepartments?.map((item, index) => {
                  return (
                    <Checkbox value={item?.id} key={index}>
                      {item?.name}
                    </Checkbox>
                  );
                })}
            </Checkbox.Group>
          </div>

          <div className="mb-20">
            <Row justify="space-between" align="middle">
              <Typography.Title level={5} className="mb-0">
                Created date
              </Typography.Title>
            </Row>
            <Divider className="mt-10 mb-10" />

            <Radio.Group className="vertical-box">
              <Radio
                value="today"
                checked={timeRange === 'today'}
                onChange={() => setTimeRange('today')}
              >
                Today
              </Radio>
              <Radio
                value="last7days"
                checked={timeRange === 'last7days'}
                onChange={() => setTimeRange('last7days')}
              >
                Last 7 days
              </Radio>
              <Radio
                value="lastWeek"
                checked={timeRange === 'lastWeek'}
                onChange={() => setTimeRange('lastWeek')}
              >
                Last week
              </Radio>
              <Radio
                value="thisMonth"
                checked={timeRange === 'thisMonth'}
                onChange={() => setTimeRange('thisMonth')}
              >
                This month
              </Radio>
              <Radio
                value="lastMonth"
                checked={timeRange === 'lastMonth'}
                onChange={() => setTimeRange('lastMonth')}
              >
                Last month
              </Radio>
              <Radio
                value="customRange"
                checked={timeRange === 'customRange'}
                onChange={() => setTimeRange('customRange')}
              >
                Custom range
              </Radio>
              {timeRange === 'customRange' && (
                <RangePicker onChange={(e) => setCustomRange(e)} />
              )}
            </Radio.Group>
          </div>
        </Form>
      </div>

      <div className="drawer-btn">
        <Button className="drawer-btn-child" onClick={onClose} size="middle">
          Cancel
        </Button>
        <Button
          type="primary"
          className="drawer-btn-child"
          htmlType="submit"
          onClick={() => {
            filterApply(1);
            setCurrentPage(1);
          }}
          loading={filterLoader}
          size="middle"
        >
          Apply
        </Button>
      </div>
    </Drawer>
  );
};

export default memo(RankingFilter);

