/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { DownOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Row,
  Typography,
  Collapse,
  Form,
  message,
  Tag,
  Grid,
  Skeleton,
  Tooltip,
} from 'antd';
import './opportunity.less';
import OpportunityView from './OpportunityView';
import { listGammas } from '../Dashboard/DashboardFunctions';
import { useSelector } from 'react-redux';
import {
  getCurrentOrganization,
  getCurrnetUserData,
  isAdmin,
  isLeader,
  isSuperAdmin,
} from '../../store/slices/loginSlice';
import { orderBy } from 'lodash';
import { useHistory, useParams } from 'react-router-dom';
import { GetFileFromS3, arrToCommaSeperated } from '../../utils/commonMethods';
import { Storage } from 'aws-amplify';
import { FetchUser, getLinkedIdeas } from './IdeaFunction';
import { RESTGetAuthorization } from '../../utils/RESTApi';
import UpdateRankModal from './UpdateRankModal';
import ReAssignedRankModal from './ReAssignedRankModal';
import PromoteModal from './PromoteModal';
import {
  UserRole,
  capitalizeFirstLetter,
  levelColors,
  userStatus,
} from 'utils/Constants';
import { fetchOrgDepartments } from 'utils/CommonFunctions';
import dayjs from 'dayjs';
import { ECHOIMAGES } from 'Assets/Images';
import { getDefaultBatchID } from 'store/slices/compareSlice';
import ItemDetailPage from './Components/ItemDetailPage';
import RelatedItemDrawer from './Components/RelatedItemDrawer';

export default function OpportunityDetail() {
  const { Panel } = Collapse;

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const isSuperAdminGroup = useSelector(isSuperAdmin);
  const isAdminGroup = useSelector(isAdmin);
  const isLeaderGroup = useSelector(isLeader);
  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;
  const userId = currnetOrg?.adminID || loginUsrOrg?.id;
  const history = useHistory();
  const opportunityDetail = history.location.state?.data;
  const pendingAction = history.location.state?.fromPending;
  let editPage = window.location.pathname.includes('edit');

  const [showView, setShowView] = useState(editPage || true);
  const [scaleValue, setScaleValue] = useState({});

  const priorityDefaultBatchID =
    currnetOrg?.defaultPrioirtybatch?.id ||
    loginUsrOrg?.Organization?.organizationDefaultPrioirtybatchId;

  const fetchDefaultBatchID = useSelector(getDefaultBatchID);

  const defaultPriorityBatchID = fetchDefaultBatchID
    ? fetchDefaultBatchID
    : priorityDefaultBatchID;

  const { questionCircle } = ECHOIMAGES.HomeIcons;

  // collapse status
  const [form] = Form.useForm();
  const [fileUpload, setFileUpload] = useState([]);
  const [attachment, setAttachMent] = useState([]);
  const [opportunityFields, setOpportunityFields] = useState([]);
  const [userData, setUserData] = useState([]);
  const [dashboardData, setDashboardData] = useState([]);
  const [backeupData, setBackeupData] = useState([]);
  const [relatedIdea, setRelatedIdea] = useState([]);
  const [relatedIdeaIds, setRelatedIdeaIds] = useState([]);
  const [formChanes, setFormChanges] = useState(false);
  const [createFile, setCreateFile] = useState([]);
  const [contributorID, setContributorID] = useState();
  const [sponsorID, setSponsorID] = useState();

  const [listAllDepartments, setListAllDepartments] = useState([]);
  const editOpportunity = history?.location?.state?.editPtiority || false;
  const [loading, setLoading] = useState(false);
  const [formLayoutByGamma, setFormLayoutByGamma] = useState([]);
  const [isCollapseOpen, setIsCollapseOpen] = useState([]);
  const [attachedLinks, setAttchedLinks] = useState([]);
  const [gammaOwner, setGammaOwner] = useState();

  const { id } = useParams();
  // drawer stuff
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  // rank modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  // promote modal
  const [isPromoteModalOpen, setIsPromoteModalOpen] = useState(false);
  const showPromoteModal = () => {
    setIsPromoteModalOpen(true);
  };

  const fetchGammaDetails = async () => {
    setLoading(true);
    let tempScaleValue = {};
    try {
      let rankingApis = `/rankings/get?id=${id}&priorityBatchID=${defaultPriorityBatchID}`;
      let res = await RESTGetAuthorization(rankingApis);
      res?.body?.layout?.map(async (item) => {
        if (item?.id === process.env.REACT_APP_CONTRIBUTOR_ID) {
          const temp = item?.value?.items?.map((el) => el?.id);
          const users = temp?.filter((el) => el === userId);
          setContributorID(users?.[0]);
        }
        if (item?.id === process.env.REACT_APP_SPONSOR_ID) {
          setSponsorID(item?.value?.id);
        }
        setGammaOwner(res?.body?.userID);
        if (item?.inputType === 'link' && item?.value) {
          let linkAttachment = [...item?.value];
          setAttchedLinks(linkAttachment);
        }
        if (item?.inputType === 'dragger' && item?.value) {
          await Promise.all(
            item?.value.map(async (item) => {
              const Uploadedfile = await getUploadFiles(item?.text);
              item.url = Uploadedfile;
            })
          );
          let fileUpload = item?.value?.map((item) => item?.text);

          setFileUpload(fileUpload);
        }
        if (item?.dropdownValue === 'Multiple') {
          if (item?.optionType === 'Custom') {
            item['newValue'] = item?.value?.map((item) => item?.text);
          } else {
            item['newValue'] = {
              '-1': item?.value?.items?.map((item) => ({ id: item?.id })),
              1: item?.value?.items,
            };
          }
        } else {
          if (
            item?.value?.[0]?.text !== undefined &&
            item?.inputType !== 'link'
          ) {
            item['newValue'] = [item?.value?.[0]?.text];
          } else if (item?.inputType === 'link') {
            item['newValue'] = item?.value?.map((item) => item?.text);
          } else {
            item['newValue'] = item?.value;
          }
        }
        if (item.type === 'measurement') {
          tempScaleValue[item.id] = item.value;
        }
        return item;
      });
      setScaleValue(tempScaleValue);
      setFormLayoutByGamma(res?.body);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const listOrgDepartments = async () => {
    const departments = await fetchOrgDepartments(orgId);
    setListAllDepartments(departments);
  };

  // to get file from S3
  const getUploadFiles = async (item) => {
    const url = await GetFileFromS3(item, 'public');
    return url;
  };

  // to delete upload files from s3 and dynmoDB
  const deleteUploadedFiles = async (item, key) => {
    await Storage.remove(item);
    message.success('File deleted successfully');
    setFileUpload(fileUpload.filter((el, index) => index !== key));
  };

  // get all users by org
  const getAllUsers = async () => {
    try {
      let data = {
        and: [
          { status: { ne: userStatus.invited } },
          { status: { ne: userStatus.inactive } },
        ],
      };
      let id = { organizationID: orgId };
      let nextToken = null;
      let allUserData = [];
      while (true) {
        let res = await FetchUser(data, id, nextToken);
        allUserData.push(...res.items);
        if (!res?.nextToken) {
          break;
        }
        nextToken = res?.nextToken;
      }
      await processUserData(allUserData);
    } catch (err) {
      console.log(err);
    }
  };
  const processUserData = async (userData) => {
    setUserData(userData);
  };

  // to get all link ideas by gamma
  const listRelatedIdea = async () => {
    try {
      const res = await getLinkedIdeas({ id: id });
      setRelatedIdea(res?.data?.getGamma?.linkedTo?.items);
      setRelatedIdeaIds(
        res?.data?.getGamma?.linkedTo?.items.map(
          (item) => item?.linkedGamma?.id
        )
      );
    } catch (error) {
      message.error(error.message);
    }
  };

  const unsavedChangesExist = () => {
    return formChanes;
  };

  useEffect(() => {
    const unblock = history.block((location) => {
      if (unsavedChangesExist()) {
        return 'Are you sure you want to leave? You have unsaved changes.';
      }
    });
    return () => {
      unblock();
    };
    // eslint-disable-next-line
  }, [formChanes, history]);

  // to fetch gammas by org
  const fetchGammas = () => {
    listGammas({ organizationID: orgId })
      .then((gammas) => {
        const temp = [];
        gammas?.items?.forEach((item) => {
          temp.push({
            ...item,
          });
        });
        setDashboardData(orderBy(temp));
        setBackeupData(orderBy(temp));
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    fetchGammas();
    getAllUsers();
    listRelatedIdea();
    listOrgDepartments();
    fetchGammaDetails();
    if (pendingAction) {
      setShowView(false);
    }
    if (editOpportunity) {
      setShowView(false);
    }
    // eslint-disable-next-line
  }, []);

  const handleFormLeave = (event) => {
    setFormChanges(true);
  };

  const detailTooltip = (
    <>
      Congrats! This idea has been promoted to an{' '}
      <span>{capitalizeFirstLetter(formLayoutByGamma?.level?.name)}</span>! Time
      to up your game! You have unlocked new fields and can now add more
      information about how this opportunity is going to help your team and
      organization. Don't forget to ask for help! You can add contributors and
      sponsors to this opportunity and they can help you add more business case
      details so that your opportunity has the best chance of winning! Keep up
      the momentum! Add more details TODAY!
      <a
        href="https://stralign.com/help-update-opportunity"
        target="_blank"
        rel="noopener noreferrer"
      >
        Click here for a video explanation and overview.
      </a>
    </>
  );

  const handleFormsetValue = (valueType) => {
    switch (valueType.inputType) {
      case 'singleLine':
        if (valueType?.value?.[0]?.text !== '') {
          return valueType?.value?.[0]?.text || valueType?.value;
        }
      case 'multiLine':
        if (valueType?.value?.[0]?.text !== '') {
          return valueType?.value?.[0]?.text || valueType?.value;
        }
      case 'dropdown':
        if (valueType.optionType === 'Custom') {
          return valueType?.value?.map((item) => item?.text);
        } else if (valueType?.label === 'Department') {
          return valueType?.value?.items?.map((item) => item?.id);
        } else if (valueType?.label === 'Contributors') {
          let userId = valueType?.value?.items?.map((item) => item?.id);
          return userId;
        } else if (valueType?.id === process.env.REACT_APP_SPONSOR_ID) {
          return valueType?.value?.id;
        } else {
          return valueType?.value
            ? valueType?.value?.map((item) => item?.text || '')
            : [];
        }
      case 'link':
        if (valueType?.value) {
          return valueType?.value?.map((item) => item?.text);
        }
      case 'dragger':
        if (valueType?.value) {
          return valueType?.value?.map((item) => item?.text);
        }
      case 'datepicker':
        return valueType?.value?.[0]?.text
          ? dayjs(valueType?.value?.[0]?.text, 'YYYY-MM-DD')
          : null;
      case 'symbol':
        return valueType?.value?.[0]?.text || valueType?.value;
      default:
        return valueType?.value;
    }
  };

  useEffect(() => {
    formLayoutByGamma?.layout?.forEach((item) => {
      form.setFieldsValue({
        [item.id]: handleFormsetValue(item),
      });
    });
    // eslint-disable-next-line
  }, [formLayoutByGamma]);

  return (
    <>
      <div className="plr-0 pt-0">
        <Row className="w-100">
          <Col span={24}>
            <div className="opportunity-detail">
              {loading ? (
                <Skeleton.Button
                  active
                  block={true}
                  className="skeleton-button"
                />
              ) : (
                <>
                  <Row
                    align="middle"
                    className="breadcrums"
                    justify="space-between"
                  >
                    {/* <Typography.Title
                      level={5}
                      className="title d-flex align-center mb-10"
                    >
                      {formLayoutByGamma?.level?.name &&
                        capitalizeFirstLetter(
                          formLayoutByGamma?.level?.name
                        )}{' '}
                      Detail
                      <Tooltip title={detailTooltip}>
                        <img
                          src={questionCircle}
                          alt=""
                          className="detail-question-icon"
                        />
                      </Tooltip>
                    </Typography.Title> */}
                  </Row>
                  {showView && (
                    <>
                      <Row align="middle" justify="space-between">
                        <Typography.Title
                          level={4}
                          className="title fs-24 fw-500"
                        >
                          {formLayoutByGamma?.title}
                        </Typography.Title>
                        <Row className="gap-10">
                          {(isAdminGroup ||
                            currnetOrg?.id ||
                            isSuperAdminGroup ||
                            contributorID === userId ||
                            gammaOwner === userId ||
                            sponsorID === userId) &&
                            showView && (
                              <Button
                                className="blue-border-btn"
                                onClick={() => {
                                  formLayoutByGamma?.level?.level === 1
                                    ? history.push(`/edit-idea/${id}`)
                                    : setShowView(false);
                                  setIsCollapseOpen(['1']);
                                  setCreateFile([]);
                                }}
                                size={screens.xxl ? 'large' : 'middle'}
                              >
                                Edit Item
                              </Button>
                            )}
                          {(isAdminGroup ||
                            currnetOrg?.id ||
                            isSuperAdminGroup) &&
                            showView && (
                              <>
                                <Button
                                  className="blue-border-btn"
                                  onClick={showModal}
                                  size={screens.xxl ? 'large' : 'middle'}
                                >
                                  Edit Rank
                                </Button>

                                <Button
                                  className="blue-btn"
                                  onClick={showPromoteModal}
                                  size={screens.xxl ? 'large' : 'middle'}
                                >
                                  Promote
                                </Button>
                              </>
                            )}
                          {isLeaderGroup && showView && (
                            <Button
                              className="blue-filled-btn"
                              onClick={showPromoteModal}
                              size="middle"
                            >
                              Promote
                            </Button>
                          )}
                        </Row>
                      </Row>
                      <Row>
                        <Col span={16}>
                          <Typography.Title
                            level={5}
                            className="mt-5 text fs-14"
                          >
                            {formLayoutByGamma?.description}
                          </Typography.Title>
                        </Col>
                      </Row>

                      <Row className="mt-20">
                        <Col span={10} md={10} sm={24} xs={24}>
                          <Typography.Title level={5} className="mb-0 fs-16">
                            <span>ID : </span>{' '}
                            <span className="fs-14 fw-400">
                              {formLayoutByGamma?.friendlyId
                                ? formLayoutByGamma?.friendlyId
                                    .toString()
                                    .padStart(6, '0')
                                : '-'}{' '}
                            </span>
                          </Typography.Title>
                          <Typography.Title className="mb-0 fs-16 mt-10 d-flex align-center">
                            <span>Stage : </span>{' '}
                            <Tag
                              className="capitalize-text ml-5"
                              key={formLayoutByGamma?.levelID}
                              color={
                                levelColors[formLayoutByGamma?.level?.level]
                              }
                            >
                              {formLayoutByGamma?.level?.name}
                            </Tag>
                            <Tooltip title={detailTooltip}>
                              <img
                                src={questionCircle}
                                alt=""
                                className="detail-question-icon"
                              />
                            </Tooltip>
                          </Typography.Title>
                          <Typography.Title
                            level={5}
                            className="mb-0 fs-16 mt-10"
                          >
                            <span>Department : </span>
                            <span className="fs-14 fw-400">
                              {arrToCommaSeperated(
                                formLayoutByGamma?.departments?.items?.map(
                                  (item) => item?.name
                                )
                              )}
                            </span>
                          </Typography.Title>
                        </Col>
                        <Col span={10} md={10} sm={24} xs={24}>
                          <Typography.Title level={5} className="mb-0 fs-16">
                            <span>Rank : </span>
                            <span className="fs-14 fw-400">
                              {formLayoutByGamma?.fixedRank === null ||
                              formLayoutByGamma?.fixedRank < 0
                                ? formLayoutByGamma?.rank || 0
                                : formLayoutByGamma?.fixedRank}
                            </span>
                          </Typography.Title>
                          <Typography.Title
                            level={5}
                            className="mb-0 fs-16 mt-10 mt-10"
                          >
                            <span>Item created date : </span>{' '}
                            <span className="fs-14 fw-400">
                              {new Date(
                                formLayoutByGamma?.createdAt
                              ).toLocaleDateString() ||
                                new Date(
                                  formLayoutByGamma?.createdAt
                                ).toLocaleDateString()}{' '}
                            </span>
                          </Typography.Title>
                          {formLayoutByGamma?.levelHistory?.items?.length >
                            0 && (
                            <Typography.Title
                              level={5}
                              className="mb-0 fs-16 mt-10"
                            >
                              <span>
                                Converted to{' '}
                                {capitalizeFirstLetter(
                                  formLayoutByGamma?.level?.name
                                )}
                                :
                              </span>{' '}
                              <span className="fs-14 fw-400">
                                {new Date(
                                  formLayoutByGamma?.levelHistory?.items?.[0]?.updatedAt
                                ).toLocaleDateString()}
                              </span>
                            </Typography.Title>
                          )}
                        </Col>
                      </Row>
                    </>
                  )}
                </>
              )}

              <Row align="middle" justify="space-between">
                {loading ? (
                  <>
                    <Skeleton
                      active
                      paragraph={{
                        rows: 3,
                      }}
                      className="mt-10"
                    />
                    <Skeleton
                      active
                      paragraph={{
                        rows: 3,
                      }}
                      className="mt-10"
                    />
                    <Skeleton
                      active
                      paragraph={{
                        rows: 3,
                      }}
                      className="mt-10"
                    />
                    <Skeleton
                      active
                      paragraph={{
                        rows: 3,
                      }}
                      className="mt-10"
                    />
                    <Skeleton
                      active
                      paragraph={{
                        rows: 3,
                      }}
                      className="mt-10"
                    />
                  </>
                ) : (
                  // ) : (currnetOrg?.id ||
                  //     isSuperAdminGroup ||
                  //     contributorID === userId ||
                  //     sponsorID === userId) &&
                  //   showView ? (
                  //   <Button
                  //     type="text"
                  //     className="bordered-button"
                  //     onClick={() => {
                  //       setShowView(false);
                  //       setCreateFile([]);
                  //     }}
                  //     size="middle"
                  //   >
                  //     <strong>Edit</strong>
                  //   </Button>
                  <></>
                )}
              </Row>

              {showView ? (
                <OpportunityView
                  opportunityFields={opportunityFields}
                  userData={userData}
                  formLayoutByGamma={formLayoutByGamma}
                  listAllDepartments={listAllDepartments}
                  scaleValue={scaleValue}
                  setScaleValue={setScaleValue}
                />
              ) : (
                <ItemDetailPage
                  form={form}
                  formLayoutByGamma={formLayoutByGamma}
                  handleFormLeave={handleFormLeave}
                  setShowView={setShowView}
                  setCreateFile={setCreateFile}
                  attachment={attachment}
                  setAttachMent={setAttachMent}
                  setFormChanges={setFormChanges}
                  attachedLinks={attachedLinks}
                  setAttchedLinks={setAttchedLinks}
                  deleteUploadedFiles={deleteUploadedFiles}
                  fileUpload={fileUpload}
                  relatedIdea={relatedIdea}
                  listAllDepartments={listAllDepartments}
                  userData={userData}
                  fetchGammaDetails={fetchGammaDetails}
                  listRelatedIdea={listRelatedIdea}
                  showDrawer={showDrawer}
                  createFile={createFile}
                  scaleValue={scaleValue}
                  setScaleValue={setScaleValue}
                  formChanes={formChanes}
                />
              )}
              {/* </Panel>
              </Collapse> */}
            </div>
          </Col>
        </Row>
        <RelatedItemDrawer
          onClose={onClose}
          open={open}
          backeupData={backeupData}
          setDashboardData={setDashboardData}
          dashboardData={dashboardData}
          listRelatedIdea={listRelatedIdea}
          setOpen={setOpen}
          opportunityDetail={opportunityDetail}
          relatedIdeaIds={relatedIdeaIds}
          relatedIdea={relatedIdea}
        />
        {/* edit rank modal  */}

        {formLayoutByGamma?.fixedRank === null ||
        formLayoutByGamma?.fixedRank < 0 ? (
          <UpdateRankModal
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            editData={formLayoutByGamma}
            getGammaByOrg={fetchGammaDetails}
          />
        ) : (
          <ReAssignedRankModal
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            editData={formLayoutByGamma}
            detailPageRank={formLayoutByGamma}
            getGammaByOrg={fetchGammaDetails}
          />
        )}
        {/* Promote modal  */}
        <PromoteModal
          isModalOpen={isPromoteModalOpen}
          setIsModalOpen={setIsPromoteModalOpen}
          editData={formLayoutByGamma}
          getGammaByOrg={fetchGammaDetails}
        />
      </div>
    </>
  );
}
