import { Button, Typography } from 'antd';
import { ECHOIMAGES } from 'Assets/Images';
import React, { memo } from 'react';

const NoFilterImage = ({ setFilterModalOpen }) => {
  const { filterPlaceholder } = ECHOIMAGES.admin;
  return (
    <div className="goal-box  mt-0 no-filter-block">
      <div>
        <img src={filterPlaceholder} alt="filter-img" />
      </div>
      {window.location.pathname.includes('/scenario-detail') ? (
        <Typography.Text>No Filters added yet.</Typography.Text>
      ) : (
        <div>
          <Button onClick={() => setFilterModalOpen(true)}>Add Filter</Button>
        </div>
      )}
    </div>
  );
};

export default memo(NoFilterImage);

