/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const fuzzySearchGammas = /* GraphQL */ `
  query FuzzySearchGammas(
    $title: String!
    $organizationID: String!
    $limit: Int
    $nextToken: String
  ) {
    fuzzySearchGammas(
      title: $title
      organizationID: $organizationID
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        friendlyId
        levelID
        level {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        levelHistory {
          nextToken
          __typename
        }
        title
        description
        measurementFields {
          measurementFieldID
          value
          __typename
        }
        additionalFields {
          name
          __typename
        }
        rankHistory {
          D
          R
          S
          __typename
        }
        mapping {
          gammaID
          organizationID
          mapping
          metadata
          createdAt
          updatedAt
          __typename
        }
        userID
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departments
        UserVotes {
          nextToken
          __typename
        }
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        ownerEmail
        hiddenUsers {
          nextToken
          __typename
        }
        sponsorID
        sponsor {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        contributors
        linkedTo {
          nextToken
          __typename
        }
        userRatings {
          nextToken
          __typename
        }
        fixedRank
        rank
        updatedByIAM
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      __typename
    }
  }
`;
export const getApiKey = /* GraphQL */ `
  query GetApiKey($id: ID!) {
    getApiKey(id: $id) {
      id
      organizationID
      Organization {
        id
        name
        website
        contact
        address {
          street
          city
          zip
          state
          country
          __typename
        }
        vision {
          key
          value
          __typename
        }
        apiKeys {
          nextToken
          __typename
        }
        stages {
          nextToken
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        adminID
        Admin {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        type
        partnerID
        Partner {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Clients {
          nextToken
          __typename
        }
        Users {
          nextToken
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        gammas {
          nextToken
          __typename
        }
        gammaCount
        organizationDefaultPrioirtybatchId
        defaultPrioirtybatch {
          id
          title
          description
          public
          organizationID
          owners
          updatedAtBy
          createdAt
          updatedAt
          __typename
        }
        priorityBatches {
          nextToken
          __typename
        }
        winningCriteria
        scoreWeightages
        objectives {
          nextToken
          __typename
        }
        categoryValues
        weightSettings {
          role
          weight
          __typename
        }
        comparisonFlags
        ratingFlags
        invitationReminderConfig
        inactivityReminderConfig
        scenarioWeightsFlag
        measurementFields {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      key
      keyID
      disabled
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listApiKeys = /* GraphQL */ `
  query ListApiKeys(
    $filter: ModelApiKeyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listApiKeys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        key
        keyID
        disabled
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const apiKeysByOrganizationID = /* GraphQL */ `
  query ApiKeysByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelApiKeyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    apiKeysByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        key
        keyID
        disabled
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const apiKeysByKey = /* GraphQL */ `
  query ApiKeysByKey(
    $key: String!
    $sortDirection: ModelSortDirection
    $filter: ModelApiKeyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    apiKeysByKey(
      key: $key
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        key
        keyID
        disabled
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getWebhooksSubscription = /* GraphQL */ `
  query GetWebhooksSubscription($id: ID!) {
    getWebhooksSubscription(id: $id) {
      id
      organizationID
      typeName
      eventName
      hookUrl
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listWebhooksSubscriptions = /* GraphQL */ `
  query ListWebhooksSubscriptions(
    $filter: ModelWebhooksSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWebhooksSubscriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationID
        typeName
        eventName
        hookUrl
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const webhooksSubscriptionsByOrganizationID = /* GraphQL */ `
  query WebhooksSubscriptionsByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelWebhooksSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    webhooksSubscriptionsByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationID
        typeName
        eventName
        hookUrl
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLoginHistory = /* GraphQL */ `
  query GetLoginHistory($userID: ID!, $date: AWSDate!) {
    getLoginHistory(userID: $userID, date: $date) {
      userID
      User {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      date
      datetime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listLoginHistories = /* GraphQL */ `
  query ListLoginHistories(
    $userID: ID
    $date: ModelStringKeyConditionInput
    $filter: ModelLoginHistoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLoginHistories(
      userID: $userID
      date: $date
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        userID
        User {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        date
        datetime
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const loginHistoriesByUserIDAndDate = /* GraphQL */ `
  query LoginHistoriesByUserIDAndDate(
    $userID: ID!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLoginHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    loginHistoriesByUserIDAndDate(
      userID: $userID
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userID
        User {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        date
        datetime
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLatestRankStatus = /* GraphQL */ `
  query GetLatestRankStatus($organizationID: ID!) {
    getLatestRankStatus(organizationID: $organizationID) {
      organizationID
      organization {
        id
        name
        website
        contact
        address {
          street
          city
          zip
          state
          country
          __typename
        }
        vision {
          key
          value
          __typename
        }
        apiKeys {
          nextToken
          __typename
        }
        stages {
          nextToken
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        adminID
        Admin {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        type
        partnerID
        Partner {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Clients {
          nextToken
          __typename
        }
        Users {
          nextToken
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        gammas {
          nextToken
          __typename
        }
        gammaCount
        organizationDefaultPrioirtybatchId
        defaultPrioirtybatch {
          id
          title
          description
          public
          organizationID
          owners
          updatedAtBy
          createdAt
          updatedAt
          __typename
        }
        priorityBatches {
          nextToken
          __typename
        }
        winningCriteria
        scoreWeightages
        objectives {
          nextToken
          __typename
        }
        categoryValues
        weightSettings {
          role
          weight
          __typename
        }
        comparisonFlags
        ratingFlags
        invitationReminderConfig
        inactivityReminderConfig
        scenarioWeightsFlag
        measurementFields {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      timestamp
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listLatestRankStatuses = /* GraphQL */ `
  query ListLatestRankStatuses(
    $organizationID: ID
    $filter: ModelLatestRankStatusFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLatestRankStatuses(
      organizationID: $organizationID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        organizationID
        organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        timestamp
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserRating = /* GraphQL */ `
  query GetUserRating($id: ID!) {
    getUserRating(id: $id) {
      id
      userID
      User {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      organizationID
      Organization {
        id
        name
        website
        contact
        address {
          street
          city
          zip
          state
          country
          __typename
        }
        vision {
          key
          value
          __typename
        }
        apiKeys {
          nextToken
          __typename
        }
        stages {
          nextToken
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        adminID
        Admin {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        type
        partnerID
        Partner {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Clients {
          nextToken
          __typename
        }
        Users {
          nextToken
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        gammas {
          nextToken
          __typename
        }
        gammaCount
        organizationDefaultPrioirtybatchId
        defaultPrioirtybatch {
          id
          title
          description
          public
          organizationID
          owners
          updatedAtBy
          createdAt
          updatedAt
          __typename
        }
        priorityBatches {
          nextToken
          __typename
        }
        winningCriteria
        scoreWeightages
        objectives {
          nextToken
          __typename
        }
        categoryValues
        weightSettings {
          role
          weight
          __typename
        }
        comparisonFlags
        ratingFlags
        invitationReminderConfig
        inactivityReminderConfig
        scenarioWeightsFlag
        measurementFields {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      gammaID
      Gamma {
        id
        friendlyId
        levelID
        level {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        levelHistory {
          nextToken
          __typename
        }
        title
        description
        measurementFields {
          measurementFieldID
          value
          __typename
        }
        additionalFields {
          name
          __typename
        }
        rankHistory {
          D
          R
          S
          __typename
        }
        mapping {
          gammaID
          organizationID
          mapping
          metadata
          createdAt
          updatedAt
          __typename
        }
        userID
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departments
        UserVotes {
          nextToken
          __typename
        }
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        ownerEmail
        hiddenUsers {
          nextToken
          __typename
        }
        sponsorID
        sponsor {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        contributors
        linkedTo {
          nextToken
          __typename
        }
        userRatings {
          nextToken
          __typename
        }
        fixedRank
        rank
        updatedByIAM
        createdAt
        updatedAt
        __typename
      }
      rating
      weight
      userRatingObjectiveId
      Objective {
        id
        name
        description
        active
        organizationObjectivesId
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        ownerId
        owner {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserRatings = /* GraphQL */ `
  query ListUserRatings(
    $filter: ModelUserRatingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserRatings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        User {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        gammaID
        Gamma {
          id
          friendlyId
          levelID
          title
          description
          userID
          organizationID
          departments
          ownerEmail
          sponsorID
          contributors
          fixedRank
          rank
          updatedByIAM
          createdAt
          updatedAt
          __typename
        }
        rating
        weight
        userRatingObjectiveId
        Objective {
          id
          name
          description
          active
          organizationObjectivesId
          ownerId
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userRatingsByUserIDAndGammaID = /* GraphQL */ `
  query UserRatingsByUserIDAndGammaID(
    $userID: ID!
    $gammaID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserRatingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userRatingsByUserIDAndGammaID(
      userID: $userID
      gammaID: $gammaID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        User {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        gammaID
        Gamma {
          id
          friendlyId
          levelID
          title
          description
          userID
          organizationID
          departments
          ownerEmail
          sponsorID
          contributors
          fixedRank
          rank
          updatedByIAM
          createdAt
          updatedAt
          __typename
        }
        rating
        weight
        userRatingObjectiveId
        Objective {
          id
          name
          description
          active
          organizationObjectivesId
          ownerId
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userRatingsByOrganizationIDAndUserID = /* GraphQL */ `
  query UserRatingsByOrganizationIDAndUserID(
    $organizationID: ID!
    $userID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserRatingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userRatingsByOrganizationIDAndUserID(
      organizationID: $organizationID
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        User {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        gammaID
        Gamma {
          id
          friendlyId
          levelID
          title
          description
          userID
          organizationID
          departments
          ownerEmail
          sponsorID
          contributors
          fixedRank
          rank
          updatedByIAM
          createdAt
          updatedAt
          __typename
        }
        rating
        weight
        userRatingObjectiveId
        Objective {
          id
          name
          description
          active
          organizationObjectivesId
          ownerId
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userRatingsByGammaID = /* GraphQL */ `
  query UserRatingsByGammaID(
    $gammaID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserRatingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userRatingsByGammaID(
      gammaID: $gammaID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        User {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        gammaID
        Gamma {
          id
          friendlyId
          levelID
          title
          description
          userID
          organizationID
          departments
          ownerEmail
          sponsorID
          contributors
          fixedRank
          rank
          updatedByIAM
          createdAt
          updatedAt
          __typename
        }
        rating
        weight
        userRatingObjectiveId
        Objective {
          id
          name
          description
          active
          organizationObjectivesId
          ownerId
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userRatingByObjectiveID = /* GraphQL */ `
  query UserRatingByObjectiveID(
    $userRatingObjectiveId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserRatingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userRatingByObjectiveID(
      userRatingObjectiveId: $userRatingObjectiveId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        User {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        gammaID
        Gamma {
          id
          friendlyId
          levelID
          title
          description
          userID
          organizationID
          departments
          ownerEmail
          sponsorID
          contributors
          fixedRank
          rank
          updatedByIAM
          createdAt
          updatedAt
          __typename
        }
        rating
        weight
        userRatingObjectiveId
        Objective {
          id
          name
          description
          active
          organizationObjectivesId
          ownerId
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchUserRatings = /* GraphQL */ `
  query SearchUserRatings(
    $filter: SearchableUserRatingFilterInput
    $sort: [SearchableUserRatingSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableUserRatingAggregationInput]
  ) {
    searchUserRatings(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        userID
        User {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        gammaID
        Gamma {
          id
          friendlyId
          levelID
          title
          description
          userID
          organizationID
          departments
          ownerEmail
          sponsorID
          contributors
          fixedRank
          rank
          updatedByIAM
          createdAt
          updatedAt
          __typename
        }
        rating
        weight
        userRatingObjectiveId
        Objective {
          id
          name
          description
          active
          organizationObjectivesId
          ownerId
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getPriorityBatch = /* GraphQL */ `
  query GetPriorityBatch($id: ID!) {
    getPriorityBatch(id: $id) {
      id
      title
      description
      priorities {
        weight
        ObjectiveID
        __typename
      }
      voteFilters {
        match
        filters {
          __typename
        }
        __typename
      }
      measurementFieldFilters {
        match
        filters {
          measurementField
          measurementFieldID
          blanks
          threshold
          __typename
        }
        __typename
      }
      fieldFilters {
        match
        filters {
          fieldID
          blanks
          field
          __typename
        }
        __typename
      }
      public
      organizationID
      Organization {
        id
        name
        website
        contact
        address {
          street
          city
          zip
          state
          country
          __typename
        }
        vision {
          key
          value
          __typename
        }
        apiKeys {
          nextToken
          __typename
        }
        stages {
          nextToken
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        adminID
        Admin {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        type
        partnerID
        Partner {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Clients {
          nextToken
          __typename
        }
        Users {
          nextToken
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        gammas {
          nextToken
          __typename
        }
        gammaCount
        organizationDefaultPrioirtybatchId
        defaultPrioirtybatch {
          id
          title
          description
          public
          organizationID
          owners
          updatedAtBy
          createdAt
          updatedAt
          __typename
        }
        priorityBatches {
          nextToken
          __typename
        }
        winningCriteria
        scoreWeightages
        objectives {
          nextToken
          __typename
        }
        categoryValues
        weightSettings {
          role
          weight
          __typename
        }
        comparisonFlags
        ratingFlags
        invitationReminderConfig
        inactivityReminderConfig
        scenarioWeightsFlag
        measurementFields {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      owners
      updatedAtBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPriorityBatches = /* GraphQL */ `
  query ListPriorityBatches(
    $filter: ModelPriorityBatchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPriorityBatches(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        priorities {
          weight
          ObjectiveID
          __typename
        }
        voteFilters {
          match
          __typename
        }
        measurementFieldFilters {
          match
          __typename
        }
        fieldFilters {
          match
          __typename
        }
        public
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        owners
        updatedAtBy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const priorityBatchesByOrganizationID = /* GraphQL */ `
  query PriorityBatchesByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPriorityBatchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    priorityBatchesByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        priorities {
          weight
          ObjectiveID
          __typename
        }
        voteFilters {
          match
          __typename
        }
        measurementFieldFilters {
          match
          __typename
        }
        fieldFilters {
          match
          __typename
        }
        public
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        owners
        updatedAtBy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchPriorityBatches = /* GraphQL */ `
  query SearchPriorityBatches(
    $filter: SearchablePriorityBatchFilterInput
    $sort: [SearchablePriorityBatchSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchablePriorityBatchAggregationInput]
  ) {
    searchPriorityBatches(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        title
        description
        priorities {
          weight
          ObjectiveID
          __typename
        }
        voteFilters {
          match
          __typename
        }
        measurementFieldFilters {
          match
          __typename
        }
        fieldFilters {
          match
          __typename
        }
        public
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        owners
        updatedAtBy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getObjective = /* GraphQL */ `
  query GetObjective($id: ID!) {
    getObjective(id: $id) {
      id
      name
      description
      active
      organizationObjectivesId
      Organization {
        id
        name
        website
        contact
        address {
          street
          city
          zip
          state
          country
          __typename
        }
        vision {
          key
          value
          __typename
        }
        apiKeys {
          nextToken
          __typename
        }
        stages {
          nextToken
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        adminID
        Admin {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        type
        partnerID
        Partner {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Clients {
          nextToken
          __typename
        }
        Users {
          nextToken
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        gammas {
          nextToken
          __typename
        }
        gammaCount
        organizationDefaultPrioirtybatchId
        defaultPrioirtybatch {
          id
          title
          description
          public
          organizationID
          owners
          updatedAtBy
          createdAt
          updatedAt
          __typename
        }
        priorityBatches {
          nextToken
          __typename
        }
        winningCriteria
        scoreWeightages
        objectives {
          nextToken
          __typename
        }
        categoryValues
        weightSettings {
          role
          weight
          __typename
        }
        comparisonFlags
        ratingFlags
        invitationReminderConfig
        inactivityReminderConfig
        scenarioWeightsFlag
        measurementFields {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      ownerId
      owner {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      Departments {
        items {
          id
          objectiveID
          departmentID
          ownerID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listObjectives = /* GraphQL */ `
  query ListObjectives(
    $filter: ModelObjectiveFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listObjectives(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        active
        organizationObjectivesId
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        ownerId
        owner {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const objectivesByOrganizationObjectivesId = /* GraphQL */ `
  query ObjectivesByOrganizationObjectivesId(
    $organizationObjectivesId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelObjectiveFilterInput
    $limit: Int
    $nextToken: String
  ) {
    objectivesByOrganizationObjectivesId(
      organizationObjectivesId: $organizationObjectivesId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        active
        organizationObjectivesId
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        ownerId
        owner {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const objectivesByOwnerId = /* GraphQL */ `
  query ObjectivesByOwnerId(
    $ownerId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelObjectiveFilterInput
    $limit: Int
    $nextToken: String
  ) {
    objectivesByOwnerId(
      ownerId: $ownerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        active
        organizationObjectivesId
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        ownerId
        owner {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserVote = /* GraphQL */ `
  query GetUserVote($id: ID!) {
    getUserVote(id: $id) {
      id
      vote
      weight
      userID
      User {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      organizationID
      Organization {
        id
        name
        website
        contact
        address {
          street
          city
          zip
          state
          country
          __typename
        }
        vision {
          key
          value
          __typename
        }
        apiKeys {
          nextToken
          __typename
        }
        stages {
          nextToken
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        adminID
        Admin {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        type
        partnerID
        Partner {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Clients {
          nextToken
          __typename
        }
        Users {
          nextToken
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        gammas {
          nextToken
          __typename
        }
        gammaCount
        organizationDefaultPrioirtybatchId
        defaultPrioirtybatch {
          id
          title
          description
          public
          organizationID
          owners
          updatedAtBy
          createdAt
          updatedAt
          __typename
        }
        priorityBatches {
          nextToken
          __typename
        }
        winningCriteria
        scoreWeightages
        objectives {
          nextToken
          __typename
        }
        categoryValues
        weightSettings {
          role
          weight
          __typename
        }
        comparisonFlags
        ratingFlags
        invitationReminderConfig
        inactivityReminderConfig
        scenarioWeightsFlag
        measurementFields {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      gammaID
      Gamma {
        id
        friendlyId
        levelID
        level {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        levelHistory {
          nextToken
          __typename
        }
        title
        description
        measurementFields {
          measurementFieldID
          value
          __typename
        }
        additionalFields {
          name
          __typename
        }
        rankHistory {
          D
          R
          S
          __typename
        }
        mapping {
          gammaID
          organizationID
          mapping
          metadata
          createdAt
          updatedAt
          __typename
        }
        userID
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departments
        UserVotes {
          nextToken
          __typename
        }
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        ownerEmail
        hiddenUsers {
          nextToken
          __typename
        }
        sponsorID
        sponsor {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        contributors
        linkedTo {
          nextToken
          __typename
        }
        userRatings {
          nextToken
          __typename
        }
        fixedRank
        rank
        updatedByIAM
        createdAt
        updatedAt
        __typename
      }
      vsGamma {
        id
        friendlyId
        levelID
        level {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        levelHistory {
          nextToken
          __typename
        }
        title
        description
        measurementFields {
          measurementFieldID
          value
          __typename
        }
        additionalFields {
          name
          __typename
        }
        rankHistory {
          D
          R
          S
          __typename
        }
        mapping {
          gammaID
          organizationID
          mapping
          metadata
          createdAt
          updatedAt
          __typename
        }
        userID
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departments
        UserVotes {
          nextToken
          __typename
        }
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        ownerEmail
        hiddenUsers {
          nextToken
          __typename
        }
        sponsorID
        sponsor {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        contributors
        linkedTo {
          nextToken
          __typename
        }
        userRatings {
          nextToken
          __typename
        }
        fixedRank
        rank
        updatedByIAM
        createdAt
        updatedAt
        __typename
      }
      userVoteObjectiveId
      Objective {
        id
        name
        description
        active
        organizationObjectivesId
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        ownerId
        owner {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      voteTime
      createdAt
      updatedAt
      userVoteVsGammaId
      __typename
    }
  }
`;
export const listUserVotes = /* GraphQL */ `
  query ListUserVotes(
    $filter: ModelUserVoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserVotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        vote
        weight
        userID
        User {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        gammaID
        Gamma {
          id
          friendlyId
          levelID
          title
          description
          userID
          organizationID
          departments
          ownerEmail
          sponsorID
          contributors
          fixedRank
          rank
          updatedByIAM
          createdAt
          updatedAt
          __typename
        }
        vsGamma {
          id
          friendlyId
          levelID
          title
          description
          userID
          organizationID
          departments
          ownerEmail
          sponsorID
          contributors
          fixedRank
          rank
          updatedByIAM
          createdAt
          updatedAt
          __typename
        }
        userVoteObjectiveId
        Objective {
          id
          name
          description
          active
          organizationObjectivesId
          ownerId
          createdAt
          updatedAt
          __typename
        }
        voteTime
        createdAt
        updatedAt
        userVoteVsGammaId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userVotesByUserID = /* GraphQL */ `
  query UserVotesByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserVoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userVotesByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        vote
        weight
        userID
        User {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        gammaID
        Gamma {
          id
          friendlyId
          levelID
          title
          description
          userID
          organizationID
          departments
          ownerEmail
          sponsorID
          contributors
          fixedRank
          rank
          updatedByIAM
          createdAt
          updatedAt
          __typename
        }
        vsGamma {
          id
          friendlyId
          levelID
          title
          description
          userID
          organizationID
          departments
          ownerEmail
          sponsorID
          contributors
          fixedRank
          rank
          updatedByIAM
          createdAt
          updatedAt
          __typename
        }
        userVoteObjectiveId
        Objective {
          id
          name
          description
          active
          organizationObjectivesId
          ownerId
          createdAt
          updatedAt
          __typename
        }
        voteTime
        createdAt
        updatedAt
        userVoteVsGammaId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userVotesByOrganizationIDAndUserID = /* GraphQL */ `
  query UserVotesByOrganizationIDAndUserID(
    $organizationID: ID!
    $userID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserVoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userVotesByOrganizationIDAndUserID(
      organizationID: $organizationID
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        vote
        weight
        userID
        User {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        gammaID
        Gamma {
          id
          friendlyId
          levelID
          title
          description
          userID
          organizationID
          departments
          ownerEmail
          sponsorID
          contributors
          fixedRank
          rank
          updatedByIAM
          createdAt
          updatedAt
          __typename
        }
        vsGamma {
          id
          friendlyId
          levelID
          title
          description
          userID
          organizationID
          departments
          ownerEmail
          sponsorID
          contributors
          fixedRank
          rank
          updatedByIAM
          createdAt
          updatedAt
          __typename
        }
        userVoteObjectiveId
        Objective {
          id
          name
          description
          active
          organizationObjectivesId
          ownerId
          createdAt
          updatedAt
          __typename
        }
        voteTime
        createdAt
        updatedAt
        userVoteVsGammaId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userVotesByGammaID = /* GraphQL */ `
  query UserVotesByGammaID(
    $gammaID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserVoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userVotesByGammaID(
      gammaID: $gammaID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        vote
        weight
        userID
        User {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        gammaID
        Gamma {
          id
          friendlyId
          levelID
          title
          description
          userID
          organizationID
          departments
          ownerEmail
          sponsorID
          contributors
          fixedRank
          rank
          updatedByIAM
          createdAt
          updatedAt
          __typename
        }
        vsGamma {
          id
          friendlyId
          levelID
          title
          description
          userID
          organizationID
          departments
          ownerEmail
          sponsorID
          contributors
          fixedRank
          rank
          updatedByIAM
          createdAt
          updatedAt
          __typename
        }
        userVoteObjectiveId
        Objective {
          id
          name
          description
          active
          organizationObjectivesId
          ownerId
          createdAt
          updatedAt
          __typename
        }
        voteTime
        createdAt
        updatedAt
        userVoteVsGammaId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userVotesByUserVoteObjectiveId = /* GraphQL */ `
  query UserVotesByUserVoteObjectiveId(
    $userVoteObjectiveId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserVoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userVotesByUserVoteObjectiveId(
      userVoteObjectiveId: $userVoteObjectiveId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        vote
        weight
        userID
        User {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        gammaID
        Gamma {
          id
          friendlyId
          levelID
          title
          description
          userID
          organizationID
          departments
          ownerEmail
          sponsorID
          contributors
          fixedRank
          rank
          updatedByIAM
          createdAt
          updatedAt
          __typename
        }
        vsGamma {
          id
          friendlyId
          levelID
          title
          description
          userID
          organizationID
          departments
          ownerEmail
          sponsorID
          contributors
          fixedRank
          rank
          updatedByIAM
          createdAt
          updatedAt
          __typename
        }
        userVoteObjectiveId
        Objective {
          id
          name
          description
          active
          organizationObjectivesId
          ownerId
          createdAt
          updatedAt
          __typename
        }
        voteTime
        createdAt
        updatedAt
        userVoteVsGammaId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchUserVotes = /* GraphQL */ `
  query SearchUserVotes(
    $filter: SearchableUserVoteFilterInput
    $sort: [SearchableUserVoteSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableUserVoteAggregationInput]
  ) {
    searchUserVotes(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        vote
        weight
        userID
        User {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        gammaID
        Gamma {
          id
          friendlyId
          levelID
          title
          description
          userID
          organizationID
          departments
          ownerEmail
          sponsorID
          contributors
          fixedRank
          rank
          updatedByIAM
          createdAt
          updatedAt
          __typename
        }
        vsGamma {
          id
          friendlyId
          levelID
          title
          description
          userID
          organizationID
          departments
          ownerEmail
          sponsorID
          contributors
          fixedRank
          rank
          updatedByIAM
          createdAt
          updatedAt
          __typename
        }
        userVoteObjectiveId
        Objective {
          id
          name
          description
          active
          organizationObjectivesId
          ownerId
          createdAt
          updatedAt
          __typename
        }
        voteTime
        createdAt
        updatedAt
        userVoteVsGammaId
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getDepartment = /* GraphQL */ `
  query GetDepartment($id: ID!) {
    getDepartment(id: $id) {
      id
      name
      organizationID
      organization {
        id
        name
        website
        contact
        address {
          street
          city
          zip
          state
          country
          __typename
        }
        vision {
          key
          value
          __typename
        }
        apiKeys {
          nextToken
          __typename
        }
        stages {
          nextToken
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        adminID
        Admin {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        type
        partnerID
        Partner {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Clients {
          nextToken
          __typename
        }
        Users {
          nextToken
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        gammas {
          nextToken
          __typename
        }
        gammaCount
        organizationDefaultPrioirtybatchId
        defaultPrioirtybatch {
          id
          title
          description
          public
          organizationID
          owners
          updatedAtBy
          createdAt
          updatedAt
          __typename
        }
        priorityBatches {
          nextToken
          __typename
        }
        winningCriteria
        scoreWeightages
        objectives {
          nextToken
          __typename
        }
        categoryValues
        weightSettings {
          role
          weight
          __typename
        }
        comparisonFlags
        ratingFlags
        invitationReminderConfig
        inactivityReminderConfig
        scenarioWeightsFlag
        measurementFields {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      Users {
        items {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      Objectives {
        items {
          id
          objectiveID
          departmentID
          ownerID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      managerID
      Manager {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDepartments = /* GraphQL */ `
  query ListDepartments(
    $filter: ModelDepartmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDepartments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        organizationID
        organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Users {
          nextToken
          __typename
        }
        Objectives {
          nextToken
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const departmentsByOrganizationID = /* GraphQL */ `
  query DepartmentsByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDepartmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    departmentsByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        organizationID
        organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Users {
          nextToken
          __typename
        }
        Objectives {
          nextToken
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const departmentByManagerID = /* GraphQL */ `
  query DepartmentByManagerID(
    $managerID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDepartmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    departmentByManagerID(
      managerID: $managerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        organizationID
        organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Users {
          nextToken
          __typename
        }
        Objectives {
          nextToken
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOrganization = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      name
      website
      contact
      address {
        street
        city
        zip
        state
        country
        __typename
      }
      vision {
        key
        value
        __typename
      }
      apiKeys {
        items {
          id
          organizationID
          key
          keyID
          disabled
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      stages {
        items {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      Departments {
        items {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      adminID
      Admin {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      type
      partnerID
      Partner {
        id
        name
        website
        contact
        address {
          street
          city
          zip
          state
          country
          __typename
        }
        vision {
          key
          value
          __typename
        }
        apiKeys {
          nextToken
          __typename
        }
        stages {
          nextToken
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        adminID
        Admin {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        type
        partnerID
        Partner {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Clients {
          nextToken
          __typename
        }
        Users {
          nextToken
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        gammas {
          nextToken
          __typename
        }
        gammaCount
        organizationDefaultPrioirtybatchId
        defaultPrioirtybatch {
          id
          title
          description
          public
          organizationID
          owners
          updatedAtBy
          createdAt
          updatedAt
          __typename
        }
        priorityBatches {
          nextToken
          __typename
        }
        winningCriteria
        scoreWeightages
        objectives {
          nextToken
          __typename
        }
        categoryValues
        weightSettings {
          role
          weight
          __typename
        }
        comparisonFlags
        ratingFlags
        invitationReminderConfig
        inactivityReminderConfig
        scenarioWeightsFlag
        measurementFields {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      Clients {
        items {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      Users {
        items {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      UserVotes {
        items {
          id
          vote
          weight
          userID
          organizationID
          gammaID
          userVoteObjectiveId
          voteTime
          createdAt
          updatedAt
          userVoteVsGammaId
          __typename
        }
        nextToken
        __typename
      }
      UserRatings {
        items {
          id
          userID
          organizationID
          gammaID
          rating
          weight
          userRatingObjectiveId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      gammas {
        items {
          id
          friendlyId
          levelID
          title
          description
          userID
          organizationID
          departments
          ownerEmail
          sponsorID
          contributors
          fixedRank
          rank
          updatedByIAM
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      gammaCount
      organizationDefaultPrioirtybatchId
      defaultPrioirtybatch {
        id
        title
        description
        priorities {
          weight
          ObjectiveID
          __typename
        }
        voteFilters {
          match
          __typename
        }
        measurementFieldFilters {
          match
          __typename
        }
        fieldFilters {
          match
          __typename
        }
        public
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        owners
        updatedAtBy
        createdAt
        updatedAt
        __typename
      }
      priorityBatches {
        items {
          id
          title
          description
          public
          organizationID
          owners
          updatedAtBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      winningCriteria
      scoreWeightages
      objectives {
        items {
          id
          name
          description
          active
          organizationObjectivesId
          ownerId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      categoryValues
      weightSettings {
        role
        weight
        __typename
      }
      comparisonFlags
      ratingFlags
      invitationReminderConfig
      inactivityReminderConfig
      scenarioWeightsFlag
      measurementFields {
        items {
          id
          name
          description
          type
          subType
          isMeasurement
          organizationID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listOrganizations = /* GraphQL */ `
  query ListOrganizations(
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        website
        contact
        address {
          street
          city
          zip
          state
          country
          __typename
        }
        vision {
          key
          value
          __typename
        }
        apiKeys {
          nextToken
          __typename
        }
        stages {
          nextToken
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        adminID
        Admin {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        type
        partnerID
        Partner {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Clients {
          nextToken
          __typename
        }
        Users {
          nextToken
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        gammas {
          nextToken
          __typename
        }
        gammaCount
        organizationDefaultPrioirtybatchId
        defaultPrioirtybatch {
          id
          title
          description
          public
          organizationID
          owners
          updatedAtBy
          createdAt
          updatedAt
          __typename
        }
        priorityBatches {
          nextToken
          __typename
        }
        winningCriteria
        scoreWeightages
        objectives {
          nextToken
          __typename
        }
        categoryValues
        weightSettings {
          role
          weight
          __typename
        }
        comparisonFlags
        ratingFlags
        invitationReminderConfig
        inactivityReminderConfig
        scenarioWeightsFlag
        measurementFields {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const organizationsByPartnerID = /* GraphQL */ `
  query OrganizationsByPartnerID(
    $partnerID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    organizationsByPartnerID(
      partnerID: $partnerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        website
        contact
        address {
          street
          city
          zip
          state
          country
          __typename
        }
        vision {
          key
          value
          __typename
        }
        apiKeys {
          nextToken
          __typename
        }
        stages {
          nextToken
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        adminID
        Admin {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        type
        partnerID
        Partner {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Clients {
          nextToken
          __typename
        }
        Users {
          nextToken
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        gammas {
          nextToken
          __typename
        }
        gammaCount
        organizationDefaultPrioirtybatchId
        defaultPrioirtybatch {
          id
          title
          description
          public
          organizationID
          owners
          updatedAtBy
          createdAt
          updatedAt
          __typename
        }
        priorityBatches {
          nextToken
          __typename
        }
        winningCriteria
        scoreWeightages
        objectives {
          nextToken
          __typename
        }
        categoryValues
        weightSettings {
          role
          weight
          __typename
        }
        comparisonFlags
        ratingFlags
        invitationReminderConfig
        inactivityReminderConfig
        scenarioWeightsFlag
        measurementFields {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchOrganizations = /* GraphQL */ `
  query SearchOrganizations(
    $filter: SearchableOrganizationFilterInput
    $sort: [SearchableOrganizationSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableOrganizationAggregationInput]
  ) {
    searchOrganizations(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        name
        website
        contact
        address {
          street
          city
          zip
          state
          country
          __typename
        }
        vision {
          key
          value
          __typename
        }
        apiKeys {
          nextToken
          __typename
        }
        stages {
          nextToken
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        adminID
        Admin {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        type
        partnerID
        Partner {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Clients {
          nextToken
          __typename
        }
        Users {
          nextToken
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        gammas {
          nextToken
          __typename
        }
        gammaCount
        organizationDefaultPrioirtybatchId
        defaultPrioirtybatch {
          id
          title
          description
          public
          organizationID
          owners
          updatedAtBy
          createdAt
          updatedAt
          __typename
        }
        priorityBatches {
          nextToken
          __typename
        }
        winningCriteria
        scoreWeightages
        objectives {
          nextToken
          __typename
        }
        categoryValues
        weightSettings {
          role
          weight
          __typename
        }
        comparisonFlags
        ratingFlags
        invitationReminderConfig
        inactivityReminderConfig
        scenarioWeightsFlag
        measurementFields {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getFormDraft = /* GraphQL */ `
  query GetFormDraft($stageID: ID!, $version: Int!) {
    getFormDraft(stageID: $stageID, version: $version) {
      stageID
      name
      layout
      version
      updatedByIAM
      Stage {
        id
        name
        active
        level
        form {
          layout
          __typename
        }
        draft {
          nextToken
          __typename
        }
        updatedByIAM
        gammas {
          nextToken
          __typename
        }
        measurementFieldConnection {
          nextToken
          __typename
        }
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      organizationID
      Organization {
        id
        name
        website
        contact
        address {
          street
          city
          zip
          state
          country
          __typename
        }
        vision {
          key
          value
          __typename
        }
        apiKeys {
          nextToken
          __typename
        }
        stages {
          nextToken
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        adminID
        Admin {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        type
        partnerID
        Partner {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Clients {
          nextToken
          __typename
        }
        Users {
          nextToken
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        gammas {
          nextToken
          __typename
        }
        gammaCount
        organizationDefaultPrioirtybatchId
        defaultPrioirtybatch {
          id
          title
          description
          public
          organizationID
          owners
          updatedAtBy
          createdAt
          updatedAt
          __typename
        }
        priorityBatches {
          nextToken
          __typename
        }
        winningCriteria
        scoreWeightages
        objectives {
          nextToken
          __typename
        }
        categoryValues
        weightSettings {
          role
          weight
          __typename
        }
        comparisonFlags
        ratingFlags
        invitationReminderConfig
        inactivityReminderConfig
        scenarioWeightsFlag
        measurementFields {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listFormDrafts = /* GraphQL */ `
  query ListFormDrafts(
    $stageID: ID
    $version: ModelIntKeyConditionInput
    $filter: ModelFormDraftFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFormDrafts(
      stageID: $stageID
      version: $version
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        stageID
        name
        layout
        version
        updatedByIAM
        Stage {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const formDraftsByStageID = /* GraphQL */ `
  query FormDraftsByStageID(
    $stageID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelFormDraftFilterInput
    $limit: Int
    $nextToken: String
  ) {
    formDraftsByStageID(
      stageID: $stageID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        stageID
        name
        layout
        version
        updatedByIAM
        Stage {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const formDraftsByOrganizationID = /* GraphQL */ `
  query FormDraftsByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelFormDraftFilterInput
    $limit: Int
    $nextToken: String
  ) {
    formDraftsByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        stageID
        name
        layout
        version
        updatedByIAM
        Stage {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getStage = /* GraphQL */ `
  query GetStage($id: ID!) {
    getStage(id: $id) {
      id
      name
      active
      level
      form {
        layout
        __typename
      }
      draft {
        items {
          stageID
          name
          layout
          version
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      updatedByIAM
      gammas {
        items {
          id
          friendlyId
          levelID
          title
          description
          userID
          organizationID
          departments
          ownerEmail
          sponsorID
          contributors
          fixedRank
          rank
          updatedByIAM
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      measurementFieldConnection {
        items {
          measurementFieldID
          stageID
          organizationID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      organizationID
      Organization {
        id
        name
        website
        contact
        address {
          street
          city
          zip
          state
          country
          __typename
        }
        vision {
          key
          value
          __typename
        }
        apiKeys {
          nextToken
          __typename
        }
        stages {
          nextToken
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        adminID
        Admin {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        type
        partnerID
        Partner {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Clients {
          nextToken
          __typename
        }
        Users {
          nextToken
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        gammas {
          nextToken
          __typename
        }
        gammaCount
        organizationDefaultPrioirtybatchId
        defaultPrioirtybatch {
          id
          title
          description
          public
          organizationID
          owners
          updatedAtBy
          createdAt
          updatedAt
          __typename
        }
        priorityBatches {
          nextToken
          __typename
        }
        winningCriteria
        scoreWeightages
        objectives {
          nextToken
          __typename
        }
        categoryValues
        weightSettings {
          role
          weight
          __typename
        }
        comparisonFlags
        ratingFlags
        invitationReminderConfig
        inactivityReminderConfig
        scenarioWeightsFlag
        measurementFields {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listStages = /* GraphQL */ `
  query ListStages(
    $filter: ModelStageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        active
        level
        form {
          layout
          __typename
        }
        draft {
          nextToken
          __typename
        }
        updatedByIAM
        gammas {
          nextToken
          __typename
        }
        measurementFieldConnection {
          nextToken
          __typename
        }
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const stagesByOrganizationID = /* GraphQL */ `
  query StagesByOrganizationID(
    $organizationID: ID!
    $level: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    stagesByOrganizationID(
      organizationID: $organizationID
      level: $level
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        active
        level
        form {
          layout
          __typename
        }
        draft {
          nextToken
          __typename
        }
        updatedByIAM
        gammas {
          nextToken
          __typename
        }
        measurementFieldConnection {
          nextToken
          __typename
        }
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const stagesByOrganizationIDAndName = /* GraphQL */ `
  query StagesByOrganizationIDAndName(
    $organizationID: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    stagesByOrganizationIDAndName(
      organizationID: $organizationID
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        active
        level
        form {
          layout
          __typename
        }
        draft {
          nextToken
          __typename
        }
        updatedByIAM
        gammas {
          nextToken
          __typename
        }
        measurementFieldConnection {
          nextToken
          __typename
        }
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLevelChange = /* GraphQL */ `
  query GetLevelChange($id: ID!) {
    getLevelChange(id: $id) {
      id
      previousID
      previous {
        id
        name
        active
        level
        form {
          layout
          __typename
        }
        draft {
          nextToken
          __typename
        }
        updatedByIAM
        gammas {
          nextToken
          __typename
        }
        measurementFieldConnection {
          nextToken
          __typename
        }
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      presentID
      present {
        id
        name
        active
        level
        form {
          layout
          __typename
        }
        draft {
          nextToken
          __typename
        }
        updatedByIAM
        gammas {
          nextToken
          __typename
        }
        measurementFieldConnection {
          nextToken
          __typename
        }
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      gammaLevelHistoryId
      gamma {
        id
        friendlyId
        levelID
        level {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        levelHistory {
          nextToken
          __typename
        }
        title
        description
        measurementFields {
          measurementFieldID
          value
          __typename
        }
        additionalFields {
          name
          __typename
        }
        rankHistory {
          D
          R
          S
          __typename
        }
        mapping {
          gammaID
          organizationID
          mapping
          metadata
          createdAt
          updatedAt
          __typename
        }
        userID
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departments
        UserVotes {
          nextToken
          __typename
        }
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        ownerEmail
        hiddenUsers {
          nextToken
          __typename
        }
        sponsorID
        sponsor {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        contributors
        linkedTo {
          nextToken
          __typename
        }
        userRatings {
          nextToken
          __typename
        }
        fixedRank
        rank
        updatedByIAM
        createdAt
        updatedAt
        __typename
      }
      changedAt
      userID
      changedBy {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listLevelChanges = /* GraphQL */ `
  query ListLevelChanges(
    $filter: ModelLevelChangeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLevelChanges(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        previousID
        previous {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        presentID
        present {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        gammaLevelHistoryId
        gamma {
          id
          friendlyId
          levelID
          title
          description
          userID
          organizationID
          departments
          ownerEmail
          sponsorID
          contributors
          fixedRank
          rank
          updatedByIAM
          createdAt
          updatedAt
          __typename
        }
        changedAt
        userID
        changedBy {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const levelChangesByGammaLevelHistoryIdAndChangedAt = /* GraphQL */ `
  query LevelChangesByGammaLevelHistoryIdAndChangedAt(
    $gammaLevelHistoryId: ID!
    $changedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLevelChangeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    levelChangesByGammaLevelHistoryIdAndChangedAt(
      gammaLevelHistoryId: $gammaLevelHistoryId
      changedAt: $changedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        previousID
        previous {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        presentID
        present {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        gammaLevelHistoryId
        gamma {
          id
          friendlyId
          levelID
          title
          description
          userID
          organizationID
          departments
          ownerEmail
          sponsorID
          contributors
          fixedRank
          rank
          updatedByIAM
          createdAt
          updatedAt
          __typename
        }
        changedAt
        userID
        changedBy {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const levelChangesByUserID = /* GraphQL */ `
  query LevelChangesByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelLevelChangeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    levelChangesByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        previousID
        previous {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        presentID
        present {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        gammaLevelHistoryId
        gamma {
          id
          friendlyId
          levelID
          title
          description
          userID
          organizationID
          departments
          ownerEmail
          sponsorID
          contributors
          fixedRank
          rank
          updatedByIAM
          createdAt
          updatedAt
          __typename
        }
        changedAt
        userID
        changedBy {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getInactivityReminderStatus = /* GraphQL */ `
  query GetInactivityReminderStatus($userID: ID!) {
    getInactivityReminderStatus(userID: $userID) {
      userID
      user {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listInactivityReminderStatuses = /* GraphQL */ `
  query ListInactivityReminderStatuses(
    $userID: ID
    $filter: ModelInactivityReminderStatusFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listInactivityReminderStatuses(
      userID: $userID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        userID
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getInvitationReminderStatus = /* GraphQL */ `
  query GetInvitationReminderStatus($userID: ID!) {
    getInvitationReminderStatus(userID: $userID) {
      userID
      user {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listInvitationReminderStatuses = /* GraphQL */ `
  query ListInvitationReminderStatuses(
    $userID: ID
    $filter: ModelInvitationReminderStatusFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listInvitationReminderStatuses(
      userID: $userID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        userID
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserSignOutStatus = /* GraphQL */ `
  query GetUserSignOutStatus($id: ID!) {
    getUserSignOutStatus(id: $id) {
      id
      userID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserSignOutStatuses = /* GraphQL */ `
  query ListUserSignOutStatuses(
    $filter: ModelUserSignOutStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserSignOutStatuses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userSignOutStatusesByUserID = /* GraphQL */ `
  query UserSignOutStatusesByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserSignOutStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userSignOutStatusesByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getComparisonCreatorStatus = /* GraphQL */ `
  query GetComparisonCreatorStatus($userID: ID!) {
    getComparisonCreatorStatus(userID: $userID) {
      userID
      expiry
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listComparisonCreatorStatuses = /* GraphQL */ `
  query ListComparisonCreatorStatuses(
    $userID: ID
    $filter: ModelComparisonCreatorStatusFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listComparisonCreatorStatuses(
      userID: $userID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        userID
        expiry
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getVoteRatingTriggerStatus = /* GraphQL */ `
  query GetVoteRatingTriggerStatus($organizationID: ID!) {
    getVoteRatingTriggerStatus(organizationID: $organizationID) {
      organizationID
      expiry
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listVoteRatingTriggerStatuses = /* GraphQL */ `
  query ListVoteRatingTriggerStatuses(
    $organizationID: ID
    $filter: ModelVoteRatingTriggerStatusFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listVoteRatingTriggerStatuses(
      organizationID: $organizationID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        organizationID
        expiry
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      photo
      contact
      email
      gammas {
        items {
          id
          friendlyId
          levelID
          title
          description
          userID
          organizationID
          departments
          ownerEmail
          sponsorID
          contributors
          fixedRank
          rank
          updatedByIAM
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      hiddenGammas {
        items {
          id
          userId
          gammaId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sponsoring {
        items {
          id
          friendlyId
          levelID
          title
          description
          userID
          organizationID
          departments
          ownerEmail
          sponsorID
          contributors
          fixedRank
          rank
          updatedByIAM
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      firstName
      lastName
      lastLogin
      designation
      status
      role
      weight
      organizationID
      Organization {
        id
        name
        website
        contact
        address {
          street
          city
          zip
          state
          country
          __typename
        }
        vision {
          key
          value
          __typename
        }
        apiKeys {
          nextToken
          __typename
        }
        stages {
          nextToken
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        adminID
        Admin {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        type
        partnerID
        Partner {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Clients {
          nextToken
          __typename
        }
        Users {
          nextToken
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        gammas {
          nextToken
          __typename
        }
        gammaCount
        organizationDefaultPrioirtybatchId
        defaultPrioirtybatch {
          id
          title
          description
          public
          organizationID
          owners
          updatedAtBy
          createdAt
          updatedAt
          __typename
        }
        priorityBatches {
          nextToken
          __typename
        }
        winningCriteria
        scoreWeightages
        objectives {
          nextToken
          __typename
        }
        categoryValues
        weightSettings {
          role
          weight
          __typename
        }
        comparisonFlags
        ratingFlags
        invitationReminderConfig
        inactivityReminderConfig
        scenarioWeightsFlag
        measurementFields {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      departmentID
      Department {
        id
        name
        organizationID
        organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Users {
          nextToken
          __typename
        }
        Objectives {
          nextToken
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      managerID
      Manager {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      UserVotes {
        items {
          id
          vote
          weight
          userID
          organizationID
          gammaID
          userVoteObjectiveId
          voteTime
          createdAt
          updatedAt
          userVoteVsGammaId
          __typename
        }
        nextToken
        __typename
      }
      LoginHistory {
        items {
          userID
          date
          datetime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      objectives {
        items {
          id
          name
          description
          active
          organizationObjectivesId
          ownerId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      UserRatings {
        items {
          id
          userID
          organizationID
          gammaID
          rating
          weight
          userRatingObjectiveId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      invitationReminderStatus {
        userID
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      inactivityReminderStatus {
        userID
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userByEmail = /* GraphQL */ `
  query UserByEmail(
    $email: AWSEmail!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByEmail(
      email: $email
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const usersByOrganizationID = /* GraphQL */ `
  query UsersByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const usersByDepartmentID = /* GraphQL */ `
  query UsersByDepartmentID(
    $departmentID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByDepartmentID(
      departmentID: $departmentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const usersByManagerID = /* GraphQL */ `
  query UsersByManagerID(
    $managerID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByManagerID(
      managerID: $managerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchUsers = /* GraphQL */ `
  query SearchUsers(
    $filter: SearchableUserFilterInput
    $sort: [SearchableUserSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableUserAggregationInput]
  ) {
    searchUsers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getLinkedGammas = /* GraphQL */ `
  query GetLinkedGammas($id: ID!) {
    getLinkedGammas(id: $id) {
      id
      gammaLinkedToId
      gamma {
        id
        friendlyId
        levelID
        level {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        levelHistory {
          nextToken
          __typename
        }
        title
        description
        measurementFields {
          measurementFieldID
          value
          __typename
        }
        additionalFields {
          name
          __typename
        }
        rankHistory {
          D
          R
          S
          __typename
        }
        mapping {
          gammaID
          organizationID
          mapping
          metadata
          createdAt
          updatedAt
          __typename
        }
        userID
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departments
        UserVotes {
          nextToken
          __typename
        }
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        ownerEmail
        hiddenUsers {
          nextToken
          __typename
        }
        sponsorID
        sponsor {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        contributors
        linkedTo {
          nextToken
          __typename
        }
        userRatings {
          nextToken
          __typename
        }
        fixedRank
        rank
        updatedByIAM
        createdAt
        updatedAt
        __typename
      }
      linkedGammasLinkedGammaId
      linkedGamma {
        id
        friendlyId
        levelID
        level {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        levelHistory {
          nextToken
          __typename
        }
        title
        description
        measurementFields {
          measurementFieldID
          value
          __typename
        }
        additionalFields {
          name
          __typename
        }
        rankHistory {
          D
          R
          S
          __typename
        }
        mapping {
          gammaID
          organizationID
          mapping
          metadata
          createdAt
          updatedAt
          __typename
        }
        userID
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departments
        UserVotes {
          nextToken
          __typename
        }
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        ownerEmail
        hiddenUsers {
          nextToken
          __typename
        }
        sponsorID
        sponsor {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        contributors
        linkedTo {
          nextToken
          __typename
        }
        userRatings {
          nextToken
          __typename
        }
        fixedRank
        rank
        updatedByIAM
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listLinkedGammas = /* GraphQL */ `
  query ListLinkedGammas(
    $filter: ModelLinkedGammasFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLinkedGammas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        gammaLinkedToId
        gamma {
          id
          friendlyId
          levelID
          title
          description
          userID
          organizationID
          departments
          ownerEmail
          sponsorID
          contributors
          fixedRank
          rank
          updatedByIAM
          createdAt
          updatedAt
          __typename
        }
        linkedGammasLinkedGammaId
        linkedGamma {
          id
          friendlyId
          levelID
          title
          description
          userID
          organizationID
          departments
          ownerEmail
          sponsorID
          contributors
          fixedRank
          rank
          updatedByIAM
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const LinkedGammasbyGammaID = /* GraphQL */ `
  query LinkedGammasbyGammaID(
    $gammaLinkedToId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelLinkedGammasFilterInput
    $limit: Int
    $nextToken: String
  ) {
    LinkedGammasbyGammaID(
      gammaLinkedToId: $gammaLinkedToId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        gammaLinkedToId
        gamma {
          id
          friendlyId
          levelID
          title
          description
          userID
          organizationID
          departments
          ownerEmail
          sponsorID
          contributors
          fixedRank
          rank
          updatedByIAM
          createdAt
          updatedAt
          __typename
        }
        linkedGammasLinkedGammaId
        linkedGamma {
          id
          friendlyId
          levelID
          title
          description
          userID
          organizationID
          departments
          ownerEmail
          sponsorID
          contributors
          fixedRank
          rank
          updatedByIAM
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const LinkedGammasbyLinkedID = /* GraphQL */ `
  query LinkedGammasbyLinkedID(
    $linkedGammasLinkedGammaId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelLinkedGammasFilterInput
    $limit: Int
    $nextToken: String
  ) {
    LinkedGammasbyLinkedID(
      linkedGammasLinkedGammaId: $linkedGammasLinkedGammaId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        gammaLinkedToId
        gamma {
          id
          friendlyId
          levelID
          title
          description
          userID
          organizationID
          departments
          ownerEmail
          sponsorID
          contributors
          fixedRank
          rank
          updatedByIAM
          createdAt
          updatedAt
          __typename
        }
        linkedGammasLinkedGammaId
        linkedGamma {
          id
          friendlyId
          levelID
          title
          description
          userID
          organizationID
          departments
          ownerEmail
          sponsorID
          contributors
          fixedRank
          rank
          updatedByIAM
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getComparison = /* GraphQL */ `
  query GetComparison($id: ID!) {
    getComparison(id: $id) {
      id
      gamma1ID
      gamma1 {
        id
        friendlyId
        levelID
        level {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        levelHistory {
          nextToken
          __typename
        }
        title
        description
        measurementFields {
          measurementFieldID
          value
          __typename
        }
        additionalFields {
          name
          __typename
        }
        rankHistory {
          D
          R
          S
          __typename
        }
        mapping {
          gammaID
          organizationID
          mapping
          metadata
          createdAt
          updatedAt
          __typename
        }
        userID
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departments
        UserVotes {
          nextToken
          __typename
        }
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        ownerEmail
        hiddenUsers {
          nextToken
          __typename
        }
        sponsorID
        sponsor {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        contributors
        linkedTo {
          nextToken
          __typename
        }
        userRatings {
          nextToken
          __typename
        }
        fixedRank
        rank
        updatedByIAM
        createdAt
        updatedAt
        __typename
      }
      gamma2ID
      gamma2 {
        id
        friendlyId
        levelID
        level {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        levelHistory {
          nextToken
          __typename
        }
        title
        description
        measurementFields {
          measurementFieldID
          value
          __typename
        }
        additionalFields {
          name
          __typename
        }
        rankHistory {
          D
          R
          S
          __typename
        }
        mapping {
          gammaID
          organizationID
          mapping
          metadata
          createdAt
          updatedAt
          __typename
        }
        userID
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departments
        UserVotes {
          nextToken
          __typename
        }
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        ownerEmail
        hiddenUsers {
          nextToken
          __typename
        }
        sponsorID
        sponsor {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        contributors
        linkedTo {
          nextToken
          __typename
        }
        userRatings {
          nextToken
          __typename
        }
        fixedRank
        rank
        updatedByIAM
        createdAt
        updatedAt
        __typename
      }
      objectiveID
      objective {
        id
        name
        description
        active
        organizationObjectivesId
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        ownerId
        owner {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      preference
      userID
      user {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      expiry
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listComparisons = /* GraphQL */ `
  query ListComparisons(
    $filter: ModelComparisonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComparisons(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        gamma1ID
        gamma1 {
          id
          friendlyId
          levelID
          title
          description
          userID
          organizationID
          departments
          ownerEmail
          sponsorID
          contributors
          fixedRank
          rank
          updatedByIAM
          createdAt
          updatedAt
          __typename
        }
        gamma2ID
        gamma2 {
          id
          friendlyId
          levelID
          title
          description
          userID
          organizationID
          departments
          ownerEmail
          sponsorID
          contributors
          fixedRank
          rank
          updatedByIAM
          createdAt
          updatedAt
          __typename
        }
        objectiveID
        objective {
          id
          name
          description
          active
          organizationObjectivesId
          ownerId
          createdAt
          updatedAt
          __typename
        }
        preference
        userID
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        expiry
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const comparisonsByGamma1ID = /* GraphQL */ `
  query ComparisonsByGamma1ID(
    $gamma1ID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelComparisonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    comparisonsByGamma1ID(
      gamma1ID: $gamma1ID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        gamma1ID
        gamma1 {
          id
          friendlyId
          levelID
          title
          description
          userID
          organizationID
          departments
          ownerEmail
          sponsorID
          contributors
          fixedRank
          rank
          updatedByIAM
          createdAt
          updatedAt
          __typename
        }
        gamma2ID
        gamma2 {
          id
          friendlyId
          levelID
          title
          description
          userID
          organizationID
          departments
          ownerEmail
          sponsorID
          contributors
          fixedRank
          rank
          updatedByIAM
          createdAt
          updatedAt
          __typename
        }
        objectiveID
        objective {
          id
          name
          description
          active
          organizationObjectivesId
          ownerId
          createdAt
          updatedAt
          __typename
        }
        preference
        userID
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        expiry
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const comparisonsByGamma2ID = /* GraphQL */ `
  query ComparisonsByGamma2ID(
    $gamma2ID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelComparisonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    comparisonsByGamma2ID(
      gamma2ID: $gamma2ID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        gamma1ID
        gamma1 {
          id
          friendlyId
          levelID
          title
          description
          userID
          organizationID
          departments
          ownerEmail
          sponsorID
          contributors
          fixedRank
          rank
          updatedByIAM
          createdAt
          updatedAt
          __typename
        }
        gamma2ID
        gamma2 {
          id
          friendlyId
          levelID
          title
          description
          userID
          organizationID
          departments
          ownerEmail
          sponsorID
          contributors
          fixedRank
          rank
          updatedByIAM
          createdAt
          updatedAt
          __typename
        }
        objectiveID
        objective {
          id
          name
          description
          active
          organizationObjectivesId
          ownerId
          createdAt
          updatedAt
          __typename
        }
        preference
        userID
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        expiry
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const comparisonsByObjectiveID = /* GraphQL */ `
  query ComparisonsByObjectiveID(
    $objectiveID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelComparisonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    comparisonsByObjectiveID(
      objectiveID: $objectiveID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        gamma1ID
        gamma1 {
          id
          friendlyId
          levelID
          title
          description
          userID
          organizationID
          departments
          ownerEmail
          sponsorID
          contributors
          fixedRank
          rank
          updatedByIAM
          createdAt
          updatedAt
          __typename
        }
        gamma2ID
        gamma2 {
          id
          friendlyId
          levelID
          title
          description
          userID
          organizationID
          departments
          ownerEmail
          sponsorID
          contributors
          fixedRank
          rank
          updatedByIAM
          createdAt
          updatedAt
          __typename
        }
        objectiveID
        objective {
          id
          name
          description
          active
          organizationObjectivesId
          ownerId
          createdAt
          updatedAt
          __typename
        }
        preference
        userID
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        expiry
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const comparisonsByUserID = /* GraphQL */ `
  query ComparisonsByUserID(
    $userID: ID!
    $preference: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelComparisonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    comparisonsByUserID(
      userID: $userID
      preference: $preference
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        gamma1ID
        gamma1 {
          id
          friendlyId
          levelID
          title
          description
          userID
          organizationID
          departments
          ownerEmail
          sponsorID
          contributors
          fixedRank
          rank
          updatedByIAM
          createdAt
          updatedAt
          __typename
        }
        gamma2ID
        gamma2 {
          id
          friendlyId
          levelID
          title
          description
          userID
          organizationID
          departments
          ownerEmail
          sponsorID
          contributors
          fixedRank
          rank
          updatedByIAM
          createdAt
          updatedAt
          __typename
        }
        objectiveID
        objective {
          id
          name
          description
          active
          organizationObjectivesId
          ownerId
          createdAt
          updatedAt
          __typename
        }
        preference
        userID
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        expiry
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMeasurementField = /* GraphQL */ `
  query GetMeasurementField($id: ID!) {
    getMeasurementField(id: $id) {
      id
      name
      description
      type
      subType
      breakpoints {
        label
        value
        __typename
      }
      isMeasurement
      organizationID
      Organization {
        id
        name
        website
        contact
        address {
          street
          city
          zip
          state
          country
          __typename
        }
        vision {
          key
          value
          __typename
        }
        apiKeys {
          nextToken
          __typename
        }
        stages {
          nextToken
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        adminID
        Admin {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        type
        partnerID
        Partner {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Clients {
          nextToken
          __typename
        }
        Users {
          nextToken
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        gammas {
          nextToken
          __typename
        }
        gammaCount
        organizationDefaultPrioirtybatchId
        defaultPrioirtybatch {
          id
          title
          description
          public
          organizationID
          owners
          updatedAtBy
          createdAt
          updatedAt
          __typename
        }
        priorityBatches {
          nextToken
          __typename
        }
        winningCriteria
        scoreWeightages
        objectives {
          nextToken
          __typename
        }
        categoryValues
        weightSettings {
          role
          weight
          __typename
        }
        comparisonFlags
        ratingFlags
        invitationReminderConfig
        inactivityReminderConfig
        scenarioWeightsFlag
        measurementFields {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      StageConnection {
        items {
          measurementFieldID
          stageID
          organizationID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMeasurementFields = /* GraphQL */ `
  query ListMeasurementFields(
    $filter: ModelMeasurementFieldFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeasurementFields(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        type
        subType
        breakpoints {
          label
          value
          __typename
        }
        isMeasurement
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        StageConnection {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const measurementFieldsByOrganizationID = /* GraphQL */ `
  query MeasurementFieldsByOrganizationID(
    $organizationID: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMeasurementFieldFilterInput
    $limit: Int
    $nextToken: String
  ) {
    measurementFieldsByOrganizationID(
      organizationID: $organizationID
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        type
        subType
        breakpoints {
          label
          value
          __typename
        }
        isMeasurement
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        StageConnection {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchMeasurementFields = /* GraphQL */ `
  query SearchMeasurementFields(
    $filter: SearchableMeasurementFieldFilterInput
    $sort: [SearchableMeasurementFieldSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableMeasurementFieldAggregationInput]
  ) {
    searchMeasurementFields(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        name
        description
        type
        subType
        breakpoints {
          label
          value
          __typename
        }
        isMeasurement
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        StageConnection {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getMeasurementFieldStageConnection = /* GraphQL */ `
  query GetMeasurementFieldStageConnection(
    $measurementFieldID: ID!
    $stageID: ID!
  ) {
    getMeasurementFieldStageConnection(
      measurementFieldID: $measurementFieldID
      stageID: $stageID
    ) {
      measurementFieldID
      stageID
      measurementField {
        id
        name
        description
        type
        subType
        breakpoints {
          label
          value
          __typename
        }
        isMeasurement
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        StageConnection {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      stage {
        id
        name
        active
        level
        form {
          layout
          __typename
        }
        draft {
          nextToken
          __typename
        }
        updatedByIAM
        gammas {
          nextToken
          __typename
        }
        measurementFieldConnection {
          nextToken
          __typename
        }
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      organizationID
      Organization {
        id
        name
        website
        contact
        address {
          street
          city
          zip
          state
          country
          __typename
        }
        vision {
          key
          value
          __typename
        }
        apiKeys {
          nextToken
          __typename
        }
        stages {
          nextToken
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        adminID
        Admin {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        type
        partnerID
        Partner {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Clients {
          nextToken
          __typename
        }
        Users {
          nextToken
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        gammas {
          nextToken
          __typename
        }
        gammaCount
        organizationDefaultPrioirtybatchId
        defaultPrioirtybatch {
          id
          title
          description
          public
          organizationID
          owners
          updatedAtBy
          createdAt
          updatedAt
          __typename
        }
        priorityBatches {
          nextToken
          __typename
        }
        winningCriteria
        scoreWeightages
        objectives {
          nextToken
          __typename
        }
        categoryValues
        weightSettings {
          role
          weight
          __typename
        }
        comparisonFlags
        ratingFlags
        invitationReminderConfig
        inactivityReminderConfig
        scenarioWeightsFlag
        measurementFields {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMeasurementFieldStageConnections = /* GraphQL */ `
  query ListMeasurementFieldStageConnections(
    $measurementFieldID: ID
    $stageID: ModelIDKeyConditionInput
    $filter: ModelMeasurementFieldStageConnectionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMeasurementFieldStageConnections(
      measurementFieldID: $measurementFieldID
      stageID: $stageID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        measurementFieldID
        stageID
        measurementField {
          id
          name
          description
          type
          subType
          isMeasurement
          organizationID
          createdAt
          updatedAt
          __typename
        }
        stage {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const measurementFieldStageConnectionsByMeasurementFieldID = /* GraphQL */ `
  query MeasurementFieldStageConnectionsByMeasurementFieldID(
    $measurementFieldID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMeasurementFieldStageConnectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    measurementFieldStageConnectionsByMeasurementFieldID(
      measurementFieldID: $measurementFieldID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        measurementFieldID
        stageID
        measurementField {
          id
          name
          description
          type
          subType
          isMeasurement
          organizationID
          createdAt
          updatedAt
          __typename
        }
        stage {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const measurementFieldStageConnectionsByStageID = /* GraphQL */ `
  query MeasurementFieldStageConnectionsByStageID(
    $stageID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMeasurementFieldStageConnectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    measurementFieldStageConnectionsByStageID(
      stageID: $stageID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        measurementFieldID
        stageID
        measurementField {
          id
          name
          description
          type
          subType
          isMeasurement
          organizationID
          createdAt
          updatedAt
          __typename
        }
        stage {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const measurementFieldStageConnectionsByOrganizationID = /* GraphQL */ `
  query MeasurementFieldStageConnectionsByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMeasurementFieldStageConnectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    measurementFieldStageConnectionsByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        measurementFieldID
        stageID
        measurementField {
          id
          name
          description
          type
          subType
          isMeasurement
          organizationID
          createdAt
          updatedAt
          __typename
        }
        stage {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getObjectivesAndDepartments = /* GraphQL */ `
  query GetObjectivesAndDepartments($id: ID!) {
    getObjectivesAndDepartments(id: $id) {
      id
      objectiveID
      departmentID
      objective {
        id
        name
        description
        active
        organizationObjectivesId
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        ownerId
        owner {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      department {
        id
        name
        organizationID
        organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Users {
          nextToken
          __typename
        }
        Objectives {
          nextToken
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      ownerID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listObjectivesAndDepartments = /* GraphQL */ `
  query ListObjectivesAndDepartments(
    $filter: ModelObjectivesAndDepartmentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listObjectivesAndDepartments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        objectiveID
        departmentID
        objective {
          id
          name
          description
          active
          organizationObjectivesId
          ownerId
          createdAt
          updatedAt
          __typename
        }
        department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        ownerID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const objectivesAndDepartmentsByObjectiveID = /* GraphQL */ `
  query ObjectivesAndDepartmentsByObjectiveID(
    $objectiveID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelObjectivesAndDepartmentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    objectivesAndDepartmentsByObjectiveID(
      objectiveID: $objectiveID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        objectiveID
        departmentID
        objective {
          id
          name
          description
          active
          organizationObjectivesId
          ownerId
          createdAt
          updatedAt
          __typename
        }
        department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        ownerID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const objectivesAndDepartmentsByDepartmentID = /* GraphQL */ `
  query ObjectivesAndDepartmentsByDepartmentID(
    $departmentID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelObjectivesAndDepartmentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    objectivesAndDepartmentsByDepartmentID(
      departmentID: $departmentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        objectiveID
        departmentID
        objective {
          id
          name
          description
          active
          organizationObjectivesId
          ownerId
          createdAt
          updatedAt
          __typename
        }
        department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        ownerID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getHiddenUsersAndHiddenGammas = /* GraphQL */ `
  query GetHiddenUsersAndHiddenGammas($id: ID!) {
    getHiddenUsersAndHiddenGammas(id: $id) {
      id
      userId
      gammaId
      user {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      gamma {
        id
        friendlyId
        levelID
        level {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        levelHistory {
          nextToken
          __typename
        }
        title
        description
        measurementFields {
          measurementFieldID
          value
          __typename
        }
        additionalFields {
          name
          __typename
        }
        rankHistory {
          D
          R
          S
          __typename
        }
        mapping {
          gammaID
          organizationID
          mapping
          metadata
          createdAt
          updatedAt
          __typename
        }
        userID
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departments
        UserVotes {
          nextToken
          __typename
        }
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        ownerEmail
        hiddenUsers {
          nextToken
          __typename
        }
        sponsorID
        sponsor {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        contributors
        linkedTo {
          nextToken
          __typename
        }
        userRatings {
          nextToken
          __typename
        }
        fixedRank
        rank
        updatedByIAM
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listHiddenUsersAndHiddenGammas = /* GraphQL */ `
  query ListHiddenUsersAndHiddenGammas(
    $filter: ModelHiddenUsersAndHiddenGammasFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHiddenUsersAndHiddenGammas(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        gammaId
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        gamma {
          id
          friendlyId
          levelID
          title
          description
          userID
          organizationID
          departments
          ownerEmail
          sponsorID
          contributors
          fixedRank
          rank
          updatedByIAM
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const hiddenUsersAndHiddenGammasByUserId = /* GraphQL */ `
  query HiddenUsersAndHiddenGammasByUserId(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelHiddenUsersAndHiddenGammasFilterInput
    $limit: Int
    $nextToken: String
  ) {
    hiddenUsersAndHiddenGammasByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        gammaId
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        gamma {
          id
          friendlyId
          levelID
          title
          description
          userID
          organizationID
          departments
          ownerEmail
          sponsorID
          contributors
          fixedRank
          rank
          updatedByIAM
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const hiddenUsersAndHiddenGammasByGammaId = /* GraphQL */ `
  query HiddenUsersAndHiddenGammasByGammaId(
    $gammaId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelHiddenUsersAndHiddenGammasFilterInput
    $limit: Int
    $nextToken: String
  ) {
    hiddenUsersAndHiddenGammasByGammaId(
      gammaId: $gammaId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        gammaId
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        gamma {
          id
          friendlyId
          levelID
          title
          description
          userID
          organizationID
          departments
          ownerEmail
          sponsorID
          contributors
          fixedRank
          rank
          updatedByIAM
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGamma = /* GraphQL */ `
  query GetGamma($id: ID!) {
    getGamma(id: $id) {
      id
      friendlyId
      levelID
      level {
        id
        name
        active
        level
        form {
          layout
          __typename
        }
        draft {
          nextToken
          __typename
        }
        updatedByIAM
        gammas {
          nextToken
          __typename
        }
        measurementFieldConnection {
          nextToken
          __typename
        }
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      levelHistory {
        items {
          id
          previousID
          presentID
          gammaLevelHistoryId
          changedAt
          userID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      title
      description
      measurementFields {
        measurementFieldID
        value
        __typename
      }
      additionalFields {
        name
        value {
          text
          updatedAtBy
          __typename
        }
        __typename
      }
      rankHistory {
        D
        R
        S
        __typename
      }
      mapping {
        gammaID
        organizationID
        Gamma {
          id
          friendlyId
          levelID
          title
          description
          userID
          organizationID
          departments
          ownerEmail
          sponsorID
          contributors
          fixedRank
          rank
          updatedByIAM
          createdAt
          updatedAt
          __typename
        }
        mapping
        metadata
        createdAt
        updatedAt
        __typename
      }
      userID
      organizationID
      Organization {
        id
        name
        website
        contact
        address {
          street
          city
          zip
          state
          country
          __typename
        }
        vision {
          key
          value
          __typename
        }
        apiKeys {
          nextToken
          __typename
        }
        stages {
          nextToken
          __typename
        }
        Departments {
          nextToken
          __typename
        }
        adminID
        Admin {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        type
        partnerID
        Partner {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        Clients {
          nextToken
          __typename
        }
        Users {
          nextToken
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        gammas {
          nextToken
          __typename
        }
        gammaCount
        organizationDefaultPrioirtybatchId
        defaultPrioirtybatch {
          id
          title
          description
          public
          organizationID
          owners
          updatedAtBy
          createdAt
          updatedAt
          __typename
        }
        priorityBatches {
          nextToken
          __typename
        }
        winningCriteria
        scoreWeightages
        objectives {
          nextToken
          __typename
        }
        categoryValues
        weightSettings {
          role
          weight
          __typename
        }
        comparisonFlags
        ratingFlags
        invitationReminderConfig
        inactivityReminderConfig
        scenarioWeightsFlag
        measurementFields {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      departments
      UserVotes {
        items {
          id
          vote
          weight
          userID
          organizationID
          gammaID
          userVoteObjectiveId
          voteTime
          createdAt
          updatedAt
          userVoteVsGammaId
          __typename
        }
        nextToken
        __typename
      }
      user {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      ownerEmail
      hiddenUsers {
        items {
          id
          userId
          gammaId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sponsorID
      sponsor {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      contributors
      linkedTo {
        items {
          id
          gammaLinkedToId
          linkedGammasLinkedGammaId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      userRatings {
        items {
          id
          userID
          organizationID
          gammaID
          rating
          weight
          userRatingObjectiveId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      fixedRank
      rank
      updatedByIAM
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listGammas = /* GraphQL */ `
  query ListGammas(
    $filter: ModelGammaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGammas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        friendlyId
        levelID
        level {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        levelHistory {
          nextToken
          __typename
        }
        title
        description
        measurementFields {
          measurementFieldID
          value
          __typename
        }
        additionalFields {
          name
          __typename
        }
        rankHistory {
          D
          R
          S
          __typename
        }
        mapping {
          gammaID
          organizationID
          mapping
          metadata
          createdAt
          updatedAt
          __typename
        }
        userID
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departments
        UserVotes {
          nextToken
          __typename
        }
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        ownerEmail
        hiddenUsers {
          nextToken
          __typename
        }
        sponsorID
        sponsor {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        contributors
        linkedTo {
          nextToken
          __typename
        }
        userRatings {
          nextToken
          __typename
        }
        fixedRank
        rank
        updatedByIAM
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const gammasByLevelID = /* GraphQL */ `
  query GammasByLevelID(
    $levelID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelGammaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    gammasByLevelID(
      levelID: $levelID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        friendlyId
        levelID
        level {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        levelHistory {
          nextToken
          __typename
        }
        title
        description
        measurementFields {
          measurementFieldID
          value
          __typename
        }
        additionalFields {
          name
          __typename
        }
        rankHistory {
          D
          R
          S
          __typename
        }
        mapping {
          gammaID
          organizationID
          mapping
          metadata
          createdAt
          updatedAt
          __typename
        }
        userID
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departments
        UserVotes {
          nextToken
          __typename
        }
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        ownerEmail
        hiddenUsers {
          nextToken
          __typename
        }
        sponsorID
        sponsor {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        contributors
        linkedTo {
          nextToken
          __typename
        }
        userRatings {
          nextToken
          __typename
        }
        fixedRank
        rank
        updatedByIAM
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const GammasByUserID = /* GraphQL */ `
  query GammasByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelGammaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    GammasByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        friendlyId
        levelID
        level {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        levelHistory {
          nextToken
          __typename
        }
        title
        description
        measurementFields {
          measurementFieldID
          value
          __typename
        }
        additionalFields {
          name
          __typename
        }
        rankHistory {
          D
          R
          S
          __typename
        }
        mapping {
          gammaID
          organizationID
          mapping
          metadata
          createdAt
          updatedAt
          __typename
        }
        userID
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departments
        UserVotes {
          nextToken
          __typename
        }
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        ownerEmail
        hiddenUsers {
          nextToken
          __typename
        }
        sponsorID
        sponsor {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        contributors
        linkedTo {
          nextToken
          __typename
        }
        userRatings {
          nextToken
          __typename
        }
        fixedRank
        rank
        updatedByIAM
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const gammasByOrganizationID = /* GraphQL */ `
  query GammasByOrganizationID(
    $organizationID: ID!
    $fixedRank: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGammaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    gammasByOrganizationID(
      organizationID: $organizationID
      fixedRank: $fixedRank
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        friendlyId
        levelID
        level {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        levelHistory {
          nextToken
          __typename
        }
        title
        description
        measurementFields {
          measurementFieldID
          value
          __typename
        }
        additionalFields {
          name
          __typename
        }
        rankHistory {
          D
          R
          S
          __typename
        }
        mapping {
          gammaID
          organizationID
          mapping
          metadata
          createdAt
          updatedAt
          __typename
        }
        userID
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departments
        UserVotes {
          nextToken
          __typename
        }
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        ownerEmail
        hiddenUsers {
          nextToken
          __typename
        }
        sponsorID
        sponsor {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        contributors
        linkedTo {
          nextToken
          __typename
        }
        userRatings {
          nextToken
          __typename
        }
        fixedRank
        rank
        updatedByIAM
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const gammasByOrganizationIDAll = /* GraphQL */ `
  query GammasByOrganizationIDAll(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelGammaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    gammasByOrganizationIDAll(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        friendlyId
        levelID
        level {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        levelHistory {
          nextToken
          __typename
        }
        title
        description
        measurementFields {
          measurementFieldID
          value
          __typename
        }
        additionalFields {
          name
          __typename
        }
        rankHistory {
          D
          R
          S
          __typename
        }
        mapping {
          gammaID
          organizationID
          mapping
          metadata
          createdAt
          updatedAt
          __typename
        }
        userID
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departments
        UserVotes {
          nextToken
          __typename
        }
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        ownerEmail
        hiddenUsers {
          nextToken
          __typename
        }
        sponsorID
        sponsor {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        contributors
        linkedTo {
          nextToken
          __typename
        }
        userRatings {
          nextToken
          __typename
        }
        fixedRank
        rank
        updatedByIAM
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const GammasBySponsorID = /* GraphQL */ `
  query GammasBySponsorID(
    $sponsorID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelGammaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    GammasBySponsorID(
      sponsorID: $sponsorID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        friendlyId
        levelID
        level {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        levelHistory {
          nextToken
          __typename
        }
        title
        description
        measurementFields {
          measurementFieldID
          value
          __typename
        }
        additionalFields {
          name
          __typename
        }
        rankHistory {
          D
          R
          S
          __typename
        }
        mapping {
          gammaID
          organizationID
          mapping
          metadata
          createdAt
          updatedAt
          __typename
        }
        userID
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departments
        UserVotes {
          nextToken
          __typename
        }
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        ownerEmail
        hiddenUsers {
          nextToken
          __typename
        }
        sponsorID
        sponsor {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        contributors
        linkedTo {
          nextToken
          __typename
        }
        userRatings {
          nextToken
          __typename
        }
        fixedRank
        rank
        updatedByIAM
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const GammasByFixedRank = /* GraphQL */ `
  query GammasByFixedRank(
    $fixedRank: Int!
    $organizationID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGammaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    GammasByFixedRank(
      fixedRank: $fixedRank
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        friendlyId
        levelID
        level {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        levelHistory {
          nextToken
          __typename
        }
        title
        description
        measurementFields {
          measurementFieldID
          value
          __typename
        }
        additionalFields {
          name
          __typename
        }
        rankHistory {
          D
          R
          S
          __typename
        }
        mapping {
          gammaID
          organizationID
          mapping
          metadata
          createdAt
          updatedAt
          __typename
        }
        userID
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departments
        UserVotes {
          nextToken
          __typename
        }
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        ownerEmail
        hiddenUsers {
          nextToken
          __typename
        }
        sponsorID
        sponsor {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        contributors
        linkedTo {
          nextToken
          __typename
        }
        userRatings {
          nextToken
          __typename
        }
        fixedRank
        rank
        updatedByIAM
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchGammas = /* GraphQL */ `
  query SearchGammas(
    $filter: SearchableGammaFilterInput
    $sort: [SearchableGammaSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableGammaAggregationInput]
  ) {
    searchGammas(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        friendlyId
        levelID
        level {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        levelHistory {
          nextToken
          __typename
        }
        title
        description
        measurementFields {
          measurementFieldID
          value
          __typename
        }
        additionalFields {
          name
          __typename
        }
        rankHistory {
          D
          R
          S
          __typename
        }
        mapping {
          gammaID
          organizationID
          mapping
          metadata
          createdAt
          updatedAt
          __typename
        }
        userID
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departments
        UserVotes {
          nextToken
          __typename
        }
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        ownerEmail
        hiddenUsers {
          nextToken
          __typename
        }
        sponsorID
        sponsor {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        contributors
        linkedTo {
          nextToken
          __typename
        }
        userRatings {
          nextToken
          __typename
        }
        fixedRank
        rank
        updatedByIAM
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getGammaZapierMapping = /* GraphQL */ `
  query GetGammaZapierMapping($gammaID: ID!) {
    getGammaZapierMapping(gammaID: $gammaID) {
      gammaID
      organizationID
      Gamma {
        id
        friendlyId
        levelID
        level {
          id
          name
          active
          level
          updatedByIAM
          organizationID
          createdAt
          updatedAt
          __typename
        }
        levelHistory {
          nextToken
          __typename
        }
        title
        description
        measurementFields {
          measurementFieldID
          value
          __typename
        }
        additionalFields {
          name
          __typename
        }
        rankHistory {
          D
          R
          S
          __typename
        }
        mapping {
          gammaID
          organizationID
          mapping
          metadata
          createdAt
          updatedAt
          __typename
        }
        userID
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departments
        UserVotes {
          nextToken
          __typename
        }
        user {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        ownerEmail
        hiddenUsers {
          nextToken
          __typename
        }
        sponsorID
        sponsor {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        contributors
        linkedTo {
          nextToken
          __typename
        }
        userRatings {
          nextToken
          __typename
        }
        fixedRank
        rank
        updatedByIAM
        createdAt
        updatedAt
        __typename
      }
      mapping
      metadata
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listGammaZapierMappings = /* GraphQL */ `
  query ListGammaZapierMappings(
    $gammaID: ID
    $filter: ModelGammaZapierMappingFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listGammaZapierMappings(
      gammaID: $gammaID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        gammaID
        organizationID
        Gamma {
          id
          friendlyId
          levelID
          title
          description
          userID
          organizationID
          departments
          ownerEmail
          sponsorID
          contributors
          fixedRank
          rank
          updatedByIAM
          createdAt
          updatedAt
          __typename
        }
        mapping
        metadata
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const gammaZapierMappingsByOrganizationID = /* GraphQL */ `
  query GammaZapierMappingsByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelGammaZapierMappingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    gammaZapierMappingsByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        gammaID
        organizationID
        Gamma {
          id
          friendlyId
          levelID
          title
          description
          userID
          organizationID
          departments
          ownerEmail
          sponsorID
          contributors
          fixedRank
          rank
          updatedByIAM
          createdAt
          updatedAt
          __typename
        }
        mapping
        metadata
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const gammaZapierMappingsByMapping = /* GraphQL */ `
  query GammaZapierMappingsByMapping(
    $mapping: String!
    $sortDirection: ModelSortDirection
    $filter: ModelGammaZapierMappingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    gammaZapierMappingsByMapping(
      mapping: $mapping
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        gammaID
        organizationID
        Gamma {
          id
          friendlyId
          levelID
          title
          description
          userID
          organizationID
          departments
          ownerEmail
          sponsorID
          contributors
          fixedRank
          rank
          updatedByIAM
          createdAt
          updatedAt
          __typename
        }
        mapping
        metadata
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      Message
      isRead
      type
      userID
      UserDetails {
        id
        photo
        contact
        email
        gammas {
          nextToken
          __typename
        }
        hiddenGammas {
          nextToken
          __typename
        }
        sponsoring {
          nextToken
          __typename
        }
        firstName
        lastName
        lastLogin
        designation
        status
        role
        weight
        organizationID
        Organization {
          id
          name
          website
          contact
          adminID
          type
          partnerID
          gammaCount
          organizationDefaultPrioirtybatchId
          winningCriteria
          scoreWeightages
          categoryValues
          comparisonFlags
          ratingFlags
          invitationReminderConfig
          inactivityReminderConfig
          scenarioWeightsFlag
          createdAt
          updatedAt
          __typename
        }
        departmentID
        Department {
          id
          name
          organizationID
          managerID
          createdAt
          updatedAt
          __typename
        }
        managerID
        Manager {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        UserVotes {
          nextToken
          __typename
        }
        LoginHistory {
          nextToken
          __typename
        }
        objectives {
          nextToken
          __typename
        }
        UserRatings {
          nextToken
          __typename
        }
        invitationReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        inactivityReminderStatus {
          userID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      metadata
      __typename
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Message
        isRead
        type
        userID
        UserDetails {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        metadata
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const NotificationByUser = /* GraphQL */ `
  query NotificationByUser(
    $userID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    NotificationByUser(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Message
        isRead
        type
        userID
        UserDetails {
          id
          photo
          contact
          email
          firstName
          lastName
          lastLogin
          designation
          status
          role
          weight
          organizationID
          departmentID
          managerID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        metadata
        __typename
      }
      nextToken
      __typename
    }
  }
`;
