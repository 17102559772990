import { Button, Divider, Empty, Skeleton, Typography } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import './pendingItems.less';
import { useSelector } from 'react-redux';
import {
  getCurrentOrganization,
  getCurrnetUserData,
} from '../../store/slices/loginSlice';
import { searchGammasCustom } from '../../utils/Actions';
import { useHistory } from 'react-router-dom';

export default function PendingActions() {
  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const userId = currnetOrg?.adminID || loginUsrOrg?.id;
  const [pendingDetails, setPendingDetails] = useState([]);
  const [loader, setLoader] = useState(false);
  const history = useHistory();

  const pendingActions = async () => {
    setLoader(true);
    try {
      const filter = {
        level: { ne: 'IDEA' },
      };
      const res = await searchGammasCustom(filter);
      setPendingDetails(res?.items);
      setLoader(false);
    } catch (err) {
      setLoader(false);
      console.log(err);
    }
  };
  useEffect(() => {
    pendingActions();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <div className="add-idea plr-0 pt-0">
        <Typography.Title level={5} className="mb-20">
          <ArrowLeftOutlined
            className="mr-10"
            onClick={() => window.history.back()}
          />{' '}
          Back to Home
        </Typography.Title>
        {loader ? (
          <Skeleton.Input
            active
            size="large"
            block="true"
            className="skeleton-h"
          />
        ) : pendingDetails?.length ? (
          pendingDetails.map((item) => {
            if (
              item?.sponserID === userId ||
              item?.contributors?.items
                .map((el) => el.userId === userId)
                .includes(true) ||
              item?.userID === userId
            ) {
              return (
                <div className="background-white p-30 mb-20 pending-item">
                  <Typography.Title level={4}>
                    Your idea has moved to the next level, Please attach
                    additional items.
                  </Typography.Title>

                  <Divider />
                  <Typography.Title level={3}>{item?.title}</Typography.Title>
                  <Typography.Title level={5}>
                    {item?.description}
                  </Typography.Title>
                  <Button
                    type="primary"
                    className="primary-btn mt-10"
                    size="large"
                    onClick={() =>
                      history.push({
                        pathname: '/ranking-detail',
                        state: { data: item, fromPending: true },
                      })
                    }
                  >
                    Add Details
                  </Button>
                </div>
              );
            } else {
              return null;
            }
          })
        ) : (
          <Empty />
        )}
      </div>
    </>
  );
}
