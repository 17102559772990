import { Modal, Typography } from 'antd';
import { ECHOIMAGES } from 'Assets/Images';
import React, { memo } from 'react';

const PublishModal = ({ isPublishModalOpen, stageStatus }) => {
  const { publishIcon } = ECHOIMAGES.admin;

  return (
    <Modal
      open={isPublishModalOpen}
      footer={null}
      closeIcon={false}
      className="publish-icon"
    >
      <div className="text-center">
        <img src={publishIcon} alt="" />
        <Typography.Title level={4}>
          Stage Published Successfully
        </Typography.Title>
        {!stageStatus?.active && (
          <Typography.Text level={3}>
            <a href="/settings" className="click-text">
              Click here
            </a>{' '}
            to enable the stage in comparison and rating
          </Typography.Text>
        )}
      </div>
    </Modal>
  );
};

export default memo(PublishModal);

