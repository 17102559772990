import {
  Button,
  Row,
  Select,
  Input,
  Space,
  Drawer,
  Typography,
  Switch,
  Form,
  Checkbox,
  Divider,
  Radio,
  Collapse,
  Grid,
  Col,
  Dropdown,
  Empty,
  message,
  Menu,
  Tooltip,
} from 'antd';
import { CheckOutlined, DownOutlined, MoreOutlined } from '@ant-design/icons';
import { ECHOIMAGES } from 'Assets/Images';
import React, { useState } from 'react';
import { archiveGoal } from './GoalFunction';
import { useSelector } from 'react-redux';
import {
  getCurrentOrganization,
  getCurrnetUserData,
  isAdmin,
  isLeader,
  isSuperAdmin,
} from 'store/slices/loginSlice';
import moment from 'moment';
import {
  arrToCommaSeperated,
  filterDataByTimeRange,
  sortArrayByDate,
  sortArrayByProperty,
} from 'utils/commonMethods';
import TableSkeleton from 'Common/TableSkeleton';
import { selectAllDepartment } from 'utils/Constants';
import ActiveGoalModal from './Components/ActiveGoalModal';
import FilterButton from 'Common/FilterButton';

export default function ArchivedGoal({
  setArchiveGoals,
  archiveGoals,
  archiveBackuoGoals,
  listGoals,
  loading,
  defaultGoalWeight,
  goalFlag,
  originalData,
  setArchiveCount,
}) {
  const { useBreakpoint } = Grid;
  const { Search } = Input;
  const { Panel } = Collapse;
  const { SortIcon, FilterIcon } = ECHOIMAGES.ideaIcons;
  const screens = useBreakpoint();
  const [goalAddedByOwner, setGoalAddedByOwner] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [ownerFilter, setOwnerFilter] = useState([]);
  const [timeRange, setTimeRange] = useState('');
  const [customRange, setCustomRange] = useState();
  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const userId = currnetOrg?.adminID || loginUsrOrg?.id;
  const isLeaderGroup = useSelector(isLeader);
  const isSuperAdminGroup = useSelector(isSuperAdmin);
  const isAdminGroup = useSelector(isAdmin);
  const [activeModalOpen, setActiveModalOpen] = useState(false);
  const [activeGoalID, setActiveGoalID] = useState();
  const [loader, setLoader] = useState(false);
  const { Text } = Typography;

  // drawer stuff
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const onSearch = (e) => {
    if (e !== '') {
      const temp = archiveBackuoGoals.filter(
        (item) =>
          item?.name && item?.name.toLowerCase().includes(e.toLowerCase())
      );
      setArchiveGoals(temp);
    } else {
      setArchiveGoals(archiveBackuoGoals);
    }
  };

  const showAllData = () => {
    setListLeaderGoal(originalData);
  };

  // sort by oldest date
  const sortByOldest = () => {
    const sortedData = sortArrayByDate(archiveGoals, 'createdAt', true);
    setArchiveGoals(sortedData);
  };

  // sort by newest date
  const sortByNewest = () => {
    const sortedData = sortArrayByDate(archiveGoals, 'createdAt', false);
    setArchiveGoals(sortedData);
  };

  // sort by modifiled date oldest
  const sortByModifiedOldest = () => {
    const sortedData = sortArrayByDate(archiveGoals, 'updatedAt', true);
    setArchiveGoals(sortedData);
  };

  // sort by  modifiled date oldest
  const sortByModifiedNewest = () => {
    const sortedData = sortArrayByDate(archiveGoals, 'updatedAt', false);
    setArchiveGoals(sortedData);
  };
  // for ascending data by title
  const sortAscending = () => {
    const sortedData = sortArrayByProperty(archiveGoals, 'name', true);
    setArchiveGoals(sortedData);
  };

  // for descending data by title
  const sortDescending = () => {
    const sortedData = sortArrayByProperty(archiveGoals, 'name', false);
    setArchiveGoals(sortedData);
  };

  const onFilterApply = () => {
    const temp = [];
    if (goalAddedByOwner) {
      const goalsOwner = archiveBackuoGoals.filter((el) =>
        el?.owner?.id.includes(userId)
      );
      goalsOwner.map((item) => {
        if (ownerFilter.length) {
          const data = ownerFilter.filter((el) =>
            el.includes(item?.owner?.email)
          );
          if (data?.length) {
            temp.push(item);
          }
        } else {
          temp.push(item);
        }
        return temp;
      });
    } else {
      archiveBackuoGoals.map((item) => {
        if (ownerFilter.length) {
          const data = ownerFilter.filter((el) =>
            el.includes(item?.owner?.email)
          );
          if (data?.length) {
            temp.push(item);
          }
        } else {
          temp.push(item);
        }
        return temp;
      });
    }
    const filterDate = filterDataByTimeRange(timeRange, temp, customRange);
    setArchiveCount({
      archiveGoalLength: filterDate?.length,
    });
    setArchiveGoals(filterDate);
  };
  const uniqueFirstNames = [];
  const filteredData = archiveBackuoGoals.filter((item) => {
    if (!uniqueFirstNames.includes(item?.owner?.firstName)) {
      uniqueFirstNames.push(item?.owner?.firstName);
      return true;
    }
    return false;
  });

  const activeObjectives = async () => {
    setLoader(true);
    try {
      let input = {
        id: activeGoalID,
        active: true,
      };
      await archiveGoal(input);
      message.success('Goal Active Successfully');
      setActiveModalOpen(false);
      await listGoals();
      setLoader(false);
    } catch (err) {
      setLoader(false);
      console.log(err);
    }
  };

  return (
    <div className="idea background-white mb-20 p-20">
      <Row
        gutter={[0, 10]}
        justify="space-between"
        align="middle"
        className="idea-header w-100"
      >
        <Search
          className="searchbar-style"
          placeholder="Search goal"
          onChange={(e) => onSearch(e.target.value)}
          id="goal-archive-search-input"
        />

        <Row gutter={[0, 10]} align="middle">
          <Typography.Text>Sort by</Typography.Text>
          <Select
            placeholder="Select sort"
            className="h-35 sorting-common ml-10"
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            defaultValue="None"
            options={[
              {
                value: 'None',
                label: (
                  <Space
                    onClick={showAllData}
                    className="goal-archive-sort-none"
                  >
                    None
                  </Space>
                ),
              },
              {
                value: 'Created date - Newest',
                label: (
                  <Space
                    onClick={sortByNewest}
                    className="goal-archive-sort-created-date-newest"
                  >
                    Created date - Newest
                  </Space>
                ),
              },
              {
                value: 'Created date - Oldest',
                label: (
                  <Space
                    onClick={sortByOldest}
                    className="goal-archive-sort-created-date-oldest"
                  >
                    Created date - Oldest
                  </Space>
                ),
              },
              {
                value: 'Modified date - Newest',
                label: (
                  <Space
                    onClick={sortByModifiedNewest}
                    className="goal-archive-sort-modified-date-newest"
                  >
                    Modified date - Newest
                  </Space>
                ),
              },
              {
                value: 'Modified date - Oldest',
                label: (
                  <Space
                    onClick={sortByModifiedOldest}
                    className="goal-archive-sort-modified-date-oldest"
                  >
                    Modified date - Oldest
                  </Space>
                ),
              },
              {
                value: 'Title - A to Z',
                label: (
                  <Space
                    onClick={sortAscending}
                    className="goal-archive-sort-title-AtoZ"
                  >
                    Title - A to Z
                  </Space>
                ),
              },
              {
                value: 'Title - Z to A',
                label: (
                  <Space
                    onClick={sortDescending}
                    className="goal-archive-sort-title-ZtoA"
                  >
                    Title - Z to A
                  </Space>
                ),
              },
            ]}
          />
          <FilterButton
            onClick={showDrawer}
            className="goal-archive-filter-btn"
          />
        </Row>
      </Row>
      {archiveGoals?.length ? (
        <Collapse
          ghost
          accordion
          expandIcon={({ isActive }) => (
            <Typography.Title level={4} className="mb-0 fs-16 fw-500">
              {screens.xs ? '' : isActive ? 'View less' : 'View more'}
              <DownOutlined className="ml-10" rotate={isActive ? 180 : 0} />
            </Typography.Title>
          )}
          expandIconPosition="end"
          className="archive-goal goal mt-30"
        >
          {archiveGoals.map((item, index) => {
            return (
              <Panel
                className="mb-20"
                key={index}
                extra={
                  <div>
                    {(isSuperAdminGroup ||
                      isAdminGroup ||
                      currnetOrg?.id(
                        isLeaderGroup && item?.ownerId === userId
                      )) && (
                      <Row className="goal-weight">
                        {goalFlag && (
                          <Typography.Title
                            level={5}
                            className="mb-0  fw-500 mr-20 fs-14"
                          >
                            {defaultGoalWeight?.filter(
                              (e) => e?.ObjectiveID === item?.id
                            )?.[0] ? (
                              <>
                                Goal weight :{' '}
                                <span className="text-primary fw-bolder fs-14">
                                  {
                                    defaultGoalWeight?.filter(
                                      (e) => e?.ObjectiveID === item?.id
                                    )?.[0]?.weight
                                  }{' '}
                                  %
                                </span>
                              </>
                            ) : null}
                          </Typography.Title>
                        )}
                        <Dropdown
                          arrow
                          overlay={
                            <Menu>
                              <Menu.Item key="2">
                                <Button
                                  type="text"
                                  className="w-100 p-0 text-left hover-off"
                                  onClick={() => {
                                    setActiveModalOpen(true);
                                    setActiveGoalID(item?.id);
                                  }}
                                >
                                  Active Goal
                                </Button>
                              </Menu.Item>
                            </Menu>
                          }
                        >
                          <Button
                            type="text"
                            onClick={(e) => e.preventDefault()}
                            className="goal-archive-menu"
                          >
                            <MoreOutlined />
                          </Button>
                        </Dropdown>
                      </Row>
                    )}
                  </div>
                }
                header={
                  <div>
                    <Typography.Title
                      level={5}
                      className="fw-bolder fs-18"
                      id="goal-active-name"
                    >
                      {item?.name}
                    </Typography.Title>
                    <Row className="head-data mt-20" gutter={[0, 20]}>
                      <Col span={6} lg={6} md={8} sm={24} xs={24}>
                        <Typography.Title
                          level={5}
                          className="mb-0 title fs-14"
                          data-archive-goal-created-at={item?.createdAt}
                        >
                          Created date :
                        </Typography.Title>
                        <Typography.Title level={5} className="mb-0 mt-0 data">
                          {moment(item?.createdAt).format('l')}
                        </Typography.Title>
                      </Col>
                      <Col span={6} lg={6} md={8} sm={24} xs={24}>
                        <Typography.Title
                          level={5}
                          className="mb-0 title fs-14"
                          data-archive-updated-at={item?.updatedAt}
                        >
                          Last modified date :
                        </Typography.Title>
                        <Typography.Title
                          level={5}
                          className="mb-0 mt-0 data fs-14"
                        >
                          {moment(item?.updatedAt).format('l')}
                        </Typography.Title>
                      </Col>
                      <Col span={6} lg={6} md={8} sm={24} xs={24}>
                        <Typography.Title
                          level={5}
                          className="mb-0 title fs-14"
                          data-archive-goal-owner={
                            item?.owner?.firstName
                              ? item?.owner?.firstName +
                                ' ' +
                                item?.owner?.lastName
                              : '-'
                          }
                        >
                          Owner :
                        </Typography.Title>
                        <Typography.Title
                          level={5}
                          className="mb-0 mt-0 data fs-14"
                        >
                          <Text
                            style={
                              true
                                ? {
                                    width: 150,
                                  }
                                : undefined
                            }
                            ellipsis={
                              true
                                ? {
                                    tooltip: (
                                      <span>
                                        {' '}
                                        {item?.owner?.firstName
                                          ? item?.owner?.firstName +
                                            ' ' +
                                            item?.owner?.lastName
                                          : '-'}
                                      </span>
                                    ),
                                  }
                                : false
                            }
                            className="fs-14"
                          >
                            {item?.owner?.firstName
                              ? item?.owner?.firstName +
                                ' ' +
                                item?.owner?.lastName
                              : '-'}
                          </Text>
                        </Typography.Title>
                      </Col>
                      <Col span={6} lg={6} md={8} sm={24} xs={24}>
                        <Typography.Title
                          level={5}
                          className="mb-0 title fs-14"
                        >
                          Department :
                        </Typography.Title>
                        <Typography.Title
                          level={5}
                          className="mb-0 mt-0 data fs-14"
                        >
                          {' '}
                          <Text
                            style={
                              true
                                ? {
                                    width: 120,
                                  }
                                : undefined
                            }
                            ellipsis={
                              true
                                ? {
                                    tooltip: (
                                      <span>
                                        {' '}
                                        {item?.Departments?.items?.length
                                          ? arrToCommaSeperated(
                                              item?.Departments?.items?.map(
                                                (el) => {
                                                  return el?.departmentID ===
                                                    selectAllDepartment
                                                    ? 'All'
                                                    : el?.department?.name;
                                                }
                                              )
                                            )
                                          : '-'}
                                      </span>
                                    ),
                                  }
                                : false
                            }
                            className="fs-14"
                          >
                            {item?.Departments?.items?.length
                              ? arrToCommaSeperated(
                                  item?.Departments?.items?.map((el) => {
                                    return el?.departmentID ===
                                      selectAllDepartment
                                      ? 'All'
                                      : el?.department?.name;
                                  })
                                )
                              : '-'}
                          </Text>
                        </Typography.Title>
                      </Col>
                    </Row>
                  </div>
                }
              >
                <p
                  className="goal-details fs-16"
                  data-archive-goal-description={item?.description}
                >
                  {item?.description}
                </p>
              </Panel>
            );
          })}
        </Collapse>
      ) : loading ? (
        <TableSkeleton />
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}

      <Drawer
        className="related-item-drawer brawer-with-btn"
        title={
          <Row justify="space-between" align="middle">
            <Typography.Title level={3} className="mb-0 ">
              Filters
            </Typography.Title>
            <Button
              type="text"
              className="text-blue fs-14 p-0 goal-archive-filter-clearall"
              onClick={() => {
                setOwnerFilter([]);
                setTimeRange('');
                setSelectAll(false);
              }}
            >
              <strong>Clear All</strong>
            </Button>
          </Row>
        }
        closeIcon={null}
        closable={false}
        height={screens.xs ? '83vh' : ''}
        placement={screens.xs ? 'bottom' : 'right'}
        onClose={onClose}
        open={open}
      >
        <div>
          <Row justify="space-between" align="middle">
            <Typography.Title
              level={5}
              className="mb-0 fw-400 fs-14 text-black"
            >
              Goals added by me
            </Typography.Title>
            <Switch
              checked={goalAddedByOwner}
              onChange={(e) => setGoalAddedByOwner(e)}
              size="small"
              id="goal-archive-addedbyme"
            />
          </Row>

          <Form className="mt-20">
            <div className="mb-20">
              <Row justify="space-between" align="middle">
                <Typography.Title level={5} className="mb-0 text-primary">
                  Owner
                </Typography.Title>
                <Checkbox
                  className="select-all goal-archive-select-all"
                  checked={selectAll}
                  onChange={(e) => {
                    setOwnerFilter(
                      e.target.checked
                        ? filteredData.map((data) => data?.owner?.email)
                        : []
                    );
                    setSelectAll(e.target.checked);
                  }}
                >
                  Select All
                </Checkbox>
              </Row>
              <Divider className="mt-10 mb-10" />

              <Checkbox.Group
                className="vertical-box"
                onChange={(e) => setOwnerFilter(e)}
                value={ownerFilter}
              >
                {filteredData.map((item, index) => {
                  return (
                    <Checkbox
                      key={index}
                      value={item?.owner?.email}
                      id={`goal-archive-filter-owner${item?.owner?.email}`}
                    >
                      {item?.owner?.firstName + ' ' + item?.owner?.lastName}
                    </Checkbox>
                  );
                })}
              </Checkbox.Group>
            </div>

            <div className="mb-20">
              <Row justify="space-between" align="middle">
                <Typography.Title level={5} className="mb-0 text-primary">
                  Created date
                </Typography.Title>
              </Row>
              <Divider className="mt-10 mb-10" />

              <Radio.Group className="vertical-box">
                <Radio
                  value="today"
                  checked={timeRange === 'today'}
                  onChange={() => setTimeRange('today')}
                  id="goal-archive-filter-today"
                >
                  Today
                </Radio>
                <Radio
                  value="last7days"
                  checked={timeRange === 'last7days'}
                  onChange={() => setTimeRange('last7days')}
                  id="goal-archive-filter-last7days"
                >
                  Last 7 days
                </Radio>
                <Radio
                  value="lastWeek"
                  checked={timeRange === 'lastWeek'}
                  onChange={() => setTimeRange('lastWeek')}
                  id="goal-archive-filter-lastweek"
                >
                  Last week
                </Radio>
                <Radio
                  value="thisMonth"
                  checked={timeRange === 'thisMonth'}
                  onChange={() => setTimeRange('thisMonth')}
                  id="goal-archive-filter-thismonth"
                >
                  This month
                </Radio>
                <Radio
                  value="lastMonth"
                  checked={timeRange === 'lastMonth'}
                  onChange={() => setTimeRange('lastMonth')}
                  id="goal-archive-filter-lastmonth"
                >
                  Last month
                </Radio>
                <Radio
                  value="customRange"
                  checked={timeRange === 'customRange'}
                  onChange={() => setTimeRange('customRange')}
                  id="goal-archive-filter-customrange"
                >
                  Custom range
                </Radio>
                {timeRange === 'customRange' && (
                  <RangePicker onChange={(e) => setCustomRange(e)} />
                )}
              </Radio.Group>
            </div>
          </Form>
        </div>

        <div className="drawer-btn">
          <Button
            className="drawer-btn-child goal-archive-filter-cancel"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            className="drawer-btn-child goal-archive-filter-apply"
            htmlType="submit"
            onClick={onFilterApply}
          >
            Apply
          </Button>
        </div>
      </Drawer>
      <ActiveGoalModal
        activeModalOpen={activeModalOpen}
        setActiveModalOpen={setActiveModalOpen}
        activeObjectives={activeObjectives}
        loader={loader}
      />
    </div>
  );
}


