import { Divider, Modal, Tabs, Typography } from 'antd';
import { FetchUser } from 'Pages/AllPriorities/IdeaFunction';
import React, { memo, useEffect, useState } from 'react';
import { userStatus } from 'utils/Constants';
import { RESTGetAuthorization, RESTGetCount } from 'utils/RESTApi';
import CreateFilter from './CreateFilter';
import IdeaFilter from './IdeaFilter';
import { v4 as uuidv4 } from 'uuid';
import { listAllStages } from 'Pages/Stages/Services/StageActions';
import { useSelector } from 'react-redux';
import {
  getCurrentOrganization,
  getCurrnetUserData,
} from 'store/slices/loginSlice';
import { useParams } from 'react-router-dom';

const FilterTab = ({
  setFilterModalOpen,
  filterModalOpen,
  setAppliedFilters,
  listAllDepartments,
  savedFilters,
  appliedFilters,
  setAddFilter,
  addFilter,
  setSaveRankingFilter,
  savedRankingFilter,
  saveRankingFilter,
  setWhoList,
  whoList,
  whoOptionsObj,
  setIdeaList,
  ideaList,
  ideaOptionsObj,
  setSelectedValue,
  selectedValue,
  ideaMatch,
  setIdeaMatch,
  setRankingCount,
}) => {
  const [ideaFilterList, setIdeaFilterList] = useState([]);
  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;
  const userId = currnetOrg?.adminID || loginUsrOrg?.id;
  const { id } = useParams();
  function validateWhoOptions(whoOptionsObj) {
    // Check if departmentToInclude is either null or a valid value

    if (
      (whoOptionsObj.departmentToInclude !== null &&
        whoOptionsObj.departmentValue.length) ||
      (whoOptionsObj.userRoleToInclude !== null &&
        whoOptionsObj.userRoleValue.length) ||
      (whoOptionsObj.usersToInclude !== null && whoOptionsObj.usersValue.length)
    ) {
      return true;
    }

    if (whoOptionsObj.departmentToInclude === null) {
      return false;
    }

    // Check if departmentValue is an array
    if (!whoOptionsObj.departmentValue.length) {
      return false;
    }

    // Check if userRoleToInclude is either null or a valid value
    if (whoOptionsObj.userRoleToInclude === null) {
      return false;
    }

    // Check if userRoleValue is an array
    if (!whoOptionsObj.userRoleValue.length) {
      return false;
    }

    // Check if usersToInclude is either null or a valid value
    if (whoOptionsObj.usersToInclude === null) {
      return false;
    }

    // Check if usersValue is an array
    if (!whoOptionsObj.usersValue.length) {
      return false;
    }

    // All validations passed, return true
    return true;
  }

  function validateIdeaOptions(ideaOptionsObj) {
    // Check if mfname is either null or a valid value
    if (ideaOptionsObj.mfname !== null || ideaOptionsObj.category !== null) {
      return true;
    }

    if (ideaOptionsObj.category !== null) {
      // Check if category is either null or a valid value
      return true;
    }

    // Check if lowvalue is either null or a valid value
    if (ideaOptionsObj.lowvalue !== null) {
      return true;
    }

    // Check if extraField is an array
    if (ideaOptionsObj.extraField?.length) {
      return true;
    }

    // Check if valueTest is either null or a valid value
    if (ideaOptionsObj.valueTest !== null) {
      return true;
    }

    // Check if highvalue is either null or a valid value
    if (ideaOptionsObj.highvalue !== null) {
      return true;
    }

    // Check if highscalevalue is either null or a valid value
    if (ideaOptionsObj.highscalevalue !== null) {
      return true;
    }

    // No valid value found, return false
    return false;
  }

  // Usage example:

  const handleSubmitFilters = () => {
    let tempApplyFilter = Array.isArray(appliedFilters)
      ? [...appliedFilters]
      : [];
    whoList.forEach((item) => {
      let result = {};
      //department filter

      let weightTemp = [];
      Object.entries(item?.usersandWeight).map(([key, value]) => {
        if (item?.usersValue.includes(key)) {
          weightTemp.push(value);
        }
      });

      let usersRoleWeightTemp = [];
      Object.entries(item?.userRoleandWeight).map(([key, value]) => {
        if (item?.userRoleValue.includes(key)) {
          usersRoleWeightTemp.push(value);
        }
      });
      // user filter
      let checkValid = validateWhoOptions(item);
      if (checkValid) {
        result['departmentFilters'] = {
          included:
            item.departmentValue?.length > 0 && item.departmentToInclude
              ? true
              : false,
          departmentID: item.departmentValue,
          field:
            item.departmentValue &&
            item.departmentValue?.reduce((acc, curr) => {
              acc[curr] = listAllDepartments?.find((e) => curr.includes(e?.id));
              return acc;
            }, {}),
          weight: [''],
        };

        (result['userFilters'] = {
          included:
            item?.usersValue?.length && item.usersToInclude === 'to include'
              ? true
              : false,
          userID: item.usersValue || [],
          weight: weightTemp,
        }),
          //  user role filter

          (result['userRoleFilters'] = {
            included:
              item.userRoleValue && item.userRoleToInclude === 'to include'
                ? true
                : false,
            userROLE: item.userRoleValue || [],
            weight: usersRoleWeightTemp,
          }),
          tempApplyFilter.push({ id: uuidv4(), ...result });
      }
    });
    setAppliedFilters(tempApplyFilter);
  };

  const getMFList = async () => {
    try {
      let listApi = `/measurement/filters?organization_id=${orgId}`;
      let res = await RESTGetAuthorization(listApi);
      setIdeaFilterList(res?.body);
    } catch (err) {
      console.log(err);
    }
  };

  const [userData, setUserData] = useState([]);
  // get all users by org
  const getAllUsers = async () => {
    try {
      let data = {
        and: [
          { status: { ne: userStatus.invited } },
          { status: { ne: userStatus.inactive } },
        ],
      };
      let id = { organizationID: orgId };
      let nextToken = null;
      let allUserData = [];
      while (true) {
        let res = await FetchUser(data, id, nextToken);
        allUserData.push(...res.items);
        if (!res?.nextToken) {
          break;
        }
        nextToken = res?.nextToken;
      }
      await processUserData(allUserData);
    } catch (err) {
      console.log(err);
    }
  };
  const processUserData = async (userData) => {
    setUserData(userData);
  };

  const [listStage, setListStage] = useState([]);
  const listAllStagesByOrg = async () => {
    try {
      let response = await listAllStages({ organizationID: orgId });
      let sortedLevel = response.items.sort((a, b) => a.level - b.level);
      setListStage(sortedLevel);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getMFList();
    getAllUsers();
    listAllStagesByOrg();
    // eslint-disable-next-line
  }, []);

  const handleSubmitIdeaFilter = () => {
    let tempArray = Array.isArray(saveRankingFilter)
      ? [...saveRankingFilter]
      : [];
    ideaList.forEach((item) => {
      let result = {};

      const filteredStageData = item?.extraField?.reduce((acc, item) => {
        acc[item] = {
          id: listStage.filter((e) => item === e?.id)?.[0]?.id,
          level: listStage.filter((e) => item === e?.id)?.[0]?.level,
          name: listStage.filter((e) => item === e?.id)?.[0]?.name,
        };
        return acc;
      }, {});

      const filteredUserData = item?.extraField?.reduce((acc, item) => {
        acc[item] = {
          id: userData?.filter((e) => e?.id === item)?.[0]?.id,
          firstName: userData?.filter((e) => e?.id === item)?.[0]?.firstName,
          lastName: userData?.filter((e) => e?.id === item)?.[0]?.lastName,
        };
        return acc;
      }, {});

      const filteredDepartmentData = item?.extraField?.reduce((acc, item) => {
        acc[item] = {
          id: listAllDepartments?.filter((e) => e?.id === item)?.[0]?.id,
          name: listAllDepartments?.filter((e) => e?.id === item)?.[0]?.name,
        };
        return acc;
      }, {});
      if (
        item?.lowvalue !== null ||
        item?.highvalue !== null ||
        item?.valueTest !== null ||
        item?.highscalevalue !== null ||
        item?.extraField?.length
      ) {
        let checkValid = validateIdeaOptions(item);
        if (checkValid) {
          result = {
            id: item?.mfname,
            filter: {
              [item?.category]: (() => {
                if (
                  item?.category === 'is between' ||
                  item?.category === 'is not between'
                ) {
                  if (item?.lowvalue !== null && item?.highvalue !== null) {
                    return [item?.lowvalue, item?.highvalue];
                  } else {
                    return [item?.valueTest, item?.highscalevalue];
                  }
                } else if (item?.lowvalue) {
                  return item?.lowvalue;
                } else if (item?.extraField?.length > 0) {
                  return item?.extraField;
                } else {
                  return item?.valueTest;
                }
              })(),
            },
            threshold: item?.threshold,
            blanks: item?.includeBlanks,
            field:
              item?.mfname?.split(':')?.[0] ===
                process.env.REACT_APP_SPONSOR_ID ||
              item?.mfname?.split(':')?.[0] ===
                process.env.REACT_APP_CONTRIBUTOR_ID
                ? {
                    ...filteredUserData,
                    name: ideaFilterList?.items?.filter(
                      (e) => e?.id === item?.mfname
                    )?.[0]?.name,
                    description: ideaFilterList?.items?.filter(
                      (e) => e?.id === item?.mfname
                    )?.[0]?.description,
                  }
                : item?.mfname?.split(':')?.[0] ===
                  process.env.REACT_APP_STAGE_ID
                ? {
                    ...filteredStageData,
                    name: ideaFilterList?.items?.filter(
                      (e) => e?.id === item?.mfname
                    )?.[0]?.name,
                    description: ideaFilterList?.items?.filter(
                      (e) => e?.id === item?.mfname
                    )?.[0]?.description,
                  }
                : item?.mfname?.split(':')?.[0] ===
                  process.env.REACT_APP_DEPARTMENT_ID
                ? {
                    ...filteredDepartmentData,
                    name: ideaFilterList?.items?.filter(
                      (e) => e?.id === item?.mfname
                    )?.[0]?.name,
                  }
                : ideaFilterList?.items?.filter(
                    (e) => e?.id === item?.mfname
                  )?.[0],
          };

          tempArray.push({ uniqueID: uuidv4(), ...result });
        }
      }
    });
    setSaveRankingFilter(tempArray);
    getRankingFilter(tempArray);
    setFilterModalOpen(false);
  };

  const getRankingFilter = async (rankingFilter) => {
    try {
      let inputData = {
        userID: userId,
        scenario: {
          organizationID: orgId,
          id: id,
          rankingFilters: {
            match: ideaMatch,
            filters: rankingFilter || [],
          },
        },
      };
      let res = await RESTGetCount(inputData);
      setRankingCount(res?.body);
    } catch (err) {
      console.log(err);
    }
  };

  const items = [
    {
      key: '1',
      label: 'Who',
      children: (
        <CreateFilter
          setAppliedFilters={setAppliedFilters}
          listAllDepartments={listAllDepartments}
          savedFilters={savedFilters}
          appliedFilters={appliedFilters}
          addFilter={addFilter}
          setAddFilter={setAddFilter}
          setFilterModalOpen={setFilterModalOpen}
          filterModalOpen={filterModalOpen}
          setWhoList={setWhoList}
          whoList={whoList}
          whoOptionsObj={whoOptionsObj}
          setSelectedValue={setSelectedValue}
          selectedValue={selectedValue}
        />
      ),
    },
    {
      key: '2',
      label: 'Ideas',
      children: (
        <IdeaFilter
          setSaveRankingFilter={setSaveRankingFilter}
          setFilterModalOpen={setFilterModalOpen}
          savedRankingFilter={savedRankingFilter}
          saveRankingFilter={saveRankingFilter}
          setIdeaList={setIdeaList}
          ideaList={ideaList}
          ideaOptionsObj={ideaOptionsObj}
          setIdeaMatch={setIdeaMatch}
          ideaMatch={ideaMatch}
        />
      ),
    },
  ];

  return (
    <Modal
      open={filterModalOpen}
      className="scenario-filter-modal"
      closable={false}
      width={1100}
      onOk={() => {
        handleSubmitFilters();
        handleSubmitIdeaFilter();
        setFilterModalOpen(false);
        setWhoList([whoOptionsObj]);
        setIdeaList([ideaOptionsObj]);
      }}
      onCancel={() => {
        setFilterModalOpen(false);
      }}
    >
      <Typography.Title level={4} className="scenario-title">
        Scenario Filters
      </Typography.Title>
      <Divider />
      <Tabs defaultActiveKey={1} items={items} />
    </Modal>
  );
};

export default memo(FilterTab);

