/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "StrAlignPublic",
            "endpoint": "https://wfriunjzol.execute-api.us-east-1.amazonaws.com/live",
            "region": "us-east-1"
        },
        {
            "name": "StrAlignRest",
            "endpoint": "https://d7a30wd1sk.execute-api.us-east-1.amazonaws.com/live",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://lg2pmn52afhlzaztt5bu3w2wdy.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-1:d18659bf-d6a5-4044-a925-297e32b8c9a2",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_jMzhcrHYW",
    "aws_user_pools_web_client_id": "5qk4d5vhd34o7fgfeppk6vj9n2",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "trlign-20230228152142-hostingbucket-live",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d2mexu6wy0ukme.cloudfront.net",
    "aws_user_files_s3_bucket": "stralign-uploads114859-live",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
