import {
  Button,
  Col,
  Divider,
  Radio,
  Row,
  Tooltip,
  Typography,
  Tag,
  Drawer,
  Skeleton,
  Empty,
  Grid,
} from 'antd';
import React, { useEffect, useState } from 'react';
import {
  QuestionCircleOutlined,
  CheckCircleFilled,
  DownOutlined,
} from '@ant-design/icons';
import './compare.less';
// import CompareFinish from './CompareFinish';
import { API } from 'aws-amplify';
import { useSelector, useDispatch } from 'react-redux';
import {
  getCompareTour,
  getCurrentOrganization,
  getCurrnetUserData,
  getHomeTour,
  isSME,
  setCompareTour,
} from '../../store/slices/loginSlice';
import moment from 'moment';
import { levelColors } from 'utils/Constants';
import {
  RESTCompare,
  RESTGet,
  RESTGetAuthorization,
  RESTGetRanking,
} from 'utils/RESTApi';
import KudosModal from './Components/KudosModal';
import {
  getComparisonCall,
  getDefaultBatchID,
  getModalTrue,
  getTotalCompare,
  getTotalSeconds,
  setCompareAdd,
  setComparisonCall,
  setTotalCompare,
  setTotalSeconds,
} from '../../store/slices/compareSlice';
import Joyride from 'react-joyride';

export default function Compare() {
  // Radio button stuff
  const [value, setValue] = useState('');
  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;
  const userId = currnetOrg?.adminID || loginUsrOrg?.id;
  const userWeight = currnetOrg?.Admin?.weight || loginUsrOrg?.weight;
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const [compareData, setCompareData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [skipLoader, setSkipLoader] = useState(false);
  const [loading, setLoading] = useState(true);
  const [formLayoutByGamma, setFormLayoutByGamma] = useState([]);
  const [viewMoreLoader, setViewMoreLoader] = useState(false);
  const [animate, setAnimate] = useState({
    gamma1: false,
    gamma2: false,
  });
  const [selectGammaAnimate, setSelectGammaAnimate] = useState({
    gamma1: false,
    gamma2: false,
  });
  const [shouldGoalAnimate, setShouldGoalAnimate] = useState(false);
  const [objectiveID, setObjectiveID] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [errorMessage, setErrorMessage] = useState();

  const currentItem = compareData !== null && compareData[currentIndex];

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isKudosModal, setIsKudosModal] = useState(false);

  const [isTimerPaused, setIsTimerPaused] = useState(false);
  const [gamma1Data, setGamma1Data] = useState([]);
  const [gamma2Data, setGamma2Data] = useState([]);
  //for each compareTime
  const [compareMinutes, setCompareMinutes] = useState(0);
  const [compareSeconds, setcompareSeconds] = useState(0);

  const [runTour, setRunTour] = useState(false);
  const [joyrideStepIndex, setJoyrideStepIndex] = useState(0);

  const dispatch = useDispatch();
  const getAlreadyModalTrue = useSelector(getModalTrue);
  const comparisonCall = useSelector(getComparisonCall);
  const isSMEGroup = useSelector(isSME);
  const startFirstTimeTour = useSelector(getHomeTour);
  const startCompareTour = useSelector(getCompareTour);

  // Function to reset the timer
  const resetTimer = () => {
    setCompareMinutes(0);
    setcompareSeconds(0);
  };

  const handleOpenKudosModal = () => {
    setIsKudosModal(true);
  };

  const priorityDefaultBatchID =
    currnetOrg?.defaultPrioirtybatch?.id ||
    loginUsrOrg?.Organization?.organizationDefaultPrioirtybatchId;

  const fetchDefaultBatchID = useSelector(getDefaultBatchID);

  const defaultPriorityBatchID = fetchDefaultBatchID
    ? fetchDefaultBatchID
    : priorityDefaultBatchID;

  const getTotalComparison = useSelector(getTotalCompare);

  //Function to fetch next comparison based on last node
  const fetchNextSetComparison = async (e) => {
    if (currentItem?.gamma1?.id && currentItem?.gamma2?.id) {
      // eslint-disable-next-line max-len
      const path = `/compare?startWithGammaId1=${currentItem?.gamma1?.id}&objectiveId=${currentItem?.objective?.id}&organizationId=${orgId}&limit=10&userId=${userId}&lastComparisonId=${currentItem?.id}`;
      const apiName = 'StrAlignRest';
      const res = await RESTGet(path);
      setGamma1Data(res?.body);

      // eslint-disable-next-line max-len
      const path1 = `/compare?startWithGammaId2=${currentItem?.gamma2?.id}&objectiveId=${currentItem?.objective?.id}&organizationId=${orgId}&limit=10&userId=${userId}&lastComparisonId=${currentItem?.id}`;
      const apiName1 = 'StrAlignRest';
      const res1 = await RESTGet(path1);
      setGamma2Data(res1?.body);
      if (
        e.target.value === res?.body?.[0]?.gamma1?.id ||
        currentItem?.gamma1?.id !== res?.body?.[0]?.gamma1?.id
      ) {
        setCompareData(res?.body);
      } else if (
        e.target.value === res1?.body?.[0]?.gamma2?.id ||
        currentItem?.gamma2?.id !== res?.body?.[0]?.gamma2?.id
      ) {
        setCompareData(res1?.body);
      }
      if (!res1?.body || !res?.body) {
        await fetchCompare();
      }
      setLoading(false);
    } else {
      await fetchCompare();
    }
  };

  const onChange = async (e) => {
    setLoader(true);
    setAnimate({
      gamma1: false,
      gamma2: false,
    });
    setValue(e.target.value);
    // Calculate the new index based on the selected gamma
    const currentIndex1 = currentIndex;
    const isGamma1Selected = e.target.value === currentItem?.gamma1?.id;
    const isGamma2Selected = e.target.value === currentItem?.gamma2?.id;
    let newIndex;
    if (isGamma1Selected) {
      newIndex = 2 * currentIndex1 + 1;
      setAnimate({
        gamma1: false,
        gamma2: true,
      });
      setSelectGammaAnimate({
        gamma1: true,
        gamma2: false,
      });
    } else if (isGamma2Selected) {
      newIndex = 2 * currentIndex1 + 2;
      setAnimate({
        gamma1: true,
        gamma2: false,
      });
      setSelectGammaAnimate({
        gamma1: false,
        gamma2: true,
      });
    } else {
      newIndex = currentIndex1;
      animateGamma = '';
    }
    const body = {
      objective_id: currentItem?.objective?.id,
      comparison_id: currentItem?.id,
      organizationId: orgId,
      user_id: userId,
      Gamma1: {
        gammaId: currentItem?.gamma1?.id,
        selected: e.target.value === currentItem?.gamma1?.id ? true : false,
        skipped: false,
        user_support: userWeight,
        voteTime: compareMinutes * 60 + compareSeconds,
      },
      Gamma2: {
        gammaId: currentItem?.gamma2?.id,
        selected: e.target.value === currentItem?.gamma2?.id ? true : false,
        skipped: false,
        user_support: userWeight,
        voteTime: compareMinutes * 60 + compareSeconds,
      },
    };
    setSelectedIndex(selectedIndex + 1);
    dispatch(setTotalCompare(getTotalComparison + 1));
    setCurrentIndex(newIndex);
    setObjectiveID(currentItem?.objective?.id);
    const apiName = 'StrAlignRest';
    const path = '/compare';
    await RESTCompare(body);
    // await API.post(apiName, path, { body });
    resetTimer();
    dispatch(setCompareAdd(true));
    setAnimate({
      gamma1: false,
      gamma2: false,
    });
    setSelectGammaAnimate({
      gamma1: false,
      gamma2: false,
    });
    setValue('');

    if (
      compareData?.length > 0 &&
      (!compareData?.[2 * currentIndex + 2] ||
        !compareData?.[2 * currentIndex + 3])
    ) {
      newIndex = 0;
      setLoading(true);
      await fetchNextSetComparison(e);
    }
    setCurrentIndex(newIndex);
    if (
      gamma1Data === null ||
      gamma2Data === null ||
      selectedIndex + 1 === 10 ||
      currentItem === null
    ) {
      await fetchCompare();
    }
    if (selectedIndex + 1 === 10) {
      setIsKudosModal(true);
      handleOpenKudosModal();
      setIsTimerPaused(true);
    }

    setLoader(false);
    setJoyrideStepIndex(1);
  };

  const onSkip = async () => {
    setSkipLoader(true);
    setAnimate({
      gamma1: false,
      gamma2: false,
    });
    const body = {
      objective_id: currentItem?.objective?.id,
      comparison_id: currentItem?.id,
      organizationId: orgId,
      Gamma1: {
        gammaId: currentItem?.gamma1?.id,
        selected: false,
        skipped: true,
        user_support: userWeight,
      },
      Gamma2: {
        gammaId: currentItem?.gamma2?.id,
        selected: false,
        skipped: true,
        user_support: userWeight,
      },
    };
    const apiName = 'StrAlignRest';
    const path = '/compare';
    await RESTCompare(body);
    // await API.post(apiName, path, { body });
    if (compareData[currentIndex + 1] === null) {
      fetchCompare();
    }
    setCurrentIndex(currentIndex + 1);
    if (compareData?.length === currentIndex + 1) {
      await fetchCompare();
    }
    setSkipLoader(false);
    setAnimate({
      gamma1: true,
      gamma2: true,
    });
    setRunTour(false);
    dispatch(setCompareTour(false));
    if (startFirstTimeTour) {
      localStorage.setItem('firstTimeinCompare', true);
    }
  };

  // drawer stuff
  const [open, setOpen] = useState(false);
  const onClose = () => {
    setOpen(false);
  };
  // fetch gammas for compare 2 gammas
  const fetchCompare = async (gID) => {
    setLoading(true);
    setAnimate({
      gamma1: false,
      gamma2: false,
    });
    dispatch(setCompareAdd(false));
    try {
      const gid = gID || value;
      const path = `/compare?organizationId=${orgId}&limit=10&userId=${userId}`;
      const apiName = 'StrAlignRest';
      const res = await RESTGet(path);
      setValue('');
      setCompareData(res?.body);
      setGamma1Data(res?.body);
      setGamma2Data(res?.body);
      setLoading(false);
      setAnimate({
        gamma1: true,
        gamma2: true,
      });
      setCurrentIndex(0);

      if ((res?.body === null || res?.body?.length === 0) && comparisonCall) {
        setLoading(false);
        setTimeout(() => {
          setAnimate({
            gamma1: false,
            gamma2: false,
          });
          fetchCompare(gid);
        }, 5000);
        dispatch(setComparisonCall(false));
      }
      setTimeout(() => {
        setAnimate({
          gamma1: false,
          gamma2: false,
        });
      }, 2000);
    } catch (err) {
      setLoading(false);
      setErrorMessage(err.response);
      console.log(err);
    }
  };
  const fetchFormLayoutByGamma = async (id) => {
    setViewMoreLoader(true);
    try {
      setOpen(true);
      let rankingApis = `/rankings/get?id=${id}&priorityBatchID=${defaultPriorityBatchID}`;
      let res = await RESTGetAuthorization(rankingApis);
      setFormLayoutByGamma(res?.body);
      setViewMoreLoader(false);
    } catch (err) {
      setViewMoreLoader(false);
      console.log(err);
    }
  };

  useEffect(() => {
    fetchCompare();
    // eslint-disable-next-line
  }, []);

  //For goal change animation
  useEffect(() => {
    if (currentItem?.objective?.id !== objectiveID) {
      setShouldGoalAnimate(true);

      const animationTimeout = setTimeout(() => {
        setShouldGoalAnimate(false);
      }, 3000);

      return () => {
        clearTimeout(animationTimeout);
      };
    }
  }, [currentItem, objectiveID]);

  const [TotalTimeInSeconds, setTotalTimeInSeconds] = useState(0);
  const getSeconds = useSelector(getTotalSeconds);
  //For Timer
  useEffect(() => {
    let timer;
    if (compareData && compareData?.length > 0) {
      if (!isTimerPaused) {
        timer = setTimeout(() => {
          setTotalTimeInSeconds((prevSeconds) => prevSeconds + 1);
          dispatch(setTotalSeconds(getSeconds + 1));
        }, 1000);
      }

      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line
  }, [TotalTimeInSeconds, isTimerPaused, compareData]);

  useEffect(() => {
    let timer;

    // Function to update the timer
    const updateTimer = () => {
      if (compareSeconds === 59) {
        setCompareMinutes((prevMinutes) => prevMinutes + 1);
        setcompareSeconds(0);
      } else {
        setcompareSeconds((prevSeconds) => prevSeconds + 1);
      }
    };

    timer = setInterval(updateTimer, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [compareSeconds]);

  const [isRenderAnimation, setIsRenderAnimation] = useState(false);

  useEffect(() => {
    if (compareData?.length) {
      const timer = setTimeout(
        () => {
          setIsRenderAnimation(true);
        },
        currentItem?.objective?.id !== objectiveID ? 1000 : 0
      );
      return () => {
        setIsRenderAnimation(false);
        clearTimeout(timer);
      };
    }
    // eslint-disable-next-line
  }, [compareData, objectiveID]);

  useEffect(() => {
    if (startFirstTimeTour && !localStorage.getItem('firstTimeinCompare')) {
      dispatch(setCompareTour(true));
    }
    // eslint-disable-next-line
  }, [startFirstTimeTour]);

  useEffect(() => {
    if (compareData?.length > 0) {
      setRunTour(startCompareTour);
    }
  }, [compareData, startCompareTour]);

  const handleJoyrideCallback = (data) => {
    if (data.type === 'step:after' && data.action === 'close') {
      // The user closed the tooltip, stop the tour
      if (startFirstTimeTour) {
        localStorage.setItem('firstTimeinCompare', true);
      }
      setRunTour(false);
      dispatch(setCompareTour(false));
    }
  };

  const steps = [
    {
      target: '.compare-step',
      spotlightClicks: true,

      content: (
        <div className="joyride-style">
          <div className="title-content">Convey Your Expertise</div>
          <div
            className="details-content"
            style={{ fontWeight: '400px !important' }}
          >
            Select the idea, project or goal that you think will make the most
            positive impact against the goal. Skip if you are not sure!
          </div>
        </div>
      ),

      disableBeacon: true,
    },
    {
      target: '.skip-step',
      spotlightClicks: true,
      content: (
        <div className="joyride-style">
          <div className="title-content">Don't be concerned </div>
          <div
            className="details-content"
            style={{ fontWeight: '400px !important' }}
          >
            You may skip the comparison if it does not apply to you.
          </div>
        </div>
      ),
      disableBeacon: true,
      hideBackButton: true,
    },
  ];

  const tooltipTitle = <>{currentItem?.objective?.description}</>;
  return (
    <>
      <div className="background-white compare-tab">
        <div className="compare-step">
          {compareData?.length > 0 && (
            <>
              <Row className="header header-bg">
                {isRenderAnimation && (
                  <>
                    <Row>
                      <Col>
                        <Typography.Title
                          level={3}
                          id="yourElementId"
                          className={
                            shouldGoalAnimate
                              ? 'fs-24 animate-left-card'
                              : 'fs-24'
                          }
                        >
                          {currentItem?.objective?.name}{' '}
                          <Tooltip
                            title={tooltipTitle}
                            overlayStyle={{
                              maxWidth: screens.xs ? '300px' : '',
                            }}
                          >
                            <QuestionCircleOutlined className="fs-18 question-icon" />
                          </Tooltip>
                        </Typography.Title>
                      </Col>
                    </Row>
                    {!screens.xs && (
                      <Row>
                        <Col span={24} lg={24} md={24} sm={24} xs={24}>
                          <Typography.Title
                            level={5}
                            className={
                              shouldGoalAnimate ? 'animate-left-card' : 'fs-16'
                            }
                          >
                            Which item below will have a more{' '}
                            <Typography.Text className="fw-bold fs-16">
                              positive impact
                            </Typography.Text>{' '}
                            on this goal,{' '}
                            {currentItem?.gamma1?.title || 'No title'} or{' '}
                            {currentItem?.gamma2?.title || 'No title'}?
                          </Typography.Title>
                        </Col>
                      </Row>
                    )}
                  </>
                )}
                <div className="border-bottom"></div>
              </Row>
            </>
          )}
          {compareData?.length > 0 && screens.xs && (
            <Row>
              <Col span={24} lg={24} md={24} sm={24} xs={24}>
                <Typography.Title
                  level={5}
                  className={shouldGoalAnimate ? 'animate-left-card' : 'fs-16'}
                >
                  Which will have a more{' '}
                  <Typography.Text className="fw-bold fs-16">
                    positive impact
                  </Typography.Text>{' '}
                  on the above goal?
                </Typography.Title>
              </Col>
            </Row>
          )}

          {loading ? (
            <Row gutter={[20, 20]} justify="center" className="mt-30">
              <Col span={11} md={11} sm={24} xs={24}>
                <div className="idea-box w-100">
                  <Skeleton
                    active
                    paragraph={{
                      rows: 6,
                    }}
                  />
                </div>
              </Col>
              <Col span={11} md={11} sm={24} xs={24}>
                <div className="idea-box w-100">
                  <Skeleton
                    active
                    paragraph={{
                      rows: 6,
                    }}
                  />
                </div>
              </Col>
            </Row>
          ) : compareData?.length > 0 ? (
            <Radio.Group
              className="radio-custom mb-20 w-100"
              onChange={onChange}
              value={value}
              disabled={loader}
            >
              <Row justify="center" className="mt-30">
                {currentItem?.gamma1 ? (
                  <Col span={11} md={11} sm={24} xs={24}>
                    <div
                      className={
                        animate?.gamma1
                          ? 'idea related-item w-100 animate-left-card'
                          : selectGammaAnimate?.gamma1
                          ? 'idea related-item w-100'
                          : 'idea related-item w-100'
                      }
                    >
                      <Radio
                        value={currentItem?.gamma1?.id}
                        className="idea-box w-100"
                      >
                        <div className="h-100 wrapper">
                          <Row
                            justify="space-between"
                            align="top"
                            className="h-100"
                          >
                            <Col span={2} md={2} sm={2} xs={2}>
                              {value === currentItem?.gamma1?.id ? (
                                <CheckCircleFilled />
                              ) : (
                                <div className="circle"></div>
                              )}
                            </Col>
                            <Col
                              span={22}
                              md={22}
                              sm={22}
                              xs={22}
                              className="details"
                            >
                              <Row className="data">
                                <Typography.Title
                                  className="title fs-20"
                                  level={3}
                                >
                                  {currentItem?.gamma1?.title || 'No title'}
                                </Typography.Title>
                                <Typography.Title level={5} className="fs-16">
                                  {currentItem?.gamma1?.description ||
                                    'No description'}
                                </Typography.Title>
                              </Row>
                            </Col>
                          </Row>
                          <Row
                            align="middle"
                            justify="space-between"
                            className="w-100"
                          >
                            <Divider />
                            <Row align="middle">
                              <Typography.Title className="mt-0 mb-0 ml-10 fs-14">
                                <span className="fw-400">Type: </span>{' '}
                                <Tag
                                  key={currentItem?.gamma1?.level?.id}
                                  color={
                                    levelColors[
                                      currentItem?.gamma1?.level?.level
                                    ]
                                  }
                                >
                                  {currentItem?.gamma1?.level?.name}
                                </Tag>
                              </Typography.Title>
                            </Row>
                            {currentItem?.gamma1?.level?.level > 1 && (
                              <Button
                                type="text"
                                className="viewmore-btn"
                                onClick={() =>
                                  fetchFormLayoutByGamma(
                                    currentItem?.gamma1?.id
                                  )
                                }
                                size="middle"
                              >
                                View more <DownOutlined />
                              </Button>
                            )}
                          </Row>
                        </div>
                      </Radio>
                    </div>
                  </Col>
                ) : (
                  <Col span={11} md={11} sm={24} xs={24}>
                    <div className="idea-box w-100">
                      <Skeleton
                        active
                        paragraph={{
                          rows: 6,
                        }}
                      />
                    </div>
                  </Col>
                )}
                <Col span={2} md={2} sm={24} xs={24}>
                  <Row justify="center" align="middle" className="h-100">
                    <Typography.Title
                      level={3}
                      className="text-blue text-center compare-text"
                    >
                      VS
                    </Typography.Title>
                  </Row>
                </Col>
                {currentItem?.gamma2 ? (
                  <Col span={11} md={11} sm={24} xs={24}>
                    <div
                      className={
                        animate?.gamma2
                          ? 'idea related-item w-100 animate-right-card'
                          : selectGammaAnimate?.gamma2
                          ? 'idea related-item w-100'
                          : 'idea related-item w-100'
                      }
                    >
                      <Radio
                        value={currentItem?.gamma2?.id}
                        className="idea-box w-100"
                      >
                        <div className="h-100 wrapper">
                          <Row
                            justify="space-between"
                            align="top"
                            className="h-100"
                          >
                            <Col span={2} md={2} sm={2} xs={2}>
                              {value === currentItem?.gamma2?.id ? (
                                <CheckCircleFilled />
                              ) : (
                                <div className="circle"></div>
                              )}
                            </Col>
                            <Col
                              span={22}
                              md={22}
                              sm={22}
                              xs={22}
                              className="details"
                            >
                              <Row className="data">
                                <Typography.Title
                                  className="title title-font"
                                  level={3}
                                >
                                  {currentItem?.gamma2?.title || 'No title'}
                                </Typography.Title>
                                <Typography.Title level={5} className="p">
                                  {currentItem?.gamma2?.description ||
                                    'No description'}
                                </Typography.Title>
                              </Row>
                            </Col>
                          </Row>
                          <Row
                            align="middle"
                            justify="space-between"
                            className="w-100"
                          >
                            <Divider />
                            <Row align="middle">
                              <Typography.Title className="mt-0 mb-0 ml-10 p">
                                <span className="fw-400">Type: </span>{' '}
                                <Tag
                                  key={currentItem?.gamma2?.level?.id}
                                  color={
                                    levelColors[
                                      currentItem?.gamma2?.level?.level
                                    ]
                                  }
                                >
                                  {currentItem?.gamma2?.level?.name}
                                </Tag>
                              </Typography.Title>
                            </Row>
                            {currentItem?.gamma2?.level?.level > 1 && (
                              <Button
                                type="text"
                                className="viewmore-btn"
                                onClick={() =>
                                  fetchFormLayoutByGamma(
                                    currentItem?.gamma2?.id
                                  )
                                }
                                size="middle"
                              >
                                View More <DownOutlined />
                              </Button>
                            )}
                          </Row>
                        </div>
                      </Radio>
                    </div>
                  </Col>
                ) : (
                  <Col span={11} md={11} sm={24} xs={24}>
                    <div className="idea-box w-100">
                      <Skeleton
                        active
                        paragraph={{
                          rows: 6,
                        }}
                      />
                    </div>
                  </Col>
                )}
              </Row>
            </Radio.Group>
          ) : (
            <Empty
              description={
                errorMessage?.data === 'Comparison disabled' ? (
                  <span>
                    Please reach out to the Administrator for additional support{' '}
                    <br />
                    regarding the Compare feature.
                  </span>
                ) : (
                  <span>Aligning Strategy....</span>
                )
              }
            />
          )}
        </div>

        {compareData?.length > 0 ? (
          <Row className="mt-30 mb-30" justify="center">
            <Button
              className="bordered-button fs-16 skip-step"
              onClick={onSkip}
              loading={skipLoader}
              size="middle"
            >
              Skip this comparison
            </Button>
          </Row>
        ) : null}

        <Drawer
          width="35%"
          height={screens.xs ? '83vh' : ''}
          className="related-item-drawer compare-drawer"
          title={
            <Typography.Title level={4} className="mb-0 sub-header-font">
              {viewMoreLoader ? (
                <Skeleton
                  active
                  paragraph={{
                    rows: 1,
                  }}
                />
              ) : (
                formLayoutByGamma?.title
              )}
            </Typography.Title>
          }
          placement={screens.xs ? 'bottom' : 'right'}
          onClose={onClose}
          open={open}
        >
          {!viewMoreLoader ? (
            <>
              <Typography.Title level={5} className="text p">
                {formLayoutByGamma?.description}
              </Typography.Title>
              <Row className="w-100 details mt-20">
                <Row
                  justify="space-between"
                  align="middle"
                  className="w-100 mb-10"
                >
                  <Typography.Title level={5} className="p">
                    <span>ID: </span>{' '}
                    {formLayoutByGamma?.friendlyId &&
                      formLayoutByGamma?.friendlyId
                        .toString()
                        .padStart(6, '0')}{' '}
                  </Typography.Title>
                  <Typography.Title className="mt-0 p">
                    <span className="fw-400">Type: </span>{' '}
                    <Tag
                      key={formLayoutByGamma?.level?.id}
                      color={levelColors[formLayoutByGamma?.level?.level]}
                    >
                      {formLayoutByGamma?.level?.name}
                    </Tag>
                  </Typography.Title>
                </Row>
                <Col>
                  <Typography.Title level={5} className="mb-0 p">
                    <span>Department: </span>{' '}
                    {formLayoutByGamma?.departments?.items?.length
                      ? formLayoutByGamma?.departments?.items?.map((el) => {
                          return el?.department?.name;
                        })
                      : '-'}{' '}
                  </Typography.Title>

                  <Typography.Title level={5} className="mb-0 p">
                    <span>Created: </span>{' '}
                    {moment(formLayoutByGamma?.createdAt).format('l')}
                  </Typography.Title>
                </Col>
              </Row>

              <Typography.Title level={4} className="mt-20 mb-20 p">
                {formLayoutByGamma?.level?.name} fields
              </Typography.Title>
              {formLayoutByGamma?.layout?.map((item) => {
                return (
                  <>
                    {item?.inputType === 'singleLine' &&
                      !item?.hide &&
                      item?.id !== process.env.REACT_APP_TITLE_ID && (
                        <div className="box mb-20">
                          <Typography.Title level={4} className="p">
                            {item?.label}
                          </Typography.Title>
                          <Typography.Title level={5} className="input-font">
                            {item?.value}
                          </Typography.Title>
                        </div>
                      )}
                    {item?.inputType === 'multiLine' &&
                      !item?.hide &&
                      item?.id !== process.env.REACT_APP_DESCRIPTION_ID && (
                        <div className="box mb-20">
                          <Typography.Title level={4} className="p">
                            {item?.label}
                          </Typography.Title>
                          <Typography.Title level={5} className="input-font">
                            {item?.value && item?.value?.[0]?.text}
                          </Typography.Title>
                        </div>
                      )}
                  </>
                );
              })}
            </>
          ) : (
            <>
              <Skeleton
                active
                paragraph={{
                  rows: 6,
                }}
              />
              <Skeleton
                active
                className="mt-20"
                paragraph={{
                  rows: 6,
                }}
              />
            </>
          )}
        </Drawer>
      </div>
      {/* )} */}
      {!getAlreadyModalTrue && (
        <KudosModal
          isKudosModal={isKudosModal}
          setIsKudosModal={setIsKudosModal}
          totalSeconds={getSeconds}
          selectedIndex={selectedIndex}
          setIsTimerPaused={setIsTimerPaused}
        />
      )}

      {((isSMEGroup && startFirstTimeTour) ||
        (isSMEGroup && startCompareTour)) && (
        <Joyride
          stepIndex={joyrideStepIndex}
          steps={steps}
          run={runTour}
          continuous
          showProgress
          disableCloseOnEsc
          disableOverlayClose
          disableScrolling
          callback={handleJoyrideCallback}
          styles={{
            buttonNext: {
              display: 'none',
            },
          }}
        />
      )}
      {/* Render this component after completing all the comparisons */}
    </>
  );
}
