import React, { memo, useState } from 'react';
import { useSelector } from 'react-redux';

import { message, Modal, Row, Typography } from 'antd';

import { ECHOIMAGES } from 'Assets/Images';
import {
  getCurrentOrganization,
  getCurrnetUserData,
  isAdmin,
  isSuperAdmin,
} from 'store/slices/loginSlice';
import { RESTDeleteUser } from 'utils/RESTApi';

const DeleteUserModal = ({
  deleteUserModal,
  userID,
  setDeleteUserModal,
  getActiveUsersByOrganization,
  getAllUsers,
}) => {
  const { DeleteIcon } = ECHOIMAGES.superAdminIcons;

  const currnetOrg = useSelector(getCurrentOrganization);
  const isSuperAdminGroup = useSelector(isSuperAdmin);
  const isAdminGroup = useSelector(isAdmin);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;

  const [loading, setLoading] = useState(false);

  const handleDeleteUserModal = () => {
    setDeleteUserModal(false);
  };
  const deleteOrgUser = async () => {
    setLoading(true);
    try {
      let inputData = {
        user_id: userID,
        org_id: orgId,
      };
      await RESTDeleteUser(inputData);
      if (isAdminGroup || currnetOrg?.id) {
        getActiveUsersByOrganization();
      }
      if (isSuperAdminGroup && currnetOrg === null) {
        getAllUsers();
      }
      message.success('User Deleted Successfully');
      handleDeleteUserModal();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      throw err;
    }
  };
  return (
    <>
      <Modal
        open={deleteUserModal}
        onOk={deleteOrgUser}
        onCancel={handleDeleteUserModal}
        confirmLoading={loading}
        centered
        okText="Yes"
        className="delete-user-modal"
      >
        <Row justify="center">
          <img src={DeleteIcon} alt="" />
        </Row>
        <Row justify="center">
          <Typography.Title level={4}>
            Do you really want to Delete this User?
          </Typography.Title>
        </Row>
      </Modal>
    </>
  );
};

export default memo(DeleteUserModal);

