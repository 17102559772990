import { Form, InputNumber, Modal, Row, Typography, message } from 'antd';
import { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getDefaultBatchID } from 'store/slices/compareSlice';
import {
  getCurrentOrganization,
  getCurrnetUserData,
} from 'store/slices/loginSlice';
import { updateGammaNewCustom } from 'utils/Actions';
import {
  RESTGetAuthorization,
  RESTGetRanking,
  RESTUpdateRanking,
} from 'utils/RESTApi';

const UpdateRankModal = ({
  isModalOpen,
  setIsModalOpen,
  editData,
  getGammaByOrg,
  setCurrentPage,
  setNextToken,
}) => {
  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;
  const userId = currnetOrg?.adminID || loginUsrOrg?.id;

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const priorityDefaultBatchID =
    currnetOrg?.defaultPrioirtybatch?.id ||
    loginUsrOrg?.Organization?.organizationDefaultPrioirtybatchId;

  const fetchDefaultBatchID = useSelector(getDefaultBatchID);

  const defaultPriorityBatchID = fetchDefaultBatchID
    ? fetchDefaultBatchID
    : priorityDefaultBatchID;

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const handleSubmitRank = async () => {
    if (!form.getFieldValue().rank) {
      form.setFields([
        {
          name: 'rank',
          errors: ['Please enter Rank'],
        },
      ]);
      return false;
    }
    let input = { id: editData?.id, fixedRank: form.getFieldValue().rank };
    setLoading(true);
    try {
      let rankingApis = `/rankings/valid?id=${
        editData?.id
      }&organizationID=${orgId}&priorityBatchID=${defaultPriorityBatchID}&userID=${userId}&rank=${
        form.getFieldValue().rank
      }`;
      let res = await RESTGetAuthorization(rankingApis);
      if (res?.body) {
        await updateGammaNewCustom(input);
        message.success(`Rank Assigned Successfully`);
        createScoreCalculationStatus();
      } else {
        message.error('You can not assigned this rank');
      }
      setLoading(false);
      handleCancel();
      form.resetFields();
    } catch (error) {
      message.error(error.message);
      setLoading(false);
    }
  };

  const createScoreCalculationStatus = async () => {
    try {
      let inputData = {
        organization_id: orgId,
      };
      await RESTUpdateRanking(inputData);
      setNextToken(null);
      getGammaByOrg(
        null,
        null,
        null,
        1,
        null,
        '',
        null,
        null,
        null,
        null,
        false,
        false,
        false
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      open={isModalOpen}
      onOk={() => {
        handleSubmitRank();
        setCurrentPage(1);
      }}
      onCancel={handleCancel}
      closable={false}
      centered
      okText="Save"
      className="submit-idea-modal footer-end"
      confirmLoading={loading}
    >
      <Row>
        <div>
          <Typography.Title level={4} className="mt-10 fw-600 sub-header-font">
            Edit Rank
          </Typography.Title>
          <Typography.Title level={5} className="mb-20 text-black input-font">
            {editData?.title}
          </Typography.Title>
          <Typography.Title level={5} className="mb-20">
            Default Rank:{' '}
            <span className="text-primary fw-bolder">
              {(editData?.fixedRank === null || editData?.fixedRank < 0
                ? editData?.rank
                : editData?.fixedRank) ||
                (editData?.isRank ? editData?.rank : 0)}
            </span>
          </Typography.Title>
          <Typography.Title level={5} className="mb-20">
            Default rank is a system generated dynamic rank associated with the
            item.
          </Typography.Title>
          <Form
            layout="vertical"
            form={form}
            onFinish={() => {
              handleSubmitRank();
              setCurrentPage(1);
            }}
            requiredMark={false}
          >
            <Form.Item
              className="mb-0"
              label={
                <Typography.Title level={5} className="mb-0">
                  Assigned rank :
                </Typography.Title>
              }
              type="number"
              name="rank"
              rules={[
                {
                  required: true,
                  message: 'Please enter Rank',
                },
              ]}
            >
              <InputNumber min={1} className="w-100" />
            </Form.Item>
          </Form>
        </div>
      </Row>
    </Modal>
  );
};

export default memo(UpdateRankModal);

