import { Form, Modal, Row, Select, Typography, message } from 'antd';
import moment from 'moment';
import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getCurrentOrganization,
  getCurrnetUserData,
} from 'store/slices/loginSlice';
import { updateGammaNewCustom } from 'utils/Actions';
import { createLevelChangePromote } from './IdeaFunction';
import { useHistory } from 'react-router-dom';
import { fetchOrganizationStages } from 'utils/CommonFunctions';
import { RESTUpdateRanking, RESTNotificationGamma } from 'utils/RESTApi';

const PromoteModal = ({
  isModalOpen,
  setIsModalOpen,
  editData,
  getGammaByOrg,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [promoteValue, setPromoteValue] = useState('');
  const [promotedLevel, setPromotedLevel] = useState('');
  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const userId = currnetOrg?.adminID || loginUsrOrg?.id;
  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;
  const history = useHistory();

  const [allStages, setAllStages] = useState([]);

  const getStageName = async () => {
    try {
      const sortedStages = await fetchOrganizationStages(orgId);
      let demoteStages =
        editData?.level?.level > 2
          ? sortedStages.filter((item) => item.level > 1)
          : editData?.level?.level > 1
          ? sortedStages.filter((item) => item.level > 2)
          : sortedStages.filter(
              (item) => editData?.level?.level !== item?.level
            );
      setAllStages(demoteStages);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    form.setFieldsValue({ promote: editData?.level?.id || editData?.levelID });
  }, [editData, form]);

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
    setPromotedLevel('');
  };
  const handleSubmitPromote = async () => {
    if (!form.getFieldValue().promote) {
      form.setFields([
        {
          name: 'promote',
          errors: ['Please select Promote'],
        },
      ]);
      return false;
    }
    let input = {
      id: editData?.id,
      levelID: form.getFieldValue().promote?.value,
    };

    let levelInput = {
      gammaLevelHistoryId: editData?.id,
      changedAt: moment().format(),
      userID: userId,
      presentID: form.getFieldValue().promote?.value,
      previousID: editData?.level?.id || editData?.levelID,
    };
    setLoading(true);
    try {
      if (promoteValue) {
        await updateGammaNewCustom(input);
        await createLevelChangePromote(levelInput);
        await RESTNotificationGamma(editData?.id, userId);
        await RESTUpdateRanking({
          organization_id: orgId,
          aggregates: true,
        });
        message.success(`Changes saved successfully`);
        history.push(`/ranking-detail/${editData?.id}`);
        setLoading(false);
        getGammaByOrg();
        handleCancel();
      } else {
        message.error('Please select a gamma type');
        setLoading(false);
      }
    } catch (error) {
      message.error(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    getStageName();
    // eslint-disable-next-line
  }, [editData]);

  return (
    <Modal
      open={isModalOpen}
      onOk={handleSubmitPromote}
      onCancel={handleCancel}
      closable={false}
      centered
      okText="Save"
      className="submit-idea-modal footer-end"
      confirmLoading={loading}
    >
      <Row className="w-100">
        <div className="w-100">
          <Typography.Title level={4} className="mt-10 mb-20 fw-bolder">
            {editData?.title}
          </Typography.Title>
          <Form
            layout="vertical"
            form={form}
            onFinish={handleSubmitPromote}
            requiredMark={false}
          >
            <Form.Item
              className="mb-0"
              label={
                <Typography.Title
                  level={5}
                  className="mb-0 text-primary fs-16 fw-bolder"
                >
                  {promotedLevel === ''
                    ? 'Promote to'
                    : promotedLevel < editData?.level?.level
                    ? 'Demote to'
                    : 'Promote to'}
                </Typography.Title>
              }
              name="promote"
              rules={[
                {
                  required: true,
                  message: 'Please select Promote',
                },
              ]}
            >
              <Select
                className="select-h-40"
                placeholder="Select priority level"
                mode="single"
                labelInValue={true}
                onChange={(selectedValue, selectedOption) => {
                  setPromoteValue(selectedValue),
                    setPromotedLevel(selectedOption?.data?.level);
                }}
              >
                {allStages
                  ?.filter((item) => item?.active)
                  .sort((a, b) => a.level - b.level)
                  .map((item) => {
                    return (
                      <Option
                        value={item.id}
                        key={item.id}
                        data={{ level: item?.level }}
                      >
                        {item.name}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Form>
        </div>
      </Row>
    </Modal>
  );
};

export default memo(PromoteModal);

