import {
  Grid,
  Modal,
  Table,
  Tag,
  Tooltip,
  Typography,
  Image,
  Skeleton,
} from 'antd';
import { ECHOIMAGES } from 'Assets/Images';
import React, { memo, useEffect, useState, useRef } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import {
  getCurrentOrganization,
  getCurrnetUserData,
  isAdmin,
  isSuperAdmin,
} from 'store/slices/loginSlice';
import { useHistory, useParams } from 'react-router-dom';
import { RESTGetRankingFilter } from 'utils/RESTApi';
import { levelColors } from 'utils/Constants';
import moment from 'moment';
const ScenarioRankings = ({ isModalOpen, setIsModalOpen, itemData }) => {
  const { upArrow, downArrow, minusArrow } = ECHOIMAGES.HomeIcons;
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const isAdminGroup = useSelector(isAdmin);
  const isSuperAdminGroup = useSelector(isSuperAdmin);
  const { id } = useParams();
  const currentOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const orgId = currentOrg?.id || loginUsrOrg?.organizationID;
  const userId = currentOrg?.adminID || loginUsrOrg?.id;
  const [nextTokenData, setNextToken] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortTableOrder, setSortTableOrder] = useState('ASC');
  const [sortTableColumn, setSortTableColumn] = useState('rank');
  const [scenarioRankings, setScenarioRankings] = useState([]);
  const [loading, setLoading] = useState(true);
  const isFetching = useRef(false);
  const history = useHistory();

  const fetchGammas = async (sortOrder, sortColumn, page) => {
    let tempPage = page || currentPage;
    if (orgId) {
      let allData = [];
      let nextToken = nextTokenData;
      let sort_direction = sortTableOrder;
      let sort_field = sortTableColumn;
      if (sortOrder && sortColumn) {
        sort_direction = sortOrder;
        sort_field = sortColumn;
      }

      // eslint-disable-next-line max-len
      let rankingApi = `/rankings?id=${orgId}&priorityBatchID=${id}&userID=${userId}&page=${tempPage}&sort_direction=${sort_direction}&sort_field=${sort_field}`;
      if (nextToken && tempPage > 1) {
        rankingApi += `&nextToken=${nextToken}`;
      }

      try {
        const res = await RESTGetRankingFilter(rankingApi);
        const temp = res?.body?.items?.map((item, index) => {
          return {
            ...item?.Gamma,
            rank:
              item?.Gamma?.fixedRank > 0
                ? item?.Gamma?.fixedRank
                : item?.Gamma?.rank,
            isRank: item.isRanked,
          };
        });

        setNextToken(res?.body?.nextToken);

        allData =
          nextToken && tempPage > 1 ? [...scenarioRankings, ...temp] : temp;
        setScenarioRankings(allData);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
      return allData;
    }
  };

  const handelInfiniteScroll = async (e) => {
    const { target } = e;
    const isBottom =
      Math.round(target.scrollHeight) - Math.round(target.scrollTop) ===
      Math.round(target.clientHeight);
    if (isBottom && nextTokenData) {
      if (isFetching.current) {
        return;
      }
      isFetching.current = true;
      setCurrentPage((prevPage) => prevPage + 1);
      await fetchGammas(sortTableOrder, sortTableColumn, currentPage + 1);
      isFetching.current = false;
    }
  };

  const sortTableData = async (sortData) => {
    let sortOrder = sortData.order === 'ascend' ? 'ASC' : 'DESC';
    let sortColumn = sortData.field;
    setSortTableOrder(sortOrder);
    setSortTableColumn(sortColumn);
    setCurrentPage(1);
    setNextToken(null);
    await fetchGammas(sortOrder, sortColumn, 1);
  };

  const handleRowClick = (record, event) => {
    history.push({
      pathname: `/ranking-detail/${record.id}`,
      state: { data: record, previousPath: `/scenario-detail/${id}` },
    });
  };

  useEffect(() => {
    if (isModalOpen) {
      fetchGammas();
    }
    // eslint-disable-next-line
  }, [isModalOpen]);

  const columns = [
    {
      title: 'Rank',
      dataIndex: 'rank',
      key: 'rank',
      width: '3%',
      align: 'center',
      sortOrder:
        sortTableColumn === 'rank'
          ? sortTableOrder === 'ASC'
            ? 'ascend'
            : 'descend'
          : null,
      sorter: (a, b) => {
        return a - b;
      },
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, row) => {
        return {
          props: {
            className:
              row.fixedRank > 0 && (isAdminGroup || isSuperAdminGroup)
                ? 'editedRank rank'
                : '',
          },
          children: (
            <>
              <span
                className={
                  row.outOfThreshold
                    ? ' fw-400 fs-14 outofthresholdcolor'
                    : 'fw-400 fs-14'
                }
                style={{ borderLeft: row.color }}
              >
                {row.fixedRank > 0
                  ? row.fixedRank
                  : row.isRank === false
                  ? '-'
                  : text || 0}
              </span>
            </>
          ),
        };
      },
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: '35%',
      sortOrder:
        sortTableColumn === 'title'
          ? sortTableOrder === 'ASC'
            ? 'ascend'
            : 'descend'
          : null,
      render: (text, row) => {
        return {
          props: {
            className:
              row.fixedRank > 0 && (isAdminGroup || isSuperAdminGroup)
                ? 'editedRank'
                : '',
          },
          children: (
            <span
              className={
                row?.outOfThreshold
                  ? 'fw-400 capitalize-text fs-14 outofthresholdcolor'
                  : 'fw-400 capitalize-text fs-14'
              }
            >
              {text}
            </span>
          ),
        };
      },
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: (
        <div
          style={{
            display: 'flex',
            gap: '5px',
            alignItem: 'center',
          }}
        >
          <span>Stage </span>
          <Tooltip
            title="Ideas -> Opportunity -> Project. As ideas gain traction with more positive votes
          from team members, ideas can get promoted up to opportunity and could be selected as a project."
          >
            <InfoCircleOutlined />
          </Tooltip>
        </div>
      ),
      key: 'Type',
      dataIndex: 'level',
      width: '10%',
      sortOrder:
        sortTableColumn === 'level'
          ? sortTableOrder === 'ASC'
            ? 'ascend'
            : 'descend'
          : null,
      render: (text, row) => {
        return {
          props: {
            className:
              row.fixedRank > 0 && (isAdminGroup || isSuperAdminGroup)
                ? 'editedRank'
                : '',
          },
          children: (
            <Tag key={text.id} color={levelColors[text.level]}>
              {text?.name?.toUpperCase()}
            </Tag>
          ),
        };
      },
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'Department',
      dataIndex: 'departments',
      key: 'departments',
      render: (text, row) => {
        const departmentNames = row?.departments?.map((item) => item?.name);
        return {
          props: {
            className:
              row.fixedRank > 0 && (isAdminGroup || isSuperAdminGroup)
                ? 'editedRank'
                : '',
          },
          children: (
            <Typography.Text
              style={
                true
                  ? {
                      width: 200,
                    }
                  : undefined
              }
              ellipsis={
                true
                  ? {
                      tooltip: departmentNames?.join(', '),
                    }
                  : false
              }
            >
              {departmentNames?.join(', ')}
            </Typography.Text>
          ),
        };
      },
    },
    {
      title: 'Created on',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '11%',
      render: (text, row) => {
        return {
          props: {
            className:
              row.fixedRank > 0 && (isAdminGroup || isSuperAdminGroup)
                ? 'editedRank'
                : '',
          },
          children: <span>{moment(text)?.format('l')}</span>,
        };
      },
    },
    {
      title: (
        <div
          style={{
            display: 'flex',
            gap: '5px',
            alignItem: 'center',
          }}
        >
          <span>Change</span>
          <Tooltip
            title="Has the rank changed recently? For example, 
          if an idea gets positive votes and moves from rank #10 to #4 in the rankings, the change will be +6."
          >
            <InfoCircleOutlined />
          </Tooltip>
        </div>
      ),
      dataIndex: 'change',
      key: 'change',
      width: '5%',
      align: 'center',
      sortOrder:
        sortTableColumn === 'change'
          ? sortTableOrder === 'ASC'
            ? 'ascend'
            : 'descend'
          : null,
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: {
        compare: (a, b) => a.change - b.change,
      },
      render: (_, row) => {
        return {
          props: {
            className:
              row.fixedRank > 0 && (isAdminGroup || isSuperAdminGroup)
                ? 'editedRank'
                : '',
          },
          children:
            row?.previousRank['Recent'] - row.rank > 0 ? (
              <>
                {row.isRank === false ? (
                  <>
                    <span className=" fw-700 fs-14">-</span>
                  </>
                ) : (
                  <>
                    {row.fixedRank > 0 ? (
                      row.rank - row.fixedRank === 0 ? (
                        <>
                          <Image src={minusArrow} preview={false} />
                          <span
                            className={
                              row.outOfThreshold
                                ? 'outofthresholdcolor fw-700 fs-14'
                                : 'fw-700 fs-14'
                            }
                          >
                            {' '}
                            {row.rank - row.fixedRank}
                          </span>
                        </>
                      ) : (
                        <>
                          <Image src={upArrow} preview={false} />

                          <span
                            className={
                              row.outOfThreshold
                                ? 'outofthresholdcolor fw-700 fs-14'
                                : 'fw-700 fs-14'
                            }
                          >
                            {' '}
                            +{row?.previousRank['Recent'] - row.rank}
                          </span>
                        </>
                      )
                    ) : (
                      <>
                        <Image src={upArrow} preview={false} />
                        <span
                          className={
                            row.outOfThreshold
                              ? 'outofthresholdcolor fw-700 fs-14'
                              : 'fw-700 fs-14'
                          }
                        >
                          {' '}
                          +{row?.previousRank['Recent'] - row.rank}
                        </span>
                      </>
                    )}
                  </>
                )}
              </>
            ) : (
              <div>
                {row.isRank === false ? (
                  <>
                    <span
                      className={
                        row.outOfThreshold
                          ? 'outofthresholdcolor fw-700 fs-14'
                          : 'fw-700 fs-14'
                      }
                    >
                      -
                    </span>
                  </>
                ) : row.fixedRank !== null ? (
                  row.fixedRank > 0 || row?.previousRank['Recent'] > 0 ? (
                    row.rank - row.fixedRank === 0 ? (
                      <>
                        <Image src={minusArrow} preview={false} />
                        <span
                          className={
                            row.outOfThreshold
                              ? 'outofthresholdcolor fw-700 fs-14'
                              : 'fw-700 fs-14'
                          }
                        >
                          {' '}
                          {row.rank - row.fixedRank}
                        </span>
                      </>
                    ) : row?.previousRank['Recent'] ? (
                      <>
                        {row?.previousRank['Recent'] - row.rank === 0 ? (
                          <>
                            <Image src={minusArrow} preview={false} />
                            <span
                              className={
                                row.outOfThreshold
                                  ? 'outofthresholdcolor fw-700 fs-14'
                                  : 'fw-700 fs-14'
                              }
                            >
                              {' '}
                              0
                            </span>{' '}
                          </>
                        ) : (
                          <>
                            <Image src={downArrow} preview={false} />
                            <span
                              className={
                                row.outOfThreshold
                                  ? 'outofthresholdcolor fw-700 fs-14'
                                  : 'fw-700 fs-14'
                              }
                            >
                              {' '}
                              {row?.previousRank['Recent'] - row.rank}
                            </span>
                          </>
                        )}
                      </>
                    ) : (
                      <div>
                        <img src={minusArrow} alt="" />
                        <span
                          className={
                            row.outOfThreshold
                              ? 'outofthresholdcolor fw-700 fs-14'
                              : 'fw-700 fs-14'
                          }
                        >
                          {' '}
                          0
                        </span>{' '}
                      </div>
                    )
                  ) : (
                    <>
                      <div>
                        <span
                          className={
                            row.outOfThreshold
                              ? 'outofthresholdcolor fw-700 fs-14'
                              : 'fw-700 fs-14'
                          }
                        >
                          {' '}
                          -
                        </span>{' '}
                      </div>
                    </>
                  )
                ) : row?.previousRank['Recent'] ? (
                  <>
                    {' '}
                    {row?.previousRank['Recent'] - row.rank === 0 ? (
                      <div>
                        <img src={minusArrow} alt="" />
                        <span
                          className={
                            row.outOfThreshold
                              ? 'outofthresholdcolor fw-700 fs-14'
                              : 'fw-700 fs-14'
                          }
                        >
                          {' '}
                          0
                        </span>{' '}
                      </div>
                    ) : (
                      <>
                        <Image src={downArrow} preview={false} />
                        <span
                          className={
                            row.outOfThreshold
                              ? 'outofthresholdcolor fw-700 fs-14'
                              : 'fw-700 fs-14'
                          }
                        >
                          {' '}
                          {row?.previousRank['Recent'] - row.rank}
                        </span>
                      </>
                    )}
                  </>
                ) : (
                  '-'
                )}
              </div>
            ),
        };
      },
    },
  ];
  return (
    <Modal
      open={isModalOpen}
      footer={null}
      width={1100}
      onCancel={() => setIsModalOpen(false)}
    >
      {itemData?.rankingFilters?.filters?.length > 0 && (
        <Typography.Text level={5} className="fs-14">
          <span
            className={
              itemData?.rankingFilters?.count === 0
                ? 'fw-bold zero-items-count'
                : itemData?.rankingFilters?.count <= 5
                ? 'fw-bold yellow-items-count'
                : 'fw-bold'
            }
          >
            {' '}
            {itemData?.rankingFilters?.count}{' '}
          </span>{' '}
          items match your scenario
        </Typography.Text>
      )}
      {loading ? (
        <Skeleton
          active
          paragraph={{
            rows: 4,
          }}
        />
      ) : (
        <div
          style={{ maxHeight: 'calc(100vh - 140px)', overflowY: 'auto' }}
          onScroll={handelInfiniteScroll}
          className="mt-10"
        >
          <Table
            onChange={(pagination, filter, sorter) => {
              sortTableData(sorter);
            }}
            onRow={(record) => ({
              onClick: () => handleRowClick(record),
            })}
            columns={columns}
            dataSource={scenarioRankings}
            pagination={false}
            className="cursor-pointer ranking-table"
            size={screens.xxl ? 'middle' : 'small'}
          />
        </div>
      )}
    </Modal>
  );
};

export default memo(ScenarioRankings);

