import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  Button,
  Row,
  Space,
  Typography,
  Col,
  Modal,
  message,
  Skeleton,
  Tabs,
} from 'antd';
import moment from 'moment';

import './priorityBatches.less';
import { ECHOIMAGES } from '../../Assets/Images';
import {
  getCurrentOrganization,
  getCurrnetUserData,
  isAdmin,
  isSuperAdmin,
} from '../../store/slices/loginSlice';
import { deletePriorityBatches } from './PriorityFunction';
import { arrToCommaSeperated } from 'utils/commonMethods';
import { getDefaultBatchID } from 'store/slices/compareSlice';
import { RESTGetAuthorization, RESTScenarioCopy } from 'utils/RESTApi';
import GoalsandFilters from './components/GoalsandFilters';
import ScenarioSummary from './components/ScenarioSummary';

export default function PriorityBatchDetail({ defaultPriorityBatchDetails }) {
  const { TabPane } = Tabs;
  const { deletePriorityBatch } = ECHOIMAGES.PriorityBatches;
  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;
  const userId = currnetOrg?.adminID || loginUsrOrg?.id;
  let priorityDefaultBatchID =
    currnetOrg?.defaultPrioirtybatch?.id ||
    loginUsrOrg?.Organization?.organizationDefaultPrioirtybatchId;
  const fetchDefaultBatchID = useSelector(getDefaultBatchID);

  const defaultPriorityBatchID = fetchDefaultBatchID
    ? fetchDefaultBatchID
    : priorityDefaultBatchID;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const isSuperAdminGroup = useSelector(isSuperAdmin);
  const isAdminGroup = useSelector(isAdmin);
  const [showEditButton, setShowEditButton] = useState(false);
  const [itemData, setItemData] = useState([]);
  const [loading, setLoading] = useState(false);

  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    (async () => {
      if (id) {
        setLoading(true);
        let api = `/scenarios/get?organizationID=${orgId}&userID=${userId}&id=${id}`;
        let res = await RESTGetAuthorization(api);
        setItemData(res?.body);
        setLoading(false);
        let ownerIdArray = res?.body?.owners?.map((i) => i.id);
        let disableCondition =
          id === defaultPriorityBatchID
            ? isSuperAdminGroup || isAdminGroup
              ? false
              : true
            : ownerIdArray?.includes(userId) ||
              isSuperAdminGroup ||
              isAdminGroup
            ? false
            : true;
        if (!disableCondition) {
          setShowEditButton(true);
        }
      }
    })();
    // eslint-disable-next-line
  }, [defaultPriorityBatchDetails, id, defaultPriorityBatchID]);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // create priorities
  const createDuplicatePriorityBatch = async () => {
    try {
      const inputData = {
        id: defaultPriorityBatchDetails?.id || id,
        organizationID: orgId,
        userID: userId,
      };

      await RESTScenarioCopy(inputData);
      message.success('Scenarios duplicate successfully');
      window.location.reload();
      history.push('/scenarios');
    } catch (err) {
      console.log(err);
    }
  };

  const deletePriorityList = async () => {
    try {
      await deletePriorityBatches({ id: itemData?.id });
      history.push('/scenarios');
      message.success('Scenarios deleted');
    } catch (err) {
      console.log(err);
    }
  };

  // const items = [
  //   {
  //     key: '1',
  //     label: <span id="active-goal-tab">Summary</span>,
  //     children: <ScenarioSummary scaleType={scaleType} itemData={itemData} />,
  //   },
  //   {
  //     key: '2',
  //     label: 'Goals and Filters',
  //     children: <GoalsandFilters itemData={itemData} loading={loading} />,
  //   },
  // ];

  return (
    <div className="add-idea plr-0 pt-0 overflow-hidden">
      <div className="background-white p-24">
        <Row justify="space-between" align="middle" gutter={[0, 20]}>
          <Row align="middle">
            <Typography.Title level={3} className="mb-0 text-black fs-20">
              {loading ? <Skeleton.Input active /> : itemData?.title}{' '}
            </Typography.Title>
          </Row>

          <Row>
            <Space size={[10, 0]}>
              {showEditButton && itemData.id !== defaultPriorityBatchID ? (
                <Button
                  className="danger-button"
                  onClick={showModal}
                  size="middle"
                  disabled={loading}
                >
                  Delete
                </Button>
              ) : null}
              <Button
                className="bordered-button"
                onClick={createDuplicatePriorityBatch}
                size="middle"
                disabled={loading}
              >
                Duplicate
              </Button>
              {(showEditButton || isAdminGroup || isSuperAdminGroup) && (
                <Button
                  className="bordered-button"
                  onClick={() =>
                    history.push({
                      pathname: `/add-scenario/${itemData?.id}`,
                    })
                  }
                  size="middle"
                  type="primary"
                  disabled={loading}
                >
                  Edit
                </Button>
              )}
            </Space>
          </Row>
        </Row>
        <Row>
          <Col span={18} lg={18} md={18} sm={24} xs={24}>
            <Typography.Text level={3} className="mb-0 text-black">
              {loading ? <Skeleton.Input active /> : itemData?.description}{' '}
            </Typography.Text>
          </Col>
        </Row>
        <Row className="head-data mt-30 mb-30" gutter={[0, 20]}>
          <Col span={4} lg={4} md={8} sm={24} xs={24}>
            <Typography.Title level={5} className="mb-0 title fs-14">
              Scenario type
            </Typography.Title>
            <Typography.Title level={5} className="mb-0 mt-0 data fs-14">
              {' '}
              {loading ? (
                <Skeleton.Input active />
              ) : itemData?.public ? (
                'Shared'
              ) : (
                'Private' || '-'
              )}
            </Typography.Title>
          </Col>
          <Col span={4} lg={4} md={8} sm={24} xs={24}>
            <Typography.Title level={5} className="mb-0 title fs-14">
              Owner
            </Typography.Title>
            <Typography.Title level={5} className="mb-0 mt-0 data fs-14">
              {' '}
              {loading ? (
                <Skeleton.Input active />
              ) : itemData?.owners?.length > 0 ? (
                arrToCommaSeperated(
                  itemData?.owners?.map((item) =>
                    item?.firstName + ' ' + item?.lastName
                      ? item?.firstName + ' ' + item?.lastName
                      : '-'
                  )
                )
              ) : (
                '-'
              )}
            </Typography.Title>
          </Col>

          <Col span={4} lg={4} md={8} sm={24} xs={24}>
            <Typography.Title level={5} className="mb-0 title fs-14">
              Created date
            </Typography.Title>
            <Typography.Title level={5} className="mb-0 mt-0 data fs-14">
              {' '}
              {loading ? (
                <Skeleton.Input active />
              ) : (
                moment(itemData?.createdAt).format('l')
              )}
            </Typography.Title>
          </Col>
          <Col span={4} lg={4} md={8} sm={24} xs={24}>
            <Typography.Title level={5} className="mb-0 title fs-14">
              Last modified date
            </Typography.Title>
            <Typography.Title level={5} className="mb-0 mt-0 data fs-14">
              {' '}
              {loading ? (
                <Skeleton.Input active />
              ) : (
                moment(itemData?.updatedAt).format('l')
              )}
            </Typography.Title>
          </Col>
        </Row>
        <Tabs defaultActiveKey="1">
          <TabPane tab={<span>Summary</span>} key="1">
            <ScenarioSummary itemData={itemData} />
          </TabPane>
          <TabPane tab={<span>Goals and Filters</span>} key="2">
            <GoalsandFilters itemData={itemData} loading={loading} />
          </TabPane>
        </Tabs>
      </div>

      <Modal
        open={isModalOpen}
        onCancel={handleCancel}
        centered
        okText="Delete"
        className="submit-idea-modal"
        onOk={deletePriorityList}
      >
        <Row justify="center">
          <img src={deletePriorityBatch} alt="" />
          <Typography.Title level={4} className="mt-10">
            Are you sure that you want to delete scenario?
          </Typography.Title>
          <Typography.Title level={5} className="mb-20">
            This action can not be undone.
          </Typography.Title>
        </Row>
      </Modal>
    </div>
  );
}
