import { Button, message, Modal, Row, Typography } from 'antd';
import React, { memo, useState } from 'react';
import blurImage from 'Assets/admin/blurImage.png';
import { CopyOutlined, DeleteOutlined } from '@ant-design/icons';
import { RESTDeleteApiKey } from 'utils/RESTApi';
import { useSelector } from 'react-redux';
import {
  getCurrentOrganization,
  getCurrnetUserData,
} from 'store/slices/loginSlice';

const SecretKeyModal = ({
  isModalOpen,
  setIsModalOpen,
  secretKey,
  getSecretKey,
}) => {
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;
  const [showSecretKey, setShowSecretKey] = useState(false);
  const [loading, setLoading] = useState(false);

  const deleteSecretKey = async (id) => {
    setLoading(true);
    try {
      let input = {
        organization_id: orgId,
        id: id,
      };
      await RESTDeleteApiKey(input);
      message.success('Deleted successfully');
      await getSecretKey();
      setIsModalOpen(false);
      setShowSecretKey(false);
      setLoading(false);
    } catch (err) {
      message.error('Failed delete secret key');
      setLoading(false);
    }
  };

  return (
    <Modal
      open={isModalOpen}
      onCancel={handleCloseModal}
      footer={null}
      centered
      className="secretkey-modal"
    >
      <Row className="fs-20 fw-bold mb-10">Secret key</Row>
      {!showSecretKey && (
        <Row>
          <div
            style={{
              backgroundImage: `url(${blurImage})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeate',
            }}
            className="w-100 mb-10"
          >
            <Button
              className="fw-bold mr-10"
              type="primary"
              onClick={() => setShowSecretKey(true)}
              size="middle"
            >
              Reveal Secret Key
            </Button>
          </div>
        </Row>
      )}
      {showSecretKey &&
        secretKey?.items?.map((item, index) => {
          return (
            <div className="reveal-key">
              <Typography.Text
                copyable={{
                  text: item?.key,
                  icon: <CopyOutlined className="copy-icon" />,
                }}
                className="key-name"
              >
                {item?.key}
              </Typography.Text>
              <Button
                className="delete-btn"
                shape="circle"
                type="text"
                loading={loading}
                onClick={() => deleteSecretKey(item?.id)}
              >
                <DeleteOutlined />
              </Button>
            </div>
          );
        })}
    </Modal>
  );
};

export default memo(SecretKeyModal);

