import { Modal, Typography } from 'antd';
import { ECHOIMAGES } from 'Assets/Images';
import React, { memo } from 'react';

const PreviousStageConfirmation = ({
  previousFiledConfirmationModal,
  setPreviousFiledConfirmationModal,
  handleUpdateFormData,
}) => {
  const { confirmationIcon } = ECHOIMAGES.admin;

  const handleCancelModal = () => {
    setPreviousFiledConfirmationModal(false);
  };

  return (
    <Modal
      open={previousFiledConfirmationModal}
      closeIcon={false}
      className="publish-icon confirm-modal"
      onCancel={handleCancelModal}
      onOk={handleUpdateFormData}
      // confirmLoading={loading}
      okText="Save"
      // centered={true}
    >
      <div className="text-center mb-30">
        <img src={confirmationIcon} alt="" className="publish-img mb-10" />
        <Typography.Text className=" mb-20">
          Are you sure you want to save your changes, <br /> these changes will
          reflect on all the stages
        </Typography.Text>
      </div>
    </Modal>
  );
};

export default PreviousStageConfirmation;

