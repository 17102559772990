import { Col, Row, Typography } from 'antd';
import { ECHOIMAGES } from 'Assets/Images';
import React, { memo } from 'react';
import './integration.less';
import { useHistory } from 'react-router-dom';

const Integration = () => {
  const {
    integrationIcon,
    zapierIcon,
    mondayIcon,
    smartsheetIcon,
    microsoftIcon,
  } = ECHOIMAGES.admin;
  const history = useHistory();

  return (
    <div className="background-white">
      <Row justify="center integration-tab">
        <Col span={20} className="main-box">
          <div className="main-div">
            <div className="inner-div">
              <Typography.Title className="fs-20">
                Your StrAlign Integration
              </Typography.Title>
              <div className="div-text">
                <Typography.Text className="fs-16">
                  Select your channel to start StrAlign
                  <br />
                  integration into your system
                </Typography.Text>
              </div>
            </div>
            <div>
              <img src={integrationIcon} style={{ height: 145 }} alt="" />
            </div>
          </div>
        </Col>
        <Col span={20} className="mt-30">
          <Row className="cards-container" gutter={[16, 16]}>
            <Col
              className="card-container"
              span={8}
              md={8}
              onClick={() => history.push('/integration/integration-detail')}
            >
              <div className="platform-card">
                <img className="zapier-icon" src={zapierIcon} alt="" />
                <p className="fs-18">Zapier</p>
              </div>
            </Col>
            <Col
              className="card-container"
              span={8}
              md={8}
              onClick={() => window.open('https://www.stralign.com/contact')}
            >
              <div className="platform-card">
                <img
                  className="zapier-icon"
                  src={mondayIcon}
                  alt=""
                  className="mb-20"
                />
              </div>
              <div className="coming-soon">
                <p className="cs-text">Coming soon...</p>
              </div>
            </Col>
            <Col
              className="card-container"
              span={8}
              md={8}
              onClick={() => window.open('https://www.stralign.com/contact')}
            >
              <div className="platform-card">
                <img
                  className="zapier-icon"
                  src={smartsheetIcon}
                  alt=""
                  className="mb-20"
                />
              </div>
              <div className="coming-soon">
                <p className="cs-text">Coming soon...</p>
              </div>
            </Col>
            <Col
              className="card-container"
              span={8}
              md={8}
              onClick={() => window.open('https://www.stralign.com/contact')}
            >
              <div className="platform-card">
                <img
                  className="zapier-icon"
                  src={microsoftIcon}
                  alt=""
                  className="mb-20"
                />
              </div>
              <div className="coming-soon">
                <p className="cs-text">Coming soon...</p>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default memo(Integration);
