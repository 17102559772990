import React, { memo } from 'react';
import { Modal, Row, Typography } from 'antd';

const CustomDeleteModal = ({
  open,
  onOk,
  onCancel,
  confirmLoading,
  title,
  children,
  text,
  okText,
  cancelText,
}) => {
  return (
    <Modal
      visible={open}
      onOk={onOk}
      onCancel={onCancel}
      confirmLoading={confirmLoading}
      centered
      okText={okText ? <p id="goal-ok-btn">{okText}</p> : 'Delete'}
      cancelText={
        cancelText ? <p id="goal-cancel-btn">{cancelText}</p> : 'Cancel'
      }
      className="delete-user-modal"
    >
      <Row justify="center">{children}</Row>
      <Row justify="center">
        <Typography.Title level={4}>{title}</Typography.Title>
      </Row>
      <Row justify="center">
        <Typography.Text level={4}>{text}</Typography.Text>
      </Row>
    </Modal>
  );
};

export default memo(CustomDeleteModal);

