import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  Button,
  Col,
  Collapse,
  Divider,
  Drawer,
  Empty,
  Input,
  message,
  Popover,
  Row,
  Tag,
  Typography,
  Grid,
  Select,
} from 'antd';
import { ArrowLeftOutlined, ShareAltOutlined } from '@ant-design/icons';
import { orderBy } from 'lodash';
import moment from 'moment';

import './idea.less';
import './opportunity.less';
import { listGammas } from '../Dashboard/DashboardFunctions';

import {
  getCurrentOrganization,
  getCurrnetUserData,
  isAdmin,
  isLeader,
  isSuperAdmin,
} from '../../store/slices/loginSlice';
import { getLinkedIdeas, linkAddIdea, deleteLink } from './IdeaFunction';
import UpdateRankModal from './UpdateRankModal';
import ReAssignedRankModal from './ReAssignedRankModal';
import PromoteModal from './PromoteModal';
import { listUserGammaType } from 'Pages/SuperAdmin/SuperAdminFun';
import { getGammas } from './OpportunityFunctions';
import {
  arrToCommaSeperated,
  debounceFn,
  searchTextInArrayOfObject,
} from 'utils/commonMethods';
import CommonSkeleton from 'Common/Skeleton';
import { levelColors } from 'utils/Constants';

export default function IdeaDetail() {
  const { Panel } = Collapse;
  const { Paragraph } = Typography;
  const { Search } = Input;
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const isAdminGroup = useSelector(isAdmin);
  const isSuperAdminGroup = useSelector(isSuperAdmin);
  const isLeaderGroup = useSelector(isLeader);
  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;
  const [open, setOpen] = useState(false);
  const [dashboardData, setDashboardData] = useState([]);
  const [backeupData, setBackupData] = useState([]);
  const history = useHistory();
  const [relatedIdea, setRelatedIdea] = useState([]);
  const [relatedIdeaIds, setRelatedIdeaIds] = useState([]);
  const [gammaTypeList, setGammaTypeList] = useState([]);
  const [ideaDetails, setIdeaDetails] = useState('');
  const [loading, setLoading] = useState(false);

  const { id } = useParams();

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const [isPromoteModalOpen, setIsPromoteModalOpen] = useState(false);
  const showPromoteModal = () => {
    setIsPromoteModalOpen(true);
  };

  const getGammaByOrg = async () => {
    try {
      setLoading(true);
      if (id) {
        const userDetails = await getGammas({ id: id });
        setIdeaDetails(userDetails);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    getGammaByOrg();
    // eslint-disable-next-line
  }, [id]);

  const handleGetGammaType = async () => {
    try {
      const data = await listUserGammaType();
      setGammaTypeList(data?.data?.__type?.enumValues);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchGammas = () => {
    listGammas(orgId)
      .then((gammas) => {
        const temp = gammas.map((item) => {
          return {
            ...item,
            loading: false,
          };
        });
        setDashboardData(orderBy(temp));
        setBackupData(orderBy(temp));
      })
      .catch((err) => console.error(err));
  };

  const onSearch = (searchText) => {
    if (searchText !== '') {
      const keysToSearch = ['title', 'description', 'friendlyId'];
      const returnFilterData = searchTextInArrayOfObject(
        searchText,
        keysToSearch,
        backeupData
      );
      setDashboardData(returnFilterData);
    } else {
      setDashboardData(backeupData);
    }
  };

  const debouncedHandleInput = debounceFn(onSearch, 500);

  const handleInputSearch = (searchText) => {
    debouncedHandleInput(searchText);
  };

  const listRelatedIdea = async () => {
    try {
      const res = await getLinkedIdeas({ id: id });
      const updatedRelatedIdea = res?.data?.getGamma?.linkedTo?.items.map(
        (item) => {
          return {
            ...item,
            loading: false,
          };
        }
      );
      setRelatedIdea(updatedRelatedIdea);
      setRelatedIdeaIds(
        res?.data?.getGamma?.linkedTo?.items.map(
          (item) => item?.linkedGamma?.id
        )
      );
    } catch (error) {
      console.log(error);
    }
  };
  const handleLinkIdeaLoader = (index, loader) => {
    let temp = [...dashboardData];
    temp[index].loading = loader;
    setDashboardData(temp);
  };

  const linkIdeas = async (linkId, index) => {
    handleLinkIdeaLoader(index, true);
    try {
      const inputData = {
        gammaLinkedToId: id,
        linkedGammasLinkedGammaId: linkId,
      };

      await linkAddIdea(inputData);
      await listRelatedIdea();
      message.success('Items linked successfully');
      handleLinkIdeaLoader(index, false);
    } catch (error) {
      handleLinkIdeaLoader(index, false);
      console.log(error);
    }
  };

  const unLinkIdeas = async (id) => {
    try {
      await deleteLink(id);
      message.success('Items unlink successfully');
      await listRelatedIdea();
    } catch (error) {
      console.log(error);
    }
  };

  const unLinkRelatedIdeas = async (id, index) => {
    handleLinkIdeaLoader(index, true);
    try {
      await deleteLink(id);
      message.success('Idea unlink successfully');
      setOpen(false);
      await listRelatedIdea();
      handleLinkIdeaLoader(index, false);
    } catch (error) {
      handleLinkIdeaLoader(index, false);
      console.log(error);
    }
  };
  useEffect(() => {
    fetchGammas();
    listRelatedIdea();
    handleGetGammaType();
    // eslint-disable-next-line
  }, []);
  return (
    <div className="plr-0 pt-0">
      <Row align="middle" justify="space-between" className="breadcrums ">
        <Typography.Title level={5}>
          <ArrowLeftOutlined
            className="mr-10"
            onClick={() => history.push('/rankings')}
          />{' '}
          Idea Details
        </Typography.Title>
        {(isAdminGroup || currnetOrg?.id || isSuperAdminGroup) && (
          <Row className="gap-10">
            <Button
              className="bordered-button"
              onClick={() => history.push(`/edit-idea/${id}`)}
            >
              Edit Priority
            </Button>
            <Button className="bordered-button" onClick={showModal}>
              Edit Rank
            </Button>
            <Button className="blue-btn" onClick={showPromoteModal}>
              Promote
            </Button>
          </Row>
        )}
        {isLeaderGroup && (
          <Button className="blue-btn" onClick={showPromoteModal}>
            Promote
          </Button>
        )}
      </Row>
      <Row className="w-100 mt-20">
        <Col span={24}>
          <div className="opportunity-detail idea">
            {loading ? (
              <CommonSkeleton />
            ) : (
              <>
                <Row align="middle" justify="space-between">
                  <Typography.Title level={4} className="title sub-header-font">
                    {ideaDetails.title}
                  </Typography.Title>

                  <Popover
                    content={'Idea link copied'}
                    placement="bottom"
                    trigger="clicked"
                  >
                    <Button type="text" className="share-icon">
                      <ShareAltOutlined />
                      Share
                    </Button>
                  </Popover>
                </Row>

                <Typography.Title level={5} className="mt-20 text p">
                  {ideaDetails.description || 'No description'}
                </Typography.Title>

                <Row className="details">
                  <Col
                    span={12}
                    lg={12}
                    md={14}
                    sm={24}
                    xs={24}
                    className="rmb-20"
                  >
                    <Typography.Title level={5} className="mb-0 p">
                      <span>Rank : </span>{' '}
                      {ideaDetails?.fixedRank === null ||
                      ideaDetails?.fixedRank < 0
                        ? ideaDetails.rank?.[0]
                        : ideaDetails.fixedRank}
                    </Typography.Title>
                    <Typography.Title level={5} className="mb-0 p">
                      <span>ID : </span>{' '}
                      {ideaDetails?.friendlyId
                        ? ideaDetails?.friendlyId.toString().padStart(6, '0')
                        : '-'}{' '}
                    </Typography.Title>
                    <Typography.Title level={5} className="mb-0 p">
                      <span>Department : </span>{' '}
                      {arrToCommaSeperated(
                        ideaDetails?.departments?.items?.map((el) => {
                          return el?.department?.name;
                        })
                      )}{' '}
                    </Typography.Title>
                    <Typography.Title level={5} className="mb-0 p">
                      <span>Created : </span>
                      {moment(ideaDetails.createdAt).format('l')}{' '}
                    </Typography.Title>
                  </Col>
                  <Col span={12} lg={12} md={10} sm={24} xs={24}>
                    <Typography.Title level={5} className="mt-0 mb-0 p">
                      <span>Type : </span>{' '}
                      <Tag
                        className={
                          ideaDetails.level === 'IDEA'
                            ? 'idea tag-font'
                            : ideaDetails.level === 'PROJECT'
                            ? 'project tag-font'
                            : 'opportunity tag-font'
                        }
                      >
                        {ideaDetails.level}
                      </Tag>
                    </Typography.Title>
                  </Col>
                </Row>
              </>
            )}
          </div>
        </Col>

        <Col span={24}>
          <Collapse
            bordered={false}
            className="idea-detail-collapse mt-20 pannel-text idea"
            expandIconPosition="end"
          >
            <Panel
              header="Related Items"
              key="1"
              className="fs-18 pannel-text"
              extra={
                <div>
                  <Button
                    type="primary"
                    className="primary-btn related-btn"
                    onClick={showDrawer}
                    size={screens.xxl ? 'large' : 'middle'}
                  >
                    Add Related Items
                  </Button>
                </div>
              }
            >
              <Row gutter={[20, 25]} className="mt-30">
                {relatedIdea?.map((item, index) => {
                  return (
                    <Col
                      span={12}
                      md={12}
                      sm={24}
                      xs={24}
                      key={item?.linkedGamma?.title}
                    >
                      <div className="idea-box idea">
                        <div className="h-100 wrapper">
                          <Row>
                            <Link to="">
                              <Paragraph
                                className="title p"
                                ellipsis={{
                                  rows: 2,
                                  expandable: false,
                                  symbol: 'more',
                                  tooltip: item?.linkedGamma?.title,
                                }}
                              >
                                {item?.linkedGamma?.title}
                              </Paragraph>

                              <Paragraph
                                className="idea-desc p"
                                ellipsis={{
                                  rows: 3,
                                  expandable: false,
                                  symbol: 'more',
                                  tooltip: item?.linkedGamma?.description,
                                }}
                              >
                                {item?.linkedGamma?.description}
                              </Paragraph>
                            </Link>
                          </Row>

                          <Row
                            align="middle"
                            justify="space-between"
                            className="w-100"
                          >
                            <Divider />
                            <Row align="middle">
                              <Typography.Title level={5} className="mb-0 p">
                                <span>ID : </span>{' '}
                                {item?.linkedGamma?.friendlyId &&
                                  item?.linkedGamma?.friendlyId
                                    .toString()
                                    .padStart(6, '0')}{' '}
                              </Typography.Title>
                              <Typography.Title
                                level={5}
                                className="mt-0 mb-0 ml-10 p"
                              >
                                <span>Type : </span>{' '}
                                <Tag
                                  className={
                                    item?.linkedGamma?.level === 'IDEA'
                                      ? 'idea tag-font'
                                      : item?.linkedGamma?.level === 'PROJECT'
                                      ? 'project tag-font'
                                      : 'opportunity tag-font'
                                  }
                                >
                                  {item?.linkedGamma?.level}
                                </Tag>
                              </Typography.Title>
                            </Row>

                            <Popover
                              content={'Idea link copied'}
                              placement="bottom"
                              trigger="clicked"
                            >
                              <Button
                                className="unlink-btn"
                                loading={item.loading}
                                onClick={() => unLinkIdeas(item?.id)}
                                size={screens.xxl ? 'large' : 'middle'}
                              >
                                Unlink
                              </Button>
                            </Popover>
                          </Row>
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </Panel>
          </Collapse>
        </Col>
      </Row>

      <Drawer
        width="40%"
        height={screens.xs ? '83vh' : ''}
        placement={screens.xs ? 'bottom' : 'right'}
        className="related-item-drawer"
        title={
          <Typography.Title level={4} className="mb-0 sub-header-font">
            Add related items
          </Typography.Title>
        }
        onClose={onClose}
        open={open}
      >
        <Search
          className="searchbar-style"
          placeholder="Search by keyword or ID"
          onChange={(e) => handleInputSearch(e.target.value)}
        />
        {dashboardData?.length ? (
          dashboardData
            .filter((el) => el.id !== ideaDetails?.id)
            .map((item, index) => {
              return (
                (item?.title || item?.description) && (
                  <Row className="idea mt-20" gutter={[0, 20]}>
                    <div className="idea-box idea w-100">
                      <Paragraph className="title capitalize-text sub-header-font">
                        {item?.title}
                      </Paragraph>

                      <Paragraph
                        ellipsis={{
                          rows: 3,
                          expandable: false,
                          symbol: 'more',
                          tooltip: item?.description,
                        }}
                        className="p"
                      >
                        {item?.description}
                      </Paragraph>

                      <Row
                        align="middle"
                        justify="space-between"
                        className="w-100"
                      >
                        <Divider />
                        <Row align="middle">
                          <Typography.Title level={5} className="mb-0 p">
                            <span>ID : </span>
                            {item?.friendlyId &&
                              item?.friendlyId.toString().padStart(6, '0')}{' '}
                          </Typography.Title>
                          <Typography.Title
                            level={5}
                            className="mt-0 mb-0 ml-10 p"
                          >
                            <span>Type : </span>{' '}
                            <Tag
                              className={
                                item?.level === 'IDEA'
                                  ? 'idea tag-font'
                                  : item?.level === 'PROJECT'
                                  ? 'project tag-font'
                                  : 'opportunity tag-font'
                              }
                            >
                              {item?.level}
                            </Tag>
                          </Typography.Title>
                        </Row>
                        {relatedIdeaIds?.includes(item?.id) ? (
                          <Button
                            type="primary"
                            className="unlink-btn"
                            loading={item.loading}
                            onClick={() =>
                              unLinkRelatedIdeas(
                                relatedIdea.filter(
                                  (el) => el?.linkedGamma?.id === item.id
                                )[0]?.id,
                                index
                              )
                            }
                            size={screens.xxl ? 'large' : 'middle'}
                          >
                            Unlink
                          </Button>
                        ) : (
                          <Button
                            type="primary"
                            className="primary-btn"
                            loading={item.loading}
                            onClick={() => linkIdeas(item?.id, index)}
                            size={screens.xxl ? 'large' : 'middle'}
                          >
                            Link
                          </Button>
                        )}
                      </Row>
                    </div>
                  </Row>
                )
              );
            })
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </Drawer>

      {/* edit rank modal  */}
      {ideaDetails?.fixedRank === null || ideaDetails?.fixedRank < 0 ? (
        <UpdateRankModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          editData={ideaDetails}
          getGammaByOrg={getGammaByOrg}
        />
      ) : (
        <ReAssignedRankModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          editData={ideaDetails}
          getGammaByOrg={getGammaByOrg}
        />
      )}
      {/* Promote modal  */}
      <PromoteModal
        isModalOpen={isPromoteModalOpen}
        setIsModalOpen={setIsPromoteModalOpen}
        editData={ideaDetails}
        gammaTypeList={gammaTypeList}
        getGammaByOrg={getGammaByOrg}
      />
    </div>
  );
}
