import { Modal, Typography } from 'antd';
import { ECHOIMAGES } from 'Assets/Images';
import React, { memo } from 'react';

function ConfirmationModal({
  confirmationModal,
  setConfirmationModal,
  updateStageForm,
  loading,
}) {
  const { confirmationIcon } = ECHOIMAGES.admin;
  const handleCancelModal = () => {
    setConfirmationModal(false);
  };
  return (
    <Modal
      open={confirmationModal}
      closeIcon={false}
      className="publish-icon confirm-modal"
      onCancel={handleCancelModal}
      onOk={updateStageForm}
      confirmLoading={loading}
      okText="Publish"
    >
      <div className="text-center">
        <img src={confirmationIcon} alt="" className="publish-img mb-10" />
        <Typography.Text>
          You are about to publish the changes to an existing stage.
        </Typography.Text>
      </div>
    </Modal>
  );
}

export default memo(ConfirmationModal);

