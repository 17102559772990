import { Badge, message, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getCurrentOrganization,
  getCurrnetUserData,
  isAdmin,
  isLeader,
  isSME,
  isSuperAdmin,
} from 'store/slices/loginSlice';
import ArchivedGoal from './ArchivedGoal';
import Goal from './Goal';
import { listObjective } from './GoalFunction';
import { useHistory } from 'react-router-dom';
import Vision from './Components/Vision';
import { getOrganizationCustom } from 'utils/Actions';

export default function AdminGoal() {
  const [userCount, setUserCount] = useState({
    activeGoalLength: 0,
  });
  const [archiveCount, setArchiveCount] = useState({
    archiveGoalLength: 0,
  });
  const [originalData, setOriginalData] = useState([]);
  const [listLeaderGoal, setListLeaderGoal] = useState([]);
  const [lisBackupGoal, setListBackupGoal] = useState([]);
  const [archiveGoals, setArchiveGoals] = useState([]);
  const [archiveBackuoGoals, setArchiveBackupGoals] = useState([]);
  const [visionDetails, setVisonDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(true);
  const [defaultGoalWeight, setDefaultGoalWeight] = useState([]);
  const [goalFlag, setGoalFlag] = useState(false);

  const [tabValue, setTabValue] = useState(1);
  const isLeaderGroup = useSelector(isLeader);
  const isSuperAdminGroup = useSelector(isSuperAdmin);
  const isSMEGroup = useSelector(isSME);
  const isAdminGroup = useSelector(isAdmin);
  const history = useHistory();
  const fromMyGoal = history.location?.state?.myGoals;
  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;
  const userId = currnetOrg?.adminID || loginUsrOrg?.id;

  const getVisionDetails = async () => {
    try {
      let res = await getOrganizationCustom({ id: orgId });
      let GoalWeightFlag = res?.scenarioWeightsFlag;
      if (GoalWeightFlag) {
        setGoalFlag(GoalWeightFlag);
      } else {
        setGoalFlag(false);
      }
      setDefaultGoalWeight(res?.defaultPrioirtybatch?.priorities);
      setVisonDetails(res?.vision);
      setLoader(false);
    } catch (err) {
      setLoader(false);
      console.log(err);
      throw err;
    }
  };

  // list Organization Goal for Leader
  const listGoals = async () => {
    try {
      const filter = {
        organizationObjectivesId: orgId,
        filter: {
          active: { eq: tabValue === 1 ? true : false },
        },
      };
      const res = await listObjective(filter);
      let usersGoals;
      if (fromMyGoal) {
        usersGoals =
          res?.data?.objectivesByOrganizationObjectivesId?.items.filter(
            (el) => el?.ownerId === userId
          );
      } else {
        usersGoals = res?.data?.objectivesByOrganizationObjectivesId?.items;
      }
      if (tabValue === 1) {
        setListLeaderGoal(usersGoals);
        setOriginalData(usersGoals);
        setListBackupGoal(usersGoals);
      } else {
        setArchiveGoals(usersGoals);
        setArchiveBackupGoals(usersGoals);
      }
      if (tabValue === 1) {
        setUserCount({
          ...userCount,
          activeGoalLength: usersGoals?.length,
        });
      } else {
        setArchiveCount({
          ...archiveCount,
          archiveGoalLength: usersGoals?.length,
        });
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const listActiveGoals = async () => {
    setLoading(true);
    try {
      const filter = {
        organizationObjectivesId: orgId,
        filter: {
          active: { eq: true },
        },
      };
      const res = await listObjective(filter);
      let usersGoals;
      if (fromMyGoal) {
        usersGoals =
          res?.data?.objectivesByOrganizationObjectivesId?.items.filter(
            (el) => el?.ownerId === userId
          );
      } else {
        usersGoals = res?.data?.objectivesByOrganizationObjectivesId?.items;
      }

      setListLeaderGoal(usersGoals);
      setOriginalData(usersGoals);
      setListBackupGoal(usersGoals);
      setUserCount({
        ...userCount,
        activeGoalLength: usersGoals?.length,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error(error.message);
    }
  };

  const listArchiveGoals = async () => {
    try {
      const filter = {
        organizationObjectivesId: orgId,
        filter: {
          active: { eq: false },
        },
      };
      const res = await listObjective(filter);
      let usersGoals;
      if (fromMyGoal) {
        usersGoals =
          res?.data?.objectivesByOrganizationObjectivesId?.items.filter(
            (el) => el?.ownerId === userId
          );
      } else {
        usersGoals = res?.data?.objectivesByOrganizationObjectivesId?.items;
      }

      setArchiveGoals(usersGoals);
      setArchiveBackupGoals(usersGoals);
      setListLeaderGoal(usersGoals);
      setArchiveCount({
        ...archiveCount,
        archiveGoalLength: usersGoals?.length,
      });
    } catch (error) {
      message.error(error.message);
    }
  };
  useEffect(() => {
    if ((isSuperAdminGroup || isAdminGroup) && tabValue == 1) {
      listActiveGoals();
    } else if ((isSuperAdminGroup || isAdminGroup) && tabValue == 2) {
      listArchiveGoals();
    } else if (isLeaderGroup) {
      listGoals();
    }
    // eslint-disable-next-line
  }, [tabValue]);
  const items = [
    {
      key: '1',
      label: (
        <span id="active-goal-tab">
          Active goals <Badge count={userCount.activeGoalLength} showZero />
        </span>
      ),
      children: (
        <Goal
          setListLeaderGoal={setListLeaderGoal}
          listLeaderGoal={listLeaderGoal}
          lisBackupGoal={lisBackupGoal}
          setListBackupGoal={setListBackupGoal}
          listGoals={listGoals}
          listActiveGoals={listActiveGoals}
          loading={loading}
          defaultGoalWeight={defaultGoalWeight}
          goalFlag={goalFlag}
          getVisionDetails={getVisionDetails}
          originalData={originalData}
          setUserCount={setUserCount}
        />
      ),
      hide: isSMEGroup ? true : false,
    },
    {
      key: '2',
      label: (
        <span id="archive-goal-tab">
          Archived Goals{' '}
          <Badge count={archiveCount.archiveGoalLength} showZero />
        </span>
      ),
      children: (
        <ArchivedGoal
          setArchiveGoals={setArchiveGoals}
          archiveGoals={archiveGoals}
          archiveBackuoGoals={archiveBackuoGoals}
          setArchiveBackupGoals={setArchiveBackupGoals}
          listGoals={listGoals}
          defaultGoalWeight={defaultGoalWeight}
          goalFlag={goalFlag}
          originalData={originalData}
          setArchiveCount={setArchiveCount}
        />
      ),
      hide: isSMEGroup ? true : false,
    },
  ].filter((el) => !el.hide);

  return (
    <>
      <Vision
        orgId={orgId}
        getVisionDetails={getVisionDetails}
        loader={loader}
        visionDetails={visionDetails}
      />
      <div className="plr-0 p-0 admin-goal">
        {(isSuperAdminGroup || isAdminGroup || isLeaderGroup) && (
          <Tabs
            defaultActiveKey="1"
            items={items}
            onChange={(e) => setTabValue(e)}
          />
        )}
        {isSMEGroup && (
          <Goal
            setListLeaderGoal={setListLeaderGoal}
            listLeaderGoal={listLeaderGoal}
            lisBackupGoal={lisBackupGoal}
            setListBackupGoal={setListBackupGoal}
            defaultGoalWeight={defaultGoalWeight}
            goalFlag={goalFlag}
            getVisionDetails={getVisionDetails}
            originalData={originalData}
          />
        )}
      </div>
    </>
  );
}

