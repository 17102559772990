import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import Joyride from 'react-joyride';
import { useDispatch, useSelector } from 'react-redux';
import { getAddIdeaTour, setAddIdeaTour } from 'store/slices/loginSlice';

function OnboardingIdea({ joyrideStepIndex, setJoyrideStepIndex }) {
  const [ideaTour, setIdeaTour] = useState(false);
  const startIdeaTour = useSelector(getAddIdeaTour);

  const steps = [
    {
      target: '.idea-step1',
      content: (
        <div className="joyride-style">
          <div className="title-content">Craft Your Idea</div>
          <div className="details-content">
            Please provide details about your idea. Also you can link your idea
            with similar concepts.
          </div>
        </div>
      ),
      disableBeacon: true,
      hideBackButton: true,
      spotlightClicks: true,
      placement: 'right',
    },
    {
      target: '.idea-step2',
      content: (
        <div className="joyride-style">
          <div className="title-content">You Are Not Alone!</div>
          <div className="details-content">
            As you type a new idea title, similar or related ideas,
            opportunities, or projects that your teammates have shared will
            appear here.
          </div>
          <Button
            onClick={() => setJoyrideStepIndex(2)}
            type="text"
            className="idea-skip-btn"
            size="middle"
          >
            Skip
          </Button>
        </div>
      ),
      disableBeacon: true,
      hideBackButton: true,
      spotlightClicks: true,
      placement: 'left',
    },
    {
      target: '.idea-step3',
      content: (
        <div className="joyride-style">
          <div className="title-content">Bring Your Idea to Life</div>
          <div className="details-content">
            "Click 'Submit' to transform your idea into reality. Your idea could
            be the next big thing!
          </div>
        </div>
      ),
      disableBeacon: true,
      hideBackButton: true,
      spotlightClicks: true,
      placement: 'right',
    },
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      setJoyrideStepIndex(0);
      setIdeaTour(startIdeaTour);
    }, 3000);
    // eslint-disable-next-line
  }, [startIdeaTour]);

  const handleJoyrideCallback = (data) => {
    if (data.type === 'step:after' && data.action === 'close') {
      // The user closed the tooltip, stop the tour
      setIdeaTour(false);
      dispatch(setAddIdeaTour(false));
    }
  };

  return (
    <>
      <Joyride
        steps={steps}
        stepIndex={joyrideStepIndex}
        run={ideaTour}
        continuous
        showProgress
        disableCloseOnEsc
        disableOverlayClose
        disableScrolling
        callback={handleJoyrideCallback}
      />
    </>
  );
}

export default OnboardingIdea;

