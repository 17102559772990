import config from '../aws-exports';
const clientId = process.env.REACT_APP_CLIENT_ID;
const authorityID = process.env.REACT_APP_AUTHORITY_ID;
const redirectURI =
  window.location.hostname === 'localhost'
    ? process.env.REACT_APP_REDIRECT_URI
    : `${config.aws_content_delivery_url}/login`;
const grapMeEndpoint = process.env.REACT_APP_ENDPOINT;

export const msalConfig = {
  auth: {
    clientId: clientId,
    authority: authorityID, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: redirectURI,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ['User.Read', 'email'],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: grapMeEndpoint,
};

