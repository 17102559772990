import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Col, Row, Input, Button, Grid } from 'antd';

import { ECHOIMAGES } from '../../Assets/Images';
import { userStatus } from '../../utils/Constants';
import {
  getCurrentOrganization,
  getCurrnetUserData,
  isAdmin,
  isSuperAdmin,
} from '../../store/slices/loginSlice';
import { getUsersByOrganizationIDCustom } from './SuperAdminFun';
import AdminGlobalFilter from './AdminGlobalFilter';
import { searchUsersCustom } from 'utils/Actions';
import { fetchOrgDepartments } from 'utils/CommonFunctions';
import {
  debounceFn,
  GetFileFromS3,
  searchTextInArrayOfObject,
} from 'utils/commonMethods';
import ActiveUserModal from './components/ActiveUserModal';
import DisabledUserTable from './components/DisabledUserTable';
import FilterButton from 'Common/FilterButton';
import { RESTGetManagement } from 'utils/RESTApi';

export default function DisabledUsers({
  userCount,
  setUserCount,
  setInitalFlag,
  activeTab,
}) {
  const { Search } = Input;
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const [open, setOpen] = useState(false);
  const [allDisabledUsers, setAllDisabledUsers] = useState([]);
  const [backupAllUsers, setBackupAllUsers] = useState([]);
  const [isActiveModalOpen, setIsActiveModalOpen] = useState(false);
  const [userID, setUserID] = useState('');
  const [allUsers, setAllUsers] = useState([]);
  const [listAllDepartments, setListAllDepartments] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [nextTokenData, setNextToken] = useState(null);
  const [sortTableOrder, setSortTableOrder] = useState('ASC');
  const [sortTableColumn, setSortTableColumn] = useState('createdAt');
  const [loadData, setLoadData] = useState(false);

  const { FilterIcon } = ECHOIMAGES.ideaIcons;
  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;
  const isSuperAdminGroup = useSelector(isSuperAdmin);
  const isAdminGroup = useSelector(isAdmin);
  const [adminFilter, setAdminFilter] = useState({});
  const [filterChange, setFilterChange] = useState(false);
  const [searchField, setSearchField] = useState('');

  //drawer stuff
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const showActiveModal = () => {
    setIsActiveModalOpen(true);
  };
  const getSignedUrlImg = async (fileKey) => {
    const signedURL = await GetFileFromS3(fileKey, {
      level: 'public',
    });
    return signedURL;
  };
  const [currentPage, setCurrentPage] = useState(1);

  //for admin
  const getDisabledUsersByOrganization = async (
    sortOrder,
    sortColumn,
    page,
    filterChange,
    search
  ) => {
    try {
      let tempPage = page || currentPage;
      setLoading(true);

      let filter = {
        organizationID: { eq: orgId },
      };

      let sort_direction = sortTableOrder;
      let sort_field = sortTableColumn;
      if (sortOrder && sortColumn) {
        sort_direction = sortOrder;
        sort_field = sortColumn;
      }
      if (search === '') {
        delete adminFilter.Search;
      } else {
        adminFilter.Search = search;
      }
      let inputData = {
        organization_id: orgId,
        sort_field: sort_field,
        sort_direction: sort_direction,
        scope: 'ADMIN',
        page: tempPage,
        filters: adminFilter,
      };

      adminFilter.Status = activeTab == 1 ? 'ACTIVE' : 'DISABLED';
      let res = await RESTGetManagement(inputData);
      const users = res?.body || [];
      let allDisabledUsersData = filterChange
        ? users
        : tempPage > 1
        ? [...allDisabledUsers, ...users]
        : users;

      let totalUsersCount = await searchUsersCustom(filter);
      let activeUser =
        totalUsersCount?.data?.searchUsers?.aggregateItems?.[0]?.result?.buckets.find(
          (item) => item?.key === userStatus.active
        );
      let disabledUser =
        totalUsersCount?.data?.searchUsers?.aggregateItems?.[0]?.result?.buckets.find(
          (item) => item?.key === userStatus.disabled
        );
      let invitedUser =
        totalUsersCount?.data?.searchUsers?.aggregateItems?.[0]?.result?.buckets.find(
          (item) => item?.key === userStatus.invited
        );
      setAllDisabledUsers(allDisabledUsersData);
      setBackupAllUsers(allDisabledUsersData);
      setUserCount({
        ...userCount,
        activeUserLength: activeUser?.doc_count || 0,
        disabledUserLength: disabledUser?.doc_count || 0,
        invitesUserLength: invitedUser?.doc_count || 0,
      });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const getAllUsers = async (sortOrder, sortColumn, page, filterChange) => {
    let tempPage = page || currentPage;
    let sort_direction = sortTableOrder;
    let sort_field = sortTableColumn;
    if (sortOrder && sortColumn) {
      sort_direction = sortOrder;
      sort_field = sortColumn;
    }
    let userData = {
      or: [
        { status: { eq: userStatus.confirm } },
        { status: { eq: userStatus.enable } },
        { status: { eq: userStatus.active } },
        { status: { eq: userStatus.disabled } },
      ],
    };
    let usersCount = await searchUsersCustom(userData);
    let activeUser =
      usersCount?.data?.searchUsers?.aggregateItems?.[0]?.result?.buckets.find(
        (item) => item?.key === userStatus.active
      );
    let disabledUser =
      usersCount?.data?.searchUsers?.aggregateItems?.[0]?.result?.buckets.find(
        (item) => item?.key === userStatus.disabled
      );
    setUserCount({
      ...userCount,
      activeUserLength: activeUser?.doc_count || 0,
      disabledUserLength: disabledUser?.doc_count || 0,
    });

    let inputData = {
      organization_id: orgId,
      sort_field: sort_field,
      sort_direction: sort_direction,
      scope: 'SUPERADMIN',
      page: tempPage,
      filters: adminFilter,
    };
    adminFilter.Status = activeTab == 1 ? 'ACTIVE' : 'DISABLED';
    let res = await RESTGetManagement(inputData);

    const users = res?.body || [];
    let allDisabledUsers = filterChange
      ? users
      : tempPage > 1
      ? [...allUsers, ...users]
      : users;

    setAllUsers(allDisabledUsers);
    setBackupAllUsers(allDisabledUsers);
    setLoading(false);
    setLoadData(false);
  };

  const sortTableData = async (sortData) => {
    let sortOrder = sortData.order === 'ascend' ? 'ASC' : 'DESC';
    let sortColumn = sortData.field;
    setSortTableOrder(sortOrder);
    setSortTableColumn(sortColumn);
    setCurrentPage(1);
    if (isSuperAdminGroup && currnetOrg === null) {
      await getAllUsers(sortOrder, sortColumn, 1, true);
    }
    if (isAdminGroup || currnetOrg?.id) {
      await getDisabledUsersByOrganization(
        sortOrder,
        sortColumn,
        1,
        true,
        searchField
      );
    }
  };

  const handelInfiniteScroll = async (e) => {
    const { target } = e;
    const isBottom =
      Math.round(target.scrollHeight) - Math.round(target.scrollTop) ===
      Math.round(target.clientHeight);
    if (isBottom && allDisabledUsers?.length > 0) {
      setCurrentPage((prevPage) => prevPage + 1);
      setLoadData(true);
      if (isAdminGroup || currnetOrg?.id) {
        await getDisabledUsersByOrganization(
          sortTableOrder,
          sortTableColumn,
          currentPage + 1,
          null,
          searchField
        );
      }
    }
    if (isBottom && allUsers?.length > 0) {
      setCurrentPage((prevPage) => prevPage + 1);
      setLoadData(true);
      if (isSuperAdminGroup && currnetOrg === null) {
        await getAllUsers(sortTableOrder, sortTableColumn, currentPage + 1);
      }
    }
  };

  const listOrgDepartments = async () => {
    const departments = await fetchOrgDepartments(orgId);
    setListAllDepartments(departments);
  };

  useEffect(() => {
    if (isAdminGroup || currnetOrg?.id) {
      getDisabledUsersByOrganization();
    }
    if (isSuperAdminGroup && currnetOrg === null) {
      getAllUsers();
    }
    listOrgDepartments();
    // eslint-disable-next-line
  }, []);

  const onSearch = async (searchText) => {
    if (searchText !== '') {
      setCurrentPage(1);
      setSearchField(searchText);
      await getDisabledUsersByOrganization(
        sortTableOrder,
        sortTableColumn,
        1,
        true,
        searchText
      );
    } else {
      setCurrentPage(1);
      setSearchField('');
      await getDisabledUsersByOrganization(
        sortTableOrder,
        sortTableColumn,
        1,
        true,
        ''
      );
    }
  };

  const debouncedHandleInput = debounceFn(onSearch, 500);

  const handleInputSearch = (searchText) => {
    debouncedHandleInput(searchText);
  };

  return (
    <div className="background-white p-20 idea">
      <Row justify="center">
        <Col span={24}>
          <Row justify="space-between" align="middle">
            <Search
              className="searchbar-style"
              placeholder="Search"
              onChange={(e) => handleInputSearch(e.target.value)}
            />
            {selectedRowKeys?.length ? (
              <Button
                type="primary"
                className="fw-bold"
                onClick={showActiveModal}
                size="middle"
              >
                Active All
              </Button>
            ) : (
              <FilterButton onClick={showDrawer} />
            )}
          </Row>
        </Col>
      </Row>
      <DisabledUserTable
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        allDisabledUsers={allDisabledUsers}
        allUsers={allUsers}
        showActiveModal={showActiveModal}
        setUserID={setUserID}
        loading={loading}
        handleScroll={handelInfiniteScroll}
        loadData={loadData}
        sortTableData={sortTableData}
        sortOrder={sortTableOrder}
        sortColumn={sortTableColumn}
      />
      {/* Filter Drawer  */}
      <AdminGlobalFilter
        tab="disabled"
        onClose={onClose}
        open={open}
        setListAllDepartments={setListAllDepartments}
        listAllDepartments={listAllDepartments}
        backupAllUsers={backupAllUsers}
        setAllDisabledUsers={setAllDisabledUsers}
        setOpen={setOpen}
        activeTab={activeTab}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        sortTableColumn={sortTableColumn}
        sortTableOrder={sortTableOrder}
        setFilterChange={setFilterChange}
        filterChange={filterChange}
        setAdminFilter={setAdminFilter}
        searchField={searchField}
        setAllUsers={setAllUsers}
      />
      {/* Active user modal  */}
      <ActiveUserModal
        isActiveModalOpen={isActiveModalOpen}
        userID={userID}
        getDisabledUsersByOrganization={getDisabledUsersByOrganization}
        getAllUsers={getAllUsers}
        setIsActiveModalOpen={setIsActiveModalOpen}
        setSelectedRowKeys={setSelectedRowKeys}
        selectedRowKeys={selectedRowKeys}
        setInitalFlag={setInitalFlag}
        activeTab={activeTab}
      />
    </div>
  );
}

