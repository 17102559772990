import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import {
  Typography,
  Form,
  Row,
  Col,
  Input,
  Radio,
  Select,
  Button,
  message,
  Skeleton,
  Divider,
  Tooltip,
} from 'antd';
import {
  CloseOutlined,
  InfoCircleOutlined,
  EditOutlined,
  PlusOutlined,
  WarningOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import moment from 'moment';

import './priorityBatches.less';
import {
  getCurrentOrganization,
  getCurrnetUserData,
} from '../../store/slices/loginSlice';

import {
  RESTGetAuthorization,
  RESTGetCount,
  RESTNotification,
  RESTScenarioUpdate,
  RESTUpdateRankingAggregates,
} from '../../utils/RESTApi';
import { capitalizeFirstLetter, UserRole, userStatus } from 'utils/Constants';
import AddGoal from './components/AddGoal';
import { getDefaultBatchID } from 'store/slices/compareSlice';
import { fetchOrgDepartments } from 'utils/CommonFunctions';
import { FetchUser } from 'Pages/AllPriorities/IdeaFunction';
import { v4 as uuidv4 } from 'uuid';
import { arrToCommaSeperated } from 'utils/commonMethods';
import NoFilterImage from './components/NoFilterImage';
import { getOrganizationCustom, listObjectiveCustom } from 'utils/Actions';
import FilterTab from './components/FilterTab';
import EditWhoFilterModal from './components/EditWhoFilterModal';
import EditIdeaFilterModal from './components/EditIdeaFilterModal';
import { ECHOIMAGES } from 'Assets/Images';

export default function AddPriorityBatch() {
  const { Option } = Select;

  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;
  const userId = currnetOrg?.adminID || loginUsrOrg?.id;
  const [form] = Form.useForm();
  const history = useHistory();
  const { id } = useParams();

  const [listLeaderGoal, setListLeaderGoal] = useState([]);
  const [listLeaderGoalBackup, setListLeaderGoalBackup] = useState([]);
  const [ownerList, setOwnerList] = useState([]);
  const [goalWeight, setGoalWeight] = useState([]);
  const [addGoal, setAddGoal] = useState([]);
  const [addGoalforModal, setAddGoalforModal] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [removedItems, setRemovedItems] = useState([]);
  const [batchTypeValue, setBatchTypeValue] = useState(false);
  const [prioritybatch, setPriorityBatch] = useState({});
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [savedFilters, setSavedFilters] = useState();
  const [editField, setEditField] = useState(null);
  const [selectedValue, setSelectedValue] = useState('ALL');
  const [ideaMatch, setIdeaMatch] = useState('ALL');
  const [addFilter, setAddFilter] = useState(false);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [saveRankingFilter, setSaveRankingFilter] = useState([]);
  const [savedRankingFilter, setSavedRankingFilter] = useState();
  const [goalModalOpen, setGoalModalOpen] = useState(false);
  const [editWhoFilterModalOpen, setEditWhoFilterModalOpen] = useState(false);
  const [editIdeaFilterModal, setEditIdeaFilterModal] = useState(false);
  const [rankingCount, setRankingCount] = useState();
  const whoOptionsObj = {
    departmentToInclude: null,
    departmentValue: [],
    userRoleToInclude: null,
    userRoleValue: [],
    customUserRoleWeightCheckbox: false,
    userRoleandWeight: {},
    usersToInclude: null,
    usersValue: [],
    customUsersWeightCheckbox: false,
    usersandWeight: {},
  };
  const [whoList, setWhoList] = useState([whoOptionsObj]);
  const ideaOptionsObj = {
    mfname: null,
    category: null,
    lowvalue: null,
    extraField: [],
    valueTest: null,
    highvalue: null,
    highscalevalue: null,
    threshold: null,
    includeBlanks: true,
  };
  const [ideaList, setIdeaList] = useState([ideaOptionsObj]);
  const { TextArea } = Input;

  const { warningIcon, minusButton, refreshIcon } = ECHOIMAGES.PriorityBatches;
  let priorityDefaultBatchID =
    currnetOrg?.defaultPrioirtybatch?.id ||
    loginUsrOrg?.Organization?.organizationDefaultPrioirtybatchId;

  const fetchDefaultBatchID = useSelector(getDefaultBatchID);

  const defaultPriorityBatchID = fetchDefaultBatchID
    ? fetchDefaultBatchID
    : priorityDefaultBatchID;

  const calculateTotal = () => {
    let total = 0;
    for (let i = 0; i < addGoal.length; i++) {
      total += parseInt(addGoal[i].weight || 0);
    }
    return total;
  };

  const handleInputChange = (event, index) => {
    const res = /^([1-9][0-9]{0,1}|100)$/; // Regex to allow only values between 1 to 100
    const re = /^[0-9]{1,3}$/; // Regex to allow only 1-3 digit numeric values
    if (
      (event.target.value === '' || re.test(event.target.value)) &&
      (event.target.value === '' || res.test(event.target.value))
    ) {
      const inputValue = event.target.value ? event.target.value : '';
      const newValues = [...goalWeight];
      newValues[index] = inputValue;
      setGoalWeight(newValues);
      const temp = [...addGoal];
      temp[index]['weight'] = inputValue;
      setAddGoal(temp);
    }
  };

  const handleSelectChange = (selectedIds) => {
    // Access the email of the selected owner based on their ID
    const selectedOwnerEmails = selectedIds.map((id) => {
      const selectedOwner = ownerList
        ?.filter((item) => item?.role !== UserRole?.sme)
        .find((item) => item.id === id);
      return selectedOwner ? selectedOwner.email : null;
    });
  };
  const [isNextToken, setIsNextToken] = useState(null);

  //get all users by org
  const getAllUsers = async () => {
    setLoading(true);
    try {
      let data = {
        status: { eq: userStatus.active },
        or: [
          { role: { eq: UserRole.admin } },
          { role: { eq: UserRole.leader } },
          { role: { eq: UserRole.superAdmin } },
          { role: { eq: UserRole.sme } },
        ],
      };
      let id = { organizationID: orgId };
      let nextToken = null;
      let allUserData = [];

      while (true) {
        let res = await FetchUser(data, id, nextToken);
        if (res?.items) {
          allUserData.push(...res.items);
        }
        setLoading(false);
        setIsNextToken(res?.nextToken);
        if (!res?.nextToken) {
          break;
        }
        nextToken = res?.nextToken;
      }
      await processUserData(allUserData);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };
  const processUserData = async (userData) => {
    setOwnerList(userData);
  };

  // create priorities
  const createPriority = async (e) => {
    setLoader(true);
    try {
      const ObjectiveData = [];
      addGoal.forEach((item) => {
        if (item?.weight) {
          ObjectiveData.push({ ObjectiveID: item?.id, weight: item?.weight });
        }
      });
      const filtersArray = appliedFilters?.map((item) => {
        const { includeExclude, id, ...filterWithoutIncludeExclude } = item;
        return filterWithoutIncludeExclude;
      });

      if (calculateTotal() > 100) {
        message.error('Total weight cannot exceed 100');
        setLoader(false);
      } else {
        const alreadySelectedOwnerIds = form.getFieldValue()?.owner || [];

        const ownerEmails = alreadySelectedOwnerIds.map((ownerID) => {
          const selectedOwner = ownerList
            ?.filter((item) => item?.role !== UserRole?.sme)
            ?.find((item) => item?.id === ownerID);
          return selectedOwner?.id || null;
        });

        const inputData = {
          userID: userId,
          scenario: {
            organizationID: orgId,
            id: id,
            title: e?.Prioritybatchname,
            description: e?.scenariodesc,
            public: batchTypeValue,
            priorities: ObjectiveData,
            updatedAtBy: {
              userID: userId,
              time: moment().format('MMMM Do YYYY, h:mm a'),
            },
            owners: ownerEmails,
            voteFilters: { match: selectedValue, filters: filtersArray || [] },
            rankingFilters: {
              match: ideaMatch,
              filters: saveRankingFilter || [],
            },
          },
        };
        await RESTScenarioUpdate(inputData)
          .then(async (res) => {
            const oldOwner = prioritybatch?.owners?.filter((el) =>
              e?.owner?.includes(el?.id)
            );

            const newOwner = [];
            e?.owner?.map((item) => {
              const data = oldOwner?.filter((el) => el?.id === item);
              if (data?.length === 0) {
                newOwner.push(item);
              }
              return item;
            });
            if (id) {
              await Promise.all(
                newOwner?.map(async (item) => {
                  await RESTNotification(
                    'createPriorityBatchesAndUsers',
                    item,
                    res?.body?.[0]?.id,
                    userId
                  );
                })
              );
            } else {
              await Promise.all(
                e?.owner?.map(async (item) => {
                  await RESTNotification(
                    'createPriorityBatchesAndUsers',
                    item,
                    res?.body?.[0]?.id,
                    userId
                  );
                })
              );
            }
            setLoader(false);
            message.success(
              `Scenario ${id ? 'updated' : 'created'} successfully`
            );
            history.push({
              pathname: `/scenario-detail/${res?.body?.[0]?.id}`,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } catch (err) {
      console.log(err);
      setLoader(false);
      message.error(err.message);
    }
  };

  const getScenarioById = async () => {
    let api = `/scenarios/get?organizationID=${orgId}&userID=${userId}&id=${id}`;
    let res = await RESTGetAuthorization(api);
    setPriorityBatch(res?.body);
    setRankingCount(res?.body?.rankingFilters?.count);
  };

  const [listAllDepartments, setListAllDepartments] = useState([]);

  const listOrgDepartments = async () => {
    const departments = await fetchOrgDepartments(orgId);
    setListAllDepartments(departments);
  };

  const listGoals = async () => {
    try {
      const filter = {
        organizationObjectivesId: orgId,
      };
      const res = await listObjectiveCustom(filter);
      const defaultBatches = await getOrganizationCustom({ id: orgId });

      const temp = [];
      const tempArray = [];
      res?.data?.objectivesByOrganizationObjectivesId?.items?.forEach((el) => {
        const data = prioritybatch?.priorities?.filter(
          (vl) => vl?.ObjectiveID === el?.id
        );
        if (data?.length > 0) {
          temp.push({ ...data[0], ...el });
        } else {
          tempArray.push(el);
        }
      });
      let defaultScenarioGoals = [];
      res?.data?.objectivesByOrganizationObjectivesId?.items?.forEach((el) => {
        let objectiveData =
          defaultBatches?.defaultPrioirtybatch?.priorities?.find(
            (vl) => vl?.ObjectiveID === el?.id
          );
        if (objectiveData) {
          defaultScenarioGoals.push({ ...el, ...objectiveData });
        }
      });
      if (temp.length > 0) {
        setAddGoal(temp);
        setAddGoalforModal(temp);
      } else {
        setAddGoal(defaultScenarioGoals);
        setAddGoalforModal(defaultScenarioGoals);
      }
      setListLeaderGoal(
        temp.length
          ? tempArray
          : res?.data?.objectivesByOrganizationObjectivesId?.items
      );
      setListLeaderGoalBackup(
        res?.data?.objectivesByOrganizationObjectivesId?.items
      );
    } catch (error) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    if (prioritybatch?.title) {
      form.setFieldsValue({
        Prioritybatchname: prioritybatch?.title,
        Prioritybatchtype: prioritybatch?.public,
        owner: prioritybatch?.owners?.map((item) => item?.id),
        scenariodesc: prioritybatch?.description,
      });
      setAppliedFilters(
        prioritybatch?.voteFilters?.filters?.map((item) => {
          return {
            id: uuidv4(),
            ...item,
          };
        })
      );
      setSaveRankingFilter(
        prioritybatch?.rankingFilters?.filters?.map((item) => {
          return {
            uniqueID: uuidv4(),
            ...item,
          };
        })
      );

      if (prioritybatch?.voteFilters?.match) {
        setSelectedValue(prioritybatch?.voteFilters?.match);
      } else {
        setSelectedValue(selectedValue);
      }
      if (prioritybatch?.rankingFilters?.match) {
        setIdeaMatch(prioritybatch?.rankingFilters?.match);
      } else {
        setIdeaMatch(selectedValue);
      }
      if (prioritybatch?.public) {
        setBatchTypeValue(true);
      }
    } else {
      form.setFieldsValue({
        owner: [userId],
      });
    }
    // eslint-disable-next-line
  }, [prioritybatch]);

  useEffect(() => {
    getAllUsers();
    listGoals();
    // eslint-disable-next-line
  }, [prioritybatch]);

  useEffect(() => {
    if (id) {
      getScenarioById();
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    listOrgDepartments();
    // eslint-disable-next-line
  }, []);

  const handleRemoveItem = (itemId) => {
    const updatedFilters = appliedFilters?.filter((item) => item.id !== itemId);
    setAppliedFilters(updatedFilters);
  };

  const handleRemoveIdea = (itemId) => {
    const updatedFilters = saveRankingFilter?.filter(
      (item) => item.uniqueID !== itemId
    );
    setSaveRankingFilter(updatedFilters);
    onCancelFilterCount(updatedFilters);
  };

  const handleFilterValue = (item) => {
    if (item?.field?.type === '#') {
      const symbol = item?.field?.subType === '$' ? '$' : '';
      // If type is '#'
      if (
        Object.entries(item?.filter)[0][0] === 'is between' ||
        Object.entries(item?.filter)[0][0] === 'is not between'
      ) {
        return (
          Object.entries(item?.filter)[0][0] +
          ' ' +
          symbol +
          Object.entries(item?.filter)[0][1][0] +
          '-' +
          symbol +
          Object.entries(item?.filter)[0][1][1]
        );
      } else {
        return (
          Object.entries(item?.filter)[0][0] +
          ' ' +
          symbol +
          Object.entries(item?.filter)[0][1]
        );
      }
    } else if (item?.field?.type === '+-') {
      // If type is '+-'
      if (
        Object.entries(item?.filter)[0][0] === 'is between' ||
        Object.entries(item?.filter)[0][0] === 'is not between'
      ) {
        return (
          Object.entries(item?.filter)[0][0] +
          ' ' +
          item?.field?.breakpoints.find(
            (b) =>
              parseInt(b.value) ===
              parseInt(Object.entries(item?.filter)[0][1][0])
          )?.label +
          '-' +
          item?.field?.breakpoints.find(
            (b) =>
              parseInt(b.value) ===
              parseInt(Object.entries(item?.filter)[0][1][1])
          )?.label
        );
      } else {
        return (
          Object.entries(item?.filter)[0][0] +
          ' ' +
          item?.field?.breakpoints?.find(
            (b) => b.value === Object.entries(item?.filter)[0][1]
          )?.label
        );
      }
    } else if (
      item?.field?.name === 'Sponsor' ||
      item?.field?.name === 'Contributor'
    ) {
      return (
        Object.entries(item?.filter)[0][0] +
        ' ' +
        Object.entries(item?.field)
          .filter(([key, value]) => typeof value !== 'string')
          .map((item) => item?.[1]?.firstName + ' ' + item?.[1]?.lastName)
          .join(', ')
      );
    } else {
      if (Object?.entries(item?.filter)[0][0] !== 'null') {
        return (
          Object?.entries(item?.filter)[0][0] +
          ' ' +
          Object?.entries(item?.field)
            .filter(([key, value]) => typeof value !== 'string')
            .map((item) => item?.[1]?.name)
            .join(', ')
        );
      }
    }
  };
  const [rankingCountLoader, setRankingCountLoader] = useState(false);

  const getRankingFilter = async () => {
    setRankingCountLoader(true);
    try {
      let inputData = {
        userID: userId,
        scenario: {
          organizationID: orgId,
          id: id,
          rankingFilters: {
            match: ideaMatch,
            filters: saveRankingFilter,
          },
        },
      };
      let res = await RESTGetCount(inputData);
      setRankingCount(res?.body);
      setRankingCountLoader(false);
    } catch (err) {
      console.log(err);
    }
  };

  const onCancelFilterCount = async (updatedFilters) => {
    setRankingCountLoader(true);
    try {
      let inputData = {
        userID: userId,
        scenario: {
          organizationID: orgId,
          id: id,
          rankingFilters: {
            match: ideaMatch,
            filters: updatedFilters,
          },
        },
      };
      let res = await RESTGetCount(inputData);
      setRankingCount(res?.body);
      setRankingCountLoader(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleRemoveGoal = (index, item) => {
    const tempSet = new Set(listLeaderGoal.map((el) => el.id));

    listLeaderGoalBackup.forEach((el) => {
      if (el.id === item.id && !tempSet.has(el.id)) {
        tempSet.add(el.id);
      }
    });

    const updatedListLeaderGoal = Array.from(tempSet).map((id) =>
      listLeaderGoalBackup.find((el) => el.id === id)
    );

    setListLeaderGoal(updatedListLeaderGoal);
    setRemovedItems(removedItems.filter((_, key) => key !== index));
    setAddGoal(addGoal.filter((_, key) => key !== index));
  };
  return (
    <div className="add-idea overflow-hidden background-white">
      <Row gutter={[20, 20]} className="idea-create create-goal">
        <Col span={24} lg={24} md={24} xs={24} sm={24}>
          <Form
            layout="vertical"
            requiredMark={false}
            form={form}
            onFinish={createPriority}
            className="scenario-form mt-0"
          >
            <Row justify="space-between">
              <Col span={8}>
                <Form.Item
                  className="form-label"
                  name="Prioritybatchname"
                  tooltip={{
                    title: (
                      <span>
                        {' '}
                        Create a title for your scenario that is unique and
                        memorable. A few popular scenario titles are for the
                        Quarter/Year they were created in or for a specific
                        department or team or leader. <br />
                        Examples : "2023 Q1 Leadership Team Retreat" or
                        "Marketing - June Goal Re-Baseline" or "Operations Rocks
                        in Q2.
                      </span>
                    ),
                    // eslint-disable-next-line max-len
                    icon: <InfoCircleOutlined />,
                  }}
                  rules={[
                    {
                      required: true,
                      message: 'Please input scenario name!',
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter scenario name"
                    className="fs-24 title-scale p-0"
                  />
                </Form.Item>
              </Col>
              <Col span={16} className="save-btn">
                <Button
                  type="primary"
                  className="blue-filled-btn"
                  htmlType="submit"
                  loading={loader}
                  disabled={addGoal?.length === 0}
                  size="middle"
                >
                  Save Scenario
                </Button>
              </Col>
            </Row>
            <Row>
              <Col span={8} md={8}>
                <Form.Item
                  label={<span className="fs-14">Scenario Description</span>}
                  name="scenariodesc"
                  className="form-label"
                >
                  <TextArea rows={4} />
                </Form.Item>
              </Col>
            </Row>
            {defaultPriorityBatchID !== id && (
              <>
                <Row>
                  <Col span={8} md={8}>
                    <Form.Item
                      label={<span className="fs-14">Scenario type</span>}
                      name="Prioritybatchtype"
                      className="form-label"
                      tooltip={{
                        title: (
                          <span>
                            If you select "Shared", other leaders in your
                            organization will be able to view, compare and use
                            for scenario planning. <br />
                            If you select private, only the owners will be able
                            to see this scenario.
                          </span>
                        ),
                        // eslint-disable-next-line max-len
                        icon: <InfoCircleOutlined />,
                      }}
                      rules={[
                        {
                          required: true,
                          message: 'Please select batch type!',
                        },
                      ]}
                    >
                      <Radio.Group
                        onChange={(e) => setBatchTypeValue(e.target.value)}
                        className="fs-16"
                      >
                        <Radio value={true}>Shared</Radio>
                        <Radio value={false}>Private</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>
                    <Form.Item
                      label={<span className="fs-14">Add Owner</span>}
                      name="owner"
                      className="form-label"
                      tooltip={{
                        title: (
                          <span>
                            Select who will be the owner of the scenario.
                            <br />
                            TIP - Even if you create the original scenario - if
                            you are not listed as an owner and add a different
                            owner, only the owners will be able to view + edit.
                          </span>
                        ),
                        // eslint-disable-next-line max-len
                        icon: <InfoCircleOutlined />,
                      }}
                      rules={[
                        {
                          required: true,
                          message: 'Please select owner!',
                        },
                      ]}
                    >
                      {loading ? (
                        <Skeleton.Input active />
                      ) : (
                        <Select
                          className="fs-14 p-5 dropdown-style pl-0"
                          placeholder="Select Owner"
                          mode="multiple"
                          onChange={handleSelectChange}
                          style={{
                            width: '100%',
                          }}
                          maxTagCount="responsive"
                        >
                          {ownerList
                            ?.filter((item) => item?.role !== UserRole?.sme)
                            .map((item, index) => {
                              return (
                                <Option
                                  key={index}
                                  value={item?.id}
                                  className="fs-14"
                                >
                                  {item?.firstName + ' ' + item?.lastName}
                                </Option>
                              );
                            })}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}

            <Row>
              <Col span={11}>
                <div>
                  <Row>
                    <Typography.Title level={5} className="mb-0">
                      Added Goals
                    </Typography.Title>
                    <Tooltip
                      className="ml-10"
                      title="Choose the goals that align with your scenario and assign a % weight. 
                    Total weight of combined goals cannot exceed 100%"
                    >
                      <InfoCircleOutlined />
                    </Tooltip>
                  </Row>

                  <>
                    <Form.Item name="goal" className="mt-10">
                      {addGoal?.map((item, index) => {
                        return (
                          <Row>
                            <Col span={24}>
                              <Row
                                justify="space-between"
                                align="middle"
                                className={
                                  item.active
                                    ? 'added-goal'
                                    : 'added-goal archive-goals'
                                }
                                gutter={[0, 20]}
                              >
                                <Typography.Title
                                  level={5}
                                  className="mb-0 title fs-16 fw-500"
                                  style={
                                    true
                                      ? {
                                          width: 300,
                                        }
                                      : undefined
                                  }
                                  ellipsis={
                                    true
                                      ? {
                                          tooltip: item?.name,
                                        }
                                      : false
                                  }
                                >
                                  {item?.name}
                                </Typography.Title>
                                <Row align="middle">
                                  <Typography.Title
                                    level={5}
                                    className="mb-0 mr-10 fs-14"
                                  >
                                    {' '}
                                  </Typography.Title>
                                  <Input
                                    value={item.weight}
                                    className="input-number"
                                    suffix="%"
                                    onChange={(event) => {
                                      handleInputChange(event, index);
                                    }}
                                  />
                                  <Divider
                                    type="vertical"
                                    className="goal-divider"
                                  />
                                  <div>
                                    <Button
                                      onClick={() => {
                                        handleRemoveGoal(index, item);
                                      }}
                                      className="minus-btn p-0"
                                    >
                                      <img
                                        src={minusButton}
                                        alt="minus"
                                        className="mr-0"
                                      />
                                    </Button>
                                  </div>
                                </Row>
                              </Row>
                            </Col>
                          </Row>
                        );
                      })}
                      {(prioritybatch?.priorities?.length ||
                        addGoal?.length > 0) && (
                        <Row justify="space-between" align="middle">
                          <Typography.Title
                            level={5}
                            className="fw-400 fs-14"
                          ></Typography.Title>
                          <Typography.Title
                            level={5}
                            className="mt-0 fw-400 fs-14"
                          >
                            Total weight : {calculateTotal()}%{' '}
                          </Typography.Title>
                        </Row>
                      )}
                    </Form.Item>
                    <Button
                      className="fs-12 fw-500 blue-filled-btn"
                      type="primary"
                      size="small"
                      onClick={() => {
                        setEditField(null);
                        setGoalModalOpen(true);
                      }}
                    >
                      <PlusOutlined />
                    </Button>
                  </>
                </div>
              </Col>
              <Col span={1} className="scenario-divider">
                <Divider type="vertical" className="h-auto" />
              </Col>
              <Col span={12}>
                <div>
                  <Row justify="space-between" align="middle">
                    <Row>
                      <Typography.Title level={5} className="mb-0">
                        Scenario Filters
                      </Typography.Title>
                      <Tooltip
                        className="ml-10"
                        title="Filter data that feeds your scenario by department, user role, and user. 
                      you can also filter to only consider ideas that match certain criteria."
                      >
                        <InfoCircleOutlined />
                      </Tooltip>
                    </Row>
                    <Row>
                      {saveRankingFilter?.length > 0 && (
                        <div className="d-flex align-center">
                          <Typography.Text className="mr-10">
                            <span
                              className={
                                rankingCount === 0
                                  ? 'fw-bold zero-items-count'
                                  : rankingCount <= 5
                                  ? 'fw-bold yellow-items-count'
                                  : 'fw-bold'
                              }
                            >
                              {rankingCount}
                            </span>{' '}
                            items match your scenario
                          </Typography.Text>
                          <Button
                            type="text"
                            className="p-0"
                            onClick={getRankingFilter}
                          >
                            {rankingCountLoader ? (
                              <LoadingOutlined />
                            ) : (
                              <img
                                src={refreshIcon}
                                alt=""
                                className="refresh-ui"
                              />
                            )}
                          </Button>
                        </div>
                      )}
                    </Row>
                  </Row>
                  {appliedFilters?.length > 0 && (
                    <div className="who-filter mt-10">
                      <div>
                        <Typography.Text className="who-text">
                          Who
                        </Typography.Text>
                      </div>
                      <div>
                        <Typography.Text className="mr-5 gray-text">
                          Use votes that match
                        </Typography.Text>

                        <Select
                          value={selectedValue}
                          variant="borderless"
                          className="condition-selector all-conition-dropdown"
                          onChange={(e) => {
                            setSelectedValue(e);
                          }}
                          style={{
                            flex: 1,
                          }}
                          options={[
                            {
                              value: 'ALL',
                              label: 'All conditions',
                            },
                            {
                              value: 'ONE',
                              label: 'Any conditions',
                            },
                          ]}
                        />
                      </div>
                    </div>
                  )}
                  {appliedFilters?.map((item, index) => {
                    let includeStringDepartment = 'to be excluded';
                    let includeStringUserRole = 'to be excluded';
                    let includedStringUsers = 'to be excluded';

                    let addedDepartment = [];
                    if (item?.departmentFilters?.included) {
                      includeStringDepartment = 'to be included';
                    }
                    if (item?.userRoleFilters?.included) {
                      includeStringUserRole = 'to be included';
                    }
                    if (item?.userFilters?.included) {
                      includedStringUsers = 'to be included';
                    }

                    addedDepartment =
                      item?.departmentFilters?.departmentID?.map((id) => {
                        return listAllDepartments?.find((e) => e?.id === id)
                          ?.name;
                      });
                    return (
                      <Row>
                        <Col
                          span={24}
                          className={
                            editField === item?.id
                              ? 'added-filter edit-item'
                              : 'added-filter'
                          }
                        >
                          <Row align="middle">
                            <Col span={22}>
                              <Row>
                                {item?.departmentFilters?.departmentID?.length >
                                  0 && (
                                  <>
                                    <Typography.Text className="fw-500">
                                      Departments
                                    </Typography.Text>
                                    <Typography.Text className="ml-5 fw-500">
                                      {includeStringDepartment} :{' '}
                                    </Typography.Text>
                                    <Typography.Text
                                      style={
                                        true
                                          ? {
                                              width: 200,
                                            }
                                          : undefined
                                      }
                                      ellipsis={
                                        true
                                          ? {
                                              tooltip:
                                                arrToCommaSeperated(
                                                  addedDepartment
                                                ),
                                            }
                                          : false
                                      }
                                      className="ml-5"
                                    >
                                      <span>
                                        {arrToCommaSeperated(addedDepartment)}
                                      </span>
                                    </Typography.Text>
                                  </>
                                )}
                              </Row>
                              <Row
                                className={
                                  item?.departmentFilters?.departmentID?.length
                                    ? 'mt-5'
                                    : ''
                                }
                              >
                                {item?.userRoleFilters?.userROLE?.length >
                                  0 && (
                                  <>
                                    <Typography.Text className="fw-500">
                                      User roles
                                    </Typography.Text>{' '}
                                    <Typography.Text className="ml-5 fw-500">
                                      {includeStringUserRole} :{' '}
                                    </Typography.Text>
                                    <Typography.Text
                                      style={
                                        true
                                          ? {
                                              width: 200,
                                            }
                                          : undefined
                                      }
                                      ellipsis={
                                        true
                                          ? {
                                              tooltip:
                                                item?.userRoleFilters?.userROLE?.map(
                                                  (role, index) => (
                                                    <>
                                                      <span className="fw-500">
                                                        {role === UserRole.sme
                                                          ? capitalizeFirstLetter(
                                                              UserRole?.teamMember
                                                            )
                                                          : capitalizeFirstLetter(
                                                              role
                                                            )}
                                                      </span>{' '}
                                                      {item?.userRoleFilters
                                                        ?.weight?.length >
                                                        0 && (
                                                        <>
                                                          (W=
                                                          <span className="fw-500">
                                                            {
                                                              item
                                                                ?.userRoleFilters
                                                                ?.weight[index]
                                                            }
                                                          </span>
                                                          )
                                                        </>
                                                      )}
                                                      {index <
                                                        item?.userRoleFilters
                                                          ?.userROLE?.length -
                                                          1 && <span>, </span>}
                                                    </>
                                                  )
                                                ),
                                            }
                                          : false
                                      }
                                      className="ml-5"
                                    >
                                      {item?.userRoleFilters?.userROLE?.map(
                                        (role, index) => (
                                          <>
                                            <span>
                                              {role === UserRole.sme
                                                ? capitalizeFirstLetter(
                                                    UserRole?.teamMember
                                                  )
                                                : capitalizeFirstLetter(role)}
                                            </span>{' '}
                                            {item?.userRoleFilters?.weight
                                              ?.length > 0 && (
                                              <>
                                                (W=
                                                <span className="fw-500">
                                                  {
                                                    item?.userRoleFilters
                                                      ?.weight[index]
                                                  }
                                                </span>
                                                )
                                              </>
                                            )}
                                            {index <
                                              item?.userRoleFilters?.userROLE
                                                ?.length -
                                                1 && <span>, </span>}
                                          </>
                                        )
                                      )}
                                    </Typography.Text>
                                  </>
                                )}
                              </Row>
                              <Row
                                className={
                                  item?.userRoleFilters?.userROLE?.length
                                    ? 'mt-5'
                                    : ''
                                }
                              >
                                {item?.userFilters?.userID?.length > 0 && (
                                  <>
                                    <Typography.Text className="fw-500">
                                      Users
                                    </Typography.Text>
                                    <Typography.Text className="ml-5 fw-500">
                                      {includedStringUsers} :{' '}
                                    </Typography.Text>
                                    <Typography.Text
                                      style={
                                        true
                                          ? {
                                              width: 250,
                                            }
                                          : undefined
                                      }
                                      ellipsis={
                                        true
                                          ? {
                                              tooltip:
                                                item?.userFilters?.userID?.map(
                                                  (name, index) => (
                                                    <>
                                                      <span>
                                                        {ownerList?.find(
                                                          (e) => e?.id === name
                                                        )?.firstName +
                                                          ' ' +
                                                          ownerList?.find(
                                                            (e) =>
                                                              e?.id === name
                                                          )?.lastName}{' '}
                                                      </span>
                                                      {item?.userFilters?.weight
                                                        ?.length > 0 && (
                                                        <>
                                                          (W=
                                                          <span className="fw-500">
                                                            {
                                                              item?.userFilters
                                                                ?.weight[index]
                                                            }
                                                          </span>
                                                          )
                                                        </>
                                                      )}
                                                      {index <
                                                        item?.userFilters
                                                          ?.userID?.length -
                                                          1 && <span>, </span>}
                                                    </>
                                                  )
                                                ),
                                            }
                                          : false
                                      }
                                      className="ml-5"
                                    >
                                      {item?.userFilters?.userID?.map(
                                        (name, index) => (
                                          <>
                                            <span>
                                              {ownerList?.find(
                                                (e) => e?.id === name
                                              )?.firstName +
                                                ' ' +
                                                ownerList?.find(
                                                  (e) => e?.id === name
                                                )?.lastName}{' '}
                                            </span>
                                            {item?.userFilters?.weight?.length >
                                              0 && (
                                              <>
                                                (W=
                                                <span className="fw-500">
                                                  {
                                                    item?.userFilters?.weight[
                                                      index
                                                    ]
                                                  }
                                                </span>
                                                )
                                              </>
                                            )}
                                            {index <
                                              item?.userFilters?.userID
                                                ?.length -
                                                1 && <span>, </span>}
                                          </>
                                        )
                                      )}
                                    </Typography.Text>
                                  </>
                                )}
                              </Row>
                            </Col>
                            <Col span={1} className="edit-icon-filter">
                              <Button
                                icon={
                                  <EditOutlined
                                    onClick={() => {
                                      setEditWhoFilterModalOpen(true);
                                      setSavedFilters(item);
                                    }}
                                  />
                                }
                                className="edit-btn"
                              />
                            </Col>
                            <Col span={1}>
                              <Button
                                icon={
                                  <CloseOutlined
                                    onClick={() => {
                                      handleRemoveItem(item.id);
                                      setSavedFilters();
                                    }}
                                  />
                                }
                                className="cross-btn cross-icon-btn"
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    );
                  })}
                  {saveRankingFilter?.length > 0 && (
                    <div className="who-filter mt-10">
                      <div>
                        <Typography.Text className="who-text">
                          Ideas
                        </Typography.Text>
                      </div>
                      <div>
                        <Typography.Text className="mr-5 gray-text">
                          Show ideas that match
                        </Typography.Text>

                        <Select
                          value={ideaMatch}
                          variant="borderless"
                          className="condition-selector all-conition-dropdown"
                          onChange={(e) => {
                            setIdeaMatch(e);
                          }}
                          style={{
                            flex: 1,
                          }}
                          options={[
                            {
                              value: 'ALL',
                              label: 'All conditions',
                            },
                            {
                              value: 'ONE',
                              label: 'Any conditions',
                            },
                          ]}
                        />
                      </div>
                    </div>
                  )}
                  {saveRankingFilter?.map((item) => {
                    return (
                      <Row>
                        <Col
                          span={24}
                          className={
                            item?.isUpdated
                              ? 'added-filter updated-bg'
                              : item?.isDeleted
                              ? ' added-filter deleted-bg'
                              : 'added-filter'
                          }
                        >
                          <Row align="middle">
                            <Col span={22}>
                              <Row>
                                <>
                                  {item?.isUpdated && (
                                    <img
                                      src={warningIcon}
                                      alt=""
                                      className="mr-5"
                                    />
                                  )}
                                  {item?.isDeleted && <WarningOutlined />}
                                  <Typography.Text
                                    className={
                                      item?.isDeleted
                                        ? 'mr-5 deleted-mf-color'
                                        : 'mr-5 fw-500'
                                    }
                                  >
                                    {item?.field?.name}
                                  </Typography.Text>
                                  <Typography.Text
                                    className={
                                      item?.isDeleted ? 'deleted-mf-color' : ''
                                    }
                                  >
                                    {handleFilterValue(item)}
                                  </Typography.Text>
                                </>
                              </Row>
                              <Row>
                                <>
                                  {item?.threshold !== null &&
                                    item?.threshold !== undefined && (
                                      <Typography.Text
                                        className={
                                          item?.isDeleted
                                            ? 'deleted-mf-color mr-5'
                                            : 'mr-5'
                                        }
                                      >
                                        <span className="fw-500">
                                          {' '}
                                          Threshold :
                                        </span>{' '}
                                        {item?.field?.type === '+-' ? (
                                          item?.field?.breakpoints?.find(
                                            (el) =>
                                              parseInt(el?.value) ===
                                              parseInt(item?.threshold)
                                          )?.label
                                        ) : (
                                          <>
                                            {item?.field?.subType === '$'
                                              ? '$'
                                              : ''}
                                            {item?.threshold}
                                          </>
                                        )}
                                      </Typography.Text>
                                    )}
                                  <Typography.Text
                                    className={
                                      item?.isDeleted ? 'deleted-mf-color' : ''
                                    }
                                  >
                                    <span className="fw-500">
                                      Include blanks :
                                    </span>{' '}
                                    {item?.blanks ? 'true' : 'false'}
                                  </Typography.Text>
                                </>
                              </Row>
                              {item?.isUpdated && (
                                <>
                                  <Divider className="updated-divider" />
                                  <Typography.Text>
                                    Update required
                                  </Typography.Text>
                                </>
                              )}
                              {item?.isDeleted && (
                                <>
                                  <Divider className="updated-divider" />
                                  <Typography.Text>
                                    Field no longer available
                                  </Typography.Text>
                                </>
                              )}
                            </Col>
                            {!item?.isDeleted && (
                              <Col span={1} className="edit-icon-filter">
                                <Button
                                  icon={
                                    <EditOutlined
                                      onClick={() => {
                                        setEditIdeaFilterModal(true);
                                        setSavedRankingFilter(item);
                                      }}
                                    />
                                  }
                                  className="edit-btn"
                                />
                              </Col>
                            )}
                            <Col span={1} className="edit-icon-filter">
                              <Button
                                icon={
                                  <CloseOutlined
                                    onClick={() => {
                                      handleRemoveIdea(item.uniqueID);
                                      setSavedFilters();
                                    }}
                                  />
                                }
                                style={{ height: '17px' }}
                                className="cross-btn cross-icon-btn"
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    );
                  })}

                  {appliedFilters?.length > 0 ||
                  saveRankingFilter?.length > 0 ? (
                    <Button
                      className="fs-12 fw-500 blue-filled-btn"
                      type="primary"
                      size="small"
                      onClick={() => {
                        setFilterModalOpen(true);
                        setSavedRankingFilter();
                        setWhoList([whoOptionsObj]);
                        setIdeaList([ideaOptionsObj]);
                      }}
                    >
                      <PlusOutlined />
                    </Button>
                  ) : (
                    <NoFilterImage setFilterModalOpen={setFilterModalOpen} />
                  )}
                </div>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col span={12} lg={12} md={24} xs={24} sm={24}>
          <FilterTab
            setFilterModalOpen={setFilterModalOpen}
            filterModalOpen={filterModalOpen}
            setAppliedFilters={setAppliedFilters}
            listAllDepartments={listAllDepartments}
            savedFilters={savedFilters}
            appliedFilters={appliedFilters}
            addFilter={addFilter}
            setAddFilter={setAddFilter}
            setSaveRankingFilter={setSaveRankingFilter}
            savedRankingFilter={savedRankingFilter}
            saveRankingFilter={saveRankingFilter}
            setWhoList={setWhoList}
            whoList={whoList}
            whoOptionsObj={whoOptionsObj}
            setIdeaList={setIdeaList}
            ideaList={ideaList}
            ideaOptionsObj={ideaOptionsObj}
            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
            setIdeaMatch={setIdeaMatch}
            ideaMatch={ideaMatch}
            setRankingCount={setRankingCount}
            rankingCount={rankingCount}
          />
          <AddGoal
            setAddGoal={setAddGoal}
            setListLeaderGoal={setListLeaderGoal}
            setListLeaderGoalBackup={setListLeaderGoalBackup}
            listLeaderGoal={listLeaderGoal}
            listLeaderGoalBackup={listLeaderGoalBackup}
            prioritybatch={prioritybatch}
            addGoal={addGoal}
            setRemovedItems={setRemovedItems}
            removedItems={removedItems}
            setGoalModalOpen={setGoalModalOpen}
            goalModalOpen={goalModalOpen}
            setGoalWeight={setGoalWeight}
            goalWeight={goalWeight}
            setAddGoalforModal={setAddGoalforModal}
            addGoalforModal={addGoalforModal}
          />
          <EditWhoFilterModal
            editWhoFilterModalOpen={editWhoFilterModalOpen}
            setEditWhoFilterModalOpen={setEditWhoFilterModalOpen}
            savedFilters={savedFilters}
            listAllDepartments={listAllDepartments}
            appliedFilters={appliedFilters}
            setAppliedFilters={setAppliedFilters}
          />
          <EditIdeaFilterModal
            setEditIdeaFilterModal={setEditIdeaFilterModal}
            editIdeaFilterModal={editIdeaFilterModal}
            savedRankingFilter={savedRankingFilter}
            setSaveRankingFilter={setSaveRankingFilter}
            saveRankingFilter={saveRankingFilter}
            setRankingCount={setRankingCount}
            ideaMatch={ideaMatch}
          />
        </Col>
      </Row>
    </div>
  );
}
