import React, { memo, useEffect, useState } from 'react';
import {
  Button,
  Col,
  DatePicker,
  Grid,
  Row,
  Select,
  Typography,
  Space,
} from 'antd';
import { CheckOutlined, LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { arrToCommaSeperated } from 'utils/commonMethods';
import { gammaType, GraphlevelColors } from 'utils/Constants';
import { useSelector } from 'react-redux';
import {
  getCurrentOrganization,
  getCurrnetUserData,
} from 'store/slices/loginSlice';
import { fetchOrganizationStages } from 'utils/CommonFunctions';

dayjs.extend(weekday);
dayjs.extend(localeData);

Highcharts.SVGRenderer.prototype.symbols.image = function (
  x,
  y,
  width,
  height
) {
  return [
    'M',
    x,
    y,
    'L',
    x + width,
    y,
    x + width,
    y + height,
    x,
    y + height,
    'Z',
  ];
};

const RankingGraphs = ({
  dashboardData,
  setDashboardData,
  filterChange,
  setItemLimit,
  yearlyBackupRankData,
  jsonData,
  selectSwitch,
  fetchData,
  setSelectedDates,
  setSelectedTopItems,
  setSelectedDays,
  graphLoading,
}) => {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);
  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;

  const [allStages, setAllStages] = useState([]);

  const getStageName = async () => {
    try {
      const sortedStages = await fetchOrganizationStages(orgId);
      setAllStages(sortedStages);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getStageName();
    // eslint-disable-next-line
  }, []);

  //state
  const [yearlyRankData, setYearlyRankData] = useState({});
  const selectedDays = localStorage.getItem('days')
    ? localStorage.getItem('days')
    : '1 Week';
  const [selectedRange, setSelectedRange] = useState(selectedDays);
  const [selectedTopValues, setSelectedTopValues] = useState(10);
  const storedSelectedDate = JSON.parse(localStorage.getItem('selectedDate'));
  const parsedSelectedDate = storedSelectedDate
    ? [
        dayjs(storedSelectedDate[0], 'YYYY-MM-DD'),
        dayjs(storedSelectedDate[1], 'YYYY-MM-DD'),
      ]
    : [dayjs().subtract(6, 'week').startOf('week').add(6, 'days'), dayjs()];
  const [selectedDateRange, setSelectedDateRange] =
    useState(parsedSelectedDate);
  const visibleDays = JSON.parse(localStorage.getItem('options'));
  const optionDays = visibleDays ? visibleDays : ['1 Day', '1 Week', '1 Month'];

  const [visibleOptions, setVisibleOptions] = useState(optionDays);
  const getLastDayOfWeek = (date) => {
    const momentDate = dayjs(date, 'YYYY-MM-DD');
    const dayOfWeek = momentDate.weekday();
    let firstDayOfWeek;

    if (momentDate.isSame(dayjs(), 'week')) {
      if (dayjs().day() !== 0) {
        firstDayOfWeek = dayjs().startOf('week');
      } else {
        firstDayOfWeek = dayjs();
      }
    } else {
      firstDayOfWeek = momentDate.clone().subtract(dayOfWeek, 'days');
    }
    return firstDayOfWeek.format('YYYY-MM-DD');
  };

  //to get the last day of the month
  const getLastDayOfMonth = (date) => {
    const momentDate = moment(date, 'YYYY-MM-DD');
    const lastDayOfMonth = momentDate.endOf('month');
    return lastDayOfMonth.format('YYYY-MM-DD');
  };
  //function for data showing in graph.
  const filterJson = (range) => {
    const dateFormat = 'YYYY-MM-DD';
    switch (range) {
      case '1 Day':
        let tempSeries = {};
        Object.keys(yearlyRankData).reduce((acc, key) => {
          const filteredDates = Object.entries(yearlyRankData[key]).reduce(
            (accDates, [date, info]) => {
              if (
                date &&
                date >= selectedDateRange?.[0]?.format(dateFormat) &&
                date <= selectedDateRange?.[1]?.format(dateFormat)
              ) {
                accDates[date] = info;
              }
              return accDates;
            },
            {}
          );

          if (Object.keys(filteredDates)?.length > 0) {
            acc[key] = filteredDates;
            tempSeries[key] = filteredDates;
          }
          return acc;
        }, {});
        return tempSeries;

      case '1 Week':
        let tt = {};
        const filteredData = Object.keys(yearlyRankData).reduce((acc, key) => {
          const filteredDates = key
            ? Object.entries(yearlyRankData[key]).reduce(
                (accDates, [date, info]) => {
                  if (
                    date &&
                    date >= selectedDateRange?.[0]?.format(dateFormat) &&
                    date <= selectedDateRange?.[1]?.format(dateFormat)
                  ) {
                    accDates[date] = info;
                  }
                  return accDates;
                },
                {}
              )
            : {};
          if (Object.keys(filteredDates)?.length > 0) {
            acc[key] = filteredDates;
            tt[key] = filteredDates;
          }
          return acc;
        }, {});

        Object.entries(filteredData).forEach(([key, value]) => {
          let d = {};
          Object.entries(value).forEach(([k, v]) => {
            let data = getLastDayOfWeek(k);
            if (data.length > 0) {
              d[data] = v;
              tt[key] = d;
            }
          });
        });

        return tt;

      case '1 Month':
        let monthData = {};

        const monthlyData = Object.keys(yearlyRankData).reduce((acc, key) => {
          const filteredDates = Object.entries(yearlyRankData[key]).reduce(
            (accDates, [date, info]) => {
              if (
                date &&
                date >= selectedDateRange?.[0]?.format(dateFormat) &&
                date <= selectedDateRange?.[1]?.format(dateFormat)
              ) {
                accDates[date] = info;
              }
              return accDates;
            },
            {}
          );
          if (Object.keys(filteredDates)?.length > 0) {
            acc[key] = filteredDates;
            monthData[key] = filteredDates;
          }
          return acc;
        }, {});

        Object.entries(monthlyData).forEach(([key, value]) => {
          let d = {};
          Object.entries(value).forEach(([k, v]) => {
            let data = getLastDayOfMonth(k);
            if (data.length > 0) {
              d[data] = v;
              monthData[key] = d;
            }
          });
        });
        return monthData;
      default:
    }
  };

  const data = filterJson(selectedRange);

  const handleShowValues = async (value) => {
    await fetchData(
      null,
      null,
      null,
      null,
      null,
      '',
      selectedDateRange?.[0],
      selectedDateRange?.[1],
      selectedRange,
      value
    );
    setSelectedTopItems(value);
    setItemLimit(value);
    setSelectedTopValues(value);
  };
  //for start date and end date onChange function
  const handleDateFilter = (value) => {
    setSelectedDateRange(value);
    const dateFormat = 'YYYY-MM-DD';
    localStorage.setItem('selectedDate', JSON.stringify(value));
    setTimeout(async () => {
      await fetchData(
        null,
        null,
        null,
        null,
        null,
        '',
        value[0],
        value[1],
        selectedRange,
        selectedTopValues
      );
    }, 2000);
    setSelectedDates([value[0], value[1]]);
    const filteredData = Object.keys(yearlyRankData).reduce((acc, key) => {
      const filteredDates = Object.entries(yearlyRankData[key]).reduce(
        (accDates, [date, info]) => {
          if (
            date >= value?.[0]?.format(dateFormat) &&
            date <= value?.[1]?.format(dateFormat)
          ) {
            accDates[date] = info;
          }
          return accDates;
        },
        {}
      );
      if (Object.keys(filteredDates)?.length > 0) {
        acc[key] = filteredDates;
      }
      const startDate = value[0];
      const endDate = value[1];
      const daysDiff = endDate.diff(startDate, 'days');
      const monthsDiff = endDate.diff(startDate, 'months');
      const weeksDiff = endDate.diff(startDate, 'weeks');

      if (daysDiff === 7) {
        setVisibleOptions(['1 Day']);
        const optionsToSet = ['1 Day'];
        // Convert the array to a JSON string and store it in localStorage
        localStorage.setItem('options', JSON.stringify(optionsToSet));
        setSelectedRange('1 Day');
        localStorage.setItem('days', '1 Day');
      } else if (weeksDiff >= 8 && monthsDiff >= 1) {
        setVisibleOptions(['1 Day', '1 Week', '1 Month']);
        const optionsToSet = ['1 Day', '1 Week', '1 Month'];
        // Convert the array to a JSON string and store it in localStorage
        localStorage.setItem('options', JSON.stringify(optionsToSet));
        setSelectedRange('1 Week');
        localStorage.setItem('days', '1 Week');
      } else if (weeksDiff >= 1) {
        setVisibleOptions(['1 Day', '1 Week']);
        const optionsToSet = ['1 Day', '1 Week'];
        // Convert the array to a JSON string and store it in localStorage
        localStorage.setItem('options', JSON.stringify(optionsToSet));
        setSelectedRange('1 Week');
        localStorage.setItem('days', '1 Week');
      } else {
        setVisibleOptions(['1 Day']);
        const optionsToSet = ['1 Day'];
        // Convert the array to a JSON string and store it in localStorage
        localStorage.setItem('options', JSON.stringify(optionsToSet));
        setSelectedRange('1 Day');
        localStorage.setItem('days', '1 Day');
      }

      return acc;
    }, {});
  };

  // Create an array of dates within the selected range
  const fetchChangeDashboardData = (jsonData) => {
    let rankingObject = {};
    dashboardData.forEach((item) => {
      if (jsonData && jsonData[item.id] && item.chart) {
        rankingObject[item.id] = jsonData[item.id];
      }
    });
    setYearlyRankData(rankingObject);
  };

  //function for showing color in table based on graph data
  const handleColorChangeData = (obj) => {
    let colorCodeObject = {};
    let graphColorData = [...dashboardData];
    dashboardData.forEach((item) => {
      if (obj && obj[item.id]) {
        colorCodeObject[item.id] = obj[item.id];
      }
    });
    const dataArray = Object.entries(colorCodeObject);
    const top5Data = dataArray.slice(0, selectedTopValues);
    const top5DataObject = Object.fromEntries(top5Data);

    graphColorData.map((i) => {
      if (top5DataObject[i.id]) {
        const getStageLevel = allStages?.filter(
          (item) => item?.id === i?.level?.id
        );
        const gColor = GraphlevelColors[getStageLevel?.[0]?.level];

        i.color = gColor;
      } else {
        i.color = 'black';
      }
    });
    if (selectSwitch) {
      setDashboardData(graphColorData);
    }
  };

  useEffect(() => {
    if (jsonData) {
      handleColorChangeData(jsonData);
      fetchChangeDashboardData(jsonData);
    }
    // eslint-disable-next-line
  }, [jsonData, allStages]);

  useEffect(() => {
    if (Object.keys(yearlyBackupRankData)?.length) {
      handleColorChangeData(yearlyBackupRankData);
    }
    // eslint-disable-next-line
  }, [selectedTopValues, filterChange, allStages]);

  useEffect(() => {
    if (Object.keys(yearlyBackupRankData).length) {
      fetchChangeDashboardData(yearlyBackupRankData);
    }
    // eslint-disable-next-line
  }, [dashboardData]);

  let seriesData = Object?.entries(data);
  let series = seriesData.slice(0, selectedTopValues).map(([item, value]) => {
    const lastEntry = Object.values(value).pop();
    const lastStageName = lastEntry?.S?.id;
    const data = Object.entries(value).map(([k, v]) => {
      const timestamp = Date.parse(k);
      let stageName = allStages?.filter(
        (item) => item?.id === v.S?.id || item?.id === v.Stage?.id
      )[0];

      return {
        x: timestamp,
        y: v.R, // Or whatever the appropriate data value is
        Stage: stageName?.name,
        marker: {
          // enabled: false,
          symbol: 'square',
        },
      };
    });
    const getStageLevel = allStages?.filter(
      (item) => item?.id === lastStageName || item?.id === lastStageName?.id
    );

    const gColor = GraphlevelColors[getStageLevel?.[0]?.level];

    return {
      name: dashboardData.filter((i) => i.id === item)[0] || item,
      data: data,
      color: gColor,
    };
  });

  //Dynamic tooltip function
  const tooltipFormatter = function () {
    // 'this' refers to the current point object
    const linkPath =
      this.point.Stage === gammaType.opportunity ||
      this.point.Stage === gammaType.project
        ? `/ranking-detail/edit/${this.series.name.id}`
        : `/edit-idea/${this.series.name.id}`;
    const linkText = this.series.name.title;
    const departmentNames =
      this.series.name?.departments?.map((item) => item?.name) || [];

    const formattedDate = Highcharts.dateFormat('%Y-%m-%d', new Date(this.x));
    const tooltipContent =
      '<b>Date</b>: ' +
      formattedDate +
      '<br/>' +
      '<b>Name</b>: <a href="' +
      linkPath +
      '">' +
      linkText +
      '</a><br/>' +
      '<b>Rank</b>: ' +
      this.y +
      '<br/>' +
      '<b>Stage</b>: ' +
      this.point.Stage +
      '<br/>' +
      '<b>Department</b>: ' +
      (departmentNames.length > 0 ? arrToCommaSeperated(departmentNames) : '-');

    return tooltipContent;
  };

  //graph's chartCofig
  const chartConfig = {
    chart: {
      type: 'line',
      zoomType: 'x',
      panning: {
        enabled: true,
        type: 'x',
      },
      panKey: 'shift',
      events: {
        load: function () {
          this.yAxis[0].setExtremes(1);
        },
      },
    },

    events: {
      render: function () {
        const chart = this;
        const yAxis = chart.yAxis[0];
        const label = yAxis.plotLines[0].label;

        // Position for the line above the label
        const x1 = chart.plotLeft + label.getBBox().x;
        const x2 = x1 + label.width;
        const y = chart.plotTop + label.getBBox().y - 6; // Adjust the vertical position as needed

        // Draw the line using Renderer API
        chartConfig.renderer
          .path(['M', x1, y, 'L', x2, y])
          .attr({
            'stroke-width': 1, // Line width
            stroke: 'red', // Line color
            zIndex: 3, // Higher zIndex to position it above the label
          })
          .add();
      },
    },
    credits: {
      enabled: false,
    },

    legend: {
      enabled: false,
    },

    title: {
      text: null,
      align: 'left',
    },

    subtitle: {
      text: null,
      align: 'left',
    },

    yAxis: {
      title: {
        text: 'Rank',
      },
      accessibility: {
        description: 'Percentage usage',
      },
      tickPositioner: function () {
        var positions = [],
          tick = Math.floor(this?.dataMin),
          increment = Math.ceil((this?.dataMax - this?.dataMin) / 6);
        if (this?.dataMax !== null && this?.dataMin !== null && increment > 0) {
          for (tick; tick - increment <= this?.dataMax; tick += increment) {
            positions?.push(tick);
          }
        }
        return positions;
      },
      reversed: true,
    },

    xAxis: {
      ordinal: false,
      type: 'datetime',
      scrollbar: {
        enabled: true, // Enable the scrollbar
      },

      title: {
        text: 'Date',
      },
      labels: {
        formatter: function () {
          const date = new Date(this.value);

          const formattedDate = date.toISOString().split('T')[0];
          if (selectedRange === '1 Day') {
            // Return the formatted date only if it matches the selected date range
            if (
              date >= selectedDateRange[0].toDate() &&
              date <= selectedDateRange[1].toDate()
            ) {
              return formattedDate;
            } else {
              return ''; // Return an empty string for other dates
            }
          } else if (selectedRange === '1 Week') {
            const dateObject = dayjs(formattedDate, 'YYYY-MM-DD');

            const lastDayOfWeek = dateObject.startOf('week');

            return lastDayOfWeek.format('YYYY-MM-DD');
          } else if (selectedRange === '1 Month') {
            // Convert the formatted date to a dayjs object
            const momentDate = moment(date, 'YYYY-MM-DD');
            const lastDayOfMonth = momentDate.endOf('month');
            return lastDayOfMonth.format('YYYY-MM-DD');
          }
          return formattedDate;
        },
      },
      tickPositioner: function () {
        const tickPositions = [];
        let interval = '';
        if (selectedRange === '1 Day') {
          interval = 24 * 60 * 60 * 1000; // 1 day in milliseconds
        } else if (selectedRange === '1 Week') {
          interval = 7 * 24 * 60 * 60 * 1000; // 1 week in milliseconds
        } else {
          interval = 30 * 24 * 60 * 60 * 1000; // month in milliseconds
        }

        // Calculate tick positions based on the selected date range
        const startDate = this.dataMin;
        const endDate = this.dataMax;
        let currentTick = startDate;

        while (currentTick <= endDate) {
          tickPositions.push(currentTick);
          currentTick += interval;
        }

        return tickPositions;
      },
    },

    tooltip: {
      stickOnContact: true,
      useHTML: true, // Enable HTML in the tooltip
      formatter: tooltipFormatter,
    },

    plotOptions: {
      series: {
        cursor: 'pointer',
        lineWidth: 2,
        marker: {
          enabled: false, // Disable markers by default for all series
        },
        dataLabels: {
          enabled: false, // Disable data labels by default for all series
          format: '{y}%', // Display the value as a label

          style: {
            textOutline: false, // Remove text outline (optional)
          },
        },
      },
    },

    series: series,

    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 550,
          },
          chartOptions: {
            chart: {
              spacingLeft: 3,
              spacingRight: 3,
            },
            legend: {
              itemWidth: 150,
            },
            xAxis: {
              categories: [
                'Dec. 2010',
                'May 2012',
                'Jan. 2014',
                'July 2015',
                'Oct. 2017',
                'Sep. 2019',
              ],
              title: '',
            },
            yAxis: {
              visible: false,
            },
          },
        },
      ],
    },
  };

  const handleChange = async (value) => {
    localStorage.setItem('days', value);
    await fetchData(
      null,
      null,
      null,
      null,
      null,
      '',
      selectedDateRange?.[0],
      selectedDateRange?.[1],
      selectedRange,
      selectedTopValues
    );
    setSelectedDays(value);
    setSelectedRange(value);
  };

  // Handler for the "Last Month"
  const getLastMonthRange = () => {
    const lastMonthStart = dayjs().startOf('month').subtract(1, 'month');
    const lastMonthEnd = dayjs().endOf('month').subtract(1, 'month');
    handleDateFilter([lastMonthStart, lastMonthEnd]);
    setSelectedDateRange([lastMonthStart, lastMonthEnd]);
  };

  // Handler for the "Last Week"
  const getLastWeekRange = () => {
    const lastMonthStart = dayjs().startOf('week').subtract(1, 'week');
    const lastMonthEnd = dayjs().endOf('week').subtract(1, 'week');
    handleDateFilter([lastMonthStart, lastMonthEnd]);
    setSelectedDateRange([lastMonthStart, lastMonthEnd]);
    setSelectedRange('1 Day');
  };

  // Handler for the "Last Quarter"
  const getLastQuarterRange = () => {
    const lastQuarterStart = dayjs().startOf('month').subtract(3, 'month');
    const lastQuarterEnd = dayjs().endOf('month').subtract(1, 'month');
    setSelectedDateRange([lastQuarterStart, lastQuarterEnd]);
    handleDateFilter([lastQuarterStart, lastQuarterEnd]);
  };
  // // Handler for the "Last 6 Month"
  const getLastSixMonthsRange = () => {
    const lastSixMonthsStart = dayjs().startOf('month').subtract(6, 'month');
    const lastSixMonthsEnd = dayjs().endOf('month').subtract(1, 'month');
    setSelectedDateRange([lastSixMonthsStart, lastSixMonthsEnd]);
    handleDateFilter([lastSixMonthsStart, lastSixMonthsEnd]);
  };

  // // Handler for the "Last 6 week"
  const getLastSixWeekRange = () => {
    const lastSixMonthsStart = dayjs()
      .subtract(6, 'week')
      .startOf('week')
      .add(6, 'days');
    const lastSixMonthsEnd = dayjs();
    setSelectedDateRange([lastSixMonthsStart, lastSixMonthsEnd]);
    handleDateFilter([lastSixMonthsStart, lastSixMonthsEnd]);
  };

  const disabledDate = (current) => {
    // Get the current date
    const currentDate = moment().endOf('day');

    // Disable all dates that are after the current date
    return current && current > currentDate;
  };

  return (
    <>
      <Typography.Title level={5} className="mt-10">
        Chart
      </Typography.Title>
      <Row justify="space-between" align="middle">
        <Space>
          <Typography.Text>Timeline</Typography.Text>
          <Select
            value={selectedRange}
            onChange={handleChange}
            className="graph-dropdown"
            style={{
              width: 120,
            }}
          >
            {visibleOptions.map((option) => (
              <Option key={option} value={option}>
                {option}
              </Option>
            ))}
          </Select>
          <DatePicker.RangePicker
            className="ml-10 date-picker"
            allowClear={false}
            value={parsedSelectedDate}
            onChange={handleDateFilter}
            format={'YYYY-MM-DD'}
            disabledDate={disabledDate}
            renderExtraFooter={() => (
              <Row style={{ padding: 5 }} gutter={[10, 10]}>
                <Col>
                  <Button
                    size="small"
                    onClick={getLastWeekRange}
                    className="range-btn"
                  >
                    Last Week
                  </Button>
                </Col>
                <Col>
                  <Button
                    size="small"
                    onClick={getLastMonthRange}
                    className="range-btn"
                  >
                    Last Month
                  </Button>
                </Col>
                <Col>
                  <Button
                    size="small"
                    onClick={getLastQuarterRange}
                    className="range-btn"
                  >
                    Last Quarter
                  </Button>
                </Col>
                <Col>
                  <Button
                    size="small"
                    onClick={getLastSixMonthsRange}
                    className="range-btn"
                  >
                    Last 6 Month
                  </Button>
                </Col>
                <Col>
                  <Button
                    size="small"
                    onClick={getLastSixWeekRange}
                    className="range-btn"
                  >
                    Last 6 Week
                  </Button>
                </Col>
              </Row>
            )}
          />
        </Space>
        <Row align="middle">
          <Space className="gap-0">
            <Typography.Text>Show by</Typography.Text>
            <Select
              value={selectedTopValues}
              onChange={handleShowValues}
              className="sorting-common h-35 ml-10"
              allowClear
              options={[
                {
                  value: 5,
                  label: <Space>Top 5</Space>,
                },
                {
                  value: 10,
                  label: <Space>Top 10</Space>,
                },
                {
                  value: 15,
                  label: <Space>Top 15</Space>,
                },
                {
                  value: -1,
                  label: <Space>All</Space>,
                },
              ]}
            />
          </Space>
        </Row>
      </Row>
      {graphLoading ? (
        <div className="graph-loader">
          <LoadingOutlined
            style={{
              fontSize: 80,
              color: '#0082ca',
            }}
            spin
          />
        </div>
      ) : (
        <Row className="mt-20 chart-step">
          <Col span={24}>
            <div>
              <HighchartsReact highcharts={Highcharts} options={chartConfig} />
            </div>
          </Col>
        </Row>
      )}
      {/* <Row className="mt-20 chart-step">
        <Col span={24}>
          <div>
            <HighchartsReact highcharts={Highcharts} options={chartConfig} />
          </div>
        </Col>
      </Row> */}
    </>
  );
};

export default memo(RankingGraphs);

