import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  totalMinutes: 0,
  totalSeconds: 0,
  totalCompare: 0,
  modalTrue: false,
  compareAdd: false,
  comparisonCall: true,
  nullComparison: true,
  defaultBatchID: '',
};

const compareSlice = createSlice({
  name: 'compareSlice',
  initialState,
  reducers: {
    setTotalMinutes: (state, { payload }) => {
      state.totalMinutes = payload;
    },
    setTotalSeconds: (state, { payload }) => {
      state.totalSeconds = payload;
    },
    setTotalCompare: (state, { payload }) => {
      state.totalCompare = payload;
    },
    setModalTrue: (state, { payload }) => {
      state.modalTrue = payload;
    },
    setCompareAdd: (state, { payload }) => {
      state.compareAdd = payload;
    },
    setComparisonCall: (state, { payload }) => {
      state.comparisonCall = payload;
    },
    setNullComparison: (state, { payload }) => {
      state.nullComparison = payload;
    },
    setDefaultBatchID: (state, { payload }) => {
      state.defaultBatchID = payload;
    },
  },
});

export const getTotalMinutes = (state) => state?.compareReducer?.totalMinutes;
export const getTotalSeconds = (state) => state?.compareReducer?.totalSeconds;
export const getTotalCompare = (state) => state?.compareReducer?.totalCompare;
export const getModalTrue = (state) => state?.compareReducer?.modalTrue;
export const getAddedCompare = (state) => state?.compareReducer?.compareAdd;
export const getComparisonCall = (state) =>
  state?.compareReducer?.comparisonCall;
export const getNullComparison = (state) =>
  state?.compareReducer?.nullComparison;
export const getDefaultBatchID = (state) =>
  state?.compareReducer?.defaultBatchID;

const { actions, reducer } = compareSlice;
export const {
  setTotalMinutes,
  setTotalSeconds,
  setTotalCompare,
  setModalTrue,
  setCompareAdd,
  setComparisonCall,
  setNullComparison,
  setDefaultBatchID,
} = actions;
export default reducer;

