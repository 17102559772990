import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import {
  Button,
  Row,
  Select,
  Space,
  Form,
  Input,
  Col,
  Table,
  Tag,
  Tooltip,
  message,
  Grid,
  Drawer,
  Typography,
  Radio,
  Image,
  Divider,
} from 'antd';
import { InfoCircleOutlined, RightOutlined } from '@ant-design/icons';
import { API, graphqlOperation } from 'aws-amplify';

import { ECHOIMAGES } from '../../Assets/Images';
import '../AllPriorities/idea.less';
import './scenarioPlanning.less';
import {
  getCurrentOrganization,
  getCurrnetUserData,
} from '../../store/slices/loginSlice';
import { onCreateScoreCustom } from '../../graphql/customSubscriptions';
import {
  debounceFn,
  searchTextInArrayOfObject,
  sortArrayByDate,
  sortArrayByProperty,
} from '../../utils/commonMethods';
import TableSkeleton from '../../Common/TableSkeleton';
import { RESTGetAuthorization, RESTGetRankingFilter } from 'utils/RESTApi';
import FilterDrawer from './components/FilterDrawer';
import { fetchOrgDepartments } from 'utils/CommonFunctions';
import { levelColors } from 'utils/Constants';
import { getDefaultBatchID } from 'store/slices/compareSlice';
import ScenarioDetail from './components/ScenarioDetail';
import FilterButton from 'Common/FilterButton';

export default function ScenarioPlanning() {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const { downArrow, upArrow, minusArrow } = ECHOIMAGES.HomeIcons;

  const currnetOrg = useSelector(getCurrentOrganization);
  const loginUsrOrg = useSelector(getCurrnetUserData);

  const [dashboardData, setDashboardData] = useState([]);
  const [backupDashboardData, setBackupDashboardData] = useState([]);

  const userId = currnetOrg?.adminID || loginUsrOrg?.id;
  const orgId = currnetOrg?.id || loginUsrOrg?.organizationID;

  const [listAllDepartments, setListAllDepartments] = useState([]);
  const [selectedPriority, setSelectedPriority] = useState({
    position1: '',
    position2: '',
  });
  const [positionPriority, setPositionPriority] = useState({});
  const [position2Priority, setPosition2Priority] = useState([]);
  const [priorityPositionList, SetPriorityPositionList] = useState([]);
  const [backupPositionPriorityList, SetBackupPositionPriorityList] = useState(
    []
  );
  const [priorityDrawer, setPriorityDrawer] = useState(false);
  const [scenarioDetailDrawer, setScenarioDetailDrawer] = useState(false);
  const [position1Selected, setPosition1Selected] = useState(false);
  const [position2selected, setPosition2Selected] = useState(false);
  const [selectedScenarioDetail, setSelectedScenarioDetail] = useState();
  const location = useLocation();
  const compareScenariosIds = location?.state.data;
  const [form] = Form.useForm();
  const { Search } = Input;
  const [open, setOpen] = useState(false);
  const [tableOpen, setTableOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loader, setLoader] = useState(false);
  const history = useHistory();
  const showTableDrawer = (drawerState) => {
    if (drawerState === 1) {
      setPriorityDrawer(true);

      if (selectedPriority.position1) {
        form.setFieldsValue({ priority: selectedPriority.position1 });
      } else {
        form.resetFields();
      }
    } else {
      if (selectedPriority.position2) {
        form.setFieldsValue({ priority: selectedPriority.position2 });
      } else {
        form.resetFields();
      }

      setPriorityDrawer(false);
    }
    setTableOpen(true);
  };
  const onTableDrawerClose = () => {
    setTableOpen(false);
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const listAddedPriorityBatch = async () => {
    try {
      let api = `/scenarios?organizationID=${orgId}&userID=${userId}`;
      let res = await RESTGetAuthorization(api);
      SetPriorityPositionList(res?.body?.items);
      SetBackupPositionPriorityList(res?.body?.items);
    } catch (error) {
      message.error(error.message);
    }
  };
  const [scenarioLoader, setScenarioLoader] = useState(false);

  const getScenarioById = async () => {
    try {
      setScenarioLoader(true);
      let api = '';
      let res;
      if (position1Selected) {
        api = `/scenarios/get?organizationID=${orgId}&userID=${userId}`;
        if (selectedPriority?.position1 || compareScenariosIds !== undefined) {
          api += `&id=${
            selectedPriority?.position1
              ? selectedPriority?.position1
              : compareScenariosIds !== undefined
              ? compareScenariosIds?.[1]
              : ''
          }`;
          res = await RESTGetAuthorization(api);
        }
      } else {
        api = `/scenarios/get?organizationID=${orgId}&userID=${userId}`;
        if (position2selected) {
          api += `&id=${
            selectedPriority?.position2 !== ''
              ? selectedPriority?.position2
              : compareScenariosIds !== undefined
              ? compareScenariosIds?.[2]
              : ''
          }`;
          res = await RESTGetAuthorization(api);
        }
      }
      setSelectedScenarioDetail(res?.body);
      setScenarioLoader(false);
    } catch (err) {
      setScenarioLoader(false);
      console.log(err);
    }
  };

  const scenarioSearch = (e) => {
    if (e !== '') {
      const temp = backupPositionPriorityList.filter(
        (item) =>
          item?.title && item?.title.toLowerCase().includes(e.toLowerCase())
      );
      SetPriorityPositionList(temp);
    } else {
      SetPriorityPositionList(backupPositionPriorityList);
    }
  };

  const onSearch = (searchText) => {
    if (searchText !== '') {
      const keysToSearch = ['title'];
      const returnFilterData = searchTextInArrayOfObject(
        searchText,
        keysToSearch,
        backupDashboardData
      );
      setDashboardData(returnFilterData);
    } else {
      setDashboardData(backupDashboardData);
    }
  };

  const debouncedHandleInput = debounceFn(onSearch, 500);

  const handleInputSearch = (searchText) => {
    debouncedHandleInput(searchText);
  };

  const priorityDefaultBatchID =
    currnetOrg?.defaultPrioirtybatch?.id ||
    loginUsrOrg?.Organization?.organizationDefaultPrioirtybatchId;

  const fetchDefaultBatchID = useSelector(getDefaultBatchID);
  const [nextTokenData, setNextToken] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [rankingFilter, setRankingFilter] = useState({});
  const [sortTableOrder, setSortTableOrder] = useState('ASC');
  const [sortTableColumn, setSortTableColumn] = useState('rank');
  const [sortColumnField, setSortColumnFiled] = useState();
  const [filterChange, setFilterChange] = useState(false);
  const fetchGammas = async (
    id,
    sortOrder,
    sortColumn,
    page,
    filterChange,
    sortField
  ) => {
    let tempPage = page || currentPage;
    if ((fetchDefaultBatchID || priorityDefaultBatchID) && orgId) {
      let allData = [];
      let nextToken = nextTokenData;
      let sort_direction = sortTableOrder;
      let sort_field = sortTableColumn;
      let sort_column = sortColumnField;
      if (sortOrder && sortColumn) {
        sort_direction = sortOrder;
        sort_field = sortColumn;
        sort_column = sortField;
      }

      let batchID = fetchDefaultBatchID
        ? fetchDefaultBatchID
        : priorityDefaultBatchID;
      let rankingApi = '';
      let firstPosition = selectedPriority?.position1
        ? selectedPriority?.position1
        : compareScenariosIds?.[1] !== undefined
        ? compareScenariosIds?.[1]
        : '';
      let secondPosition = selectedPriority?.position2
        ? selectedPriority?.position2
        : compareScenariosIds?.[2] !== undefined
        ? compareScenariosIds?.[2]
        : '';
      if (sort_column === 'Position') {
        // eslint-disable-next-line max-len
        rankingApi = `/rankings?id=${orgId}&priorityBatchID=${firstPosition}&priorityBatchID1=${batchID}&priorityBatchID2=${secondPosition}&userID=${userId}&page=${tempPage}&sort_direction=${sort_direction}&sort_field=${sort_field}`;
      } else if (sort_column === 'Position2') {
        // eslint-disable-next-line max-len
        rankingApi = `/rankings?id=${orgId}&priorityBatchID=${secondPosition}&priorityBatchID1=${batchID}&priorityBatchID2=${firstPosition}&userID=${userId}&page=${tempPage}&sort_direction=${sort_direction}&sort_field=${sort_field}`;
      } else {
        // eslint-disable-next-line max-len
        rankingApi = `/rankings?id=${orgId}&priorityBatchID=${batchID}&priorityBatchID1=${firstPosition}&priorityBatchID2=${secondPosition}&userID=${userId}&page=${tempPage}&sort_direction=${sort_direction}&sort_field=${sort_field}`;
      }
      if (nextToken && tempPage !== 1) {
        rankingApi += `&nextToken=${nextToken}`;
      }
      try {
        const res = await RESTGetRankingFilter(rankingApi, rankingFilter);
        const temp = res?.body?.items?.map((item, index) => ({
          ...item?.Gamma,
          rank:
            item?.Gamma?.fixedRank > 0
              ? item?.Gamma?.fixedRank
              : item?.Gamma?.rank,
          isRank: item.isRanked,
        }));

        setNextToken(res?.body?.nextToken);
        allData = filterChange
          ? temp
          : nextToken
          ? [...dashboardData, ...temp]
          : temp;

        setDashboardData(allData);
        setBackupDashboardData(allData);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }

      return allData;
    } else {
      setLoading(false);
    }
  };

  const scenarioRanking = async (
    id,
    sortOrder,
    sortColumn,
    page,
    filterChange,
    sortField
  ) => {
    setLoader(true);
    let tempPage = page || currentPage;
    if ((fetchDefaultBatchID || priorityDefaultBatchID) && orgId) {
      let allData = [];
      let sort_direction = sortTableOrder;
      let sort_field = sortTableColumn;
      let sort_column = sortColumnField;
      if (sortOrder && sortColumn) {
        sort_direction = sortOrder;
        sort_field = sortColumn;
        sort_column = sortField;
      }

      let batchID = fetchDefaultBatchID
        ? fetchDefaultBatchID
        : priorityDefaultBatchID;
      let rankingApi = '';
      let firstPosition = selectedPriority?.position1
        ? selectedPriority?.position1
        : compareScenariosIds?.[1] !== undefined
        ? compareScenariosIds?.[1]
        : '';
      let secondPosition = selectedPriority?.position2
        ? selectedPriority?.position2
        : compareScenariosIds?.[2] !== undefined
        ? compareScenariosIds?.[2]
        : '';
      if (sort_column === 'Position') {
        // eslint-disable-next-line max-len
        rankingApi = `/rankings?id=${orgId}&priorityBatchID=${firstPosition}&priorityBatchID1=${batchID}&priorityBatchID2=${secondPosition}&userID=${userId}&page=${tempPage}&sort_direction=${sort_direction}&sort_field=${sort_field}`;
      } else if (sort_column === 'Position2') {
        // eslint-disable-next-line max-len
        rankingApi = `/rankings?id=${orgId}&priorityBatchID=${secondPosition}&priorityBatchID1=${batchID}&priorityBatchID2=${firstPosition}&userID=${userId}&page=${tempPage}&sort_direction=${sort_direction}&sort_field=${sort_field}`;
      } else {
        // eslint-disable-next-line max-len
        rankingApi = `/rankings?id=${orgId}&priorityBatchID=${batchID}&priorityBatchID1=${firstPosition}&priorityBatchID2=${secondPosition}&userID=${userId}&page=${tempPage}&sort_direction=${sort_direction}&sort_field=${sort_field}`;
      }

      try {
        const res = await RESTGetRankingFilter(rankingApi, rankingFilter);
        const temp = res?.body?.items?.map((item, index) => ({
          ...item?.Gamma,
          rank:
            item?.Gamma?.fixedRank > 0
              ? item?.Gamma?.fixedRank
              : item?.Gamma?.rank,
          isRank: item.isRanked,
        }));

        setNextToken(res?.body?.nextToken);
        allData = [...allData, ...temp];
        setDashboardData(allData);
        setBackupDashboardData(allData);
        onTableDrawerClose();
        setLoader(false);
      } catch (err) {
        console.log(err);
        setLoader(false);
      }

      return allData;
    } else {
      setLoader(false);
    }
  };
  const handelInfiniteScroll = async (e) => {
    const { target } = e;
    const isBottom =
      Math.round(target.scrollHeight) - Math.round(target.scrollTop) ===
      Math.round(target.clientHeight);
    if (isBottom && nextTokenData) {
      setCurrentPage((prevPage) => prevPage + 1);
      await fetchGammas(
        null,
        sortTableOrder,
        sortTableColumn,
        currentPage + 1,
        null,
        sortColumnField
      );
    }
  };

  const sortTableData = async (sortData) => {
    let sortOrder = sortData.order === 'ascend' ? 'ASC' : 'DESC';
    let sortColumn = sortData.field;
    let sortField = sortData.columnKey;
    setSortTableOrder(sortOrder);
    setSortTableColumn(sortColumn);
    setSortColumnFiled(sortField);
    setCurrentPage(1);
    await fetchGammas(null, sortOrder, sortColumn, 1, true, sortField);
  };

  const listOrgDepartments = async () => {
    const departments = await fetchOrgDepartments(orgId);
    setListAllDepartments(departments);
  };

  useEffect(() => {
    fetchGammas();
    listOrgDepartments();
    listAddedPriorityBatch();
    scenarioRanking();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getScenarioById();
    // eslint-disable-next-line
  }, [position1Selected, position2selected]);

  let columns = [
    {
      title: 'Rank',
      dataIndex: 'rank',
      key: 'rank',
      width: 70,
      align: 'center',
      render: (text, row) => {
        let thresholdColor =
          sortColumnField === 'Position' || sortColumnField === 'Position2'
            ? row?.outOfThreshold1
            : row.outOfThreshold;
        return {
          props: {},
          children: (
            <span
              className={
                thresholdColor ? 'outofthresholdcolor table-font' : 'table-font'
              }
            >
              {' '}
              {row.isRank === false
                ? '-'
                : sortColumnField === 'Position' ||
                  sortColumnField === 'Position2'
                ? row?.rank1
                : row?.rank || 0}
            </span>
          ),
        };
      },
      sortOrder:
        sortTableColumn === 'rank'
          ? sortTableOrder === 'ASC'
            ? 'ascend'
            : 'descend'
          : null,
      sorter: (a, b) => {
        return a - b;
      },
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: '30%',

      render: (text, row) => {
        let thresholdColor =
          sortColumnField === 'Position' || sortColumnField === 'Position2'
            ? row?.outOfThreshold1
            : row.outOfThreshold;
        return {
          props: {},
          children: (
            <span
              className={
                thresholdColor ? 'table-font outofthresholdcolor' : 'table-font'
              }
            >
              {text}
            </span>
          ),
        };
      },
      sortOrder:
        sortTableColumn === 'title'
          ? sortTableOrder === 'ASC'
            ? 'ascend'
            : 'descend'
          : null,
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: (
        <span>
          Stage{' '}
          <Tooltip
            title="Ideas -> Opportunity -> Project. As ideas gain traction with more positive votes
          from team members, ideas can get promoted up to opportunity and could be selected as a project."
          >
            <InfoCircleOutlined className="ml-5" />
          </Tooltip>
        </span>
      ),
      dataIndex: 'level',
      key: 'Type',
      width: 150,
      sorter: true,
      sortOrder:
        sortTableColumn === 'level'
          ? sortTableOrder === 'ASC'
            ? 'ascend'
            : 'descend'
          : null,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, row, { Type }) => {
        return {
          props: {},
          children: (
            <Tag key={text.id} color={levelColors[text.level]}>
              {text?.name?.toUpperCase()}
            </Tag>
          ),
        };
      },
    },
    {
      title: (
        <div className="scenario-drawer-btn">
          <div>
            <Button onClick={() => showTableDrawer(1)} className="scenario-btn">
              <Tooltip
                title={
                  selectedPriority.position1
                    ? priorityPositionList.filter(
                        (el) => el.id === selectedPriority.position1
                      )?.[0]?.title
                    : compareScenariosIds?.[1]
                    ? priorityPositionList?.filter(
                        (el) => el?.id === compareScenariosIds?.[1]
                      )?.[1]?.title
                    : null
                }
              >
                <span className="ellipsis text-black">
                  {selectedPriority?.position1
                    ? priorityPositionList?.filter(
                        (el) => el?.id === selectedPriority?.position1
                      )?.[0]?.title
                    : compareScenariosIds?.[1]
                    ? priorityPositionList?.filter(
                        (el) => el?.id === compareScenariosIds?.[1]
                      )?.[0]?.title
                    : 'Select Scenario'}{' '}
                </span>
              </Tooltip>
            </Button>
          </div>
          <div>
            <RightOutlined onClick={() => showTableDrawer(1)} />
            <Divider type="vertical" />
            <Button
              type="text"
              disabled={
                !selectedPriority?.position1 && !compareScenariosIds?.[1]
              }
              onClick={() => {
                setScenarioDetailDrawer(true);
                setPosition1Selected(true);
                setPosition2Selected(false);
              }}
            >
              <InfoCircleOutlined />
            </Button>
          </div>
        </div>
      ),
      children: [
        {
          title: <span className="text-primary fw-bold">Position</span>,
          dataIndex: 'position1',
          className: 'sub-col',
          key: 'Position',
          align: 'center',
          sortOrder:
            sortTableColumn === 'position1'
              ? sortTableOrder === 'ASC'
                ? 'ascend'
                : 'descend'
              : null,
          sortDirections: ['ascend', 'descend', 'ascend'],
          sorter:
            selectedPriority?.position1 || compareScenariosIds?.[1]
              ? true
              : false,
          render: (text, row) => {
            let thresholdPosition =
              sortColumnField === 'Position'
                ? row.outOfThreshold
                : sortColumnField === 'Position2'
                ? row.outOfThreshold2
                : row.outOfThreshold1;
            return {
              props: {},
              children: (
                <span
                  className={
                    thresholdPosition
                      ? 'table-font outofthresholdcolor'
                      : 'table-font'
                  }
                >
                  {' '}
                  {sortColumnField === 'Position'
                    ? row?.rank
                    : sortColumnField === 'Position2'
                    ? row?.rank2
                    : row.rank1}
                </span>
              ),
            };
          },
        },

        {
          title: 'Difference',
          dataIndex: 'change',
          className: 'sub-col',
          key: 'Change',
          align: 'center',
          render: (_, row) => {
            return {
              props: {},

              children:
                selectedPriority?.position1 || compareScenariosIds?.[1] ? (
                  sortColumnField === 'Position' ? (
                    row.isRank === false ? (
                      <div>
                        <Image
                          src={minusArrow}
                          className="w-auto"
                          preview={false}
                        />
                        <span
                          className={
                            row?.outOfThreshold
                              ? 'table-font outofthresholdcolor fw-700'
                              : 'table-font fw-700'
                          }
                        >
                          {' '}
                          0
                        </span>
                      </div>
                    ) : row.rank - row.rank1 > 0 ? (
                      <div>
                        <Image
                          src={downArrow}
                          className="w-auto"
                          preview={false}
                        />
                        <span
                          className={
                            row?.outOfThreshold
                              ? 'table-font outofthresholdcolor fw-700'
                              : 'table-font fw-700'
                          }
                        >
                          {' '}
                          -{row.rank - row.rank1}
                        </span>
                      </div>
                    ) : row.rank - row.rank1 < 0 ? (
                      <div>
                        <Image
                          src={upArrow}
                          className="w-auto"
                          preview={false}
                        />
                        <span
                          className={
                            row?.outOfThreshold
                              ? 'table-font outofthresholdcolor fw-700'
                              : 'table-font fw-700'
                          }
                        >
                          {' '}
                          +{row.rank1 - row.rank}
                        </span>
                      </div>
                    ) : (
                      <div>
                        <Image
                          src={minusArrow}
                          className="w-auto"
                          preview={false}
                        />
                        <span
                          className={
                            row?.outOfThreshold
                              ? 'table-font outofthresholdcolor fw-700'
                              : 'table-font fw-700'
                          }
                        >
                          {' '}
                          0
                        </span>
                      </div>
                    )
                  ) : sortColumnField === 'Position2' ? (
                    row.isRank === false ? (
                      <div>
                        <Image
                          src={minusArrow}
                          className="w-auto"
                          preview={false}
                        />
                        <span
                          className={
                            row?.outOfThreshold2
                              ? 'table-font outofthresholdcolor fw-700'
                              : 'table-font fw-700'
                          }
                        >
                          {' '}
                          0
                        </span>
                      </div>
                    ) : row?.rank2 - row.rank1 > 0 ? (
                      <div>
                        <Image
                          src={downArrow}
                          className="w-auto"
                          preview={false}
                        />
                        <span
                          className={
                            row?.outOfThreshold2
                              ? 'table-font outofthresholdcolor fw-700'
                              : 'table-font fw-700'
                          }
                        >
                          {' '}
                          -{row?.rank2 - row.rank1}
                        </span>
                      </div>
                    ) : row?.rank2 - row.rank1 < 0 ? (
                      <div>
                        <Image
                          src={upArrow}
                          className="w-auto"
                          preview={false}
                        />
                        <span
                          className={
                            row?.outOfThreshold2
                              ? 'table-font outofthresholdcolor fw-700'
                              : 'table-font fw-700'
                          }
                        >
                          {' '}
                          +{row.rank1 - row?.rank2}
                        </span>
                      </div>
                    ) : (
                      <div>
                        <Image
                          src={minusArrow}
                          className="w-auto"
                          preview={false}
                        />
                        <span
                          className={
                            row?.outOfThreshold2
                              ? 'table-font outofthresholdcolor fw-700'
                              : 'table-font fw-700'
                          }
                        >
                          {' '}
                          0
                        </span>
                      </div>
                    )
                  ) : row.rank1 - row.rank > 0 ? (
                    <div>
                      <Image
                        src={downArrow}
                        className="w-auto"
                        preview={false}
                      />
                      <span
                        className={
                          row?.outOfThreshold1
                            ? 'table-font outofthresholdcolor fw-700'
                            : 'table-font fw-700'
                        }
                      >
                        {' '}
                        -{row.rank1 - row.rank}
                      </span>
                    </div>
                  ) : row.rank1 && row.rank1 - row.rank < 0 ? (
                    <div>
                      <Image src={upArrow} className="w-auto" preview={false} />
                      <span
                        className={
                          row?.outOfThreshold1
                            ? 'table-font outofthresholdcolor fw-700'
                            : 'table-font fw-700'
                        }
                      >
                        {' '}
                        +{row.rank - row.rank1}
                      </span>
                    </div>
                  ) : (
                    <div>
                      <Image
                        src={minusArrow}
                        className="w-auto"
                        preview={false}
                      />
                      <span
                        className={
                          row?.outOfThreshold1
                            ? 'table-font outofthresholdcolor fw-700'
                            : 'table-font fw-700'
                        }
                      >
                        {' '}
                        0
                      </span>
                    </div>
                  )
                ) : (
                  <div>
                    <Image
                      src={minusArrow}
                      className="w-auto"
                      preview={false}
                    />
                    <span
                      className={
                        row?.outOfThreshold1
                          ? 'table-font outofthresholdcolor fw-700'
                          : 'table-font fw-700'
                      }
                    >
                      {' '}
                      0
                    </span>
                  </div>
                ),
            };
          },
        },
      ],
    },
    {
      title: (
        <div className="scenario-drawer-btn">
          <div>
            <Button onClick={showTableDrawer} className="scenario-btn">
              <Tooltip
                title={
                  selectedPriority.position2
                    ? priorityPositionList.filter(
                        (el) => el.id === selectedPriority.position2
                      )?.[0]?.title
                    : compareScenariosIds?.[2]
                    ? priorityPositionList.filter(
                        (el) => el.id === compareScenariosIds?.[2]
                      )?.[0]?.title
                    : null
                }
              >
                <span className="ellipsis text-black">
                  {selectedPriority.position2
                    ? priorityPositionList.filter(
                        (el) => el.id === selectedPriority.position2
                      )?.[0]?.title
                    : compareScenariosIds?.[2]
                    ? priorityPositionList.filter(
                        (el) => el.id === compareScenariosIds?.[2]
                      )?.[0]?.title
                    : 'Select scenario'}{' '}
                </span>
              </Tooltip>
            </Button>
          </div>
          <div>
            <RightOutlined onClick={showTableDrawer} />
            <Divider type="vertical" />
            <Button
              disabled={
                !compareScenariosIds?.[2] && !selectedPriority.position2
              }
              type="text"
              onClick={() => {
                setScenarioDetailDrawer(true);
                setPosition2Selected(true);
                setPosition1Selected(false);
              }}
            >
              <InfoCircleOutlined />
            </Button>
          </div>
        </div>
      ),
      children: [
        {
          title: 'Position',
          dataIndex: 'position2',
          className: 'sub-col',
          key: 'Position2',
          align: 'center',
          sortOrder:
            sortTableColumn === 'position2'
              ? sortTableOrder === 'ASC'
                ? 'ascend'
                : 'descend'
              : null,
          sortDirections: ['ascend', 'descend', 'ascend'],
          sorter:
            selectedPriority?.position2 || compareScenariosIds?.[2]
              ? true
              : false,
          render: (text, row) => {
            return {
              props: {},
              children: (
                <span
                  className={
                    row?.outOfThreshold2
                      ? 'table-font outofthresholdcolor'
                      : 'table-font'
                  }
                >
                  {' '}
                  {sortColumnField === 'Position2'
                    ? row?.rank
                    : sortColumnField === 'Position'
                    ? row?.rank2
                    : row.rank2 || 0}
                </span>
              ),
            };
          },
        },
        {
          title: 'Difference',
          dataIndex: 'change',
          className: 'sub-col',
          key: 'Change2',
          align: 'center',
          render: (_, row) => {
            return {
              props: {},
              children:
                selectedPriority?.position2 || compareScenariosIds?.[2] ? (
                  sortColumnField === 'Position2' ? (
                    row.isRank === false ? (
                      <div>
                        <Image
                          src={minusArrow}
                          className="w-auto"
                          preview={false}
                        />
                        <span
                          className={
                            row?.outOfThreshold
                              ? 'table-font outofthresholdcolor fw-700'
                              : 'table-font fw-700'
                          }
                        >
                          {' '}
                          0
                        </span>
                      </div>
                    ) : row?.rank - row.rank1 > 0 ? (
                      <div>
                        <Image
                          src={downArrow}
                          preview={false}
                          className="w-auto"
                        />
                        <span
                          className={
                            row?.outOfThreshold
                              ? 'table-font outofthresholdcolor fw-700'
                              : 'table-font fw-700'
                          }
                        >
                          {' '}
                          -{row?.rank - row.rank1}
                        </span>
                      </div>
                    ) : row?.rank - row.rank1 < 0 ? (
                      <div>
                        <Image
                          src={upArrow}
                          preview={false}
                          className="w-auto"
                        />
                        <span
                          className={
                            row?.outOfThreshold
                              ? 'table-font outofthresholdcolor fw-700'
                              : 'table-font fw-700'
                          }
                        >
                          {' '}
                          +{row.rank1 - row?.rank}
                        </span>
                      </div>
                    ) : (
                      <div>
                        <Image
                          src={minusArrow}
                          preview={false}
                          className="w-auto"
                        />
                        <span
                          className={
                            row?.outOfThreshold
                              ? 'table-font outofthresholdcolor fw-700'
                              : 'table-font fw-700'
                          }
                        >
                          {' '}
                          0
                        </span>
                      </div>
                    )
                  ) : sortColumnField === 'Position' ? (
                    row.isRank === false ? (
                      <div>
                        <Image
                          src={minusArrow}
                          className="w-auto"
                          preview={false}
                        />
                        <span
                          className={
                            row?.outOfThreshold2
                              ? 'table-font outofthresholdcolor fw-700'
                              : 'table-font fw-700'
                          }
                        >
                          {' '}
                          0
                        </span>
                      </div>
                    ) : row?.rank2 - row.rank1 > 0 ? (
                      <div>
                        <Image
                          src={downArrow}
                          className="w-auto"
                          preview={false}
                        />
                        <span
                          className={
                            row?.outOfThreshold2
                              ? 'table-font outofthresholdcolor fw-700'
                              : 'table-font fw-700'
                          }
                        >
                          {' '}
                          -{row?.rank2 - row.rank1}
                        </span>
                      </div>
                    ) : row?.rank2 - row.rank1 < 0 ? (
                      <div>
                        <Image
                          src={upArrow}
                          className="w-auto"
                          preview={false}
                        />
                        <span
                          className={
                            row?.outOfThreshold2
                              ? 'table-font outofthresholdcolor fw-700'
                              : 'table-font fw-700'
                          }
                        >
                          {' '}
                          +{row.rank1 - row?.rank2}
                        </span>
                      </div>
                    ) : (
                      <div>
                        <Image
                          src={minusArrow}
                          className="w-auto"
                          preview={false}
                        />
                        <span
                          className={
                            row?.outOfThreshold2
                              ? 'table-font outofthresholdcolor fw-700'
                              : 'table-font fw-700'
                          }
                        >
                          {' '}
                          0
                        </span>
                      </div>
                    )
                  ) : row.rank2 - row.rank > 0 ? (
                    <div>
                      <Image
                        src={downArrow}
                        preview={false}
                        className="w-auto"
                      />
                      <span
                        className={
                          row?.outOfThreshold2
                            ? 'table-font outofthresholdcolor fw-700'
                            : 'table-font fw-700'
                        }
                      >
                        {' '}
                        -{row.rank2 - row.rank}
                      </span>
                    </div>
                  ) : row.rank2 && row.rank2 - row.rank < 0 ? (
                    <div>
                      <Image src={upArrow} preview={false} className="w-auto" />
                      <span
                        className={
                          row?.outOfThreshold2
                            ? 'table-font outofthresholdcolor fw-700'
                            : 'table-font fw-700'
                        }
                      >
                        {' '}
                        +{row.rank - row.rank2}
                      </span>
                    </div>
                  ) : (
                    <div>
                      <Image
                        src={minusArrow}
                        preview={false}
                        className="w-auto"
                      />
                      <span
                        className={
                          row?.outOfThreshold2
                            ? 'table-font outofthresholdcolor fw-700'
                            : 'table-font fw-700'
                        }
                      >
                        {' '}
                        0
                      </span>
                    </div>
                  )
                ) : (
                  <div>
                    <Image
                      src={minusArrow}
                      className="w-auto"
                      preview={false}
                    />
                    <span
                      className={
                        row?.outOfThreshold2
                          ? 'table-font outofthresholdcolor fw-700'
                          : 'table-font fw-700'
                      }
                    >
                      {' '}
                      0
                    </span>
                  </div>
                ),
            };
          },
        },
      ],
    },
  ];

  const handleRowClick = (record) => {
    history.push({
      pathname: `/ranking-detail/${record.id}`,
      state: { data: record },
    });
  };
  useEffect(() => {
    // subscribe for account Update
    const filter = {
      organizationID: orgId,
    };
    const Accountsubscriber = API.graphql(
      graphqlOperation(onCreateScoreCustom, filter)
    ).subscribe({
      next: (d) => {
        // fetchGammas();
      },
      error: (e) => {},
    });
    return () => {
      Accountsubscriber.unsubscribe();
    };

    // eslint-disable-next-line
  }, [orgId]);

  return (
    <div className="w-100 idea background-white">
      <Row justify="space-between" align="middle" className="idea-header w-100">
        <Search
          className="searchbar-style mb-10"
          placeholder="Search"
          onChange={(e) => handleInputSearch(e.target.value)}
        />

        <Row gutter={[0, 10]} className="mb-10">
          <Typography.Text className="d-flex m-auto">Sort by</Typography.Text>
          <Select
            defaultValue="None"
            placeholder="Select sort"
            className="h-35 ml-10 sorting-common"
            dropdownMatchSelectWidth={false}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            onChange={(value) => {
              const [column, order] = value.split(':');
              setSortTableColumn(column);
              setSortTableOrder(order);
              setCurrentPage(1);
              setNextToken(null);
              fetchGammas(null, order, column, 1, true, sortColumnField);
            }}
          >
            <Select.Option value="None">
              {' '}
              <Space>None</Space>
            </Select.Option>
            <Select.Option value="rank:ASC">
              {' '}
              <Space>Rank - High to Low</Space>
            </Select.Option>
            <Select.Option value="rank:DESC">
              <Space>Rank - Low to High</Space>{' '}
            </Select.Option>
            <Select.Option value="createdAt:ASC">
              <Space>Created date - Oldest</Space>
            </Select.Option>
            <Select.Option value="createdAt:DESC">
              <Space>Created date - Newest</Space>
            </Select.Option>
            <Select.Option value="title:ASC">
              {' '}
              <Space>Title - A to Z</Space>{' '}
            </Select.Option>
            <Select.Option value="title:DESC">
              {' '}
              <Space>Title - Z to A</Space>{' '}
            </Select.Option>
          </Select>
          <FilterButton onClick={showDrawer} />
        </Row>
      </Row>

      <Row>
        <Col span={24}>
          {loading ? (
            <TableSkeleton />
          ) : (
            <div
              style={{ maxHeight: 'calc(100vh - 140px)', overflowY: 'auto' }}
              onScroll={handelInfiniteScroll}
            >
              <Table
                dataSource={dashboardData}
                onChange={(pagination, filter, sorter) => {
                  sortTableData(sorter);
                }}
                columns={columns}
                scroll={{ x: 1000 }}
                pagination={false}
                className="sp-table cursor-pointer"
                onRow={(record) => ({
                  onClick: () => handleRowClick(record),
                })}
                size={screens.xxl ? 'middle' : 'small'}
                sticky={{
                  offsetHeader: 0,
                }}
              />
            </div>
          )}
        </Col>
      </Row>

      {/* Filter Drawer*/}
      <FilterDrawer
        listAllDepartments={listAllDepartments}
        backupDashboardData={backupDashboardData}
        setDashboardData={setDashboardData}
        setOpen={setOpen}
        open={open}
        userId={userId}
        setFilterChange={setFilterChange}
        filterChange={filterChange}
        setRankingFilter={setRankingFilter}
        sortTableOrder={sortTableOrder}
        sortTableColumn={sortTableColumn}
        sortColumnField={sortColumnField}
        selectedPriority={selectedPriority}
        compareScenariosIds={compareScenariosIds}
      />

      {/* table drawer  */}
      <Drawer
        className="related-item-drawer brawer-with-btn scenario-drawer"
        title={
          <Row justify="space-between" align="middle" className="pb-0">
            <Typography.Title level={3} className="mb-0 pb-0">
              Select Scenario
            </Typography.Title>
          </Row>
        }
        closeIcon={null}
        closable={false}
        height={screens.xs ? '83vh' : ''}
        placement={screens.xs ? 'bottom' : 'right'}
        onClose={onTableDrawerClose}
        open={tableOpen}
      >
        <div>
          <Form
            className="mt-20"
            form={form}
            onFinish={() => {
              scenarioRanking(
                null,
                sortTableOrder,
                sortTableColumn,
                1,
                null,
                sortColumnField
              );

              setCurrentPage(1);
              setNextToken(null);
            }}
          >
            <Row
              justify="space-between"
              align="middle"
              className="idea-header w-100"
            >
              <Search
                className="searchbar-style mb-10"
                placeholder="Search scenario"
                onChange={(e) => scenarioSearch(e.target.value)}
              />
            </Row>
            <Form.Item name="priority">
              <div className="mt-10">
                <Row justify="space-between" align="middle">
                  <Radio.Group
                    onChange={(e) => {
                      priorityDrawer
                        ? setSelectedPriority({
                            ...selectedPriority,
                            position1: e.target.value,
                          })
                        : setSelectedPriority({
                            ...selectedPriority,
                            position2: e.target.value,
                          });
                    }}
                    value={
                      priorityDrawer
                        ? selectedPriority.position1
                          ? selectedPriority.position1
                          : compareScenariosIds?.[1]
                        : selectedPriority.position2
                        ? selectedPriority.position2
                        : compareScenariosIds?.[2]
                    }
                  >
                    <Space direction="vertical">
                      {priorityPositionList?.map((item, index) => {
                        return (
                          <Radio value={item?.id} key={index}>
                            {item?.title}
                          </Radio>
                        );
                      })}
                    </Space>
                  </Radio.Group>
                </Row>
              </div>
            </Form.Item>
            <div className="drawer-btn-plan">
              <Button
                className="drawer-btn-child"
                onClick={onTableDrawerClose}
                size="middle"
              >
                Cancel
              </Button>
              <Button
                type="primary"
                className="drawer-btn-child"
                htmlType="submit"
                loading={loader}
                size="middle"
              >
                Apply
              </Button>
            </div>
          </Form>
        </div>
      </Drawer>
      <ScenarioDetail
        scenarioDetailDrawer={scenarioDetailDrawer}
        setScenarioDetailDrawer={setScenarioDetailDrawer}
        selectedScenarioDetail={selectedScenarioDetail}
        setPosition1Selected={setPosition1Selected}
        setPosition2Selected={setPosition2Selected}
        scenarioLoader={scenarioLoader}
      />
    </div>
  );
}
